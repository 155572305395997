import React from 'react';
import { DashboardIcon, MixIcon } from '@radix-ui/react-icons';
import { ScrollAreaContainer } from 'components/scroll-area/scroll-area';
import { editorContextStore } from 'contexts/editor-context';
import { DashboardType } from 'core/common/types';
import { Code2, LayoutGrid } from 'lucide-react';

const dashboardLeftPanelTabs: {
    [key in DashboardType]?: {
        name: string,
        icon: React.ReactNode,
    }
} = {
    'projects': {
        name: 'Projects',
        icon: <LayoutGrid size={18} />,
    },
    'manage-api': {
        name: "API",
        icon: <Code2 size={18} />
    },
    // 'models': {
    //     name: 'Custom Models',
    //     icon: <MixIcon width={18} height={18}/>,
    // }
};

export default function DashboardLeftPanel() {
    const dashboardType = editorContextStore(state => state.dashboardType);
    const setDashboardType = editorContextStore(state => state.setDashboardType);
    return (
        <ScrollAreaContainer
            className='p-4 lg:w-[268px] hidden md:block md:flex-none border-r border-zinc-800'
        >
            {Object.entries(dashboardLeftPanelTabs).map(([key, tab]) => <div
                key={key}
                className={`w-full flex flex-row items-center justify-start select-none cursor-pointer px-4 py-2 mb-2 rounded-md transition-colors border active:border-zinc-700 active:text-white ${dashboardType === key ? 'text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800 hover:border-zinc-700/50' : 'text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900'}`}
                onClick={() => {
                    // @ts-ignore
                    setDashboardType(key);
                }}
            >
                <span className='mr-3'>
                    {tab.icon}
                </span>
                <span>
                    {tab.name}
                </span>
            </div>)}
        </ScrollAreaContainer>
    );
}