import React from "react"
import { noop } from "lodash";
import { cleanupPrompt } from "components/utils/prompt";
import { StateUpdater } from "core/common/types";
import { FlairTemplateGenerator, FlairGenerateTemplateItem, FlairTemplateGeneratorBatch, GenerateTemplateTypesenseDoc } from "core/common/types/template-generator";
import { editorContextStore } from "contexts/editor-context";

export type IndexedGenerateTemplateItem = FlairGenerateTemplateItem & {
    index: number,
};

export type IndexedGenerateTemplateItemCollection = Record<string, IndexedGenerateTemplateItem>;


const GenerateTemplatesContext = React.createContext<{
    items: IndexedGenerateTemplateItemCollection,
    setItems: (value: StateUpdater<IndexedGenerateTemplateItemCollection>) => void,
    templatesGenerator: FlairTemplateGenerator | undefined,
    setTemplatesGenerator: (generator: StateUpdater<FlairTemplateGenerator | undefined>) => void,
    searchString: string,
    setSearchString: (value: string) => void,
    isSearching: boolean,
    setIsSearching: (value: StateUpdater<boolean>) => void,
    semanticItems: IndexedGenerateTemplateItemCollection,
    setSemanticItems: (value: StateUpdater<IndexedGenerateTemplateItemCollection>) => void,
}>({
    items: {},
    setItems: noop,
    templatesGenerator: undefined,
    setTemplatesGenerator: noop,
    searchString: '',
    setSearchString: noop,
    isSearching: false,
    setIsSearching: noop,
    semanticItems: {},
    setSemanticItems: noop,
});

export function useGenerateTemplatesContext() {
    return React.useContext(GenerateTemplatesContext);
}

export function postprocessTemplatesBatch(
    batch: FlairTemplateGeneratorBatch,
    startIndex = 0,
) {
    return batch.reduce<IndexedGenerateTemplateItemCollection>((result, item, index) => {
        const template: IndexedGenerateTemplateItem = {
            ...item,
            index: index + startIndex,
        };
        result[item.id] = template;
        return result;
    }, {});
}

export function GenerateTemplatesSearchRoot({
    items: initItems = {},
    children,
}: {
    items?: IndexedGenerateTemplateItemCollection,
    children: React.ReactNode,
}) {
    const [items, setItems] = React.useState<IndexedGenerateTemplateItemCollection>(initItems);
    const [semanticItems, setSemanticItems] = React.useState<IndexedGenerateTemplateItemCollection>({});
    const [searchString, setSearchString] = React.useState('');
    const [templatesGenerator, setTemplatesGenerator] = React.useState<FlairTemplateGenerator | undefined>();
    const [isSearching, setIsSearching] = React.useState(false);
    const prevSearchRef = React.useRef('');
    const batchSize = 24;

    React.useEffect(() => {
        const {
            backend,
        } = editorContextStore.getState();

        const generator = backend?.getGenerateTemplateGenerator({
            batchSize: batchSize,
        });

        setTemplatesGenerator(generator);

        generator?.getNextBatch()?.then((templates) => {
            setItems(postprocessTemplatesBatch(templates));
        });
    }, []);

    return (
        <GenerateTemplatesContext.Provider
            value={{
                items,
                setItems,
                templatesGenerator,
                setTemplatesGenerator,
                searchString,
                setSearchString: (value: string) => {
                    const search = cleanupPrompt(value);

                    if (search !== prevSearchRef.current && templatesGenerator) {
                        // Handle search logic
                        setIsSearching(true);

                        templatesGenerator.setConstraints({
                            searchString: search,
                        })?.then((typesenseBatch) => {
                            // Determine if we actually want to commit to this search string
                            setItems(
                                postprocessTemplatesBatch(typesenseBatch),
                            );

                            const idMap: { [id: string]: boolean } = {};
                            typesenseBatch.forEach(doc => {
                                idMap[doc.id] = true;
                            });

                            // append new items here from semantic search generator.
                            if (value !== "" && typesenseBatch.length < batchSize) {
                                const resultPromise = templatesGenerator.pineconeSearch(value, batchSize - typesenseBatch.length)
                                if (resultPromise !== undefined && resultPromise !== null) {
                                    resultPromise.then((batch) => {
                                        const newBatch: GenerateTemplateTypesenseDoc[] = [];

                                        batch.forEach(result => {
                                            if (!idMap[result.id]) {
                                                newBatch.push(result);
                                                idMap[result.id] = true;
                                            }
                                        });

                                        setSemanticItems(
                                            postprocessTemplatesBatch(newBatch),
                                        );
                                    });
                                }
                            }

                            setIsSearching(false);
                        });
                    }

                    prevSearchRef.current = search;
                    setSearchString(value);
                },
                isSearching,
                setIsSearching,
                semanticItems,
                setSemanticItems,
            }}
        >
            {children}
        </GenerateTemplatesContext.Provider>
    );
}

