import React, { ReactNode } from 'react';
import { useFeatureFlags } from 'hooks/use-featureFlags';
import { FeatureFlags } from 'core/controllers/featureFlags';

/**
 * How to use:
 *
 * When FeatureFlag is a boolean you can simply pass the key
 * <FeatureFlag flagKey='testFeature' >
 * 		<div> Test </div>
 * </FeatureFlag>
 *
 * FeatureFlags can have any values so you can pass a function as condition
 * <FeatureFlag flagKey='testFeature' condition={(flagValue) => flagValue === 0} >
 * 		<div> Test </div>
 * </FeatureFlag>
 *
 */

export type FeatureFlagProps<K extends keyof FeatureFlags> = {
    flagKey: K;
    condition?: FeatureFlags[K] | ((flagValue: FeatureFlags[K]) => boolean);
    not?: boolean;
    children: ReactNode;
};

export function FeatureFlag<K extends keyof FeatureFlags>({ flagKey, condition, children, not = false }: FeatureFlagProps<K>) {
    const isEnabled = useFeatureFlags(flagKey, condition);

    const shouldRender = not ? !isEnabled : isEnabled;

    if (!shouldRender) return null;

    return <>{children}</>;
};
