import { SimpleSpinner } from 'components/icons/simple-spinner';
import { editorContextStore } from 'contexts/editor-context';
import React from 'react';

export default function EditorLoading({
    message: inputMessage,
}: {
    message?: string | React.ReactNode,
}) {
    /**
     * Steps:
     * - Wait for the user to log in
     * - Wait for the document (not the asset) to be loaded
     */

    const [isUserReady, setIsUserReady] = React.useState(false);

    const user = editorContextStore(state => state.user);

    React.useEffect(() => {
        setIsUserReady(user?.uid != null);
    }, [user]);

    const message = React.useMemo(() => {
        if (inputMessage) {
            return inputMessage;
        }
        if (isUserReady) {
            return 'Downloading project data ...';
        } else {
            return 'Signing in ...';
        }
    }, [inputMessage, isUserReady]);

    return (
        <div className='w-screen h-screen flex flex-col justify-center items-center text-base text-zinc-500 bg-zinc-900'>
            <SimpleSpinner pathClassName='fill-lime-500'/>
            {message}
        </div>
    );
}