import React from 'react';
import { DropdownClassName, InputBoxClassName, PrimaryButtonClassName } from 'components/constants/class-names';
import { classNames } from 'core/utils/classname-utils';
import { Image, UploadCloud } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import styles from './custom-model.module.css';
import { Cross1Icon, Cross2Icon } from '@radix-ui/react-icons';
import { getMegabytesFromBytes } from 'core/utils/number-utils';
import { generateUUID } from 'core/utils/uuid-utils';
import { useFileDrop } from 'hooks/use-file-drop';
import { ProgressHandler } from 'components/utils/progress-handler';
import { clamp } from 'lodash';
import { isValidHttpsUrl } from 'core/utils/string-utils';
import { editorContextStore } from 'contexts/editor-context';
import { EditorAssetContentType, UiDisplayMessageEventHandler } from 'core/common/types';
import { FloatTagZIndex } from 'components/constants/zIndex';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { loadImageElementFromBlob, loadImageElementFromURL } from 'core/utils/image-loader';
import { resizeImageCanvasElement } from 'core/utils/image-utils';

export type UploadFileFunction = (params: { data: File | Blob, contentType: EditorAssetContentType }) => Promise<string | undefined>;

function FlexDivider() {
    return (
        <div className='flex-1 h-px bg-zinc-800'>
        </div>
    )
}

function OrDivider() {
    return (
        <div className='w-full my-4 flex flex-row items-center'>
            <FlexDivider />
            <span className='mx-6'>
                or
            </span>
            <FlexDivider />
        </div>
    );
}

function isValidImageContentType(type: any): type is EditorAssetContentType {
    return type === 'image/png' || type === 'image/jpeg' || type === 'image/webp';
}

async function uploadImageFile(
    image: File | Blob,
    onError: (message: string) => void,
    uploadFile: UploadFileFunction,
) {
    try {
        console.log(`Try upload image of type ${image.type}`)

        const contentType = image.type;
        if (!isValidImageContentType(contentType)) {
            onError(`Image of type ${contentType} is invalid`);
            return;
        }
        const path = await uploadFile({
            data: image,
            contentType,
        });
        return {
            path,
            size: image.size,
        };

    } catch (error) {
        console.error(error);
        onError((error as any)?.message || 'Unknown error');
    }
    console.error('Unknown upload errors');
}

async function uploadImageUrl(
    imageUrl: string,
    onError: (message: string) => void,
    uploadFile: UploadFileFunction,
) {
    try {
        if (!isValidHttpsUrl(imageUrl)) {
            onError(`Image url ${imageUrl.slice(0, 100)} is invalid`);
            return;
        }
        const { backend } = editorContextStore.getState();
        if (!backend) {
            onError?.('Backend is not initialized');
            return;
        }

        const r = await fetch(imageUrl);

        const contentType = r.headers.get("Content-Type");
        if (!isValidImageContentType(contentType)) {
            onError(`Image of type ${contentType} is invalid`);
            return;
        }

        const blob = await r.blob();

        const path = await uploadFile({
            data: blob,
            contentType,
        });

        return {
            path,
            size: blob.size,
        }
    } catch (error) {
        console.error(error);
        onError((error as any)?.message || 'Unknown error');
    }
}

// async function resizeAndUploadImage({
//     image,
//     uploadCallback,
//     maxLength = 1280,
// }: {
//     maxLength?: number,
//     image: string | File | Blob,
//     uploadCallback: UploadFileFunction,
// }): Promise<string | undefined> {
//     let imgElement: HTMLImageElement;
//     if (typeof image === 'string') {
//         imgElement = await loadImageElementFromURL(image);
//     } else {
//         imgElement = await loadImageElementFromBlob(image);
//     }

//     let width = imgElement.width;
//     let height = imgElement.height;

//     if (width > height) {
//         if (width > maxLength) {
//             height = Math.round((height * maxLength) / width);
//             width = maxLength;
//         }
//     } else {
//         if (height > maxLength) {
//             width = Math.round((width * maxLength) / height);
//             height = maxLength;
//         }
//     }

//     const canvas = document.createElement('canvas');
//     canvas.width = width;
//     canvas.height = height;

//     await resizeImageCanvasElement({
//         from: imgElement,
//         to: canvas,
//         width,
//         height,
//     });

//     const contentType = EditorAssetContentType.png;

//     const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, contentType, 1));

//     if (!blob) return undefined;

//     return uploadCallback({ data: blob, contentType });
// }


function uploadImage(
    image: string | File | Blob,
    onError: (message: string) => void,
    uploadFile: UploadFileFunction,
) {
    if (typeof (image) === 'string') {
        return uploadImageUrl(image, onError, uploadFile);
    }
    return uploadImageFile(image, onError, uploadFile);
}

type UploadedImageData = {
    id: string,
    name: string,
    sizeBytes: number,
    promise: Promise<unknown>,
    onDelete?: (id: string) => void,
};

function UploadedImageProgress({
    id,
    name,
    promise,
    sizeBytes,
    onDelete
}: UploadedImageData) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const progressHandler = new ProgressHandler({
            setProgress: (value) => setProgress(Math.round(clamp(value * 100, 0, 100))),
        });
        progressHandler.setProgress(0.01);
        promise.then(() => {
            progressHandler.setProgress(1);
        })
    }, [promise]);

    return (
        <div className='relative w-full p-4 mb-2 rounded-md flex flex-col border border-zinc-800 overflow-hidden'>
            <div className='w-full flex flex-row items-center'>
                <div className='p-2 rounded text-zinc-300'>
                    <Image size={18} />
                </div>
                <div className='h-full flex-1 flex flex-col px-2 pr-4'>
                    <div className='w-full flex flex-row items-center'>
                        <span className='max-w-[300px] flex-1 truncate font-semibold'>
                            {name}
                        </span>
                    </div>
                </div>
                <div className='flex flex-row items-center text-zinc-400 '>
                    <div className='flex-1 truncate'>
                        {sizeBytes > 0 ?
                            getMegabytesFromBytes(sizeBytes).toFixed(2) :
                            'Unknown '
                        }MB
                    </div>
                </div>
                <div className='flex flex-row items-center justify-self-end text-zinc-400 px-2'>
                    <Cross1Icon
                        className='text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors'
                        onClick={() => {
                            console.log('UploadedImageProgress - delete', {id, onDelete});

                            onDelete?.(id);
                        }}
                    />
                </div>
            </div>
            <div
                className='absolute left-0 bottom-0 w-full h-1 bg-zinc-800'
            >
                <div
                    className={`${styles.TransitionWidth} rounded-full h-full bg-lime-500`}
                    style={{
                        width: `${progress}%`,
                    }}
                />
            </div>
        </div>
    );
}

function handleUploadImage({
    image,
    onError,
    uploadFile,
}: {
    image: string | File | Blob,
    onError: (message: string) => void,
    uploadFile: UploadFileFunction,
}): UploadedImageData | null {
    const {
        backend,
        customModelId,
        eventEmitter,
    } = editorContextStore.getState();
    if (!backend || !customModelId) {
        onError('No valid model set in the backend. Please refresh the page.');
        return null;
    }
    const id = generateUUID();

    debugLog(`Start uploading image to model ${customModelId}`);

    const promise = uploadImage(
        image,
        onError,
        uploadFile,
    ).then(async (result) => {
        if (!result?.path) {
            return;
        }

        const {
            path,
        } = result;

        debugLog(`Start setting model ${customModelId} data item ${id}`);

        await backend.setCustomModelDataItem({
            modelId: customModelId,
            dataId: id,
            data: {
                id,
                storagePath: path,
            },
        });

        return result;
    }).catch((error) => {

        debugError(`Cannot upload image to model ${customModelId}: `, error);

        eventEmitter.emit<UiDisplayMessageEventHandler>(
            'ui:display-message',
            'error',
            'Cannot upload image, please try again later.',
        );

    });

    return {
        id,
        name: image instanceof File ? image.name : `image_${id.slice(0, 3)}`,
        sizeBytes: image instanceof Blob ? image.size : 0,
        promise,
    }
}

export function UploadImageForm({
    uploadFile,
    onDelete = () => {}
}: {
    uploadFile: UploadFileFunction,
    onDelete: (id: string) => void
}) {
    const [error, setError] = React.useState('');

    const [uploadImageUrl, setUploadImageUrl] = React.useState("");

    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedImageData[]>([]);

    const addFiles = React.useCallback((newFiles: FileList) => {
        const filesData: UploadedImageData[] = [];
        for (let i = 0; i < newFiles.length; ++i) {
            const file = newFiles[i];
            if (!file) {
                continue;
            }
            const data = handleUploadImage({
                image: file,
                onError: setError,
                uploadFile,
            });
            if (!data) {
                return;
            }
            filesData.push(data);
        }
        setUploadedFiles((files) => ([
            ...files,
            ...filesData,
        ]));
    }, [uploadFile]);

    const addDroppedFiles = React.useCallback((
        newFiles: FileList,
        e: React.DragEvent<HTMLDivElement>,
    ) => {
        addFiles(newFiles);
    }, [addFiles]);

    const {
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
    } = useFileDrop({
        handleDropFiles: addDroppedFiles,
    });

    return (
        <>
            <label
                htmlFor='upload-image-from-local'
                className='w-full'
            >
                <div
                    className='w-full py-16 flex flex-col items-center justify-center rounded-md border-2 border-dashed border-zinc-800 hover:text-zinc-100 hover:border-zinc-700 transition-colors cursor-pointer'
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                >
                    <UploadCloud className='mb-2' />
                    <div className='text-center'>
                        Drag and Drop or <br />
                        Choose <span className='text-lime-500'>file</span> to upload <br />
                        <span className='text-zinc-500'>
                            Only accept PNG or JPG files.
                        </span>
                    </div>
                </div>
            </label>
            <input
                type="file"
                multiple
                id="upload-image-from-local"
                style={{
                    display: 'none',
                }}
                onChange={(e) => {
                    const files = e.target.files;
                    if (!files) {
                        return;
                    }
                    addFiles(files);
                }}
            />
            <OrDivider />
            <label className='w-full'>
                Add images from URL
            </label>
            <div className='w-full my-2 flex flex-row items-center'>
                <input
                    type='url'
                    className={classNames(
                        InputBoxClassName,
                        'text-sm',
                    )}
                    placeholder="Add image URL"
                    value={uploadImageUrl}
                    onChange={(e) => {
                        setUploadImageUrl(e.currentTarget.value ?? "");
                    }}
                />
                <div className='w-2' />
                <button
                    className={PrimaryButtonClassName}
                    onClick={() => {
                        if (!uploadImageUrl) {
                            return;
                        }

                        const data = handleUploadImage({
                            image: uploadImageUrl,
                            onError: setError,
                            uploadFile,
                        });

                        if (!data) {
                            return;
                        }

                        setUploadedFiles((files) => ([
                            ...files,
                            data,
                        ]));
                    }}
                >
                    Add
                </button>
            </div>
            <div className='h-2' />
            <div
                className='flex-1 w-full my-2 overflow-x-hidden overflow-y-auto'
            >
                <div className='w-full flex flex-col text-sm'>
                    {uploadedFiles.map((file, index) => (
                        <UploadedImageProgress
                            key={file.id}
                            onDelete={() => {
                                uploadedFiles.splice(index, 1);
                                setUploadedFiles([...uploadedFiles]);
                                onDelete(file.id)
                            }}
                            {...file}
                        />
                    ))}
                </div>
            </div>
            {error && <div className='w-full truncate px-3 py-2 bg-red-800 border border-red-700 rounded-md text-red-200'>
                {error}
            </div>}
        </>
    )
}

async function uploadModelDatasetImage({
    data,
    modelId,
    contentType,
}: {
    data: File | Blob,
    modelId: string,
    contentType: EditorAssetContentType,
}) {
    try {
        const {
            backend,
        } = editorContextStore.getState();
        if (!backend) {
            return '';
        }

        return await backend.uploadCustomModelDataItemToStorage({
            data,
            modelId,
        });
    } catch (error) {
        console.error(`Cannot upload file to model ${modelId}: `, error);
    }
    return "";
}

export function UploadImageFormPopover({
    triggerProps,
    contentProps,
    children,
    modelId,
    onDelete = () => {},
    ...props
}: Dialog.DialogProps & {
    children?: React.ReactNode,
    triggerProps?: Dialog.DialogTriggerProps,
    contentProps?: Dialog.DialogContentProps,
    modelId: string,
    onDelete?: (id: string) => void,
}) {
    const [open, setOpen] = React.useState(false);

    const uploadFile = React.useCallback(({
        data,
        contentType,
    }: {
        data: File | Blob,
        contentType: EditorAssetContentType,
    }) => {
        return uploadModelDatasetImage({
            data,
            modelId,
            contentType,
        });
    }, [modelId]);

    return (
        <Dialog.Root
            {...props}
            open={open}
            onOpenChange={setOpen}
        >
            <Dialog.Trigger
                {...triggerProps}
            >
                {children}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay
                    className={styles.DialogOverlay}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                />
                <Dialog.Content
                    {...contentProps}
                    className={styles.DialogContent}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                >
                    <div
                        className={classNames(
                            DropdownClassName,
                            'min-w-[80vw] md:min-w-[500px] max-h-[90vh] px-6 py-5 flex flex-col items-center',
                        )}
                    >
                        <div className='w-full flex flex-row items-center mb-5'>
                            <span className='text-xl font-semibold'>
                                Add image
                            </span>
                            <div className='flex-1' />
                            <Dialog.Close asChild>
                                <Cross2Icon
                                    width={18}
                                    height={18}
                                    className="text-zinc-500 hover:text-zinc-200 cursor-pointer"
                                />
                            </Dialog.Close>
                        </div>
                        <UploadImageForm
                            uploadFile={uploadFile}
                            onDelete={onDelete}
                        />
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}