import React from 'react';
import { fabric } from 'fabric';
import { editorContextStore } from 'contexts/editor-context';
import { FloatTagButtonWithTooltip } from './float-tag-button';
import { BringFrontIcon, SendBackIcon } from 'components/icons/bring-front-icon';
import { Editor } from 'core/editor';
import { Box, Scaling } from 'lucide-react';
import { isStaticImageObject3d } from 'core/common/types/3d';
import { isActiveSelection, isGenerationFrame, isStaticImageObject, isStaticImageObjectGenerated } from 'core/utils/type-guards';
import { SetObjectEditImageProgressControllerEventHandler, StartUpscaleV2Job } from 'core/common/types';
import { EditorActiveObject } from 'core/common/interfaces';
import { EditImageProgressController, ObjectWithProgress } from 'components/panels/panel-items/edit/edit-image-process';
import { classNames } from 'core/utils/classname-utils';
import { debugLog } from 'core/utils/print-utilts';

function Divider() {
    return (
        <div className='w-px' />
    );
}

const ButtonClassName = "flex flex-row items-center justify-center text-xs gap-1 font-semibold"

const IconSize = 14;

function BringToFrontButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Bring to front"
            onClick={() => {
                editor?.objects.bringToFront();
            }}
        >
            <BringFrontIcon size={IconSize} className="text-zinc-500" />
            Front
        </FloatTagButtonWithTooltip>
    )
}

function SendToBackButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Send to back"
            onClick={() => {
                editor?.objects.sendToBack();
            }}
        >
            <SendBackIcon size={IconSize} className="text-zinc-500" />
            Back
        </FloatTagButtonWithTooltip>
    )
}

function UpscaleButton({
    editor,
    activeObject,
}: {
    editor: Editor | null,
    activeObject: EditorActiveObject,
}) {
    const [disabled, setDisabled] = React.useState(false);
    const [editImageController, setEditImageController] = React.useState<EditImageProgressController | undefined>();

    React.useEffect(() => {
        if (!editor) {
            setDisabled(true);
            return;
        }

        if (!activeObject || !isStaticImageObject(activeObject)) {
            setDisabled(true);
            return;
        }

        const handleSetController = ({
            object
        }: {
            object: fabric.StaticImage,
        }) => {
            if (!object || object.id !== activeObject.id || object.type !== activeObject.type) {
                return;
            }
            const controller = (object as any as ObjectWithProgress).editImageProgressController;
            if (!controller) {
                return;
            }
            setEditImageController(controller);
        }

        handleSetController({
            object: activeObject,
        });

        editor.on<SetObjectEditImageProgressControllerEventHandler>(
            'object:set-edit-image-progress-controller',
            handleSetController,
        );
        return () => {
            editor.off<SetObjectEditImageProgressControllerEventHandler>(
                'object:set-edit-image-progress-controller',
                handleSetController,
            );
        }
    }, [editor, activeObject]);

    React.useEffect(() => {
        if (editImageController?.isDestroyed === false) {
            setDisabled(true);

            const handleFinish = () => {
                setDisabled(false);
            };

            editImageController.on(EditImageProgressController.PROGRESS_FINISH_EVENT, handleFinish);
            editImageController.on(EditImageProgressController.DESTROY_EVENT, handleFinish);

            return () => {
                editImageController.off(EditImageProgressController.PROGRESS_FINISH_EVENT, handleFinish);
                editImageController.off(EditImageProgressController.DESTROY_EVENT, handleFinish);
            };
        } else {
            setDisabled(false);
        }
    }, [editImageController]);

    return (
        <FloatTagButtonWithTooltip
            className={classNames(
                ButtonClassName,
            )}
            disabled={disabled}
            tooltipChildren="Upscale the image to 2k resolution with details."
            onClick={() => {
                if (disabled) {
                    debugLog("Processing ...");
                    return;
                }

                setDisabled(true);

                editor?.state.setActiveLeftPanels((activeLeftPanels) => {
                    return [
                        ...activeLeftPanels,
                        'UpscaleV2',
                    ]
                });

                setTimeout(() => {
                    editor?.emit<StartUpscaleV2Job>('upscale-v2:start-job');
                }, 100);
            }}
        >
            <Scaling size={IconSize} className="text-zinc-500" />
            Upscale
        </FloatTagButtonWithTooltip>
    )
}

function Edit3dButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Rotate 3D prop"
            onClick={() => {
                editorContextStore.getState().setActiveLeftPanels((prevLeftPanels) => ([
                    ...prevLeftPanels,
                    'TransformProps3d',
                ]));
            }}
        >
            <Box size={IconSize} className="text-zinc-500" />
            3D
        </FloatTagButtonWithTooltip>
    )
}

function DefaultObjectFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
            />
        </>
    )
}

function GeneratedObjectFloatTag({
    editor,
    activeObject,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    activeObject: EditorActiveObject,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
            />
            <Divider/>
            <UpscaleButton
                editor={editor}
                activeObject={activeObject}
            />
        </>
    )
}

function Object3dFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <BringToFrontButton
                editor={editor}
            />
            <Divider />
            <SendToBackButton
                editor={editor}
            />
            <Edit3dButton
                editor={editor}
            />
        </>
    )
}

export function ObjectFloatTag({
    onComponentMount,
    onComponentUnmount,
}: {
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    const editor = editorContextStore(state => state.editor);
    const activeObject = editorContextStore(state => state.activeObject);

    if (!editor || !activeObject) {
        return null;
    }

    if (isGenerationFrame(activeObject as any)) {
        return null;
    }

    if (isActiveSelection(activeObject)) {
        return null;
    }

    if (isStaticImageObject3d(activeObject)) {
        return (
            <Object3dFloatTag
                editor={editor}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    if (isStaticImageObjectGenerated(activeObject)) {
        return (
            <GeneratedObjectFloatTag
                editor={editor}
                activeObject={activeObject}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    return (
        <DefaultObjectFloatTag
            editor={editor}
            onComponentMount={onComponentMount}
            onComponentUnmount={onComponentUnmount}
        />
    );
}