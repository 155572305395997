import React, { createElement, memo, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tooltip } from 'components/utils/tooltip';
import { classNames } from 'core/utils/classname-utils';
import { editorContextStore } from 'contexts/editor-context';
import { AppUserQuotas, UserProject, UserProjectType } from 'core/common/types';
import {
  getOrderedProjectTypes,
  SpecialProjectWorkflowNames,
  SpecialProjectTypes,
  SpecialProjectTypeImages,
  SpecialProjectTooltipTexts,
} from 'components/constants/UserProjectTypeStyles';
import { NEW_PROJECT, NEW_TRYON_PROJECT, CUSTOM_MODELS} from 'components/constants/routes';
import {
  useFeatureFlags
} from 'hooks/use-featureFlags';

import styles from './dashboard.module.css';
import { useCreateProjectEffect } from './dashboard-projects';

import {
  trackWorkflowAction
} from 'components/panels/panel-items/components/WorkflowCarousel';

import { debugError, debugLog } from "core/utils/print-utilts";
import { DashboardTitleClassName } from 'components/constants/class-names';

const projectGridClassName = `${styles.DashboardWorkflowGrid}`;
const cardClassName =
  'outline-none border border-zinc-800 hover:border-lime-600 shadow-sm hover:shadow-xl hover:shadow-lime-500/5 rounded-md overflow-hidden cursor-pointer text-zinc-500 hover:text-zinc-300 transition text-sm 2xl:text-base';
const cardInactiveClassName =
  'outline-none border border-zinc-800 shadow-sm rounded-md overflow-hidden cursor-pointer text-zinc-500 transition text-sm 2xl:text-base';

function CreateNewProjectWorkflowCard({
  onClick,
  disabled = false,
  userQuotas,
  projectType,
}: {
  onClick: () => void;
  disabled?: boolean;
  userQuotas?: AppUserQuotas | null;
  projectType?: SpecialProjectTypes;
}) {

  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      triggerChildren={
        <div
          id="create-new-project-workflow-card"
          className={classNames(
            disabled ? cardInactiveClassName : cardClassName,
            'flex flex-row items-center justify-start group transition-colors',
            disabled
              ? 'cursor-not-allowed text-zinc-500'
              : `cursor-pointer group-hover:border-neutral-700 group-hover:shadow-neutral-100`,
            'pb-[75%] relative overflow-hidden'
          )}
          onClick={disabled ? undefined : onClick}
        >
          <div
            className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
            style={{
              backgroundImage: projectType && SpecialProjectTypeImages[projectType] ? `url(${SpecialProjectTypeImages[projectType]})` : 'url(https://picsum.photos/seed/' + projectType + '/800)',
            }}
          ></div>
          <div
            className="absolute inset-0 pointer-events-none select-none bg-cover bg-center bg-black/30 transition-all duration-300 group-hover:bg-transparent"
          ></div>
          <div className={`absolute bottom-0 bg-gradient-to-t from-black to-transparent transition-all duration-300 group-hover:opacity-0 group-hover:pl-10 group-hover:from-transparent duration-300 w-full text-zinc-300 text-base font-bold pt-12 pb-4 px-4 overflow-hidden text-ellipsis text-left`}
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
            }}
          >
            {projectType ? SpecialProjectWorkflowNames[projectType] : ''}
          </div>
        </div>
      }
      contentProps={{
        align: 'start',
        sideOffset: 8,
      }}
      contentChildren={
        disabled
          ? `Max ${userQuotas?.maxNumProjects ?? 0} projects reached`
          : (projectType && SpecialProjectTooltipTexts[projectType]) || `Create ${projectType || 'product'} photography project.`
      }
    />
  );
}

export const DashboardWorkflows = memo(function DashboardWorkflows({ visible = true }: { visible?: boolean }) {
  const [projects, setProjects] = useState<Record<string, UserProject>>({});
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);
  const setDashboardType = editorContextStore(state => state.setDashboardType);
  const isWorkflowsEnabled = useFeatureFlags('Workflows');

  const navigate = useNavigate();

  const { canCreateNewProject } = useCreateProjectEffect();

  useEffect(() => {
    try {
      if (user) {
        return backend?.onUserProjectsUpdate(user.uid, setProjects);
      }
    } catch (error) {
      debugError(error);
    }
  }, [backend, user]);

  useEffect(() => {
    if (!isWorkflowsEnabled) {
      navigate('/');
    }
  }, [navigate, isWorkflowsEnabled])

  return (
    <div className={classNames(visible ? 'flex flex-col' : 'hidden', 'p-4 pt-0')}>
      <div
        className={classNames(
          DashboardTitleClassName,
          'my-4',
        )}
      >
        Choose Workflow Example
      </div>
      <div className={`${projectGridClassName} `}>
        {Object.values(getOrderedProjectTypes())
          .map((type, index) => {
            return (
              <CreateNewProjectWorkflowCard
                key={index}
                disabled={type !== UserProjectType.CustomModels && !canCreateNewProject}
                projectType={type as SpecialProjectTypes}
                onClick={() => {
                  if (type === UserProjectType.CustomModels) {
                    setDashboardType('models');

                    debugLog('Navigate to custom models dashboard type');

                    navigate(`/${CUSTOM_MODELS}`, { replace: true });
                  } else if (type === UserProjectType.Fashion) {
                    navigate(`/${NEW_TRYON_PROJECT}`, { replace: true });
                  } else {
                    navigate(`/${NEW_PROJECT}/${type}`, { replace: true });
                  }
                  trackWorkflowAction('WorkflowDashboardCardClick', {
                    workflow:type as SpecialProjectTypes
                  });
                }}
              />
            );
          })}
      </div>
    </div>
  );
});
