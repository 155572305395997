import { AnalyticsConfig } from 'analytics/config';
import { getNewProjectScene, getProjectRealTimeResultImageUrl } from 'components/constants/sample-projects';
import EditorLoading from 'components/editor/editor-loading';
import { editorContextStore } from 'contexts/editor-context';
import { useProjectInitSceneContext } from 'contexts/project-init-scene-context';
import { UserProjectType, getUserProjectType } from 'core/common/types';
import { isUserProjectQuotaValid } from 'core/utils/quota-utils';
import { generateUUID } from 'core/utils/uuid-utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { backendCreateNewProject } from './create-new-project-utils';
import { debugLog, debugWarn } from 'core/utils/print-utilts';
import { SpecialProjectUntitledNames, isSpecialProjectType} from 'components/constants/UserProjectTypeStyles';

function getTemporaryProjectId() {
    return `tmp_${generateUUID()}`;
}

export function isTemporaryProjectId(id: string) {
    return id?.slice?.(0, 4) === 'tmp_';
}

export function CreateNewProject() {
    const { projectType } = useParams();
    debugLog('CreateNewProject', {projectType});

    const user = editorContextStore(state => state.user);
    const backend = editorContextStore(state => state.backend);
    const projectId = editorContextStore(state => state.projectId);
    const displayNameRef = React.useRef<string>();
    const isProjectCreatedRef = React.useRef(false);
    const navigate = useNavigate();
    const [message, setMessage] = React.useState('Loading ...');
    const { setInitScene } = useProjectInitSceneContext();
    const setProjectDisplayName = editorContextStore(state => state.setProjectDisplayName);

    const handleCreateNewProject = React.useCallback((displayName: string) => {
        if (projectId != null && !isTemporaryProjectId(projectId)) {
            debugLog(`Project ${projectId} is already created, no need to create again`);
            return null;
        }
        if (isProjectCreatedRef.current || displayNameRef.current === displayName) {
            debugLog(`Project ${projectId} with name ${displayName} is already created, no need to create again`);
            return null;
        }
        displayNameRef.current = displayName;
        isProjectCreatedRef.current = true;

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.ProjectCreate,
            {
                projectId,
                name: displayName,
                reason: 'Page Open',
            }
        )

        return backendCreateNewProject({
            displayName,
            projectType: getUserProjectType(projectType) || UserProjectType.ProductPhotography,
        })
    }, [
        projectId, projectType
    ]);

    React.useEffect(() => {
        if (user?.uid && isProjectCreatedRef.current === false) {
            let displayName = 'Untitled Project';

            const checkedProjectType = getUserProjectType(projectType);
            if (checkedProjectType && isSpecialProjectType(checkedProjectType) && SpecialProjectUntitledNames[checkedProjectType]) {
                displayName = SpecialProjectUntitledNames[checkedProjectType];
            }

            debugLog(`Create new project ${displayName}`);

            handleCreateNewProject(displayName)?.then((res) => {
                if ((res as any)?.data?.code === 400) {
                    // User not found on the server, try again later
                    debugLog('User id is invalid, cannot create project.');
                    setMessage('Cannot find your projects. Signing you up ...');
                    debugWarn((res as any).data);

                    editorContextStore.getState().analytics.track(
                        AnalyticsConfig.ProjectCreateError,
                        {
                            name: displayName,
                            data: (res as any).data,
                            error: 'User id is invalid, cannot create project.',
                        }
                    );

                    backend?.onUserQuotasUpdate({
                        userId: user.uid,
                        onUpdate: (quotas) => {
                            if (isUserProjectQuotaValid(quotas)) {

                                debugLog('User registered in the database, try create the project again');

                                editorContextStore.getState().analytics.track(
                                    AnalyticsConfig.ProjectCreate,
                                    {
                                        name: displayName,
                                        quotas,
                                        reason: 'Quota Update',
                                    },
                                );

                                handleCreateNewProject(displayName);
                            }
                        }
                    });
                }
            });

            setMessage('Loading initial scene ...');

            const newProjectScene = getNewProjectScene(projectType as UserProjectType) ?? undefined;

            debugLog(`Init scene from project type ${projectType}:`, newProjectScene);

            setInitScene(newProjectScene);

            // Set the realtime image here
            const {
                setRealtimeColorCorrectImageUrl,
            } = editorContextStore.getState();

            setRealtimeColorCorrectImageUrl(
                getProjectRealTimeResultImageUrl(projectType as UserProjectType),
            );

            const randomProjectId = getTemporaryProjectId();
            // Redirect to the temporary project
            // TODO - update the project type
            let usedProjectType = UserProjectType.ProductPhotography;

            if (projectType && getUserProjectType(projectType)) {
                usedProjectType = getUserProjectType(projectType)!;
            }

            debugLog('new project', {usedProjectType});

            editorContextStore.getState().setProjectType(usedProjectType);
            navigate?.(
                `/projects/${randomProjectId}`,
                {
                    replace: true,
                    state: {
                        isTempProject: true,
                        displayName: displayNameRef.current,
                        projectType: usedProjectType,
                    },
                },
            );

            setProjectDisplayName(displayNameRef.current);

        } else if (isProjectCreatedRef.current) {
            debugLog('Project is already created.');
        }
    }, [user, backend, projectType, navigate, handleCreateNewProject, setInitScene]);

    return (
        <EditorLoading message={message} />
    );
}