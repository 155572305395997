import React from "react";
import { BottomPanelsList } from "./bottom-panels-list";
import { BottomPanelItem, setMobileBottomPanelStateAndHeight } from "./bottom-panel-item";
import { BottomPanelZIndex } from "components-mobile/constants/zIndex";
import { classNames } from "core/utils/classname-utils";
import { editorContextStore } from "contexts/editor-context";
import { MobileBottomPanelState } from "core/common/types/mobile";

export function BottomPanels() {
    const mobileBottomPanelState = editorContextStore(state => state.mobileBottomPanelState);

    React.useEffect(() => {


        if (mobileBottomPanelState === MobileBottomPanelState.Hide) {
            editorContextStore.getState()
                .setActiveLeftPanels([]);
        }

    }, [mobileBottomPanelState]);

    return (
        <div
            id="bottom-panels"
            className={classNames(
                "fixed top-0 flex flex-col justify-end pointer-events-none transition-colors",
                mobileBottomPanelState === MobileBottomPanelState.Full ?
                    "bg-zinc-950/50" : "bg-transparent",
            )}
            style={{
                height: '100svh',
                zIndex: BottomPanelZIndex,
            }}
        >
            <div
                className={classNames(
                    "flex-1",
                    mobileBottomPanelState === MobileBottomPanelState.Full ?
                        "pointer-events-auto" :
                        "pointer-events-none"
                )}
                onClick={() => {
                    if (mobileBottomPanelState !== MobileBottomPanelState.Full) {
                        return;
                    }

                    setMobileBottomPanelStateAndHeight(
                        MobileBottomPanelState.Hide,
                        0,
                    );
                }}
            />
            <BottomPanelItem
                className="pointer-events-auto"
            />
            <BottomPanelsList
                className="min-h-0 pointer-events-auto"
            />
        </div>
    )
}