import React from "react";

export function useFileDrop({
    handleDropFiles
}: {
    handleDropFiles: (files: FileList, e: React.DragEvent<HTMLDivElement>) => void
}) {
    const [isDragging, setIsDragging] = React.useState(false);

    const dragCounterRef = React.useRef(0);

    const handleDrag: React.DragEventHandler<HTMLDivElement> = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleDragIn: React.DragEventHandler<HTMLDivElement> = React.useCallback((e) => {
        e.preventDefault();
        dragCounterRef.current++
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true)
        }
    }, []);

    const handleDragOut: React.DragEventHandler<HTMLDivElement> = React.useCallback((e) => {
        e.preventDefault();
        dragCounterRef.current--
        if (dragCounterRef.current > 0) return;

        setIsDragging(false)
    }, []);

    const handleDrop: React.DragEventHandler<HTMLDivElement> = React.useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleDropFiles?.(e.dataTransfer.files, e)
            e.dataTransfer.clearData()
            dragCounterRef.current = 0
        }
    }, [handleDropFiles]);

    return {
        isDragging,
        setIsDragging,
        onDragEnter: handleDragIn,
        onDragLeave: handleDragOut,
        onDragOver: handleDrag,
        onDrop: handleDrop,
    };
}