import React from 'react';
import { QuestionMarkIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { classNames } from 'core/utils/classname-utils';
import { DropdownClassName } from 'components/constants/class-names';
import styles from './helper.module.css';
import { Tooltip } from 'components/utils/tooltip';
import { HelperWidgetZIndex } from 'components/constants/zIndex';
import { editorContextStore } from 'contexts/editor-context';

const HelperWidgetTrigger = React.forwardRef(({
    className,
    open,
    onOpenChange,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    open?: boolean,
    onOpenChange?: (value: boolean) => void,
}, forwardRef: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <Tooltip
            open={open}
            onOpenChange={onOpenChange}
            openDelay={300}
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={
                <div
                    {...props}
                    ref={forwardRef}
                    className="absolute bottom-[2rem] right-[2rem] rounded-full bg-black shadow-lg-center shadow-lime-500/20 p-3 text-zinc-300"
                    style={{
                        zIndex: HelperWidgetZIndex,
                    }}
                >
                    <QuestionMarkIcon />
                </div>
            }
            contentChildren="Help and resources"
            contentClassName='bg-black text-sm mx-2'
            contentProps={{
                side: 'left',
            }}
        />
    );
})

const DropdownItemClassName = 'w-full pl-4 pr-8 py-2 text-left text-zinc-300 font-normal hover:text-lime-500 select-none cursor-pointer transition-colors focus:outline-none focus:bg-zinc-800/30 focus-visible:outline-none';

function DropdownLink({
    className,
    children,
    ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
    return (
        <a
            {...props}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
                DropdownItemClassName,
                className ?? '',
            )}
        >
            {children}
        </a>
    )
}

export function HelperWidget() {
    const [open, setOpen] = React.useState(false);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    return (
        <Popover.Root
            open={open}
            onOpenChange={(value) => {
                setTooltipOpen(open => {
                    if (!value) {
                        return open;
                    }
                    if (!open) {
                        return open;
                    }
                    return false;
                });
                setOpen(value);
            }}
        >
            <Popover.Trigger
                asChild
            >
                <HelperWidgetTrigger
                    open={tooltipOpen}
                    onOpenChange={setTooltipOpen}
                />
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    sideOffset={6}
                    className={classNames(
                        DropdownClassName,
                        styles.PopoverContent,
                        'flex flex-col text-sm min-w-[180px] px-0 mx-2 bg-black'
                    )}
                    style={{
                        zIndex: HelperWidgetZIndex,
                    }}
                >
                    <DropdownLink
                        href="https://flair.ai/howitworks"
                    >
                        Getting started
                    </DropdownLink>
                    <DropdownLink
                        href="https://flair.ai/search"
                    >
                        Prompt library
                    </DropdownLink>
                    <button
                        className={DropdownItemClassName}
                        onClick={(e) => {
                            const {
                                backend,
                                setUserOnboardData,
                            } = editorContextStore.getState();
                            const newOnboardData = {
                                isProjectTutorialFinished: false,
                            };
                            setUserOnboardData(newOnboardData);
                            backend?.setUserOnboardData({
                                userOnboardData: newOnboardData,
                            });
                            setOpen(false);
                        }}
                    >
                        Restart onboarding
                    </button>
                    <DropdownLink
                        href="mailto:mickey@flair.ai"
                    >
                        Contact Customer Support
                    </DropdownLink>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

