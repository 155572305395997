export class ShortcutsUtils {
    static isCtrlKey(event: KeyboardEvent) {
        return event.ctrlKey || event.metaKey;
    }

    //delete
    static isDelete(event: KeyboardEvent) {
        return event.key === "Delete" || event.key === "Backspace"
    }

    // save or update template
    static isCtrlS(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyS"
    }

    // select all
    static isCtrlA(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyA"
    }

    // copy
    static isCtrlC(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyC"
    }

    // paste
    static isCtrlV(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyV"
    }
    // redo
    static isCtrlY(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyY"
    }

    // cut
    static isCtrlX(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyX"
    }

    static isCtrlE(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.code === "KeyE";
    }

    // nudge
    static isArrowUp(event: KeyboardEvent) {
        return event.code === "ArrowUp"
    }

    // nudge
    static isArrowDown(event: KeyboardEvent) {
        return event.code === "ArrowDown"
    }

    // nudge
    static isArrowLeft(event: KeyboardEvent) {
        return event.code === "ArrowLeft"
    }

    // nudge
    static isArrowRight(event: KeyboardEvent) {
        return event.code === "ArrowRight"
    }

    // modifier
    static isShift(event: KeyboardEvent) {
        return event.shiftKey
    }

    static isShiftOne(event: KeyboardEvent) {
        return event.shiftKey && event.code === "Digit1";
    }

    // lineHeight--
    static isAltDown(event: KeyboardEvent) {
        return event.altKey && event.code === "ArrowDown"
    }

    // lineHeight++
    static isAltUp(event: KeyboardEvent) {
        return event.altKey && event.code === "ArrowUp"
    }

    // charSpacing++
    static isAltRight(event: KeyboardEvent) {
        return event.altKey && event.code === "ArrowRight"
    }

    // charSpacing--
    static isAltLeft(event: KeyboardEvent) {
        return event.altKey && event.code === "ArrowLeft"
    }

    static isSquareBracketLeft(event: KeyboardEvent) {
        return event.code === 'BracketLeft';
    }

    static isSquareBracketRight(event: KeyboardEvent) {
        return event.code === 'BracketRight';
    }

    // redo
    static isCtrlShiftZ(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.shiftKey && event.code === "KeyZ"
    }

    // undo
    static isCtrlZ(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && !event.shiftKey && event.code === "KeyZ"
    }

    // zoom reset
    static isCtrlOne(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.key === "1"
    }

    // zoom in
    static isCtrlMinus(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.key === "-"
    }

    // zoom out
    static isCtrlEqual(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.key === "="
    }

    // zoom to fit
    static isCtrlZero(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.key === "0"
    }

    static isCtrlAltArrowUp(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.altKey && event.code === 'ArrowUp';
    }

    static isCtrlAltArrowDown(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlKey(event) && event.altKey && event.code === 'ArrowDown';
    }

    static isEscape(event: KeyboardEvent) {
        return event.code === 'Escape';
    }

    static isCtrlAltK(event: KeyboardEvent) {

        return ShortcutsUtils.isCtrlKey(event) && event.altKey && event.code === "KeyK";

    }
}