import { downloadImageDataUrl } from 'components/utils/data';
import {
    HtmlCanvasController,
    CanvasImageData,
    CanvasHollowRectData,
    HtmlCanvasControllerProps,
    Bounds,
    CanvasBrushStrokeHistory,
    getAffineTransformationMatrixForBounds,
    getBoundsFromCanvasImagePosition,
    Matrix2x3,
} from './html-canvas-controller';
import { editorContextStore } from 'contexts/editor-context';
import { UpscaleModelType } from 'core/common/types/upscale';
import { IShortcutsManager } from 'core/common/interfaces';
import { ShortcutsUtils } from 'core/utils/shortcuts-utils';
import { fabric } from "fabric"
import { getDataUrlFromImageElement } from 'core/utils/image-utils';
import { throttle } from 'lodash';
import { AppUserSubscriptionTier, AppUserSubscriptionTierV2, TryOnRenderClothImageEventHandler } from 'core/common/types';
import { FirebaseBackend } from 'backend/firebase/firebase-backend';
import { debugLog } from 'core/utils/print-utilts';
import { AnalyticsConfig } from 'analytics/config';
import { upscaleCreativeImageForFashionV2 } from 'components/panels/panel-items/edit/upscale-v2-utils';
import { WebRenderProcessController } from 'components/utils/render-process-controller';
import { ColorCorrectV2Stage } from 'core/common/types/color-correct-v2';

export class TryOnPersonCanvasHistory extends CanvasBrushStrokeHistory {

}

export class TryOnPersonCanvasController extends HtmlCanvasController {

    public isInitialized = false;

    private background: CanvasHollowRectData = {
        type: 'hollow-rect',
        strokeStyle: '#27272a',
        lineWidth: 1,
        x: 0,
        y: 0,
        width: 1000,
        height: 1000,
    };

    private personImageData?: CanvasImageData;

    private warpedClothImageData?: CanvasImageData;

    private clothCanvasContext?: CanvasRenderingContext2D;

    private unsubscribeToContextState?: () => void;

    historyRef: { current: TryOnPersonCanvasHistory };

    private tmpPoint0 = { x: 0, y: 0 };

    constructor({
        historyRef,
        ...props
    }: HtmlCanvasControllerProps & {
        historyRef: { current: TryOnPersonCanvasHistory }
    }) {
        super(props);

        this.historyRef = historyRef;

        this.updateBackground();
    }

    init() {
        if (this.isInitialized) {
            return;
        }

        this.isInitialized = true;

        this.center();

        this.dirty = true;

        const {
            editor,
            tryOnPersonImageElement,
            tryOnWarpedClothImageElement,
        } = editorContextStore.getState();

        if (tryOnPersonImageElement) {
            this.personImageData = this.getImageData(
                tryOnPersonImageElement,
            );
        }

        if (tryOnWarpedClothImageElement) {
            this.warpedClothImageData = this.getImageData(
                tryOnWarpedClothImageElement,
            );
        }

        const unsubscribePersonImageElement = editorContextStore.subscribe(
            state => state.tryOnPersonImageElement,
            (tryOnPersonImageElement) => {
                if (!tryOnPersonImageElement) {
                    this.personImageData = undefined;
                    return;
                }
                this.personImageData = this.getImageData(
                    tryOnPersonImageElement,
                )
                this.render();
            }
        );

        const unsubscribeWarpedClothImageElement = editorContextStore.subscribe(
            state => state.tryOnWarpedClothImageElement,
            (tryOnWarpedClothImageElement) => {
                if (!tryOnWarpedClothImageElement) {
                    this.warpedClothImageData = undefined;
                    return;
                }
                this.warpedClothImageData = this.getImageData(
                    tryOnWarpedClothImageElement,
                )
                this.render();
            }
        );

        const unsubscribeEditorState = editorContextStore.subscribe(
            state => state.tryOnEditorState,
            (tryOnEditorState) => {
                if (tryOnEditorState === 'warping') {
                    this.warpedClothImageData = undefined;
                    this.render();
                }
            }
        );

        const unsubscribeModelId = editorContextStore.subscribe(
            state => state.tryOnModelId,
            () => {
                // Clear all history
                this.history.reset();
            }
        );

        this.unsubscribeToContextState = () => {
            unsubscribePersonImageElement();
            unsubscribeWarpedClothImageElement();
            unsubscribeEditorState();
            unsubscribeModelId();
        }

        this.historyRef.current.renderBrushStrokes = this.renderBrushStrokes;

        editor?.on<TryOnRenderClothImageEventHandler>('tryon:render-cloth-image', this.renderClothImage);
    }

    destroy() {
        this.clearImages();
        this.unsubscribeToContextState?.();

        const {
            editor,
        } = editorContextStore.getState();
        editor?.off<TryOnRenderClothImageEventHandler>('tryon:render-cloth-image', this.renderClothImage);
    }

    setClothContext(ctx: CanvasRenderingContext2D) {
        this.clothCanvasContext = ctx;
    }

    updateBackground() {
        const { boundsWidth, boundsHeight } = this;

        this.background = {
            type: 'hollow-rect',
            strokeStyle: '#27272a',
            lineWidth: 1,
            x: 0,
            y: 0,
            width: boundsWidth,
            height: boundsHeight,
        }
    }

    setBounds(bounds: Bounds) {
        this.bounds = bounds;

        this.dirty = true;

        this.updateBackground();
    }

    renderImages(context?: CanvasRenderingContext2D | null) {

        const ctx = context || this.ctx;

        if (!ctx) {
            return;
        }

        const {
            tryOnPersonImageElement,
        } = editorContextStore.getState();

        if (this.personImageData && tryOnPersonImageElement) {

            HtmlCanvasController.drawObject({
                ctx,
                object: {
                    ...this.personImageData,
                    image: tryOnPersonImageElement,
                },
            });

        }

        this.renderWarpedClothImage();
    }

    renderWarpedClothImage() {
        const { clothCanvasContext, warpedClothImageData } = this;

        if (!clothCanvasContext) {
            return;
        }

        if (!warpedClothImageData) {
            return;
        }

        const {
            tryOnWarpedClothImageElement,
        } = editorContextStore.getState();

        if (!tryOnWarpedClothImageElement) {
            return;
        }


        HtmlCanvasController.drawObject({
            ctx: clothCanvasContext,
            object: {
                ...warpedClothImageData,
                image: tryOnWarpedClothImageElement,
                alpha: 1.0,
            }
        });

        this.history.undos.forEach((brushStroke) => {
            HtmlCanvasController.drawBrushStroke({
                ctx: clothCanvasContext,
                object: brushStroke,
            });
        });

        if (this.history.current) {
            HtmlCanvasController.drawBrushStroke({
                ctx: clothCanvasContext,
                object: this.history.current,
            });
        }
    }

    renderBrushStrokes = () => {

        if (!this.clothCanvasContext) {
            return;
        }

        const ctx = this.ctx;

        if (!ctx) {
            return;
        }

        this.canvasDefault();

        if (this.clothCanvasContext) {
            this.clothCanvasContext.clearRect(0, 0, this.clothCanvasContext.canvas.width, this.clothCanvasContext.canvas.height);
        }

        this.apply();

        this.renderWarpedClothImage();

        this.canvasDefault();
    }

    render() {
        const ctx = this.ctx;
        if (!ctx) {
            debugLog("Canvas context is invalid");
            return;
        }

        this.canvasDefault();

        this.clearCanvas();

        this.apply();

        HtmlCanvasController.drawHollowRect({
            ctx,
            object: this.background,
        });

        this.renderImages();

        this.canvasDefault();
    }

    clearCanvas() {
        if (!this.ctx) {
            return;
        }
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        if (this.clothCanvasContext) {
            this.clothCanvasContext.clearRect(0, 0, this.clothCanvasContext.canvas.width, this.clothCanvasContext.canvas.height);
        }
    }


    canvasDefault(): void {
        if (!this.ctx) {
            return;
        }

        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.clothCanvasContext?.setTransform(1, 0, 0, 1, 0, 0);
    }

    apply(): void {
        if (this.dirty) {
            this.update();
        }
        this.ctx?.setTransform(...this.matrix);
        this.clothCanvasContext?.setTransform(...this.matrix);
    }


    clearImages() {
        this.personImageData = undefined;
        this.warpedClothImageData = undefined;
        this.dirty = true;
    }

    getLocalCoordinateFromPixelCoordinate(point: {
        x: number,
        y: number,
    }): { x: number, y: number } {
        if (!this.clothCanvasContext) {
            return { x: 0, y: 0 };
        }
        const { x, y } = this.toWorld(point, this.tmpPoint0);
        return {
            x, y,
        };
    }

    get history() {
        return this.historyRef.current;
    }

    get currentBrushStroke() {
        return this.history.current;
    }

    startBrushStroke({
        point,
        brushType,
        color,
        lineWidth,
    }: {
        point: { x: number, y: number },
        brushType: 'erase',
        color: string,
        lineWidth: number,
    }) {
        if (!this.clothCanvasContext) {
            return;
        }
        point = this.getLocalCoordinateFromPixelCoordinate(point);
        this.history.save({
            type: 'brush-stroke',
            brushType,
            color,
            lineWidth: lineWidth / this.scale,
            points: [
                point.x,
                point.y,
            ],
        });

        this.emit('brush-stroke:start');
    }

    moveBrushStroke(point: { x: number, y: number }) {
        if (!this.clothCanvasContext) {
            return;
        }
        const { currentBrushStroke } = this;
        if (!currentBrushStroke) {
            return;
        }
        const brushStroke = currentBrushStroke;
        point = this.getLocalCoordinateFromPixelCoordinate(point);
        brushStroke.points.push(point.x);
        brushStroke.points.push(point.y);
    }

    endBrushStroke() {
        // this.moveBrushStroke(point);

        this.emit('brush-stroke:end');
    }

    renderWarpedImage() {
        const {
            tryOnWarpedClothImageElement,
        } = editorContextStore.getState();

        if (!tryOnWarpedClothImageElement) {
            return;
        }

        const {
            clothCanvasContext,
            warpedClothImageData,
        } = this;

        if (!clothCanvasContext || !warpedClothImageData) {
            return tryOnWarpedClothImageElement.src;
        }

        const tmpCanvas = fabric.util.createCanvasElement();
        tmpCanvas.width = tryOnWarpedClothImageElement.width;
        tmpCanvas.height = tryOnWarpedClothImageElement.height;


        const tmpCtx = tmpCanvas.getContext('2d');
        if (!tmpCtx) {
            return tryOnWarpedClothImageElement.src;
        }

        const {
            transformMatrix,
            boundsRelative,
        } = getAffineTransformationMatrixForBounds(
            getBoundsFromCanvasImagePosition(warpedClothImageData.target),
            clothCanvasContext.canvas.width,
            clothCanvasContext.canvas.height,
        );

        const prevMatrix: Matrix2x3 = [...this.matrix];

        this.matrix = transformMatrix;

        this.canvasDefault();

        this.clearCanvas();

        this.apply();

        this.renderImages();

        this.canvasDefault();

        // Save the render result

        const {
            left, right, top, bottom,
        } = boundsRelative;
        const width = right - left;
        const height = bottom - top;

        tmpCtx.drawImage(
            clothCanvasContext.canvas,
            left, top,
            width, height,
            0, 0,
            tmpCanvas.width, tmpCanvas.height,
        );

        const outputUrl = tmpCanvas.toDataURL();

        this.matrix = prevMatrix;

        this.render();

        return outputUrl;
    }

    async getCanvasDataUrl() {
        // Load mask image
        const {
            tryOnWarpedClothImageElement,
            tryOnWarpedHumanMaskImageElement,
        } = editorContextStore.getState();

        if (!tryOnWarpedClothImageElement || !tryOnWarpedHumanMaskImageElement) {
            return {};
        }

        let warpedClothImage = await getDataUrlFromImageElement({
            from: tryOnWarpedClothImageElement
        });

        if (this.history.numUndos() > 0) {
            warpedClothImage = this.renderWarpedImage() || warpedClothImage;
        }

        const warpedClothHumanMaskImage = await getDataUrlFromImageElement({
            from: tryOnWarpedHumanMaskImageElement,
        })

        return {
            warpedClothImage,
            warpedClothHumanMaskImage,
        }
    }

    async saveCanvas() {
        const {
            warpedClothImage,
            warpedClothHumanMaskImage,
        } = await this.getCanvasDataUrl();

        if (warpedClothImage) {
            debugLog(warpedClothImage);
            downloadImageDataUrl(
                warpedClothImage,
                "tryon-warped-cloth-image"
            ).catch(console.error);
        }

        if (warpedClothHumanMaskImage) {
            debugLog(warpedClothHumanMaskImage);
            downloadImageDataUrl(
                warpedClothHumanMaskImage,
                "tryon-cloth-human-mask-image"
            ).catch(console.error);
        }

    }

    renderClothImage = throttle(async () => {
        const context = editorContextStore.getState();
        const {
            editor,
            backend,
            analytics,
            userQuotas,
            tryOnEditorState,
            setTryOnEditorState,
            setTryOnRenderProgress,
            tryOnModelPrompt = 'a woman',
            tryOnClothPrompt = 'a shirt',
            tryOnBackgroundPrompt = 'in front of a city background',
            tryOnModelId,
            setTryOnRenderResults,
        } = context;

        try {

            if (!editor || !backend) {
                return;
            }

            if (tryOnEditorState !== 'idle') {
                return;
            }
            let stepStart = performance.now();
            const {
                warpedClothImage: warpedClothImageUrl,
                warpedClothHumanMaskImage: warpedHumanMaskImageUrl,
            } = await this.getCanvasDataUrl();
            // warpedClothImageUrl RGBA image with alpha = white if cloth, black everywhere else.
            // warpedHumanMaskImageUrl RGB image that's white in cloth area and also possibly arms, unclear

            if (!warpedClothImageUrl || !warpedHumanMaskImageUrl) {
                return;
            }

            setTryOnEditorState('rendering');

            setTryOnRenderProgress(0.1);

            if (!warpedClothImageUrl || !warpedHumanMaskImageUrl) {
                debugLog("Warped cloth and human mask is invalid");
                return;
            }

            const width = 768;
            const height = 1024;

            const prompt = `photo of ${tryOnModelPrompt} wearing ${tryOnClothPrompt} ${tryOnBackgroundPrompt}, soft shadows, highly detailed, award-winning fashion editorial`;
            const result = await backend.renderClothImages({
                'render_overview_prompt': prompt,
                'face_prompt': `photo of ${tryOnModelPrompt}, highly detailed skin, highly detailed hair`,
                'warped_cloth_image': warpedClothImageUrl,
                'warped_cloth_human_mask_image': warpedHumanMaskImageUrl,
                'target_width': width,
                'target_height': height,
                'num_inference_steps': 25,
                'model_image_id': tryOnModelId,
            });

            analytics.track(
                AnalyticsConfig.TryOnRenderSteps,
                {
                    step: 'RenderClothImages',
                    duration: performance.now() - stepStart,
                    gotResult: !!result,
                }
            );

            if (!result) {
                return;
            }
            debugLog("setting init tryon render results");
            setTryOnRenderResults([{
                imageUrl: result.image,
                width,
                height,
            }]);
            debugLog("set init tryon render results");

            // trying to upscale
            if (userQuotas?.tierV2 !== AppUserSubscriptionTierV2.Free || process.env.NODE_ENV === 'development') {
                debugLog("trying upscale...");
                stepStart = performance.now();
                setTryOnRenderProgress(.1);
                setTryOnEditorState('upscaling');

                const renderProcessController = new WebRenderProcessController();

                const upscaleResult = await upscaleCreativeImageForFashionV2({
                    backend,
                    editor,
                    imageUrl: result.image,
                    maskImageUrl: warpedClothImageUrl,
                    prompt,
                    renderProcessController,
                    stagesToRun: [
                        ColorCorrectV2Stage.Clarity,
                        ColorCorrectV2Stage.SmoothBlend,
                    ],
                    targetHeight: Math.round(width * 2),
                    targetWidth: Math.round(height * 2),
                });

                // const upscaleResult = await backend.upscaleImage({
                //     modelType: FirebaseBackend.getUpscaleImageModelType(
                //         UpscaleModelType.Premium,
                //         AppUserSubscriptionTier.Pro,
                //     ),
                //     userSubscriptionTier: AppUserSubscriptionTier.Pro, // Use appropriate subscription tier if known
                //     imageUrl: result.image, // Use the image URL from the render result
                //     inputImageUrl: warpedClothImageUrl, // alpha channel white where cloth, black everywhere else. and we upscale everything besides cloth.
                //     prompt: prompt,
                //     upscale: 2, // Scale factor for upscaling
                //     onError: (error) => console.error('Upscale error:', error), // Error handling
                // });

                debugLog("got upscaling result, setting tryon render result...");
                analytics.track(
                    AnalyticsConfig.TryOnRenderSteps,
                    {
                        step: 'UpscaleImage',
                        duration: performance.now() - stepStart,
                        gotResult: !!upscaleResult,
                    }
                );
                if (upscaleResult) {
                    setTryOnRenderResults([{
                        imageUrl: upscaleResult,
                        width: width * 2, // Adjust width for upscaled image
                        height: height * 2, // Adjust height for upscaled image
                    }]);
                } else {
                    console.error('Upscale result is invalid');
                }
                debugLog("done upscaling");
            }

        } catch (error) {
            console.error(error);
        }

        setTryOnRenderProgress(1);
        setTryOnEditorState('idle');
    }, 1000);

    isUndo(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlZ(event);
    }

    isRedo(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event);
    }

    isSave(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlS(event);
    }

    handleKeyDown = (event: KeyboardEvent) => {
        let isHandled = false;

        if (this.isUndo(event)) {
            this.historyRef.current?.undo();
            isHandled = true;
        } else if (this.isRedo(event)) {
            this.historyRef.current?.redo();
            isHandled = true;
        } else if (this.isSave(event)) {
            this.saveCanvas();
            isHandled = true;
        }
        return {
            isHandled,
        };
    }
}

export class TryOnPersonCanvasShortcutsManager implements IShortcutsManager {
    private controllerRef: { current?: TryOnPersonCanvasController };
    private historyRef: { current?: TryOnPersonCanvasHistory };

    constructor({
        controllerRef,
        historyRef,
    }: {
        controllerRef: { current?: TryOnPersonCanvasController };
        historyRef: { current?: TryOnPersonCanvasHistory };
    }) {
        this.controllerRef = controllerRef;
        this.historyRef = historyRef;
    }


    isUndo(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlZ(event);
    }

    isRedo(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event);
    }

    isSave(event: KeyboardEvent) {
        return ShortcutsUtils.isCtrlS(event);
    }

    handleKeyDown(event: KeyboardEvent) {
        let isHandled = false;
        if (this.isUndo(event)) {
            this.historyRef.current?.undo();
            isHandled = true;
        } else if (this.isRedo(event)) {
            this.historyRef.current?.redo();
            isHandled = true;
        } else if (this.isSave(event)) {
            this.controllerRef.current?.saveCanvas();
            isHandled = true;
        }
        return {
            isHandled,
        };
    }
}