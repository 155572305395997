import { SetObjectEditImageProgressControllerEventHandler } from 'core/common/types';
import { editorContextStore } from 'contexts/editor-context';
import { ProgressHandler } from "components/utils/progress-handler";
import EventEmitter from "events";
import { EditImageProcessType, UiDisplayMessageEventHandler } from "core/common/types"
import { fabric } from "fabric"
import { removeImageObjectBackground } from './remove-background';
import { upscaleImageObject } from './upscale';
import { magicEraseImageObject } from './magic-erase';
import { StartRenderJobArgs } from 'backend/base';
import { imageVariationImageObject } from './image-variations';
import { UpscaleModelType } from 'core/common/types/upscale';
import { UpscaleRenderProcessController, WebRenderProcessController } from 'components/utils/render-process-controller';
import { upscaleCreativeImageObjectV2 } from 'components/panels/panel-items/edit/upscale-v2-utils';

type RemoveBackgroundProps = {
    type: 'remove-background',
    object: fabric.StaticImage,
}

function removeBackground({
    object,
}: RemoveBackgroundProps) {
    const {
        editor,
        backend,
    } = editorContextStore.getState();
    if (!editor || !backend) {
        return;
    }
    const isStopped = { current: false };
    const removeBackgroundPromise = removeImageObjectBackground({
        object,
        editor,
        backend,
        onError: (e) => {
            editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', e.message);
        },
        isStopped,
    });
    return {
        stop: () => isStopped.current = true,
        promise: removeBackgroundPromise,
    };
}

type UpscaleImageInternalProps = {
    modelType: UpscaleModelType,
    object: fabric.StaticImage,
    upscale?: 2 | 4,
}

function upscaleImageInternal({
    modelType,
    object,
    upscale,
}: UpscaleImageInternalProps) {
    const {
        editor,
        backend,
    } = editorContextStore.getState();
    if (!editor || !backend) {
        return;
    }

    const renderProcessController = new UpscaleRenderProcessController();

    const upscaleImagePromise = upscaleImageObject({
        modelType,
        renderProcessController,
        object,
        editor,
        backend,
        onError: (e) => {
            editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', e.message);
        },
        upscale,
    });
    return {
        stop: () => renderProcessController.cancelJob(),
        promise: upscaleImagePromise,
    };
}

type UpscaleImageProps = {
    type: 'upscale',
    object: fabric.StaticImage,
    upscale?: 2 | 4,
}

function upscaleImage({
    object,
    upscale,
}: UpscaleImageProps) {
    return upscaleImageInternal({
        modelType: UpscaleModelType.Basic,
        object,
        upscale,
    });
}


type UpscaleImagePremiumProps = {
    type: 'upscale-premium',
    object: fabric.StaticImage,
    creativity?: number,
}

function upscaleImagePremiumInternal({
    object,
    creativity,
}: {
    object: fabric.StaticImage,
    creativity?: number,
}) {

    const {
        editor,
        backend,
    } = editorContextStore.getState();
    if (!editor || !backend) {
        return;
    }

    const renderProcessController = new WebRenderProcessController();

    const upscaleImagePromise = upscaleCreativeImageObjectV2({
        renderProcessController,
        object,
        editor,
        backend,
        creativity,
    });

    return {
        stop: () => renderProcessController.cancelJob(),
        promise: upscaleImagePromise,
    };

}

function upscaleImagePremium({
    object,
    creativity
}: UpscaleImagePremiumProps) {
    return upscaleImagePremiumInternal({
        object,
        creativity,
    });
}

type MagicEraseProps = {
    type: 'magic-erase',
    object: fabric.StaticImage,
    canvas: HTMLCanvasElement,
}

function magicErase({
    object,
    canvas,
}: MagicEraseProps) {
    const {
        editor,
    } = editorContextStore.getState();
    if (!editor) {
        return;
    }
    const isStopped = { current: false };
    const promise = magicEraseImageObject({
        object,
        canvas,
        onError: (e) => {
            editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', e.message);
        },
        isStopped: () => isStopped.current,
    });
    return {
        stop: () => isStopped.current = true,
        promise,
    }
}

type ImageVariationProps = {
    type: 'image-variations',
    object: fabric.StaticImage & ObjectWithProgress,
    renderArgs?: Partial<StartRenderJobArgs>,
    strength: number,
    onRenderError: (error: any, finished?: boolean) => void,
}

function imageVariation({
    object,
    strength,
    onRenderError,
}: ImageVariationProps) {
    console.log('Start image variations');
    const isStopped = { current: false };
    const promise = imageVariationImageObject({
        object,
        strength,
        onRenderError: (e) => {
            console.log('Error image variations');
            console.error(e);
        },
    });
    return {
        stop: () => isStopped.current = true,
        promise,
    }
}

type ProcessImageProps = RemoveBackgroundProps | UpscaleImageProps | UpscaleImagePremiumProps | MagicEraseProps | ImageVariationProps;

function processImage(props: ProcessImageProps) {
    if (props.type === 'remove-background') {
        return removeBackground(props);
    }
    if (props.type === 'upscale') {
        return upscaleImage(props);
    }
    if (props.type === 'upscale-premium') {
        return upscaleImagePremium(props);
    }
    if (props.type === 'magic-erase') {
        return magicErase(props);
    }
    if (props.type === 'image-variations') {
        return imageVariation(props);
    }
}

const editImageJobTypeToSpeed: Record<EditImageProcessType, number> = {
    'remove-background': ProgressHandler.DEFAULT_SPEED * 2,
    'image-variations': ProgressHandler.DEFAULT_SPEED,
    'magic-erase': ProgressHandler.DEFAULT_SPEED,
    'upscale': ProgressHandler.DEFAULT_SPEED * 0.5,
    'upscale-premium': ProgressHandler.DEFAULT_SPEED * 0.1,
    'generic-loading': ProgressHandler.DEFAULT_SPEED * 0.1,
}

type EditImageProgressControllerProps = ProcessImageProps;

export class EditImageProgressController extends EventEmitter {

    static PROGRESS_UPDATE_EVENT = 'progress-update'
    static PROGRESS_FINISH_EVENT = 'progress-finish'
    static DESTROY_EVENT = 'destroy'

    private _isDestroyed = false;

    private _type: EditImageProcessType | undefined;


    private _progress: number = 0;

    private progressHandler?: ProgressHandler;

    private stopProcess: (() => void) | undefined;

    constructor(props: EditImageProgressControllerProps) {
        super();
        this._type = props.type;

        const startProcessResult = processImage(props);

        if (!startProcessResult) {
            return;
        }

        const {
            promise,
            stop,
        } = startProcessResult;

        const speed = editImageJobTypeToSpeed[props.type] || ProgressHandler.DEFAULT_SPEED;

        this.progressHandler = new ProgressHandler({
            speed,
            setProgress: (value) => {
                this._progress = value;
                this.emit(EditImageProgressController.PROGRESS_UPDATE_EVENT, value);
            }
        });
        this.progressHandler.setProgress(0.01);
        promise.then(() => {
            if (this._isDestroyed) {
                return;
            }
            this.progressHandler?.setProgress(1);
            this.emit(EditImageProgressController.PROGRESS_FINISH_EVENT, true);
            setTimeout(() => {
                this.destroy();
            }, 300);
        });
        this.stopProcess = stop;
    }

    destroy() {
        this.stopProcess?.();
        this._isDestroyed = true;
        this._type = undefined;
        this.progressHandler?.reset();
        this.progressHandler?.setProgress(0);
        this.emit(EditImageProgressController.DESTROY_EVENT, true);
        this.removeAllListeners();
    }

    get type() {
        return this._type;
    }

    get progress() {
        if (this._isDestroyed) {
            return 0;
        }
        return this._progress;
    }

    set progress(value: number) {
        if (this._isDestroyed) {
            return;
        }
        this._progress = value;
        this.progressHandler?.setProgress(value);
        this.emit(EditImageProgressController.PROGRESS_UPDATE_EVENT, value);
    }

    get isDestroyed() {
        return this._isDestroyed;
    }

    subscribeToProgress(
        onProgressUpdate: (value: number) => void,
        onProgressFinish: () => void,
        onDestroy: () => void,
    ) {
        this.on(EditImageProgressController.PROGRESS_UPDATE_EVENT, onProgressUpdate);
        this.on(EditImageProgressController.PROGRESS_FINISH_EVENT, onProgressFinish);
        this.on(EditImageProgressController.DESTROY_EVENT, onDestroy);
        return () => {
            this.off(EditImageProgressController.PROGRESS_UPDATE_EVENT, onProgressUpdate);
            this.off(EditImageProgressController.PROGRESS_FINISH_EVENT, onProgressFinish);
            this.off(EditImageProgressController.DESTROY_EVENT, onDestroy);
        }
    }
}

export type ObjectWithProgress = {
    editImageProgressController?: EditImageProgressController;
}

export function createObjectEditImageProgressController(props: EditImageProgressControllerProps) {
    const object = props.object;

    const prevController = (object as ObjectWithProgress).editImageProgressController;
    // Make sure that there's no existing edit progress on the object
    if (prevController && prevController.isDestroyed === false) {
        return prevController;
    }

    (object as ObjectWithProgress).editImageProgressController = new EditImageProgressController(props);

    const editor = editorContextStore.getState().editor;
    if (editor) {
        editor.emit<SetObjectEditImageProgressControllerEventHandler>(
            'object:set-edit-image-progress-controller',
            {
                object,
            },
        );
    }

    return (object as ObjectWithProgress).editImageProgressController;
}