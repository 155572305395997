// Accessories workflow JSON
import { SampleProjectScene } from "core/common/scene";

export const AccessoriesProject: SampleProjectScene = {
  "metadata": {
    "animated": false
  },
  "objects": {
    "oHdYyDVMHishtt7aawltb": {
      "strokeWidth": 0,
      "metadata": {
        "subject": "sneaker",
        "imageType": "subject"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.74,
      "scaleY": 0.83,
      "top": -84.98,
      "angle": 0,
      "id": "oHdYyDVMHishtt7aawltb",
      "flipX": true,
      "flipY": false,
      "height": 948,
      "zIndex": 0,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": -43.42,
      "name": "StaticImage",
      "width": 948,
      "opacity": 1,
      "asset": {
        "path": "sampleProjects/assets/X1hinKiwj4tCjsMomlbO.png",
        "type": "image-storage"
      }
    }
  },
  "name": "Untitled Project",
  "generationFrame": {
    "top": -204.06000000000006,
    "left": -205.16000000000003,
    "referneceImage": {
      "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d7b044f9-0834-4a42-5cda-89939714f700/1024",
      "previewPath": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/da9de4b4-bd92-4821-fe12-b9295e51a800/256",
      "type": "image-url"
    },
    "width": 1024,
    "id": "generation-frame",
    "type": "GenerationFrame",
    "promptTemplate": {
      "words": [
        {
          "isAutoFilled": true,
          "prefix": "",
          "autocompleteType": "subject",
          "type": "input",
          "value": "sneaker",
          "valueBeforeAutoFill": "sneaker",
          "objectIds": [
            "oHdYyDVMHishtt7aawltb"
          ]
        },
        {
          "prefix": "on",
          "autocompleteType": "placement",
          "type": "input",
          "value": "white fluffy clouds in a blue room"
        }
      ]
    },
    "height": 1024
  },
  "id": "kr6LulphtX5pksNjWHQD",
  "version": "51",
  "frame": {
    "width": 0,
    "height": 0
  }
};