import { AnalyticsConfig } from 'analytics/config';
import { editorContextStore } from "contexts/editor-context";
import { Base } from "core/controllers/base";
import { ControllerOptions } from "../interfaces";
import { EditorEventHandler, TutorialFinishEventHandler, TutorialSkipEventHandler, TutorialStartEventHandler, UiDisplayMessageEventHandler } from "../types";


export class SegmentEventHandler extends Base {

    private events: EditorEventHandler[] = [];

    constructor(props: ControllerOptions) {
        super(props);

        this.on<TutorialStartEventHandler>(
            'tutorial:start',
            (config) => {
                editorContextStore.getState().analytics.track(
                    AnalyticsConfig.TutorialStart,
                    {
                        config,
                    }
                );
            }
        );

        this.on<UiDisplayMessageEventHandler>(
            'ui:display-message',
            (
                type,
                message,
                title,
            ) => {
                editorContextStore.getState().analytics.track(
                    AnalyticsConfig.MessageDisplay,
                    {
                        type,
                        message,
                        title,
                    }
                );
            }
        );

        this.on<TutorialFinishEventHandler>(
            'tutorial:finish',
            (step, config) => {

                editorContextStore.getState().analytics.track(
                    AnalyticsConfig.TutorialFinish,
                    {
                        step,
                        config,
                    }
                );

            }
        );

        this.on<TutorialSkipEventHandler>(
            'tutorial:skip',
            (step, config) => {

                editorContextStore.getState().analytics.track(
                    AnalyticsConfig.TutorialSkip,
                    {
                        step,
                        config,
                    }
                );

            }
        );
    }

    private getUid() {
        return editorContextStore.getState().user?.uid ?? '';
    }

    public destroy() {
        this.events.forEach(({ type, handler }) => {
            this.editor.off(type, handler);
        });
    }

    public on<T extends EditorEventHandler>(type: T['type'], handler: T['handler']) {
        // @ts-ignore
        this.events.push({ type, handler });
        return this.editor.on(type, handler);
    }
}