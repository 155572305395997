import { fabric } from "fabric";
import { Backend } from "backend/base";
import { debugError, debugLog } from "./print-utilts";
import { EditorAssetExtension, PastGeneration, PromptEditorEventHandler } from "core/common/types";
import { isDataURL, isValidFirebaseStoragePath, isValidHttpsUrl } from "./string-utils";
import { imageProcessingUtils } from "./image-processing-utils";
import { Assets } from "core/controllers/assets";
import { editorContextStore } from "contexts/editor-context";
import { addSceneJsonToCanvas } from "./scene-json-utils";
import { Editor } from "core/editor";
import { GenerateToolEditorState } from "core/common/types/generate-tool";
import { getTemplateFromPrompt, mergePromptTemplateSubjects } from "core/common/prompt-template";
import { cloneDeep } from "lodash";
import { getPromptFromTextContent } from "./text-utils";
import { SceneJSON, isSceneJSON } from "core/common/types/scene-json";
import { DEFAULT_CANVAS_LENGTH, defaultObjectOptions } from "core/common/constants";
import ObjectImporter from "./object-importer";
import { ILayer } from "core/common/layers";


async function getUrlFromStoragePath(
    backend: Backend,
    inputImagePath?: string,
) {
    if (!inputImagePath) {
        return;
    }

    try {
        if (isValidHttpsUrl(inputImagePath) || isDataURL(inputImagePath)) {

            return inputImagePath;

        }

        return await backend.getDownloadUrlFromStoragePath(
            inputImagePath,
        );

    } catch (error) {

        console.error(error);

    }
}

async function getMaskImageFromPastGenerationV1(
    backend: Backend,
    pastGeneration?: PastGeneration | null,
) {
    if (!backend || !pastGeneration) {
        debugLog("Past generation or the backend is invalid");
        return undefined;
    }

    const inputImagePath = pastGeneration.inputImagePath;
    const inputMaskImagePath = pastGeneration.inputMaskImagePath;

    const [
        inputImageUrl,
        inputMaskImageUrl,
    ] = await Promise.all([
        getUrlFromStoragePath(backend, inputImagePath),
        getUrlFromStoragePath(backend, inputMaskImagePath),
    ]);


    if (!inputImageUrl) {
        debugLog("Input image url is invalid.");
        return undefined;
    }

    if (!inputMaskImageUrl) {
        debugLog("Input mask image url is invalid.");
        return inputImageUrl;
    }

    return imageProcessingUtils.setAlphaMaskChannel({
        channelIndex: 0,
        colorImage: inputImageUrl,
        maskImage: inputMaskImageUrl,
    });
}

async function getMaskImageFromPastGenerationV2({
    editor,
    backend,
    pastGeneration,
    targetWidth,
    targetHeight,
}: {
    editor: Editor,
    backend: Backend,
    pastGeneration?: PastGeneration | null,
    targetWidth?: number,
    targetHeight?: number,
}) {
    if (!backend || !pastGeneration) {
        debugError("Past generation or the backend is invalid");
        return undefined;
    }

    const sceneJSON = await getSceneJsonFromPastGenerations({
        editor,
        pastGeneration,
    });

    if (!sceneJSON) {
        debugError('The input scene JSON is invalid.');
        return undefined;
    }

    const {
        objects,
        generationFrameBounds,
    } = sceneJSON;

    const objectImporter = new ObjectImporter(editor);

    const sceneObjects = (
        await Promise.all(Object.values(objects).map(async (layer) => {
            try {

                const object = await objectImporter.import(
                    layer as ILayer,
                    defaultObjectOptions as Required<ILayer>,
                );

                return object;

            } catch (error) {
                debugError(error);
            }
        }))
    ).filter(Boolean) as fabric.Object[];

    const {
        image: inputImageUrl, // composite image
        mask: inputMaskImageUrl, // composte mask image
    } = await editor.canvas.getDataURLsFromScene(
        {
            generationFrameBounds,
            sceneObjects,
            alwaysUseShapeControl: false,
            targetWidth,
            targetHeight,
        },
    );


    if (inputImageUrl && inputMaskImageUrl) {
        return imageProcessingUtils.setAlphaMaskChannel({
            channelIndex: 0,
            colorImage: inputImageUrl,
            maskImage: inputMaskImageUrl,
        });
    }

    return inputImageUrl;
}

export async function getMaskImageFromPastGeneration({
    editor,
    backend,
    pastGeneration,
    targetHeight,
    targetWidth,
}: {
    editor: Editor,
    backend: Backend,
    pastGeneration?: PastGeneration | null,
    targetWidth?: number,
    targetHeight?: number,
}) {
    try {
        if (!pastGeneration) {
            return undefined;
        }

        const {
            version,
        } = pastGeneration;

        if (version === 'v2') {
            return getMaskImageFromPastGenerationV2({
                editor,
                backend,
                pastGeneration,
                targetHeight,
                targetWidth,
            });
        }

        return getMaskImageFromPastGenerationV1(backend, pastGeneration);
    } catch (error) {

        console.error(error);

    }

    return undefined;
}

export async function getPastGenerationFromStaticImage(
    assets: Assets,
    object: fabric.StaticImage,
) {
    try {
        const generationId = object.generationId;

        if (!generationId) {
            return;
        }

        return await assets.getPastGeneration(generationId);
    } catch (error) {
        debugError(error);
    }
}

function applyPastGenarationsV1({
    editor,
    pastGeneration,
}: {
    editor: Editor,
    pastGeneration: PastGeneration,
}) {
    const promptTemplate = pastGeneration.promptTemplate || getTemplateFromPrompt(pastGeneration.prompt);

    const templateTo = cloneDeep(promptTemplate);

    editor.state.setGenerateToolPromptTemplate((templateFrom) => {
        if (templateFrom) {
            // Merge the subjects
            return mergePromptTemplateSubjects(templateFrom, templateTo);
        }
        return templateTo;
    });

    editor.emit<PromptEditorEventHandler>(
        'prompt-editor:set-state',
        getPromptFromTextContent(pastGeneration.prompt),
    );
}


async function getSceneJsonFromPastGenerations({
    editor,
    pastGeneration,
}: {
    editor: Editor,
    pastGeneration: PastGeneration,
}) {
    const sceneJsonPath = pastGeneration.sceneJsonPath;

    if (!sceneJsonPath) {
        return;
    }

    debugLog(`Start loading scene json from ${sceneJsonPath}`);

    const sceneJSONDownloadUrl = await editor.assets.loadAsset({
        path: sceneJsonPath,
    });

    if (!sceneJSONDownloadUrl) {
        return;
    }

    // Fetch the JSON file
    const response = await fetch(sceneJSONDownloadUrl);
    if (!response.ok) {
        throw new Error(`Failed to fetch file from ${sceneJSONDownloadUrl}: ${response.statusText}`);
    }

    // Parse the JSON
    const sceneJSON = await response.json();

    if (!isSceneJSON(sceneJSON)) {
        return;
    }

    return sceneJSON;
}

async function loadSceneJsonFromPastGenerations({
    editor,
    pastGeneration,
    sceneJSON,
}: {
    editor: Editor,
    pastGeneration: PastGeneration,
    sceneJSON?: SceneJSON,
}) {
    try {
        const generationFrame = editor.generationFrames.generationFrame;

        const {
            left = 0,
            top = 0,
            height = DEFAULT_CANVAS_LENGTH,
        } = generationFrame ?? {};

        sceneJSON = sceneJSON || await getSceneJsonFromPastGenerations({
            editor,
            pastGeneration,
        });

        if (!sceneJSON) {
            return;
        }

        // TODO: Check if the generation frame is empty
        const objectsInsideGenerationFrame = editor.generationFrames.getObjectsIntersectingGenerationFrame();
        const isGenerationFrameEmpty = objectsInsideGenerationFrame.length <= 0;

        await addSceneJsonToCanvas({
            editor,
            sceneJSON,
            targetGenerationFrameTopLeft: {
                top: isGenerationFrameEmpty ? top : top + height,
                left,
            },
        });
    } catch (error) {
        console.error(error);
    }
}

async function applyPastGenarationsV2({
    editor,
    pastGeneration,
    sceneJSON,
}: {
    editor: Editor,
    pastGeneration: PastGeneration,
    sceneJSON?: SceneJSON,
}) {
    const {
        setGenerateToolPromptTemplate,
        setGenerateToolReferenceImage,
    } = editor.state;

    debugLog('Load past generation: ', pastGeneration);

    const promptTemplate = pastGeneration.promptTemplate || getTemplateFromPrompt(pastGeneration.prompt);

    setGenerateToolPromptTemplate(promptTemplate);

    const { referenceImagePath } = pastGeneration;

    if (referenceImagePath) {

        setGenerateToolReferenceImage({
            type: (isValidHttpsUrl(referenceImagePath) || isDataURL(referenceImagePath)) ? 'image-url' : 'image-storage',
            path: referenceImagePath,
        });
    } else {
        setGenerateToolReferenceImage(undefined);
    }

    await loadSceneJsonFromPastGenerations({
        editor,
        sceneJSON,
        pastGeneration,
    });
}

export async function addPastGenerationToCanvas({
    pastGeneration,
    sceneJSON,
}: {
    pastGeneration: PastGeneration,
    sceneJSON?: SceneJSON,
}) {
    try {
        const editorState = editorContextStore.getState();

        const {
            editor,
        } = editorState;

        if (!editor) {
            return;
        }

        const { version } = pastGeneration;

        if (version === 'v2') {
            await applyPastGenarationsV2({
                editor,
                pastGeneration,
                sceneJSON,
            });
        } else {
            applyPastGenarationsV1({
                editor,
                pastGeneration,
            });
        }
    } catch (error) {
        console.error(error);
    }
}