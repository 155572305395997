export enum RenderPipelineType {
    Default = 'default',
    RefDefault = 'ref_default',
    Canny = 'canny',
    RefCanny = 'ref_canny',
    Hed = 'hed',
    RefHed = 'ref_hed',
    ColorHedInpaint = 'color_hed_inpaint',
    RefColorHedInpaint = 'ref_color_hed_inpaint',
}

const pipelineTypeWithReferenceImage = new Set([
    RenderPipelineType.RefDefault,
    RenderPipelineType.RefCanny,
    RenderPipelineType.RefHed,
    RenderPipelineType.RefColorHedInpaint,
])

export function doesPipelineTypeRequireReferenceImage(pipelineType: RenderPipelineType) {
    return pipelineTypeWithReferenceImage.has(pipelineType);
}

type ImageType = string;

export type ImageOverlayStrength = number | [number, number];

export interface ImageOverlayArgs {
    image: ImageType;
    mask_image: ImageType;
    overlay_strength: ImageOverlayStrength;
}

interface RefinerArgs {
    use_refiner?: boolean;
    refiner_prompt?: string;
    refiner_negative_prompt?: string;
    refiner_num_inference_steps?: number;
    refiner_guidance_scale?: number;
    refiner_strength?: number;
}



interface PostProcessingArgs {
    overlay_use_poisson?: boolean
    overlay_use_canny?: boolean
}

interface OverlayArgs {
    inpaint_start?: number;
    inpaint_finish?: number;
    denoising_start?: number;
    denoising_end?: number;
}

interface RefImageArgs {
    ref_image?: ImageType;
    ip_adapter_scale_start?: number;
    ip_adapter_scale_finish?: number;
    ip_adapter_start?: number;
    ip_adapter_finish?: number;
    ref_overlay_args?: OverlayArgs;
    ref_image_canny_strength?: number;
}

type ControlNetGuidanceScale = number | [number, number] | [number, number, number]


type ControlNetConditioningImageConfigItem = {
    image: ImageType,
    duration: number,
}

type ControlNetConditioningImageConfig = ControlNetConditioningImageConfigItem[]

interface CannyArgs {
    canny_image?: ImageType
    canny_base_image?: ImageType
    canny_image_config?: ControlNetConditioningImageConfig
    canny_controlnet_conditioning_scale?: number
    canny_control_guidance_start?: ControlNetGuidanceScale;
    canny_control_guidance_finish?: ControlNetGuidanceScale;
    canny_controlnet_conditioning_scale_start?: number;
    canny_controlnet_conditioning_scale_finish?: number;
}


interface HedArgs {
    hed_image?: ImageType;
    hed_image_config?: ControlNetConditioningImageConfig;
    hed_controlnet_conditioning_scale?: number;
    hed_control_guidance_start?: ControlNetGuidanceScale;
    hed_control_guidance_finish?: ControlNetGuidanceScale;
    hed_controlnet_conditioning_scale_start?: number;
    hed_controlnet_conditioning_scale_finish?: number;
}


interface ColorArgs {
    color_image?: ImageType;
    color_image_config?: ControlNetConditioningImageConfig
    color_controlnet_conditioning_scale?: number;
    color_control_guidance_start?: ControlNetGuidanceScale;
    color_control_guidance_finish?: ControlNetGuidanceScale;
    color_controlnet_conditioning_scale_start?: number;
    color_controlnet_conditioning_scale_finish?: number;
}


export interface RenderArgs extends RefinerArgs { // Assuming RefinerArgs is defined elsewhere
    num_images?: number,
    uid: string;
    prompt: string;
    prompt_2?: string;
    negative_prompt?: string;
    negative_prompt_2?: string;
    height?: number;
    width?: number;
    num_inference_steps?: number;
    guidance_scale?: number;
    guidance_rescale?: number;
    original_size?: [number, number];
    crops_coords_top_left?: [number, number];
    target_size?: [number, number];
    seed?: number;
    img2img_image?: ImageType;
    img2img_strength?: number;
    image_overlay_use_poisson?: boolean;
    image_overlay_use_canny?: boolean;
    image_overlay_strength?: ImageOverlayStrength;
    image_overlays?: ImageOverlayArgs[];
    apply_extra_poisson_blending_to_color_correction?: boolean,
}

export interface InpaintArgs {
    composite_image?: ImageType;
    composite_mask_image?: ImageType;
}

export interface DefaultRenderArgs extends RenderArgs {
    pipeline_type: RenderPipelineType.Default;
}

export interface RefDefaultRenderArgs extends RenderArgs, RefImageArgs {
    pipeline_type: RenderPipelineType.RefDefault;
}

export interface CannyRenderArgs extends RenderArgs, PostProcessingArgs, CannyArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.Canny;
}

export interface RefCannyRenderArgs extends RenderArgs, PostProcessingArgs, CannyArgs, RefImageArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.RefCanny;
}

export interface HedRenderArgs extends RenderArgs, PostProcessingArgs, HedArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.Hed;
}

export interface RefHedRenderArgs extends RenderArgs, PostProcessingArgs, HedArgs, RefImageArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.RefHed;
}



export interface ColorHedInpaintRenderArgs extends RenderArgs, PostProcessingArgs, ColorArgs, HedArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.ColorHedInpaint;
}

export interface RefColorHedInpaintRenderArgs extends RenderArgs, PostProcessingArgs, ColorArgs, HedArgs, RefImageArgs, InpaintArgs {
    pipeline_type: RenderPipelineType.RefColorHedInpaint;
}

export type RenderPipelineArgs = DefaultRenderArgs | RefDefaultRenderArgs | CannyRenderArgs | RefCannyRenderArgs | HedRenderArgs | RefHedRenderArgs | ColorHedInpaintRenderArgs | RefColorHedInpaintRenderArgs;

export const RenderPipelineArgImageKeysSet: Set<string> = new Set([
    'composite_image',
    'composite_mask_image',
    'ref_image',
    'hed_image',
    'color_image',
    'canny_image',
    'canny_base_image',
    'img2img_image',
]);

export interface RenderApiUrlParams {
    type: 'canny' | 'hed',
    tier: 'free' | 'pro',
    requiresReferenceImage: boolean,
}