import React from "react"
import { LeftPanelSectionContainer } from "./base"

export function Text() {
    return (
        <div
            className="flex flex-col"
        >
        </div>
    )
}