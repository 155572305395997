import { debugLog } from "./print-utilts";
import { AppUserQuotas, AppUserSubscriptionTierV2, CustomModelTrainingBackendType, CustomModelTrainingInput, CustomModelType, getDisplayNameFromId } from "core/common/types";
import { generateUUID } from "./uuid-utils";


export function canUserCreateCustomModel({
    userQuotas,
}: {
    userQuotas?: AppUserQuotas | null,
}) {
    if (!userQuotas) {

        debugLog('User quotas is not defined yet.');

        return false;
    }

    const {
        numCustomModels = 0,
        maxNumCustomModels = 1,
    } = userQuotas;

    return numCustomModels < maxNumCustomModels;
}

export function canUserStartTraining({
    userQuotas,
    input,
}: {
    userQuotas?: AppUserQuotas | null,
    input: CustomModelTrainingInput,
}) {
    if (!userQuotas) {

        debugLog('User quotas is not defined yet.');

        return false;
    }

    const {
        tierV2 = AppUserSubscriptionTierV2.Free,
        numCustomModelTrainingCreditsUsed = 0,
        maxNumCustomModelTrainingCreditsTotal = 1,
    } = userQuotas;

    const ok = numCustomModelTrainingCreditsUsed < maxNumCustomModelTrainingCreditsTotal;

    if (input.backendType === CustomModelTrainingBackendType.Fal && tierV2 === AppUserSubscriptionTierV2.Free) {
        return false;
    }

    return ok;
}

export function canUserStartPrediction({
    userQuotas,
}: {
    userQuotas?: AppUserQuotas | null,
}) {
    if (!userQuotas) {
        debugLog('User quotas is not defined yet.');

        return false;
    }

    const {
        numCustomModelPredictionCreditsUsed = 0,
        maxNumCustomModelPredictionCreditsTotal = 1,
    } = userQuotas;

    return numCustomModelPredictionCreditsUsed < maxNumCustomModelPredictionCreditsTotal;
}


export function getCustomModelDisplayName({
    modelId,
    customModelType = CustomModelType.Custom,
}: {
    modelId?: string,
    customModelType?: CustomModelType,
}) {
    modelId = modelId || generateUUID();
    return `${customModelType}-${getDisplayNameFromId(modelId)}`;
}