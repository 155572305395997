import React from "react";
import { Editor } from 'core/editor';
import { CanvasScrollBarZIndex } from "components/constants/zIndex";
import { editorContextStore } from "contexts/editor-context";
import { clamp } from "lodash";
import Zoom from "core/controllers/zoom";

const minScale = 100.0 / Zoom.maxZoom;
const maxScale = 100.0 / Zoom.minZoom;

function rescaleThumbScale(thumbScale: number) {
    return (thumbScale - minScale) / (maxScale - minScale);
}

function updateThumbScaleAndPosition(
    direction: 'x' | 'y',
    editor: Editor,
    canvasHeight: number,
    setThumbScale: (value: number) => void,
    setThumbTop: (value: number) => void,
    viewportHeightRef: { current: number },
    originalHeightRef: { current: number },
) {
    let originalHeight = canvasHeight;
    const viewportTransform = editor.zoom.viewportTransform;
    let zoomRatio = viewportTransform[3] ?? 1;

    const index = direction === 'x' ? 4 : 5;

    const translateY = viewportTransform[index];
    const thumbScale = clamp(
        rescaleThumbScale(1.0 / zoomRatio), 0.0, 1.0,
    );
    zoomRatio = 1.0 / thumbScale;
    const viewportHeight = originalHeight * thumbScale;

    if (translateY > canvasHeight * 0.5) {
        // Extend the canvas height above
        originalHeight = translateY * 2;
    } else if (translateY < canvasHeight * (-0.5) + zoomRatio * viewportHeight) {
        // Extend the canvas height below
        originalHeight = (translateY - zoomRatio * viewportHeight) * -2;
    }

    const offsetY = (originalHeight * 0.5 - translateY);

    setThumbScale(viewportHeight / originalHeight);
    setThumbTop(offsetY / originalHeight);

    viewportHeightRef.current = viewportHeight;
    originalHeightRef.current = originalHeight;
}


export function CanvasScrollBars() {
    const editor = editorContextStore(state => state.editor);
    const [thumbScaleX, setThumbScaleX] = React.useState(1);
    const [thumbScaleY, setThumbScaleY] = React.useState(1);
    const [thumbTop, setThumbTop] = React.useState(0);
    const [thumbLeft, setThumbLeft] = React.useState(0);
    const scrollbarThumbVerticalRef = React.useRef<HTMLDivElement | null>(null);
    const scrollbarContainerVerticalRef = React.useRef<HTMLDivElement | null>(null);
    const scrollbarThumbHorizontalRef = React.useRef<HTMLDivElement | null>(null);
    const scrollbarContainerHorizontalRef = React.useRef<HTMLDivElement | null>(null);
    const viewportHeightRef = React.useRef(1);
    const originalHeightRef = React.useRef(1);
    const viewportWidthRef = React.useRef(1);
    const originalWidthRef = React.useRef(1);
    const isPointerDownRef = React.useRef(false);
    const pointerInit = React.useRef({
        direction: 'y',
        x: 0,
        y: 0,
        left: 0,
        top: 0,
        thumbLeft: 0,
        thumbTop: 0,
    });

    React.useEffect(() => {
        const canvas = editor?.canvas.canvas;
        if (!editor || !canvas) {
            return;
        }

        const onBeforeRender = () => {
            if (isPointerDownRef.current) {
                return;
            }
            // Check if Zoom and Pan state has changed
            const canvasWidth = editor.canvas.canvas.getWidth();
            const canvasHeight = editor.canvas.canvas.getHeight();

            updateThumbScaleAndPosition(
                'y',
                editor,
                canvasHeight,
                setThumbScaleY,
                setThumbTop,
                viewportHeightRef,
                originalHeightRef,
            );

            updateThumbScaleAndPosition(
                'x',
                editor,
                canvasWidth,
                setThumbScaleX,
                setThumbLeft,
                viewportWidthRef,
                originalWidthRef,
            );
        }

        const handlePointerMove = (e: PointerEvent) => {
            if (!isPointerDownRef.current || !editor || !scrollbarContainerVerticalRef.current || !scrollbarContainerHorizontalRef.current || !scrollbarThumbVerticalRef.current || !scrollbarThumbHorizontalRef.current) {
                return;
            }
            const viewportTransform = editor.canvas.canvas.viewportTransform;
            if (!viewportTransform) {
                return;
            }
            if (pointerInit.current.direction === 'x') {
                const dx = e.clientX - pointerInit.current.x;
                const { width } = scrollbarContainerHorizontalRef.current.getBoundingClientRect();
                const dxRatio = (dx / width);
                const left = dxRatio * originalWidthRef.current;
                viewportTransform[4] = pointerInit.current.left - left;
                editor.zoom.setCanvasViewportTransform(viewportTransform);
                const thumbLeft = pointerInit.current.thumbLeft + dxRatio;
                scrollbarThumbHorizontalRef.current.style.left = 100 * thumbLeft + '%';
            } else {
                const dy = e.clientY - pointerInit.current.y;
                const { height } = scrollbarContainerVerticalRef.current.getBoundingClientRect();
                const dyRatio = (dy / height);
                const top = dyRatio * originalHeightRef.current;
                viewportTransform[5] = pointerInit.current.top - top;
                editor.zoom.setCanvasViewportTransform(viewportTransform);
                const thumbTop = pointerInit.current.thumbTop + dyRatio;
                scrollbarThumbVerticalRef.current.style.top = 100 * thumbTop + '%';
            }
        }

        const handlePointerUp = () => {
            isPointerDownRef.current = false;
        }

        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);

        canvas.on('before:render', onBeforeRender);
        return () => {
            canvas.off('before:render', onBeforeRender);
            window.removeEventListener('pointermove', handlePointerMove);
            window.removeEventListener('pointerup', handlePointerUp);
        }
    }, [editor]);

    return (
        <>
            <div
                className="absolute flex flex-row items-center h-2 w-full bottom-0 pointer-events-none mb-1"
            >
                <div
                    ref={scrollbarContainerHorizontalRef}
                    className="relative flex-1 h-full mx-3"
                    style={{
                        zIndex: CanvasScrollBarZIndex,
                    }}
                >
                    <div
                        ref={scrollbarThumbHorizontalRef}
                        className="absolute h-[80%] rounded-full bg-zinc-500/30 hover:bg-zinc-500 pointer-events-auto transition-colors"
                        style={{
                            left: thumbLeft * 100 + "%",
                            width: thumbScaleX * 100 + "%",
                        }}
                        onPointerDown={(e) => {
                            if (!editor) {
                                return;
                            }
                            const viewportTransform = editor.canvas.canvas.viewportTransform;
                            if (!viewportTransform) {
                                return;
                            }
                            isPointerDownRef.current = true;
                            pointerInit.current.direction = 'x';
                            pointerInit.current.x = e.clientX;
                            pointerInit.current.y = e.clientY;
                            pointerInit.current.left = viewportTransform[4];
                            pointerInit.current.top = viewportTransform[5];
                            pointerInit.current.thumbLeft = thumbLeft;
                            pointerInit.current.thumbTop = thumbTop;
                        }}
                    />
                </div>
            </div>
            <div
                className="absolute flex flex-col items-center w-2 h-full right-0 pointer-events-none mr-1"
            >
                <div
                    ref={scrollbarContainerVerticalRef}
                    className="relative flex-1 w-full my-3"
                    style={{
                        zIndex: CanvasScrollBarZIndex,
                    }}
                >
                    <div
                        ref={scrollbarThumbVerticalRef}
                        className="absolute w-[80%] rounded-full bg-zinc-500/30 hover:bg-zinc-500 pointer-events-auto transition-colors"
                        style={{
                            top: thumbTop * 100 + "%",
                            height: thumbScaleY * 100 + '%',
                        }}
                        onPointerDown={(e) => {
                            if (!editor) {
                                return;
                            }
                            const viewportTransform = editor.canvas.canvas.viewportTransform;
                            if (!viewportTransform) {
                                return;
                            }
                            isPointerDownRef.current = true;
                            pointerInit.current.direction = 'y';
                            pointerInit.current.x = e.clientX;
                            pointerInit.current.y = e.clientY;
                            pointerInit.current.left = viewportTransform[4];
                            pointerInit.current.top = viewportTransform[5];
                            pointerInit.current.thumbLeft = thumbLeft;
                            pointerInit.current.thumbTop = thumbTop;
                        }}
                    />
                </div>
            </div>
        </>
    )
}
