import React from "react"
import { editorContextStore } from "contexts/editor-context";
import { ProgressBar } from "../components/progress-bar";
import { AppUserSubscriptionTier, TriggerStartGenerationRenderEventHandler, UiDisplayMessageDialogEventHandler } from "core/common/types";
import { classNames } from "core/utils/classname-utils";
import {
    handleRenderFrontend,
} from "components-mobile/utils/render";
import { Tooltip } from "components-mobile/utils/tooltip";
import { PrimaryButtonClassNameDisabled } from "components-mobile/constants/class-names";
import { Pause } from "lucide-react";
import { RealTimeRenderMode } from "core/common/types/realtime-render";

export function RenderButton({
    className,
    isGenerateError,
    setIsGenerateError,
}: {
    className?: string,
    isGenerateError: boolean,
    setIsGenerateError: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    const editor = editorContextStore(state => state.editor);
    const generateToolIsRendering = editorContextStore(state => state.generateToolIsRendering);
    const generateToolRenderProgress = editorContextStore(state => state.generateToolRenderProgress);

    const onClickRenderButton = React.useCallback(() => {
        const {
            setGenerateToolIsRendering,
            setGenerateToolRenderJobId,
            setGenerateToolRenderProgress,
        } = editorContextStore.getState();

        handleRenderFrontend({
            onRenderError: () => {
                setIsGenerateError(true);

                setTimeout(() => {
                    setIsGenerateError(false);
                    setGenerateToolIsRendering(false);
                    setGenerateToolRenderJobId(null);
                    setGenerateToolRenderProgress(0);
                }, 1000);

                return true;
            },
        });
    }, [
        setIsGenerateError,
    ]);

    React.useEffect(() => {
        if (!editor) {
            return;
        }

        if (generateToolIsRendering) {
            return;
        }

        editor.on<TriggerStartGenerationRenderEventHandler>(
            'generation:trigger-start',
            onClickRenderButton,
        );

        return () => {
            editor.off<TriggerStartGenerationRenderEventHandler>(
                'generation:trigger-start',
                onClickRenderButton,
            );
        }
    }, [editor, generateToolIsRendering, onClickRenderButton]);

    return (
        generateToolIsRendering ?
            <ProgressBar
                className={classNames(
                    "w-full pointer-events-auto",
                    className ?? '',
                )}
                cancellable
                progress={generateToolRenderProgress}
                isError={isGenerateError}
                onClick={() => {
                    const {
                        setGenerateToolRenderProcessController,
                        setGenerateToolIsRendering,
                        setGenerateToolRenderProgress,
                        generateToolRenderProcessController,
                    } = editorContextStore.getState();

                    generateToolRenderProcessController?.abort();

                    setGenerateToolRenderProgress(0);

                    setTimeout(() => {
                        setGenerateToolIsRendering(false);
                        setGenerateToolRenderProcessController(undefined);
                    }, 500);
                }}
            /> :
            <button
                id="generate-button"
                className={classNames(
                    "relative px-3 py-2 rounded-md bg-lime-500 hover:bg-lime-400 active:bg-lime-600 text-center text-zinc-900 font-semibold transition-colors pointer-events-auto cursor-pointer",
                    className ?? "",
                )}
                onClick={onClickRenderButton}
            >
                Generate
            </button>
    )
}

function RenderButtonQuotaLimit() {
    return (
        <button
            className="box-border w-full px-3 py-2 rounded-md bg-lime-500 hover:bg-lime-400 active:bg-lime-600 text-center text-zinc-900 font-semibold transition-colors pointer-events-auto cursor-pointer"
            onClick={() => {
                editorContextStore.getState().editor?.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'quota-subscribe',
                    { message: "No credit left. Please subscribe to get unlimited credits." },
                );
            }}
        >
            Generate
            <span className="ml-2 px-2 py-1 rounded-md bg-lime-600/50 text-lime-200">
                No Credit
            </span>
        </button>
    )
}

export function LeftPanelRenderButton() {
    const [isGenerateError, setIsGenerateError] = React.useState(false);
    const userQuotas = editorContextStore(state => state.userQuotas);

    const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
    const numRenders = userQuotas?.numRenders || 0;
    const maxNumRenders = userQuotas?.maxNumRenders || 0;
    const isRenderAvailable = numRenders < maxNumRenders || tier !== AppUserSubscriptionTier.Free;

    return isRenderAvailable ?
        <RenderButton
            className="w-full shadow-md box-border"
            isGenerateError={isGenerateError}
            setIsGenerateError={setIsGenerateError}
        /> :
        <RenderButtonQuotaLimit />
        ;
}