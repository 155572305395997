import type { Bounds } from "./html-canvas-controller";
import { TryOnClothBrushStrokesHistory, TryOnClothCanvasController } from "./tryon-cloth-canvas-controller";
import { TryOnPersonCanvasController, TryOnPersonCanvasHistory } from "./tryon-person-canvas-controller";


type RefObject<T> = {
    current: T
};

export type TryOnEditorControllerProps = {
    bounds: Bounds,
}

export class TryOnEditorController {
    tryOnClothBrushStrokesHistoryRef: RefObject<TryOnClothBrushStrokesHistory>;

    tryOnClothCanvasControllerRef: RefObject<TryOnClothCanvasController>;

    tryOnPersonCanvasHistoryRef: RefObject<TryOnPersonCanvasHistory>;

    tryOnPersonCanvasControllerRef: RefObject<TryOnPersonCanvasController>;

    constructor({
        bounds,
    }: TryOnEditorControllerProps) {
        this.tryOnClothBrushStrokesHistoryRef = {
            current: new TryOnClothBrushStrokesHistory(),
        };

        this.tryOnClothCanvasControllerRef = {
            current: new TryOnClothCanvasController({
                bounds,
                historyRef: this.tryOnClothBrushStrokesHistoryRef,
            }),
        };

        this.tryOnPersonCanvasHistoryRef = {
            current: new TryOnPersonCanvasHistory(),
        };

        this.tryOnPersonCanvasControllerRef = {
            current: new TryOnPersonCanvasController({
                bounds,
                historyRef: this.tryOnPersonCanvasHistoryRef,
            })
        };
    }

    destroy() {
        this.tryOnClothBrushStrokesHistoryRef.current?.destroy();
        this.tryOnPersonCanvasHistoryRef.current?.destroy();
        this.tryOnClothCanvasControllerRef.current?.destroy();
        this.tryOnPersonCanvasControllerRef.current?.destroy();
    }
}