import { ApiInputType } from "core/common/types/api";
import { OptionsInput, OptionsInputProps } from "./options-input";
import { SliderInput, SliderInputProps } from "./slider-input";
import { NumberInput, NumberInputProps, TextInput, TextInputProps } from "./text-input";
import { getObjectEntries } from "core/utils/type-utils";
import { ImageInput, ImageInputProps } from "./image-input";



export type ApiInputProps = TextInputProps | NumberInputProps | OptionsInputProps | SliderInputProps | ImageInputProps;

export type ApiConfig = Record<string, ApiInputProps>

export type ApiStateOfApiConfig<T extends ApiConfig> = {
    [key in keyof T]: T[key]['value']
}

export function getApiStateFromApiConfig<T extends ApiConfig>(
    apiConfig: T,
): ApiStateOfApiConfig<T> {
    return getObjectEntries(
        apiConfig
    ).reduce((prevState, [key, { value }]) => {
        prevState[key] = value;
        return prevState;
    }, {} as ApiStateOfApiConfig<T>);
}

export function ApiInput(props: ApiInputProps) {
    if (props.type === ApiInputType.Text) {
        return (
            <TextInput
                {...props}
            />
        )
    } else if (props.type === ApiInputType.Number) {
        return (
            <NumberInput
                {...props}
            />
        );
    } else if (props.type === ApiInputType.Options) {
        return (
            <OptionsInput
                {...props}
            />
        );
    } else if (props.type === ApiInputType.Slider) {
        return (
            <SliderInput
                {...props}
            />
        );
    } else if (props.type === ApiInputType.Image) {
        return (
            <ImageInput
                {...props}
            />
        );
    }
    return null;
}