import React from "react";
import { useLeftPanelState } from "hooks/use-left-panel-state";
import { isPanelItemCustomized, panelFixedBottomItems, panelFixedTopItems, panelItems } from "./panel-items";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { LeftPanelItemType } from "components/constants/editor-options";
import { editorContextStore } from "contexts/editor-context";
import { LeftPanelFixedTopZIndex, BottomPanelZIndex } from "components-mobile/constants/zIndex";
import { getObjectEntries } from "core/utils/type-utils";
import bottomPanelItemStyles from './bottom-panel-item.module.css';
import { classNames } from "core/utils/classname-utils";
import { mergeRefs } from "components/utils/merge-refs";
import { MobileBottomPanelState } from "core/common/types/mobile";

const GrabIcon = React.forwardRef(function GrabIcon(
    props: React.SVGProps<SVGSVGElement>,
    forwardedRef: React.LegacyRef<SVGSVGElement>,
) {
    return (
        <svg
            {...props}
            ref={forwardedRef}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="6" y1="6" x2="18" y2="6" />
        </svg>
    );
})

const resizeTransitionDurationSeconds = 0.25;
const resizeTransitionDurationStyle = `${resizeTransitionDurationSeconds}s`;

const bottomPanelStateToHeightRatio: Record<MobileBottomPanelState, number> = {
    [MobileBottomPanelState.Full]: 0.7,
    // [MobileBottomPanelState.Half]: 0.4,
    [MobileBottomPanelState.Hide]: 0.0,
}

function getClosestBottomPanelState(target: number) {
    const stateRef = { current: MobileBottomPanelState.Full };

    const height = getObjectEntries(bottomPanelStateToHeightRatio)
        .reduce((closest, [state, num]) => {
            num *= window.innerHeight;

            if (Math.abs(num - target) < Math.abs(closest - target)) {

                stateRef.current = state;

                return num;
            } else {

                return closest;

            }
        }, bottomPanelStateToHeightRatio[
        MobileBottomPanelState.Full
        ] * window.innerHeight);

    return {
        state: stateRef.current,
        height,
    };
}

export function setMobileBottomPanelStateAndHeight(
    state: MobileBottomPanelState,
    height: number,
) {
    const {
        setMobileBottomPanelState,
    } = editorContextStore.getState();

    setTimeout(() => {
        setMobileBottomPanelState(
            state,
        );
    }, resizeTransitionDurationSeconds * 1000);

    setTimeout(() => {
        editorContextStore.getState()
            .setMobileBottomPanelHeight(height);
    }, resizeTransitionDurationSeconds * 50);
}

function isTouchEvent<E = Element>(event: any): event is React.TouchEvent<E> {
    return event?.type.includes('touch');
}

function doesPointerIntersectWithBoundingClient(
    pointer: { clientX: number, clientY: number },
    rect: DOMRect,
) {
    const {
        clientX: mouseX,
        clientY: mouseY,
    } = pointer;


    const isInside = mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom;

    return isInside;
}

const ResizablePanel = React.forwardRef(function ResizablePanel(
    {
        children,
        className = "",
        style = {},
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwarededRef: React.ForwardedRef<HTMLDivElement>,
) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const resizeTriggerRef = React.useRef<SVGSVGElement | null>(null);
    const panelHeight = editorContextStore(state => state.mobileBottomPanelHeight);

    // const [panelHeight, setPanelHeight] = React.useState(300);
    const [hasHeightAnimation, setHasHeightAnimation] = React.useState(true);
    const startPositionYRef = React.useRef<number>(0);
    const startHeightRef = React.useRef<number>(0);

    const isResizingRef = React.useRef(false);
    const mobileBottomPanelState = editorContextStore(state => state.mobileBottomPanelState);

    React.useEffect(() => {
        editorContextStore.getState().setMobileBottomPanelHeight(
            window.innerHeight *
            bottomPanelStateToHeightRatio[mobileBottomPanelState]
        );
    }, [mobileBottomPanelState]);

    React.useLayoutEffect(() => {
        editorContextStore.getState().setMobileBottomPanelHeight(
            window.innerHeight *
            bottomPanelStateToHeightRatio[MobileBottomPanelState.Full]
        );
    }, []);

    const startResizing = React.useCallback((startEvent: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        // Check if the pointer is intersecting the bounding box of the resize trigger
        if (!resizeTriggerRef.current) {
            console.log('Resize trigger is invalid');
            return;
        }


        const pointer = isTouchEvent<HTMLDivElement>(startEvent)
            ? startEvent.touches[0]
            : startEvent;

        startPositionYRef.current = pointer.clientY;

        if (!doesPointerIntersectWithBoundingClient(
            pointer,
            resizeTriggerRef.current.getBoundingClientRect(),
        )) {
            console.log('Disable resizing');
            return;
        }

        setHasHeightAnimation(false);
        startHeightRef.current = panelHeight;
        isResizingRef.current = true;

    }, [
        panelHeight,
    ]);

    const onMove = React.useCallback((moveEvent: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (!isResizingRef.current) return;

        setHasHeightAnimation(false);

        // const isTouchEvent = moveEvent.type.includes('touch');
        const currentPositionY = isTouchEvent<HTMLDivElement>(moveEvent)
            ? moveEvent.touches[0].clientY
            : moveEvent.clientY;

        const delta = currentPositionY - startPositionYRef.current;
        const newHeight = Math.max(50, startHeightRef.current - delta);
        editorContextStore.getState().setMobileBottomPanelHeight(newHeight);
    }, []);

    const onEnd = React.useCallback((event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (!isResizingRef.current) return;

        isResizingRef.current = false;

        setHasHeightAnimation(true);

        const {
            state,
            height: closestHeight,
        } = getClosestBottomPanelState(panelHeight);

        setMobileBottomPanelStateAndHeight(
            state,
            closestHeight,
        );
    }, [
        panelHeight,
    ]);

    return (
        <div
            ref={mergeRefs([forwarededRef, containerRef])}
            {...props}
            className={classNames(
                "relative pt-[6vh]",
                className,
                hasHeightAnimation ? bottomPanelItemStyles.SnapToHeight : "",
            )}
            style={{
                ...style,
                height: `${panelHeight}px`,
                backgroundColor: '',
                // @ts-ignore
                '--transition-duration': resizeTransitionDurationStyle,
            }}
            onMouseDown={startResizing}
            onMouseUp={onEnd}
            onMouseMove={onMove}
            onTouchStart={startResizing}
            onTouchEnd={onEnd}
            onTouchMove={onMove}
        >
            <GrabIcon
                ref={resizeTriggerRef}
                className="absolute top-0 w-20 text-green-500 pointer-events-none"
                style={{
                    zIndex: BottomPanelZIndex,
                    fontSize: '1.5rem',
                    width: '100vw',
                    height: 'min(100px, 20vh)',
                    lineHeight: '40px',
                }}
            >
            </GrabIcon>
            {children}
        </div>
    );
});


function LeftPanelItemFixedTop({
    activeLeftPanel,
}: {
    activeLeftPanel: LeftPanelItemType,
}) {

    const Component = panelFixedTopItems[activeLeftPanel];

    if (!Component) {
        return null;
    }

    return (
        <div
            className="w-full px-4 bg-zinc-900"
            style={{
                zIndex: LeftPanelFixedTopZIndex,
            }}
        >
            <Component />
        </div>
    );
}

function LeftPanelItemFixedBottom({
    activeLeftPanel,
}: {
    activeLeftPanel: LeftPanelItemType,
}) {
    const Component = panelFixedBottomItems[activeLeftPanel];

    if (!Component) {
        return null;
    }
    return (
        <div className="w-full px-4 border-t border-zinc-800">
            <Component />
        </div>
    )
}

function LeftPanelItemDefault({
    activeLeftPanel,
    children,
    isCustomized = false,
}: {
    activeLeftPanel: LeftPanelItemType,
    children?: React.ReactNode,
    isCustomized?: boolean,
}) {
    if (isCustomized) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <>
            <LeftPanelItemFixedTop
                activeLeftPanel={activeLeftPanel}
            />
            <div
                className="w-full flex flex-col px-4 text-zinc-300">
                {children}
            </div>
            <LeftPanelItemFixedBottom
                activeLeftPanel={activeLeftPanel}
            />
        </>
    )
}

function isJSXElement(element: any): element is JSX.Element {
    return (
        element !== null &&
        typeof element === "object" &&
        "props" in element &&
        "type" in element
    );
}

function BottomPanelItemInternal({
    leftPanel,
}: {
    leftPanel: LeftPanelItemType,
}) {
    const {
        activeLeftPanel,
    } = useLeftPanelState();

    const isCustomized = isPanelItemCustomized[leftPanel];

    const Component = React.useMemo(() => {

        const Component = panelItems[leftPanel];

        if (isJSXElement(Component)) {
            // Cached component

            return Component;
        }

        if (leftPanel === activeLeftPanel) {
            return <Component />;
        }

        return null;
    }, [leftPanel, activeLeftPanel]);

    return (
        <div
            className="flex-col w-full min-h-[100svh] "
            style={{
                display: leftPanel === activeLeftPanel ? 'flex' : 'none',
            }}
        >
            <LeftPanelItemDefault activeLeftPanel={leftPanel} isCustomized={isCustomized}>
                {Component}
            </LeftPanelItemDefault>
        </div>
    );
}

const leftPanelToDefaultPanelState: {
    [key in LeftPanelItemType]?: MobileBottomPanelState
} = {
    'Assets': MobileBottomPanelState.Full,
    'Generate': MobileBottomPanelState.Full,
    'Elements': MobileBottomPanelState.Full,
};

export function BottomPanelItem({
    style = {},
    ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    const mobileBottomPanelState = editorContextStore(state => state.mobileBottomPanelState);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const prevActiveLeftPanelRef = React.useRef<LeftPanelItemType | null>(
        activeLeftPanels[activeLeftPanels.length - 1] ?? null
    );

    React.useEffect(() => {

        if (activeLeftPanels.length <= 0) {
            prevActiveLeftPanelRef.current = null;
            return;
        }

        const activeLeftPanel = activeLeftPanels[activeLeftPanels.length - 1];

        if (activeLeftPanel !== prevActiveLeftPanelRef.current) {
            const bottomPanelState = leftPanelToDefaultPanelState[activeLeftPanel] ?? MobileBottomPanelState.Hide;

            editorContextStore.getState().setMobileBottomPanelState(
                bottomPanelState,
            );
        }

        prevActiveLeftPanelRef.current = activeLeftPanel;
    }, [activeLeftPanels]);

    return (
        <ResizablePanel
            {...props}
            style={{
                display: mobileBottomPanelState !== MobileBottomPanelState.Hide ?
                    'block' :
                    'none',
            }}
        >
            <ScrollAreaContainer orientation="vertical" className="h-full bg-zinc-900 border-t border-zinc-800 rounded-lg">
                {getObjectEntries(panelItems).map(([leftPanel]) => (
                    <BottomPanelItemInternal
                        key={leftPanel}
                        leftPanel={leftPanel}
                    />
                ))}
            </ScrollAreaContainer>
        </ResizablePanel>
    );
};
