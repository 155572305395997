import React from "react"
import { classNames } from "core/utils/classname-utils";
import { PastGeneration, PromptEditorEventHandler } from "core/common/types";
import { LeftPanelImageGridItem, LeftPanelImageGridItemPlaceholder } from "components/panels/panel-items/components/image-grid-item";
import { editorContextStore } from "contexts/editor-context";
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { DropdownClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { isStaticImageObject } from "core/utils/type-guards";
import { getPromptFromTextContent } from "core/utils/text-utils";
import { Navigate } from "../components/navigate";
import * as HoverCard from '@radix-ui/react-hover-card';
import { PlusIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { LeftPanelRenderButton } from "./render-button";
import { getTemplateFromPrompt, mergePromptTemplateSubjects } from "core/common/prompt-template";
import { useGenerateTemplatesContext } from "../components/generate-templates";
import { LeftPanelSectionImageGridPagination } from '../components/pagination';
import { clamp, cloneDeep } from "lodash";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { ChevronLeft, ChevronRight } from "lucide-react";
import GridItemsHorizontalStyles from './grid-items-horizontal.module.css'
import { LEFT_PANEL_WIDTH } from "components/constants/left-panels";
import { isPastGeneration } from 'core/utils/type-guards';
import { AppUserSubscriptionTier } from 'core/common/types';
import { Timestamp } from 'firebase/firestore';
import { useInView } from "react-intersection-observer";
import { UiDisplayMessageDialogEventHandler } from "core/common/types";
import { EyeOff } from "lucide-react";

function onClickLeftPanelSectionPastGenerationGridItem(
    item: PastGeneration,
) {
    const {
        editor,
        setGenerateToolEditorTab,
    } = editorContextStore.getState();

    if (!editor || !setGenerateToolEditorTab) {
        return;
    }

    const promptTemplate = item.promptTemplate || getTemplateFromPrompt(item.prompt);

    const templateTo = cloneDeep(promptTemplate);

    editor.state.setGenerateToolPromptTemplate((templateFrom) => {
        if (templateFrom) {
            // Merge the subjects
            return mergePromptTemplateSubjects(templateFrom, templateTo);
        }
        return templateTo;
    });

    editor.emit<PromptEditorEventHandler>(
        'prompt-editor:set-state',
        getPromptFromTextContent(item.prompt),
    );
}

export function LeftPanelSectionPastGenerationGridItem({
    item,
    className,
    onClick = onClickLeftPanelSectionPastGenerationGridItem,
    lastRowRef,
}: {
    item: PastGeneration,
    className?: string,
    onClick?: (item: PastGeneration) => void,
    lastRowRef?: (node?: Element | null) => void;
}) {

    const editor = editorContextStore(state => state.editor);
    const backend = editorContextStore(state => state.backend);
    const [imgSrc, setImgSrc] = React.useState('');
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        if (item.imgPathType === 'image-id') {
            const imageObject = editor?.objects.findOneById(item.imgPath);
            if (isStaticImageObject(imageObject)) {
                setImgSrc(imageObject.getSrc());
            }
        } else if (item.imgPath) {
            editor?.assets.loadAsset({
                path: item.imgPath,
                type: 'image-storage',
            }).then(src => {
                if (!src) {
                    setIsError(true);
                }
                setImgSrc(src ?? '');
            })
        }
    }, [editor, backend, item]);

    if (isError) {
        return null;
    }

    return (
        <LeftPanelImageGridItem
            ref={lastRowRef}
            ratio={1}
            delayDuration={0}
            imgSrc={imgSrc}
            imgAlt={item.alt}
            imageProps={{
                draggable: false,
            }}
            className={className}
            onClick={() => onClick?.(item)}
            hoverCardContent={
                <>
                    <span>
                        {item.prompt}
                    </span>
                    <span
                        className="mt-2 text-zinc-500"
                    >
                        Click to use this prompt.
                    </span>
                </>
            }
        />
    )
}

function PastGenerationHiddenLimit({
    ratio = 1,
}: {
    ratio?: number,
}) {
    return (
        <AspectRatio.Root
            ratio={ratio}
            className={classNames(
                SecondaryButtonClassNameInactive,
                "flex flex-col items-center justify-center",
            )}
            onClick={() => {
                editorContextStore.getState().editor?.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'quota-subscribe',
                    {
                        title: "Results older than a day are hidden.",
                        header: "Subscribe to see generations that are older than a day",
                    },
                );
            }}
        >
            <EyeOff size={18} />
            <div className="font-semibold text-center">
                Some results are hidden
            </div>
        </AspectRatio.Root>
    )
}

export function PastGenerationsEmptyGridItem() {
    const userQuotas = editorContextStore(state => state.userQuotas);
    const {
        numRenders = 0,
        tier = AppUserSubscriptionTier.Free,
    } = userQuotas || {};
    const areResultsHidden = numRenders > 0 && tier === AppUserSubscriptionTier.Free;
    return (
        areResultsHidden ?
            <PastGenerationHiddenLimit /> :
            <AspectRatio.Root
                ratio={1}
                className={classNames(
                    SecondaryButtonClassNameDisabled,
                    'relative w-full overflow-hidden py-0 text-center',
                )}
            >
                No previous result found.
            </AspectRatio.Root>
    );
}

function ViewAllButton({
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            className={classNames(
                "select-none text-zinc-500 hover:text-lime-500 cursor-pointer transition-colors",
                className ?? '',
            )}
            {...props}
        >
            View all
        </div>
    )
}

export function BackButton({
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            className={classNames(
                "select-none text-zinc-500 hover:text-lime-500 cursor-pointer transition-colors",
                className ?? '',
            )}
            {...props}
        >
            Back
        </div>
    )
}

const searchStringRecentlyUsed = 'recently used';

export function isSearchingRecentlyUsed(
    searchString: string,
) {
    return searchString.toLowerCase() === searchStringRecentlyUsed;
}

function LeftPanelPastGenerationsTitle() {
    const {
        searchString,
        setSearchString,
    } = useGenerateTemplatesContext();

    const isExpanded = isSearchingRecentlyUsed(searchString);

    return (
        <div className="w-full flex flex-row justify-start items-center font-semibold mb-2">
            <span className="flex-1 truncate">
                Recently Used
            </span>
            {
                isExpanded ?
                    <BackButton
                        onClick={() => {
                            setSearchString('');
                        }}
                    /> :
                    <ViewAllButton
                        onClick={() => {
                            setSearchString(searchStringRecentlyUsed);
                        }}
                    />
            }
        </div>
    )
}

export function LeftPanelSectionPastGenerations({
    limit = 8,
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    limit?: number,
}) {
    const pastGenerations = editorContextStore(state => state.pastGenerations);
    const setPastGenerations = editorContextStore(state => state.setPastGenerations);
    const numPastGenerations = Object.keys(pastGenerations).length;

    const [firstIndex, setFirstIndex] = React.useState(0);
    const userQuotas = editorContextStore(state => state.userQuotas);
    const backend = editorContextStore(state => state.backend);

    const [
        lastRowRef,
        lastRowInView,
    ] = useInView();



    React.useEffect(() => {
        if (!lastRowInView) {
            return;
        }

        const fetchPastGenerations = async () => {
            if (!backend) {
                return;
            }

            const pastGen = await backend.getPastGenerationGenerator()?.getNextBatch();

            const newPastGenerations: Record<string, PastGeneration> = {};
            pastGen?.forEach((doc) => {
                if (isPastGeneration(doc)) {
                    const id = doc.id;
                    newPastGenerations[id] = doc;
                }
            })

            setPastGenerations({
                ...pastGenerations,
                ...newPastGenerations,
            });
        };
        fetchPastGenerations();
    }, [lastRowInView, backend]);


    const maxIndex = Math.max(Math.floor(numPastGenerations / limit) * limit, 0);

    const items = React.useMemo(() => {
        if (limit > 0) {
            return Object.entries(pastGenerations).slice(firstIndex, firstIndex + limit);
        }
        return Object.entries(pastGenerations);
    }, [firstIndex, limit, pastGenerations]);

    const hasPrev = firstIndex >= limit;
    const hasNext = firstIndex < maxIndex;

    const gridItems: JSX.Element[] = [];

    if (numPastGenerations > 0) {
        gridItems.push(...items.map(([id, item], index) => (
            <LeftPanelSectionPastGenerationGridItem
                lastRowRef={!hasNext && index === items.length - 1 ? lastRowRef : undefined}
                key={id}
                item={item}
            />
        )));
        for (let i = gridItems.length; i < limit; ++i) {
            gridItems.push(
                <LeftPanelImageGridItemPlaceholder key={i} />
            );
        }
    } else {
        gridItems.push(<PastGenerationsEmptyGridItem key="empty-grid" />);
    }

    return (
        <div
            className={classNames(
                'flex flex-col',
            )}
            {...props}
        >
            <LeftPanelPastGenerationsTitle />
            <div
                className="grid grid-cols-2 gap-2"
            >
                {gridItems}
            </div>
            <LeftPanelSectionImageGridPagination
                hasPrev={hasPrev}
                hasNext={hasNext}
                clickPrev={() => {
                    setFirstIndex(index => clamp(index - limit, 0, maxIndex));
                }}
                clickNext={() => {
                    setFirstIndex(index => clamp(index + limit, 0, maxIndex));
                }}
            />
        </div>
    );
}

function TextEditorHeader() {
    return (
        <div className="flex flex-row items-center">
            <div className="py-1 font-semibold">
                Select a recently used prompt.
            </div>
            <HoverCard.Root
                openDelay={100}
                closeDelay={100}
            >
                <HoverCard.Trigger
                    className={classNames(
                        'ml-2 text-zinc-500 hover:text-lime-500'
                    )}
                >
                    <QuestionMarkCircledIcon />
                </HoverCard.Trigger>
                <HoverCard.Content
                    sideOffset={6}
                    className={DropdownClassName}
                >
                    <span
                        className="text-zinc-200"
                    >
                        Click on an image below to select a recently used prompt.
                    </span>
                </HoverCard.Content>
            </HoverCard.Root>
        </div>
    )
}

const chevronContainerClassName = 'absolute top-0 px-1.5 h-full flex items-center justify-center text-zinc-100 from-zinc-900 to-transparent cursor-pointer';

export function LeftPanelPastGenerations() {
    const pastGenerations = editorContextStore(state => state.pastGenerations);
    const setPastGenerations = editorContextStore(state => state.setPastGenerations);
    const items = React.useMemo(() => {
        return Object.entries(pastGenerations).slice(0, 4);
    }, [pastGenerations]);
    const scrollAreaContainerRef = React.useRef<HTMLDivElement | null>(null);
    const scrollAreaViewportRef = React.useRef<HTMLDivElement | null>(null);
    const leftArrowRef = React.useRef<HTMLDivElement | null>(null);
    const rightArrowRef = React.useRef<HTMLDivElement | null>(null);
    const isDraggingRef = React.useRef(false);
    const dragStartRef = React.useRef(0);
    const dragStartScrollLeftRef = React.useRef(0);
    const dragDistanceRef = React.useRef(0);
    const backend = editorContextStore(state => state.backend);

    const userQuotas = editorContextStore(state => state.userQuotas);

    React.useEffect(() => {
        if (!backend) {
            return;
        }
        const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
        const fetchPastGenerations = async () => {
            let earliestTimeModified = Timestamp.fromDate(new Date(Date.now() - 86400000));

            if (tier !== AppUserSubscriptionTier.Free) {
                earliestTimeModified = Timestamp.fromDate(new Date(Date.now() - 315360000000));
            }

            backend.initializePastGenerator({
                batchSize: 50,
                earliestTimeModified: earliestTimeModified,
            })

            const pastGen = await backend.getPastGenerationGenerator()?.getNextBatch();

            const pastGenerations: Record<string, PastGeneration> = {};
            pastGen?.forEach((doc) => {
                if (isPastGeneration(doc)) {
                    const id = doc.id;
                    pastGenerations[id] = doc;
                }
            })

            setPastGenerations(pastGenerations);
        };
        fetchPastGenerations();
    }, [backend, setPastGenerations, userQuotas?.tier]);


    const {
        setSearchString,
    } = useGenerateTemplatesContext();

    const updateArrows = React.useCallback(() => {
        if (!scrollAreaViewportRef.current) {
            return;
        }
        const scrollLeft = scrollAreaViewportRef.current.scrollLeft ?? 0;
        if (leftArrowRef.current) {
            leftArrowRef.current.style.display = scrollLeft > 0 ? 'flex' : 'none';
        }
        if (rightArrowRef.current) {
            const width = scrollAreaViewportRef.current.getBoundingClientRect().width;
            rightArrowRef.current.style.display = scrollLeft < width ? 'flex' : 'none';
        }
    }, []);

    const {
        numRenders = 0,
        tier = AppUserSubscriptionTier.Free,
    } = userQuotas || {};

    const areResultsHidden = items.length <= 0 && numRenders > 0 && tier === AppUserSubscriptionTier.Free;

    return (
        <div>
            <LeftPanelPastGenerationsTitle />
            <ScrollAreaContainer
                ref={scrollAreaContainerRef}
                viewportRef={scrollAreaViewportRef}
                orientation="horizontal"
                hideScrollbar
                className={classNames(
                    "relative w-full",
                    GridItemsHorizontalStyles.ScrollContainer,
                )}
                onScroll={() => {
                    updateArrows();
                }}
                onPointerOver={() => {
                    updateArrows();
                }}
                onPointerLeave={() => {
                    isDraggingRef.current = false;
                }}
                onPointerDown={(e) => {
                    if (!scrollAreaViewportRef.current) {
                        return;
                    }
                    isDraggingRef.current = true;
                    dragStartRef.current = e.clientX;
                    dragStartScrollLeftRef.current = scrollAreaViewportRef.current.scrollLeft;
                    dragDistanceRef.current = 0;
                }}
                onPointerUp={(e) => {
                    isDraggingRef.current = false;
                }}
                onPointerMove={(e) => {
                    if (!isDraggingRef.current || !scrollAreaViewportRef.current) {
                        return;
                    }
                    const x = e.clientX;
                    const dx = (x - dragStartRef.current);
                    scrollAreaViewportRef.current.scrollLeft = Math.max(0, dragStartScrollLeftRef.current - dx);
                    dragDistanceRef.current = dx;
                }}
            >
                <div
                    className="w-[200%] grid grid-cols-5 gap-2"
                >
                    {
                        items.map(([id, item]) => (
                            <LeftPanelSectionPastGenerationGridItem
                                key={id}
                                item={item}
                                onClick={(item) => {
                                    if (Math.abs(dragDistanceRef.current) > 10) {
                                        return;
                                    }
                                    onClickLeftPanelSectionPastGenerationGridItem(item);
                                }}
                            />
                        ))
                    }
                    {
                        areResultsHidden ?
                            <PastGenerationHiddenLimit /> :
                            (
                                <AspectRatio.Root
                                    ratio={1}
                                    className={classNames(
                                        SecondaryButtonClassNameInactive,
                                        'flex flex-col items-center justify-center'
                                    )}
                                    onClick={() => {
                                        setSearchString(searchStringRecentlyUsed);
                                    }}
                                >
                                    <PlusIcon width={18} height={18} />
                                    <div className="font-semibold">
                                        View All
                                    </div>
                                </AspectRatio.Root>
                            )
                    }
                </div>
                <div
                    ref={leftArrowRef}
                    className={classNames(
                        chevronContainerClassName,
                        'left-0 bg-gradient-to-r',
                        GridItemsHorizontalStyles.ScrollArrow,
                    )}
                    style={{
                        display: 'none',
                    }}
                    onClick={() => {
                        scrollAreaViewportRef.current?.scrollTo({
                            left: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <ChevronLeft size={20} />
                </div>
                <div
                    ref={rightArrowRef}
                    className={classNames(
                        chevronContainerClassName,
                        'right-0 bg-gradient-to-l',
                        GridItemsHorizontalStyles.ScrollArrow,
                    )}
                    onClick={() => {
                        scrollAreaViewportRef.current?.scrollBy({
                            left: LEFT_PANEL_WIDTH,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <ChevronRight size={20} />
                </div>
            </ScrollAreaContainer>
        </div>
    )
}