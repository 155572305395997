import { editorContextStore } from 'contexts/editor-context';
import { getDefaultUserApiData } from 'core/common/types/api';
import React from 'react';



export function useUserApiStatusUpdateEffect() {
    const user = editorContextStore(state => state.user);
    const backend = editorContextStore(state => state.backend);

    React.useEffect(() => {
        if (!user || !backend) {
            return;
        }


        return backend.onUserApiDataUpdate((userApiData) => {


            const {
                setUserApiData,
            } = editorContextStore.getState();

            setUserApiData(userApiData ? { ...userApiData } : getDefaultUserApiData());

        });

    }, [user, backend]);
}

export function useApiKeys() {
    const userApiStatus = editorContextStore(state => state.userApiData);

    return React.useMemo(() => ({
        'Primary API key': userApiStatus.primaryKey,
        'Secondary API key': userApiStatus.secondaryKey,
    }), [userApiStatus]);
}