export enum ImageFormat {
    PNG = 'png',
    JPEG = 'jpeg',
    WEBP = 'webp',
}

export const imageFormatToExtension: Record<ImageFormat, string> = {
    [ImageFormat.JPEG]: 'jpeg',
    [ImageFormat.PNG]: 'png',
    [ImageFormat.WEBP]: 'webp',
}

export const imageFormatToName: Record<ImageFormat, string> = {
    [ImageFormat.JPEG]: 'JPG',
    [ImageFormat.PNG]: 'PNG',
    [ImageFormat.WEBP]: 'WEBP',
}

export enum ChannelRGBA {
    R = 0,
    G = 1,
    B = 2,
    A = 3,
}