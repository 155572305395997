import { autocompleteBackgroundPrefixes, autocompletePlacementPrefixes, autocompleteSurroundingsPrefixes, getPromptAutocompleteValues } from 'components/constants/prompt-autocomplete';
import { SimpleTextEditor } from 'components/text-editor/simple-text-editor';
import { classNames } from 'core/utils/classname-utils';
import React from 'react';
import { DropdownOptionItem, DropdownOptions, DropdownOptionsWithTriggerBorder, getDropdownOptionItemsFromArray } from 'components/utils/dropdown-options';

import { editorContextStore } from 'contexts/editor-context';
import { Tooltip } from 'components/utils/tooltip';
import { Cross1Icon, ChevronDownIcon, PlusIcon } from '@radix-ui/react-icons';
import styles from './prompt-template-editor.module.css';
import { PromptTemplate, PromptWord } from 'core/common/types';
import { StateUpdater } from 'core/common/types';
import { PromptAutocompleteType } from 'components/text-editor/prompt-autocomplete';
import { capitalizeFirstLetter } from 'core/utils/string-utils';
import { isStaticImageObjectUploaded } from 'core/utils/type-guards';

import { Editor } from 'core/editor';
import { handlePromptWordValueChange } from 'components/panels/panel-items/generate/use-update-prompt-from-objects';

const inputLabels: Record<PromptAutocompleteType, string> = {
    'subject': 'Product',
    'background': "Background",
    'custom': 'Custom',
    'placement': 'Placement',
    'surrounding': "Surrounding",
    'template': 'Template',
}

const inputTypeOptions: Record<string, DropdownOptionItem<PromptAutocompleteType>> = {
    'placement': {
        name: 'Placement',
        value: 'placement',
    },
    'surrounding': {
        name: 'Surrounding',
        value: 'surrounding',
    },
    'background': {
        name: 'Background',
        value: 'background',
    },
    'custom': {
        name: 'Custom',
        value: 'custom',
    }
}

function InsertTextInputButton({
    insertTextInput,
}: {
    insertTextInput: (type: PromptAutocompleteType) => void,
}) {
    return (
        <div
            className={classNames(
                'relative w-full h-4 text-xs',
                styles.LabelDropdownContainerDivider,
            )}
        >
            <div className='h-1' />
            <div
                className={classNames(
                    'absolute w-full top-[80%] left-0 border-b border-zinc-800/50',
                    styles.InsertButton,
                )}
            />
            <div
                className={classNames(
                    styles.InsertButton,
                    'absolute top-[2px] w-full flex items-center justify-center pointer-events-none',
                )}
            >
                <DropdownOptions
                    asChild
                    options={inputTypeOptions}
                    contentProps={{
                        sideOffset: 4,
                    }}
                    onSelectItem={(item) => {
                        insertTextInput?.(item.value);
                    }}
                >
                    <div
                        className='w-fit flex flex-row items-center justify-center px-2 py-1 rounded-full bg-zinc-900 text-zinc-500 hover:text-zinc-300 border border-zinc-800 shadow-md transition-colors cursor-pointer select-none pointer-events-auto'
                    >
                        <PlusIcon fontSize={8} className='mr-1' />
                        <span className='mr-1'>
                            Insert
                        </span>
                    </div>
                </DropdownOptions>
            </div>
        </div>
    )
}

const textInputTypeClassName = 'flex flex-row items-center pr-2 pb-1.5 text-sm text-zinc-500 truncate select-none transition-colors'

function TextInputTypeLabel({
    label,
    labelClassName,
}: {
    label?: string,
    labelClassName?: string,
}) {
    return (
        <div
            className={classNames(
                textInputTypeClassName,
                labelClassName ?? '',
            )}
        >
            <span className='mr-1'>
                {capitalizeFirstLetter(label?.toLowerCase() ?? '')}
            </span>
        </div>
    );
}

function TextInputTypeSwitch({
    label,
    labelClassName,
    onSwitchType,
}: {
    label?: string,
    labelClassName?: string,
    onSwitchType?: (type: PromptAutocompleteType) => void,
}) {
    return (
        <DropdownOptions
            className={classNames(
                textInputTypeClassName,
                'hover:text-zinc-300 focus:text-zinc-400 focus:outline-none cursor-pointer',
                styles.LabelDropdownContainer,
                labelClassName ?? '',
            )}
            options={inputTypeOptions}
            onSelectItem={(item) => {
                onSwitchType?.(item.value);
            }}
        >
            <span className='mr-1'>
                {capitalizeFirstLetter(label?.toLowerCase() ?? '')}
            </span>
            <ChevronDownIcon fontSize={16} className={styles.LabelDropdownShow} />
        </DropdownOptions>
    )
}

type TextInputLabelProps = {
    label: string,
    labelClassName?: string,
    canSwitchType?: boolean,
    onSwitchType?: (type: PromptAutocompleteType) => void,
    canDelete?: boolean,
    onDelete?: () => void,
}

function TextInputLabel({
    label,
    labelClassName,
    canSwitchType,
    onSwitchType,
    canDelete = false,
    onDelete,
}: TextInputLabelProps) {
    onDelete = canDelete ? onDelete : undefined;
    return (
        <div className={classNames(
            'w-full flex flex-row items-center justify-start',
            styles.LabelDropdownContainer,
        )}>
            {canSwitchType ?
                <TextInputTypeSwitch
                    label={label}
                    labelClassName={labelClassName}
                    onSwitchType={onSwitchType}
                /> :
                <TextInputTypeLabel
                    label={label}
                    labelClassName={labelClassName}
                />}
            <div className='flex-1' />
            {onDelete && <Tooltip
                triggerChildren={
                    <Cross1Icon
                        fontSize={16}
                        className={classNames(
                            "mx-2 mb-1 text-zinc-700 hover:text-zinc-500 cursor-pointer transition-colors",
                            // styles.LabelDropdownShow,
                        )}
                        onClick={onDelete}
                    />
                }
                contentChildren={`Delete ${label.toLowerCase()} prompt`}
            />}
        </div>
    )
}


type TextInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & TextInputLabelProps & {
    autocompleteItems: string[];
    autocompleteLabel?: string;
    autocompleteWidth?: string | number;
    value: string,
    onValueChange: (value: string) => void,
    onInsert?: (type: PromptAutocompleteType) => void,
};

function TextInput({
    id,
    label,
    labelClassName,
    canSwitchType = true,
    onSwitchType,
    canDelete,
    onDelete,
    onInsert,
    ...props
}: TextInputProps) {
    return (
        <div className='w-full flex flex-col'>
            <TextInputLabel
                label={label}
                labelClassName={labelClassName}
                canSwitchType={canSwitchType}
                canDelete={canDelete}
                onDelete={onDelete}
                onSwitchType={onSwitchType}
            />
            <SimpleTextEditor
                {...props}
                id={id}
                className='flex-1 text-sm'
                description={`Select or enter ${label.toLowerCase()} prompt`}
                tooltipProps={{
                    triggerProps: {
                        asChild: true,
                    },
                    contentProps: {
                        sideOffset: 18,
                    }
                }}
            />
            {onInsert ? <InsertTextInputButton
                insertTextInput={onInsert}
            /> : <div className='h-4' />}
        </div>
    );
}

type TextDropdownWithInputProps = TextInputProps & {
    dropdownValue?: string,
    dropdownItems?: Record<string, DropdownOptionItem<string>>,
    onSelectDropdownItem?: (value: DropdownOptionItem<string>) => void,
}

function TextDropdownWithInput({
    id,
    label,
    labelClassName,
    canSwitchType = true,
    onSwitchType,
    canDelete,
    onDelete,
    onInsert,
    dropdownValue = 'None',
    dropdownItems,
    onSelectDropdownItem,
    ...props
}: TextDropdownWithInputProps) {
    return (
        <div className='w-full flex flex-col'>
            <TextInputLabel
                label={label}
                labelClassName={labelClassName}
                canSwitchType={canSwitchType}
                canDelete={canDelete}
                onDelete={onDelete}
                onSwitchType={onSwitchType}
            />
            <div className='w-full flex flex-row items-center'>
                {dropdownItems && onSelectDropdownItem &&
                    <DropdownOptionsWithTriggerBorder
                        options={dropdownItems}
                        onSelectItem={onSelectDropdownItem}
                        value={dropdownValue}
                        className="mr-2"
                        style={{
                            minWidth: 68,
                        }}
                    />}
                <SimpleTextEditor
                    {...props}
                    id={id}
                    className='flex-1 text-sm min-w-[10px]'
                    description={`Select or enter ${label.toLowerCase()} prompt`}
                    tooltipProps={{
                        triggerProps: {
                            asChild: true,
                        },
                        contentProps: {
                            sideOffset: 18,
                        }
                    }}
                />
            </div>
            {onInsert && <InsertTextInputButton
                insertTextInput={onInsert}
            />}
        </div>
    );
}

function TextInputWrapper({
    dropdownValue,
    dropdownItems,
    onSelectDropdownItem,
    hasDropdown = false,
    ...props
}: TextDropdownWithInputProps & {
    hasDropdown?: boolean,
}) {
    if (hasDropdown && dropdownItems && onSelectDropdownItem) {
        return (
            <TextDropdownWithInput
                {...props}
                dropdownValue={dropdownValue}
                dropdownItems={dropdownItems}
                onSelectDropdownItem={onSelectDropdownItem}
            />
        );
    }
    return (
        <TextInput {...props} />
    );
}

const autocompleteDropdownType: Record<PromptAutocompleteType, Record<string, DropdownOptionItem<string>> | undefined> = {
    'subject': undefined,
    'placement': getDropdownOptionItemsFromArray(autocompletePlacementPrefixes),
    'surrounding': getDropdownOptionItemsFromArray(autocompleteSurroundingsPrefixes),
    'background': getDropdownOptionItemsFromArray(autocompleteBackgroundPrefixes),
    'custom': undefined,
    'template': undefined,
}

function typeHasDropdown(type: PromptAutocompleteType) {
    return type === 'placement' || type === 'surrounding' || type === 'background';
}

function typeCanDelete(type: PromptAutocompleteType) {
    return type !== 'subject';
}

function typeIsRequired(type: PromptAutocompleteType) {
    return type === 'subject';
}

function createDefaultPromptWord(type: PromptAutocompleteType): PromptWord {
    if (type === 'placement') {
        return {
            type: 'fixed',
            prefix: 'on',
            value: 'a white platform',
            autocompleteType: type,
        }
    }
    if (type === 'surrounding') {
        return {
            type: 'fixed',
            prefix: 'with',
            value: 'wavy fabric',
            autocompleteType: type,
        }
    }
    if (type === 'background') {
        return {
            type: 'fixed',
            prefix: 'in front of',
            value: 'white background',
            autocompleteType: type,
        }
    }
    return {
        type: 'fixed',
        value: '',
        autocompleteType: type,
    }
}

function getSubjectImageIntersectionGenerationFrame(
    editor: Editor,
) {
    const objects = editor.generationFrames.getObjectsIntersectingGenerationFrame(
        (object) => isStaticImageObjectUploaded(object)
    );
    if (objects.length !== 1) {
        return;
    }
    return objects[0];
}

export function PromptTemplateFormField({
    word,
    index,
    setPromptTemplate,
    canInsert = true,
}: {
    word: PromptWord,
    index: number,
    setPromptTemplate: (updater: StateUpdater<PromptTemplate>) => void,
    canInsert?: boolean,
}) {
    const type = word.autocompleteType ?? 'custom';
    const dropdownValue = word.prefix;
    const dropdownItems = autocompleteDropdownType[type];
    const autocompleteItems = getPromptAutocompleteValues(type, dropdownValue);
    const editor = editorContextStore(state => state.editor);

    const required = typeIsRequired(type);
    const canDelete = typeCanDelete(type);
    const hasDropdown = typeHasDropdown(type);

    const onDelete = React.useCallback(() => {
        setPromptTemplate((prevTemplate) => {
            const newTemplate = { ...prevTemplate };
            newTemplate.words.splice(index, 1);
            return newTemplate;
        });
    }, [index, setPromptTemplate]);

    const onInsert = React.useCallback((type: PromptAutocompleteType) => {
        setPromptTemplate((prevTemplate) => {
            const newTemplate = { ...prevTemplate };
            newTemplate.words.splice(index + 1, 0, createDefaultPromptWord(type));
            return newTemplate;
        });
    }, [index, setPromptTemplate]);

    const onSelectDropdownItem = React.useCallback((item: DropdownOptionItem<string>) => {
        setPromptTemplate((prevTemplate) => {
            const newTemplate = { ...prevTemplate };
            newTemplate.words[index] = {
                ...word,
                prefix: item.value,
            }
            return newTemplate;
        });
    }, [word, index, setPromptTemplate]);

    const onSwitchType = React.useCallback((type: PromptAutocompleteType) => {
        if (type === word.autocompleteType) {
            return;
        }
        setPromptTemplate((prevTemplate) => {
            const newTemplate = { ...prevTemplate };
            const newWord = createDefaultPromptWord(type);
            if (type === 'custom') {
                newWord.value = word.prefix ?
                    word.prefix.trim() + ' ' + word.value.trim() :
                    word.value.trim();
            }
            newTemplate.words[index] = newWord;
            return newTemplate;
        });
    }, [word, index, setPromptTemplate]);

    return (
        <TextInputWrapper
            id={`prompt-editor-${type}-${index}`}
            label={inputLabels[type]}
            autocompleteItems={autocompleteItems}
            value={word.value}
            onValueChange={(value) => {
                handlePromptWordValueChange({
                    index,
                    word,
                    value,
                    setPromptTemplate,
                });
            }}
            canSwitchType={type !== 'subject'}
            onSwitchType={onSwitchType}
            canDelete={canDelete}
            required={required}
            onDelete={onDelete}
            onInsert={canInsert ? onInsert : undefined}
            hasDropdown={hasDropdown}
            dropdownValue={word.prefix}
            dropdownItems={dropdownItems}
            onSelectDropdownItem={onSelectDropdownItem}
        />
    );
}

export function PromptTemplateForm({
    promptTemplate,
    setPromptTemplate,
}: {
    promptTemplate: PromptTemplate,
    setPromptTemplate: (updater: StateUpdater<PromptTemplate>) => void,
}) {

    const words = promptTemplate.words;

    return (
        <>
            {words.map((word, index) => (
                <PromptTemplateFormField
                    key={index}
                    word={word}
                    index={index}
                    setPromptTemplate={setPromptTemplate}
                />
            ))}
        </>
    )
}