import React from "react";
import { BringFrontIcon, SendBackIcon } from "components/icons/bring-front-icon";
import { PanelListButton, panelListButtonIconSize } from "./button";
import { editorContextStore } from "contexts/editor-context";
import { isActiveSelection, isImageObject } from "core/utils/type-guards";
import { classNames } from "core/utils/classname-utils";
import { Download, LayoutGrid } from "lucide-react";
import { DownloadImageResizeOption } from "core/common/types/download-image";
import { ImageFormat } from "core/common/types/image";
import { getStaticImageFileStem } from "components/utils/data";
import { handleDownloadImage } from "components/utils/download-image-button";
import { arrangeSelectionInGrid } from "../panel-items/edit/edit-selection-utils";

function BringToFront() {
    return (
        <PanelListButton
            onClick={() => {
                editorContextStore.getState().editor?.objects.bringToFront();
            }}
        >
            <BringFrontIcon size={Math.round(panelListButtonIconSize * 1.1)} />
            Front
        </PanelListButton>
    );
}

function SendToBack() {
    return (
        <PanelListButton
            onClick={() => {
                editorContextStore.getState().editor?.objects.sendToBack();
            }}
        >
            <SendBackIcon size={Math.round(panelListButtonIconSize * 1.1)} />
            Back
        </PanelListButton>
    );
}

function DownloadImage() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [formatOption, setFormatOption] = React.useState<ImageFormat>(ImageFormat.WEBP);
    const [resizeOption, setResizeOption] = React.useState<DownloadImageResizeOption>(DownloadImageResizeOption.OneX);

    const suffixInputRef = React.useRef<HTMLInputElement | null>(null);

    const imageObject = editorContextStore(state => state.activeObject);

    const imageFileStem = React.useMemo(() => getStaticImageFileStem(imageObject as any as fabric.Object), [imageObject]);

    React.useEffect(() => {
        if (!imageObject) {
            return;
        }

        const downloadOptionResize = (imageObject.metadata?.downloadOptionResize as DownloadImageResizeOption) || DownloadImageResizeOption.OneX;
        const downloadOptionFormat = (imageObject.metadata?.downloadOptionFormat as ImageFormat) || ImageFormat.WEBP;

        if (suffixInputRef.current) {
            suffixInputRef.current.value = imageObject.metadata?.downloadOptionSuffix || "";
        }

        setResizeOption(downloadOptionResize);
        setFormatOption(downloadOptionFormat);

    }, [
        imageObject,
    ]);

    return (
        <PanelListButton
            onClick={() => {
                if (isLoading) {
                    return;
                }

                if (!isImageObject(imageObject)) {
                    return;
                }

                setIsLoading(true);

                handleDownloadImage({
                    imageUrl: imageObject.getSrc(),
                    resizeOption,
                    formatOption,
                    imageFileStem,
                    suffix: suffixInputRef.current?.value,
                }).then(() => {
                    console.log('Finish download');
                }).finally(() => {
                    setIsLoading(false);
                });
            }}
        >
            <Download size={panelListButtonIconSize} />
            Save
        </PanelListButton>
    );
}



function ArrangeInGrid() {
    return (
        <PanelListButton
            onClick={() => {
                arrangeSelectionInGrid();
            }}
        >
            <LayoutGrid size={panelListButtonIconSize} />
            Grid
        </PanelListButton>
    )
}

const EditImage = React.forwardRef(function EditImage(
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                "flex flex-row items-center justify-start",
                className,
            )}
        >
            <BringToFront />
            <SendToBack />
            <DownloadImage />
        </div>
    )
});

const EditNone = React.forwardRef(function EditNone(
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                className,
            )}
        >
        </div>
    )
});

const EditSelection = React.forwardRef(function EditSelection(
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                className,
            )}
        >
            <ArrangeInGrid />
        </div>
    );
})

export const Edit = React.forwardRef(function Edit(
    props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    const activeObject = editorContextStore(state => state.activeObject);

    if (isImageObject(activeObject)) {
        return (
            <EditImage
                {...props}
                ref={forwardedRef}
            />
        );
    }

    if (isActiveSelection(activeObject)) {
        return (
            <EditSelection
                {...props}
                ref={forwardedRef}
            />
        );
    }

    return (
        <EditNone
            {...props}
            ref={forwardedRef}
        />
    );
})