import React from 'react';
import * as Tabs from "@radix-ui/react-tabs"
import { classNames } from 'core/utils/classname-utils';
import styles from './api-playground.module.css';
import { ApiDashboardModelType, apiDashboardModelIdToModelType } from 'core/common/types/api';
import { Navigate, useParams } from "react-router-dom";
import { editorContextStore } from 'contexts/editor-context';


function ApiModelSelector({
    className = "",
    children,
    ...props
}: Tabs.TabsTriggerProps & {
    value: ApiDashboardModelType,
}) {
    return (
        <Tabs.Trigger
            {...props}
            className={classNames(
                styles.TabsTrigger,
                "py-2 text-center hover:text-lime-500 active:text-lime-600 focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b transition-colors",
                className,
            )}
        >
            {children}
        </Tabs.Trigger>
    )
}

function ImageGenerationModelSelector() {
    return (
        <ApiModelSelector
            value={ApiDashboardModelType.ImageGeneration}
        />
    )
}

export function ApiPlayground() {
    return (
        <div
            className=''
        >
            <Tabs.Root>
                <Tabs.List>
                    <ImageGenerationModelSelector />
                </Tabs.List>
            </Tabs.Root>
        </div>
    );
}

export function ApiPlaygroundRouter() {
    const params = useParams();


    React.useEffect(() => {
        const apiId = params.apiId;

        const apiModelType = apiId ? apiDashboardModelIdToModelType[apiId] : undefined;

        const {
            setDashboardType,
            setApiDashboardModelType,
        } = editorContextStore.getState();

        setDashboardType('manage-api');
        setApiDashboardModelType(apiModelType);

    }, [params]);

    return (
        <Navigate to="/api" />
    );
}