import { fabric } from "fabric"
import { EditorAsset } from "core/common/types"
import { LoadImageOptions, loadImage } from "../core/utils/image-loader"
import { LayerType } from "core/common/layers"



export interface BackgroundImageOptions extends fabric.IImageOptions {
  id?: string
  name?: string
  description?: string
  subtype?: string
  src?: string
  asset?: EditorAsset;
}

export type BackgroundImageElement = string | HTMLCanvasElement | HTMLImageElement | HTMLVideoElement;

export class BackgroundImageObject extends fabric.Image {

  static type = LayerType.BACKGROUND_IMAGE;

  //@ts-ignore
  initialize(element: any, options: BackgroundImageOptions = {}) {
    options.type = LayerType.BACKGROUND_IMAGE;
    //@ts-ignore
    super.initialize(element, {
      ...options,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      selectable: false,
      hoverCursor: "default",
      hasBorders: false,
    })

    return this
  }

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      function (img) {
        // @ts-ignore
        return callback && callback(new fabric.BackgroundImage(img, options))
      },
      null,
      // @ts-ignore
      { crossOrigin: "anonymous" }
    )
  }



  static fromURL(
    url: string,
    callback?: (image: fabric.BackgroundImage) => void,
    imgOptions?: (BackgroundImageOptions & LoadImageOptions) | undefined,
  ) {
    const asset: EditorAsset = {
      type: 'image-url',
      path: url,
    };

    const imageOptions = {
      ...imgOptions,
      asset,
    };

    const backgroundImage = new fabric.BackgroundImage(
      undefined,
      imageOptions,
    );

    const options = imgOptions || {};

    loadImage(url, options).then(function (imageElement) {

      backgroundImage.setElement(imageElement);

      callback?.(backgroundImage);
    });

    return backgroundImage;
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
}

fabric.BackgroundImage = fabric.util.createClass(BackgroundImageObject, {
  type: BackgroundImageObject.type,
})
fabric.BackgroundImage.fromObject = BackgroundImageObject.fromObject
fabric.BackgroundImage.fromURL = BackgroundImageObject.fromURL;

declare module "fabric" {
  namespace fabric {
    class BackgroundImage extends BackgroundImageObject {
      constructor(element?: BackgroundImageElement, options?: BackgroundImageOptions)
    }

    interface IUtil {
      isTouchEvent(event: Event): boolean
      getPointer(event: Event, a?: any): Point
    }
  }
}
