import { editorContextStore } from "contexts/editor-context";

export class InvoiceGenerator {

    private batchSize: number;

    private isFinished = false;

    private lastVisisble?: string;

    constructor(
        batchSize = 8
    ) {
        this.batchSize = batchSize;
    }

    async getNextBatch() {
        if (this.isFinished) {
            return [];
        }

        const { backend } = editorContextStore.getState();

        const result = await backend?.getUserInvoices({
            limit: this.batchSize,
            starting_after: this.lastVisisble,
        });

        this.isFinished = !Boolean(result?.has_more);

        const invoices = result?.data || [];

        if (invoices.length <= 0) {
            this.isFinished = true;
        }

        return invoices;
    }
}