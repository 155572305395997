import React from "react";
import { ApiDashboardNavigateHeader } from "./navigate-header";
import { ApiDashboardModelPreviewTab, ApiDashboardModelTab, ApiDashboardModelType, ApiPipelineType, ApiRenderState, GenerateImageApiDocType } from "core/common/types/api";

import * as Tabs from "@radix-ui/react-tabs";
import * as Select from '@radix-ui/react-select';
import { classNames } from "core/utils/classname-utils";

import apiStyles from './api-playground.module.css';
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { BarChart2, BookOpen, Code } from "lucide-react";
import { cloneDeep } from "lodash";
import { ApiInput, ApiInputProps, getApiStateFromApiConfig } from "./api-input";
import { getObjectEntries } from "core/utils/type-utils";
import { ReactSyntaxHighlighter, ReactSyntaxHighlighterLanguage, ReactSyntaxHighlighterStyle } from "components/syntax-highlighter/react-syntax-lightner";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, QuestionMarkCircledIcon, } from "@radix-ui/react-icons";
import { CopyButton } from 'components/utils/copy-button';
import StickyBox from "components/utils/sticky-box";
import { editorContextStore } from "contexts/editor-context";
import { SimpleSpinner } from "components/icons/simple-spinner";
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { maskApiKeyWithAsterisks } from "core/utils/string-utils";
import { GenerateImageApiState, ApiLanguage, getGenerateImageApiConfigFromApiPipelineType, getSampleApiRequestCode } from "./generate-image-api-config";
import { submitGenerateImageApiRequest } from "./generate-image-api-request";
import { isApiInputValueMissing } from "./input-utils";
import { DefaultGenerateImageApiDocumentation, generateImageApiDocConfig } from "./generate-image-api-documentation";
import { GenerateImagePlaygroundContext } from './generate-image-api-context';
import { GenerateImageApiUsage } from "./generate-image-api-usage";
import { getGenerateImageApiPricingFromApiState } from "./api-pricing-utils";
import { preprocessImageUrl } from "core/utils/url-utils";

const SelectLanguageItem = React.forwardRef((
    {
        children,
        className = "",
        ...props
    }: Select.SelectItemProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
    return (
        <Select.Item
            className={classNames(
                'text-[13px] leading-none text-zinc-300 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-zinc-800 data-[highlighted]:text-lime-500 focus:outline-none focus-visible:outline-none transition-colors',
                className
            )}
            {...props}
            ref={forwardedRef}
        >
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                <CheckIcon />
            </Select.ItemIndicator>
        </Select.Item>
    );
});

function getSyntaxHighlighterLanguage(apiLanguage: ApiLanguage): ReactSyntaxHighlighterLanguage {
    if (apiLanguage === ApiLanguage.Javascript || apiLanguage === ApiLanguage.JavascriptNodeJs) {
        return ReactSyntaxHighlighterLanguage.Javascript;
    } else if (apiLanguage === ApiLanguage.Typescript) {
        return ReactSyntaxHighlighterLanguage.Typescript;
    } else if (apiLanguage === ApiLanguage.Python) {
        return ReactSyntaxHighlighterLanguage.Python;
    } else if (apiLanguage === ApiLanguage.Bash) {
        return ReactSyntaxHighlighterLanguage.Bash;
    }
    return ReactSyntaxHighlighterLanguage.Javascript;
}

function SelectLanguageButton({
    placeholder,
    value,
    onValueChange,
}: {
    value: ApiLanguage,
    onValueChange: (value: ApiLanguage) => void,
    placeholder?: string,
}) {
    return (
        <Select.Root
            value={value}
            onValueChange={onValueChange}
        >
            <Select.Trigger
                className="inline-flex flex-row items-center justify-start rounded px-2 py-1 text-[13px] leading-none gap-[5px] bg-transparent text-zinc-300 border border-solid border-transparent focus:border-zinc-600 hover:bg-zinc-700 data-[placeholder]:text-zinc-500 outline-none transition-colors"
                aria-label="Language"
            >
                <Select.Value placeholder={placeholder} className='flex-1' />
                <Select.Icon className="text-zinc-300">
                    <ChevronDownIcon />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className={classNames(
                    DropdownClassName,
                    "overflow-hidden p-1 border-zinc-800 focus-visible:border-zinc-800 pointer-events-none",
                )}>
                    <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-zinc-900 text-zinc-300 cursor-default">
                        <ChevronUpIcon />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-[5px]">
                        <Select.Group>
                            <SelectLanguageItem value={ApiLanguage.Javascript}>
                                Javascript
                            </SelectLanguageItem>
                            <SelectLanguageItem value={ApiLanguage.JavascriptNodeJs}>
                                Javascript (Node.JS)
                            </SelectLanguageItem>
                            <SelectLanguageItem value={ApiLanguage.Typescript}>
                                Typescript
                            </SelectLanguageItem>
                            <SelectLanguageItem value={ApiLanguage.Python}>
                                Python
                            </SelectLanguageItem>
                        </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-zinc-900 text-zinc-300 cursor-default">
                        <ChevronDownIcon />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

type GenerateImageApiPlaygroundCodeProps = {
    code: string,
    language: ApiLanguage,
    setLanguage: (value: ApiLanguage) => void,
}

function GenerateImageApiPlaygroundCode({
    code,
    language,
    setLanguage,
}: GenerateImageApiPlaygroundCodeProps) {
    const userApiData = editorContextStore(state => state.userApiData);

    const displayCode = React.useMemo(() => maskApiKeyWithAsterisks(code, userApiData.primaryKey), [code, userApiData]);

    return (
        <div
            className=""
        >
            <div className="bg-zinc-800/30 border-b border-zinc-800 px-2 py-1 flex flex-row items-center justify-start">
                <SelectLanguageButton
                    value={language}
                    onValueChange={setLanguage}
                />
                <div className="flex-1" />
                <CopyButton
                    textToCopy={code}
                />
            </div>
            <ReactSyntaxHighlighter
                language={getSyntaxHighlighterLanguage(language)}
                highlighterStyle={ReactSyntaxHighlighterStyle.VscDarkPlus}
                style={{
                    [`pre[class*="language-"]`]: {
                        background: 'transparent',
                    }
                }}
                wrapLongLines
            >
                {displayCode}
            </ReactSyntaxHighlighter>
        </div>
    )
}

function GenerateImageApiPlaygroundPreviewTab({
    className = "",
    children,
    ...props
}: Tabs.TabsTriggerProps & {
    value: ApiDashboardModelPreviewTab,
}) {
    return (
        <Tabs.Trigger
            {...props}
            className={classNames(
                apiStyles.TabsTrigger,
                "group px-1 py-1 text-sm text-center focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b-2 transition-colors",
                className,
            )}
        >
            <div className='w-fit min-w-[2rem] px-3 py-2 rounded flex flex-row items-center justify-center gap-3 bg-transparent group-hover:text-zinc-300 group-hover:bg-zinc-800 transition-colors'>
                {children}
            </div>
        </Tabs.Trigger>
    )
}

const PreviewImageContainerClassName = "m-4 flex items-center justify-center border border-zinc-800 bg-cover bg-center rounded"

function GenerateImageApiPlaygroundResultImages() {
    const {
        apiState,
        renderState,
        renderResults,
    } = React.useContext(GenerateImagePlaygroundContext);

    const {
        width = 1024,
        height = 1024,
    } = apiState;

    const imageUrl = renderResults[0];

    if (renderState === ApiRenderState.Rendering) {
        return (
            <AspectRatio.Root
                ratio={width / height}
                className={PreviewImageContainerClassName}
            >
                <div
                    className="flex flex-row items-center gap-4 justify-center text-zinc-500"
                >
                    <SimpleSpinner
                        width={23}
                        height={23}
                        pathClassName="fill-lime-500"
                    />
                    <span className="truncate">
                        Processing ... Please wait up to 30 seconds.
                    </span>
                </div>
            </AspectRatio.Root>
        );
    }

    if (renderResults.length <= 0) {
        return (
            <AspectRatio.Root
                ratio={width / height}
                className={PreviewImageContainerClassName}
            >
                <div
                    className="flex flex-row items-center gap-4 justify-center text-zinc-500"
                >
                    No output yet! Press the "Submit" button to test the API.
                </div>
            </AspectRatio.Root>
        );
    }

    return (
        <AspectRatio.Root
            ratio={width / height}
            className={PreviewImageContainerClassName}
            style={{
                backgroundImage: imageUrl ? `url(${preprocessImageUrl(imageUrl)})` : undefined,
            }}
        />
    )
}

function GenerateImageApiPlaygroundResults() {

    const {
        renderResultMessage,
    } = React.useContext(GenerateImagePlaygroundContext);

    return (
        <div
            className="flex flex-col items-center justify-center"
        >
            <GenerateImageApiPlaygroundResultImages />
            <div className="w-full h-px bg-zinc-800" />
            <div
                className="p-4 w-full text-zinc-800 flex flex-col items-stretch justify-start gap-2"
            >
                <div className="text-sm text-zinc-700">
                    Response messages:
                </div>
                <div className="text-base text-zinc-200">
                    {renderResultMessage}
                </div>
            </div>
        </div>
    )
}

function GenerateImageApiPlaygroundPreview({
    code,
    language,
    setLanguage,
}: GenerateImageApiPlaygroundCodeProps) {
    const {
        playgroundTab,
        setPlaygroundTab,
    } = React.useContext(GenerateImagePlaygroundContext);

    return (
        <Tabs.Root
            value={playgroundTab}
            // @ts-ignore
            onValueChange={setPlaygroundTab}
            defaultValue={ApiDashboardModelPreviewTab.Code}
        >
            <Tabs.List
                className='border-0 border-b border-zinc-800'
            >
                <GenerateImageApiPlaygroundPreviewTab
                    value={ApiDashboardModelPreviewTab.Code}
                >
                    Code
                </GenerateImageApiPlaygroundPreviewTab>
                <GenerateImageApiPlaygroundPreviewTab
                    value={ApiDashboardModelPreviewTab.Results}
                >
                    Outputs
                </GenerateImageApiPlaygroundPreviewTab>
            </Tabs.List>
            <div className=''>
                <Tabs.Content
                    value={ApiDashboardModelPreviewTab.Code}
                >
                    <GenerateImageApiPlaygroundCode
                        code={code}
                        language={language}
                        setLanguage={setLanguage}
                    />
                </Tabs.Content>
                <Tabs.Content
                    value={ApiDashboardModelPreviewTab.Results}
                >
                    <GenerateImageApiPlaygroundResults />
                </Tabs.Content>
            </div>
        </Tabs.Root>
    )
}

function validateRenderArgs({
    apiState,
}: {
    apiState: GenerateImageApiState,
}) {
    const pipelineType = apiState.pipelineType;

    const config = getGenerateImageApiConfigFromApiPipelineType(pipelineType as ApiPipelineType);

    if (!config) {
        return {
            ok: false,
        };
    }

    const missingRequired = getObjectEntries(config).find(([key, item]) => {
        return item.required && isApiInputValueMissing(
            { ...item, value: apiState[key] } as ApiInputProps
        )
    }) != null;

    return {
        ok: !missingRequired,
    }
}

function SubmitApiRequestButtonIdle() {
    const {
        apiState,
        renderState,
        setRenderState,
        setRenderResults,
        setRenderResultMessage,
        setPlaygroundTab,
    } = React.useContext(GenerateImagePlaygroundContext);

    const {
        ok: isApiStateValid,
    } = React.useMemo(() => validateRenderArgs({
        apiState,
    }), [apiState]);

    return (
        <button
            className={classNames(
                isApiStateValid ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
                "justify-center min-w-[50px] truncate transition-colors"
            )}
            onClick={() => {
                if (!isApiStateValid) {
                    setRenderState(ApiRenderState.Idle);
                    setRenderResultMessage("Missing required parameters.");
                    return;
                }

                submitGenerateImageApiRequest({
                    apiState,
                    apiRenderState: renderState,
                    setApiRenderState: setRenderState,
                    setRenderResults,
                    setRenderResultMessage,
                    setPlaygroundTab,
                });
            }}
        >
            {isApiStateValid ? "Submit" : "Submit (Required fields missing)"}
        </button>
    );
}

function SubmitApiRequestButtonRendering() {
    return (
        <button
            className={classNames(
                PrimaryButtonClassNameLoading,
                "flex flex-row text-lime-800 items-center justify-center min-w-[50px] gap-2"
            )}
        >
            <SimpleSpinner
                width={23}
                height={23}
                pathClassName="fill-lime-800"
            />
            Processing
        </button>
    );
}

function SubmitApiRequestButton() {
    const { renderState } = React.useContext(GenerateImagePlaygroundContext);

    if (renderState === ApiRenderState.Rendering) {
        return (
            <SubmitApiRequestButtonRendering />
        );
    }

    return (
        <SubmitApiRequestButtonIdle />
    );
}

function EstimatedPrice() {
    const playgroundContext = React.useContext(GenerateImagePlaygroundContext);

    const {
        apiState,
        setModelTab,
        setDocTab,
    } = playgroundContext;

    const price = React.useMemo(() => getGenerateImageApiPricingFromApiState(apiState), [apiState]);

    return (
        <div className="flex flex-row items-center text-xs lg:text-sm text-zinc-500 gap-2">
            <span>
                {`Estimated cost: $${price}`}
            </span>
            <QuestionMarkCircledIcon
                className="text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer"
                onClick={() => {
                    setDocTab('Pricing');
                    setModelTab(ApiDashboardModelTab.Docs);
                }}
            />
        </div>
    )
}

function getApiConfigDescription(
    pipelineType: ApiPipelineType,
    key: string,
) {
    return generateImageApiDocConfig[pipelineType]?.fieldGroups?.Request?.fields?.[key]?.content;
}

function GenerateImageApiPlayground() {
    const playgroundContext = React.useContext(GenerateImagePlaygroundContext);

    const {
        apiState,
        setApiState,
        setDocTab,
    } = playgroundContext;

    const apiKey = editorContextStore(state => state.userApiData.primaryKey || "YOUR API KEY");

    const [code, setCode] = React.useState(getSampleApiRequestCode({
        language: ApiLanguage.Javascript,
        apiState,
        apiKey,
    }));
    const [language, setLanguage] = React.useState(ApiLanguage.Javascript);

    React.useEffect(() => {
        setCode(getSampleApiRequestCode({
            language,
            apiState,
            apiKey,
        }));
    }, [language, apiState, apiKey]);

    const apiConfig = React.useMemo(() => cloneDeep(
        getGenerateImageApiConfigFromApiPipelineType(apiState.pipelineType as ApiPipelineType)
    ), [apiState.pipelineType]);


    return (
        <div
            className="relative flex flex-col md:flex-row gap-4 text-sm"
        >
            <div className="flex-1 flex flex-col gap-4">
                {getObjectEntries(apiConfig).map(([key, props]) => (
                    <ApiInput
                        {...props}
                        key={key}
                        description={getApiConfigDescription(
                            apiState.pipelineType as ApiPipelineType,
                            key,
                        )}
                        value={apiState[key] as any}
                        onValueChange={(value: unknown) => {
                            if (key === 'pipelineType') {
                                // Handle pipeline type change
                                setApiState({
                                    ...getApiStateFromApiConfig(
                                        getGenerateImageApiConfigFromApiPipelineType(
                                            value as ApiPipelineType
                                        )
                                    ),
                                    pipelineType: value as ApiPipelineType,
                                });
                                setDocTab(value as ApiPipelineType);
                            } else {
                                setApiState((prevState) => {
                                    return {
                                        ...prevState,
                                        [key]: value,
                                    }
                                });
                            }
                        }}
                    />
                ))}
                <div className="flex-1" />
                <StickyBox bottom>
                    <div
                        className="bg-zinc-900 border-t border-zinc-800 py-4 flex flex-col xl:flex-row items-center gap-2"
                    >
                        <EstimatedPrice />
                        <div className="flex-1 min-w-0" />
                        <div
                            className="flex flex-row items-center gap-4"
                        >
                            <button
                                className={classNames(
                                    SecondaryButtonClassNameInactive,
                                    "justify-center min-w-[50px]"
                                )}
                            >
                                Reset
                            </button>
                            <SubmitApiRequestButton />
                        </div>
                    </div>
                </StickyBox>
            </div>
            <div className="flex-1 min-h-[10rem] rounded-md bg-zinc-800/20 border border-zinc-800">
                <GenerateImageApiPlaygroundPreview
                    code={code}
                    language={language}
                    setLanguage={setLanguage}
                />
            </div>
        </div>
    )
}

function DemoPlayground() {
    return (
        <GenerateImageApiPlayground />
    );
}

function Usage() {
    return (
        <GenerateImageApiUsage />
    )
}

function Documentation() {
    return (
        <DefaultGenerateImageApiDocumentation />
    )
}

function GenerateImageApiTabTrigger({
    className = "",
    children,
    ...props
}: Tabs.TabsTriggerProps & {
    value: ApiDashboardModelTab,
}) {
    return (
        <Tabs.Trigger
            {...props}
            className={classNames(
                apiStyles.TabsTrigger,
                "group px-1 py-1 text-sm text-center focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b-2 transition-colors",
                className,
            )}
        >
            <div className='w-fit min-w-[2rem] px-3 py-2 rounded flex flex-row items-center justify-center gap-3 bg-transparent group-hover:text-zinc-300 group-hover:bg-zinc-800 transition-colors'>
                {children}
            </div>
        </Tabs.Trigger>
    )
}


function GenerateImageApiTabs() {
    const {
        modelTab,
        setModelTab,
    } = React.useContext(GenerateImagePlaygroundContext);

    return (
        <Tabs.Root
            value={modelTab}
            // @ts-ignore
            onValueChange={setModelTab}
        >
            <Tabs.List
                className='border-0 border-b border-zinc-800'
            >
                <GenerateImageApiTabTrigger
                    value={ApiDashboardModelTab.Demo}
                >
                    <Code size={16} />
                    Playground
                </GenerateImageApiTabTrigger>
                <GenerateImageApiTabTrigger
                    value={ApiDashboardModelTab.Docs}
                >
                    <BookOpen size={16} />
                    Documentation
                </GenerateImageApiTabTrigger>
                <GenerateImageApiTabTrigger
                    value={ApiDashboardModelTab.Usage}
                >
                    <BarChart2 size={16} />
                    Usage
                </GenerateImageApiTabTrigger>
            </Tabs.List>
            <div className='p-4 max-w-[1920px]'>
                <Tabs.Content
                    value={ApiDashboardModelTab.Demo}
                >
                    <DemoPlayground />
                </Tabs.Content>
                <Tabs.Content
                    value={ApiDashboardModelTab.Docs}
                >
                    <Documentation />
                </Tabs.Content>
                <Tabs.Content
                    value={ApiDashboardModelTab.Usage}
                >
                    <Usage />
                </Tabs.Content>
            </div>
        </Tabs.Root>
    );
}

export function GenerateImageApi() {
    const [apiState, setApiState] = React.useState<GenerateImageApiState>(
        cloneDeep(getApiStateFromApiConfig(getGenerateImageApiConfigFromApiPipelineType(
            ApiPipelineType.ProductPlacement,
        ))),
    );

    const [renderProgress, setRenderProgress] = React.useState(0);
    const [renderState, setRenderState] = React.useState(ApiRenderState.Idle);
    const [renderResults, setRenderResults] = React.useState<string[]>([]);
    const [renderResultMessage, setRenderResultMessage] = React.useState('');
    const [playgroundTab, setPlaygroundTab] = React.useState(ApiDashboardModelPreviewTab.Code);
    const [modelTab, setModelTab] = React.useState<ApiDashboardModelTab>(ApiDashboardModelTab.Demo);
    const [docTab, setDocTab] = React.useState<GenerateImageApiDocType>(ApiPipelineType.Default);

    React.useEffect(() => {
        setRenderState(ApiRenderState.Idle);
        setRenderProgress(0);
    }, []);

    React.useEffect(() => {
        setRenderResults([]);
    }, [apiState.pipelineType]);

    return (
        <GenerateImagePlaygroundContext.Provider
            value={{
                apiState,
                setApiState,
                renderProgress,
                setRenderProgress,
                renderState,
                setRenderState,
                renderResults,
                setRenderResults,
                renderResultMessage,
                setRenderResultMessage,
                playgroundTab,
                setPlaygroundTab,
                modelTab,
                setModelTab,
                docTab,
                setDocTab,
            }}
        >
            <div>
                <ApiDashboardNavigateHeader
                    paths={[
                        {
                            name: 'API Dashboard',
                            to: '/api',
                        },
                        {
                            name: 'Generate Image',
                            to: `/api/${ApiDashboardModelType.ImageGeneration}`,
                        },
                    ]}
                />
                <GenerateImageApiTabs />
            </div>
        </GenerateImagePlaygroundContext.Provider>
    )
}