import { Timestamp } from "firebase/firestore";

export enum UpscaleJobStatus {
    Active = "Active",
    Completed = "Completed",
    Stopped = "Stopped",
}

export interface UpscaleJobState {
    id: string,
    status: UpscaleJobStatus,
    render_start_timestamp: Timestamp,
    storage_paths?: string[],
}

export function isUpscaleJobState(state: any): state is UpscaleJobState {
    return state && typeof (state.id) === 'string' && state.status;
}