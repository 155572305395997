import { promptAutocompleteBackgrounds, promptAutocompleteSurroundings } from 'components/constants/prompt-autocomplete';
import { classNames } from 'core/utils/classname-utils';
import { getNumWords } from 'core/utils/string-utils';
import React from 'react';
import { Command } from './autocomplete';


export const AutocompleteItemGroupClassName = 'text-xs text-zinc-500 py-1'

export const AutocompleteItemClassName = 'rounded-md px-3 py-2 my-1 text-zinc-400 text-sm select-none cursor-pointer transition-colors';

export const AutocompleteItemClassNameUnselected = 'bg-zinc-800/20 hover:bg-zinc-800 hover:shadow-sm aria-selected:bg-zinc-800 aria-selected:shadow-sm hover:text-zinc-200';
export const AutocompleteItemClassNameSelected = 'bg-zinc-800/80 hover:bg-zinc-800 hover:shadow-sm text-zinc-300 hover:text-zinc-200';

export type PromptAutocompleteType = 'template' | 'subject' | 'placement' | 'surrounding' | 'background' | 'custom';


export type PromptSurrounding = {
    type: 'surrounding',
    key: 'on' | 'next to',
}

export type AddTextHandler = (params: {
    value: string,
    type?: PromptAutocompleteType,
}) => void;

export type ReplaceTextHandler = (params: {
    value: string,
    state: string,
    type?: PromptAutocompleteType,
}) => void;

const templates = [
    {
        value: 'perfume bottle surrounded by pink roses in front of a pink background',
        state: '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"perfume bottle surrounded by pink roses in front of a pink background","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"tag":"h1"}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}',
    },
    {
        value: 'yellow serum bottle in front of orange slices with a white background',
        state: '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"yellow serum bottle in front of orange slices with a white background","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"tag":"h1"}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}',
    },
    {
        value: 'small translucent bottle submerged under blue water and surrounded by bubbles',
        state: '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"small translucent bottle submerged under blue water and surrounded by bubbles","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"tag":"h1"}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}',
    },
];

const subjects = [
    {
        value: 'cosmetic bottle',
    },
    {
        value: 'perfume bottle',
    },
    {
        value: 'packaging box',
    },
    {
        value: 'wine bottle',
    },
    {
        value: 'lotion tube',
    },
    {
        value: 'candle',
    },
    {
        value: 'soap dispenser',
    },
    {
        value: 'watch',
    },
    {
        value: 'bubble tea',
    },
    {
        value: 'handbag',
    },
]

const surroundings = promptAutocompleteSurroundings;

const backgrounds = promptAutocompleteBackgrounds;

export function CommandItem({
    children,
    className,
    onSelect,
    ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, "onSelect" | "value" | "disabled"> & {
    children: React.ReactNode,
    className?: string,
    onSelect: (value: string) => void,
}) {
    const [selected, setSelected] = React.useState(false);

    return (
        <Command.Item
            className={classNames(
                AutocompleteItemClassName,
                selected ? AutocompleteItemClassNameSelected : AutocompleteItemClassNameUnselected,
                className ?? '',
            )}
            onSelect={onSelect}
            onSelectedChange={(selected) => {
                setSelected(selected);
            }}
            {...props}
        >
            {children}
        </Command.Item>
    )
}

export function PromptTemplates({
    replaceText,
}: {
    replaceText: ReplaceTextHandler,
}) {
    return (
        <Command.Group
            className={AutocompleteItemGroupClassName}
            heading="Templates"
        >
            {templates.map(template => (
                <CommandItem
                    key={template.value}
                    className={AutocompleteItemClassName}
                    onSelect={() => {
                        replaceText({ ...template, type: 'template' });
                    }}
                >
                    {template.value}
                </CommandItem>
            ))}
        </Command.Group>
    )
}

export function PromptSubjects({
    addText,
}: {
    addText: AddTextHandler,
}) {
    return (
        <Command.Group
            className={classNames(
                AutocompleteItemGroupClassName,
            )}
            heading="Subject"
        >
            {subjects.map(template => (
                <CommandItem
                    key={template.value}
                    className={AutocompleteItemClassName}
                    onSelect={() => {
                        console.log(`Click template ${template.value}`);
                        addText({ ...template, type: 'subject' });
                    }}
                >
                    {template.value}
                </CommandItem>
            ))}
        </Command.Group>
    );
}

export function PromptSurroundings({
    addText,
}: {
    addText: AddTextHandler,
}) {
    return (
        <Command.Group
            className={AutocompleteItemGroupClassName}
            heading="Surroundings"
        >
            {surroundings.map(template => (
                <CommandItem
                    key={template.value}
                    className={AutocompleteItemClassName}
                    onSelect={() => {
                        console.log(`Click surroundings ${template.value}`);
                        addText({ ...template, type: 'surrounding' });
                    }}
                >
                    {template.value}
                </CommandItem>
            ))}
        </Command.Group>
    );
}

export function PromptBackgrounds({
    addText,
}: {
    addText: AddTextHandler,
}) {
    return (
        <Command.Group
            className={AutocompleteItemGroupClassName}
            heading="Backgrounds"
        >
            {backgrounds.map(template => (
                <CommandItem
                    key={template.value}
                    className={AutocompleteItemClassName}
                    onSelect={() => {
                        console.log(`Click background ${template.value}`);
                        addText({ ...template, type: 'background' });
                    }}
                >
                    {template.value}
                </CommandItem>
            ))}
        </Command.Group>
    );
}

export function PromptAutocomplete({
    prompt,
    prevAutocompleteType,
    currAutocompleteType,
    addText,
    replaceText,
}: {
    prompt: string,
    prevAutocompleteType: PromptAutocompleteType | undefined,
    currAutocompleteType: PromptAutocompleteType | undefined,
    addText: AddTextHandler,
    replaceText: ReplaceTextHandler,
}) {
    // console.log(`Prev type ${prevAutocompleteType}; Curr type: ${currAutocompleteType}`);
    const numWords = React.useMemo(() => getNumWords(prompt), [prompt]);
    if (numWords <= 1) {
        return (
            <>
                <PromptTemplates
                    replaceText={replaceText}
                />
                <PromptSubjects
                    addText={addText}
                />
            </>
        );
    }
    if (currAutocompleteType === 'subject') {
        return (
            <>
                <PromptSubjects
                    addText={addText}
                />
                <PromptSurroundings
                    addText={addText}
                />
                <PromptBackgrounds
                    addText={addText}
                />
            </>
        )
    }
    if (currAutocompleteType === 'surrounding' || prevAutocompleteType === 'subject') {
        return (
            <>
                <PromptSurroundings
                    addText={addText}
                />
                <PromptBackgrounds
                    addText={addText}
                />
            </>
        );
    }
    if (currAutocompleteType === 'background' || prevAutocompleteType === 'surrounding') {
        return (
            <>
                <PromptBackgrounds
                    addText={addText}
                />
                <PromptSurroundings
                    addText={addText}
                />
            </>
        );
    }
    if (numWords > 2) {
        return (
            <>
                <PromptSurroundings
                    addText={addText}
                />
                <PromptBackgrounds
                    addText={addText}
                />
                <PromptSubjects
                    addText={addText}
                />
            </>
        );
    }
    return (
        <>
            <PromptSubjects
                addText={addText}
            />
            <PromptSurroundings
                addText={addText}
            />
            <PromptBackgrounds
                addText={addText}
            />
        </>
    );
}