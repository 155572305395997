import { ApiDashboardModelPreviewTab, ApiPipelineType, ApiRenderState } from "core/common/types/api";
import { GenerateImageApiState, isCannyGenerateImageApiState, isProductPlacementGenerateImageApiState } from "./generate-image-api-config";
import { StateUpdater } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";

export type SubmitApiRequestProps = {
    apiState: GenerateImageApiState,
    apiRenderState: ApiRenderState,
    setApiRenderState: (value: StateUpdater<ApiRenderState>) => void,
    setRenderResults: (value: StateUpdater<string[]>) => void,
    setRenderResultMessage: (value: StateUpdater<string>) => void,
    setPlaygroundTab: (value: StateUpdater<ApiDashboardModelPreviewTab>) => void,
}

const apiEndpointUrl = process.env.REACT_APP_RENDER_API_ENDPOINT_URL;

async function submitDefaultApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps) {
    if (apiRenderState !== ApiRenderState.Idle) {
        return;
    }

    const {
        userApiData,
    } = editorContextStore.getState();

    setApiRenderState(ApiRenderState.Rendering);

    setPlaygroundTab(ApiDashboardModelPreviewTab.Results);

    const {
        pipelineType,
        prompt,
        negativePrompt,
        width,
        height,
        numInferenceSteps,
        guidanceScale,
        seed,
    } = apiState;

    if (pipelineType !== ApiPipelineType.Default) {
        setRenderResultMessage(`Incorrect pipeline type: ${pipelineType}; Need to set pipeline type to ${ApiPipelineType.Default}.`);
        setRenderResults([]);
    }

    const response = await fetch(
        apiEndpointUrl,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-Key': userApiData.primaryKey,
            },
            body: JSON.stringify({
                'pipeline_type': pipelineType,
                'prompt': prompt,
                'negative_prompt': negativePrompt,
                'guidance_scale': guidanceScale,
                'num_inference_steps': numInferenceSteps,
                'width': width,
                'height': height,
                'seed': seed,
            }),
        },
    );

    if (response.ok) {

        const result = await response.json();

        if (Array.isArray(result.images) && typeof (result.images[0]) === 'string') {
            setRenderResultMessage("");
            setRenderResults(result.images);
        } else {
            setRenderResultMessage(result?.message || "Cannot parse result images.");
            setRenderResults([]);
        }

    } else {
        const message = (await response.json())?.message;

        setRenderResultMessage(message);

        setRenderResults([]);

    }

    setApiRenderState(ApiRenderState.Idle);
}

async function submitCannyApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps) {
    if (apiRenderState !== ApiRenderState.Idle) {
        return;
    }

    const {
        userApiData,
    } = editorContextStore.getState();

    setApiRenderState(ApiRenderState.Rendering);

    setPlaygroundTab(ApiDashboardModelPreviewTab.Results);

    if (!isCannyGenerateImageApiState(apiState)) {
        setRenderResultMessage(`Incorrect pipeline type: ${apiState.pipelineType}; Need to set pipeline type to ${ApiPipelineType.Canny}.`);
        setRenderResults([]);
        return;
    }

    const {
        pipelineType,
        prompt,
        negativePrompt,
        width,
        height,
        numInferenceSteps,
        guidanceScale,
        seed,
        canny_base_image,
        canny_controlnet_conditioning_scale,
    } = apiState;

    const response = await fetch(
        apiEndpointUrl,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-Key': userApiData.primaryKey,
            },
            body: JSON.stringify({
                'pipeline_type': pipelineType,
                'prompt': prompt,
                'negative_prompt': negativePrompt,
                'guidance_scale': guidanceScale,
                'num_inference_steps': numInferenceSteps,
                'width': width,
                'height': height,
                'seed': seed,
                'canny_base_image': canny_base_image,
                'canny_controlnet_conditioning_scale': canny_controlnet_conditioning_scale,
            }),
        },
    );

    if (response.ok) {

        const result = await response.json();

        if (Array.isArray(result.images) && typeof (result.images[0]) === 'string') {
            setRenderResultMessage("");
            setRenderResults(result.images);
        } else {
            setRenderResultMessage(result?.message || "Cannot parse result images.");
            setRenderResults([]);
        }

    } else {
        const message = (await response.json())?.message;

        setRenderResultMessage(message);

        setRenderResults([]);

    }

    setApiRenderState(ApiRenderState.Idle);
}

async function submitProductPlacementApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps) {
    if (apiRenderState !== ApiRenderState.Idle) {
        return;
    }

    const {
        userApiData,
    } = editorContextStore.getState();

    setApiRenderState(ApiRenderState.Rendering);

    setPlaygroundTab(ApiDashboardModelPreviewTab.Results);

    if (!isProductPlacementGenerateImageApiState(apiState)) {
        setRenderResultMessage("The args are not valid product placement arguments.")
        return;
    }

    const {
        pipelineType,
        prompt,
        negativePrompt,
        width,
        height,
        numInferenceSteps,
        guidanceScale,
        seed,
        ref_image,
        ref_image_scale_start,
        ref_image_scale_finish,
        foreground_image,
        foreground_mask_image,
    } = apiState;

    const response = await fetch(
        apiEndpointUrl,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-Key': userApiData.primaryKey,
            },
            body: JSON.stringify({
                'pipeline_type': pipelineType,
                'prompt': prompt,
                'negative_prompt': negativePrompt,
                'guidance_scale': guidanceScale,
                'num_inference_steps': numInferenceSteps,
                'width': width,
                'height': height,
                'seed': seed,
                'ref_image': ref_image,
                'ref_image_scale_start': ref_image_scale_start,
                'ref_image_scale_finish': ref_image_scale_finish,
                'foreground_image': foreground_image,
                'foreground_mask_image': foreground_mask_image,
            }),
        },
    );

    if (response.ok) {

        const result = await response.json();

        if (Array.isArray(result.images) && typeof (result.images[0]) === 'string') {
            setRenderResultMessage("");
            setRenderResults(result.images);
        } else {
            setRenderResultMessage(result?.message || "Cannot parse result images.");
            setRenderResults([]);
        }

    } else {
        const message = (await response.json())?.message;

        setRenderResultMessage(message);

        setRenderResults([]);

    }

    setApiRenderState(ApiRenderState.Idle);
}

export async function submitGenerateImageApiRequest({
    apiState,
    apiRenderState,
    setApiRenderState,
    setRenderResults,
    setRenderResultMessage,
    setPlaygroundTab,
}: SubmitApiRequestProps) {
    try {
        const {
            userApiData,
        } = editorContextStore.getState();

        if (!userApiData?.primaryKey) {
            setRenderResultMessage("No API subscription found.");
            setApiRenderState(ApiRenderState.Idle);
            setRenderResults([]);
            return;
        }

        if (apiState.pipelineType === ApiPipelineType.Default) {
            return await submitDefaultApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        } else if (apiState.pipelineType === ApiPipelineType.Canny) {
            return await submitCannyApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        } else if (apiState.pipelineType === ApiPipelineType.ProductPlacement) {
            return await submitProductPlacementApiRequest({
                apiState,
                apiRenderState,
                setApiRenderState,
                setRenderResults,
                setRenderResultMessage,
                setPlaygroundTab,
            });
        }

    } catch (error) {

        console.error(error);

        setRenderResultMessage("Unknown error in the backend.");
        setRenderResults([]);
    } finally {
        setApiRenderState(ApiRenderState.Idle);
    }
}