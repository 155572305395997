import React from 'react';
import { createPortal } from 'react-dom';
import { colors } from 'components/constants/colors';
import { CanvasFrameZIndex } from 'components/constants/zIndex';
import { editorContextStore } from 'contexts/editor-context';
import { DEFAULT_CANVAS_LENGTH, DEFAULT_RENDER_LENGTH } from 'core/common/constants';
import { LayerType } from 'core/common/layers';
import { GenerationFramePointerLeaveEventName, GenerationFramePointerOverEventName, RealTimeRenderCheckPointerOverHandler, RealTimeRenderGetResultEventHandler, RealTimeRenderSaveResultEventHandler } from 'core/common/types';
import { Editor } from 'core/editor';
import { classNames } from 'core/utils/classname-utils';
import { getObjectCoordsOverlapArea, getObjectWorldCoords } from 'core/utils/geometry-utils';
import { isStaticImageObjectGenerated, isStaticImageObjectHed } from 'core/utils/type-guards';
import { GenerationFrameObjectIcon } from 'components/canvas-frames/generation-frame-object-icon';
import { isStaticImageObject3d } from 'core/common/types/3d';
import { RealTimeRenderMode, RealTimeRenderStatus } from 'core/common/types/realtime-render';
import { Tooltip } from 'components/utils/tooltip';
import { Plus, RefreshCcw } from 'lucide-react';
import { onRenderImageResultAdded, uploadRenderInputImage } from 'components/utils/render';
import { nanoid } from 'nanoid';
import { clamp } from 'lodash';
import { getPromptFromTemplate } from 'core/common/prompt-template';
import generationFrameStyles from './generation-frame.module.css';
import { HttpProtocol, RealTimeServerTimeOutSeconds, RealTimeServerUrl } from 'components/editor/realtime-render-websocket-controller';
import { FloatTagButtonWithTooltip } from 'components/editor/float-tag/float-tag-button';
import { FLOAT_TAG_HEIGHT } from 'components/constants/float-tags';
import { CANVAS_CONTAINER_ID, EDITOR_FLOAT_TAG_CONTAINER_ID } from 'components/constants/ids';
import { RenderButton } from 'components-mobile/panels/panel-items/generate/render-button';

function updateObjectsIntersectionGenerationFrame() {
    const { editor } = editorContextStore.getState();
    if (!editor) {
        return;
    }
    editor.generationFrames.updateImagesIntersectingGenerationFrame();
}

function getNumGeneratedImages() {
    const { editor } = editorContextStore.getState();
    if (!editor) {
        return 0;
    }
    return editor.objects.find(isStaticImageObjectGenerated).length;
}

function getGenerationFrameColor(
    isSelected = false,
    isCrowded = false,
) {
    if (isCrowded) {
        return isSelected ? colors.red[300] : colors.red[500];
    }
    return isSelected ? colors.lime[300] : colors.lime[500];
}

function getGenerationFrameMessage({
    isCrowded,
    isCoveredByGeneratedImage,
}: {
    isCrowded: boolean,
    isCoveredByGeneratedImage?: boolean,
}) {
    if (isCrowded) {
        return 'Only one product here is ideal.';
    }
    if (isCoveredByGeneratedImage) {
        return 'Only product image should be inside.';
    }
    return 'Drag and Drop your own product';
}

function GenerationFrameMessage({
    isEmpty,
    isCrowded,
    isCoveredByGeneratedImage = false,
}: {
    isEmpty: boolean,
    isCrowded: boolean,
    isCoveredByGeneratedImage?: boolean,
}) {
    const visible = isEmpty || isCrowded || isCoveredByGeneratedImage;
    const message = getGenerationFrameMessage({
        isCrowded,
        isCoveredByGeneratedImage,
    });
    return (
        <div
            className={classNames(
                'relative flex-1 w-full flex flex-col items-center justify-center transition-opacity',
            )}
            style={{
                opacity: visible ? 1 : 0,
            }}
        >
            <div className='flex-1'>
            </div>
            <div
                className='w-full text-center mb-2 truncate'
            >
                {message}
            </div>
        </div>
    )
}

function getGenerationResultsPreviewMessage({
    isEmpty,
}: {
    isEmpty: boolean,
}) {
    if (isEmpty) {
        return 'Step 2: Click the "Generate" button.';
    }
    return 'Click the "Generate" button.';
}

function RealTimeGenerationProgressBar() {
    const realtimeRenderProgress = editorContextStore(state => state.realtimeRenderProgress);

    const [widthPercent, setWidthPercent] = React.useState(0);

    React.useEffect(() => {
        setWidthPercent(clamp(realtimeRenderProgress * 100, 0, 100));
    }, [realtimeRenderProgress]);

    return (
        <div
            className='absolute left-0 bottom-0 h-[2px] bg-lime-500 ransition-width duration-500 ease-in-out'
            style={{
                width: `${widthPercent}%`,
            }}
        >
        </div>
    );
}

/* @tw */
const realtimeControlPanelButtonClassNameBase = "p-1 rounded bg-zinc-900/30";

/* @tw */
const realtimeControlPanelButtonClassNameActive = "hover:bg-lime-700/60 active:bg-lime-800/50 text-lime-400/80 hover:text-lime-400 pointer-events-auto";

/* @tw */
const realtimeControlPanelButtonClassNameInactive = "hover:bg-lime-800/50 text-lime-400/80 pointer-events-auto";

/* @tw */
const realtimeControlPanelButtonClassNameDisabled = "bg-zinc-600/50";

/* @tw */
const FloatTagButtonCommonClassName = 'min-w-0 flex flex-row items-center justify-center text-sm font-semibold gap-2'

export function SaveResultsIcon() {
    return (
        <button
            className={classNames(
                realtimeControlPanelButtonClassNameBase,
                realtimeControlPanelButtonClassNameActive,
            )}
        >
            <Plus
                size={16}
            />
        </button>
    )
}

function SaveResultsButton() {
    const editor = editorContextStore(state => state.editor);
    return (
        <FloatTagButtonWithTooltip
            disabled={editor == null}
            className={FloatTagButtonCommonClassName}
            tooltipChildren="Add the generated image to canvas."
            onClick={() => {
                if (!editor) {
                    return;
                }

                editor.emit<RealTimeRenderSaveResultEventHandler>(
                    'realtime-render:save-result',
                );
            }}
        >
            <Plus size={16} className='text-zinc-500' />
            <span className='truncate'>
                Add
            </span>
        </FloatTagButtonWithTooltip>
    );
}

export function ReGenerateIcon() {

    return (
        <button
            className={classNames(
                realtimeControlPanelButtonClassNameBase,
                realtimeControlPanelButtonClassNameActive
            )}
        >
            <RefreshCcw
                size={16}
            />
        </button>
    );
}

export function useRealtTimeIsRendering() {
    const realtimeRenderProgress = editorContextStore(state => state.realtimeRenderProgress);
    return React.useMemo(() => realtimeRenderProgress > 0 && realtimeRenderProgress < 1, [realtimeRenderProgress]);

}

function useRealtTimeRegenerationCallbackInternal(
    isRendering: boolean,
) {
    return React.useCallback(() => {
        const {
            realtimeRenderController,
        } = editorContextStore.getState();

        if (isRendering || !realtimeRenderController) {
            return;
        }

        realtimeRenderController.render();
    }, [
        isRendering,
    ]);
}

export function useRealtTimeRegenerationCallback() {
    const isRendering = useRealtTimeIsRendering();

    return {
        isRendering,
        realtimeRegenerateCallback: useRealtTimeRegenerationCallbackInternal(isRendering)
    };
}

function ReGenerateButton() {
    const isRendering = useRealtTimeIsRendering();

    const realtimeRegenerateCallback = useRealtTimeRegenerationCallbackInternal(isRendering);

    const realtimeRenderProgress = editorContextStore(state => state.realtimeRenderProgress);

    return (
        <FloatTagButtonWithTooltip
            disabled={isRendering}
            className={classNames(
                FloatTagButtonCommonClassName,
                isRendering ? "text-zinc-500" : "",
            )}
            tooltipChildren={(
                <div>
                    {isRendering ?
                        "Generating ..." :
                        "Regenerate the image in real-time mode."}
                </div>
            )}
            onClick={realtimeRegenerateCallback}
        >
            <RefreshCcw
                size={16}
                className={classNames(
                    'text-zinc-500',
                    isRendering ? "animate-spin" : "",
                )}
            />
            <span
                className={classNames(
                    'truncate transition-opacity',
                    isRendering ? generationFrameStyles.ProgressText : "",
                )}
                // @ts-ignore
                style={{ '--progress-percentage': realtimeRenderProgress }}
            >
                Refresh
            </span>
        </FloatTagButtonWithTooltip>
    );
}

function RealTimeGenerationProgressValue() {
    const realtimeRenderProgress = editorContextStore(state => state.realtimeRenderProgress);

    return (
        <div className={classNames(
            realtimeControlPanelButtonClassNameBase,
            'min-w-0 truncate text-xs',
        )}>
            {`${Math.floor(realtimeRenderProgress * 100)}%`}
        </div>
    );
}

const ControlPanelButtonGroupOffsetTop = 8;
const ControlPanelButtonGroupWidth = 180;

const RealTimeGenerationControlPanelButtonGroup = React.forwardRef(function RealTimeGenerationControlPanelButtonGroup({
    className = "",
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                className,
                "flex items-center justify-center pointer-events-auto",
            )}
            style={{
                width: `${ControlPanelButtonGroupWidth}px`,
            }}
            {...props}
        >
            <div
                className="max-w-full px-1 py-1 rounded-md flex flex-row items-center justify-center text-sm text-zinc-300 bg-zinc-800 shadow-md border border-zinc-700 transition-opacity"
            >
                <SaveResultsButton />
                <ReGenerateButton />
            </div>
        </div>
    )
})

function RealTimeGenerationControlPanel() {
    return (
        <div
            className='absolute left-0 bottom-0 w-full z-50'
        >
            <div className='flex flex-row gap-1 p-1 w-full items-center justify-end pointer-events-none'>
                <RealTimeGenerationProgressValue />
            </div>
            <RealTimeGenerationProgressBar />
        </div>
    )
}

function RealTimeGenerationLoadingCover() {
    const realtimeRenderProgress = editorContextStore(state => state.realtimeRenderProgress);

    return (
        <div
            className={classNames(
                realtimeRenderProgress < 1 ? generationFrameStyles.OpacityPulse : "opacity-0",
                'absolute left-0 top-0 w-full h-full pointer-events-none select-none bg-cover transition-opacity z-20'
            )}
            style={{
                backgroundImage: 'url("https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fc05c779-7137-4c66-cc63-6798da94a600/public")',
            }}
        >
        </div>
    )
}

function getRealTimeRenderResult(imageElement: HTMLImageElement) {
    const {
        realtimeColorCorrectImageUrl,
    } = editorContextStore.getState();

    if (realtimeColorCorrectImageUrl) {
        return realtimeColorCorrectImageUrl;
    }

    const canvas = document.createElement("canvas");

    // canvas.width = imageElement.width;
    // canvas.height = imageElement.height;

    canvas.width = DEFAULT_RENDER_LENGTH;
    canvas.height = DEFAULT_RENDER_LENGTH;

    const context = canvas.getContext('2d');
    if (!context) {
        return;
    }

    context.drawImage(
        imageElement,
        0, 0, DEFAULT_RENDER_LENGTH, DEFAULT_RENDER_LENGTH,
        0, 0, DEFAULT_RENDER_LENGTH, DEFAULT_RENDER_LENGTH,
    );

    return canvas.toDataURL('image/png');
}

async function saveRealTimeRenderResult({
    imageElement,
    offsetX = 1,
}: {
    imageElement: HTMLImageElement,
    offsetX?: number,
}) {
    const {
        editor,
        realtimeRenderController,
        generateToolPromptTemplate,
    } = editorContextStore.getState();

    if (!editor || !realtimeRenderController) {
        console.log('Editor is invalid');
        return;
    }

    const url = getRealTimeRenderResult(imageElement);

    if (!url) {
        return;
    }

    const width = DEFAULT_RENDER_LENGTH;
    const height = DEFAULT_RENDER_LENGTH;

    const generationFrameCenter = editor.objects.getGenerationFrame()?.getCenterPoint() || editor.canvas.getCenterPoint();
    const location = generationFrameCenter.setX(
        generationFrameCenter.x + width * 1.01,
    );


    if (offsetX !== 0) {
        location.setX(
            location.x + offsetX * DEFAULT_CANVAS_LENGTH,
        );
    }


    const generationId = nanoid();


    const resultImageObject = await editor.objects.addImageFromUrl({
        url,
        location,
        uploadStorage: true,
        setActive: false,
        generationId,
        targetHeight: height,
    });



    if (!resultImageObject) {
        return;
    }

    const renderInputImage = realtimeRenderController.renderInputImage;
    const renderInputMaskImage = realtimeRenderController.renderInputMaskImage;

    onRenderImageResultAdded({
        outputImage: resultImageObject,
        prompt: getPromptFromTemplate(generateToolPromptTemplate),
        inputImagePath: renderInputImage,
        inputMaskImagePath: renderInputMaskImage,
        promptTemplate: generateToolPromptTemplate,
    });
}

function RealTimeGenerationResultsPreview() {
    const editor = editorContextStore(state => state.editor);

    const containerElementRef = React.useRef<HTMLDivElement | null>(null);

    const imageElementRef = React.useRef<HTMLImageElement | null>(null);

    const colorCorrectionResultRef = React.useRef<HTMLImageElement | null>(null);

    const buttonGroupElementRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (!editor) {
            return;
        }

        const image = imageElementRef.current;

        if (!image) {
            return;
        }

        const handleSaveRealTimeResult = async (offsetX = 1) => {
            // Save the real-time generated result
            if (!imageElementRef.current) {
                return;
            }

            await saveRealTimeRenderResult({
                imageElement: imageElementRef.current,
                offsetX,
            });
        };

        const handleGetRealTimeResult = (onResult: (imageUrl: string | undefined) => void) => {
            if (!imageElementRef.current) {
                return onResult(undefined);
            }

            return onResult(getRealTimeRenderResult(imageElementRef.current));
        }

        const handleCheckPointerOver: RealTimeRenderCheckPointerOverHandler['handler'] = (
            pointerCoordinate,
            callback,
        ) => {
            if (!containerElementRef.current) {
                return;
            }

            // Get the bounding rectangle of the element
            const rect = containerElementRef.current.getBoundingClientRect();

            // Check if the pointer coordinates are within the rectangle
            const isPointerOver = pointerCoordinate.x >= rect.left &&
                pointerCoordinate.x <= rect.right &&
                pointerCoordinate.y >= rect.top &&
                pointerCoordinate.y <= rect.bottom;

            editorContextStore.getState().realtimeRenderIsPointerOverResultRef.current = isPointerOver;

            callback?.(isPointerOver);
        }

        editor.on<RealTimeRenderGetResultEventHandler>(
            'realtime-render:get-result',
            handleGetRealTimeResult,
        );

        editor.on<RealTimeRenderSaveResultEventHandler>(
            'realtime-render:save-result',
            handleSaveRealTimeResult,
        );

        editor.on<RealTimeRenderCheckPointerOverHandler>(
            'realtime-render:check-pointer-over',
            handleCheckPointerOver,
        );

        return () => {
            editor.off<RealTimeRenderGetResultEventHandler>(
                'realtime-render:get-result',
                handleGetRealTimeResult,
            );

            editor.off<RealTimeRenderSaveResultEventHandler>(
                'realtime-render:save-result',
                handleSaveRealTimeResult,
            );

            editor.off<RealTimeRenderCheckPointerOverHandler>(
                'realtime-render:check-pointer-over',
                handleCheckPointerOver,
            );

            editorContextStore.getState().realtimeRenderIsPointerOverResultRef.current = false;
        };
    }, [editor]);

    const realtimeRenderStatus = editorContextStore(state => state.realtimeRenderStatus);

    const realtimeUserId = editorContextStore(state => state.realtimeUserId);

    const colorCorrectionImageUrl = editorContextStore(state => state.realtimeColorCorrectImageUrl);

    const realtimeServerId = editorContextStore(state => state.realtimeServerId);

    const isRealTimeRendering = React.useMemo(() => (
        realtimeUserId != null &&
        realtimeServerId != null &&
        realtimeRenderStatus !== RealTimeRenderStatus.DISCONNECTED &&
        realtimeRenderStatus !== RealTimeRenderStatus.TIMEOUT
    ), [
        realtimeRenderStatus,
        realtimeUserId,
        realtimeServerId,
    ]);


    const [uniqueKey, setUniqueKey] = React.useState(0); // To trigger reconnection

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment the uniqueKey every RealTimeServerTimeOutSeconds seconds to change the src and re-establish the connection
            setUniqueKey(prevKey => prevKey + 1);
        }, RealTimeServerTimeOutSeconds * 1000);

        return () => clearInterval(intervalId); // Clear the interval on realtime user id
    }, [
        realtimeUserId,
        realtimeServerId,
    ]);

    const getImageSrc = () => {
        if (isRealTimeRendering) {
            // Append the uniqueKey as a query parameter to force reconnection
            return `${HttpProtocol}://${RealTimeServerUrl}/api/stream/${realtimeUserId}?realtime_server=${realtimeServerId}&uniqueKey=${uniqueKey}`;
            // return `http://${RealTimeServerUrl}/api/stream/${realtimeUserId}?uniqueKey=${uniqueKey}`; // For local testingwhen there's only one instance
        }
        // Fallback or initial src
        // Smallest image: https://stackoverflow.com/questions/9126105/blank-image-encoded-as-data-uri
        return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
    };

    const editorFloatTagContainer = document.getElementById(CANVAS_CONTAINER_ID);

    const updateControlButtonPosition = React.useCallback(() => {
        const containerElement = containerElementRef.current;

        if (!containerElement) {
            return;
        }

        const buttonGroupElement = buttonGroupElementRef.current;

        if (!buttonGroupElement) {
            return;
        }

        let parentLeft = 0;
        let parentTop = 0;

        const parentElement = buttonGroupElement.parentElement;

        if (parentElement) {

            const parentBoundingRect = parentElement.getBoundingClientRect();

            parentLeft = parentBoundingRect.left;
            parentTop = parentBoundingRect.top;

        }

        const {
            left,
            right,
            bottom,
        } = containerElement.getBoundingClientRect();

        const center = (left + right) / 2;

        const buttonGroupLeft = center - ControlPanelButtonGroupWidth / 2 - parentLeft;
        const buttonGroupTop = bottom - parentTop + ControlPanelButtonGroupOffsetTop;

        buttonGroupElement.style.left = `${buttonGroupLeft}px`;
        buttonGroupElement.style.top = `${buttonGroupTop}px`;
    }, []);

    React.useEffect(() => {
        const canvas = editor?.canvas.canvas;

        if (!canvas) {
            return;
        }

        updateControlButtonPosition();

        const handleBeforeRender = updateControlButtonPosition;

        canvas.on('before:render', handleBeforeRender);

        return () => {
            canvas.off('before:render', handleBeforeRender);
        }

    }, [
        editor,
        updateControlButtonPosition,
    ]);

    return (
        <div
            ref={containerElementRef}
            className='absolute w-full h-full flex flex-col rounded-sm border border-lime-500 transition-opacity'
            style={{
                left: '100%',
                top: 0,
            }}
        >
            <img
                ref={colorCorrectionResultRef}
                className='absolute w-full object-fit z-10 transition-opacity'
                crossOrigin='anonymous'
                style={{
                    opacity: colorCorrectionImageUrl ? '1.0' : '0.0',
                }}
                src={colorCorrectionImageUrl}
                alt="color-correction"
            />
            <img
                ref={imageElementRef}
                className='w-full object-fit z-0'
                crossOrigin='anonymous'
                src={getImageSrc()}
                alt="realtime-preview"
            />
            <RealTimeGenerationControlPanel />
            <RealTimeGenerationLoadingCover />
            {editorFloatTagContainer ?
                createPortal(
                    (
                        <RealTimeGenerationControlPanelButtonGroup
                            ref={buttonGroupElementRef}
                            className='absolute'
                        />
                    ),
                    editorFloatTagContainer,
                ) : null
            }
        </div>
    );
}

type GenerationResultsDefaultProps = {
    isEmpty: boolean,
    isCrowded: boolean,
    numGeneratedImages: number,
}

function GenerationResultsDefault({
    isEmpty,
    isCrowded,
    numGeneratedImages = 0,
}: GenerationResultsDefaultProps) {
    const generateToolIsRendering = editorContextStore(state => state.generateToolIsRendering);

    const visible = (!isCrowded && numGeneratedImages === 0) || generateToolIsRendering;

    const message = getGenerationResultsPreviewMessage({
        isEmpty,
    });

    return (
        <div
            className='absolute px-2 py-1 w-full h-full flex flex-col rounded-sm bg-radial-lime-500 transition-opacity'
            style={{
                left: '100%',
                top: 0,
                opacity: visible ? 1 : 0,
            }}
        >
            <div className='w-full truncate'>
                Generated images will appear here
            </div>
            <div className='flex-1' />
            {!generateToolIsRendering &&
                <div
                    className='w-full text-center mb-2 truncate'
                >
                    {message}
                </div>}
        </div>
    )
}

function GenerationResultsPreview({
    ...props
}: {
    isEmpty: boolean,
    isCrowded: boolean,
    numGeneratedImages: number,
}) {
    const realtimeRenderMode = editorContextStore(state => state.realtimeRenderMode);

    if (realtimeRenderMode === RealTimeRenderMode.Active) {
        return (
            <RealTimeGenerationResultsPreview />
        );
    }

    return (
        <GenerationResultsDefault
            {...props}
        />
    );
}

function isFrameCoveredByGeneratedResult(
    editor: Editor | null,
    objectsInsideGenerationFrame: fabric.Object[],
    overlapAreaThreshold = 0.5,
) {
    if (!editor) {
        return;
    }
    const generationFrame = editor.objects.getGenerationFrame();
    if (!generationFrame) {
        return false;
    }
    const generationFrameBounds = getObjectWorldCoords(generationFrame);
    const [tl, tr, br, bl] = generationFrameBounds;
    const generationFrameAreaInv = 1.0 / ((br.x - tl.x) * (br.y - tl.y));
    const generatedResult = objectsInsideGenerationFrame.find((object) => {
        if (!isStaticImageObjectGenerated(object)) {
            return false;
        }
        const area = getObjectCoordsOverlapArea(
            generationFrameBounds,
            getObjectWorldCoords(object),
        ) * generationFrameAreaInv;
        return area > overlapAreaThreshold;
    });
    return generatedResult != null;
}

function isGenerationFrameCrowded(
    objectsInsideGenerationFrame: fabric.Object[],
) {
    return false;
    // return objectsInsideGenerationFrame.filter((object) => !isStaticImageObjectHed(object) && !isStaticImageObject3d(object)).length > 1;
}

function GenerationFrameToolbar() {
    const [isGenerateError, setIsGenerateError] = React.useState(false);

    return (
        <div
            className='absolute flex flex-row items-center justify-center w-full bottom-[-38px] h-[36px] pointer-events-auto'
        >
            <RenderButton
                className='truncate'
                isGenerateError={isGenerateError}
                setIsGenerateError={setIsGenerateError}
            />
        </div>
    )
}

export function GenerationFrame({
    visible = true,
}: {
    visible?: boolean,
}) {
    const editor = editorContextStore(state => state.editor);
    const objectsInsideGenerationFrame = editorContextStore(state => state.objectsInsideGenerationFrame);

    const [numGeneratedImages, setNumGeneratedImages] = React.useState(0);

    const {
        isEmpty,
        isCrowded,
        isCoveredByGeneratedImage,
    } = React.useMemo(() => ({
        isEmpty: objectsInsideGenerationFrame.length <= 0,
        isCrowded: isGenerationFrameCrowded(objectsInsideGenerationFrame),
        isCoveredByGeneratedImage: isFrameCoveredByGeneratedResult(editor, objectsInsideGenerationFrame),
    }), [editor, objectsInsideGenerationFrame]);

    const isError = React.useMemo(() => isCrowded || isCoveredByGeneratedImage, [isCrowded, isCoveredByGeneratedImage]);
    const canvasContainerRef = React.useRef<HTMLDivElement | null>(null);
    const isSelectedRef = React.useRef(false);
    const isInpaintPointerDownRef = React.useRef(false);
    const isPointerOverRef = React.useRef(false);

    React.useEffect(() => {
        if (!canvasContainerRef.current) {
            return;
        }
        canvasContainerRef.current.style.outlineColor = getGenerationFrameColor(isSelectedRef.current, isError);
    }, [isError]);


    const onPointerOver = React.useCallback(() => {
        isPointerOverRef.current = true;
    }, []);
    const onPointerLeave = React.useCallback(() => {
        isPointerOverRef.current = false;
    }, []);
    const onPointerUp = React.useCallback(() => {
    }, []);

    const onBeforeRender = React.useCallback(() => {
        if (editor && canvasContainerRef.current) {

            const generationFrame = editor.objects.getGenerationFrame();
            if (generationFrame) {

                if (!generationFrame.visible) {
                    canvasContainerRef.current.style.display = 'none';
                    return;
                } else {
                    canvasContainerRef.current.style.display = 'block';
                }

                generationFrame.setCoords();

                const generationFrameOCoords = generationFrame.oCoords;

                if (!generationFrameOCoords) {
                    return;
                }

                const tl = generationFrameOCoords.tl;
                const br = generationFrameOCoords.br;
                canvasContainerRef.current.style.left = `${tl?.x || 0}px`;
                canvasContainerRef.current.style.top = `${tl?.y || 0}px`;

                // const backgroundImage = editor.objects.getBackgroundImage();

                // if (backgroundImage) {

                //     backgroundImage.left = generationFrame.left;
                //     backgroundImage.top = generationFrame.top;
                //     backgroundImage.setCoords();

                // }

                const displayWidth = br.x - tl.x;
                const displayHeight = br.y - tl.y;
                if (displayWidth > 0) {
                    canvasContainerRef.current.style.width = `${displayWidth}px`;
                    canvasContainerRef.current.style.height = `${displayHeight}px`;
                }
            }


        }
    }, [
        editor,
    ]);

    const onSelectionCreated = React.useCallback((e: fabric.IEvent<Event>) => {
        if (!editor) {
            return;
        }
        const activeObject = editor.canvas.canvas?.getActiveObject();
        if (!activeObject) {
            return;
        }

        if (!canvasContainerRef.current) {
            return;
        }

        isSelectedRef.current = activeObject.type === LayerType.GENERATION_FRAME;

        canvasContainerRef.current.style.outlineColor = getGenerationFrameColor(isSelectedRef.current, isError);
    }, [editor, isError]);

    const onSelectionCleared = React.useCallback((e: fabric.IEvent<Event>) => {
        isSelectedRef.current = false;
        if (canvasContainerRef.current) {
            canvasContainerRef.current.style.outlineColor = getGenerationFrameColor(false, isError);
        }
    }, [isError]);

    const handleObjectsUpdate = React.useCallback(() => {
        updateObjectsIntersectionGenerationFrame();
        setNumGeneratedImages(getNumGeneratedImages());
    }, []);

    React.useEffect(() => {
        editor?.once(
            'editor:init',
            () => {
                setTimeout(() => {
                    handleObjectsUpdate();
                }, 1000);
            }
        );
    }, [editor, handleObjectsUpdate]);

    const onObjectAdded = React.useCallback(() => {
        handleObjectsUpdate();
    }, [handleObjectsUpdate]);

    const onObjectModified = React.useCallback((e: fabric.IEvent<Event>) => {
        const activeObject = e.target;
        if (activeObject?.type === LayerType.GENERATION_FRAME) {
            if (canvasContainerRef.current) {
                const tl = activeObject.oCoords?.tl;
                canvasContainerRef.current.style.left = `${tl?.x || 0}px`;
                canvasContainerRef.current.style.top = `${tl?.y || 0}px`;
            }
        }
        handleObjectsUpdate();
    }, [handleObjectsUpdate]);

    const onObjectRemoved = React.useCallback(() => {
        handleObjectsUpdate();
    }, [handleObjectsUpdate]);

    const handleCanvasContainerMount = React.useCallback((canvasContainer: HTMLDivElement) => {
        canvasContainerRef.current = canvasContainer;
        if (canvasContainer) {
            canvasContainer?.addEventListener(
                'wheel',
                (e) => {
                    if (!isInpaintPointerDownRef.current) {
                        editor?.events.onMouseWheel({ e });
                    }
                },
                { passive: false }
            );
        }
    }, [editor]);

    const initEventHandlers = React.useCallback(() => {
        const canvas = editor?.canvas.canvas;
        if (canvas) {
            canvas.on('before:render', onBeforeRender);
            canvas.on('selection:created', onSelectionCreated);
            canvas.on('selection:updated', onSelectionCreated);
            canvas.on('selection:cleared', onSelectionCleared);
            canvas.on('object:modified', onObjectModified);
            canvas.on('object:added', onObjectAdded);
            canvas.on('object:removed', onObjectRemoved);
            canvas.on('mouse:up', onPointerUp);
            canvas.on(GenerationFramePointerOverEventName, onPointerOver);
            canvas.on(GenerationFramePointerLeaveEventName, onPointerLeave);
            updateObjectsIntersectionGenerationFrame();
        }
    }, [
        editor,
        onBeforeRender,
        onObjectAdded,
        onObjectModified,
        onSelectionCreated,
        onSelectionCleared,
        onObjectRemoved,
        onPointerUp,
        onPointerOver,
        onPointerLeave,
    ]);

    const removeEventHandlers = React.useCallback(() => {
        const canvas = editor?.canvas.canvas;
        if (canvas) {
            canvas.off('before:render', onBeforeRender);
            canvas.off('selection:created', onSelectionCreated);
            canvas.off('selection:updated', onSelectionCreated);
            canvas.off('selection:cleared', onSelectionCleared);
            canvas.off('object:modified', onObjectModified);
            canvas.off('object:added', onObjectAdded);
            canvas.off('object:removed', onObjectRemoved);
            canvas.off('mouse:up', onPointerUp);
            canvas.off(GenerationFramePointerOverEventName, onPointerOver);
            canvas.off(GenerationFramePointerLeaveEventName, onPointerLeave);
        }
    }, [
        editor,
        onBeforeRender,
        onSelectionCreated,
        onSelectionCleared,
        onObjectModified,
        onObjectAdded,
        onObjectRemoved,
        onPointerUp,
        onPointerOver,
        onPointerLeave,
    ]);

    React.useEffect(() => {
        initEventHandlers();
        return () => {
            removeEventHandlers();
        }
    }, [
        initEventHandlers,
        removeEventHandlers,
    ]);

    return (
        <div
            ref={handleCanvasContainerMount}
            className={classNames(
                'absolute text-sm outline pointer-events-none select-none shadow-generation-frame transition-colors',
                isError ? 'text-red-500 shadow-red-500/20 outline-red-500' : 'text-lime-500 shadow-lime-500/20 outline-lime-500'
            )}
            style={{
                width: `${DEFAULT_CANVAS_LENGTH}px`,
                height: `${DEFAULT_CANVAS_LENGTH}px`,
                zIndex: CanvasFrameZIndex,
                outlineWidth: '2px',
                outlineOffset: '-1px',
                display: visible ? 'block' : 'none',
            }}
        >
            <div className='w-full h-full px-2 py-1 flex flex-col'>
                <div
                    className='w-full truncate'
                >
                    Place your product here
                </div>
                <GenerationFrameMessage
                    isEmpty={isEmpty}
                    isCrowded={isCrowded}
                    isCoveredByGeneratedImage={isCoveredByGeneratedImage}
                />
            </div>
            <GenerationResultsPreview
                isEmpty={isEmpty}
                isCrowded={isCrowded}
                numGeneratedImages={numGeneratedImages}
            />
        </div>
    );
}