export type RolloutFunction<T> = (userId: string) => T;

export type BackendUserFeatureFlags = { featureFlags: Partial<FeatureFlags>, isVIP: boolean };

export type FeatureFlags = {
    newFeature: boolean;
    testFeature: number;
    // Used for the big reference image in the editor tab
    BigGenerateReferenceImage: boolean;
    // Workflows feature for starting shortcuts
    Workflows: boolean;
};

export const DefaultFeatureFlags: FeatureFlags = {
    newFeature: false,
    testFeature: 0,
    BigGenerateReferenceImage: false,
    Workflows: false,
};

export const FeatureFlagRolloutFunctions: {
    [K in keyof FeatureFlags]?: RolloutFunction<FeatureFlags[K]>
} = {
    newFeature: () => Math.random() < 0.5,
    BigGenerateReferenceImage: () => Math.random() < 0.333,
    Workflows: () => true,
};
