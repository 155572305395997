import { QuestionMarkIcon } from '@radix-ui/react-icons';
import { defaultGenerateTemplateId } from 'components/constants/default-generate-template';
import { editorContextStore } from 'contexts/editor-context';
import { Folder } from 'lucide-react';
import React from 'react';
import type { StepByStepsTutorialConfig } from '../config'
import { Highlighter } from '../highlighter';
import { useStepByStepTutorialContext } from '../step-by-step-context';
import { inlineButtonClassName } from './constants';

function VideoContainer({
    src,
    style = {
        position: 'relative',
        paddingTop: '56.25%',
    },
    title = 'video',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    src: string,
    title?: string,
}) {
    return (
        <div
            {...props}
            style={style}
        >
            <iframe
                src={src}
                title={title}
                style={{
                    position: 'absolute',
                    border: 'none',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
            />
        </div>
    );
}

function WelcomeToFlair() {
    return (
        <div
            className='text-zinc-400'
        >
            <span>
                Flair uses AI to generate photoshoots for your product! Take this 4-step tutorial to create a product ad.
            </span>
        </div>
    );
}

const placeAssetHighlightItemId = "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/55fbb185-e305-487b-7908-52b948f26e00/public"

function PlaceAsset() {
    const [highlightId, setHighlightId] = React.useState<string | null>(placeAssetHighlightItemId);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);

    React.useEffect(() => {
        const activeLeftPanel = activeLeftPanels[activeLeftPanels.length - 1];
        setHighlightId(activeLeftPanel === 'Assets' ? placeAssetHighlightItemId : null);
    }, [activeLeftPanels]);

    React.useEffect(() => {
        setHighlightId(placeAssetHighlightItemId);
        editorContextStore.getState().setActiveLeftPanels(['Assets']);
    }, []);

    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} attachToTarget />}
            Use the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId(placeAssetHighlightItemId)}><Folder className='inline mr-1 mb-px' size={15} /> Assets</span> panel on the bottom to add images into the editor.<br />
        </div>
    );
}

const defaultGenerateTemplateElementId = 'test_077';
function UseGenerateToolTemplate() {
    const [highlightId, setHighlightId] = React.useState<string | null>(defaultGenerateTemplateElementId);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    const { setStep } = useStepByStepTutorialContext();
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);

    React.useEffect(() => {
        const activeLeftPanel = activeLeftPanels[activeLeftPanels.length - 1];
        setHighlightId(activeLeftPanel === 'Generate' ? defaultGenerateTemplateElementId : null);
    }, [activeLeftPanels]);

    React.useEffect(() => {
        setTimeout(() => {
            if (editorContextStore.getState().generateToolEditorTab === 'Templates') {
                return;
            }
            setStep(3);
        }, 100);
    }, [generateToolEditorTab, setStep]);

    React.useEffect(() => {
        const {
            setActiveObject,
            setActiveLeftPanels,
            setGenerateToolEditorTab,
        } = editorContextStore.getState();
        setActiveObject(null);
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Templates');
    }, []);

    console.log(defaultGenerateTemplateElementId);
    return (
        <div
            className='text-zinc-400'
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} attachToTarget={highlightId === defaultGenerateTemplateElementId} />}
            Select a template to configure the prompt.<br /><br />
        </div>
    )
}

function ConfigPrompt() {
    const [highlightId, setHighlightId] = React.useState<string | null>('prompt-editor-subject-0');
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    const setGenerateToolEditorTab = editorContextStore(state => state.setGenerateToolEditorTab);
    React.useEffect(() => {
        setHighlightId('prompt-editor-subject-0');
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Editor');
    }, [setActiveLeftPanels, setGenerateToolEditorTab]);
    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} />}
            Fill out the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('prompt-editor-subject-0')} onPointerOut={() => setHighlightId('left-panel-prompt-editor-form')}>Subject</span> describing the image in the canvas (e.g. bottle). This value is required.<br /><br />
        </div>
    );
}

function autofillSubject() {
    const {
        generateToolPromptTemplate,
        setGenerateToolPromptTemplate,
    } = editorContextStore.getState();

    let subjectIndex = generateToolPromptTemplate.words.findIndex(word => word.autocompleteType === 'subject');

    if (subjectIndex < 0) {
        setGenerateToolPromptTemplate((template) => {
            return {
                ...template,
                words: [
                    {
                        type: 'fixed',
                        value: 'bottle',
                        autocompleteType: 'subject'
                    },
                    ...template.words,
                ],
            }
        });
        return true;
    } else if (!generateToolPromptTemplate.words[subjectIndex]?.value) {
        setGenerateToolPromptTemplate((template) => {
            const newWords = template.words.slice();
            newWords[subjectIndex] = {
                type: 'fixed',
                value: 'bottle',
                autocompleteType: 'subject',
            }
            return {
                ...template,
                words: newWords,
            }
        });
        return true;
    }
    return false;
}

function ClickGenerate() {
    const [highlightId, setHighlightId] = React.useState<string | null>('generate-button');
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    React.useEffect(() => {
        setHighlightId('generate-button');
        setActiveLeftPanels(['Generate']);
        autofillSubject();
    }, [setActiveLeftPanels]);
    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} attachToTarget />}
            Hit the green <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('generate-button')} onPointerOut={() => setHighlightId('generate-button')}>Generate</span> button on the bottom panel.<br /><br />
            Wait for a few seconds, and the resulting image will appear to the right of the <span className='text-lime-500'>Canvas</span>.<br /><br />
            Click on the generated image, and you can find more options to download / edit the image on the bottom panel.<br />
        </div>
    );
}

function Ending() {
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    const setGenerateToolEditorTab = editorContextStore(state => state.setGenerateToolEditorTab);
    React.useEffect(() => {
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Templates');
    }, [setActiveLeftPanels, setGenerateToolEditorTab]);
    return (
        <div className='text-zinc-400 flex flex-col'>
            <div className='mb-2'>
                That's it for the short introduction. Try select another template to see how different prompts influence the generation.<br /><br />
                Now let's build some cool designs!
            </div>
        </div>
    )
}

export const stepByStepsFirstOnboardConfig: StepByStepsTutorialConfig = {
    name: 'Editor First Onboard Tutorial',
    hasWelcome: true,
    hasEnding: true,
    displayDone: true,
    heightRatio: 0.25,
    topRatio: 0.05,
    steps: [
        {
            title: 'Welcome to Flair!',
            Content: WelcomeToFlair,
        },
        {
            title: 'Place asset into the Canvas',
            Content: PlaceAsset,
        },
        {
            title: 'Select a prompt template',
            Content: UseGenerateToolTemplate,
        },
        {
            title: 'Configure the prompt',
            Content: ConfigPrompt,
        },
        {
            title: 'Click generate!',
            Content: ClickGenerate,
        },
        {
            title: '🎉 That\'s it!',
            Content: Ending,
        }
    ]
}

function TemplateHighlighter() {
    const [show, setShow] = React.useState(false);
    const hasShownRef = React.useRef(false);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    React.useEffect(() => {
        hasShownRef.current = false;
    }, []);
    React.useEffect(() => {
        if (hasShownRef.current) {
            setShow(false);
            return;
        }
        if (!activeLeftPanels.includes('Generate')) {
            setShow(false);
            return;
        }
        if (generateToolEditorTab === 'Templates') {
            setShow(true);
            hasShownRef.current = true;
            return;
        }
        setShow(false);
    }, [activeLeftPanels, generateToolEditorTab]);
    if (!show) {
        return null;
    }
    return (
        <>
            <Highlighter type="box" elementId={defaultGenerateTemplateElementId} attachToTarget={true} />
        </>
    )
}

function TemplateTabHighlighter() {
    const [show, setShow] = React.useState(false);
    const hasShownRef = React.useRef(false);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    React.useEffect(() => {
        hasShownRef.current = false;
    }, []);
    React.useEffect(() => {
        if (hasShownRef.current) {
            setShow(false);
            return;
        }
        if (!activeLeftPanels.includes('Generate')) {
            setShow(false);
            return;
        }
        if (generateToolEditorTab === 'Editor') {
            setShow(true);
            hasShownRef.current = true;
            return;
        }
        setShow(false);
    }, [activeLeftPanels, generateToolEditorTab]);
    if (!show) {
        return null;
    }
    return (
        <>
            <Highlighter
                type="box"
                elementId='left-panel-generate-tab-Templates'
                popupContent='Try more prompts in the "Templates" tab.'
            />
        </>
    )
}

export function FirstOnboardDefaultHighlighters() {
    return (
        <>
            <TemplateHighlighter />
        </>
    );
}