import React from "react"
import { fabric } from "fabric"
import { classNames } from "core/utils/classname-utils";
import { DropdownClassName } from "components/constants/class-names";
import { MessageDialog } from "./message-dialog";
import { editorContextStore } from "contexts/editor-context";
import { getRawDataUrlFromImageObject, setImageObjectSrc } from "core/utils/image-utils";
import { ProgressHandler } from "components/utils/progress-handler";
import { displayUiMessage } from "components/utils/display-message";
import {
    MessageDialogContentInner,
    MessageDialogContentInnerProps,
} from './message-dialog-content';

export function RemoveImageObjectsBackgroundDialog({
    object: image,
    onClose,
    centerGenerationFrames = true,
}: {
    object: fabric.StaticImage,
    onClose?: () => void,
    centerGenerationFrames?: boolean,
}) {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [removeBg, setRemoveBg] = React.useState(false);
    const imageUrlRef = React.useRef<string | undefined>();
    const [imageNoBgUrl, setImageNoBgUrl] = React.useState<string | undefined>();
    const isBgRemovedRef = React.useRef(false);
    const isBgRemoveAppliedRef = React.useRef(false);
    const [removeBackgroundProgress, setRemoveBackgroundProgress] = React.useState(0);

    React.useEffect(() => {
        if (!image) {
            setOpen(false);
            return;
        }
        imageUrlRef.current = image.getSrc();
    }, [image]);

    React.useEffect(() => {
        if (!open) {
            const {
                editor,
                setActiveLeftPanels,
            } = editorContextStore.getState();
            setActiveLeftPanels(['Generate']);

            // Move center the generation frame onto the uploaded object

            if (editor && centerGenerationFrames) {
                editor.generationFrames.centerToObject(
                    // @ts-ignore
                    image,
                );
                editor.generationFrames.updateImagesIntersectingGenerationFrame();
            }

            onClose?.();
        }
    }, [open, onClose, image, centerGenerationFrames]);

    const handleOpenChange = React.useCallback((value: boolean, forceClose = false) => {
        if (!isLoading) {
            setOpen(value);
        }
    }, [isLoading]);

    const applyImageNoBgUrl = React.useCallback((url: string) => {
        if (isBgRemoveAppliedRef.current) {
            return;
        }
        isBgRemoveAppliedRef.current = true;
        // Apply the no background url
        setImageObjectSrc(
            image,
            url,
            (error) => displayUiMessage(error.message, 'error'),
        );
    }, [image]);

    const handleRemoveBackground = React.useCallback(async () => {
        try {
            if (!image) {
                return;
            }
            const { backend } = editorContextStore.getState();
            if (!backend) {
                return;
            }
            if (isBgRemovedRef.current) {
                return;
            }
            isBgRemovedRef.current = true;
            setIsLoading(true);
            const progressHandler = new ProgressHandler({
                setProgress: setRemoveBackgroundProgress,
            });
            const imageUrl = await getRawDataUrlFromImageObject({
                object: image,
            });
            if (!imageUrl) {
                setIsLoading(false);
                return;
            }
            const outputUrl = await backend.removeBackground({
                imageUrl,
                onError: (error) => {
                    console.error(error);
                    displayUiMessage(error.message, 'error');
                    setIsLoading(false);
                },
            });
            if (outputUrl) {
                setImageNoBgUrl(outputUrl);
                if (removeBg) {
                    applyImageNoBgUrl(outputUrl);
                }
            }
            setIsLoading(false);
            progressHandler.setProgress(1);
        } catch (error) {
            console.error(error);
            displayUiMessage((error as Error)?.message ?? error, 'error');
            setIsLoading(false);
            isBgRemovedRef.current = false;
        }
    }, [image, removeBg, applyImageNoBgUrl]);

    React.useEffect(() => {
        if (step === 0) {
            handleRemoveBackground();
        }
    }, [step, handleRemoveBackground]);

    const messageDialogProps: MessageDialogContentInnerProps[] = React.useMemo(() => ([
        {
            type: 'remove-background',
            image,
            setOpen,
            imageNoBgUrl,
            isLoading,
            setIsLoading,
            progress: removeBackgroundProgress,
            onClickNext: () => {
                setRemoveBg(true);
                if (imageNoBgUrl) {
                    applyImageNoBgUrl(imageNoBgUrl);
                }
                setStep((step) => step + 1);
            },
            onClickPrev: () => {
                setRemoveBg(false);
                setStep((step) => step + 1);
            },
        },
        {
            type: 'caption-subject',
            image,
            imageUrl: removeBg ? imageNoBgUrl : imageUrlRef.current,
            setOpen,
            isLoading,
            onClickNext: () => setOpen(false),
            onClickPrev: () => setStep((step) => step - 1),
        },
    ]), [
        image,
        isLoading,
        imageNoBgUrl,
        applyImageNoBgUrl,
        removeBackgroundProgress,
        removeBg,
    ]);

    return (
        <MessageDialog
            open={open}
            onOpenChange={handleOpenChange}
            contentChildren={
                <div
                    className={classNames(
                        DropdownClassName,
                        'min-w-[80vw] md:min-w-[680px] max-h-[80vh] px-6 py-5 flex flex-col items-center',
                    )}
                >
                    <MessageDialogContentInner
                        {...messageDialogProps[step % messageDialogProps.length]}
                    />
                </div>
            }
        />
    );
}