import { cleanupPrompt } from "components/utils/prompt";
import { editorContextStore } from "contexts/editor-context"
import { StateUpdater } from "core/common/types";
import { getPromptFromTemplate, getTemplateFromPrompt } from "core/common/prompt-template";
import { PromptEditorType, PromptState, PromptTemplate } from "core/common/types";
import { getTextContentFromPromptState } from "core/utils/text-utils";
import React from "react"

export function useGenerateToolPrompt() {
    
    const promptTemplate = editorContextStore(state => state.generateToolPromptTemplate);
    
    const setPromptTemplate = editorContextStore(state => state.setGenerateToolPromptTemplate);

    const prompt = React.useMemo(() => getPromptFromTemplate(promptTemplate), [promptTemplate]);

    const setPrompt = React.useCallback((prompt: StateUpdater<string>) => {
        if (typeof(prompt) === 'function') {
            setPromptTemplate(template => getTemplateFromPrompt(prompt(getPromptFromTemplate(template))));
        } else {
            setPromptTemplate(getTemplateFromPrompt(cleanupPrompt(prompt)));
        }
    }, [setPromptTemplate]);

    return {
        prompt,
        setPrompt,
        promptTemplate,
    };
}

function getGenerateToolPromptValueInternal(
    generateToolPromptState: PromptState,
    generateToolPromptTemplate: PromptTemplate,
    generateToolPromptEditorType: PromptEditorType,
) {
    if (generateToolPromptEditorType === 'Textarea') {
        return getTextContentFromPromptState(generateToolPromptState);
    }
    return getPromptFromTemplate(generateToolPromptTemplate);
}

export function getGenerateToolPromptValue() {
    const {
        generateToolPromptState,
        generateToolPromptTemplate,
        generateToolPromptEditorType,
    } = editorContextStore.getState();
    return getGenerateToolPromptValueInternal(
        generateToolPromptState,
        generateToolPromptTemplate,
        generateToolPromptEditorType,
    );
}

export function useGenerateToolPromptValue() {
    const generateToolPromptEditorType = editorContextStore(state => state.generateToolPromptEditorType);
    const generateToolPromptTemplate = editorContextStore(state => state.generateToolPromptTemplate);
    const generateToolPromptState = editorContextStore(state => state.generateToolPromptState);
    return getGenerateToolPromptValueInternal(
        generateToolPromptState,
        generateToolPromptTemplate,
        generateToolPromptEditorType,
    );
}