import React from "react"
import { EditorAlertDialog, EditorAlertDialogActionButton, EditorAlertDialogCancelButton, EditorAlertDialogContentContainer, EditorAlertDialogTitle } from "components/popup/alert-dialog";
import { editorContextStore } from "contexts/editor-context";
import { LeftPanelAlertCancelEventHandler, LeftPanelAlertConfirmEventHandler } from "core/common/types";

export function LeftPanelAlertDialog() {
    const isOpen = editorContextStore(state => state.isLeftPanelAlertOpen);
    const setOpen = editorContextStore(state => state.setLeftPanelAlertOpen);
    const alertProps = editorContextStore(state => state.leftPanelAlertProps);

    const {
        title = "Are you sure to exit?",
        subtitle = "",
    } = alertProps;

    return (
        <EditorAlertDialog
            open={isOpen}
            onOpenChange={setOpen}
            defaultOpen={false}
            contentProps={{
                onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    e.preventDefault();
                    setOpen(false);
                    editorContextStore.getState().editor?.emit<
                        LeftPanelAlertCancelEventHandler
                    >(
                        'left-panel:alert-cancel',
                    );
                }
            }}
            contentChildren={(
                <EditorAlertDialogContentContainer
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <EditorAlertDialogTitle
                        className="text-lg"
                    >
                        {title}
                    </EditorAlertDialogTitle>
                    <div
                        className="my-2 text-zinc-500"
                    >
                        {subtitle}
                    </div>
                    <div
                        className="flex flex-row mt-4 justify-end items-center"
                    >
                        <EditorAlertDialogCancelButton
                            className="min-w-[80px]"
                            onClick={() => {
                                editorContextStore.getState().editor?.emit<
                                    LeftPanelAlertCancelEventHandler
                                >(
                                    'left-panel:alert-cancel',
                                );
                            }}
                        >
                            Cancel
                        </EditorAlertDialogCancelButton>
                        <div className="mx-1" />
                        <EditorAlertDialogActionButton
                            className="min-w-[80px]"
                            onClick={() => {
                                editorContextStore.getState().editor?.emit<
                                    LeftPanelAlertConfirmEventHandler
                                >(
                                    'left-panel:alert-confirm',
                                );
                            }}
                        >
                            Exit
                        </EditorAlertDialogActionButton>
                    </div>
                </EditorAlertDialogContentContainer>
            )}
        />
    );
}