import { SetActiveHistoryHandler } from 'core/common/types';
import { IHistory, ControllerOptions } from 'core/common/interfaces';
import { Base } from "core/controllers/base"
import { EditorHistory } from './editor-history';


export class ActiveHistory extends Base implements IHistory {
    private activeHistoryRef: { current: (IHistory | undefined) } = { current: undefined };

    private editorHistory: EditorHistory;

    constructor(options: ControllerOptions) {
        super(options);
        this.editorHistory = new EditorHistory(options);

        this.editor.on<SetActiveHistoryHandler>('history:set', this.setActiveHistory);

    }

    private setActiveHistory = (historyRef: { current?: (IHistory | undefined) }) => {
        this.activeHistoryRef.current = historyRef?.current || undefined;
    }

    public initialize() {
        this.activeHistoryRef.current?.initialize();
        this.editorHistory.initialize();
    }

    public destroy() {
        this.activeHistoryRef.current?.destroy();
        this.editorHistory.destroy();
        this.editor.off<SetActiveHistoryHandler>('history:set', this.setActiveHistory);
    }

    public reset = () => {
        this.activeHistoryRef.current?.reset();
        this.editorHistory.reset();
    }

    public save = () => {
        const activeHistory = this.activeHistoryRef.current;
        if (activeHistory) {

            activeHistory.save();

        } else {

            this.editorHistory.save();

        }
    }

    public undo = () => {
        const activeHistory = this.activeHistoryRef.current;
        if (activeHistory) {

            activeHistory.undo();

        } else {

            this.editorHistory.undo();

        }
    }

    public redo = () => {
        const activeHistory = this.activeHistoryRef.current;
        if (activeHistory) {
            activeHistory.redo();
        } else {
            this.editorHistory.redo();
        }
    }
}