import React from "react";
import styles from './documentation.module.css';
import { classNames } from "core/utils/classname-utils";
import * as Tabs from "@radix-ui/react-tabs";
import { ApiPipelineType, GenerateImageApiDocType } from "core/common/types/api";
import { GenerateImagePlaygroundContext } from "./generate-image-api-context";
import { getObjectEntries } from "core/utils/type-utils";
import { GetGenerateImageApiPricingProps, getGenerateImageApiPricing } from "./api-pricing-utils";




function DocFieldHeaderName({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-sm text-zinc-200 font-semibold"
        >
            {children}
        </div>
    )
}

function DocFieldHeaderTag({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-sm text-zinc-500"
        >
            {children}
        </div>
    )
}

function DocFieldHeader({
    children,
    tags = [],
}: {
    children: React.ReactNode,
    tags?: React.ReactNode[],
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className={classNames(
                styles.ContainerWithVerticalDividers,
                "flex flex-row justify-start items-center",
            )}
        >
            <DocFieldHeaderName>
                {children}
            </DocFieldHeaderName>
            {tags.map((tag, index) => (
                <DocFieldHeaderTag
                    key={index}
                >
                    {tag}
                </DocFieldHeaderTag>
            ))}
        </div>
    )
}

function CodeInline({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <span className="p-1 rounded bg-zinc-800/50 text-zinc-200 border border-zinc-800">
            {children}
        </span>
    )
}

function DocFieldContent({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-sm text-zinc-400 leading-relaxed"
        >
            {children}
        </div>
    )
}

type DocFieldProps = {
    headerName: React.ReactNode,
    headerTags: React.ReactNode[],
    content: React.ReactNode,
}

function DocField({
    headerName,
    headerTags,
    content,
}: DocFieldProps) {

    return (
        <div
            className="flex flex-col justify-start gap-4"
        >
            <DocFieldHeader
                tags={headerTags}
            >
                {headerName}
            </DocFieldHeader>
            <DocFieldContent>
                {content}
            </DocFieldContent>
        </div>
    )
}

function DocFieldGroupHeader({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-xl font-semibold text-zinc-200"
        >
            {children}
        </div>
    )
}

function DocFieldGroupDescription({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-sm text-zinc-400"
        >
            {children}
        </div>
    )
}

type DocFieldGroupConfig = {
    name: React.ReactNode,
    description: React.ReactNode,
    fields: Record<string, DocFieldProps>,
}

function DocFieldGroup({
    config,
}: {
    config: DocFieldGroupConfig,
}) {
    const {
        name,
        description,
        fields,
    } = config;
    return (
        <div
            className="flex flex-col gap-8"
        >
            <div
                className="flex flex-col gap-4"
            >
                <DocFieldGroupHeader>
                    {name}
                </DocFieldGroupHeader>
                <DocFieldGroupDescription>
                    {description}
                </DocFieldGroupDescription>
            </div>
            <div
                className={classNames(
                    styles.ContainerWithHorizontalDividers,
                    "flex flex-col",
                )}
            >
                {getObjectEntries(fields).map(([key, props], index) => (
                    <DocField
                        key={key}
                        {...props}
                    />
                ))}
            </div>
        </div>
    );
}

function DocHeader({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-2xl font-semibold text-zinc-200"
        >
            {children}
        </div>
    )
}

function DocDescription({
    children,
}: {
    children: React.ReactNode,
}) {
    if (!children) {
        return null;
    }
    return (
        <div
            className="text-sm text-zinc-400"
        >
            {children}
        </div>
    )
}

type DocConfig = {
    name: React.ReactNode,
    description: React.ReactNode,
    fieldGroups: Record<string, DocFieldGroupConfig>,
}

function Doc({
    config,
}: {
    config: DocConfig,
}) {
    const {
        name,
        description,
        fieldGroups,
    } = config;
    return (
        <div
            className="flex flex-col gap-8"
        >
            <div
                className="flex flex-col gap-4"
            >
                <DocHeader>
                    {name}
                </DocHeader>
                <DocDescription>
                    {description}
                </DocDescription>
            </div>
            <div
                className={classNames(
                    styles.ContainerWithHorizontalDividers,
                    "flex flex-col",
                )}
            >
                {getObjectEntries(fieldGroups).map(([key, fieldGroupConfig]) => (
                    <DocFieldGroup
                        key={key}
                        config={fieldGroupConfig}
                    />
                ))}
            </div>
        </div>
    );
}


const defaultDocFields: Record<string, DocFieldProps> = {
    pipelineType: {
        headerName: "pipeline_type",
        headerTags: [
            "required",
        ],
        content: (
            <span>
                The pipeline to use for image generation. Options include <CodeInline>default</CodeInline>, <CodeInline>canny</CodeInline>, and <CodeInline>product_placement</CodeInline>.
            </span>
        ),
    },
    prompt: {
        headerName: "prompt",
        headerTags: ["required"],
        content: (
            <span>
                A text description of the desired image(s). This should clearly describe what you want to generate.
            </span>
        ),
    },
    negativePrompt: {
        headerName: "negative_prompt",
        headerTags: ["optional"],
        content: (
            <span>
                A text description of what you wish to avoid in the generated image(s). This helps guide the generation away from undesired elements.
            </span>
        ),
    },
    width: {
        headerName: "width",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>1024</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The width of the generated images in pixels. Allowed values range from <CodeInline>512</CodeInline> to <CodeInline>1536</CodeInline>, with increments of <CodeInline>128</CodeInline> pixels.
            </span>
        ),
    },
    height: {
        headerName: "height",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>1024</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The height of the generated images in pixels. Allowed values range from <CodeInline>512</CodeInline> to <CodeInline>1536</CodeInline>, with increments of <CodeInline>128</CodeInline> pixels.
            </span>
        ),
    },
    numInferenceSteps: {
        headerName: "num_inference_steps",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>10</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The number of inference steps to take when generating the image(s). Allowed values range from <CodeInline>1</CodeInline> to <CodeInline>50</CodeInline> steps.
            </span>
        ),
    },
    guidanceScale: {
        headerName: "guidance_scale",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>7.5</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The guidance scale used during image generation, which influences the adherence to the prompt. The value can range from <CodeInline>0</CodeInline> to <CodeInline>20</CodeInline>, with increments of <CodeInline>0.5</CodeInline>.
            </span>
        ),
    },
    seed: {
        headerName: "seed",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>-1</CodeInline> (indicating a random seed)
            </span>,
        ],
        content: (
            <span>
                A specific seed number to ensure the reproducibility of the images. Must be a non-negative integer or <CodeInline>-1</CodeInline> for a random seed.
            </span>
        ),
    },
};

const cannyDocFields: Record<string, DocFieldProps> = {
    pipelineType: {
        headerName: "pipeline_type",
        headerTags: [
            "required",
        ],
        content: (
            <span>
                The pipeline to use for image generation. Should be <CodeInline>canny</CodeInline> for this pipeline. Options include <CodeInline>default</CodeInline>, <CodeInline>canny</CodeInline>, and <CodeInline>product_placement</CodeInline>.
            </span>
        ),
    },
    canny_base_image: {
        headerName: "canny_base_image",
        headerTags: [
            "required",
            <span>Image data URI</span>,
            <span>Less than 4MB</span>,
        ],
        content: (
            <span>
                The base image used to generate a canny image. Please ensure that the image is a valid <CodeInline>PNG</CodeInline>, <CodeInline>JPEG</CodeInline>, or <CodeInline>WEBP</CodeInline> data URI and the size is less than <CodeInline>4MB</CodeInline>.
            </span>
        ),
    },
    canny_controlnet_conditioning_scale: {
        headerName: "canny_controlnet_conditioning_scale",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>0.8</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The scale that controls the influence of the outline in the generated image. It can range from <CodeInline>0</CodeInline> (no influence) to <CodeInline>1.0</CodeInline> (full influence), with a default value of <CodeInline>0.8</CodeInline>.
            </span>
        ),
    },
    ...defaultDocFields,
};

const productPlacementDocFields: Record<string, DocFieldProps> = {
    pipelineType: {
        headerName: "pipeline_type",
        headerTags: [
            "required",
        ],
        content: (
            <span>
                The pipeline to use for image generation. Should be <CodeInline>product_placement</CodeInline> for this pipeline. Options include <CodeInline>default</CodeInline>, <CodeInline>canny</CodeInline>, and <CodeInline>product_placement</CodeInline>.
            </span>
        ),
    },
    foreground_image: {
        headerName: "foreground_image",
        headerTags: [
            "required",
            <span>Image data URI</span>,
            <span>Less than 4MB</span>,
        ],
        content: (
            <span>
                The main image to be placed in the foreground. If the <CodeInline>foreground_mask_image</CodeInline> is not provided, the foreground image's transparency channel will be used as the mask. If the foreground image has no valid transparency channel, then the mask will be automatically detected. Ensure it is a valid <CodeInline>PNG</CodeInline>, <CodeInline>JPEG</CodeInline>, or <CodeInline>WEBP</CodeInline> data URI, and the size is under <CodeInline>4MB</CodeInline>.
            </span>
        ),
    },
    foreground_mask_image: {
        headerName: "foreground_mask_image",
        headerTags: [
            "optional",
            <span>Image data URI</span>,
            <span>Less than 4MB</span>,
        ],
        content: (
            <span>
                An optional mask image whose white areas correspond to the foreground in the <CodeInline>foreground_image</CodeInline>. Provide a valid <CodeInline>PNG</CodeInline>, <CodeInline>JPEG</CodeInline>, or <CodeInline>WEBP</CodeInline> data URI. The size must be less than <CodeInline>4MB</CodeInline>.
            </span>
        ),
    },
    ref_image: {
        headerName: "ref_image",
        headerTags: [
            "optional",
            <span>Image data URI</span>,
            <span>Less than 4MB</span>,
        ],
        content: (
            <span>
                An optional reference image to guide the style of the generated image. It must be a valid <CodeInline>PNG</CodeInline>, <CodeInline>JPEG</CodeInline>, or <CodeInline>WEBP</CodeInline> data URI and not exceed <CodeInline>4MB</CodeInline> in size.
            </span>
        ),
    },
    ref_image_scale_start: {
        headerName: "ref_image_scale_start",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>0.8</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The starting scale for the reference image, affecting its initial influence on the generation. Values can range from <CodeInline>0</CodeInline> to <CodeInline>1.0</CodeInline>, with the default set to <CodeInline>0.8</CodeInline>.
            </span>
        ),
    },
    ref_image_scale_finish: {
        headerName: "ref_image_scale_finish",
        headerTags: [
            "optional",
            <span>
                Defaults to <CodeInline>0.5</CodeInline>
            </span>,
        ],
        content: (
            <span>
                The ending scale for the reference image, influencing the final stages of the generation. It ranges from <CodeInline>0</CodeInline> to <CodeInline>1.0</CodeInline>, defaulting to <CodeInline>0.5</CodeInline>.
            </span>
        ),
    },
    ...defaultDocFields,
};

const responseDocFields: Record<string, DocFieldProps> = {
    success: {
        headerName: 'success',
        headerTags: [
            'boolean',
        ],
        content: (
            <span>
                Indicates whether the request is successful.
            </span>
        ),
    },
    images: {
        headerName: 'images',
        headerTags: [
            <CodeInline>{"Array<string>"}</CodeInline>,
        ],
        content: (
            <span>
                An array of https URLs, each pointing to an image generated by the request. The images are stored in WEBP format. Note that the result image will be deleted within a day, so please save it on your storage system after receiving the request.
            </span>
        ),
    },
    duration_seconds: {
        headerName: 'duration_seconds',
        headerTags: [
            'number',
        ],
        content: (
            <span>
                The time taken to process the request in the backend server, measured in seconds. Note that the actual request time may be longer due to waiting in queues.
            </span>
        ),
    },
    status_code: {
        headerName: 'status_code',
        headerTags: [
            'number',
        ],
        content: (
            <span>
                The HTTP status code associated with the response.
            </span>
        ),
    },
    message: {
        headerName: 'message',
        headerTags: [
            'string',
        ],
        content: (
            <span>
                The error message associated with the response.
            </span>
        ),
    },
}

const defaultDocConfig: DocConfig = {
    name: 'Base pipeline',
    description: '',
    fieldGroups: {
        Request: {
            name: "Request",
            description: "",
            fields: defaultDocFields,
        },
        Response: {
            name: "Response",
            description: "",
            fields: responseDocFields,
        },
    }
};

const cannyDocConfig: DocConfig = {
    name: "Outline control pipeline",
    description: "",
    fieldGroups: {
        Request: {
            name: "Request",
            description: "",
            fields: cannyDocFields,
        },
        Response: {
            name: "Response",
            description: "",
            fields: responseDocFields,
        },
    }
}

const productPlacementDocConfig: DocConfig = {
    name: "Product placement pipeline",
    description: "",
    fieldGroups: {
        Request: {
            name: "Request",
            description: "",
            fields: productPlacementDocFields,
        },
        Response: {
            name: "Response",
            description: "",
            fields: responseDocFields,
        },
    }
}

const PricingTableHeader = ({
    children,
}: {
    children: React.ReactNode,
}) => (
    <th
        className="text-sm text-left px-4 py-2 border-b border-zinc-800"
    >
        {children}
    </th>
)

const PricingTableRow = ({
    children,
}: {
    children: React.ReactNode,
}) => (
    <tr>
        {children}
    </tr>
)

const PricingTableRowField = ({
    children,
}: {
    children: React.ReactNode,
}) => (
    <td
        className="h-[38px] px-4 py-2 border-b border-zinc-800"
    >
        {children}
    </td>
)

function PricingDescriptionRow({
    props,
}: {
    props: GetGenerateImageApiPricingProps,
}) {
    const pricePerImage = React.useMemo(() => getGenerateImageApiPricing(props), [props]);

    return (
        <PricingTableRow>
            <PricingTableRowField><CodeInline>{props.pipelineType}</CodeInline></PricingTableRowField>
            <PricingTableRowField>
                <div className="flex flex-row items-center justify-start gap-2">
                    <span>
                        {`$${pricePerImage} per image`}
                    </span>
                    <span className="text-zinc-500">
                        {`(${Math.floor(1.0 / pricePerImage)} images per dollar)`}
                    </span>
                </div>
            </PricingTableRowField>
        </PricingTableRow>
    )
}

function PricingDescription() {

    return (
        <div
            className="flex flex-col gap-4"
        >
            <p>
                Only pay for what you use. The API subscription charges a fixed price for each image. Below are the prices per image for each pipeline.
            </p>
            <table
                className="w-full"
            >
                <thead>
                    <tr>
                        <PricingTableHeader>Pipeline Type</PricingTableHeader>
                        <PricingTableHeader>Price</PricingTableHeader>
                    </tr>
                </thead>
                <tbody>
                    <PricingDescriptionRow
                        props={{
                            pipelineType: ApiPipelineType.Default,
                        }}
                    />
                    <PricingDescriptionRow
                        props={{
                            pipelineType: ApiPipelineType.Canny,
                        }}
                    />
                    <PricingDescriptionRow
                        props={{
                            pipelineType: ApiPipelineType.ProductPlacement,
                        }}
                    />
                </tbody>
            </table>
        </div>
    )
}

const pricingDocConfig: DocConfig = {
    name: "Pricing",
    description: "",
    fieldGroups: {
        Overview: {
            name: "Overview",
            description: (
                <PricingDescription />
            ),
            fields: {},
        }
    }
}

export const generateImageApiDocConfig: Record<GenerateImageApiDocType, DocConfig> = {
    [ApiPipelineType.ProductPlacement]: productPlacementDocConfig,
    [ApiPipelineType.Canny]: cannyDocConfig,
    [ApiPipelineType.Default]: defaultDocConfig,
    Pricing: pricingDocConfig,
}

function DocTabTrigger({
    className = "",
    children,
    ...props
}: Tabs.TabsTriggerProps & {
    value: GenerateImageApiDocType,
}) {
    return (
        <Tabs.Trigger
            {...props}
            className={classNames(
                "group pl-3 pr-3 xl:pr-6 py-2 truncate rounded text-sm text-zinc-500 focus:outline-none active:outline-none focus-visible:outline-none hover:bg-zinc-800 data-[state=active]:text-zinc-200 transition-colors",
                className,
            )}
        >
            {children}
        </Tabs.Trigger>
    )
}

function DocTabs() {
    const {
        docTab,
        setDocTab,
    } = React.useContext(GenerateImagePlaygroundContext);

    return (
        <Tabs.Root
            value={docTab}
            // @ts-ignore
            onValueChange={setDocTab}
            className="flex flex-row justify-start gap-6 xl:gap-12 w-full xl:w-[1000px]"
        >
            <Tabs.List
                className="flex flex-col justify-start items-stretch gap-2"
            >
                {getObjectEntries(generateImageApiDocConfig).map(([value, config]) => (
                    <DocTabTrigger
                        key={value}
                        value={value}
                    >
                        {config.name}
                    </DocTabTrigger>
                ))}
            </Tabs.List>
            <div
            >
                {getObjectEntries(generateImageApiDocConfig).map(([value, config]) => (
                    <Tabs.Content
                        key={value}
                        value={value}
                    >
                        <Doc
                            config={config}
                        />
                    </Tabs.Content>
                ))}
            </div>
        </Tabs.Root>
    )
}



export function DefaultGenerateImageApiDocumentation() {
    return (
        <div className="w-full flex flex-row justify-start">
            <DocTabs />
        </div >
    )
}