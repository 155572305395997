import { IGenerationFrame, ILayer } from "./layers"
import type { TimeRange, Optional } from "./types"

export interface IFrame {
  width: number
  height: number
}

export type IObject = Partial<ILayer> & {
  zIndex: number,
};
export interface IScene {
  id: string
  version: string,
  frame: IFrame
  name?: string
  description?: string
  objects: Record<string, IObject>,
  // layers: string[]
  metadata: Record<string, any>
  preview?: string
  duration?: number
  display?: TimeRange
  generationFrame?: IGenerationFrame
}

export type SampleProjectScene = Optional<IScene, 'id'>;

function sortSceneObjects(a: { zIndex: number }, b: { zIndex: number }) {
  return a.zIndex - b.zIndex;
}

export function getSceneLayers(scene: {
  objects: Record<string, IObject>,
}) {
  return Object.values(scene.objects).sort(sortSceneObjects);
}