import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import styles from './right-click-menu.module.css';


import { EditorRightClickMenuObject } from './object-menu';
import { downloadImageDataUrl, getStaticImageFileStem } from 'components/utils/data';
import { editorContextStore } from 'contexts/editor-context';

export function EditorRightClickMenuImage() {
    const activeObject = editorContextStore(state => state.activeObject);
    const imageFileStem = React.useMemo(() => getStaticImageFileStem(activeObject), [activeObject]);
    return (
        <>
            <EditorRightClickMenuObject />
            <ContextMenu.Item
                className={styles.ContextMenuItem}
                onClick={() => {
                    const src = (activeObject as any)?.getSrc();
                    if (!src) {
                        return;
                    }
                    downloadImageDataUrl(src, imageFileStem);
                }}
            >
                Download
            </ContextMenu.Item>
        </>
    )
}