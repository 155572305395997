import { EMAIL_STORAGE_KEY } from 'components/constants/backend';
import { InputBoxClassName, PrimaryButtonClassName } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';
import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FullScreenAuthContainer } from './auth-conatiner';

export function FinishEmailLogin() {
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const backend = editorContextStore(state => state.backend);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const user = editorContextStore(state => state.user);
    React.useEffect(() => {
        if (user?.uid) {
            setMessage('Successfully signed in, redirecting ...');
            navigate('/');
        }
    }, [user, navigate]);

    React.useEffect(() => {
        if (backend) {
            const localEmail = searchParams.get('email') || window.localStorage.getItem(EMAIL_STORAGE_KEY);
            if (typeof (localEmail) === 'string') {
                setEmail(localEmail);
                backend.signInWithEmailLink(localEmail).catch((error) => {
                    setMessage(error?.message || 'Unknown error. Please refresh the page.');
                });
            } else {
                setMessage('Please type in your email address.');
            }
        }
    }, [backend, searchParams]);

    return (
        <FullScreenAuthContainer>
            <div
                className='my-4 text-xl font-semibold text-zinc-300'
            >
                Sign in with email link
            </div>
            <div className='w-full flex flex-col my-4'>
                <div
                    className='text-zinc-300 mb-2'
                >
                    Email
                </div>
                <input
                    type="Email"
                    className={`${InputBoxClassName} grow`}
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget?.value)}
                />
            </div>
            <button
                className={`w-full mt-4 ${PrimaryButtonClassName} h-[42px]`}
                onClick={() => {
                    if (email) {
                        if (backend) {
                            backend.signInWithEmailLink(email).then(() => {
                                navigate('/');
                            }).catch((error) => {
                                const message = error?.message;
                                if (message && typeof (message) === 'string') {

                                    if (message.toLowerCase().includes("auth/invalid-action-code")) {
                                        setMessage("Email link is already used. Please sign in again. ");
                                    } else {
                                        setMessage(message);
                                    }

                                } else {
                                    setMessage('Unknown error. Please refresh the page.');
                                }
                            });
                        } else {
                            setMessage('Backend is not initialized, please try again later.');
                        }
                    } else {
                        setMessage('Please type in a valid email.');
                    }
                }}
            >
                Sign in
            </button>
            <div className='w-full pt-2 min-h-[56px] text-zinc-500 flex justify-start items-center'>
                <div>
                    <span>
                        {message}
                    </span>
                    <span>
                        &nbsp;Having trouble? Try <Link className='underline text-lime-500 hover:text-lime-400' to="/login">other sign-in methods</Link>.
                    </span>
                </div>
            </div>
        </FullScreenAuthContainer>
    )
};