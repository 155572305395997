import React, { useEffect, useState } from 'react';
import { editorContextStore } from 'contexts/editor-context';
import { FeatureFlags } from 'core/controllers/featureFlags/featureFlags';
import { AnalyticsConfig } from 'analytics/config';

/**
 * How to use:
 *
 * For boolean FeatureFlags you can simply do
 * const isEnabled = useFeatureFlags(testFeature);
 *
 * Providing a condition function
 * const isEnabled = useFeatureFlags(testFeature, (flagValue) => flagValue === 0);
 *
 */
export const useFeatureFlags = <K extends keyof FeatureFlags>(
    flagKey: K,
    condition?: FeatureFlags[K] | ((flagValue: FeatureFlags[K]) => boolean)
): boolean => {
    const isVIP = editorContextStore(state => state.featureFlagVIP);
    const featureFlags = editorContextStore(state => state.featureFlags);

    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        const checkFlag = () => {
            if (!featureFlags) {
                setIsEnabled(false);
                return;
            }

            const flag = featureFlags[flagKey];

            if (isVIP) {
                setIsEnabled(true);
            } else if (flag && typeof condition === 'function') {
                setIsEnabled(condition(flag));
            } else if (flag && typeof condition !== 'undefined') {
                setIsEnabled(flag === condition);
            } else {
                setIsEnabled(!!flag);
            }
        };

        checkFlag();
    }, [flagKey, condition, featureFlags, isVIP]);

    return isEnabled;
};

/**
 * Hook loading the FeatureFlags for the given user from backend.
 * If they dont exist for the user yet we use default values.
 */
export const useFeatureFlagsEffect = () => {
    const backend = editorContextStore(state => state.backend);
    const user = editorContextStore(state => state.user);
    const { featureFlagsController } = editorContextStore.getState();

    useEffect(() => {

        if (!backend || !user || !featureFlagsController) return;

        backend.getUserFeatureFlags(user?.uid).then((userFlagData) => {
            featureFlagsController.clear();
            featureFlagsController.setFlags(userFlagData.featureFlags);
            featureFlagsController.setVIP(userFlagData.isVIP);

            editorContextStore.getState().analytics.track(
                AnalyticsConfig.UsedFeatureFlags,
                {
                    isVIP: userFlagData.isVIP,
                    ...namespaceObjectValues(userFlagData.featureFlags, 'featureFlag')
                }
            );
        })

        return () => {
            featureFlagsController.clear();
        }
    }, [backend, user]);

}

/**
 * Support function to namespace all the keys of an objects
 */
function namespaceObjectValues(obj: Record<string, any>, namespace: string, result: Record<string, any> = {}): Record<string, any> {
    for (let key in obj) {
        let propName = namespace ? namespace + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            namespaceObjectValues(obj[key], propName, result);
        } else {
            result[propName] = obj[key];
        }
    }
    return result;
}