export const background = {
    angle: 0,
    stroke: null,
    strokeWidth: 0,
    left: 0,
    top: 0,
    width: 1280,
    height: 720,
    opacity: 1,
    originX: "left",
    originY: "top",
    scaleX: 1,
    scaleY: 1,
    type: "Background",
    flipX: false,
    flipY: false,
    skewX: 0,
    skewY: 0,
    visible: true,
    fill: "#ffffff",
    metadata: {},
  }
  
  export const image = {
    angle: 0,
    stroke: "#ffffff",
    strokeWidth: 0,
    left: 167.19,
    top: 332.82,
    width: 320,
    height: 36.16,
    opacity: 1,
    originX: "left",
    originY: "top",
    scaleX: 1,
    scaleY: 1,
    type: "StaticImage",
    flipX: false,
    flipY: false,
    skewX: 0,
    skewY: 0,
    visible: true,
    src: "",
    metadata: {},
  }
  
  export const path = {
    angle: 0,
    stroke: "#ffffff",
    strokeWidth: 0,
    left: 0,
    top: 0,
    width: 60,
    height: 60,
    opacity: 1,
    originX: "left",
    originY: "top",
    scaleX: 1,
    scaleY: 1,
    type: "StaticPath",
    flipX: false,
    flipY: false,
    skewX: 0,
    skewY: 0,
    visible: true,
    path: [],
    fill: "#3dc1d3",
    metadata: {},
  }
  
  export const text = {
    angle: 0,
    stroke: "#ffffff",
    strokeWidth: 0,
    left: 167.19,
    top: 332.82,
    width: 320,
    height: 36.16,
    opacity: 1,
    originX: "left",
    originY: "top",
    scaleX: 1,
    scaleY: 1,
    type: "StaticText",
    flipX: false,
    flipY: false,
    skewX: 0,
    skewY: 0,
    visible: true,
    fill: "#000000",
    charspacing: 0,
    fontSize: 32,
    fontFamily: "ABeeZee",
    textAlign: "center",
    lineheight: 1.16,
    text: "Hello world",
    fontURL: "",
    metadata: {},
  }
  