import React from 'react';
import { fabric } from "fabric"
import { editorContextStore } from 'contexts/editor-context';
import { DisplayMessageDialogType, UiCloseMessageDialogEventHandler, UiCloseMessageDialogResponse, UiDisplayMessageDialogEventHandler, UiDisplayMessageDialogEventHandlerProps } from 'core/common/types';
import { RemoveImageObjectsBackgroundDialog } from './remove-background-dialog';
import { isStaticImageObject } from 'core/utils/type-guards';
import { QuotaSubscribeDialog } from './quota-subscribe-dialog';
import { Editor } from 'core/editor';
import { UploadClothObjectDialog } from './upload-cloth-dialog';
import { noop } from 'lodash';
import { ProcessUploadedImageDialog } from './process-uploaded-image-dialog';
import { RealTimeSubscribeDialog } from './realtime-subscribe-dialog';

function useEditorMessageDialogTest(
    editor: Editor | null,
) {
    React.useEffect(() => {

        if (process.env.NODE_ENV !== 'development') {
            return;
        }
        if (!editor) {
            return;
        }
        editor.once(
            'editor:init',
            () => {
                // setTimeout(() => {
                //     editor.emit<UiDisplayMessageDialogEventHandler>(
                //         'ui:display-message-dialog',
                //         'remove-background',
                //         {
                //             objects: editor.objects.find(isStaticImageObject),
                //         }
                //     );
                // }, 1000);
            },
        );

    }, [editor]);
}

export function EditorMessageDialog() {
    const [type, setType] = React.useState<DisplayMessageDialogType | null>(null);
    const [props, setProps] = React.useState<UiDisplayMessageDialogEventHandlerProps>();
    const editor = editorContextStore(state => state.editor);


    React.useEffect(() => {
        if (!editor) {
            return;
        }
        const handleDisplayMessageDialog: UiDisplayMessageDialogEventHandler['handler'] = (type, params) => {
            setType(type);
            setProps(params);
        }
        editor.on<UiDisplayMessageDialogEventHandler>(
            'ui:display-message-dialog',
            handleDisplayMessageDialog,
        );
        return () => {
            editor.off<UiDisplayMessageDialogEventHandler>(
                'ui:display-message-dialog',
                handleDisplayMessageDialog,
            );
        }
    }, [editor]);

    const handleClose = React.useCallback((response: UiCloseMessageDialogResponse) => {
        editor?.emit<UiCloseMessageDialogEventHandler>(
            'ui:close-message-dialog',
            response,
        );
        setType(null);
        setProps({});

    }, [editor]);

    if (type === 'remove-background') {
        if (!props || !props.objects) {
            console.log('No uploaded objects');
            return null;
        }

        const images = props.objects.filter(isStaticImageObject) as any as fabric.StaticImage[];

        if (images.length <= 0) {
            console.log('No images in the uploaded objects');
            return null;
        }

        return (
            <RemoveImageObjectsBackgroundDialog
                object={images[0]}
                onClose={() => handleClose({ type: 'remove-background' })}
            />
        );
    }

    if (type === 'quota-subscribe') {
        return (
            <QuotaSubscribeDialog
                header={props?.header}
                title={props?.title}
                onClose={() => handleClose({ type })}
            />
        );
    }

    if (type === 'upload-cloth') {
        if (!props || !props.image) {
            console.log('No uploaded images');
            return null;
        }

        return (
            <UploadClothObjectDialog
                image={props.image}
                onClose={() => handleClose({ type: 'upload-cloth' })}
            />
        )
    }

    if (type === 'process-uploaded-image') {
        if (!props || !props.image) {
            return null;
        }

        return (
            <ProcessUploadedImageDialog
                image={props.image}
                onClose={handleClose}
            />
        )
    }

    if (type === 'realtime-subscribe') {
        return (
            <RealTimeSubscribeDialog
                onClose={() => handleClose({ type: 'realtime-subscribe' })}
            />
        );
    }

    return null;
}