import { UserProjectType } from 'core/common/types';

export type SpecialProjectTypes =
  | UserProjectType.Accessories
  | UserProjectType.Beauty
  | UserProjectType.CPG
  | UserProjectType.CustomModels
  | UserProjectType.Fashion
  | UserProjectType.Food
  | UserProjectType.Furniture
  | UserProjectType.Homegoods
  | UserProjectType.Staging
  | UserProjectType.Humans
;

export function isSpecialProjectType(projectType: UserProjectType): projectType is SpecialProjectTypes {
  return (
    projectType === UserProjectType.Beauty ||
    projectType === UserProjectType.Accessories ||
    projectType === UserProjectType.CPG ||
    projectType === UserProjectType.Fashion ||
    projectType === UserProjectType.Food ||
    projectType === UserProjectType.Furniture ||
    projectType === UserProjectType.Homegoods ||
    projectType === UserProjectType.Staging ||
    projectType === UserProjectType.Humans
  );
}

export const SpecialProjectTypeOrder: SpecialProjectTypes[] = [
  UserProjectType.Beauty,
  UserProjectType.CustomModels,
  UserProjectType.Humans,
  UserProjectType.Food,
  UserProjectType.Furniture,
  UserProjectType.CPG,
  UserProjectType.Staging,
  UserProjectType.Accessories,
  UserProjectType.Fashion,
  UserProjectType.Homegoods,
];

export function getOrderedProjectTypes(): SpecialProjectTypes[] {
  return SpecialProjectTypeOrder;
}

interface ProjectTypeInfo {
  carouselName: string;
  workflowName: string;
  image: string;
  tooltipText: string;
  untitledName: string;
}

export const UserProjectTypeInfo: Record<SpecialProjectTypes, ProjectTypeInfo> = {
  [UserProjectType.Accessories]: {
    carouselName: 'Accessories',
    workflowName: 'Shoes, Handbags, & Accessories',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c631412d-3ffb-49b6-7fc2-bbd6c2c0dc00/1024',
    tooltipText: 'Create shoes, handbags, & accessories photography project',
    untitledName: 'Untitled Accessories Project',
  },
  [UserProjectType.Beauty]: {
    carouselName: 'Beauty',
    workflowName: 'Skincare, Beauty & Cosmetics',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/982ea4ba-2ce1-4d01-bd95-d19f8835a000/1024',
    tooltipText: 'Create skincare, beauty & cosmetics photography project',
    untitledName: 'Untitled Beauty Project',
  },
  [UserProjectType.CPG]: {
    carouselName: 'CPG',
    workflowName: 'Consumer Packaged Goods',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f4ec02d4-4f1f-45ff-ca9e-06209d8ba100/1024',
    tooltipText: 'Create consumer packaged goods photography project',
    untitledName: 'Untitled CPG Project',
  },
  // Will only link to models
  [UserProjectType.CustomModels]: {
    carouselName: 'Fashion Model',
    workflowName: 'Fashion Model',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cd3b773-9ea4-4d19-f892-c432571f7800/1024',
    tooltipText: 'Create your own custom model for fashion model photography project',
    untitledName: 'NOT NEEDED SINCE WE LINK TO MODELS',
  },
  [UserProjectType.Fashion]: {
    carouselName: 'Graphic T-shirts ',
    workflowName: 'Graphic T-shirts and Sweaters',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/70a08ba7-8e07-45f0-cc28-f4634a295a00/1024',
    tooltipText: 'Create fashion project',
    untitledName: 'Untitled graphic T-shirts Project',
  },
  [UserProjectType.Food]: {
    carouselName: 'Food',
    workflowName: 'Food Photography',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a470a4fc-049f-4717-b2d9-2b06ef4c7200/1024',
    tooltipText: 'Create food photography project',
    untitledName: 'Untitled Food Project',
  },
  [UserProjectType.Furniture]: {
    carouselName: 'Furniture',
    workflowName: 'Furniture Photography',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d6b94ca4-d0d2-499c-6023-7e0d48e7a700/1024',
    tooltipText: 'Create furniture photography project',
    untitledName: 'Untitled Furniture Project',
  },
  [UserProjectType.Homegoods]: {
    carouselName: 'Homegoods',
    workflowName: 'Vases, Pottery & Homegoods',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f1be62c3-2173-472f-1bd3-ee0959911300/1024',
    tooltipText: 'Create Vases, Pottery & Homegoods project',
    untitledName: 'Untitled Homegoods Project',
  },
  [UserProjectType.Staging]: {
    carouselName: 'Staging',
    workflowName: 'Virtual Staging',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ac792bab-96d0-4544-df39-44d35a896400/1024',
    tooltipText: 'Create virtual staging project',
    untitledName: 'Untitled Staging Project',
  },
  [UserProjectType.Humans]: {
    carouselName: 'Humans',
    workflowName: 'Human shots',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/50271d4f-14e7-4d3c-43f7-9301f591e100/1024',
    tooltipText: 'Create human model project',
    untitledName: 'Untitled Human Project',
  },
};

export function getProjectTypeInfo(projectType: SpecialProjectTypes): ProjectTypeInfo {
  return UserProjectTypeInfo[projectType];
}

export const SpecialProjectCarouselNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.carouselName])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectWorkflowNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.workflowName])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectTypeImages: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.image])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectTooltipTexts: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.tooltipText])
) as Record<SpecialProjectTypes, string>;

export const SpecialProjectUntitledNames: Record<SpecialProjectTypes, string> = Object.fromEntries(
  Object.entries(UserProjectTypeInfo).map(([key, value]) => [key, value.untitledName])
) as Record<SpecialProjectTypes, string>;
