import { editorContextStore } from 'contexts/editor-context';
import React from 'react';
import { Navigate } from 'react-router-dom';

export function RequireAuth({
    children,
}: {
    children: React.ReactNode,
}) {
    
    const user = editorContextStore(state => state.user);

    return (
        user?.uid ? <>{children}</> : <Navigate replace to='/login'/> 
    );
}