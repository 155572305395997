import { RenderProcessController, RenderJobController, RenderJobControllerStatus } from "core/common/interfaces";
import { debugError } from "core/utils/print-utilts";

export class WebRenderProcessController implements RenderProcessController, RenderJobController {
    private _status: RenderJobControllerStatus = RenderJobControllerStatus.Rendering;

    get status() {
        return this._status;
    }

    private abortControllers: AbortController[] = [];

    get signal() {
        const abortController = new AbortController();
        this.abortControllers.push(abortController);
        return abortController.signal;
    }

    private cancelJobCallbacks: (() => void | Promise<void>)[] = [];

    setCancelJobCallback(callback: () => void | Promise<void>) {
        this.cancelJobCallbacks.push(callback);
    }

    destroy() {
        this.abort();
        this.abortControllers.length = 0;
        this.cancelJobCallbacks.length = 0;
    }

    private abortInternal() {
        this.abortControllers.forEach((controller) => controller.abort());
    }

    abort() {
        this.cancelJob();
    }

    async cancelJob() {
        debugError('Cancelling web render process controller');

        this._status = RenderJobControllerStatus.Cancelled;

        try {

            this.abortInternal();

        } catch (error) {

            debugError(error);

        }

        try {

            await Promise.all(this.cancelJobCallbacks.map((callback) => callback?.()?.catch?.(debugError)));

        } catch (error) {

            debugError(error);

        }


        this.cancelJobCallbacks.length = 0;
    }

    isCancelled() {
        return this.status !== RenderJobControllerStatus.Rendering;
    }
}

export class UpscaleRenderProcessController implements RenderJobController {
    private _status: RenderJobControllerStatus = RenderJobControllerStatus.Rendering;

    get status() {
        return this._status;
    }

    private cancelJobCallback?: () => Promise<void>;

    setCancelJobCallback(callback: () => Promise<void>) {
        this.cancelJobCallback = callback;
    }

    async cancelJob() {
        this._status = RenderJobControllerStatus.Cancelled;
        await this.cancelJobCallback?.();
        this.cancelJobCallback = undefined;
    }

    isCancelled() {
        return this.status !== RenderJobControllerStatus.Rendering;
    }
}