// Beauty workflow JSON
import { SampleProjectScene } from "core/common/scene";

export const BeautyProject: SampleProjectScene = {
  "metadata": {
    "animated": false
  },
  "objects": {
    "_i7rWJFn0X2ApVgvf-XAK": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "stick",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a59ea91f-a93b-452f-3249-9964df75a800/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 1.41,
      "scaleY": 1.47,
      "top": 512.89,
      "angle": -175,
      "id": "_i7rWJFn0X2ApVgvf-XAK",
      "flipX": false,
      "flipY": true,
      "height": 167,
      "zIndex": 5,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 585.76,
      "name": "StaticImage",
      "width": 450,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a59ea91f-a93b-452f-3249-9964df75a800/public",
        "type": "image-url"
      }
    },
    "gkQIcW9QvtlqJ_D2fBTyV": {
      "strokeWidth": 0,
      "metadata": {
        "placementPrefix": "on",
        "colorDisplayType": "color-and-shape",
        "placement": "a rectangular marble platform",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a7aa2dc9-88f6-4a06-fc5e-99e95800d400/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.67,
      "scaleY": 0.65,
      "top": 32.34,
      "angle": 0,
      "id": "gkQIcW9QvtlqJ_D2fBTyV",
      "flipX": false,
      "flipY": false,
      "height": 926,
      "zIndex": 1,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 7.37,
      "name": "StaticImage",
      "width": 926,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a7aa2dc9-88f6-4a06-fc5e-99e95800d400/public",
        "type": "image-url"
      }
    },
    "4--UuJVjNAe9JpMOwvvMj": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "white flower",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fef0d075-9067-4672-167c-ea61eaeb4f00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.34,
      "scaleY": 0.34,
      "top": 340.01,
      "angle": 0,
      "id": "4--UuJVjNAe9JpMOwvvMj",
      "flipX": false,
      "flipY": false,
      "height": 504,
      "zIndex": 7,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 537.34,
      "name": "StaticImage",
      "width": 495,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fef0d075-9067-4672-167c-ea61eaeb4f00/public",
        "type": "image-url"
      }
    },
    "JJoPqbaWPvAiaSvG4zNP0": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "water droplet",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.28,
      "scaleY": 0.28,
      "top": 314.47,
      "angle": 0,
      "id": "JJoPqbaWPvAiaSvG4zNP0",
      "flipX": false,
      "flipY": false,
      "height": 397,
      "zIndex": 8,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 437.31,
      "name": "StaticImage",
      "width": 357,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public",
        "type": "image-url"
      }
    },
    "Zm8RD0dC_Ept1_G25fr2f": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "leaves on a stem",
        "surroundingPrefix": "next to",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/80e64896-db70-48e7-ca16-ac7c9c9b3100/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.65,
      "scaleY": 0.65,
      "top": -138.26,
      "angle": 0,
      "id": "Zm8RD0dC_Ept1_G25fr2f",
      "flipX": false,
      "flipY": false,
      "height": 617,
      "zIndex": 0,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 38.62,
      "name": "StaticImage",
      "width": 405,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/80e64896-db70-48e7-ca16-ac7c9c9b3100/public",
        "type": "image-url"
      }
    },
    "dwNx8kEGR0mqqPN5adHHD": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "water droplet",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.32,
      "scaleY": 0.32,
      "top": 135.69,
      "angle": 0,
      "id": "dwNx8kEGR0mqqPN5adHHD",
      "flipX": false,
      "flipY": false,
      "height": 397,
      "zIndex": 2,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 356.26,
      "name": "StaticImage",
      "width": 357,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public",
        "type": "image-url"
      }
    },
    "SR9b1KuZIbCW3xzpeuzw6": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "rock",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c8783d33-ff84-4981-8690-bd43bdeb2a00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.1,
      "scaleY": 0.13,
      "top": 294.3,
      "angle": 0,
      "id": "SR9b1KuZIbCW3xzpeuzw6",
      "flipX": false,
      "flipY": false,
      "height": 2500,
      "zIndex": 4,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 33.9,
      "name": "StaticImage",
      "width": 2500,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c8783d33-ff84-4981-8690-bd43bdeb2a00/public",
        "type": "image-url"
      }
    },
    "Nri5ga0_MxdfJ0jr9l-xG": {
      "strokeWidth": 0,
      "metadata": {
        "subject": "a tube bottle"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.82,
      "scaleY": 0.78,
      "top": -294.23,
      "angle": 0,
      "id": "Nri5ga0_MxdfJ0jr9l-xG",
      "flipX": false,
      "flipY": false,
      "height": 766,
      "zIndex": 3,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 133.28,
      "name": "StaticImage",
      "width": 412,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0883533-5692-4409-fff5-c5fafedbb600/public",
        "type": "image-url"
      }
    },
    "P7Ql4cfcxiL17GyXr6R7j": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "water droplet",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.28,
      "scaleY": 0.28,
      "top": 457.27,
      "angle": 0,
      "id": "P7Ql4cfcxiL17GyXr6R7j",
      "flipX": false,
      "flipY": false,
      "height": 397,
      "zIndex": 6,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": -146.36,
      "name": "StaticImage",
      "width": 357,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c104a348-4477-494d-f26d-3017893a1c00/public",
        "type": "image-url"
      }
    }
  },
  "name": "Untitled Project",
  "generationFrame": {
    "top": -422.31,
    "left": -216.23000000000002,
    "referneceImage": {
      "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b06bdaa2-f62a-4159-80f7-958a589adb00/1024",
      "previewPath": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fb7db816-894d-43dc-a300-5eeef5eb5e00/256",
      "type": "image-url"
    },
    "width": 1024,
    "id": "generation-frame",
    "type": "GenerationFrame",
    "promptTemplate": {
      "words": [
        {
          "isAutoFilled": true,
          "prefix": "",
          "autocompleteType": "subject",
          "type": "input",
          "value": "a tube bottle",
          "valueBeforeAutoFill": "a tube bottle",
          "objectIds": [
            "Nri5ga0_MxdfJ0jr9l-xG"
          ]
        },
        {
          "prefix": "in",
          "autocompleteType": "placement",
          "type": "input",
          "value": " a cream colored room"
        },
        {
          "isAutoFilled": true,
          "prefix": "on",
          "autocompleteType": "placement",
          "type": "input",
          "value": "a rectangular marble platform",
          "objectIds": [
            "gkQIcW9QvtlqJ_D2fBTyV"
          ]
        },
        {
          "isAutoFilled": true,
          "prefix": "next to",
          "autocompleteType": "surrounding",
          "type": "input",
          "value": "leaves on a stem",
          "objectIds": [
            "Zm8RD0dC_Ept1_G25fr2f"
          ]
        },
        {
          "isAutoFilled": true,
          "prefix": "with",
          "autocompleteType": "surrounding",
          "type": "input",
          "value": "water droplet and rock and stick and white flower",
          "objectIds": [
            "dwNx8kEGR0mqqPN5adHHD",
            "SR9b1KuZIbCW3xzpeuzw6",
            "_i7rWJFn0X2ApVgvf-XAK",
            "P7Ql4cfcxiL17GyXr6R7j",
            "4--UuJVjNAe9JpMOwvvMj",
            "JJoPqbaWPvAiaSvG4zNP0"
          ]
        }
      ]
    },
    "height": 1024
  },
  "id": "Xrxleygmjwj9DxIRwy09",
  "version": "190",
  "frame": {
    "width": 0,
    "height": 0
  }
};