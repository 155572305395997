import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import { classNames } from 'core/utils/classname-utils';

import styles from './right-click-menu.module.css';
import { editorContextStore } from 'contexts/editor-context';
import { ChevronRightIcon } from '@radix-ui/react-icons';

export function EditorRightClickMenuObject() {
    return (
        <>
            <ContextMenu.Item
                className={classNames(
                    styles.ContextMenuItem,
                )}
                onSelect={() => {
                    editorContextStore.getState().editor?.objects?.remove();
                }}
            >
                Delete
                <div
                    className={styles.RightSlot}
                >
                    Backspace
                </div>
            </ContextMenu.Item>
            <ContextMenu.Sub>
                <ContextMenu.SubTrigger
                    className={styles.ContextMenuSubTrigger}
                >
                    Arrange
                    <div
                        className={styles.RightSlot}
                    >
                        <ChevronRightIcon />
                    </div>
                </ContextMenu.SubTrigger>
                <ContextMenu.Portal>
                    <ContextMenu.SubContent
                        className={styles.ContextMenuSubContent}
                        sideOffset={2}
                        alignOffset={-5}
                    >
                        <ContextMenu.Item
                            className={styles.ContextMenuItem}
                            onSelect={() => {
                                editorContextStore.getState().editor?.objects.bringToFront();
                            }}
                        >
                            Bring to Front
                            <div
                                className={styles.RightSlot}
                            >
                                {"]"}
                            </div>
                        </ContextMenu.Item>
                        <ContextMenu.Item
                            className={styles.ContextMenuItem}
                            onSelect={() => {
                                editorContextStore.getState().editor?.objects.bringForward();
                            }}
                        >
                            Bring Forward
                            <div
                                className={styles.RightSlot}
                            >
                                {"Ctrl + Alt + \u2191"}
                            </div>
                        </ContextMenu.Item>
                        <ContextMenu.Item
                            className={styles.ContextMenuItem}
                            onSelect={() => {
                                editorContextStore.getState().editor?.objects.sendBackwards();
                            }}
                        >
                            Send Backwards
                            <div
                                className={styles.RightSlot}
                            >
                                {"Ctrl + Alt + \u2193"}
                            </div>
                        </ContextMenu.Item>
                        <ContextMenu.Item
                            className={styles.ContextMenuItem}
                            onSelect={() => {
                                editorContextStore.getState().editor?.objects.sendToBack();
                            }}
                        >
                            Send to Back
                            <div
                                className={styles.RightSlot}
                            >
                                {"["}
                            </div>
                        </ContextMenu.Item>
                    </ContextMenu.SubContent>
                </ContextMenu.Portal>
            </ContextMenu.Sub>
        </>
    )
}