export function getUniqueArray<T = unknown>(array: T[]): T[] {
    return [...new Set(array)];
}


export function getRandomElement<T = unknown>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export function getObjectFromArray<T = any>(array: T[], getKey: (item: T) => string = JSON.stringify): Record<string, T> {
    const output: Record<string, T> = {};
    array.forEach((item) => {
        const key = getKey(item);
        output[key] = item;
    });
    return output;
}

export function removeLastFromImmutableList<T = any>(array: T[], defaultValue: T | undefined = undefined, lastN = 1): T[] {
    if (array.length <= lastN) {
        return defaultValue ? [defaultValue] : [];
    }
    return array.slice(0, -lastN);
}

export function fillUndefined<T>(arr: (T | undefined)[], defaultValue: T): T[] {
    return arr.map(item => item == null ? defaultValue : item);
}

export function argmin<T>(list: T[], comparator: (a: T, b: T) => boolean): T | undefined {
    if (list.length === 0) {
        return undefined; // Handle empty list case
    }

    let argminIndex = 0;
    for (let i = 1; i < list.length; i++) {
        if (comparator(list[i], list[argminIndex])) {
            argminIndex = i;
        }
    }

    return list[argminIndex];
}


export type Comparator<T> = (a: T, b: T) => boolean;

export function areArraysEqual<T>(
    array1: T[],
    array2: T[],
    comparator: Comparator<T> = (a, b) => a === b
): boolean {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
        return false;
    }

    // Check each element for equality
    for (let i = 0; i < array1.length; i++) {
        if (!comparator(array1[i], array2[i])) {
            return false;
        }
    }

    // If all elements are equal
    return true;
}

export function appendUniqueItems<T>(array1: T[], array2: T[]): T[] {
    const uniqueItems = new Set(array1);
    array2.forEach(item => {
        if (!uniqueItems.has(item)) {
            array1.push(item);
            uniqueItems.add(item);
        } else {
            console.warn(`Item ${item} already exists in the array`);
        }
    });
    return array1;
}
