import { defaultGenerateTemplate } from "components/constants/default-generate-template";
import { SerializedEditorState, SerializedParagraphNode, SerializedTextNode } from "lexical";
import { PromptTemplate, PromptWord } from "./types";
import { classNames } from "core/utils/classname-utils";
import { getStyleFromCSS } from "core/utils/text-utils";
import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete";
import { cleanupText } from "core/utils/string-utils";
import { autocompleteBackgroundPrefixes, autocompletePlacementPrefixes, autocompleteSurroundingsPrefixes, doesAutocompleteTypeHavePrefix } from "components/constants/prompt-autocomplete";
import { getUniqueArray } from "core/utils/array-utils";
import { clone, cloneDeep } from "lodash";
export const PROMPT_TEMPLATE_PLACEHOLDER = '_PLACEHOLDER_';

export function getStringFromPromptWord(param: PromptWord): string {
    if (!param.value) {
        return '';
    }

    if (param.prefix) {
        return cleanupText(param.prefix.trim() + ' ' + param.value.trim());
    }
    return cleanupText(param.value.trim());
}

export function getConnectionFromPromptWord(word: PromptWord) {
    return word.autocompleteType === 'background' ? ', ' : ' ';
}

function addWordToPrompt(prompt: string, word: PromptWord) {
    if (!prompt || prompt.length <= 0) {
        return getStringFromPromptWord(word);
    }
    return prompt + getConnectionFromPromptWord(word) + getStringFromPromptWord(word);
}

function evalTemplate(params: PromptWord[]) {
    return cleanupText(params.reduce(addWordToPrompt, '').trim());
}

export function getPromptFromTemplate(promptTemplate: PromptTemplate) {
    return evalTemplate(
        promptTemplate.words,
    );
}

type StringSearchParam = string | RegExp;

const surroundingPrefixes: StringSearchParam[] = getUniqueArray([
    ...autocompleteSurroundingsPrefixes.map(prefix => ` ${prefix.trim()} `),
    ' surrounded by ',
    ' next to ',
]);

const placementPrefixes: StringSearchParam[] = getUniqueArray([
    ...autocompletePlacementPrefixes.map(prefix => ` ${prefix.trim()} `),
    ' on ',
    ' emerging from ',
    ' buried in ',
]);

const backgroundPrefixes: StringSearchParam[] = getUniqueArray([
    ...autocompleteBackgroundPrefixes.map(prefix => ` ${prefix.trim()} `),
    ' in front of ',
    ' against ',
    /(?=with).*(?=background)/i,
    /(?=in).*(?=background)/i,
    ' in ',
]);

const typeToPrefix = {
    'placement': placementPrefixes,
    'surrounding': surroundingPrefixes,
    'background': backgroundPrefixes,
}

function findOne(searchParams: StringSearchParam[], prompt: string) {
    for (let i = 0; i < searchParams.length; ++i) {
        const search = searchParams[i];
        const index = prompt.search(search);
        if (index >= 0) {
            return {
                index,
                search,
            };
        }
    }
    return {
        index: -1,
        search: undefined,
    };
}

function validatePromptTemplate(promptTemplate: PromptTemplate) {
    const newPromptTemplate = { ...promptTemplate };
    // Check if the prompt template has a subject field. If not, update the prompt template
    const subjectWord = promptTemplate.words.find(word => word.autocompleteType === 'subject');
    if (!subjectWord) {
        newPromptTemplate.words.splice(0, 0, {
            type: 'fixed',
            value: '',
            autocompleteType: 'subject',
        });
    }
    return newPromptTemplate;
}

function getWordFromSearchResult(
    prompt: string,
    prevIndex: number,
    index: number,
    prevType: PromptAutocompleteType,
    search?: StringSearchParam,
): PromptWord {
    if (typeof (search) === 'string' && doesAutocompleteTypeHavePrefix(prevType)) {
        const prefixEnd = prevIndex + (search as string).length;
        const prefix = prompt.substring(
            prevIndex,
            prefixEnd,
        ).trim();
        const value = prompt.substring(
            prefixEnd,
            index,
        ).trim();
        return {
            type: 'fixed',
            value,
            prefix,
            autocompleteType: prevType,
        };
    }
    return {
        type: 'fixed',
        value: prompt.substring(prevIndex, index).trim(),
        autocompleteType: prevType,
    };
}

export function getTemplateFromPrompt(prompt: string) {

    const template: PromptTemplate = {
        words: [],
    }

    let prevIndex = 0;
    let prevSearch: StringSearchParam | undefined;
    let prevType: PromptAutocompleteType = 'subject';
    let index = 0;
    const typePrefixesTuples = Object.entries(typeToPrefix);
    const numPrefixes = typePrefixesTuples.length;

    for (let i = 0; i < numPrefixes; ++i) {
        const searchPrompt = prompt.substring(prevIndex);
        const [autocompleteType, prefixes] = typePrefixesTuples[i];
        const result = findOne(prefixes, searchPrompt);
        const search = result.search;
        index = result.index;

        if (index >= 0) {
            index += prevIndex;
            template.words.push(getWordFromSearchResult(
                prompt,
                prevIndex,
                index,
                prevType,
                search,
            ));
            prevIndex = index;
            prevSearch = search;
            prevType = autocompleteType as PromptAutocompleteType;
        }

    }

    if (prevIndex < prompt.length - 1) {
        template.words.push(getWordFromSearchResult(
            prompt,
            prevIndex,
            prompt.length,
            prevType || 'custom',
            prevSearch,
        ));
    }
    return validatePromptTemplate(template);
}

function mergePromptTemplateWord(
    w: PromptWord,
    wordsFrom: PromptWord[],
) {
    const { autocompleteType } = w;
    const i = wordsFrom.findIndex(w => w.autocompleteType === autocompleteType);
    if (i >= 0) {
        const wFrom = wordsFrom[i];
        w.value = wFrom.value || w.value;
        wordsFrom.splice(i, 1);
    }
}

export function mergePromptTemplateSubjects(
    templateFrom: PromptTemplate,
    templateTo: PromptTemplate,
) {
    const wordsFrom = templateFrom.words.slice();
    templateTo.words.forEach((w) => {
        if (w?.autocompleteType === 'subject') {
            mergePromptTemplateWord(w, wordsFrom);
        }
    });
    return templateTo;
}

export function mergePromptTemplate(
    templateFrom: PromptTemplate,
    templateTo: PromptTemplate,
) {
    const wordsFrom = templateFrom.words.slice();
    templateTo.words.forEach((w) => {
        if (w?.type === 'input') {
            mergePromptTemplateWord(w, wordsFrom);
        }
    });
    return templateTo;
}

export const defaultPromptTemplate: PromptTemplate = defaultGenerateTemplate.prompt;

export function getStyleStringfyPromptWord(w: PromptWord) {
    return classNames(
        w.type ? `promptTemplateType: ${w.type};` : '',
        w.autocompleteType ? `autocompleteType: ${w.autocompleteType};` : '',
        w.autocompleteValues ? `autocompleteValues: ${w.autocompleteValues};` : '',
        w.placeholder ? `placeholder: ${w.placeholder?.replace(/\s+/g, '_')};` : '',
    );
}

export function getPromptWordFromStyleString(styleString: string): PromptWord {
    const styleObject = getStyleFromCSS(styleString);
    return {
        value: '',
        // @ts-ignore
        type: styleObject.promptTemplateType || 'fixed',
        // @ts-ignore
        autocompleteType: styleObject.autocompleteType || undefined,
        // @ts-ignore
        autocompleteValues: styleObject.autocompleteValues || undefined,
        placeholder: styleObject.placeholder?.replace(/_+/g, ' ') || '',
    }
}

export function getPromptStateFromPromptTemplate(promptTemplate: PromptTemplate) {
    const editorState: SerializedEditorState = {
        "root": {
            "children": [
                {
                    // @ts-ignore
                    "children": [],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1,
                    "tag": "h1"
                }
            ],
            "direction": "ltr",
            "format": "",
            "indent": 0,
            "type": "root",
            "version": 1
        }
    };
    promptTemplate.words.forEach((w, index) => {
        const paragraphNode = editorState.root.children[0];
        (paragraphNode as SerializedParagraphNode).children.push({
            "detail": 0,
            "format": 0,
            "mode": "normal",
            "style": getStyleStringfyPromptWord(w),
            "text": (index === 0 ? '' : ' ') + (w.value || w.placeholder || ''),
            "type": "text",
            "version": 1,
        } as SerializedTextNode)
    });
    return editorState;
}

export function getPromptTemplateFromPromptStates(editorState: SerializedEditorState) {
    const promptTemplate: PromptTemplate = {
        words: [],
    };
    const paragraphNode = editorState.root.children.find(n => n.type === 'paragraph') as SerializedParagraphNode;
    if (paragraphNode) {
        paragraphNode.children.forEach((node) => {
            if (node.type === 'text') {
                const text = (node as SerializedTextNode).text;
                const w: PromptWord = {
                    ...getPromptWordFromStyleString((node as any).style),
                    value: text.trim(),
                }
                promptTemplate.words.push(w);
            }
        })
    }
    return promptTemplate;
}

export function getSubjectFromPromptTemplate(promptTemplate: PromptTemplate) {
    if (!promptTemplate) {
        return;
    }

    const subjectWord = promptTemplate.words.find((promptWord) => {
        return promptWord.autocompleteType === 'subject'
    });

    return subjectWord?.value;
}

export function setPromptTemplateSubject(
    template: PromptTemplate,
    subject: string,
) {
    template = cloneDeep(template);

    const subjectWordIndex = template.words.findIndex(w => w.autocompleteType === 'subject');

    let words: PromptWord[] = [];

    if (subjectWordIndex >= 0) {
        const subjectWord = template.words[subjectWordIndex];
        subjectWord.value = subject;
        words = template.words;
    } else {
        const subjectWord: PromptWord = {
            value: subject,
            autocompleteType: "subject",
            type: "input",
        }
        words = [subjectWord, ...template.words];
    }

    return {
        ...template,
        words,
    };
}