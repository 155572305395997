import React from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames } from 'core/utils/classname-utils';
import { Tooltip } from 'components/utils/tooltip';

import styles from './dashboard-custom-models.module.css';
import { useCanUserCreateCustomModel } from 'hooks/use-custom-models-effect';
import { editorContextStore } from 'contexts/editor-context';
import { CustomModelType, UiDisplayMessageDialogEventHandler } from 'core/common/types';
import { debugLog } from 'core/utils/print-utilts';
import { getObjectEntries } from 'core/utils/type-utils';
import { clamp } from 'lodash';
import { DashboardTitleClassName } from 'components/constants/class-names';
import { ChevronRight } from 'lucide-react';


export interface CustomModelTrainingInputSliderOptions {
    learningRate: number[],
    steps: number[],
    iterMultipler: number[],
}

export function getCustomModelTrainingInputFromSliderValue({
    percent,
    sliderOptions,
}: {
    percent: number,
    sliderOptions: CustomModelTrainingInputSliderOptions,
}) {
    return Object.fromEntries(
        getObjectEntries(sliderOptions)
        .map(([key, options]) => {
            const index = clamp(
                Math.round(percent * options.length - 1),
                0,
                options.length - 1,
            );

            debugLog(`Get training input ${key} from ${percent} at ${index}: ${options[index]}`);

            return [
                key,
                options[index],
            ];
        })
    ) as Record<keyof CustomModelTrainingInputSliderOptions, number>;
}


export interface CustomModelTrainingInputSliderConfigs {
    defaultValue: number,
    maxNumSteps: number,
    options: CustomModelTrainingInputSliderOptions,
};

export const defaultCustomModelTrainingInputSliderOptions: CustomModelTrainingInputSliderConfigs = {
    defaultValue: 5,
    maxNumSteps: 10,
    options: {
        learningRate: [
            2e-4,
            1e-4,
            8e-5,
        ],
        steps: [1000, 1500, 2000, 2500, 3000, 4000],
        iterMultipler: [0.5, 0.8, 1.0, 1.2, 1.5, 2.0],
    },
};

export type CustomModelWorkflow = {
    label?: string;
    subline?: string;
    imageUrl: string;
    hoverText: string;
    dataTabLabel?: string;
    trainingSliderConfigs: CustomModelTrainingInputSliderConfigs;
    moodboardExampleUrls?: string[];
    moodboardInfoboxText?: string | string[];
    playgroundPrompt?: string;
};

export const customModelWorkflowData: {
    [type in CustomModelType]?: CustomModelWorkflow
} = {
    [CustomModelType.Product]: {
        label: 'Product',
        dataTabLabel: 'Product photos',
        subline: 'Train model based on your product',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/348762d6-a9b9-44dc-2986-e4aa74209c00/1024',
        hoverText: 'Create a custom model that preserves the details of your product.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your product. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/17bab463-c909-4f2e-9edd-9e1d5d8b7900/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d80c93e8-8ccd-4ace-9970-18ac45afdf00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bce0a7d5-a4cd-44f7-cbc5-aa45a703e700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/54842d7f-ac67-4ee0-bf4a-706dc2db3000/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/77ee8f8b-f73d-47a2-78bb-b3bc22b4c600/1024',
        ],
        playgroundPrompt: 'Photo of @MENTION_MODEL in front of a light brown background, 35mm lens',
    },
    [CustomModelType.Fashion]: {
        label: 'Fashion & Wearables',
        dataTabLabel: 'Clothing photos',
        subline: 'Train model based on your fashion item',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6ec46ec0-14d8-4930-f06f-8df49c0fa300/1024',
        hoverText: 'Create a custom model that preserves the details of your clothing.', // TODO Update text
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    1e-4,
                    9e-5,
                    8e-5,
                    7e-5,
                ],
                steps: [2000, 2500, 3000, 3500, 4000, 4500],
                iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0],
            },
        },
        moodboardInfoboxText: [
            'Upload 3 or more photos of your clothing. You can take these photos yourself or find them online',
            'Results get better with more photos from different angles',
        ],
        moodboardExampleUrls: [
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/345d39d0-6ba5-47b5-ba5a-d8f31ed04a00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cced9c26-897b-4dcc-a3ee-99d3bf2eaa00/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/613e9b50-c5e3-4f88-33f9-00a3d2aab500/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/26c9b3c5-5695-4b02-1d74-1fd122384700/1024',
            'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6a832c02-b5a0-449c-4729-12394cc6da00/1024',
        ],
        playgroundPrompt: 'Tall model wearing @MENTION_MODEL walking on the street in the city towards the camera, 80mm lens',
    },
    [CustomModelType.Style]: {
        label: 'Aesthetic',
        subline: 'Create artistic model based on your style',
        imageUrl: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c5cd42e6-6332-4774-6676-0a665d3b6400/1024',
        hoverText: 'Create a model that learns a style based on your moodboard.',
        trainingSliderConfigs: {
            defaultValue: 5,
            maxNumSteps: 10,
            options: {
                learningRate: [
                    2e-4,
                    1e-4,
                    8e-5,
                ],
                steps: [500, 1000, 1500, 2000, 2500],
                iterMultipler: [0.5, 0.8, 1.0, 1.2, 1.5],
            },
        },
        moodboardInfoboxText: 'Upload 3 photos with a consistent style aesthetic - results get better with more photos.',
        moodboardExampleUrls: [
            // TODO add moodboard examples
            // 'https://picsum.photos/seed/CustomModelWorkflowAestheticMoodboardExample1/500/500',
            // 'https://picsum.photos/seed/CustomModelWorkflowAestheticMoodboardExample2/500/500',
        ],
    },
};

export type CustomModelWorkflowCardProps = {
    disabled?: boolean;
    workflow: CustomModelWorkflow;
    onClick: () => void;
};

export function CustomModelWorkflowCard({
    workflow,
    disabled = false,
    onClick = () => { },
}: CustomModelWorkflowCardProps) {
    return (
        <Tooltip
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={
                <div
                    id="create-new-project-workflow-card"
                    className={classNames(
                        'flex flex-row items-center justify-start group transition-colors rounded-md ',
                        disabled
                            ? 'cursor-not-allowed text-zinc-500'
                            : `cursor-pointer group-hover:border-neutral-700 group-hover:shadow-neutral-100`,
                        'pb-[82%] relative overflow-hidden'
                    )}
                    onClick={disabled ? undefined : onClick}
                >
                    <div
                        className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
                        style={{
                            backgroundImage: workflow.imageUrl
                                ? `url(${workflow.imageUrl})`
                                : 'url(https://picsum.photos/seed/' +
                                (workflow.label || 'CustomModelWorkflow').split(' ')[0] +
                                '/500/500)',
                        }}
                    ></div>
                    <div className="absolute inset-0 pointer-events-none select-none bg-cover bg-center bg-black/30 transition-all duration-300 group-hover:bg-transparent"></div>
                    <div
                        className="absolute w-full flex flex-col bottom-0 bg-gradient-to-t from-black to-transparent transition-all group-hover:from-transparent duration-300 pt-12 pb-4 px-4 overflow-hidden text-ellipsis text-left"
                        style={{
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                        }}
                    >
                        {workflow.label && (
                            <div
                                className={`w-full text-zinc-300 text-base font-bold relative left-0 group-hover:left-10 opacity-100 group-hover:opacity-0 transition-all`}
                            >
                                {workflow.label}
                            </div>
                        )}
                        {workflow.subline && (
                            <div
                                className={`w-full text-zinc-400 text-xs font-normal relative left-0 group-hover:left-10 opacity-100 group-hover:opacity-0 transition-all`}
                            >
                                {workflow.subline}
                            </div>
                        )}
                        <button
                            className={classNames(
                                "flex flex-row items-center gap-2 mt-4 w-fit px-2 py-1 text-sm font-semibold rounded backdrop-blur-sm bg-zinc-950/20 border border-zinc-800 text-zinc-300 transition-colors",
                                "hover:text-zinc-900 hover:bg-lime-500"
                            )}
                        >
                            <span>
                                Start training
                            </span>
                            <ChevronRight size={16} className='relative left-0 group-hover:left-1 transition-all'/>
                        </button>
                    </div>
                </div>
            }
            contentProps={{
                align: 'start',
                sideOffset: 8,
            }}
            contentChildren={
                workflow.hoverText || `Create ${workflow.label} photography project.`
            }
        />
    );
}

const workflowGridClassName = `${styles.DashboardWorkflowGrid}`;



export function CustomModelWorkflows() {
    const navigate = useNavigate();

    const canCreate = useCanUserCreateCustomModel();


    const onClickCUstomModelWorkflow = React.useCallback(({
        customModelType,
    }: {
        customModelType: string,
    }) => {
        if (!canCreate) {

            const {
                eventEmitter,
            } = editorContextStore.getState();

            debugLog('Display custom model quota subscribe dialog');

            eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                'ui:display-message-dialog',
                'quota-subscribe',
                {
                    title: "No custom model credits left.",
                    header: "You have used all custom model credits. Please subscribe to train more custom models.",
                }
            );

            return;
        }


        navigate(`/newModel/${customModelType}`, {
            replace: true,
        });

    }, [navigate, canCreate]);

    return (
        <div className='flex flex-col items-stretch gap-4'>
            <div className={DashboardTitleClassName}>
                Create custom model
            </div>
            <div className={classNames('customModelWorkflows', workflowGridClassName, 'w-full flex flex-row')}>
                {getObjectEntries(customModelWorkflowData).map((entry) => {
                    if (!entry) {
                        return null;
                    }

                    const [customModelType, workflow] = entry;

                    if (!workflow) {
                        return null;
                    }

                    return (
                        <CustomModelWorkflowCard
                            key={customModelType}
                            workflow={workflow}
                            onClick={() => {
                                onClickCUstomModelWorkflow({
                                    customModelType,
                                });
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
