import React from "react"
import { Navigate } from "./components/navigate"
import { Scene3dCanvas } from "components/canvas3d/element3d"
import { editorContextStore } from "contexts/editor-context"

function exitTransformProps3d() {
    editorContextStore.getState().setActiveLeftPanels((prevLeftPanels) => {
        if (prevLeftPanels.length <= 1) {
            return ['Assets'];
        }
        return prevLeftPanels.slice(0, prevLeftPanels.length - 1);
    });
}

export function TransformProps3d() {
    return (
        <div
            className="flex flex-col"
        >
            <Scene3dCanvas
                onExit={exitTransformProps3d}
            />
            <Navigate>
                Transform 3D Prop
            </Navigate>
        </div>
    )
}