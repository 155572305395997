import React from "react"
import styles from './alert-dialog.module.css';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { classNames } from "core/utils/classname-utils";
import { AlertDialogOverlayZIndex } from "components/constants/zIndex";



export function EditorAlertDialogTitle({
    children,
    className = "",
    ...props
}: AlertDialogPrimitive.AlertDialogTitleProps) {
    return (
        <AlertDialogPrimitive.Title
            {...props}
            className={classNames(
                'font-medium text-zinc-300',
                className,
            )}
        >
            {children}
        </AlertDialogPrimitive.Title>
    );
}

export function EditorAlertDialogCancelButton({
    children,
    className = "",
    ...props
}: AlertDialogPrimitive.AlertDialogCancelProps) {
    return (
        <AlertDialogPrimitive.Cancel
            {...props}
            className={classNames(
                "px-4 py-2 flex flex-row justify-center items-center bg-zinc-900 rounded-md border border-solid border-zinc-800 hover:border-lime-600 hover:text-lime-500 active:text-lime-600 focus:border-zinc-500 focus:hover:border-lime-500 focus-visible:outline-none transition-colors select-none cursor-pointer"
            )}
        >
            {children}
        </AlertDialogPrimitive.Cancel>
    )
}

export function EditorAlertDialogActionButton({
    children,
    className = "",
    ...props
}: AlertDialogPrimitive.AlertDialogActionProps) {
    return (
        <AlertDialogPrimitive.Action
            {...props}
            className={classNames(
                'px-4 py-2 flex flex-row justify-center items-center rounded-md focus-visible:outline-none transition-colors select-none cursor-pointer font-medium bg-red-500 hover:bg-red-600 text-red-100 focus-visible:outline-red-600',
                className,
            )}
        >
            {children}
        </AlertDialogPrimitive.Action>
    )
}

export function EditorAlertDialogContentContainer({
    className = "",
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                'flex flex-col px-4 py-4 mx-8 bg-zinc-900 rounded-md border border-zinc-800 shadow-lg-center shadow-zinc-300/10',
                className,
            )}
        >
            {children}
        </div>
    )
}

export function EditorAlertDialog({
    triggerChildren,
    contentChildren,
    contentProps = {},
    triggerProps = {},
    ...props
}: AlertDialogPrimitive.AlertDialogProps & {
    contentChildren?: React.ReactNode,
    contentProps?: AlertDialogPrimitive.DialogContentProps,
    triggerChildren?: React.ReactNode,
    triggerProps?: AlertDialogPrimitive.DialogTriggerProps,
}) {
    const contentClassName = contentProps?.className || "";

    return (
        <AlertDialogPrimitive.Root
            {...props}
        >
            {
                triggerChildren && (
                    <AlertDialogPrimitive.Trigger
                        {...triggerProps}
                    >
                        {triggerChildren}
                    </AlertDialogPrimitive.Trigger>
                )
            }
            <AlertDialogPrimitive.Portal>
                <AlertDialogPrimitive.Overlay
                    className={styles.AlertDialogOverlay}
                    style={{
                        zIndex: AlertDialogOverlayZIndex,
                    }}
                />
                <AlertDialogPrimitive.Content
                    {...contentProps}
                    className={classNames(
                        styles.AlertDialogContent,
                        'absolute w-full h-full top-0 flex justify-center items-center text-zinc-300',
                        contentClassName,
                    )}
                    style={{
                        zIndex: AlertDialogOverlayZIndex + 1,
                    }}
                >
                    {contentChildren}
                </AlertDialogPrimitive.Content>
            </AlertDialogPrimitive.Portal>
        </AlertDialogPrimitive.Root>
    );
}