import { displayUiMessage } from "components/utils/display-message";
import { editorContextStore } from "contexts/editor-context";
import { StripeSubscriptionPriceId } from "core/common/types/subscription";


export async function subscribeToPlan(
    price: StripeSubscriptionPriceId,
    openNewPage = false,
) {
    const newWindow = openNewPage && window.open("", "_blank");
    return await editorContextStore.getState().backend?.createCheckoutSession({
        price,
    }).then(({ url, message }) => {
        if (url) {
            if (newWindow) {
                newWindow.location.assign(url);
            } else {
                window.location.assign(url);
            }
        } else {
            if (message) displayUiMessage(message);
        }
    }).catch(error => {
        console.error(error);

        displayUiMessage('Unknown error occurred. Please refresh the page.', 'error');
    });
}

export async function subscribeToIndividualProPlan(openNewPage = false) {
    const newWindow = openNewPage && window.open("", "_blank");
    return await editorContextStore.getState().backend?.createCheckoutSession({
        price: StripeSubscriptionPriceId.IndividualProPlan,
    }).then(({ url, message }) => {
        if (url) {
            if (newWindow) {
                newWindow.location.assign(url);
            } else {
                window.location.assign(url);
            }
        } else {
            if (message) displayUiMessage(message);
        }
    }).catch(error => {
        console.error(error);

        displayUiMessage('Unknown error occurred. Please refresh the page.', 'error');
    });
}

export async function openSubscriptionsLink(openNewPage = true) {
    return await editorContextStore.getState().backend?.createSubscriptionsPortalLink({
        returnUrl: window.location.origin,
    }).then((url) => {
        if (!url) {
            console.error("Url is invalid");
            return;
        }
        const newWindow = openNewPage && window.open("", "_blank");
        if (newWindow) {
            newWindow.location.assign(url);
        } else {
            window.location.assign(url);
        }
    }).catch(error => {
        console.error(error);

        displayUiMessage('Unknown error occurred. Please refresh the page.', 'error');
    });
}