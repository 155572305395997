import { Editor } from "core/editor";
import { SceneJSON } from "core/common/types/scene-json";
import { debugError, debugLog } from "./print-utilts";
import ObjectImporter from "./object-importer";
import { getObjectEntries } from "./type-utils";
import { generateUUID } from "./uuid-utils";
import { ILayer } from "core/common/layers";
import { defaultObjectOptions } from "core/common/constants";
import { addObjectToCanvas } from "components/utils/add-to-canvas-utils";

export async function addSceneJsonToCanvas({
    sceneJSON,
    editor,
    targetGenerationFrameTopLeft = {
        left: 0,
        top: 0,
    },
}: {
    sceneJSON: SceneJSON,
    editor: Editor,
    targetGenerationFrameTopLeft?: {
        top: number,
        left: number,
    },
}) {
    try {
        if (!sceneJSON?.objects || Object.keys(sceneJSON.objects).length <= 0) {
            debugError("No objects from the scene to add to the canvas: ", sceneJSON);
            return;
        }

        const {
            generationFrameBounds,
        } = sceneJSON;

        const offset = {
            top: targetGenerationFrameTopLeft.top - generationFrameBounds.top,
            left: targetGenerationFrameTopLeft.left - generationFrameBounds.left,
        };

        const objectImporter = new ObjectImporter(editor);

        const canvas = editor.canvas.canvas;

        const importedObjects = await Promise.all(getObjectEntries(
            sceneJSON.objects
        ).map(async ([, layer]) => {
            if (!layer) {
                return;
            }

            try {
                const objectId = generateUUID();
                layer.id = objectId;

                const object = await objectImporter.import(
                    layer as ILayer,
                    defaultObjectOptions as Required<ILayer>,
                );

                if (!object) {
                    return;
                }

                object.left = (object.left ?? 0) + offset.left;
                object.top = (object.top ?? 0) + offset.top;

                (object as any).zIndex = layer.zIndex ?? 0;

                return object;
            } catch (error) {
                debugError(error);
            }
            return undefined;
        }));

        importedObjects.filter(Boolean)
        .sort((a, b) => (
            (a as any).zIndex - (b as any).zIndex
        ))
        .forEach((object) => {
            if (!object) {
                return;
            }

            addObjectToCanvas({
                canvas,
                object,
            });

            debugLog(object);
        });

        const generationFrame = editor.generationFrames.generationFrame;

        if (generationFrame) {
            generationFrame.left = targetGenerationFrameTopLeft.left;
            generationFrame.top = targetGenerationFrameTopLeft.top;
        } else {
            debugError('The canvas do not have a valid generation frame.');
        }

        // await editor.zoom.zoomToFit(
        //     generationFrame,
        //     180,
        //     {
        //         animate: true,
        //         durationMs: 500,
        //         fixZoom: true,
        //     },
        // );

        editor.objects.onShuffledStack();

        editor.history.save();

        return importedObjects;
    } catch (error) {
        console.error(error);
    }
}