import React from "react";
import { Navigate } from "../components/navigate";
import { LeftPanelSectionContainer } from "../base";
import { NumberSlider } from "../components/number-slider";
import { editorContextStore } from "contexts/editor-context";
import { EditImageProcessType } from "core/common/types";
import { OtherProcessRunningButton } from "./buttons";
import { ProgressBar } from "../components/progress-bar";
import { classNames } from "core/utils/classname-utils";
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled } from "components/constants/class-names";
import { isStaticImageObject } from "core/utils/type-guards";
import { createObjectEditImageProgressController, EditImageProgressController, ObjectWithProgress } from "./edit-image-process";
import { AnalyticsConfig } from "analytics/config";


function UpscaleV2PrimaryButton({
    isProcessing,
    isOtherProcessRunning,
    editImageProcessType,
    onRenderStart,
    onRenderCancel,
    disabled = false,
}: {
    isProcessing: boolean,
    isOtherProcessRunning: boolean,
    editImageProcessType: EditImageProcessType | undefined,
    onRenderStart: () => void,
    onRenderCancel: () => void,
    disabled?: boolean,
}) {
    if (isOtherProcessRunning) {
        return (
            <OtherProcessRunningButton
                type={editImageProcessType}
            />
        );
    }

    if (isProcessing) {
        return (
            <ProgressBar
                progress={0.01}
                cancellable
                cancelMessage="Stop upscale"
                onClick={onRenderCancel}
            />
        );
    }

    if (disabled) {
        return (
            <button
                className={classNames(
                    PrimaryButtonClassNameDisabled,
                    "cursor-not-allowed",
                )}
            >
                Upscale Disabled
            </button>
        )
    }

    return (
        <button
            className={classNames(
                PrimaryButtonClassName,
            )}
            onClick={onRenderStart}
        >
            Upscale
        </button>
    )
}

const upscaleV2EditImageProcessType = "upscale-premium";

export function LeftPanelUpscaleV2() {
    const activeObject = editorContextStore(state => state.activeObject);
    const upscaleV2Creativity = editorContextStore(state => state.upscaleV2Creativity);
    const setUpscaleV2Creativity = editorContextStore(state => state.setUpscaleV2Creativity);

    const [disabled, setDisabled] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [editImageProcessType, setEditImageProcessType] = React.useState<EditImageProcessType>();
    const [editImageProgressController, setEditImageProgressController] = React.useState<EditImageProgressController | undefined>(undefined);

    const isOtherProcessRunning = React.useMemo(() => editImageProcessType != null && editImageProcessType !== upscaleV2EditImageProcessType, [editImageProcessType]);

    React.useEffect(() => {
        if (isStaticImageObject(activeObject)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

        const editImageProgressController = (activeObject as ObjectWithProgress)?.editImageProgressController;

        setEditImageProgressController(editImageProgressController);

        if (editImageProgressController?.isDestroyed === false) {
            setIsProcessing(true);
            setEditImageProcessType(editImageProgressController.type);
        }
    }, [activeObject]);

    return (
        <div
            className="flex flex-col"
        >
            <Navigate/>
            <LeftPanelSectionContainer>
                <NumberSlider
                    name="Creativity"
                    nameClassName='min-w-[95px] text-sm font-semibold'
                    value={upscaleV2Creativity}
                    setValue={setUpscaleV2Creativity}
                    defaultValue={0.5}
                    min={0}
                    max={1}
                    step={0.1}
                />
                <UpscaleV2PrimaryButton
                    disabled={disabled}
                    isProcessing={isProcessing}
                    editImageProcessType={editImageProcessType}
                    isOtherProcessRunning={isOtherProcessRunning}
                    onRenderStart={() => {
                        if (!isStaticImageObject(activeObject)) {
                            return;
                        }

                        setEditImageProcessType(upscaleV2EditImageProcessType);

                        setIsProcessing(true);

                        setEditImageProgressController(
                            createObjectEditImageProgressController({
                                type: upscaleV2EditImageProcessType,
                                object: activeObject,
                            }),
                        );

                        editorContextStore.getState().analytics.track(
                            AnalyticsConfig.EditImageToolButtonInteraction,
                            {
                                interactionTarget: 'Upscale Creative Button'
                            }
                        );
                    }}
                    onRenderCancel={() => {
                        editImageProgressController?.destroy();
                        setIsProcessing(false);
                    }}
                />
            </LeftPanelSectionContainer>
        </div>
    )
}