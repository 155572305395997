import { classNames } from 'core/utils/classname-utils';
import React from 'react';
import { FlexDividerHorizontal } from './divider-horizontal';


export function ContentDividerHorizontal({
    className = '',
    children,
}: {
    className?: string,
    children: React.ReactNode,
}) {
    return (
        <div className={classNames(
            'w-full flex flex-row items-center',
            className,
        )}>
            <FlexDividerHorizontal />
            {children}
            <FlexDividerHorizontal />
        </div>
    );
}

export function OrDividerHorizontal({
    className = '',
}: {
    className?: string,
}) {
    return (
        <ContentDividerHorizontal className={className}>
            <span className='mx-6'>
                or
            </span>
        </ContentDividerHorizontal>
    );
}