import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { UploadIcon } from '@radix-ui/react-icons';
import { ApiInputType } from 'core/common/types/api';
import { getDataUrlFromImageElementResized, getImageElementFromFilesAsync } from 'core/utils/image-utils';
import React from 'react';
import { InputLabel, isApiInputValueMissing } from './input-utils';
import { classNames } from 'core/utils/classname-utils';

export type ImageInputProps = {
    type: ApiInputType.Image,
    id: string,
    name: React.ReactNode,
    description?: React.ReactNode,
    value: string,
    onValueChange: (value: string) => void,
    targetLength?: number,
    required?: boolean,
}

function ImageUploadText() {
    return (
        <>
            <UploadIcon
                width={18}
                height={18}
            />
            Upload Image
        </>
    )
}

export function ImageInput({
    id,
    name,
    value,
    onValueChange,
    description,
    targetLength = 1024,
    required = false,
}: ImageInputProps) {
    const [isImageLoaded, setImageLoaded] = React.useState(false);

    const handleUploadFiles = React.useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const imageLength = Math.max(Math.min(targetLength, 1536), 512);

        const files = e.target.files;
        if (!files) {
            return;
        }


        const imageElement = await getImageElementFromFilesAsync(files);

        if (!imageElement) {
            return;
        }

        const imageDataUrl = await getDataUrlFromImageElementResized({
            image: imageElement,
            targetLength: imageLength,
        });

        onValueChange(imageDataUrl || "");

    }, [targetLength, onValueChange]);

    const labelId = `image-input-${id}`;

    const missingRequired = required && isApiInputValueMissing({
        type: ApiInputType.Image,
        value,
        id,
        name,
        onValueChange,
    });

    return (
        <div
            className='flex flex-col gap-2'
        >
            <InputLabel
                required={required}
                missingRequired={missingRequired}
                description={description}
            >
                {name}
            </InputLabel>
            <input
                type='file'
                id={labelId}
                style={{
                    display: 'none',
                }}
                onChange={(e) => {
                    handleUploadFiles(e);
                }}
            />
            <label
                htmlFor={labelId}
                className={classNames(
                    'relative p-4 group cursor-pointer rounded-md border flex flex-row items-center justify-center transition-colors',
                    missingRequired ? "border-red-800 hover:border-red-500 hover:text-red-500" : "border-zinc-800 hover:border-lime-500 hover:text-lime-500",
                )}
            >
                <div className='w-[256px]'>
                    <AspectRatio.Root
                        ratio={1}
                        className='rounded overflow-hidden flex flex-row items-center justify-center gap-2 outline-1 outline-zinc-800/50 outline-dashed'
                    >
                        <img
                            alt={`uploaded ${id}`}
                            className='object-cover w-full h-full'
                            src={value}
                            style={{
                                display: isImageLoaded ? 'block' : 'none',
                            }}
                            onLoad={() => setImageLoaded(true)}
                            onError={() => setImageLoaded(false)}
                        />
                        {
                            !isImageLoaded && <ImageUploadText />
                        }
                    </AspectRatio.Root>
                </div>
            </label>
        </div>
    )
}