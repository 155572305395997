import { editorContextStore } from 'contexts/editor-context';
import { TutorialFinishEventHandler, TutorialSkipEventHandler, TutorialStartEventHandler } from 'core/common/types';
import React from 'react';
import ReactDOM from 'react-dom';
import { StepByStepsTutorialConfig } from './config';
import { StepByStepTutorial } from './step-by-step-tutorial';
import { createNewProjectConfig } from './tutorials/create-new-project';
import { FirstOnboardDefaultHighlighters, stepByStepsFirstOnboardConfig } from './tutorials/first-onboard';

function ProjectTutorialWindowInner({
    config,
    onClose: onCloseCallback,
}: {
    config?: StepByStepsTutorialConfig,
    onClose: () => void,
}) {
    const [step, setStep] = React.useState(0);

    const onClose = () => {
        onCloseCallback();

        if (!config) {
            return;
        }

        if (step >= config.steps.length - 1) {

            editorContextStore.getState().editor?.emit<TutorialFinishEventHandler>(
                'tutorial:finish',
                step,
                config.name,
            );

        } else {

            editorContextStore.getState().editor?.emit<TutorialSkipEventHandler>(
                'tutorial:skip',
                step,
                config.name,
            );

        }

    };

    return ReactDOM.createPortal(
        <StepByStepTutorial
            step={step}
            config={config}
            setStep={setStep}
            onSkip={onClose}
            onClose={onClose}
        />,
        document.body,
    );
}

export function ProjectTutorialWindow({
    isOpen,
    setIsOpen,
    config,
}: {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    config?: StepByStepsTutorialConfig,
}) {

    const userOnboardData = editorContextStore(state => state.userOnboardData);

    React.useEffect(() => {
        const isOpen = userOnboardData ? !userOnboardData.isProjectTutorialFinished : false;

        setIsOpen(isOpen);
    }, [userOnboardData, setIsOpen]);

    React.useEffect(() => {
        if (isOpen) {
            editorContextStore.getState().editor?.emit<TutorialStartEventHandler>(
                'tutorial:start',
                config?.name,
            );
        }
    }, [config, isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <ProjectTutorialWindowInner
            config={config}
            onClose={() => {
                setIsOpen(false);
                const {
                    backend,
                    setUserOnboardData,
                } = editorContextStore.getState();
                setUserOnboardData((userOnboardData) => {
                    const newData = {
                        ...userOnboardData,
                        isProjectTutorialFinished: true,
                    };
                    backend?.setUserOnboardData({
                        userOnboardData: newData,
                    });
                    return newData;
                });
            }}
        />
    )
}

export function CreateNewProjectTutorial() {
    const [isOpen, setIsOpen] = React.useState(true);

    return (
        <ProjectTutorialWindow
            config={createNewProjectConfig}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        />
    );
}

export function FirstOnboardEditorTutorial() {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isFirstOnboard, setIsFirstOnboard] = React.useState(false);
    const isProjectTutorialFinished = editorContextStore(state => state.userOnboardData?.isProjectTutorialFinished);

    React.useEffect(() => {
        if (!isProjectTutorialFinished) {
            setIsFirstOnboard(true);
            console.log('Update first onboard to true');
        }
    }, [isProjectTutorialFinished]);
    return (
        <>
            <ProjectTutorialWindow
                config={stepByStepsFirstOnboardConfig}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            {!isOpen && isFirstOnboard && <FirstOnboardDefaultHighlighters/>}
        </>
    );
}