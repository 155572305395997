// ProductPhotography JSON
import { SampleProjectScene } from "core/common/scene";

export const ProductPhotographyProject: SampleProjectScene = {
    "name": "Example Project",
    "version": "1",
    "objects": {
        "JTd-Ms9no1ka-ZElovq71": {
            "width": 630,
            "metadata": {
                "imageMaskFilterType": "#0000ff",
                "imageType": "hed",
                "colorDisplayType": "color-and-shape",
                "placementPrefix": "on",
                "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9984f223-ba3a-4193-6c1b-24e6239eb600/public",
                "placement": "circular platforms"
            },
            "opacity": 1,
            "top": 504.17,
            "scaleY": 1,
            "id": "JTd-Ms9no1ka-ZElovq71",
            "originY": "top",
            "angle": 0,
            "cropY": 0,
            "flipY": false,
            "skewX": 0,
            "cropX": 0,
            "height": 272,
            "generationId": "",
            "originX": "left",
            "scaleX": 1,
            "left": -9.5,
            "flipX": false,
            "asset": {
                "type": "image-url",
                "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9984f223-ba3a-4193-6c1b-24e6239eb600/public"
            },
            "skewY": 0,
            "visible": true,
            "strokeWidth": 0,
            "name": "StaticImage",
            "zIndex": 0,
            "type": "StaticImage"
        },
        "dTvjETFohn0p0X-4QIMRl": {
            "left": 55.5,
            "skewY": 0,
            "flipX": false,
            "originY": "top",
            "scaleX": 1,
            "metadata": {
                "subject": "a bottle",
                "imageMaskFilterType": "#ffffff"
            },
            "generationId": "",
            "name": "StaticImage",
            "zIndex": 1,
            "angle": 0,
            "id": "dTvjETFohn0p0X-4QIMRl",
            "strokeWidth": 0,
            "cropY": 0,
            "asset": {
                "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/55fbb185-e305-487b-7908-52b948f26e00/public",
                "type": "image-url"
            },
            "originX": "left",
            "flipY": false,
            "skewX": 0,
            "width": 500,
            "cropX": 0,
            "height": 500,
            "scaleY": 1,
            "visible": true,
            "type": "StaticImage",
            "top": 58,
            "opacity": 1
        }
    },
    "frame": {
        "height": 0,
        "width": 0
    },
    "metadata": {
        "animated": false
    }
} as const;