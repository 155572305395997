import type { Editor } from "core/editor"
import type { ControllerOptions, FabricCanvas, EditorState } from "core/common/interfaces"
import type { EditorConfig } from "core/common/types"

export class Base {
  protected canvas: FabricCanvas
  protected config: EditorConfig
  protected editor: Editor
  protected state: EditorState
  constructor({ canvas, config, editor, state }: ControllerOptions) {
    this.canvas = canvas
    this.config = config
    this.editor = editor
    this.state = state
  }
}