import { LeftPanelItemType } from "components/constants/editor-options";
import { createContext, } from "react";
import { EditorState } from "core/common/interfaces"
import { CustomModelType, GenerateStrength, LeftPanelAlertDialogProps, StateUpdater, defaultTryOnModelPreviewFilterQueryConstraints, getDefaultCustomModelEditorState, getDummyCustomModelEditorState, resetCustomModelEditorState } from "core/common/types"

import create from 'zustand';
import createVanilla from 'zustand/vanilla';

import { subscribeWithSelector } from 'zustand/middleware'
import { createBackend } from "backend";
import { GENERATION_FRAME_BOTTOM_FILL_DARK } from "components/constants/colors";
import { ColorAttentionMaskMap } from "core/common/types";
import { getUniqueArray } from "core/utils/array-utils";
import { emptyGenerateTemplate } from "components/constants/default-generate-template";
import { cloneDeep } from "lodash";
import { getDummyAnalytics } from "analytics/base-analytics";
import { createAnalytics } from "analytics";
import { getDummyApiEditorContextState, getDefaultApiEditorContextState } from './api-editor-context';
import { getDummyGenerateToolEditorState, getDefaultGenerateToolEditorState } from './generate-tool-editor-context';
import { getDummyRealTimeRenderEditorState, getDefaultRealTimeRenderEditorState, resetRealtimeRenderEditorState } from 'core/common/types/realtime-render';
import {
    getDummyMagicEraseEditorState,
    getDefaultMagicEraseEditorState,
} from 'core/common/types/magic-erase';
import {
    getDummyMobileEditorState,
    getDefaultMobileEditorState,
} from 'core/common/types/mobile';
import { isMobile } from "components/utils/is-mobile";
import { EditorEventEmitter } from "core/common/editor-event-emitter";
import { FeatureFlagController } from 'core/controllers/featureFlags';
import { getDefaultUpscaleV2EditorState, getDummyUpscaleV2EditorState, resetUpscaleV2EditorState } from "core/common/types/upscale-v2";
import { getDefaultColorCorrectV2EditorState, getDummyColorCorrectV2EditorState, resetColorCorrectV2EditorState } from "core/common/types/color-correct-v2";
import { getDefaultRegenerateProductEditorState, getDummyRegenerateProductEditorState, resetRegenerateProductEditorState } from "core/common/types/regenerate-product";

const noop = () => { };

export interface IEditorContext extends EditorState {
    isLeftPanelOpen: boolean
    setIsLeftPanelOpen: (value: boolean) => void,
    activeLeftPanels: LeftPanelItemType[],
    setActiveLeftPanels: (value: StateUpdater<LeftPanelItemType[]>) => void,
    skipActiveObjectLeftPanelUpdate: { current: boolean },
    isLeftPanelAlertOpen: boolean,
    setLeftPanelAlertOpen: (value: boolean) => void,
    leftPanelAlertProps: LeftPanelAlertDialogProps,
    setLeftPanelAlertProps: (value: StateUpdater<LeftPanelAlertDialogProps>) => void,
};

export const EditorContext = createContext<IEditorContext>({
    ...getDummyApiEditorContextState(),
    ...getDummyGenerateToolEditorState(),
    ...getDummyRealTimeRenderEditorState(),
    ...getDummyMagicEraseEditorState(),
    ...getDummyMobileEditorState(),
    ...getDummyUpscaleV2EditorState(),
    ...getDummyColorCorrectV2EditorState(),
    ...getDummyRegenerateProductEditorState(),
    ...getDummyCustomModelEditorState(),
    eventEmitter: new EditorEventEmitter(),
    backend: undefined,
    featureFlagsController: undefined,
    featureFlagVIP: false,
    setFeatureFlagVIP: noop,
    featureFlags: undefined,
    setFeatureFlags: noop,
    analytics: getDummyAnalytics(),
    setAnalytics: noop,
    user: null,
    setUser: noop,
    publicUserId: null,
    setPublicUserId: noop,
    userQuotas: null,
    setUserQuotas: noop,
    userOnboardData: null,
    setUserOnboardData: noop,
    version: '0',
    setVersion: noop,
    dashboardType: 'projects',
    setDashboardType: noop,
    projectId: undefined,
    setProjectId: noop,
    projectType: undefined,
    setProjectType: noop,
    projectDisplayName: undefined,
    setProjectDisplayName: noop,
    isCreatingNewProject: false,
    setIsCreatingNewProject: noop,
    customModelId: undefined,
    setCustomModelId: noop,
    customModelWorkflow: CustomModelType.Custom,
    setCustomModelWorkflow: noop,
    customModelDataset: undefined,
    setCustomModelDataset: noop,
    customModelTrainings: {},
    setCustomModelTrainings: noop,
    customModelPredictions: {},
    setCustomModelPredictions: noop,
    pastGenerations: {},
    setPastGenerations: noop,
    isSelectingTag: false,
    setIsSelectingTag: noop,
    isLeftPanelOpen: true,
    setIsLeftPanelOpen: noop,
    activeLeftPanels: ['Assets'],
    setActiveLeftPanels: noop,
    skipActiveObjectLeftPanelUpdate: { current: false },
    isLeftPanelAlertOpen: false,
    setLeftPanelAlertOpen: noop,
    leftPanelAlertProps: {},
    setLeftPanelAlertProps: noop,
    zoomRatio: 1,
    setZoomRatio: noop,
    activeObject: null,
    setActiveObject: noop,
    objectsInsideGenerationFrame: [],
    setObjectsInsideGenerationFrame: noop,
    contextMenuRequest: null,
    setContextMenuRequest: noop,
    frame: undefined,
    setFrame: noop,
    editor: null,
    setEditor: noop,
    activeInpaintMode: null,
    setActiveInpaintMode: noop,
    activeInpaintBrush: null,
    setActiveInpaintBrush: noop,
    inpaintBrushSize: 50,
    setInpaintBrushSize: noop,
    inpaintBrushColor: GENERATION_FRAME_BOTTOM_FILL_DARK,
    setInpaintBrushColor: noop,
    isInpaintPointerDown: false,
    setIsInpaintPointerDown: noop,
    // Elements
    activeElementType: '',
    setActiveElementType: noop,
    // Assets
    userImageAssetGeneratorRef: { current: undefined },
    setUserImageAssetGeneratorRef: noop,
    userImageAssetInfoCollection: {},
    setUserImageAssetInfoCollection: noop,
    // TryOn
    tryOnModelPrompt: "",
    setTryOnModelPrompt: noop,
    tryOnClothPrompt: "",
    setTryOnClothPrompt: noop,
    tryOnBackgroundPrompt: "",
    setTryOnBackgroundPrompt: noop,
    tryOnClothImageElement: undefined,
    setTryOnClothImageElement: noop,
    tryOnWarpedClothImageElement: undefined,
    setTryOnWarpedClothImageElement: noop,
    tryOnWarpedHumanMaskImageElement: undefined,
    setTryOnWarpedHumanMaskImageElement: noop,
    tryOnPersonImageElement: undefined,
    setTryOnPersonImageElement: noop,
    tryOnParsedClothUpdated: false,
    setTryOnParsedClothUpdated: noop,
    tryOnParsedClothImageElement: undefined,
    setTryOnParsedClothImageElement: noop,
    tryOnParsedClothImageBbox: undefined,
    setTryOnParsedClothImageBbox: noop,
    tryOnRenderResults: [],
    setTryOnRenderResults: noop,
    tryOnModelId: undefined,
    setTryOnModelId: noop,
    tryOnEditorState: 'idle',
    setTryOnEditorState: noop,
    tryOnClothPromptState: 'idle',
    setTryOnClothPromptState: noop,
    tryOnRenderProgress: 0,
    setTryOnRenderProgress: noop,
    tryOnClothMaskBrushSize: 0,
    setTryOnClothMaskBrushSize: noop,
    tryOnClothMaskPaintState: 'idle',
    setTryOnClothMaskPaintState: noop,
    tryOnActiveClothMaskType: 'empty',
    setTryOnActiveClothMaskType: noop,
    tryOnPersonPaintState: 'idle',
    setTryOnPersonPaintState: noop,
    tryOnPersonBrushSize: 0,
    setTryOnPersonBrushSize: noop,
    tryOnEditorController: undefined,
    setTryOnEditorController: noop,
    tryOnModelPreviewGenerator: undefined,
    setTryOnModelPreviewGenerator: noop,
    tryOnModelPreviewFilterContraints: cloneDeep(defaultTryOnModelPreviewFilterQueryConstraints),
    setTryOnModelPreviewFilterContraints: noop,
    // Edit Image Object
    editingObjectId: undefined,
    setEditingObjectId: noop,
    // Replace Product
    replaceProductInputImagePath: undefined,
    replaceProductCanvasState: undefined,
    setReplaceProductInputImagePath: noop,
    setReplaceProductCanvasState: noop,
});

function getUpdaterFunction<K extends keyof IEditorContext>(
    set: (state: StateUpdater<Partial<IEditorContext>>) => void,
    key: K,
) {
    return (updater: StateUpdater<IEditorContext[K]>) => typeof (updater) === 'function' ? set(state => ({ [key]: (updater as Function)?.((state as any)[key]) })) : set({ [key]: updater });
}

function getUpdaterFunctionWithCleanup<K extends keyof IEditorContext>(
    set: (state: StateUpdater<Partial<IEditorContext>>) => void,
    key: K,
    cleanup: (object: IEditorContext[K]) => void,
) {
    return (updater: StateUpdater<IEditorContext[K]>) => {
        set(state => {
            const prevValue = state[key] as IEditorContext[K];

            cleanup(prevValue);

            return typeof (updater) === 'function' ?
                { [key]: (updater as Function)?.(prevValue) } :
                { [key]: updater }
        });
    };
}

function getUpdaterFunctionWithUpdateCheck<K extends keyof IEditorContext>(
    set: (state: StateUpdater<Partial<IEditorContext>>) => void,
    key: K,
) {
    return (updater: StateUpdater<IEditorContext[K]>) => {
        set(state => {
            const prevValue = state[key] as IEditorContext[K];

            const editor = state.editor;
            if (editor) {
                const canUpdate = editor.canUpdateState(key, updater);
                if (!canUpdate) {
                    return state;
                }
            }


            return typeof (updater) === 'function' ?
                { [key]: (updater as Function)?.(prevValue) } :
                { [key]: updater }
        });
    };
}

function cleanupHtmlElementCallback(object?: HTMLElement) {
    return object?.remove();
}

function cleanupGenerateToolColorAttentionMaskMap(generateToolColorAttentionMaskMap?: ColorAttentionMaskMap) {
    if (generateToolColorAttentionMaskMap) {
        const imageColorMap: Record<string, string> = {};
        Object.entries(generateToolColorAttentionMaskMap).forEach(([color, { images }]) => {
            getUniqueArray(images).forEach((id) => {
                imageColorMap[id] = color;
            });
        });
        Object.values(generateToolColorAttentionMaskMap).forEach((mask) => {
            mask.images.length = 0;
        });
        Object.entries(imageColorMap).forEach(([id, color]) => {
            generateToolColorAttentionMaskMap[color]?.images.push(id);
        });
    }
    return generateToolColorAttentionMaskMap;
}

function updateGenerateToolColorAttentionMaskMap(set: (state: StateUpdater<Partial<IEditorContext>>) => void) {
    return (updater: StateUpdater<ColorAttentionMaskMap>) => {
        if (typeof (updater) === 'function') {
            set(state => {
                const newAttentionMaskMap = updater(state.generateToolColorAttentionMaskMap || {});
                return {
                    generateToolColorAttentionMaskMap: cleanupGenerateToolColorAttentionMaskMap(newAttentionMaskMap),
                }
            });
        } else {
            set(() => ({ generateToolColorAttentionMaskMap: cleanupGenerateToolColorAttentionMaskMap(updater) }));
        }
    }
}

function createEditorContextVanillaStore() {
    return createVanilla(subscribeWithSelector<IEditorContext>((set) => ({
        ...getDefaultApiEditorContextState(set),
        ...getDefaultGenerateToolEditorState(set),
        ...getDefaultRealTimeRenderEditorState(set),
        ...getDefaultMagicEraseEditorState(set),
        ...getDefaultMobileEditorState(set),
        ...getDefaultUpscaleV2EditorState(set),
        ...getDefaultColorCorrectV2EditorState(set),
        ...getDefaultRegenerateProductEditorState(set),
        ...getDefaultCustomModelEditorState(set),
        eventEmitter: new EditorEventEmitter(),
        isMobile: isMobile({
            tablet: true,
            featureDetect: true,
        }),
        analytics: createAnalytics(),
        setAnalytics: getUpdaterFunction(set, 'analytics'),
        user: null,
        setUser: (user) => set({ user }),
        publicUserId: null,
        setPublicUserId: getUpdaterFunction(set, 'publicUserId'),
        userQuotas: null,
        setUserQuotas: getUpdaterFunction(set, 'userQuotas'),
        userOnboardData: null,
        setUserOnboardData: getUpdaterFunction(set, 'userOnboardData'),
        version: '0',
        setVersion: getUpdaterFunction(set, 'version'),
        dashboardType: 'projects',
        setDashboardType: getUpdaterFunction(set, 'dashboardType'),
        pastGenerations: {},
        setPastGenerations: getUpdaterFunction(set, 'pastGenerations'),
        isSelectingTag: false,
        setIsSelectingTag: getUpdaterFunction(set, 'isSelectingTag'),
        backend: createBackend(),
        featureFlagsController: new FeatureFlagController(),
        featureFlagVIP: false,
        setFeatureFlagVIP: getUpdaterFunction(set, 'featureFlagVIP'),
        featureFlags: undefined,
        setFeatureFlags: getUpdaterFunction(set, 'featureFlags'),
        projectId: undefined,
        setProjectId: getUpdaterFunction(set, 'projectId'),
        projectType: undefined,
        setProjectType: getUpdaterFunction(set, 'projectType'),
        projectDisplayName: '',
        setProjectDisplayName: getUpdaterFunction(set, 'projectDisplayName'),
        isCreatingNewProject: false,
        setIsCreatingNewProject: getUpdaterFunction(set, 'isCreatingNewProject'),
        customModelWorkflow: CustomModelType.Custom,
        setCustomModelWorkflow: getUpdaterFunction(set, 'customModelWorkflow'),
        customModelId: undefined,
        setCustomModelId: getUpdaterFunction(set, 'customModelId'),
        customModelDataset: undefined,
        setCustomModelDataset: getUpdaterFunction(set, 'customModelDataset'),
        customModelTrainings: {},
        setCustomModelTrainings: getUpdaterFunction(set, 'customModelTrainings'),
        customModelPredictions: {},
        setCustomModelPredictions: getUpdaterFunction(set, 'customModelPredictions'),
        zoomRatio: 0,
        setZoomRatio: (zoomRatio) => set({ zoomRatio }),
        activeObject: null,
        setActiveObject: (activeObject) => set({ activeObject }),
        objectsInsideGenerationFrame: [],
        setObjectsInsideGenerationFrame: getUpdaterFunction(set, 'objectsInsideGenerationFrame'),
        frame: undefined,
        setFrame: (frame) => set({ frame }),
        editor: null,
        setEditor: (editor) => set({ editor }),
        contextMenuRequest: null,
        setContextMenuRequest: (contextMenuRequest) => set({ contextMenuRequest }),
        isLeftPanelOpen: true,
        setIsLeftPanelOpen: (isLeftPanelOpen) => set({ isLeftPanelOpen }),
        activeLeftPanels: ['Assets'],
        setActiveLeftPanels: getUpdaterFunctionWithUpdateCheck(set, 'activeLeftPanels'),
        skipActiveObjectLeftPanelUpdate: { current: false },
        isLeftPanelAlertOpen: false,
        setLeftPanelAlertOpen: getUpdaterFunction(set, 'isLeftPanelAlertOpen'),
        leftPanelAlertProps: {},
        setLeftPanelAlertProps: getUpdaterFunction(set, 'leftPanelAlertProps'),
        activeInpaintMode: null,
        setActiveInpaintMode: getUpdaterFunction(set, 'activeInpaintMode'),
        activeInpaintBrush: null,
        setActiveInpaintBrush: getUpdaterFunction(set, 'activeInpaintBrush'),
        inpaintBrushSize: 50,
        setInpaintBrushSize: getUpdaterFunction(set, 'inpaintBrushSize'),
        inpaintBrushColor: GENERATION_FRAME_BOTTOM_FILL_DARK,
        setInpaintBrushColor: getUpdaterFunction(set, 'inpaintBrushColor'),
        isInpaintPointerDown: false,
        setIsInpaintPointerDown: getUpdaterFunction(set, 'isInpaintPointerDown'),
        // Elements
        activeElementType: '',
        setActiveElementType: getUpdaterFunction(set, 'activeElementType'),
        // Assets
        userImageAssetGeneratorRef: { current: undefined },
        setUserImageAssetGeneratorRef: getUpdaterFunction(set, 'userImageAssetGeneratorRef'),
        userImageAssetInfoCollection: {},
        setUserImageAssetInfoCollection: getUpdaterFunction(set, 'userImageAssetInfoCollection'),
        // TryOn
        tryOnModelPrompt: "a woman",
        setTryOnModelPrompt: getUpdaterFunction(set, "tryOnModelPrompt"),
        tryOnClothPrompt: "",
        setTryOnClothPrompt: getUpdaterFunction(set, "tryOnClothPrompt"),
        tryOnBackgroundPrompt: "in front of a city background",
        setTryOnBackgroundPrompt: getUpdaterFunction(set, "tryOnBackgroundPrompt"),
        tryOnClothImageElement: undefined,
        tryOnWarpedClothImageElement: undefined,
        tryOnWarpedHumanMaskImageElement: undefined,
        tryOnPersonImageElement: undefined,
        tryOnParsedClothImageElement: undefined,
        tryOnParsedClothImageBbox: undefined,
        tryOnRenderResults: [],
        tryOnModelId: undefined,
        tryOnRenderProgress: 0,
        tryOnParsedClothUpdated: false,
        setTryOnParsedClothUpdated: getUpdaterFunction(set, "tryOnParsedClothUpdated"),
        setTryOnClothImageElement: getUpdaterFunctionWithCleanup(set, "tryOnClothImageElement", cleanupHtmlElementCallback),
        setTryOnWarpedClothImageElement: getUpdaterFunctionWithCleanup(set, "tryOnWarpedClothImageElement", cleanupHtmlElementCallback),
        setTryOnWarpedHumanMaskImageElement: getUpdaterFunctionWithCleanup(set, 'tryOnWarpedHumanMaskImageElement', cleanupHtmlElementCallback),
        setTryOnPersonImageElement: getUpdaterFunctionWithCleanup(set, "tryOnPersonImageElement", cleanupHtmlElementCallback),
        setTryOnParsedClothImageElement: getUpdaterFunctionWithCleanup(set, "tryOnParsedClothImageElement", cleanupHtmlElementCallback),
        setTryOnParsedClothImageBbox: getUpdaterFunction(set, "tryOnParsedClothImageBbox"),
        setTryOnRenderResults: getUpdaterFunction(set, "tryOnRenderResults"),
        setTryOnModelId: getUpdaterFunction(set, 'tryOnModelId'),
        tryOnEditorState: 'idle',
        setTryOnEditorState: getUpdaterFunction(set, 'tryOnEditorState'),
        tryOnClothPromptState: 'idle',
        setTryOnClothPromptState: getUpdaterFunction(set, 'tryOnClothPromptState'),
        setTryOnRenderProgress: getUpdaterFunction(set, 'tryOnRenderProgress'),
        tryOnClothMaskBrushSize: 50,
        setTryOnClothMaskBrushSize: getUpdaterFunction(set, 'tryOnClothMaskBrushSize'),
        tryOnClothMaskPaintState: 'idle',
        setTryOnClothMaskPaintState: getUpdaterFunction(set, 'tryOnClothMaskPaintState'),
        tryOnActiveClothMaskType: 'empty',
        setTryOnActiveClothMaskType: getUpdaterFunction(set, 'tryOnActiveClothMaskType'),
        tryOnPersonPaintState: 'idle',
        setTryOnPersonPaintState: getUpdaterFunction(set, 'tryOnPersonPaintState'),
        tryOnPersonBrushSize: 50,
        setTryOnPersonBrushSize: getUpdaterFunction(set, 'tryOnPersonBrushSize'),
        tryOnEditorController: undefined,
        setTryOnEditorController: getUpdaterFunction(set, 'tryOnEditorController'),
        tryOnModelPreviewGenerator: undefined,
        setTryOnModelPreviewGenerator: getUpdaterFunctionWithCleanup(
            set,
            'tryOnModelPreviewGenerator',
            (generator) => generator?.reset(),
        ),
        tryOnModelPreviewFilterContraints: cloneDeep(defaultTryOnModelPreviewFilterQueryConstraints),
        setTryOnModelPreviewFilterContraints: getUpdaterFunction(set, "tryOnModelPreviewFilterContraints"),
        // Regenerate Product
        editingObjectId: undefined,
        setEditingObjectId: getUpdaterFunction(set, 'editingObjectId'),
        regenerateProductRenderState: 'idle',
        setRegenerateProductRenderState: getUpdaterFunction(set, 'regenerateProductRenderState'),
        regenerateProductCorrectColor: true,
        setRegenerateProductCorrectColor: getUpdaterFunction(set, 'regenerateProductCorrectColor'),
        regenerateProductColorStrength: GenerateStrength.Default,
        setRegenerateProductColorStrength: getUpdaterFunction(set, 'regenerateProductColorStrength'),
        regenerateProductPromptTemplate: cloneDeep(emptyGenerateTemplate.prompt),
        setRegenerateProductPromptTemplate: getUpdaterFunction(set, 'regenerateProductPromptTemplate'),
        regenerateProductInputImagePath: undefined,
        setRegenerateProductInputImagePath: getUpdaterFunction(set, 'regenerateProductInputImagePath'),
        regenerateProductResults: [],
        setRegenerateProductResults: getUpdaterFunction(set, 'regenerateProductResults'),
        regenerateProductNumImages: 4,
        setRegenerateProductNumImages: getUpdaterFunction(set, 'regenerateProductNumImages'),
        regenerateProductErasedImagePath: undefined,
        setRegenerateProductErasedImagePath: getUpdaterFunction(set, 'regenerateProductErasedImagePath'),
        regenerateProductEraseMaskImagePath: undefined,
        setRegenerateProductEraseMaskImagePath: getUpdaterFunction(set, 'regenerateProductEraseMaskImagePath'),
        regenerateRenderProcessController: undefined,
        setRegenerateRenderProcessController: getUpdaterFunctionWithCleanup(set, 'regenerateRenderProcessController', (controller) => controller?.destroy()),
        regenerateProductReferenceImagePath: undefined,
        setRegenerateProductReferenceImagePath: getUpdaterFunction(set, 'regenerateProductReferenceImagePath'),
        // Replace Product
        replaceProductPromptTemplate: cloneDeep(emptyGenerateTemplate.prompt),
        replaceProductInputImagePath: undefined,
        replaceProductCanvasState: undefined,
        setReplaceProductInputImagePath: getUpdaterFunction(set, 'replaceProductInputImagePath'),
        setReplaceProductCanvasState: getUpdaterFunction(set, 'replaceProductCanvasState'),
    })));
}

export const editorContextVanillaStore = createEditorContextVanillaStore();

export const editorContextStore = create(editorContextVanillaStore);

export function initEditorContextVanillaStore() {
    if (editorContextVanillaStore) {
    }
}


export function resetEditorContextState(editorContextState: IEditorContext) {
    editorContextState.setIsSelectingTag(false);
    editorContextState.setProjectId(undefined);
    editorContextState.setProjectType(undefined);
    editorContextState.setProjectDisplayName('');
    editorContextState.setIsCreatingNewProject(false);
    editorContextState.setActiveObject(null);
    editorContextState.setObjectsInsideGenerationFrame([]);


    editorContextState.setCustomModelId(undefined);
    editorContextState.setCustomModelDataset(undefined);
    editorContextState.setCustomModelPredictions({});
    editorContextState.setCustomModelTrainings({});

    editorContextState.setActiveLeftPanels(['Assets']);
    editorContextState.setLeftPanelAlertProps({});
    editorContextState.setActiveInpaintMode(null);
    editorContextState.setActiveInpaintBrush(null);
    editorContextState.setInpaintBrushSize(50);
    editorContextState.setInpaintBrushColor(GENERATION_FRAME_BOTTOM_FILL_DARK);
    editorContextState.setIsInpaintPointerDown(false);
    editorContextState.setActiveElementType('');
    editorContextState.setUserImageAssetGeneratorRef({ current: undefined });
    editorContextState.setUserImageAssetInfoCollection({});
    editorContextState.setTryOnModelPrompt("");
    editorContextState.setTryOnClothPrompt("");
    editorContextState.setTryOnBackgroundPrompt("");
    editorContextState.setTryOnParsedClothUpdated(false);
    editorContextState.setTryOnClothImageElement(undefined);
    editorContextState.setTryOnWarpedClothImageElement(undefined);
    editorContextState.setTryOnWarpedHumanMaskImageElement(undefined);
    editorContextState.setTryOnPersonImageElement(undefined);
    editorContextState.setTryOnParsedClothImageElement(undefined);
    editorContextState.setTryOnParsedClothImageBbox(undefined);
    editorContextState.setTryOnRenderResults([]);
    editorContextState.setTryOnModelId(undefined);
    editorContextState.setTryOnEditorState('idle');
    editorContextState.setTryOnClothPromptState('idle');
    editorContextState.setTryOnRenderProgress(0);
    editorContextState.setTryOnClothMaskBrushSize(50);
    editorContextState.setTryOnClothMaskPaintState('idle');
    editorContextState.setTryOnActiveClothMaskType('empty');
    editorContextState.setTryOnPersonPaintState('idle');
    editorContextState.setTryOnPersonBrushSize(50);

    editorContextState.setTryOnModelPreviewGenerator(undefined);
    editorContextState.setTryOnModelPreviewFilterContraints(defaultTryOnModelPreviewFilterQueryConstraints);
    editorContextState.setEditingObjectId(undefined);

    resetRegenerateProductEditorState(editorContextState);

    editorContextState.setReplaceProductInputImagePath(undefined);
    editorContextState.setReplaceProductCanvasState(undefined);

    resetRealtimeRenderEditorState();

    resetUpscaleV2EditorState(editorContextState);

    resetColorCorrectV2EditorState(editorContextState);

    resetCustomModelEditorState(editorContextState);
}