import { ImageInputProps } from "./image-input";
import { OptionsInputProps } from "./options-input";
import { SliderInputProps } from "./slider-input";
import { NumberInputProps, TextInputProps } from "./text-input";
import { ApiStateOfApiConfig } from "./api-input";
import { ApiInputType, ApiPipelineType } from "core/common/types/api";
import { cloneDeep, noop } from "lodash";

export const GENERATE_IMAGE_API_ENDPOINT = "https://api.flair.ai/generate-image/v1"

export enum ApiLanguage {
    Bash = 'bash',
    Javascript = 'javascript',
    JavascriptNodeJs = 'javascript-nodejs',
    Typescript = 'typescript',
    Python = 'python',
}

export type DefaultGenerateImageApiConfig = {
    pipelineType: OptionsInputProps,
    prompt: TextInputProps,
    negativePrompt: TextInputProps,
    width: SliderInputProps,
    height: SliderInputProps,
    numInferenceSteps: SliderInputProps,
    guidanceScale: SliderInputProps,
    seed: NumberInputProps,
}

export type CannyGenerateImageApiConfig = {
    pipelineType: OptionsInputProps,
    prompt: TextInputProps,
    negativePrompt: TextInputProps,
    width: SliderInputProps,
    height: SliderInputProps,
    numInferenceSteps: SliderInputProps,
    guidanceScale: SliderInputProps,
    seed: NumberInputProps,
    canny_base_image: ImageInputProps,
    canny_controlnet_conditioning_scale: SliderInputProps,
}

export type ProductPlacementGenerateImageApiConfig = {
    pipelineType: OptionsInputProps,
    prompt: TextInputProps,
    negativePrompt: TextInputProps,
    width: SliderInputProps,
    height: SliderInputProps,
    numInferenceSteps: SliderInputProps,
    guidanceScale: SliderInputProps,
    seed: NumberInputProps,
    foreground_image: ImageInputProps,
    foreground_mask_image: ImageInputProps,
    ref_image: ImageInputProps
    ref_image_scale_start: SliderInputProps
    ref_image_scale_finish: SliderInputProps
}

export type GenerateImageApiConfig = DefaultGenerateImageApiConfig | CannyGenerateImageApiConfig | ProductPlacementGenerateImageApiConfig;

export type DefaultGenerateImageApiState = ApiStateOfApiConfig<DefaultGenerateImageApiConfig>;
export type CannyGenerateImageApiState = ApiStateOfApiConfig<CannyGenerateImageApiConfig>;
export type ProductPlacementGenerateImageApiState = ApiStateOfApiConfig<ProductPlacementGenerateImageApiConfig>;
export type GenerateImageApiState = DefaultGenerateImageApiState | CannyGenerateImageApiState | ProductPlacementGenerateImageApiState;

export function isDefaultGenerateImageApiState(apiState: GenerateImageApiState): apiState is DefaultGenerateImageApiState {
    return apiState &&
        apiState.pipelineType === ApiPipelineType.Default;
}

export function isProductPlacementGenerateImageApiState(apiState: GenerateImageApiState): apiState is ProductPlacementGenerateImageApiState {
    return apiState &&
        apiState.pipelineType === ApiPipelineType.ProductPlacement;
}

export function isCannyGenerateImageApiState(apiState: GenerateImageApiState): apiState is CannyGenerateImageApiState {
    return apiState &&
        apiState.pipelineType === ApiPipelineType.Canny;
}

function parseNonNegativeIntegerString(value: string) {
    try {
        return Math.max(parseInt(value), -1);
    } catch (error) {
        return -1;
    }
}

export const defaultGenerateImageApiConfig: DefaultGenerateImageApiConfig = {
    pipelineType: {
        type: ApiInputType.Options,
        id: 'pipelineType',
        name: 'Pipeline Type',
        value: ApiPipelineType.Default,
        options: {
            'Pipelines': Object.values(ApiPipelineType).map((type) => ({
                name: type,
                value: type,
            }))
        },
        onValueChange: noop,
        required: true,
    },
    prompt: {
        type: ApiInputType.Text,
        id: 'prompt',
        name: 'Prompt',
        value: 'photography of a dropper bottle on a concrete platform with flower petals, highly detailed, best quality, editorial, award winning, minimalistic aesthetic',
        onValueChange: noop,
        required: true,
    },
    negativePrompt: {
        type: ApiInputType.Text,
        id: 'negativePrompt',
        name: 'Negative prompt',
        value: 'blurry, cropped, ugly, chaotic, random, colorful, cluttered, distorted, drawing, painting, graphic design, 3d rendering, fake, plastic',
        onValueChange: noop,
    },
    width: {
        type: ApiInputType.Slider,
        id: 'width',
        name: 'Width',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    height: {
        type: ApiInputType.Slider,
        id: 'height',
        name: 'Height',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    numInferenceSteps: {
        type: ApiInputType.Slider,
        id: 'numInferenceSteps',
        name: 'Number of inference steps',
        value: 25,
        defaultValue: 25,
        min: 1,
        max: 50,
        step: 1,
        onValueChange: noop,
    },
    guidanceScale: {
        type: ApiInputType.Slider,
        id: 'guidanceScale',
        name: "Guidance scale",
        value: 7.5,
        defaultValue: 7.5,
        min: 0,
        max: 20,
        step: 0.5,
        onValueChange: noop,
    },
    seed: {
        type: ApiInputType.Number,
        id: 'seed',
        name: 'Seed',
        value: 42,
        onValueChange: noop,
        parseStringValue: parseNonNegativeIntegerString,
    },
}

export const cannyGenerateImageApiConfig: CannyGenerateImageApiConfig = {
    pipelineType: {
        type: ApiInputType.Options,
        id: 'pipelineType',
        name: 'Pipeline Type',
        value: ApiPipelineType.Default,
        options: {
            'Pipelines': Object.values(ApiPipelineType).map((type) => ({
                name: type,
                value: type,
            }))
        },
        onValueChange: noop,
        required: true,
    },
    prompt: {
        type: ApiInputType.Text,
        id: 'prompt',
        name: 'Prompt',
        value: 'photography of a white skincare bottle on a platform with flower petals in soft shadows, highly detailed, best quality, award winning',
        onValueChange: noop,
        required: true,
    },
    negativePrompt: {
        type: ApiInputType.Text,
        id: 'negativePrompt',
        name: 'Negative prompt',
        value: 'blurry, cropped, ugly, chaotic, random, colorful, cluttered, distorted, drawing, painting, graphic design, 3d rendering, fake, plastic',
        onValueChange: noop,
    },
    canny_base_image: {
        type: ApiInputType.Image,
        id: 'canny_base_image',
        name: "Outline image",
        value: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/156af620-362a-4eb1-f27e-18b7496a3d00/public",
        onValueChange: noop,
        required: true,
    },
    canny_controlnet_conditioning_scale: {
        type: ApiInputType.Slider,
        id: 'canny_controlnet_conditioning_scale',
        name: "Outline control scale",
        value: 0.8,
        defaultValue: 0.8,
        min: 0,
        max: 1.0,
        step: 0.1,
        onValueChange: noop,
    },
    width: {
        type: ApiInputType.Slider,
        id: 'width',
        name: 'Width',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    height: {
        type: ApiInputType.Slider,
        id: 'height',
        name: 'Height',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    numInferenceSteps: {
        type: ApiInputType.Slider,
        id: 'numInferenceSteps',
        name: 'Number of inference steps',
        value: 25,
        defaultValue: 25,
        min: 1,
        max: 50,
        step: 1,
        onValueChange: noop,
    },
    guidanceScale: {
        type: ApiInputType.Slider,
        id: 'guidanceScale',
        name: "Guidance scale",
        value: 7.5,
        defaultValue: 7.5,
        min: 0,
        max: 20,
        step: 0.5,
        onValueChange: noop,
    },
    seed: {
        type: ApiInputType.Number,
        id: 'seed',
        name: 'Seed',
        value: 42,
        onValueChange: noop,
        parseStringValue: parseNonNegativeIntegerString,
    },
}

export const productPlacementGenerateImageApiConfig: ProductPlacementGenerateImageApiConfig = {
    pipelineType: {
        type: ApiInputType.Options,
        id: 'pipelineType',
        name: 'Pipeline Type',
        value: ApiPipelineType.ProductPlacement,
        options: {
            'Pipelines': Object.values(ApiPipelineType).map((type) => ({
                name: type,
                value: type,
            }))
        },
        onValueChange: noop,
        required: true,
    },
    prompt: {
        type: ApiInputType.Text,
        id: 'prompt',
        name: 'Prompt',
        value: 'photography of a white skincare bottle on a concrete platform with flower petals, highly detailed, best quality, editorial, award winning, minimalistic aesthetic',
        onValueChange: noop,
        required: true,
    },
    negativePrompt: {
        type: ApiInputType.Text,
        id: 'negativePrompt',
        name: 'Negative prompt',
        value: 'blurry, cropped, ugly, chaotic, random, colorful, cluttered, distorted, drawing, painting, graphic design, 3d rendering, fake, plastic',
        onValueChange: noop,
    },
    foreground_image: {
        type: ApiInputType.Image,
        id: 'foreground_image',
        name: "Foreground image",
        value: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f71d59a8-5944-4769-e4ad-54795fb2ec00/public",
        onValueChange: noop,
        required: true,
    },
    foreground_mask_image: {
        type: ApiInputType.Image,
        id: 'foreground_mask_image',
        name: "Foreground mask image",
        value: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/68d0fa24-38bb-4508-df49-115afd867500/public",
        onValueChange: noop,
    },
    ref_image: {
        type: ApiInputType.Image,
        id: 'ref_image',
        name: "Reference image",
        value: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/249aa7d9-87b0-4b51-aa2d-a014fe0ea000/public",
        onValueChange: noop,
    },
    ref_image_scale_start: {
        type: ApiInputType.Slider,
        id: 'ref_image_scale_start',
        name: "Reference image scale start",
        value: 0.5,
        defaultValue: 0.5,
        min: 0,
        max: 1.0,
        step: 0.1,
        onValueChange: noop,
    },
    ref_image_scale_finish: {
        type: ApiInputType.Slider,
        id: 'ref_image_scale_finish',
        name: "Reference image scale finish",
        value: 0.2,
        defaultValue: 0.2,
        min: 0,
        max: 1.0,
        step: 0.1,
        onValueChange: noop,
    },
    width: {
        type: ApiInputType.Slider,
        id: 'width',
        name: 'Width',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    height: {
        type: ApiInputType.Slider,
        id: 'height',
        name: 'Height',
        value: 1024,
        defaultValue: 1024,
        min: 512,
        max: 1536,
        step: 128,
        onValueChange: noop,
    },
    numInferenceSteps: {
        type: ApiInputType.Slider,
        id: 'numInferenceSteps',
        name: 'Number of inference steps',
        value: 25,
        defaultValue: 25,
        min: 1,
        max: 50,
        step: 1,
        onValueChange: noop,
    },
    guidanceScale: {
        type: ApiInputType.Slider,
        id: 'guidanceScale',
        name: "Guidance scale",
        value: 7.5,
        defaultValue: 7.5,
        min: 0,
        max: 20,
        step: 0.5,
        onValueChange: noop,
    },
    seed: {
        type: ApiInputType.Number,
        id: 'seed',
        name: 'Seed',
        value: 1024,
        onValueChange: noop,
        parseStringValue: parseNonNegativeIntegerString,
    },
}

export function getGenerateImageApiConfigFromApiPipelineType(pipelineType?: ApiPipelineType) {
    if (pipelineType === ApiPipelineType.ProductPlacement) {
        return cloneDeep(productPlacementGenerateImageApiConfig);
    } else if (pipelineType === ApiPipelineType.Canny) {
        return cloneDeep(cannyGenerateImageApiConfig);
    }
    return cloneDeep(defaultGenerateImageApiConfig);
}


function getJavascriptCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
async function getDataUrlFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const canny_image_url = "https://your-outline-image.png"; // Set your outline image URL
    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipelineType}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negativePrompt}",
            guidance_scale: ${apiState.guidanceScale},
            num_inference_steps: ${apiState.numInferenceSteps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed}
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getJavascriptCodeProductPlacement(apiState: ProductPlacementGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
async function getDataUrlFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://your-reference-image.png"; // Set your reference image URL
    const foreground_image_url = "https://foreground-image.png"; // Set your foreground image URL
    const foreground_mask_image_url = "https://foreground-mask-image.png"; // Set your foreground mask image URL

    const ref_image = await getDataUrlFromUrl(ref_image_url);
    const foreground_image = await getDataUrlFromUrl(foreground_image_url);
    const foreground_mask_image = await getDataUrlFromUrl(foreground_mask_image_url);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            },
            body: JSON.stringify({
                pipeline_type: "${apiState.pipelineType}",
                prompt: "${apiState.prompt}",
                negative_prompt: "${apiState.negativePrompt}",
                guidance_scale: ${apiState.guidanceScale},
                num_inference_steps: ${apiState.numInferenceSteps},
                width: ${apiState.width},
                height: ${apiState.height},
                seed: ${apiState.seed},
                ref_image: ref_image,
                ref_image_scale_start: ${apiState.ref_image_scale_start},
                ref_image_scale_finish: ${apiState.ref_image_scale_finish},
                foreground_image: foreground_image,
                foreground_mask_image: foreground_mask_image
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;

}

function getTypescriptCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
async function getDataUrlFromUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const canny_image_url = "https://your-outline-image.png"; // Set your reference image URL

    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipelineType}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negativePrompt}",
            guidance_scale: ${apiState.guidanceScale},
            num_inference_steps: ${apiState.numInferenceSteps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed}
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getTypescriptCodeProductPlacement(apiState: ProductPlacementGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
async function getDataUrlFromUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://reference-image.png";
    const foreground_image_url = "https://foreground-image.png";
    const foreground_mask_image_url = "https://foreground-mask-image.png";

    const ref_image = await getDataUrlFromUrl(ref_image_url);
    const foreground_image = await getDataUrlFromUrl(foreground_image_url);
    const foreground_mask_image = await getDataUrlFromUrl(foreground_mask_image_url);

    try {
        const response = await fetch(url, {
            method: 'POST',[']
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            },
            body: JSON.stringify({
                pipeline_type: "${apiState.pipelineType}",
                prompt: "${apiState.prompt}",
                negative_prompt: "${apiState.negativePrompt}",
                guidance_scale: ${apiState.guidanceScale},
                num_inference_steps: ${apiState.numInferenceSteps},
                width: ${apiState.width},
                height: ${apiState.height},
                seed: ${apiState.seed},
                ref_image: ref_image,
                ref_image_scale_start: ${apiState.ref_image_scale_start},
                ref_image_scale_finish: ${apiState.ref_image_scale_finish},
                foreground_image: foreground_image,
                foreground_mask_image: foreground_mask_image
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}


function getJavascriptCodeDefault(apiState: GenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipelineType}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negativePrompt}",
            guidance_scale: ${apiState.guidanceScale},
            num_inference_steps: ${apiState.numInferenceSteps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed}
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

const images = await callApi();`;
}


function getNodeJsCodeDefault(apiState: GenerateImageApiState, apiKey = "YOUR API KEY"): string {
    return `\
const axios = require('axios');

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const response = await axios.post(url, {
            pipeline_type: "${apiState.pipelineType}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negativePrompt}",
            guidance_scale: ${apiState.guidanceScale},
            num_inference_steps: ${apiState.numInferenceSteps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
        }, {
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            }
        });

        if (response.status === 200) {
            console.log(response.data.images);
        } else {
            console.error('Error:', response.status, response.data);
        }
    } catch (error) {
        console.error('Error:', error.response || error.request || error.message);
    }
}

callApi();`;
}

function getNodeJsCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
const axios = require('axios');
const fs = require('fs').promises;

async function getImageDataUri(filePath) {
    try {
        const fileBuffer = await fs.readFile(filePath);
        const base64 = fileBuffer.toString('base64');
        const mimeType = 'image/png'; // This is assuming PNG images. Change mimeType according to your image files.
        return 'data:' + mimeType + ';base64,' + base64;
    } catch (error) {
        console.error('Error reading file:', error);
        throw error;
    }
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const cannyImage = await getImageDataUri("/path/to/outline-image.png");

        const response = await axios.post(url, {
        pipeline_type: "${apiState.pipelineType}",
        prompt: "${apiState.prompt}",
        negative_prompt: "${apiState.negativePrompt}",
        guidance_scale: ${apiState.guidanceScale},
        num_inference_steps: ${apiState.numInferenceSteps},
        width: ${apiState.width},
        height: ${apiState.height},
        seed: ${apiState.seed},
        canny_image: cannyImage,
        canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
        }, {
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        }
        });

        return response.data.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }
}

// Example usage:
(async () => {
    try {
        const images = await callApi();
        console.log(images);
    } catch (error) {
        console.error(error);
    }
})();`;
}

function getNodeJsCodeProductPlacement(apiState: ProductPlacementGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
const axios = require('axios');
const fs = require('fs').promises;

async function getImageDataUri(filePath) {
try {
    const fileBuffer = await fs.readFile(filePath);
    const base64 = fileBuffer.toString('base64');
    const mimeType = 'image/png'; // This is assuming PNG images. Change mimeType according to your image files.
    return 'data:' + mimeType + ';base64,' + base64;
} catch (error) {
    console.error('Error reading file:', error);
    throw error;
}
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const refImage = await getImageDataUri("/path/to/ref-image.png");
        const foregroundImage = await getImageDataUri("/path/to/foreground-imgae.png");
        const foregroundMaskImage = await getImageDataUri("/path/to/foreground-mask-image.png");

        const response = await axios.post(url, {
        pipeline_type: "${apiState.pipelineType}",
        prompt: "${apiState.prompt}",
        negative_prompt: "${apiState.negativePrompt}",
        guidance_scale: ${apiState.guidanceScale},
        num_inference_steps: ${apiState.numInferenceSteps},
        width: ${apiState.width},
        height: ${apiState.height},
        seed: ${apiState.seed},
        ref_image: refImage,
        ref_image_scale_start: ${apiState.ref_image_scale_start},
        ref_image_scale_finish: ${apiState.ref_image_scale_finish},
        foreground_image: foregroundImage,
        foreground_mask_image: foregroundMaskImage
        }, {
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        }
        });

        return response.data.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }
}

// Example usage:
(async () => {
    try {
        const images = await callApi();
        console.log(images);
    } catch (error) {
        console.error(error);
    }
})();`;
}

function getPythonCodeDefault(apiState: GenerateImageApiState, apiKey = "YOUR API KEY"): string {
    return `\
import requests
import json

api_key = "${apiKey}"
url = "${GENERATE_IMAGE_API_ENDPOINT}"

payload = {
    "pipeline_type": "${apiState.pipelineType}",
    "prompt": "${apiState.prompt}",
    "negative_prompt": "${apiState.negativePrompt}",
    "guidance_scale": ${apiState.guidanceScale},
    "num_inference_steps": ${apiState.numInferenceSteps},
    "width": ${apiState.width},
    "height": ${apiState.height},
    "seed": ${apiState.seed}
}

headers = {
    "Content-Type": "application/json",
    "API-Key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(payload))

if response.status_code == 200:
    images = response.json().get('images')
    print(images)
else:
    print("Error:", response.status_code, response.text)
`;

}

function getPythonCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
  import base64
  import requests

  def get_image_data_uri(file_path):
      with open(file_path, "rb") as image_file:
          encoded_string = base64.b64encode(image_file.read()).decode()
          return f"data:image/png;base64,{encoded_string}"

  def call_api():
      api_key = "${apiKey}"
      url = "${GENERATE_IMAGE_API_ENDPOINT}"

      canny_image_url = "/path/to/outline.png"
      canny_image = get_image_data_uri(canny_image_url)

      try:
          payload = {
              "pipeline_type": "${apiState.pipelineType}",
              "prompt": "${apiState.prompt}",
              "negative_prompt": "${apiState.negativePrompt}",
              "guidance_scale": ${apiState.guidanceScale},
              "num_inference_steps": ${apiState.numInferenceSteps},
              "width": ${apiState.width},
              "height": ${apiState.height},
              "seed": ${apiState.seed},
              "canny_base_image": canny_image,
              "canny_controlnet_conditioning_scale": ${apiState.canny_controlnet_conditioning_scale}
          }
          headers = {
              'Content-Type': 'application/json',
              'API-Key': api_key
          }

          response = requests.post(url, json=payload, headers=headers)
          response.raise_for_status()

          return response.json()["images"]
      except requests.exceptions.RequestException as e:
          print(f"There has been a problem with your fetch operation: {e}")

  # Example usage:
  if __name__ == "__main__":
      try:
          images = call_api()
          print(images)
      except Exception as error:
          print(error)
  `;

}

function getPythonCodeProductPlacement(apiState: ProductPlacementGenerateImageApiState, apiKey = "YOUR API KEY") {
    return `\
  import base64
  import requests

  def get_image_data_uri(file_path):
      with open(file_path, "rb") as image_file:
          encoded_string = base64.b64encode(image_file.read()).decode()
          return f"data:image/png;base64,{encoded_string}"

  def call_api():
      api_key = "${apiKey}"
      url = "${GENERATE_IMAGE_API_ENDPOINT}"

      try:
          ref_image = get_image_data_uri("/path/to/ref-image.png")
          foreground_image = get_image_data_uri("/path/to/foreground-image.png")
          foreground_mask_image = get_image_data_uri("/path/to/foreground-mask-image.png")

          payload = {
              "pipeline_type": "${apiState.pipelineType}",
              "prompt": "${apiState.prompt}",
              "negative_prompt": "${apiState.negativePrompt}",
              "guidance_scale": ${apiState.guidanceScale},
              "num_inference_steps": ${apiState.numInferenceSteps},
              "width": ${apiState.width},
              "height": ${apiState.height},
              "seed": ${apiState.seed},
              "ref_image": ref_image,
              "ref_image_scale_start": ${apiState.ref_image_scale_start},
              "ref_image_scale_finish": ${apiState.ref_image_scale_finish},
              "foreground_image": foreground_image,
              "foreground_mask_image": foreground_mask_image
          }
          headers = {
              'Content-Type': 'application/json',
              'API-Key': api_key
          }

          response = requests.post(url, json=payload, headers=headers)
          response.raise_for_status()  # Raises an HTTPError if the HTTP request returned an unsuccessful status code

          return response.json()["images"]
      except Exception as e:
          print(f"There has been a problem with your API call: {e}")

  # Example usage:
  if __name__ == "__main__":
      try:
          images = call_api()
          print(images)
      except Exception as error:
          print(error)
  `;

}

export type GetSampleApiRequestCodeProps = {
    language: ApiLanguage,
    apiState: GenerateImageApiState,
    apiKey?: string,
}

function getSampleApiRequestCodeDefault({
    language,
    apiState,
    apiKey,
}: GetSampleApiRequestCodeProps) {
    if (apiState.pipelineType !== ApiPipelineType.Default) {
        return "";
    }
    if (language === ApiLanguage.Javascript) {
        return getJavascriptCodeDefault(apiState, apiKey);
    } else if (language === ApiLanguage.Python) {
        return getPythonCodeDefault(apiState, apiKey);
    } else if (language === ApiLanguage.JavascriptNodeJs) {
        return getNodeJsCodeDefault(apiState, apiKey);
    }
    return getJavascriptCodeDefault(apiState, apiKey);
}

function getSampleApiRequestCodeProductPlacement({
    language,
    apiState,
    apiKey,
}: GetSampleApiRequestCodeProps) {
    if (!isProductPlacementGenerateImageApiState(apiState)) {
        return "";
    }
    if (language === ApiLanguage.Javascript) {
        return getJavascriptCodeProductPlacement(apiState, apiKey);
    } else if (language === ApiLanguage.Python) {
        return getPythonCodeProductPlacement(apiState, apiKey);
    } else if (language === ApiLanguage.JavascriptNodeJs) {
        return getNodeJsCodeProductPlacement(apiState, apiKey);
    } else if (language === ApiLanguage.Typescript) {
        return getTypescriptCodeProductPlacement(apiState, apiKey);
    }
    return getJavascriptCodeProductPlacement(apiState, apiKey);
}

function getSampleApiRequestCodeCanny({
    language,
    apiState,
    apiKey,
}: GetSampleApiRequestCodeProps) {
    if (!isCannyGenerateImageApiState(apiState)) {
        return "";
    }

    if (language === ApiLanguage.Javascript) {
        return getJavascriptCodeCanny(apiState, apiKey);
    } else if (language === ApiLanguage.Python) {
        return getPythonCodeCanny(apiState, apiKey);
    } else if (language === ApiLanguage.JavascriptNodeJs) {
        return getNodeJsCodeCanny(apiState, apiKey);
    } else if (language === ApiLanguage.Typescript) {
        return getTypescriptCodeCanny(apiState, apiKey);
    }
    return getJavascriptCodeCanny(apiState, apiKey);
}

export function getSampleApiRequestCode({
    apiState,
    ...props
}: GetSampleApiRequestCodeProps) {
    const { pipelineType } = apiState;

    if (pipelineType === ApiPipelineType.Default) {
        return getSampleApiRequestCodeDefault({
            apiState,
            ...props
        });
    } else if (pipelineType === ApiPipelineType.ProductPlacement) {
        return getSampleApiRequestCodeProductPlacement({
            apiState,
            ...props
        });
    } else if (pipelineType === ApiPipelineType.Canny) {
        return getSampleApiRequestCodeCanny({
            apiState,
            ...props
        });
    }

    return "";
}