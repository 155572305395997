import mime from 'mime';
import { EditorActiveObject } from 'core/common/interfaces';
import { displayUiMessage } from './display-message';
import { ImageFormat } from 'core/common/types/image';
import { getImageFormatFromImageArray } from 'core/utils/image-utils';
import { debugLog } from 'core/utils/print-utilts';


export function getFileExtensionFromDataUri(dataUri: string): string | null {
  // Extract the MIME type from the Data URI
  const mimeTypeMatch = dataUri.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (!mimeTypeMatch) {
    return null;
  }

  // Get the MIME type
  const mimeType = mimeTypeMatch[1];

  // Use the mime library to get the file extension
  const extension = mime.getExtension(mimeType);

  return extension;
}

export const toBase64 = (file: File | Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function downloadDataUrl(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();
}

export function downloadDataUrlNewTab(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = '_blank'; // Open in a new window or tab
  document.body.appendChild(link); // Append to the body to ensure visibility
  link.click();
  link.remove();
}

export function getStaticImageFileStem(image?: EditorActiveObject) {
  return image ? `image_${image.id.substring(0, 3)}` : '';
}

function isBlobWebp(blob: Blob): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer);
      // Check for WebP signature 'RIFF' followed by 'WEBP'
      const isWebp = arr[0] === 0x52 && arr[1] === 0x49 && arr[2] === 0x46 && arr[3] === 0x46 &&
        arr[8] === 0x57 && arr[9] === 0x45 && arr[10] === 0x42 && arr[11] === 0x50;
      resolve(isWebp);
    };
    reader.onerror = () => reject(new Error("Error reading blob"));
    reader.readAsArrayBuffer(blob.slice(0, 12)); // Read the first 12 bytes of the blob
  });
}

export function getImageFormatFromBlob(blob: Blob): Promise<ImageFormat | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer);
      resolve(getImageFormatFromImageArray(arr));
    };
    reader.onerror = () => reject(new Error("Error reading blob"));
    reader.readAsArrayBuffer(blob.slice(0, 12)); // Read the first 12 bytes of the blob
  });
}

export type DownloadImageBlobArgs = {
  blob: Blob,
  contentType?: string | null,
  filename: string,
  strictExtension?: string,
};

export async function downloadImageBlob({
  blob,
  contentType,
  filename,
  strictExtension,
}: DownloadImageBlobArgs) {
  if (contentType === 'image/jpeg') {
    contentType = await isBlobWebp(blob) ? 'image/webp' : contentType;
  }

  let extension: string | null = null;

  if (strictExtension) {
    extension = strictExtension.trim();
  } else {
    extension = contentType ? mime.getExtension(contentType) : null;
  }

  if (extension) {
    filename = `${filename}.${extension}`;
  }


  const dataUrl = URL.createObjectURL(blob);
  downloadDataUrl(dataUrl, filename);
  URL.revokeObjectURL(dataUrl);
}

export function downloadImageDataUrl(
  url: string | undefined,
  filename: string,
  strictExtension?: string,
): Promise<void> {
  if (!url) return Promise.resolve();

  return fetch(url)
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const contentType = response.headers.get('Content-Type');

      debugLog(`Data url content type: ${contentType}`);

      return {
        contentType,
        blob: await response.blob()
      }
    })
    .then(async ({
      blob,
      contentType,
    }) => {

      debugLog(`Image content type: ${contentType}`);

      await downloadImageBlob({
        blob,
        contentType,
        filename,
        strictExtension,
      });
    })
    .catch(error => {
      console.error('Error downloading image:', error);
      // Handle the error as per your application's needs
      displayUiMessage(`Cannot download image ${filename} from url ${url.slice(0, Math.min(url.length, 10))}`, 'error');
    });
}

export function getObjectUrlFromJsonString(data: string) {
  const blob = new Blob([data], { type: 'text/json' });
  return window.URL.createObjectURL(blob);
}

export function downloadJson(data: string, filename: string) {
  return downloadDataUrl(
    getObjectUrlFromJsonString(data),
    filename,
  );
}

export function isImagePngDataUrl(url: string) {
  return url?.startsWith('data:image/png;base64');
}

