import type { CustomModelDataset, CustomModelEditorState, CustomModelPredictionItem, CustomModelTrainingItem, CustomModelType } from './types/custom-model-types';
import type { Backend } from 'backend/base';
import type { FlairAnalytics } from 'analytics/base-analytics';
import type { AppUser, AppUserQuotas, InpaintBrushType, InpaintMode, PromptState, PromptTemplate, UserOnboardData, PastGeneration, StateUpdater, DashboardType, UserProjectType, TryOnParsedClothImageBbox, TryOnRenderResults, TryOnEditorState, TryOnClothPromptState, TryOnClothMaskPaintState, TryOnClothMaskType, TryOnPersonPaintState, RegenerateProductRenderState, GenerateStrength, RegenerateProductResults, ITryOnModelPreviewGenerator, TryOnModelPreviewFilterQueryConstraints, PublicUserId, } from './types';
import { fabric } from 'fabric'
import type { Editor } from "core/editor"
import type { EditorConfig, UserAssetInfoCollection, UserAssetInfoGeneratorRef } from "core/common/types"
import { TryOnEditorController } from 'components/editor/tryon-editor-controller';
import type { ApiEditorState } from './types/api';
import { GenerateToolEditorState } from './types/generate-tool';
import { RealTimeRenderEditorState } from './types/realtime-render';
import { MagicEraseEditorState } from './types/magic-erase';
import type { MobileEditorState } from './types/mobile';
import { EditorEventEmitter } from './editor-event-emitter';
import { FeatureFlagController, FeatureFlags } from 'core/controllers/featureFlags';
import { UpscaleV2EditorState } from './types/upscale-v2';
import { ColorCorrectV2EditorState } from './types/color-correct-v2';
import { RegenerateProductEditorState } from './types/regenerate-product';

import type {
  RenderProcessController,
} from './types/render-progress-controller';
export type {
  RenderProcessController,
} from './types/render-progress-controller';

export type Direction = "top" | "left"
export type Size = "width" | "height"
export type ScaleType = "fit" | "fill"

export interface FabricWheelEvent {
  e: WheelEvent
  target?: Object | undefined
  subTargets?: Object[] | undefined
  button?: number | undefined
  isClick?: boolean | undefined
  pointer?: fabric.IPoint | undefined
  absolutePointer?: fabric.IPoint | undefined
  transform?:
  | {
    corner: string
    original: Object
    originX: string
    originY: string
    width: number
  }
  | undefined
}

export interface Dimension {
  width: number
  height: number
}

// export interface RootHandlerOptions

export interface ControllerOptions {
  canvas: FabricCanvas
  config: EditorConfig
  editor: Editor
  state: EditorState
}

export interface CanvasOptions {
  width: number
  height: number
}

export interface FabricCanvasOption {
  wrapperEl: HTMLElement
}

export type FabricCanvas<T extends any = fabric.Canvas> = T & FabricCanvasOption


// History

export interface IHistory<T = any> {
  initialize: () => void,
  destroy: () => void,
  save: (params?: T) => void,
  undo: () => void,
  redo: () => void,
  reset: () => void,
}

// Shortcuts

export interface IShortcutsManager {
  handleKeyDown: (event: KeyboardEvent) => {
    isHandled: boolean,
  },
}

//  Template

export interface Template {
  id: string
  name: string
  preview: string
  background: any
  frame: {
    width: number
    height: number
  }
  objects: any[]
  metadata: {
    animated: boolean
  }
}

export interface GradientOptions {
  angle: number
  colors: string[]
}

export interface ShadowOptions extends fabric.IShadowOptions {
  enabled: boolean
}

export enum RenderJobControllerStatus {
  Rendering = "Rendering",
  Cancelled = "Cancelled",
}

export interface RenderJobController {
  readonly status: RenderJobControllerStatus,
  setCancelJobCallback: (callback: () => Promise<void>) => void,
  cancelJob: () => Promise<void>,
  isCancelled: () => boolean,
}

export type EditorStateFrame = {
  width: number,
  height: number,
}

export type EditorContextMenuRequest = {
  left: number,
  top: number,
  target?: fabric.Object,
}

export type EditorActiveObject = fabric.Object | fabric.ActiveSelection | null | undefined;

export const emptyPromptState: PromptState = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export interface EditorState extends MobileEditorState, CustomModelEditorState, ApiEditorState, GenerateToolEditorState, RegenerateProductEditorState, RealTimeRenderEditorState, MagicEraseEditorState, UpscaleV2EditorState, ColorCorrectV2EditorState {
  eventEmitter: EditorEventEmitter,
  analytics: FlairAnalytics,
  setAnalytics: (value: StateUpdater<FlairAnalytics>) => void,
  user: AppUser | null;
  setUser: (value: AppUser | null) => void,
  publicUserId: PublicUserId | null;
  setPublicUserId: (value: PublicUserId | null) => void,
  userQuotas: AppUserQuotas | null,
  setUserQuotas: (value: StateUpdater<AppUserQuotas | null>) => void,
  userOnboardData: UserOnboardData | null,
  setUserOnboardData: (value: StateUpdater<UserOnboardData | null>) => void,
  // Dashboard
  dashboardType: DashboardType,
  setDashboardType: (value: StateUpdater<DashboardType>) => void,
  // Projects
  version: string,
  setVersion: (value: StateUpdater<string>) => void,
  projectId: string | undefined,
  setProjectId: (value: StateUpdater<string | undefined>) => void,
  projectType: UserProjectType | undefined,
  setProjectType: (value: StateUpdater<UserProjectType | undefined>) => void,
  projectDisplayName: string | undefined,
  setProjectDisplayName: (value: StateUpdater<string | undefined>) => void,
  isCreatingNewProject: boolean,
  setIsCreatingNewProject: (value: StateUpdater<boolean>) => void,
  // Assets
  userImageAssetInfoCollection: UserAssetInfoCollection,
  setUserImageAssetInfoCollection: (value: StateUpdater<UserAssetInfoCollection>) => void,
  userImageAssetGeneratorRef: UserAssetInfoGeneratorRef,
  setUserImageAssetGeneratorRef: (value: StateUpdater<UserAssetInfoGeneratorRef>) => void,
  // Custom model
  customModelWorkflow: CustomModelType,
  setCustomModelWorkflow: (value: StateUpdater<CustomModelType>) => void,
  customModelId: string | undefined,
  setCustomModelId: (value: StateUpdater<string | undefined>) => void,
  customModelDataset: CustomModelDataset | undefined,
  setCustomModelDataset: (value: StateUpdater<CustomModelDataset | undefined>) => void,
  customModelTrainings: Record<string, CustomModelTrainingItem>,
  setCustomModelTrainings: (value: StateUpdater<Record<string, CustomModelTrainingItem>>) => void,
  customModelPredictions: Record<string, CustomModelPredictionItem>,
  setCustomModelPredictions: (value: StateUpdater<Record<string, CustomModelPredictionItem>>) => void,
  // Frame
  frame?: EditorStateFrame
  setFrame: (o: EditorStateFrame) => void
  activeObject: EditorActiveObject
  setActiveObject: (o?: EditorActiveObject) => void
  // Generation frames
  objectsInsideGenerationFrame: fabric.Object[]
  setObjectsInsideGenerationFrame: (o: fabric.Object[]) => void
  zoomRatio: number
  setZoomRatio: (o: number) => void
  contextMenuRequest: EditorContextMenuRequest | null
  setContextMenuRequest: (o: EditorContextMenuRequest | null) => void;
  isSelectingTag: boolean,
  setIsSelectingTag: (value: StateUpdater<boolean>) => void,
  pastGenerations: Record<string, PastGeneration>,
  setPastGenerations: (value: StateUpdater<Record<string, PastGeneration>>) => void,
  // Editor
  editor: Editor | null
  setEditor: (o: Editor | null) => void
  // Inpaint
  activeInpaintMode: InpaintMode,
  setActiveInpaintMode: (value: StateUpdater<InpaintMode>) => void,
  activeInpaintBrush: InpaintBrushType,
  setActiveInpaintBrush: (value: StateUpdater<InpaintBrushType>) => void,
  inpaintBrushSize: number,
  setInpaintBrushSize: (value: StateUpdater<number>) => void,
  inpaintBrushColor: string,
  setInpaintBrushColor: (value: StateUpdater<string>) => void,
  isInpaintPointerDown: boolean,
  setIsInpaintPointerDown: (value: StateUpdater<boolean>) => void,
  // Elements
  activeElementType: string,
  setActiveElementType: (value: StateUpdater<string>) => void,
  // Backend
  backend?: Backend,
  // FeatureFlags
  featureFlagsController?: FeatureFlagController,
  featureFlagVIP: boolean,
  setFeatureFlagVIP: (value: StateUpdater<boolean>) => void,
  featureFlags?: FeatureFlags,
  setFeatureFlags: (value: StateUpdater<FeatureFlags | undefined>) => void,
  // TryOn
  tryOnModelPrompt: string,
  setTryOnModelPrompt: (value: StateUpdater<string>) => void,
  tryOnClothPrompt: string,
  setTryOnClothPrompt: (value: StateUpdater<string>) => void,
  tryOnBackgroundPrompt: string,
  setTryOnBackgroundPrompt: (value: StateUpdater<string>) => void,
  tryOnClothImageElement?: HTMLImageElement;
  setTryOnClothImageElement: (value: StateUpdater<HTMLImageElement | undefined>) => void;
  tryOnWarpedClothImageElement?: HTMLImageElement;
  setTryOnWarpedClothImageElement: (value: StateUpdater<HTMLImageElement | undefined>) => void;
  tryOnWarpedHumanMaskImageElement?: HTMLImageElement;
  setTryOnWarpedHumanMaskImageElement: (value: StateUpdater<HTMLImageElement | undefined>) => void;
  tryOnPersonImageElement?: HTMLImageElement;
  setTryOnPersonImageElement: (value: StateUpdater<HTMLImageElement | undefined>) => void;
  tryOnParsedClothUpdated: boolean,
  setTryOnParsedClothUpdated: (value: StateUpdater<boolean>) => void;
  tryOnParsedClothImageElement?: HTMLImageElement;
  setTryOnParsedClothImageElement: (value: StateUpdater<HTMLImageElement | undefined>) => void;
  tryOnParsedClothImageBbox?: TryOnParsedClothImageBbox;
  setTryOnParsedClothImageBbox: (value: StateUpdater<TryOnParsedClothImageBbox | undefined>) => void;
  tryOnRenderResults: TryOnRenderResults;
  setTryOnRenderResults: (value: StateUpdater<TryOnRenderResults>) => void;
  tryOnModelId: string | undefined;
  setTryOnModelId: (value: StateUpdater<string | undefined>) => void;
  tryOnEditorState: TryOnEditorState;
  setTryOnEditorState: (value: StateUpdater<TryOnEditorState>) => void;
  tryOnClothPromptState: TryOnClothPromptState;
  setTryOnClothPromptState: (value: StateUpdater<TryOnClothPromptState>) => void;
  tryOnRenderProgress: number;
  setTryOnRenderProgress: (value: StateUpdater<number>) => void;
  tryOnClothMaskBrushSize: number;
  setTryOnClothMaskBrushSize: (value: StateUpdater<number>) => void;
  tryOnClothMaskPaintState: TryOnClothMaskPaintState,
  setTryOnClothMaskPaintState: (value: StateUpdater<TryOnClothMaskPaintState>) => void,
  tryOnActiveClothMaskType: TryOnClothMaskType,
  setTryOnActiveClothMaskType: (value: StateUpdater<TryOnClothMaskType>) => void,
  tryOnPersonPaintState: TryOnPersonPaintState,
  setTryOnPersonPaintState: (value: StateUpdater<TryOnPersonPaintState>) => void,
  tryOnPersonBrushSize: number;
  setTryOnPersonBrushSize: (value: StateUpdater<number>) => void;
  tryOnEditorController: TryOnEditorController | undefined,
  setTryOnEditorController: (value: StateUpdater<TryOnEditorController | undefined>) => void;
  tryOnModelPreviewGenerator: ITryOnModelPreviewGenerator | undefined;
  setTryOnModelPreviewGenerator: (value: StateUpdater<ITryOnModelPreviewGenerator | undefined>) => void;
  tryOnModelPreviewFilterContraints: TryOnModelPreviewFilterQueryConstraints;
  setTryOnModelPreviewFilterContraints: (value: StateUpdater<TryOnModelPreviewFilterQueryConstraints>) => void;
  // Edit Image
  editingObjectId: string | undefined,
  setEditingObjectId: (value: StateUpdater<string | undefined>) => void,
  // Regenerate Product
  regenerateProductRenderState: RegenerateProductRenderState,
  setRegenerateProductRenderState: (value: StateUpdater<RegenerateProductRenderState>) => void,
  regenerateProductCorrectColor: boolean,
  setRegenerateProductCorrectColor: (value: StateUpdater<boolean>) => void,
  regenerateProductColorStrength: GenerateStrength,
  setRegenerateProductColorStrength: (value: StateUpdater<GenerateStrength>) => void,
  regenerateProductPromptTemplate: PromptTemplate,
  setRegenerateProductPromptTemplate: (value: StateUpdater<PromptTemplate>) => void,
  regenerateProductInputImagePath: string | undefined,
  setRegenerateProductInputImagePath: (value: StateUpdater<string | undefined>) => void,
  regenerateProductResults: RegenerateProductResults,
  setRegenerateProductResults: (value: StateUpdater<RegenerateProductResults>) => void,
  regenerateProductNumImages: number,
  setRegenerateProductNumImages: (value: StateUpdater<number>) => void,
  regenerateProductErasedImagePath: string | undefined,
  setRegenerateProductErasedImagePath: (value: StateUpdater<string | undefined>) => void,
  regenerateProductEraseMaskImagePath: string | undefined,
  setRegenerateProductEraseMaskImagePath: (value: StateUpdater<string | undefined>) => void,
  regenerateRenderProcessController: RenderProcessController | undefined,
  setRegenerateRenderProcessController: (value: StateUpdater<RenderProcessController | undefined>) => void,
  regenerateProductReferenceImagePath: string | undefined,
  setRegenerateProductReferenceImagePath: (value: StateUpdater<string | undefined>) => void,
  // Replace product
  replaceProductInputImagePath: string | undefined,
  replaceProductCanvasState: string | undefined,
  setReplaceProductInputImagePath: (value: StateUpdater<string | undefined>) => void,
  setReplaceProductCanvasState: (value: StateUpdater<string | undefined>) => void,
}