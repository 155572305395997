export class AnalyticsConfig {
    static InviteCodeRequired = 'Invite Code Required';

    static ProjectCreate = 'Project Create';

    static ProjectCreateError = 'Project Create Error';

    static PageOpen = 'Page Open';

    static UserLogIn = 'User Login';

    static RenderStart = 'Render Start';

    static RenderFinish = "Render Finish";

    static RenderError = "Render Error";

    static RenderQuotaDisplay = 'Render Quota Display';

    static RenderQuotaSubscribe = "Render Quota Subscribe";

    static TutorialStart = 'Tutorial Start';

    static TutorialFinish = 'Tutorial Finish';

    static TutorialSkip = 'Tutorial Skip';

    static MessageDisplay = 'Message Display';

    static ModelCreateError = 'Model Create Error';

    static ReferenceImageInteraction = 'Reference Image Interaction';

    static TryOnUpscaleInteraction = 'TryOn Upscale Interaction';

    static EditImageToolButtonInteraction = 'Edit Image Tool Button Interaction';

    static UsedFeatureFlags = 'Used Feature Flags';

    static TryOnRenderSteps = 'TryOn Render Steps';

    static WorkflowAction = 'Workflow Action';
}
