import { fabric } from "fabric"
import { path as base } from "./constants"

export function generatePath(object: any) {
  let position = {
    top: object.top,
    left: object.left,
  }

  const { fill, path } = object

  return {
    ...base,
    ...position,
    stroke: object.stroke,
    strokeWidth: object.strokeWidth,
    strokeDashArray: object.strokeDashArray,
    strokeLineCap: object.strokeLineCap,
    strokeLineJoin: object.strokeLineJoin,
    strokeUniform: object.strokeUniform,
    strokeMiterLimit: object.strokeMiterLimit,
    strokeDashOffset: object.strokeDashOffset,
    fill,
    path: path,
  }
}

export async function parseSVG(url: string) {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(url, (objects, summary) => {
      const frame = {
        width: summary.width,
        height: summary.height,
      }

      let layers: any[] = []

      for (const object of objects) {
        if (object.type === "path") {
          const path = generatePath(object)
          layers = layers.concat(path)
        }
      }

      const design = {
        frame,
        layers,
        name: "Hello world",
      }
      resolve(design)
    })
  })
}