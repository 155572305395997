import { QuestionMarkIcon } from '@radix-ui/react-icons';
import { defaultGenerateTemplateId } from 'components/constants/default-generate-template';
import { editorContextStore } from 'contexts/editor-context';
import { Folder } from 'lucide-react';
import React from 'react';
import type { StepByStepsTutorialConfig } from '../config'
import { Highlighter } from '../highlighter';
import { useStepByStepTutorialContext } from '../step-by-step-context';
import { inlineButtonClassName } from './constants';
import { ImageComponent } from 'components/utils/image';

function VideoContainer({
    src,
    style = {
        position: 'relative',
        paddingTop: '56.25%',
    },
    title = 'video',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    src: string,
    title?: string,
}) {
    return (
        <div
            {...props}
            style={style}
        >
            <iframe
                src={src}
                title={title}
                style={{
                    position: 'absolute',
                    border: 'none',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
            />
        </div>
    );
}

function WelcomeToFlair() {
    return (
        <div
            className='text-zinc-400'
        >
            <span>
                Flair uses AI to generate photoshoots for your product! Take this 4-step tutorial to create a product ad.
            </span>
            <ImageComponent alt="welcome-to-flair" src="https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ad988971-9f07-4cc5-abdc-6fd99ccffa00/public" />
            <div>
                You can find more help and resources by clicking the <span className='inline-block rounded-full mx-1 p-3 bg-black align-middle shadow-lg-center shadow-lime-500/20'><QuestionMarkIcon /></span> icon on the lower right corner.
            </div>
        </div>
    );
}

function PlaceAsset() {
    const [highlightId, setHighlightId] = React.useState<string | null>('left-panel-assets-container');
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    React.useEffect(() => {
        setHighlightId('left-panel-assets-container');
        setActiveLeftPanels(['Assets']);
    }, [setActiveLeftPanels]);
    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} />}
            Use the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('left-panel-assets-container')}><Folder className='inline mr-1 mb-px' size={15} /> Assets</span> panel on the left to add images into the editor.<br />
            <VideoContainer
                className='my-2'
                src="https://customer-bv4abt5at37g3xk5.cloudflarestream.com/e0619e161f14422bbe7d6da03e5ec743/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-bv4abt5at37g3xk5.cloudflarestream.com%2Fe0619e161f14422bbe7d6da03e5ec743%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
            />
            We have already placed a bottle for you.
            You can also use the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('left-panel-assets-upload-image-button')} onPointerOut={() => setHighlightId('left-panel-assets-container')}>Upload Image</span> button to add your own image.<br /><br />
            Make sure the image is positioned inside the green <span className='text-lime-500'>Canvas</span>.<br />
            <ImageComponent className='w-full px-2' alt="placement-canvas" src="https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c4a6d1c3-5cb8-4870-f287-d89f49f7dd00/public" />
        </div>
    );
}

const defaultGenerateTemplateElementId = `generate-templates-${defaultGenerateTemplateId}`;
function UseGenerateToolTemplate() {
    const [highlightId, setHighlightId] = React.useState<string | null>(defaultGenerateTemplateElementId);
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    const setGenerateToolEditorTab = editorContextStore(state => state.setGenerateToolEditorTab);
    const { setStep } = useStepByStepTutorialContext();
    React.useEffect(() => {
        setTimeout(() => {
            if (editorContextStore.getState().generateToolEditorTab === 'Templates') {
                return;
            }
            setStep(3);
        }, 100);
    }, [generateToolEditorTab, setStep]);
    React.useEffect(() => {
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Templates');
    }, [setActiveLeftPanels, setGenerateToolEditorTab]);
    return (
        <div
            className='text-zinc-400'
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} attachToTarget={highlightId === defaultGenerateTemplateElementId} />}
            Select a template to configure the prompt.<br /><br />
            You can also search templates (e.g. wavy fabric) using the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('generate-search-bar')} onPointerOut={() => setHighlightId(defaultGenerateTemplateElementId)}>Search Bar</span>.
            <VideoContainer
                className='mb-6'
                src="https://customer-bv4abt5at37g3xk5.cloudflarestream.com/40f4044232a7b9fbca0f35f744535d03/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-bv4abt5at37g3xk5.cloudflarestream.com%2F40f4044232a7b9fbca0f35f744535d03%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
            />
        </div>
    )
}

function ConfigPrompt() {
    const [highlightId, setHighlightId] = React.useState<string | null>('left-panel-prompt-editor-form');
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    const setGenerateToolEditorTab = editorContextStore(state => state.setGenerateToolEditorTab);
    React.useEffect(() => {
        setHighlightId('left-panel-prompt-editor-form');
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Editor');
    }, [setActiveLeftPanels, setGenerateToolEditorTab]);
    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} />}
            Fill out the <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('prompt-editor-subject-0')} onPointerOut={() => setHighlightId('left-panel-prompt-editor-form')}>Subject</span> describing the image in the canvas (e.g. bottle). This value is required.<br /><br />
            <VideoContainer
                className='mb-6'
                src="https://customer-bv4abt5at37g3xk5.cloudflarestream.com/98a880d394fe58949eab00064e883699/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-bv4abt5at37g3xk5.cloudflarestream.com%2F98a880d394fe58949eab00064e883699%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
            />
            You can also configure the following values in the form:<br /><br />
            <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('prompt-editor-placement-0')} onPointerOut={() => setHighlightId('left-panel-prompt-editor-form')}>Placement</span>: where the subject is positioned (e.g. white circular platform)<br /><br />
            <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('prompt-editor-surrounding-0')} onPointerOut={() => setHighlightId('left-panel-prompt-editor-form')}>Surrounding</span>: objects around the subject (e.g. flowers, rocks, abstract blobs, etc.)<br /><br />
            <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('prompt-editor-background-0')} onPointerOut={() => setHighlightId('left-panel-prompt-editor-form')}>Background</span>: elements behind the subject (e.g. beige background, soft shadows, wavy fabrics, etc)<br />
        </div>
    );
}

function autofillSubject() {
    const {
        generateToolPromptTemplate,
        setGenerateToolPromptTemplate,
    } = editorContextStore.getState();

    let subjectIndex = generateToolPromptTemplate.words.findIndex(word => word.autocompleteType === 'subject');

    if (subjectIndex < 0) {
        setGenerateToolPromptTemplate((template) => {
            return {
                ...template,
                words: [
                    {
                        type: 'fixed',
                        value: 'bottle',
                        autocompleteType: 'subject'
                    },
                    ...template.words,
                ],
            }
        });
        return true;
    } else if (!generateToolPromptTemplate.words[subjectIndex]?.value) {
        setGenerateToolPromptTemplate((template) => {
            const newWords = template.words.slice();
            newWords[subjectIndex] = {
                type: 'fixed',
                value: 'bottle',
                autocompleteType: 'subject',
            }
            return {
                ...template,
                words: newWords,
            }
        });
        return true;
    }
    return false;
}

function ClickGenerate() {
    const [highlightId, setHighlightId] = React.useState<string | null>('generate-button');
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    React.useEffect(() => {
        setHighlightId('generate-button');
        setActiveLeftPanels(['Generate']);
        autofillSubject();
    }, [setActiveLeftPanels]);
    return (
        <div
            className='text-zinc-400'
            onPointerLeave={() => setHighlightId(null)}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} />}
            Hit the green <span className={inlineButtonClassName} onPointerOver={() => setHighlightId('generate-button')} onPointerOut={() => setHighlightId('generate-button')}>Generate</span> button on the left panel.<br /><br />
            Wait for a few seconds, and the resulting image will appear to the right of the <span className='text-lime-500'>Canvas</span>.<br /><br />
            Click on the generated image, and you can find more options to download / edit the image on the left panel.<br />
            <VideoContainer
                src="https://customer-bv4abt5at37g3xk5.cloudflarestream.com/17e64918d01e761a914fef0c166b58e3/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-bv4abt5at37g3xk5.cloudflarestream.com%2F17e64918d01e761a914fef0c166b58e3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
            />
        </div>
    );
}

function ArticleCard({
    alt,
    src,
    title,
    to,
}: {
    alt: string,
    src: string,
    title: string,
    to: string,
}) {
    return (
        <a
            href={to}
            className='rounded-md flex flex-col border border-zinc-800 overflow-hidden hover:border-lime-500 transition-colors'
            target="_blank"
            rel="noopener noreferrer"
        >
            <ImageComponent
                alt={alt}
                src={src}
            />
            <div className='px-3 py-3'>
                {title}
            </div>
        </a>
    )
}

function Ending() {
    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);
    const setGenerateToolEditorTab = editorContextStore(state => state.setGenerateToolEditorTab);
    React.useEffect(() => {
        setActiveLeftPanels(['Generate']);
        setGenerateToolEditorTab('Templates');
    }, [setActiveLeftPanels, setGenerateToolEditorTab]);
    return (
        <div className='text-zinc-400 flex flex-col'>
            <div className='mb-2'>
                That's it for the short introduction. Try select another template to see how different prompts influence the generation.<br /><br />
                Now let's build some cool designs!
            </div>
        </div>
    );
}

export const stepByStepsFirstOnboardConfig: StepByStepsTutorialConfig = {
    name: 'Editor First Onboard Tutorial',
    hasWelcome: true,
    hasEnding: true,
    displayDone: true,
    steps: [
        {
            title: 'Welcome to Flair!',
            Content: WelcomeToFlair,
        },
        {
            title: 'Place asset into the Canvas',
            Content: PlaceAsset,
        },
        {
            title: 'Select a prompt template',
            Content: UseGenerateToolTemplate,
        },
        {
            title: 'Configure the prompt',
            Content: ConfigPrompt,
        },
        {
            title: 'Click generate!',
            Content: ClickGenerate,
        },
        {
            title: '🎉 That\'s it!',
            Content: Ending,
        }
    ]
}

function TemplateHighlighter() {
    const [show, setShow] = React.useState(false);
    const hasShownRef = React.useRef(false);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    React.useEffect(() => {
        hasShownRef.current = false;
    }, []);
    React.useEffect(() => {
        if (hasShownRef.current) {
            setShow(false);
            return;
        }
        if (!activeLeftPanels.includes('Generate')) {
            setShow(false);
            return;
        }
        if (generateToolEditorTab === 'Templates') {
            setShow(true);
            hasShownRef.current = true;
            return;
        }
        setShow(false);
    }, [activeLeftPanels, generateToolEditorTab]);
    if (!show) {
        return null;
    }
    return (
        <>
            <Highlighter type="box" elementId={defaultGenerateTemplateElementId} attachToTarget={true} />
        </>
    )
}

function TemplateTabHighlighter() {
    const [show, setShow] = React.useState(false);
    const hasShownRef = React.useRef(false);
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const generateToolEditorTab = editorContextStore(state => state.generateToolEditorTab);
    React.useEffect(() => {
        hasShownRef.current = false;
    }, []);
    React.useEffect(() => {
        if (hasShownRef.current) {
            setShow(false);
            return;
        }
        if (!activeLeftPanels.includes('Generate')) {
            setShow(false);
            return;
        }
        if (generateToolEditorTab === 'Editor') {
            setShow(true);
            hasShownRef.current = true;
            return;
        }
        setShow(false);
    }, [activeLeftPanels, generateToolEditorTab]);
    if (!show) {
        return null;
    }
    return (
        <>
            <Highlighter
                type="box"
                elementId='left-panel-generate-tab-Templates'
                popupContent='Try more prompts in the "Templates" tab.'
            />
        </>
    )
}

export function FirstOnboardDefaultHighlighters() {
    return (
        <>
            <TemplateHighlighter />
        </>
    );
}