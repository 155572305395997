import { FRAME_DARK } from "components/constants/colors"
import type { ILayer } from 'core/common/layers';
import type { BackgroundOptions } from "objects/background"
import type { FrameOptions } from "objects/frame"
import type { EditorConfig, UserOnboardData } from "./types"
import { GenerationFrameOptions } from 'objects/generation-frame';
import { IGroupOptions } from "fabric/fabric-impl";

export const DEFAULT_VIEWPORT_TRANSFORM: [number, number, number, number, number, number,] = [
  1, 0, 0, 1, 0, 0,
]

export const PROPERTIES_TO_INCLUDE = [
  "id",
  "name",
  "description",
  "asset",
  "generationId",
  "keys",
  "keyValues",
  "animation",
  "metadata",
  "cut",
  "startAt",
  "endAt",
  "originalURL",
  "colorMap",
  "fontURL",
  "duration",
  "filters",
]

export enum MouseButton {
  Left = 1,
  Middle = 2,
  Right = 3,
}

export function getMouseButtonFromPointerEvent(e: PointerEvent): MouseButton {
  return (e?.button || 0) + 1;
}

/**
 * default length (pixels) of the generation frame in object space
 */
export const DEFAULT_CANVAS_LENGTH = 1024;

/**
 * length (pixels) of the render canvas when creating render input.
 * This value shoould be slightly larger than the `DEFAULT_CANVAS_LENGTH` so that the input can be overlayed onto the generated result while preserving the original quality.
 */

export const RENDER_CANVAS_LEGNTH = 1024;

export const MAX_CANVAS_LENGTH = 1024;

export const DEFAULT_RENDER_LENGTH = 1024;

export const DEFAULT_RENDER_NUM_INFERENCE_STEPS = 25;

export const DEFAULT_OBJECT_WIDTH = DEFAULT_CANVAS_LENGTH;

export const DEFAULT_ZOOM_SENSITIVITY = 0.02;

export const MIN_OBJECT_LENGTH = 100;

export const MAX_OBJECT_LENGTH = MAX_CANVAS_LENGTH;

export const defaultEditorConfig: EditorConfig = {
  id: "random_id_12",
  clipToFrame: false,
  scrollLimit: 200,
  propertiesToInclude: PROPERTIES_TO_INCLUDE,
  guidelines: true,
  shortcuts: true,
  frameMargin: 120,
  background: "#ecf0f1",
  type: "GRAPHIC",
  size: {
    width: 1024,
    height: 900,
  },
  controlsPosition: {
    rotation: "TOP",
  },
  shadow: null,
}

export const defaultObjectOptions: ILayer = {
  id: '',
  type: '',
  name: '',
  originX: "left",
  originY: "top",
  left: 0,
  top: 0,
  width: DEFAULT_OBJECT_WIDTH,
  fill: FRAME_DARK,
  strokeWidth: 0,
  strokeLineCap: "butt",
  strokeDashOffset: 0,
  strokeLineJoin: "miter",
  strokeUniform: false,
  strokeMiterLimit: 4,
  scaleX: 1,
  scaleY: 1,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 1,
  visible: true,
  skewX: 0,
  skewY: 0,
};

export const defaultControllerOptions: IGroupOptions = {
  cornerStyle: 'circle',
  cornerColor: '#ffffff',
  transparentCorners: false,
}

export const defaultFrameOptions: FrameOptions = {
  width: DEFAULT_OBJECT_WIDTH,
  height: DEFAULT_OBJECT_WIDTH,
  id: "frame",
  name: "Initial Frame",
  fill: FRAME_DARK,
  hoverCursor: "default",
}

export const defaultBackgroundOptions: BackgroundOptions = {
  width: DEFAULT_OBJECT_WIDTH,
  height: DEFAULT_OBJECT_WIDTH,
  fill: FRAME_DARK,
  id: "background",
  name: "Initial Frame",
  selectable: false,
};

export const defaultGenerationFrameOptions: GenerationFrameOptions = {
  width: DEFAULT_CANVAS_LENGTH,
  height: DEFAULT_CANVAS_LENGTH,
  id: 'generation-frame',
  name: 'Generation Frame',
};

export const commonParams = {
  backgroundColor: "",
  fillRule: "nonzero",
  globalCompositeOperation: "source-over",
  strokeDashArray: null,
  strokeDashOffset: 0,
  strokeLineCap: "butt",
  strokeLineJoin: "miter",
  strokeMiterLimit: 4,
  strokeUniform: false,
}

const getCopyStyleVector = () => {
  const copyStyleVector = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 3.5H5a.5.5 0 0 0-.5.5v1.5A.5.5 0 0 0 5 6h11a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5zM5 2a2 2 0 0 0-2 2v1.5a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-.25h.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-5.75a2.25 2.25 0 0 0-2.25 2.25v1.563A2 2 0 0 0 9 15v5a2 2 0 0 0 2 2h.5a2 2 0 0 0 2-2v-5a2 2 0 0 0-1.5-1.937V11.5a.75.75 0 0 1 .75-.75h5.75a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25h-.515A2 2 0 0 0 16 2H5zm7 13a.5.5 0 0 0-.5-.5H11a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-5z" fill="currentColor"></path></svg>
	`
  return `data:image/svg+xml;base64,${window.btoa(copyStyleVector)}`
}

export const getCopyStyleCursor = () => {
  return `url(${getCopyStyleVector()}), crosshair`
}

export const copyStyleProps = {
  StaticText: [
    "fill",
    "opacity",
    "stroke",
    "strokeWidth",
    "textAlign",
    "fontFamily",
    "fontSize",
    "underline",
    "shadow",
    "angle",
  ],
  StaticImage: ["opacity", "stroke", "strokeWidth", "shadow", "angle"],
  StaticPath: ["fill", "opacity", "stroke", "strokeWidth", "shadow", "angle"],
}

export enum EditorStatus {
  Loading = 'Loading',
  Authorized = 'Authorized',
  PermissionDenied = 'PermissionDenied',
}

export const defaultUserOnboardData: UserOnboardData = {
  isProjectTutorialFinished: false,
}