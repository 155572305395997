import { LeftPanelTitleClassName } from "components/constants/class-names"
import { classNames } from "core/utils/classname-utils"
import React from "react"


export function LeftPanelTitleBase({
    className,
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={`${LeftPanelTitleClassName} font-semibold mb-2 ${className}`}
        >
            {children}
        </div>
    )
}

export function LeftPanelTitle({
    className = '',
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <LeftPanelTitleBase
            {...props}
            className={classNames(
                "text-lg",
                className,
            )}
        >
            {children}
        </LeftPanelTitleBase>
    )
}

export function LeftPanelSubtitle({
    className,
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <LeftPanelTitleBase
            {...props}
            className={classNames(
                "text-sm",
                className ?? '',
            )}
        >
            {children}
        </LeftPanelTitleBase>
    )
}