import { EditorAssetContentType } from 'core/common/types';
import { editorContextStore } from 'contexts/editor-context';
import { isDataURL } from 'core/utils/string-utils';
import Pica from "pica";
import { fabric } from 'fabric';
import { loadImageElementFromBlob, loadImageElementFromURL } from './image-loader';
import { isStaticImageObjectHed } from './type-guards';
import { ChannelRGBA, ImageFormat } from 'core/common/types/image';
import { StaticImageElementColorDisplayType } from 'core/common/types/elements';

const pica = new Pica();

async function resizeImageTraditional(
    from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob,
    to: HTMLCanvasElement,
) {
    try {
        const ctx = to.getContext('2d');
        if (!ctx) {
            return to;
        }
        if (from instanceof Blob) {
            from = await loadImageElementFromBlob(from);
        }
        ctx.drawImage(
            from,
            0, 0,
            from.width, from.height,
            0, 0,
            to.width, to.height,
        );
    } catch (error) {
        console.error(error);
    }
    return to;
}

async function resizeImageInternal(
    from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob,
    to: HTMLCanvasElement,
) {
    try {
        return await pica.resize(from, to);
    } catch (error) {
        console.error(error);
    }
    return resizeImageTraditional(from, to);
}

export async function resizeImageCanvasElement({
    from,
    to,
    width,
    height,
}: {
    from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob,
    to?: HTMLCanvasElement,
    width?: number,
    height?: number,
}) {
    if (!to) {
        if (!width || !height) {
            return;
        }
        to = document.createElement('canvas');
        to.width = width;
        to.height = height;
    }
    return await resizeImageInternal(from, to);
}

function getCanvasFromImageElement(
    imageElement: HTMLImageElement,
    canvas?: HTMLCanvasElement,
): HTMLCanvasElement | undefined {
    // Create a canvas element
    canvas = canvas || document.createElement('canvas');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    // Draw the image onto the canvas
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return undefined;
    }
    ctx.drawImage(imageElement, 0, 0);

    return canvas;
}

function getDataUrlFromImageElementInternal({
    from,
    to,
}: {
    from: HTMLCanvasElement | HTMLImageElement,
    to: HTMLCanvasElement,
}) {
    const ctx = to.getContext('2d');
    if (!ctx) {
        return undefined;
    }
    to.width = from.width;
    to.height = from.height;
    ctx.drawImage(from, 0, 0);
    return to.toDataURL("image/png");
}

export async function getDataUrlFromImageElement({
    from,
    to,
    width,
    height,
}: {
    from: HTMLCanvasElement | HTMLImageElement,
    to?: HTMLCanvasElement,
    width?: number,
    height?: number,
}) {
    width = width || from.width;
    height = height || from.height;

    // Check if the source of the image element is already a valid url
    if (from instanceof HTMLImageElement && width === from.width && height === from.height && isDataURL(from.src)) {
        return from.src;
    }

    to = to || document.createElement('canvas');
    if (!width || !height || (width === from.width && height === from.height)) {
        return getDataUrlFromImageElementInternal({
            from, to,
        });
    }
    to.width = width;
    to.height = height;
    return (await resizeImageInternal(from, to)).toDataURL("image/png");
}

export async function getDataUrlFromImageElementResized({
    image,
    targetLength,
}: {
    image: HTMLCanvasElement | HTMLImageElement,
    targetLength: number,
}) {
    const width = image.width;
    const height = image.height;
    const scale = targetLength / Math.min(width, height);
    return await getDataUrlFromImageElement({
        from: image,
        width: Math.round(scale * width),
        height: Math.round(scale * height),
    });
}

export async function getRawDataUrlFromImageObject({
    object: imageObject,
    width,
    height,
}: {
    object: fabric.Image | fabric.StaticImage,
    width?: number,
    height?: number,
}) {
    const imageElement = imageObject?.getElement();
    if (!(imageElement instanceof HTMLImageElement)) {
        return;
    }
    return await getDataUrlFromImageElement({
        from: imageElement,
        width,
        height,
    });
}

export function getImageDataUrlSize(url: string) {
    return new Promise<{ width: number, height: number }>((resolve, reject) => {
        if (!isDataURL(url)) {
            return reject('No valid data url');
        }
        var i = new Image();
        i.onload = function () {
            resolve({
                width: i.width,
                height: i.height,
            });
        };
        i.onerror = reject;
        i.src = url;
    })
}

function setImageObjectSrcInternal(
    object: fabric.StaticImage,
    imageUrl: string,
) {
    return new Promise((resolve) => {
        object.setSrc(
            imageUrl,
            resolve,
        );
    });
}

export function setImageObjectSrc(
    object: fabric.StaticImage,
    imageUrl?: string | null,
    onError?: (error: Error) => void,
) {
    if (!object || !imageUrl) {
        onError?.(new Error('Image url is invalid.'));
        return;
    }
    const {
        editor,
        backend,
    } = editorContextStore.getState();
    if (!editor || !backend) {
        return;
    }
    object.setSrc(
        imageUrl,
        () => {
            editor.canvas.requestRenderAll();
        },
    );
    editor.assets.addAsset({
        data: imageUrl,
        contentType: EditorAssetContentType.png,
    }).then((path) => {
        if (path) {
            editor.assets.setObjectAsset(
                object.id,
                {
                    type: 'image-storage',
                    path,
                    contentType: EditorAssetContentType.png,
                }
            );
            return editor.assets.setUserAssetInfo({
                assetType: 'images',
                storagePath: path,
            });
        } else {
            onError?.(new Error('Cannot upload image to the storage.'));
        }
    });
}

export function canHedImageBeColored(object: fabric.Object | fabric.StaticImage) {
    if (!isStaticImageObjectHed(object)) {
        return false;
    }
    const colorDisplayType = object.metadata?.colorDisplayType;
    if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
        return object.filters && object.filters.length > 0;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.RGB) {
        return true;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly) {
        return true;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape) {
        return true;
    }
    return false;
}

export function canHedImageColorBeUsed(object: fabric.Object | fabric.StaticImage) {
    if (!isStaticImageObjectHed(object)) {
        return false;
    }
    const colorDisplayType = object.metadata?.colorDisplayType;
    if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
        return object.filters && object.filters.length > 0;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.RGB) {
        return true;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly) {
        return false;
    }
    if (colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape) {
        return true;
    }
    return false;
}

export function doesHedImageHaveHedUrl(object: fabric.Object | fabric.StaticImage) {
    if (!isStaticImageObjectHed(object)) {
        return false;
    }

    if (
        object.metadata.colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly ||
        object.metadata.colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape
    ) {
        // By definition, shape only image does not have valid hed url; the shape control must be generated.
        return false;
    }

    return Boolean(object.metadata && object.metadata.hedUrl);
}

export function getHtmlImageElementFromUrlAsync(src: string) {
    return loadImageElementFromURL(src);
}

export function getImageElementFromFileAsync(file: File) {

    return new Promise<HTMLImageElement | undefined>((resolve, reject) => {

        try {

            const fileReader = new FileReader();

            fileReader.onload = () => {
                console.log("load image");

                if (fileReader.result && typeof (fileReader.result) === 'string') {
                    return getHtmlImageElementFromUrlAsync(
                        fileReader.result,
                    ).then(
                        resolve
                    ).catch(reject);
                } else {
                    return reject("File cannot be read as a valid data url.");
                }

            }

            fileReader.onerror = reject;

            fileReader.onabort = () => resolve(undefined);

            fileReader.readAsDataURL(file);

        } catch (error) {

            console.error(error);

            reject(error);

        }

    });

}

export async function getImageElementFromFilesAsync(files: FileList) {
    for (let i = 0; i < files.length; ++i) {

        const imageElement = await getImageElementFromFileAsync(files[i]);

        if (imageElement) {

            return imageElement;

        }

    }
}

export function getImageUrlFromImageId({
    imageId,
    imageSize = 'public',
}: {
    imageId: string,
    imageSize?: '64' | '128' | '256' | 'public',
}) {
    return `https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/${imageId}/${imageSize}`;
}

export function getAssetIdFromStoragePath(input: string): string | null {
    // Use a regular expression to match the given format and extract assetId
    const match = input.match(/users\/.*?\/assets\/(.*?)(\..+)?$/);

    // If the string matches the format, return the assetId
    if (match && match[1]) {
        return match[1];
    }

    // If no match, return null
    return null;
}

export async function updateImageSubjectCaption(
    image: fabric.Object | fabric.StaticImage,
    value: string,
) {
    if (!image) {
        return;
    }

    image.metadata = {
        ...image.metadata,
        subject: value,
    };

    const {
        editor,
    } = editorContextStore.getState();

    await editor?.assets.updateObjectUserAssetInfo({
        object: image,
        assetType: 'images',
        caption: value || "",
    });
}

export function getImageFormatFromImageArray(uint: Uint8Array): ImageFormat | null {
    if (uint[0] === 0xFF && uint[1] === 0xD8 && uint[2] === 0xFF) {
        return ImageFormat.JPEG;
    } else if (uint[0] === 0x89 && uint[1] === 0x50 && uint[2] === 0x4E && uint[3] === 0x47) {
        return ImageFormat.PNG;
    } else if (uint[0] === 0x52 && uint[1] === 0x49 && uint[2] === 0x46 && uint[3] === 0x46 && uint[8] === 0x57 && uint[9] === 0x45 && uint[10] === 0x42 && uint[11] === 0x50) {
        return ImageFormat.WEBP;
    } else {
        return null;
    }
}

export function createSolidColorImageDataURL(
    width: number,
    height: number,
    color = '#000000'
): string {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // Get the 2D context of the canvas
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return '';
    }

    // Set the fill color and fill the canvas
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, width, height);

    // Convert the canvas to a data URL and return it
    return canvas.toDataURL('image/png');
}

export function getImageData(
    imageElement: HTMLImageElement | HTMLCanvasElement,
) {
    const canvas = imageElement instanceof HTMLCanvasElement ?
        imageElement :
        getCanvasFromImageElement(imageElement);

    if (!canvas) {
        return;
    }

    const ctx = canvas?.getContext?.('2d');

    if (!ctx) {
        return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    return imageData;
}

export function isImageBlack(
    imageElement: HTMLImageElement | HTMLCanvasElement,
) {
    const canvas = imageElement instanceof HTMLCanvasElement ?
        imageElement :
        getCanvasFromImageElement(imageElement);

    if (!canvas) {
        return;
    }

    const ctx = canvas?.getContext?.('2d');

    if (!ctx) {
        return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // Check each pixel to see if it is black
    for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i];
        const g = imageData.data[i + 1];
        const b = imageData.data[i + 2];
        // Alpha is imageData.data[i + 3], not needed for black check

        // If any channel is not 0, the image is not completely black
        if (r !== 0 || g !== 0 || b !== 0) {
            return false;
        }
    }

    // If all pixels are black, return true
    return true;
}

export function isImageChannelBlack(
    imageDataArray: Uint8ClampedArray,
    channel: ChannelRGBA,
) {
    // Iterate through the imageDataArray by 4s, starting at the 3rd index, which is the first alpha value
    for (let i = channel; i < imageDataArray.length; i += 4) {
        // Check if the alpha value is not 0 (indicating the pixel is not completely transparent)
        if (imageDataArray[i] !== 0) {
            // If any pixel is not completely transparent, return false
            return false;
        }
    }
    // If all alpha values are 0, return true
    return true;
}
