


export type GenerateTemplateTypesenseDoc = {
    id: string;
    prompt: string;
    image_src: string;
    tags: string[];
    score: number;
    is_tags_null: boolean;
}

export function isGenerateTemplateTypesenseDoc(obj: any): obj is GenerateTemplateTypesenseDoc {
    return (
        obj &&
        typeof obj.id === "string" &&
        typeof obj.prompt === "string" &&
        typeof obj.image_src === "string" &&
        Array.isArray(obj.tags) &&
        obj.tags.every((tag: any) => typeof tag === "string") &&
        typeof obj.score === "number" &&
        typeof obj.is_tags_null === "boolean"
    );
}


export type FlairGenerateTemplateItem = GenerateTemplateTypesenseDoc;

export type FlairTemplateGeneratorBatch = FlairGenerateTemplateItem[];

export type GetGenerateTemplatesTypesenseProps = {
    perPage?: number,
    page?: number,
    searchString?: string,
    tags?: string[],
    includeFields?: string[],
    excludeFields?: string[],
}

export type FlairTemplateGeneratorSearchConstraints = GetGenerateTemplatesTypesenseProps;

export interface FlairTemplateGenerator {
    batchSize: number,
    getNextBatch(): Promise<FlairTemplateGeneratorBatch> | undefined,
    setConstraints(constraints: FlairTemplateGeneratorSearchConstraints): Promise<FlairTemplateGeneratorBatch> | undefined,
    pineconeSearch(searchString: string, amount: number): Promise<FlairTemplateGeneratorBatch> | undefined,
}