import { Base } from "./base"
import { AlignGuidelines, AlignGuidelinesParams } from 'core/controllers/snap/align-guidelines'
import { LayerType } from "core/common/layers";
import { ControllerOptions } from "core/common/interfaces";

class Guidelines extends Base {
  private guidelines: AlignGuidelines;


  constructor(params: AlignGuidelinesParams & ControllerOptions) {
    super(params);
    this.guidelines = new AlignGuidelines({
      ignoreObjTypes: [
        {
          key: 'type',
          value: LayerType.BACKGROUND_IMAGE,
        }
      ],
      pickObjTypes: [
        {
          key: "type",
          value: LayerType.STATIC_IMAGE,
        },
        {
          key: "type",
          value: LayerType.GENERATION_FRAME,
        },
      ],
      ...params
    });
    this.guidelines.init();
  }
}

export default Guidelines;
