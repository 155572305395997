// Staging workflow JSON
import { SampleProjectScene } from "core/common/scene";
import { EditorAssetContentType } from 'core/common/types';

export const StagingProject: SampleProjectScene = {
  "metadata": {
    "animated": false
  },
  "objects": {
    "d8W49CDyY8f87wLT2DiHP": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "cockapoo",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "subject",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9834f91f-637c-47d4-7df3-59c8ee2cbb00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.25,
      "scaleY": 0.25,
      "top": -11.64,
      "angle": 0,
      "id": "d8W49CDyY8f87wLT2DiHP",
      "flipX": false,
      "flipY": false,
      "height": 612,
      "zIndex": 4,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 437.39,
      "name": "StaticImage",
      "width": 408,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9834f91f-637c-47d4-7df3-59c8ee2cbb00/public",
        "type": "image-url"
      }
    },
    "xFwvIW0onXJGqmof7D5ZQ": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "ceramic vase",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fcc44137-acc0-4591-504e-6d537a9cf200/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.25,
      "scaleY": 0.29,
      "top": 2.59,
      "angle": 0,
      "id": "xFwvIW0onXJGqmof7D5ZQ",
      "flipX": false,
      "flipY": false,
      "height": 700,
      "zIndex": 6,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 700.37,
      "name": "StaticImage",
      "width": 700,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fcc44137-acc0-4591-504e-6d537a9cf200/public",
        "type": "image-url"
      }
    },
    "QjLSz0dNF1lK9BXPR944H": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "white bed-side table",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/40146548-5544-4013-ce75-7b832ba0c600/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.35,
      "scaleY": 0.37,
      "top": -47.73,
      "angle": 0,
      "id": "QjLSz0dNF1lK9BXPR944H",
      "flipX": false,
      "flipY": false,
      "height": 700,
      "zIndex": 1,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 104.14,
      "name": "StaticImage",
      "width": 700,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/40146548-5544-4013-ce75-7b832ba0c600/public",
        "type": "image-url"
      }
    },
    "2DJYNexn48JcFZ8IE_O2l": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "leaves on a stem",
        "surroundingPrefix": "next to",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/80e64896-db70-48e7-ca16-ac7c9c9b3100/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.25,
      "scaleY": 0.26,
      "top": -103.99,
      "angle": 0,
      "id": "2DJYNexn48JcFZ8IE_O2l",
      "flipX": false,
      "flipY": false,
      "height": 617,
      "zIndex": 5,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 703.01,
      "name": "StaticImage",
      "width": 405,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/80e64896-db70-48e7-ca16-ac7c9c9b3100/public",
        "type": "image-url"
      }
    },
    "ZsEwPmnE9hb032NFWUsmk": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "wall art",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "subject",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b241883c-83b1-44be-9058-118ade3efb00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 0.61,
      "scaleY": 0.61,
      "top": -300.75,
      "angle": 0,
      "id": "ZsEwPmnE9hb032NFWUsmk",
      "flipX": false,
      "flipY": false,
      "height": 523,
      "zIndex": 2,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 347.06,
      "name": "StaticImage",
      "width": 477,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b241883c-83b1-44be-9058-118ade3efb00/public",
        "type": "image-url"
      }
    },
    "pqA4BzHAb1Q3CLh-noBqY": {
      "strokeWidth": 0,
      "metadata": {
        "surrounding": "white couch",
        "surroundingPrefix": "with",
        "colorDisplayType": "color-and-shape",
        "imageType": "hed",
        "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e20f6d20-c428-421f-d925-95ebe8430b00/public"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 1,
      "scaleY": 1,
      "top": -140.22,
      "angle": 0,
      "id": "pqA4BzHAb1Q3CLh-noBqY",
      "flipX": false,
      "flipY": false,
      "height": 415,
      "zIndex": 3,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": 246.75,
      "name": "StaticImage",
      "width": 507,
      "opacity": 1,
      "asset": {
        "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e20f6d20-c428-421f-d925-95ebe8430b00/public",
        "type": "image-url"
      }
    },
    "ci1KVK6wAcy9E6HNQAtIV": {
      "strokeWidth": 0,
      "metadata": {
        "subject": "an empty room with the sun shining through the windows"
      },
      "skewX": 0,
      "skewY": 0,
      "type": "StaticImage",
      "scaleX": 1,
      "scaleY": 1,
      "top": -514.58,
      "angle": 0,
      "id": "ci1KVK6wAcy9E6HNQAtIV",
      "flipX": false,
      "flipY": false,
      "height": 1024,
      "zIndex": 0,
      "cropX": 0,
      "generationId": "",
      "cropY": 0,
      "visible": true,
      "originX": "left",
      "originY": "top",
      "left": -2.08,
      "name": "StaticImage",
      "width": 1024,
      "opacity": 1,
      "asset": {
        "path": "sampleProjects/assets/Ho8WTmmVICrybsTCUaUI.png",
        "type": "image-storage",
        // "contentType": "image/png"
      }
    }
  },
  "name": "Untitled Project",
  "generationFrame": {
    "top": -514.5812535077345,
    "left": -2.0763936483465386,
    "referneceImage": {
      "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5da370bb-a1d8-4a53-21fa-ae6557979600/1024",
      "type": "image-url"
    },
    "width": 1024,
    "id": "generation-frame",
    "type": "GenerationFrame",
    "promptTemplate": {
      "words": [
        {
          "isAutoFilled": true,
          "prefix": "",
          "autocompleteType": "subject",
          "type": "input",
          "value": "an empty room with the sun shining through the windows",
          "objectIds": [
            "ci1KVK6wAcy9E6HNQAtIV"
          ]
        },
        {
          "prefix": "in front of",
          "autocompleteType": "background",
          "type": "input",
          "value": "a natural beige background"
        },
        {
          "prefix": "on",
          "autocompleteType": "placement",
          "type": "fixed",
          "value": "circular platforms"
        },
        {
          "isAutoFilled": true,
          "prefix": "with",
          "autocompleteType": "surrounding",
          "type": "input",
          "value": "white bed-side table and wall art and white couch and cockapoo and ceramic vase",
          "objectIds": [
            "QjLSz0dNF1lK9BXPR944H",
            "ZsEwPmnE9hb032NFWUsmk",
            "pqA4BzHAb1Q3CLh-noBqY",
            "d8W49CDyY8f87wLT2DiHP",
            "xFwvIW0onXJGqmof7D5ZQ"
          ]
        },
        {
          "isAutoFilled": true,
          "prefix": "next to",
          "autocompleteType": "surrounding",
          "type": "input",
          "value": "leaves on a stem",
          "objectIds": [
            "2DJYNexn48JcFZ8IE_O2l"
          ]
        }
      ],
      "style": ""
    },
    "height": 1024
  },
  "id": "oQBaWAnhTXI0RTKNJQzV",
  "version": "271",
  "frame": {
    "width": 0,
    "height": 0
  }
}