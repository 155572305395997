import { OBJECT_ROTATION_SNAP_THRESHOLD } from "components/constants/objects";
import { fabric } from "fabric";



export type AddToCanvasArgs = {
    canvas: fabric.Canvas,
}

export type AddObjectToCanvasArgs = AddToCanvasArgs & {
    object: fabric.Object,
}

export function addObjectToCanvas({
    canvas,
    object,
}: AddObjectToCanvasArgs) {
    object.snapAngle = OBJECT_ROTATION_SNAP_THRESHOLD;
    object.perPixelTargetFind = true;

    canvas.add(object);
}

export type AddObjectsToCanvasArgs = AddToCanvasArgs & {
    objects: fabric.Object[],
}

export function addObjectsToCanvas({
    objects,
    ...props
}: AddObjectsToCanvasArgs) {
    objects.forEach(object => (
        addObjectToCanvas({
            ...props,
            object,
        })
    ));
}