import { UserProjectType, UsedUserProjectType } from 'core/common/types';
import { AssetLibraryItem } from "./components/assets-library";

type AssetItem = {
    url: string,
    previewUrl?: string,
    name?: string,
    metadata?: Record<string, string>,
}

export const bottleImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4409ed74-750c-48b8-1dfa-80ddb2d0d600/public',
    metadata: { subject: 'a tube bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/710a30e1-6ba7-4821-129b-145e78c35700/public',
    metadata: { subject: 'a bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/30b2ac1a-0db2-4ceb-b00b-e783242cd900/public',
    metadata: { subject: 'a tube bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0883533-5692-4409-fff5-c5fafedbb600/public',
    metadata: { subject: 'a tube bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/55fbb185-e305-487b-7908-52b948f26e00/public',
    metadata: { subject: 'a skincare bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a51a6d07-174a-4548-a245-fa5780671200/public',
    metadata: { subject: 'a skincare bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab9a17c7-3ae6-4a49-8b90-7329d999d800/public',
    metadata: { subject: 'a tube bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/10439c97-edb1-477a-f17b-efc6e50e6800/public',
    metadata: { subject: 'a tube bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e7aedace-eda8-4cfb-fcb3-c83ee5ca4400/public',
    metadata: { subject: 'a black bottle' }
  }
];
export const accessoryImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3ed14934-c890-45fb-7879-6b0c5c2c3200/public',
    metadata: { subject: 'a nike sneaker' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e55abe57-3231-4c96-a1e5-66f1252adb00/public',
    metadata: { subject: 'a beige sneaker' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a44dcfb1-5b0e-4953-b1a3-047864656400/public',
    metadata: { subject: 'a nike sneaker' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/127a1faa-66ac-4c40-3439-300d3146b400/public',
    metadata: { subject: 'a beige handbag' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/56ad6876-5c28-484b-94e9-d9666efe1c00/public',
    metadata: { subject: 'a handbag' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/350dada3-3a50-4be8-a915-f6e96eb44000/public',
    metadata: { subject: 'a brown handbag' }
  }
];
export const cpgImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/11ac07a0-80bf-414f-e41e-3f25e08c4400/public',
    metadata: { subject: 'an aluminum can' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9daf6002-5ba6-4649-18e8-4ecddd805b00/public',
    metadata: { subject: 'an aluminum can' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9c02b069-cdaa-42c6-1fcc-7b0ddf47ae00/public',
    metadata: { subject: 'an aluminum can' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/14469761-730a-427c-654f-4b5fa60cac00/public',
    metadata: { subject: 'a vitamin bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bc78ea33-19a7-408a-fa9b-f53ee6076c00/public',
    metadata: { subject: 'a vitamin bottle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1445a364-64ad-4188-7e8d-e05e2f84c200/public',
    metadata: { subject: 'a beer can' }
  }
];
export const foodImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7a3377f4-2f90-428e-1643-b5379bd30800/public',
    metadata: { subject: 'a fruit salad breakfast' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/62ed8c3c-0b12-45b1-4246-1bb6bee4e300/public',
    metadata: { subject: 'an avocado toast' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2a80f737-8528-42f5-c917-8eff66162a00/public',
    metadata: { subject: 'a poke bowl' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/45586f5a-72a9-40df-7869-21c3465f0e00/public',
    metadata: { subject: 'a mozarella and tomato salad' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/34e3e85f-ce33-49f1-0626-34c2765bca00/public',
    metadata: { subject: 'a grilled chicken salad' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/12a64963-c9b1-4951-5089-804d71a51000/public',
    metadata: { subject: 'a sushi dish' }
  }
];

export const furnitureImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c2c76412-893f-4f06-4cb1-b03f75bba900/public',
    metadata: { subject: 'a white couch' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3f379c92-d896-4a82-4c96-1dc8e8474f00/public',
    metadata: { subject: 'a fluffy white chair' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a999d0f1-37ca-400c-4da9-19e7698a1400/public',
    metadata: { subject: 'a white bed' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4a99d84a-26b2-4993-d752-02cf3270e200/public',
    metadata: { subject: 'a wooden table' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/44fc084a-9283-4d91-b62f-86c393543300/public',
    metadata: { subject: 'a white chair' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e48050c8-17ef-411f-42e8-64ea3519dd00/public',
    metadata: { subject: 'a vase' }
  },
]

export const stagingImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/364eed1d-2c54-4358-d7cc-595af936b700/public',
    metadata: { subject: 'a white-brick room' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/28265aac-85bd-4343-d34f-d833262eee00/public',
    metadata: { subject: 'a wooden room with natural light ' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cba48995-30be-43fd-ba96-9bbbb0725e00/public',
    metadata: { subject: 'a white room' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/884df2e7-29b8-406d-c5e4-8d09ecbbff00/public',
    metadata: { subject: 'a white room with natural light' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/835a9e4a-6397-44f4-cd9b-c539ba7cee00/public',
    metadata: { subject: 'a sunny room' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b7a56edc-cf75-4fff-0782-a92463a10700/public',
    metadata: { subject: 'a wall with doorway' }
  },
]

export const homegoodsImages: AssetItem[] = [
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fcc44137-acc0-4591-504e-6d537a9cf200/public',
    metadata: { subject: 'a ceramic vase' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5044abe7-152f-4840-b0a6-75c80eabb600/public',
    metadata: { subject: 'a candle' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dcd05f85-59d0-4f60-6e2d-e5b22d5a0f00/public',
    metadata: { subject: 'a vase with flowers' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/aa80d81a-2e8a-405a-b6b0-ef671ce68e00/public',
    metadata: { subject: 'a wooden mug' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2fb4312e-9731-4c05-e7fe-e69b0a2c8900/public',
    metadata: { subject: 'a box of toothbrushes' }
  },
  {
    url: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6f346e7d-fe37-45f3-180d-ad2b861f1a00/public',
    metadata: { subject: 'a candle' }
  },
]

export const AssetList: Record<Partial<UsedUserProjectType>, AssetItem[]> = {
    [UserProjectType.ProductPhotography]: bottleImages,
    [UserProjectType.TryOn]: bottleImages,
    [UserProjectType.Fashion]: bottleImages,
    [UserProjectType.Beauty]: bottleImages,
    [UserProjectType.Accessories]: accessoryImages,
    [UserProjectType.CPG]: cpgImages,
    [UserProjectType.Food]: foodImages,
    [UserProjectType.Furniture]: furnitureImages,
    [UserProjectType.Staging]: stagingImages,
    [UserProjectType.Homegoods]: homegoodsImages,
    [UserProjectType.Humans]: bottleImages,
};

export function getFilteredPresetImages(projectType?: UserProjectType): AssetLibraryItem[] {
    const validProjectType = projectType && projectType in AssetList
        ? projectType as UsedUserProjectType
        : UserProjectType.Beauty;

    return (AssetList[validProjectType] || []) as AssetLibraryItem[];
}