import React from "react";
import { createPortal } from "react-dom";


export const PaintBrushCursor = React.forwardRef(({
    id,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, brushCursorRef: React.ForwardedRef<HTMLDivElement>) => {
    return createPortal(
        <div
            ref={brushCursorRef}
            id='brushcursor'
            {...props}
        />,
        document.body,
    );
});