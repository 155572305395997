import { Backend } from "backend/base";
import { UserProject, UserProjectType } from "core/common/types";
import { AnalyticsConfig } from 'analytics/config';
import { editorContextStore } from "contexts/editor-context";
import { HttpsCallableResult } from "firebase/functions";

function parseCreateNewProjectResponse(response: HttpsCallableResult<UserProject | null>) {
    if (!response || !response.data) {
        return;
    }
    const { id = null } = response.data;

    if (id && typeof (id) === 'string') {

        console.log(`Finish creating new project ${id}`);
        editorContextStore.getState().setProjectId(id);

    }

    return id;
}

export async function backendCreateNewProject({
    displayName,
    projectType,
}: {
    displayName: string,
    projectType: UserProjectType,
}) {
    const {
        backend,
        setIsCreatingNewProject,
    } = editorContextStore.getState();

    if (!backend) {
        return;
    }

    setIsCreatingNewProject(true);

    try {

        const res = await backend?.createNewProject(displayName)

        const projectId = parseCreateNewProjectResponse(res);

        if (projectId) {

            await backend.setProjectType(
                projectId,
                projectType,
            );

        }

    } catch (error) {

        console.warn(error);

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.ProjectCreateError,
            {
                projectId: editorContextStore.getState().projectId,
                name: displayName,
                error,
            }
        );

    }


    setIsCreatingNewProject(false);
}