export function getRootUrl(): string {
    if (!window) {
        return '';
    }
    const url = new URL(window.location.href);
    const protocol = window.location.protocol || 'http:';
    const cleanUrl = `${protocol}//${url.hostname}:${url.port}`;
    return cleanUrl;
}

export function appendPathToRootUrl(pathSegment: string): string {
    const cleanedUrl = getRootUrl();
    if (!cleanedUrl) {
        return '';
    }

    // Ensure the pathSegment doesn't start with a slash to avoid double slashes
    if (pathSegment.startsWith('/')) {
        pathSegment = pathSegment.substring(1);
    }

    const finalUrl = `${cleanedUrl}/${pathSegment}`;
    return finalUrl;
}

export function replaceCloudflareImageUrl({
    cloudflareImageUrl: originalUrl,
    customDomain = "flair.ai",
}: {
    cloudflareImageUrl: string,
    customDomain?: string,
}) {
    try {
        const url = new URL(originalUrl);

        // Ensure the path conforms to the expected pattern
        if (url.hostname !== "imagedelivery.net") {
			return originalUrl;
		}

        // Construct the new URL with the custom domain and cdn-cgi path
        const customDomainUrl = `https://${customDomain}/cdn-cgi/imagedelivery${url.pathname}`;

        return customDomainUrl;
    } catch (error) {
        console.error(error);
    }

    return originalUrl;
}

export function preprocessImageUrl(imageUrl?: string) {
    if (!imageUrl) {
        return undefined;
    }

    if (!imageUrl.startsWith('https://')) {
        return imageUrl;
    }

    return replaceCloudflareImageUrl({
        cloudflareImageUrl: imageUrl,
        customDomain: 'flair.ai',
    })
}