import React from 'react';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { PlusIcon } from '@radix-ui/react-icons';
import { UploadImageFormPopover } from './upload-image';

function UploadButtonTrigger() {
    return (
        <AspectRatio.Root
            ratio={1}
            className="w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-zinc-800 overflow-hidden select-none cursor-pointer hover:text-lime-400 hover:border-lime-500 focus:border-lime-300 focus:outline-none focus-visible:outline-none transition-colors"
        >
            <PlusIcon
                className='mb-2'
                width={23}
                height={23}
            />
            Add Image
        </AspectRatio.Root>
    );
}

export function UploadButton({
    modelId,
    onDelete = () => {},
}: {
    modelId: string,
    onDelete?: (id: string) => void,
}) {
    return (
        <UploadImageFormPopover
            modelId={modelId}
            triggerProps={{
                className: 'focus:outline-none focus:ring focus:ring-zinc-600'
            }}
            onDelete={onDelete}
        >
            <UploadButtonTrigger />
        </UploadImageFormPopover>
    )
}