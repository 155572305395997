import { SegmentEventHandler } from './event-handler/segment';
import { fabric } from "fabric"
import { Base } from "core/controllers/base"
import { ControllerOptions, IShortcutsManager } from "./interfaces"
import { LayerType } from "./layers"
import { defaultControllerOptions, DEFAULT_ZOOM_SENSITIVITY, MouseButton } from "./constants"
import { VisualizeColorMaskHandler } from "./event-handler/visualize-color-mask"
import { SyncEdtorState } from "./event-handler/sync-editor-state"
import { EditorShortcutsManager } from 'core/controllers/shortcuts/editor-shortcuts-manager';
import { SetActiveShortcutsManagerHandler } from './types';

class Events extends Base {


  protected _isMiddleMouseDown = false;

  protected lastPanPos?: fabric.Point;

  protected visualizeColorMask: VisualizeColorMaskHandler;

  protected syncEditorState: SyncEdtorState;

  protected segmentEvents: SegmentEventHandler;

  protected editorShortcutsManager: EditorShortcutsManager;

  protected activeShortcutsManagerRef: {
    current?: IShortcutsManager,
  } = { current: undefined };

  constructor(props: ControllerOptions) {
    super(props)
    this.visualizeColorMask = new VisualizeColorMaskHandler(props);
    this.syncEditorState = new SyncEdtorState(props);
    this.segmentEvents = new SegmentEventHandler(props);
    this.editorShortcutsManager = new EditorShortcutsManager(props);
    this.initialize();
  }

  protected setActiveShortcutsManager = (shortcutsManagerRef: { current?: IShortcutsManager }) => {
    this.activeShortcutsManagerRef.current = shortcutsManagerRef?.current;
  }

  protected initialize() {
    this.canvas.fireMiddleClick = true;
    this.canvas.wrapperEl.tabIndex = 1
    this.canvas.wrapperEl.style.outline = "none"
    // @ts-ignore
    this.canvas.on({
      "mouse:dblclick": this.onDoubleClick,
      "mouse:down": this.onMouseDown,
      "mouse:move": this.onMouseMove,
      "mouse:up": this.onMouseUp,
      "selection:cleared": this.handleSelection,
      "selection:updated": this.handleSelection,
      "mouse:wheel": this.onMouseWheel,
      "mouse:out": this.onMouseOut,
      "object:modified": this.objectModified,
      "background:selected": this.onBackgroundSelected,
    });

    this.canvas.wrapperEl.addEventListener("keydown", this.onKeyDown.bind(this), false);

    this.editor.on<SetActiveShortcutsManagerHandler>(
      'shortcuts-manager:set',
      this.setActiveShortcutsManager,
    );
  }

  public destroy() {
    this.canvas.off({
      "mouse:dblclick": this.onDoubleClick,
      "mouse:down": this.onMouseDown,
      "mouse:up": this.handleSelection,
      "selection:cleared": this.handleSelection,
      "selection:updated": this.handleSelection,
      "mouse:wheel": this.onMouseWheel,
      "mouse:out": this.onMouseOut,
      "object:modified": this.objectModified,
      "background:selected": this.onBackgroundSelected,
    })

    window.removeEventListener("keydown", this.onKeyDown.bind(this));

    this.visualizeColorMask.destroy();
    this.syncEditorState.destroy();
    this.segmentEvents.destroy();

    this.editor.off<SetActiveShortcutsManagerHandler>(
      'shortcuts-manager:set',
      this.setActiveShortcutsManager,
    );
  }

  get isMiddleMouseDown() {
    return this._isMiddleMouseDown;
  }

  protected onDoubleClick = (event: fabric.IEvent<any>) => {
    const subTarget = event.subTargets![0]
    if (subTarget) {
      this.editor.objects.select(subTarget.id)
    }
  }

  static getMousePosFromEvent(e: fabric.IEvent<any>) {
    return new fabric.Point(
      e.e?.clientX || 0,
      e.e?.clientY || 0,
    );
  }

  static detectTrackpadUtil(e: WheelEvent & {
    wheelDeltaY?: number,
    wheelDeltaX?: number,
  }) {
    var isTrackpad = false;
    if (e.wheelDeltaY) {
      if (e.wheelDeltaY === (e.deltaY * -3)) {
        isTrackpad = true;
      }
    } else if (e.deltaMode === 0) {
      isTrackpad = true;
    }

    if (e.wheelDeltaX) {
      if (e.wheelDeltaX === (e.deltaX * -3)) {
        isTrackpad = true;
      }
    } else if (e.deltaMode === 0) {
      isTrackpad = true;
    }

    return isTrackpad;
  }

  protected detectTrackpad(e: WheelEvent & {
    wheelDeltaY?: number,
    wheelDeltaX?: number,
  }) {
    return Events.detectTrackpadUtil(e);
  }

  public onMouseDown = (e: fabric.IEvent<any>) => {
    this.editor.objects.pasteStyle();

    if (e.button === MouseButton.Right) {
      this.state.setContextMenuRequest({ left: e.e.offsetX, top: e.e.offsetY, target: e.target })
    } else if (e.button === MouseButton.Middle) {
      this._isMiddleMouseDown = true;
      this.state.setContextMenuRequest(null);
      this.lastPanPos = Events.getMousePosFromEvent(e);
    } else {
      this.state.setContextMenuRequest(null)
    }
  }

  public onMouseMove = (e: fabric.IEvent<any>) => {
    if (this._isMiddleMouseDown && this.lastPanPos) {
      const viewportTransform = this.canvas.viewportTransform?.slice(0);
      const clientX = e.e?.clientX;
      const clientY = e.e?.clientY;
      if (viewportTransform && clientX != null && clientY != null) {
        viewportTransform[4] += clientX - this.lastPanPos.x;
        viewportTransform[5] += clientY - this.lastPanPos.y;
        this.lastPanPos.x = clientX;
        this.lastPanPos.y = clientY;
        this.editor.zoom.setCanvasViewportTransform(viewportTransform);
        this.canvas.requestRenderAll();
      }
    }
  }

  public onMouseUp = (e: fabric.IEvent) => {
    this.handleSelection(e);
    if (e.button === MouseButton.Middle) {
      this._isMiddleMouseDown = false;
      this.lastPanPos = undefined;
    }
  }

  objectModified = (event: fabric.IEvent) => {
    const { target } = event
    if (target instanceof fabric.Textbox) {
      this.scaleTextbox(target)
    }
    this.editor.history.save()
  }

  onMouseOut = () => {
    this.canvas.renderAll()
  }

  onMouseWheel = (event: fabric.IEvent<any>) => {
    const isCtrlKey = event.e.ctrlKey
    if (isCtrlKey) {
      this.handleZoom(event)
    } else {
      // const isTrackpad = this.detectTrackpad(event.e);
      // if (isTrackpad) {
      // }
      const viewportTransform = this.canvas.viewportTransform;
      const deltaX = event.e?.deltaX;
      const deltaY = event.e?.deltaY;
      if (viewportTransform && deltaX != null && deltaY != null) {
        viewportTransform[4] -= deltaX;
        viewportTransform[5] -= deltaY;
        this.editor.zoom.setCanvasViewportTransform(viewportTransform);
        this.canvas.requestRenderAll();
      }
    }
  }

  handleZoom = (event: fabric.IEvent<any>) => {
    const delta = event.e.deltaY
    let zoomRatio = this.canvas.getZoom()
    if (delta > 0) {
      zoomRatio -= DEFAULT_ZOOM_SENSITIVITY
    } else {
      zoomRatio += DEFAULT_ZOOM_SENSITIVITY
    }
    this.editor.zoom.zoomToPoint(new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2), zoomRatio)
    event.e.preventDefault()
    event.e.stopPropagation()
  }

  onKeyDown(event: KeyboardEvent) {
    let isHandled = false;
    // By default, use the active shortcuts manager
    if (this.activeShortcutsManagerRef.current) {

      const handleKeyDownResult = this.activeShortcutsManagerRef.current.handleKeyDown(event);
      isHandled = handleKeyDownResult.isHandled;

    }
    if (isHandled) {
      return;
    }
    // Fallback to the default editor shortcuts manager
    return this.editorShortcutsManager.handleKeyDown(event);
  }

  onBackgroundSelected = () => {
    const objects = this.canvas.getObjects()
    const frame = objects[0]
    this.canvas.setActiveObject(objects[0])
    this.state.setActiveObject(frame)
    this.canvas.requestRenderAll()
  }

  handleSelection = (target: fabric.IEvent) => {
    if (target) {
      this.state.setActiveObject(null)
      const initialSelection = this.canvas.getActiveObject() as any
      const isNotMultipleSelection =
        (initialSelection && initialSelection.type === LayerType.GROUP.toLowerCase()) ||
        (initialSelection && initialSelection.type === LayerType.STATIC_VECTOR)

      if (initialSelection && !isNotMultipleSelection && initialSelection._objects) {
        const filteredObjects = (initialSelection._objects as fabric.Object[]).filter((object) => {
          if (object.type === LayerType.BACKGROUND) {
            return false
          }
          return !object.locked
        })
        this.canvas.discardActiveObject()
        if (filteredObjects.length > 0) {
          if (filteredObjects.length === 1) {
            this.canvas.setActiveObject(filteredObjects[0])
            this.state.setActiveObject(filteredObjects[0])
          } else {
            const activeSelection = new fabric.ActiveSelection(filteredObjects, {
              ...defaultControllerOptions,
              canvas: this.canvas,
            }) as fabric.Object
            this.canvas.setActiveObject(activeSelection)
            this.state.setActiveObject(activeSelection)
          }
        }
      } else {
        this.state.setActiveObject(initialSelection)
      }
    } else {
      this.state.setActiveObject(null)
    }
    this.canvas.requestRenderAll()
  }

  scaleTextbox = (target: fabric.Textbox) => {
    const { fontSize, width, scaleX } = target
    target.set({
      fontSize: fontSize! * scaleX!,
      width: width! * scaleX!,
      scaleX: 1,
      scaleY: 1,
    })
  }
}

export default Events
