import { Backend } from "backend/base";
import { editorContextStore } from "contexts/editor-context";
import { useProjectInitSceneContext } from "contexts/project-init-scene-context";
import { EditorStatus } from "core/common/constants";
import { isTemporaryProjectId } from "core/utils/project-utils";
import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Editor } from "./canvas-editor";
import EditorLoading from "./editor-loading";
import { UserProjectType, getUserProjectType } from "core/common/types";

async function doesUserHaveAccessToProject(
    backend?: Backend,
    currentUserId?: string,
    localProjectId?: string,
) {
    if (backend && currentUserId && localProjectId) {
        const projectUsers = await backend.getProjectUsers(localProjectId);
        return projectUsers?.[currentUserId] != null;
    }
    return false;
}

function handleCreateNewTemporaryProject() {
    const {
        projectType,
        setActiveLeftPanels,
    } = editorContextStore.getState();

    if (projectType === UserProjectType.TryOn) {
        setActiveLeftPanels(['TryOnUploadCloth']);
    } else if (projectType === UserProjectType.ProductPhotography) {
        setActiveLeftPanels(['Generate']);
    }
}

export function EditorRouter() {
    const params = useParams();
    const { state } = useLocation();
    const [editorStatus, setEditorStatus] = React.useState<EditorStatus>(EditorStatus.Loading);
    const [localProjectId, setLocalProjectId] = React.useState(params?.projectId);
    const [isTempProject, setIsTempProject] = React.useState(((state as any)?.isTempProject || false) && isTemporaryProjectId(localProjectId));
    const user = editorContextStore(state => state.user);
    const backend = editorContextStore(state => state.backend);
    const { initScene } = useProjectInitSceneContext();

    React.useEffect(() => {
        setIsTempProject(((state as any)?.isTempProject || false) && isTemporaryProjectId(localProjectId));
    }, [state, localProjectId]);

    // Make sure that the user has the access to the project
    React.useEffect(() => {
        if (!localProjectId) {
            setEditorStatus(EditorStatus.Loading);
        } else if (isTempProject) {
            setEditorStatus(EditorStatus.Authorized);
            console.log(`Log into temporary project ${localProjectId}`);
            handleCreateNewTemporaryProject();
        } else if (!user) {
            setEditorStatus(EditorStatus.Loading);
            const timeoutId = setTimeout(() => {
                console.log('User is not logged in');
                setEditorStatus(EditorStatus.PermissionDenied);
            }, 1500);
            return () => {
                clearTimeout(timeoutId);
            };
        } else {
            doesUserHaveAccessToProject(
                backend,
                user?.uid,
                localProjectId,
            )?.then((isAuthorized) => {
                if (isAuthorized) {
                    setEditorStatus(EditorStatus.Authorized);
                } else {
                    console.log(`User ${user.uid} is not authorized to the project ${localProjectId}`);
                    setEditorStatus(EditorStatus.PermissionDenied);
                }
            });
        }
    }, [user, backend, localProjectId, isTempProject]);

    // When the firebase project id updates, we update the project id as well
    const projectId = editorContextStore(state => state.projectId);
    React.useEffect(() => {
        if (projectId && isTempProject) {
            console.log(`Change to new project id ${projectId} from firebase project`);
            setLocalProjectId(projectId);
            setIsTempProject(false);
            // Modify the route
            window.history.replaceState(null, '', `/projects/${projectId}`);
        }
    }, [isTempProject, projectId]);


    React.useEffect(() => {
        const projectType = getUserProjectType(state?.projectType) || UserProjectType.ProductPhotography;

        editorContextStore.getState().setProjectType(projectType);

        return () => {
            editorContextStore.getState().setProjectType(undefined);
        }

    }, [state?.projectType]);

    if (editorStatus === EditorStatus.PermissionDenied) {
        return <Navigate to="/login" />;
    }

    if (editorStatus === EditorStatus.Loading) {
        return <EditorLoading />;
    }
    if (!localProjectId) {
        return null;
    }

    return <Editor
        initScene={initScene}
    />;
}