import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { InputBoxClassName } from 'components/constants/class-names';
import CustomModelsMentionsPlugin, { correctCustomModelPromptEditorStateDisplayNames, getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState, MentionNode } from './custom-model-mention-plugin';
import { classNames } from 'core/utils/classname-utils';
import { SerializedEditorState } from 'lexical';
import { CustomModelPlaygroundPromptEditorState, CustomModelSetPromptEditorStateEventHandler } from 'core/common/types';
import { editorContextStore } from 'contexts/editor-context';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Backend } from 'backend/base';

async function correctCustomModelPromptEditorStateJsonDisplayNames({
    backend,
    promptEditorStateJson,
}: {
    backend: Backend,
    promptEditorStateJson: string,
}) {
    try {
        const promptEditorState = await correctCustomModelPromptEditorStateDisplayNames({
            backend,
            promptEditorState: JSON.parse(promptEditorStateJson),
        });

        return JSON.stringify(promptEditorState);

    } catch (error) {
        debugError('Cannot parse prompt editor json: ', promptEditorStateJson, 'Error:\n', error);
    }
}

interface CustomModelPromptEditorProps {
    editorState: CustomModelPlaygroundPromptEditorState;
    setEditorState: (editorState: CustomModelPlaygroundPromptEditorState) => void;
}

function CustomModelSetPromptEditorStateEventHandlerPlugin({
    initPromptEditorStateJson,
}: {
    initPromptEditorStateJson?: string | null,
}): JSX.Element | null {
    const backend = editorContextStore(state => state.backend);

    const eventEmitter = editorContextStore(state => state.eventEmitter);

    const [editor] = useLexicalComposerContext();

    const handleCustomModelSetPromptEditorStateEvent: CustomModelSetPromptEditorStateEventHandler['handler'] = React.useCallback(({
        promptEditorStateJson,
    }) => {
        try {
            if (!backend) {
                return;
            }

            correctCustomModelPromptEditorStateJsonDisplayNames({
                backend,
                promptEditorStateJson,
            }).then((correctedPromptEditorStateJson) => {
                if (!correctedPromptEditorStateJson) {
                    return;
                }

                editor.setEditorState(
                    editor.parseEditorState(correctedPromptEditorStateJson),
                );
            });

        } catch (error) {
            debugError('Cannot parse prompt editor json: ', promptEditorStateJson, '\nError:\n', error);
        }
    }, [editor, backend]);

    React.useEffect(() => {


        eventEmitter.on<CustomModelSetPromptEditorStateEventHandler>(
            'custom-model:set-prompt-editor-state',
            handleCustomModelSetPromptEditorStateEvent,
        );

        return () => {
            eventEmitter.off<CustomModelSetPromptEditorStateEventHandler>(
                'custom-model:set-prompt-editor-state',
                handleCustomModelSetPromptEditorStateEvent,
            );
        }

    }, [handleCustomModelSetPromptEditorStateEvent, eventEmitter]);

    React.useEffect(() => {
        if (!initPromptEditorStateJson) {
            return;
        }

        handleCustomModelSetPromptEditorStateEvent({
            promptEditorStateJson: initPromptEditorStateJson,
        });
    }, []);


    return null;
}

export function CustomModelPromptEditor({
    editorState,
    setEditorState,
}: CustomModelPromptEditorProps) {
    return (
        <LexicalComposer
            initialConfig={{
                editorState: null,
                editable: true,
                namespace: 'CustomModelPromptEditor',
                onError: (error) => {
                    debugError(error);
                },
                nodes: [
                    MentionNode,
                ],
            }}
        >
            <RichTextPlugin
                contentEditable={(
                    <ContentEditable
                        className={classNames(
                            InputBoxClassName,
                            "min-h-[68px]"
                        )}
                        spellCheck={true}
                    />
                )}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <OnChangePlugin
                onChange={(editorState) => {
                    const promptEditorState = editorState.toJSON() as SerializedEditorState;

                    const newPromptEditorState = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                        promptEditorState,
                    });
                    setEditorState(newPromptEditorState);
                }}
            />
            <CustomModelsMentionsPlugin/>
            <CustomModelSetPromptEditorStateEventHandlerPlugin initPromptEditorStateJson={editorState.json}/>
        </LexicalComposer>
  );
}
