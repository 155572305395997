import React from 'react';
import { PrismAsyncLight as SyntaxHighlighter, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import clike from 'react-syntax-highlighter/dist/esm/languages/prism/clike';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import atomDarkStyle from 'react-syntax-highlighter/dist/esm/styles/prism/atom-dark';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { deepMerge } from 'core/utils/object-utils';

export enum ReactSyntaxHighlighterLanguage {
    CLike = 'clike',
    Javascript = 'javascript',
    Typescript = 'typescript',
    Python = 'python',
    Bash = 'bash',
}

export enum ReactSyntaxHighlighterStyle {
    AtomDark = 'atom-dark',
    VscDarkPlus = 'vscDarkPlus'
}

SyntaxHighlighter.registerLanguage(ReactSyntaxHighlighterLanguage.CLike, clike);
SyntaxHighlighter.registerLanguage(ReactSyntaxHighlighterLanguage.Javascript, javascript);
SyntaxHighlighter.registerLanguage(ReactSyntaxHighlighterLanguage.Typescript, typescript);
SyntaxHighlighter.registerLanguage(ReactSyntaxHighlighterLanguage.Python, python);
SyntaxHighlighter.registerLanguage(ReactSyntaxHighlighterLanguage.Bash, bash);

function getLanguageStyle(style: ReactSyntaxHighlighterStyle) {
    if (style === ReactSyntaxHighlighterStyle.AtomDark) {
        return atomDarkStyle;
    }
    if (style === ReactSyntaxHighlighterStyle.VscDarkPlus) {
        return vscDarkPlus;
    }
}

export type ReactSyntaxHighlighterProps = SyntaxHighlighterProps & {
    highlighterStyle?: ReactSyntaxHighlighterStyle,
}

export const ReactSyntaxHighlighter = ({
    style = {},
    highlighterStyle = ReactSyntaxHighlighterStyle.AtomDark,
    children,
    ...props
}: ReactSyntaxHighlighterProps) => {

    const languageStyle = React.useMemo(() => getLanguageStyle(highlighterStyle), [highlighterStyle]);

    return (
        <SyntaxHighlighter
            style={languageStyle && deepMerge(
                languageStyle,
                style,
            )}
            {...props}
        >
            {children}
        </SyntaxHighlighter>
    );
}