import React from "react";
import { RemoveObjectLoadingCover, SetObjectLoadingCover } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { ObjectLoadingCover, ObjectLoadingCoverProps } from "components/utils/object-loading-cover";
import { isFabricObject } from "core/utils/type-guards";
import { debugLog } from "core/utils/print-utilts";

export function ObjectLoadingCovers() {
    const editor = editorContextStore(state => state.editor);
    const [covers, setCovers] = React.useState<Record<string, ObjectLoadingCoverProps>>({});

    React.useEffect(() => {
        if (!editor) {
            return;
        }

        const handleObjectRemoved = (e: fabric.IEvent<Event>) => {
            const object = e.target;
            if (!object) {
                return;
            }

            const objectId = object.id;

            setCovers((prevCovers) => {
                if (!prevCovers?.[objectId]) {
                    return prevCovers;
                }
                const {
                    [objectId]: _,
                    ...rest
                } = prevCovers;
                return rest;
            });
        };

        editor.canvas.canvas.on('object:removed', handleObjectRemoved);

        const handleSetObjectLoadingCover = ({
            objectId,
            message,
            callback,
        }: {
            objectId: string,
            message?: string,
            callback?: () => Promise<void>,
        }) => {
            debugLog(`Set object ${objectId} loading cover with message ${message}`);

            const object = editor?.objects.findOneById(objectId);
            if (!object || !isFabricObject(object)) {
                return;
            }
            setCovers(covers => {
                const oldCover = covers[objectId] ?? {};

                return {
                    ...covers,
                    [objectId]: {
                        ...oldCover,
                        object,
                        message: message ?? oldCover.message,
                    },
                };
            });

            callback?.()
                .catch(console.error)
                .finally(() => {
                    setCovers(covers => {
                        if (!covers[objectId]) {
                            return covers;
                        }
                        const {[objectId]: _, ...rest} = covers;
                        return rest;
                    });
                });
        }

        const handleRemoveObjectLoadingCover = ({
            objectId
        }: {
            objectId: string,
        }) => {
            setCovers((prevCovers) => {
                if (!prevCovers?.[objectId]) {
                    return prevCovers;
                }
                const {
                    [objectId]: _,
                    ...rest
                } = prevCovers;
                return rest;
            });
        };

        editor.on<SetObjectLoadingCover>(
            'object:set-object-loading-cover',
            handleSetObjectLoadingCover,
        );

        editor.on<RemoveObjectLoadingCover>(
            'object:remove-object-loading-cover',
            handleRemoveObjectLoadingCover,
        );

        return () => {
            setCovers({});
            editor.canvas.canvas.off('object:removed', handleObjectRemoved);
            editor.off<SetObjectLoadingCover>(
                'object:set-object-loading-cover',
                handleSetObjectLoadingCover,
            );
            editor.off<RemoveObjectLoadingCover>(
                'object:remove-object-loading-cover',
                handleRemoveObjectLoadingCover,
            );
        }
    }, [editor]);

    return (
        <>
            {Object.entries(covers).map(([objectId, props]) => props?.object && (
                <ObjectLoadingCover
                    key={objectId}
                    {...props}
                />
            ))}
        </>
    );
}