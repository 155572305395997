import React from 'react';
import { editorContextStore } from 'contexts/editor-context';
import { canUserCreateCustomModel } from 'core/utils/custom-model-utils';
import { canRoleWrite } from 'core/common/types';


export function useCustomModelsEffect() {
    const backend = editorContextStore(state => state.backend);
    const publicUserId = editorContextStore(state => state.publicUserId);
    const setCustomModels = editorContextStore(state => state.setCustomModels);

    React.useEffect(() => {
        if (!publicUserId) {
            return;
        }
        if (!backend) {
            return;
        }
        return backend?.onUserCustomModelsUpdate(
            publicUserId,
            setCustomModels,
        );
    }, [backend, publicUserId, setCustomModels]);
}

export function useCanUserCreateCustomModel() {
    const userQuotas = editorContextStore(state => state.userQuotas);

    return React.useMemo(() => {
        return canUserCreateCustomModel({
            userQuotas,
        });
    }, [userQuotas]);
}

export function usePublicCustomModelsEffect() {
    const backend = editorContextStore(state => state.backend);
    const setPublicCustomModels = editorContextStore(state => state.setPublicCustomModels);

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        backend.getPublicCustomModels()
            .then((models) => {
                setPublicCustomModels(
                    Object.fromEntries(models.map((model) => [model.id, model]))
                )
            });
    }, [backend, setPublicCustomModels]);
}

export function useCanUserEditCustomModel() {
    const publicUserId = editorContextStore(state => state.publicUserId);
    const customModelInfo = editorContextStore(state => state.customModelInfo);

    return React.useMemo(() => {
        if (!publicUserId) {
            return false;
        }

        const roles = customModelInfo?.roles;

        if (!roles) {
            return false;
        }

        const userRole = roles[publicUserId];

        if (!userRole) {
            return false;
        }

        return canRoleWrite(userRole);
    }, [
        publicUserId,
        customModelInfo,
    ]);
}