import React from "react"
import { fabric } from "fabric"
import { classNames } from "core/utils/classname-utils";
import { DropdownClassName, InputBoxClassName, InputBoxClassNameError, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameError, PrimaryButtonClassNameLoading, SecondaryButtonClassNameInactive, TextButtonClassName } from "components/constants/class-names";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { CheckIcon, Cross1Icon, Cross2Icon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import styles from './message-dialog-content.module.css';
import { clamp, isError } from "lodash";
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { editorContextStore } from "contexts/editor-context";
import { getImageCaption, getImageClothMultiCategory, getImageUrlForClassification } from "components/utils/image-classification";
import { GeneralObjectCategory, TryOnModelPreviewDataTags, clothFitQueryConstraints, clothFitToQueryConstraint, clothSleeveQueryConstraints, clothSleeveToQueryConstraint } from "core/common/types";
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useTryOnPoseLibraryFilterToggleGroupValue } from "components/utils/tryon-pose-filter";
import { displayUiMessage } from "components/utils/display-message";
import { isStaticImageObject } from "core/utils/type-guards";
import { getDataUrlFromImageElement, updateImageSubjectCaption } from "core/utils/image-utils";
import { ProgressHandler } from "components/utils/progress-handler";
import { ImageComponent } from "components/utils/image";

const tagClassName = 'inline px-1 rounded-md bg-zinc-800 border border-zinc-700';

const buttonCommonClassName = 'min-w-[100px] ml-2 flex items-center justify-center';

function ActionButton({
    onClick,
    children,
    className,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
    return (
        <button
            {...props}
            onClick={onClick}
            className={classNames(
                PrimaryButtonClassName,
                buttonCommonClassName,
                className ?? '',
            )}
        >
            {children}
        </button>
    );
}

function LoadingButton() {
    return (
        <button
            className={classNames(
                PrimaryButtonClassNameLoading,
                buttonCommonClassName,
            )}
        >
            <SimpleSpinner
                width={20}
                height={20}
                pathClassName='fill-lime-100'
            />
        </button>
    )
}

function ErrorButton() {
    return (
        <button
            className={classNames(
                PrimaryButtonClassNameError,
                buttonCommonClassName,
            )}
        >
            Error
        </button>
    )
}

function DoneButton({
    message,
}: {
    message: string,
}) {
    return (
        <button
            className={PrimaryButtonClassNameDisabled}
        >
            {message}
        </button>
    )
}

type SecondaryButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function SecondaryButton({
    className,
    children,
    ...props
}: SecondaryButtonProps) {
    return (
        <button
            {...props}
            className={classNames(
                SecondaryButtonClassNameInactive,
                buttonCommonClassName,
                className ?? '',
            )}
        >
            {children}
        </button>
    )
}

type PrimaryButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isLoading?: boolean,
    isDone?: boolean,
    isError?: boolean,
    doneMessage?: string,
}

function PrimaryButton({
    children,
    doneMessage = 'Done',
    isLoading = false,
    isError = false,
    isDone = false,
    ...props
}: PrimaryButtonProps) {
    return (
        isError ?
            <ErrorButton /> :
            isDone ?
                <DoneButton message={doneMessage} /> :
                isLoading ?
                    <LoadingButton /> :
                    <ActionButton {...props}>
                        {children}
                    </ActionButton>
    );
}

function ImagePreview({
    name,
    image,
    className,
    progress = 0,
    imageUrl,
    imageNoBgUrl,
    ...props
}: AspectRatio.AspectRatioProps & React.RefAttributes<HTMLDivElement> & {
    name?: string,
    progress?: number,
    image?: fabric.StaticImage | HTMLImageElement,
    imageUrl?: string,
    imageNoBgUrl?: string,
}) {

    const imageSrc = React.useMemo(() => (
        imageUrl || (isStaticImageObject(image) ? image.getSrc() : image?.src)
    ), [image, imageUrl]);

    const imageName = React.useMemo(() => name || image?.name || "", [name, image]);

    return (
        <AspectRatio.Root
            {...props}
            ratio={16 / 9}
            className={classNames(
                "w-full relative flex items-center justify-center rounded-md overflow-hidden border border-zinc-800 select-none drag-none",
                className ?? '',
            )}
        >
            <ImageComponent
                alt={imageName}
                src={imageSrc}
                className={classNames(
                    "absolute h-full transition-opacity",
                    imageNoBgUrl ? 'opacity-50 hover:opacity-0' : 'opacity-100',
                )}
            />
            <ImageComponent
                alt={`${imageName}-no-bg`}
                src={imageNoBgUrl}
                className="absolute h-full transition-opacity pointer-events-none"
                style={{
                    opacity: imageNoBgUrl ? 1 : 0,
                }}
            />
            {progress > 0 && <div
                className='absolute left-0 bottom-0 w-full h-1 bg-zinc-800'
            >
                <div
                    className={`${styles.TransitionWidth} rounded-full h-full bg-lime-500`}
                    style={{
                        width: `${clamp(progress * 100, 0, 100)}%`,
                    }}
                />
            </div>}
        </AspectRatio.Root>
    );
}

function SubjectInput({
    image,
}: {
    image: fabric.StaticImage,
}) {
    const [value, setValue] = React.useState('');
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState();

    const valueRef = React.useRef("");

    React.useEffect(() => {
        valueRef.current = value;
    }, [value]);

    const updateSubjectCaption = React.useCallback((value: string) => {
        updateImageSubjectCaption(image, value);
    }, [image]);

    const setSubject = React.useCallback(() => {
        if (!value || isError) {
            return;
        }

        updateSubjectCaption(value);
    }, [value, isError, updateSubjectCaption]);

    React.useEffect(() => {
        if (valueRef.current) {
            return;
        }

        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        const imageUrl = image.getSrc();

        if (!imageUrl) {
            return;
        }

        backend.getImageCaption({
            imageUrl,
            extractSubject: true,
        }).then((caption) => {
            const newValue = valueRef.current || caption;

            setValue(newValue);

            updateSubjectCaption(newValue);
        });

    }, [image, updateSubjectCaption]);

    React.useEffect(() => {
        setIsError(!Boolean(value));
    }, [value]);

    return (
        <>
            <div className="w-full flex flex-row items-center">
                <label htmlFor="dialog-subject-input" className="flex-1 py-2 mr-4 truncate">
                    Describe the product in the image:
                </label>
                <input
                    id='dialog-subject-input'
                    value={value}
                    onChange={(e) => {
                        setValue(e.currentTarget.value);
                    }}
                    onBlur={setSubject}
                    className={classNames(
                        isError ? InputBoxClassNameError : InputBoxClassName,
                        'flex-1'
                    )}
                />
            </div>
            <div className="w-full py-2 my-4 bg-black/30 rounded-md flex flex-col text-zinc-400">
                {errorMessage && <div className="w-full px-3 pb-2 border-b border-zinc-900 text-red-500 trucate">
                    {errorMessage}
                </div>}
                <div className="w-full px-3 py-2 flex flex-col">
                    <div className="w-full pb-1 flex flex-row items-center">
                        <span className="p-1 mr-2 text-lime-300 bg-lime-800 rounded-full">
                            <CheckIcon />
                        </span>
                        <div className="flex-1">
                            <span className="font-semibold">Do:</span> Describe only the product in a word or two.
                        </div>
                    </div>
                    <div className="w-full pl-[31px]">
                        e.g. <span className={tagClassName}>bottle</span>, <span className={tagClassName}>box</span>, <span className={tagClassName}>soda can</span>, etc.
                    </div>
                    <div className="h-2" />
                    <div className="w-full pb-1 flex flex-row items-center">
                        <span className="p-1 mr-2 text-red-300 bg-red-800 rounded-full">
                            <Cross2Icon />
                        </span>
                        <div className="flex-1">
                            <span className="font-semibold">Don't:</span> Describe everything in the image, or something irrelevant.
                        </div>
                    </div>
                    <div className="w-full pl-[31px]">
                        e.g. <span className={tagClassName}>picture of bottle on a table</span>, <span className={tagClassName}>soda on a beach</span>, <span className={tagClassName}>put the box on a desk</span>, etc.
                    </div>
                </div>
            </div>
        </>
    )
}

function Header({
    className,
    children,
    onClose,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    onClose: () => void,
}) {
    return (
        <div
            {...props}
            className={classNames(
                "mb-6 w-full flex flex-row items-center",
                className ?? '',
            )}
        >
            <span className="flex-1 text-lg truncate font-semibold">
                {children}
            </span>
            <Cross2Icon
                width={18}
                height={18}
                className={TextButtonClassName}
                onClick={onClose}
            />
        </div>
    )
}

function NavigationButtons({
    isEnd,
    secondaryButtonChildren = 'Previous',
    primaryButtonChildren = 'Continue',
    primaryButtonProps,
    secondaryButtonProps,
}: {
    isEnd: boolean,
    secondaryButtonChildren?: React.ReactNode,
    primaryButtonChildren?: React.ReactNode,
    primaryButtonProps: PrimaryButtonProps,
    secondaryButtonProps: SecondaryButtonProps,
}) {
    primaryButtonChildren = isEnd ? 'Done' : primaryButtonChildren;
    return (
        <div className="w-full flex flex-row items-center">
            <div className="flex-1" />
            {!isEnd &&
                <SecondaryButton
                    {...secondaryButtonProps}
                >
                    {secondaryButtonChildren}
                </SecondaryButton>
            }
            <PrimaryButton
                {...primaryButtonProps}
            >
                {primaryButtonChildren}
            </PrimaryButton>
        </div>
    )
}

export type MessageDialogContentCaptionInnerProps = {
    type: 'caption-subject',
    step?: number,
    isEnd?: boolean,
    image: fabric.StaticImage,
    imageUrl?: string,
    setOpen: (value: boolean) => void,
    isLoading?: boolean,
    onClickNext: () => void,
    onClickPrev: () => void,
}

function MessageDialogContentCaptionInner({
    step = 0,
    isEnd = false,
    image,
    imageUrl,
    setOpen,
    isLoading = false,
    onClickNext,
    onClickPrev,
}: MessageDialogContentCaptionInnerProps) {
    return (
        <>
            <Header
                onClose={() => setOpen(false)}
            >
                Step {step}: Describe the product
            </Header>
            <div className="flex-1 w-full overflow-x-hidden overflow-y-auto">
                <div className="w-full flex flex-col text-sm">
                    <ImagePreview
                        key={image.id}
                        name={'Uploaded Image'}
                        image={image}
                        imageUrl={imageUrl}
                    />
                </div>
            </div>
            <div className="w-full h-4" />
            <SubjectInput
                image={image}
            />
            <NavigationButtons
                isEnd={isEnd}
                primaryButtonProps={{
                    isLoading,
                    onClick: onClickNext,
                }}
                secondaryButtonProps={{
                    onClick: onClickPrev,
                }}
            />
        </>
    );
}

export type MessageDialogContentRemoveBackgroundInnerProps = {
    type: 'remove-background',
    step?: number,
    isEnd?: boolean,
    image: fabric.StaticImage,
    setOpen: (value: boolean) => void,
    isLoading: boolean,
    setIsLoading: (value: boolean) => void,
    onClickNext: () => void,
    onClickPrev: () => void,
    progress?: number,
    imageNoBgUrl?: string,
};

export function MessageDialogContentRemoveBackgroundInner({
    step = 0,
    isEnd = false,
    image,
    setOpen,
    isLoading,
    setIsLoading,
    onClickPrev,
    onClickNext,
    progress,
    imageNoBgUrl,
}: MessageDialogContentRemoveBackgroundInnerProps) {

    return (
        <>
            <Header
                onClose={() => setOpen(false)}
            >
                Step {step}: Remove Background?
            </Header>
            <div className="flex-1 w-full overflow-x-hidden overflow-y-auto">
                <div className="w-full flex flex-col text-sm">
                    <ImagePreview
                        key={image.id}
                        name={'Uploaded Image'}
                        image={image}
                        progress={progress}
                        imageNoBgUrl={imageNoBgUrl}
                    />
                </div>
            </div>
            <div className="w-full my-4 text-zinc-400">
                Click <span className="text-zinc-200">"Remove"</span> to remove the background. <br />
                Click <span className="text-zinc-200">"Skip"</span> to go to the next step without removing the background.
            </div>
            <NavigationButtons
                isEnd={isEnd}
                secondaryButtonProps={{
                    onClick: onClickPrev,
                }}
                secondaryButtonChildren="Skip"
                primaryButtonProps={{
                    isLoading,
                    onClick: onClickNext,
                }}
                primaryButtonChildren='Remove'
            />
        </>
    )
}

export type MessageDialogContentCaptionInnerV2Props = {
    type: 'caption-subject-v2',
    step?: number,
    isEnd?: boolean,
    setOpen: (value: boolean) => void,
    onClickNext: () => void,
    onClickPrev: () => void,
    setCaption: (value: string) => void,
    imageUrl?: string,
}

export function MessageDialogContentCaptionV2Inner({
    step = 0,
    isEnd = false,
    imageUrl,
    setOpen,
    setCaption,
    onClickNext,
    onClickPrev,
}: MessageDialogContentCaptionInnerV2Props) {
    const [value, setValue] = React.useState('');

    const valueRef = React.useRef("");

    React.useEffect(() => {
        valueRef.current = value;
    }, [value]);

    React.useEffect(() => {
        if (!imageUrl) {
            return;
        }

        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        if (valueRef.current) {
            return;
        }

        backend.getImageCaption({
            imageUrl,
            extractSubject: true,
        }).then((caption) => {
            const newValue = valueRef.current || caption || "";

            setValue(newValue);

            setCaption(newValue);
        });

    }, [
        imageUrl,
        setCaption,
    ]);

    return (
        <>
            <Header
                onClose={() => setOpen(false)}
            >
                Step {step}: Describe the product
            </Header>
            <div className="flex-1 w-full overflow-x-hidden overflow-y-auto">
                <div className="w-full flex flex-col text-sm">
                    <ImagePreview
                        name={'Uploaded Image'}
                        imageUrl={imageUrl}
                    />
                </div>
            </div>
            <div className="w-full h-4" />
            <div
                className="w-full flex flex-row items-center"
            >
                <label
                    htmlFor="dialog-subject-input-v2"
                    className="flex-1 py-2 mr-4 truncate"
                >
                    Describe the product in the image:
                </label>
                <input
                    id="dialog-subject-input-v2"
                    value={value}
                    onChange={(e) => {
                        setValue(e.currentTarget.value);
                    }}
                    onBlur={() => {
                        setCaption(value);
                    }}
                    className={classNames(
                        "flex-1",
                        InputBoxClassName,
                    )}
                />
            </div>
            <div className="w-full h-4" />
            <NavigationButtons
                isEnd={isEnd}
                primaryButtonProps={{
                    onClick: onClickNext,
                }}
                secondaryButtonProps={{
                    onClick: onClickPrev,
                }}
            />
        </>
    )
}

export type MessageDialogContentRemoveBackgroundV2InnerProps = {
    type: 'remove-background-v2',
    step?: number,
    isEnd?: boolean,
    setOpen: (value: boolean) => void,
    onClickNext: () => void,
    onClickPrev: () => void,
    imageUrl?: string,
    imageNoBgUrl: string | undefined,
    setImageNoBgUrl: (value: string) => void,
}

export function MessageDialogContentRemoveBackgroundV2Inner({
    step = 0,
    isEnd = false,
    setOpen,
    onClickNext,
    onClickPrev,
    imageUrl,
    imageNoBgUrl,
    setImageNoBgUrl,
}: MessageDialogContentRemoveBackgroundV2InnerProps) {
    // Call remove background backend API

    const [isLoading, setIsLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        if (!imageUrl) {
            return;
        }

        const {
            editor,
            backend,
        } = editorContextStore.getState();

        if (!editor || !backend) {
            return;
        }

        setIsLoading(true);
        const progressHandler = new ProgressHandler({
            setProgress: setProgress,
        });
        progressHandler.setProgress(0.1);

        backend.removeBackground({
            imageUrl,
            onError: (error) => {
                console.error(error);
                displayUiMessage(error.message, 'error');
                setIsLoading(false);
            }
        }).then((outputUrl) => {
            if (!outputUrl) {
                return;
            }

            setImageNoBgUrl(outputUrl);
        }).finally(() => {
            setIsLoading(false);
            progressHandler.setProgress(1.0);
        });
    }, [
        imageUrl,
        setImageNoBgUrl,
    ]);

    return (
        <>
            <Header
                onClose={() => setOpen(false)}
            >
                Step {step}: Remove Background?
            </Header>
            <div className="flex-1 w-full overflow-x-hidden overflow-y-auto">
                <div className="w-full flex flex-col text-sm">
                    <ImagePreview
                        name={'Uploaded Image'}
                        imageUrl={imageUrl}
                        progress={progress}
                        imageNoBgUrl={imageNoBgUrl}
                    />
                </div>
            </div>
            <div className="w-full my-4 text-zinc-400">
                Click <span className="text-zinc-200">"Remove"</span> to remove the background. <br />
                Click <span className="text-zinc-200">"Skip"</span> to go to the next step without removing the background.
            </div>
            <NavigationButtons
                isEnd={isEnd}
                secondaryButtonProps={{
                    onClick: onClickPrev,
                }}
                secondaryButtonChildren="Skip"
                primaryButtonProps={{
                    isLoading,
                    onClick: onClickNext,
                }}
                primaryButtonChildren='Remove'
            />
        </>
    )
}

export type MessageDialogContentCategorizeClothInnerProps = {
    type: 'categorize-cloth',
    step?: number,
    isEnd?: boolean,
    image: HTMLImageElement,
    setOpen: (value: boolean) => void,
    onClickNext: () => void,
    onClickPrev: () => void,
};

function ClothCategoryToggleGroupToggleButton({
    className = "",
    children,
    ...props
}: ToggleGroup.ToggleGroupItemProps & React.RefAttributes<HTMLButtonElement>) {
    return (
        <ToggleGroup.Item
            className={classNames(
                styles.ToggleGroupItem,
                "flex flex-items items-center justify-center px-3 py-2 text-sm rounded transition-colors shadow",
                className,
            )}
            {...props}
        >
            {children}
        </ToggleGroup.Item>
    )
}

function ClothCategoryToggleGroup({
    tags,
}: {
    tags: { [key in TryOnModelPreviewDataTags]?: string },
}) {

    const {
        value,
        onValueChange,
    } = useTryOnPoseLibraryFilterToggleGroupValue({
        tags,
    });

    return (
        <ToggleGroup.Root
            className="w-full grid grid-rows-1 grid-flow-col auto-cols-fr gap-2"
            type="single"
            value={value}
            onValueChange={onValueChange}
        >
            {Object.entries(tags).map(([tagKey, tagName]) => (
                <ClothCategoryToggleGroupToggleButton
                    key={tagKey}
                    value={tagKey}
                >
                    {tagName}
                </ClothCategoryToggleGroupToggleButton>
            ))}
        </ToggleGroup.Root>
    )
}

export function MessageDialogContentCategorizeClothInner({
    step = 0,
    isEnd = false,
    image,
    setOpen,
    onClickNext,
    onClickPrev,
}: MessageDialogContentCategorizeClothInnerProps) {

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const [prompt, setPrompt] = React.useState("");
    const promptRef = React.useRef(prompt);

    const tryOnClothPrompt = editorContextStore(state => state.tryOnClothPrompt);

    const handleInputBlur = React.useCallback(() => {
        const {
            setTryOnClothPrompt,
        } = editorContextStore.getState();

        setTryOnClothPrompt(prompt);
    }, [prompt]);

    React.useEffect(() => {
        promptRef.current = prompt;
    }, [prompt]);

    React.useEffect(() => {
        setPrompt(tryOnClothPrompt);
    }, [tryOnClothPrompt]);

    React.useEffect(() => {

        const imageElement = image;

        if (!imageElement) {
            return;
        }

        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        // Get image url

        getImageUrlForClassification(imageElement as HTMLImageElement).then((imageUrl) => {
            if (!imageUrl) {
                return;
            }

            // Caption image

            getImageCaption({
                image: imageUrl,
                backend,
            }).then((caption) => {

                const prompt = promptRef.current;

                if (prompt && prompt.length > 0) {
                    return;
                }

                const {
                    setTryOnClothPrompt,
                } = editorContextStore.getState();

                caption = caption || "";

                setTryOnClothPrompt(caption);

                setPrompt(caption);

            }).catch(console.error);



            // Categorize image

            getImageClothMultiCategory({
                image: imageUrl,
                backend,
            }).then(({
                generalCategory,
                sleeveCategory,
                fitCategory,
            }) => {

                console.log(generalCategory);

                if (generalCategory !== GeneralObjectCategory.cloth) {
                    setErrorMessage(`Only upper-body cloth is supported at the moment.`);
                }


                const {
                    setTryOnModelPreviewFilterContraints,
                } = editorContextStore.getState();

                const sleeveConstraint = sleeveCategory ? clothSleeveToQueryConstraint[sleeveCategory] : undefined;

                const fitConstraint = fitCategory ? clothFitToQueryConstraint[fitCategory] : undefined;

                setTryOnModelPreviewFilterContraints((prevConstraints) => {
                    const constraints = prevConstraints.filter(constraint => (
                        constraint &&
                        !clothSleeveQueryConstraints.has(constraint as any) &&
                        !clothFitQueryConstraints.has(constraint as any)
                    ));

                    if (sleeveConstraint) {
                        constraints.push(sleeveConstraint);
                    }

                    if (fitConstraint) {
                        constraints.push(fitConstraint);
                    }

                    return constraints;
                });
            }).catch(console.error);
        })


    }, [
        image,
    ]);

    return (
        <>
            <Header
                onClose={() => setOpen(false)}
            >
                Step {step}: Upload Image
            </Header>
            <div className="flex-1 flex flex-col w-full overflow-x-hidden overflow-y-auto">
                <div className="relative w-full flex flex-col text-sm">
                    <ImagePreview
                        key={image.id}
                        name={'Uploaded Image'}
                        image={image}
                    />
                    <div
                        className={classNames(
                            "absolute w-full flex items-center justify-center bottom-0 pointer-events-none",
                            errorMessage ? "" : "hidden"
                        )}
                    >
                        <div
                            className={classNames(
                                DropdownClassName,
                                "flex flex-row items-center justify-center m-2",
                            )}
                        >
                            <ExclamationTriangleIcon
                                className="mr-2 text-red-500"
                            />
                            <div
                                className="flex-1 min-w-0 truncate"
                            >
                                {errorMessage}
                            </div>
                            <Cross1Icon
                                className="ml-2 text-zinc-500 pointer-events-auto hover:text-zinc-300 cursor-pointer transition-colors"
                                onClick={() => setErrorMessage(undefined)}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="w-full flex flex-col mt-4"
                >
                    <div className="w-full flex flex-row items-center justify-center mb-4">
                        <div className="flex-1 min-w-0 truncate text-zinc-300 font-semibold">
                            Describe the Cloth:
                        </div>
                        <div className="w-[60%]">
                            <input
                                className={classNames(
                                    InputBoxClassName,
                                )}
                                value={prompt}
                                onChange={(e) => setPrompt(e.currentTarget.value)}
                                onBlur={handleInputBlur}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-row items-center justify-center mb-4">
                        <div className="flex-1 min-w-0 truncate text-zinc-300 font-semibold">
                            Sleeve Type:
                        </div>
                        <div className="w-[60%]">
                            <ClothCategoryToggleGroup
                                tags={{
                                    'long-sleeve_shirt': "Long Sleeve",
                                    "short-sleeve_shirt": "Short Sleeve",
                                    "off-shoulder_top": "No Sleeve",
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-row items-center justify-center mb-4">
                        <div className="flex-1 min-w-0 truncate text-zinc-300 font-semibold">
                            Cloth Fit:
                        </div>
                        <div className="w-[60%]">
                            <ClothCategoryToggleGroup
                                tags={{
                                    'loose-fit': "Loose Fit",
                                    "tight-fit": "Tight Fit",
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full h-[100px]" />
                </div>
            </div>
            <NavigationButtons
                isEnd={isEnd}
                secondaryButtonProps={{
                    onClick: onClickPrev,
                }}
                secondaryButtonChildren="Skip"
                primaryButtonProps={{
                    onClick: onClickNext,
                }}
                primaryButtonChildren='Upload'
            />
        </>
    )
}

export type MessageDialogContentInnerProps = MessageDialogContentCaptionInnerProps | MessageDialogContentRemoveBackgroundInnerProps | MessageDialogContentCategorizeClothInnerProps | MessageDialogContentRemoveBackgroundV2InnerProps | MessageDialogContentCaptionInnerV2Props;

export function MessageDialogContentInner(props: MessageDialogContentInnerProps) {
    const type = props.type;
    if (type === 'remove-background') {
        return (
            <MessageDialogContentRemoveBackgroundInner {...props} />
        )
    }
    if (type === 'caption-subject') {
        return (
            <MessageDialogContentCaptionInner {...props} />
        )
    }
    if (type === 'categorize-cloth') {
        return (
            <MessageDialogContentCategorizeClothInner {...props} />
        );
    }
    if (type === 'remove-background-v2') {
        return (
            <MessageDialogContentRemoveBackgroundV2Inner {...props} />
        );
    }
    if (type === 'caption-subject-v2') {
        return (
            <MessageDialogContentCaptionV2Inner {...props} />
        );
    }
    return null;
}