import React from "react";
import { ApiInputType } from "core/common/types/api";
import { classNames } from "core/utils/classname-utils";

import * as Tooltip from '@radix-ui/react-tooltip';
import type { ApiInputProps } from './api-input';
import { isDataURL, isValidHttpsUrl } from "core/utils/string-utils";
import { DropdownClassName } from "components/constants/class-names";

function DescriptionTooltipContainer({
    children,
    description = null,
}: {
    description?: React.ReactNode,
    children: React.ReactNode,
}) {
    if (!description) {
        return (
            <>
                {children}
            </>
        );
    }
    return (
        <Tooltip.Root>
            <Tooltip.Trigger>
                {children}
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    side='top'
                    align="start"
                    sideOffset={6}
                    className={classNames(
                        DropdownClassName,
                        'bg-zinc-950 text-sm max-w-[80vw] md:max-w-[35vw] 2xl:max-w-[1000px] leading-relaxed',
                    )}
                >
                    {description}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    )
}

export const InputLabel = React.forwardRef((
    {
        required = false,
        missingRequired = false,
        children,
        className = "",
        description = null,
        ...props
    }: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        required?: boolean,
        missingRequired?: boolean,
        description?: React.ReactNode,
    },
    forwarededRef: React.ForwardedRef<HTMLLabelElement>,
) => {
    return (
        <DescriptionTooltipContainer
            description={description}
        >
            <label
                {...props}
                ref={forwarededRef}
                className={classNames(
                    "group flex flex-row items-center justify-start gap-4 min-h-[24px]",
                    className,
                )}
            >
                {children}
                {required && (
                    <div
                        className={classNames(
                            "p-1 text-xs select-none rounded transition-colors",
                            missingRequired ? "bg-red-800 text-red-200" : "bg-zinc-800 text-zinc-500",
                        )}
                    >
                        required
                    </div>
                )}
                <div className="flex-1 min-w-0" />
                {description && (
                    <div className="hidden xl:block xl:max-w-[20vw] truncate select-none text-xs text-zinc-600 group-hover:text-zinc-500 transition-colors">
                        {description}
                    </div>
                )}
            </label>
        </DescriptionTooltipContainer>
    )
})

export function isApiInputValueMissing(props: ApiInputProps) {

    if (props.value === null || props.value === undefined) {
        return true;
    }

    if (props.type === ApiInputType.Text) {
        return !Boolean(props.value);
    } else if (props.type === ApiInputType.Number) {
        return typeof (props.value) !== 'number';
    } else if (props.type === ApiInputType.Slider) {
        return typeof (props.value) !== 'number';
    } else if (props.type === ApiInputType.Options) {

        const options = Object.values(props.options).reduce<string[]>((prev, v) => {
            prev.push(...v.map(({ value }) => value));
            return prev;
        }, []);

        return !options.includes(props.value);
    } else if (props.type === ApiInputType.Image) {
        return !(isDataURL(props.value) || isValidHttpsUrl(props.value));
    }

    return true;
}