import { StateUpdater } from "./utils";
import { SetEditorStateFunction, getUpdaterFunction, } from "contexts/editor-context-utils";


export interface MagicEraseEditorState {
    magicEraseRefineStrength: number,
    setMagicEraseRefineStrength: (value: StateUpdater<number>) => void,
    magicEraseFillContent: boolean,
    setMagicEraseFillContent: (value: StateUpdater<boolean>) => void,
    magicErasePrompt: string,
    setMagicErasePrompt: (value: StateUpdater<string>) => void,
}


const noop = () => { };

export function getDummyMagicEraseEditorState(): MagicEraseEditorState {
    return {
        magicEraseRefineStrength: 0,
        setMagicEraseRefineStrength: noop,
        magicEraseFillContent: false,
        setMagicEraseFillContent: noop,
        magicErasePrompt: "",
        setMagicErasePrompt: noop,
    };
}

export function getDefaultMagicEraseEditorState(set: SetEditorStateFunction): MagicEraseEditorState {
    return {
        magicEraseRefineStrength: 0,
        setMagicEraseRefineStrength: getUpdaterFunction(set, 'magicEraseRefineStrength'),
        magicEraseFillContent: false,
        setMagicEraseFillContent: getUpdaterFunction(set, 'magicEraseFillContent'),
        magicErasePrompt: "",
        setMagicErasePrompt: getUpdaterFunction(set, "magicErasePrompt"),
    };
}