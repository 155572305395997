import React from "react";
import { ApiInputType } from "core/common/types/api";
import { classNames } from "core/utils/classname-utils";
import { InputBoxClassName, InputBoxClassNameError } from "components/constants/class-names";
import { InputLabel } from "./input-utils";


export type TextInputProps = {
    type: ApiInputType.Text,
    id: string,
    name: React.ReactNode,
    description?: React.ReactNode,
    value: string,
    onValueChange: (value: string) => void,
    required?: boolean,
}


export function TextInput({
    id,
    name,
    value,
    description,
    onValueChange,
    required = false,
}: TextInputProps) {
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    const missingRequired = required && !Boolean(inputValue);

    return (
        <div className="flex flex-col gap-2">
            <InputLabel
                htmlFor={id}
                required={required}
                missingRequired={missingRequired}
                description={description}
            >
                {name}
            </InputLabel>
            <input
                id={id}
                required={required}
                className={classNames(
                    missingRequired ? InputBoxClassNameError : InputBoxClassName,
                )}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.currentTarget.value);
                }}
                onBlur={() => {
                    onValueChange(inputValue);
                }}
            />
        </div>
    );
}



export type NumberInputProps = {
    type: ApiInputType.Number,
    id: string,
    name: React.ReactNode,
    description?: React.ReactNode,
    value: number,
    onValueChange: (value: number) => void,
    parseStringValue?: (value: string) => number,
    required?: boolean,
}


export function NumberInput({
    id,
    name,
    value,
    description,
    onValueChange: handleValueChange,
    parseStringValue = parseFloat,
    required = false,
}: NumberInputProps) {
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    const missingRequired = required && !Boolean(inputValue);

    const onValueChange = React.useCallback((inputValue: string | number) => {
        const value = typeof (inputValue) === 'number' ? inputValue : parseStringValue(inputValue);
        handleValueChange(value);
        setInputValue(value.toString());
    }, [
        handleValueChange,
        parseStringValue,
    ]);

    return (
        <div className="flex flex-col gap-2">
            <InputLabel
                htmlFor={id}
                required={required}
                missingRequired={missingRequired}
                description={description}
            >
                {name}
            </InputLabel>
            <input
                id={id}
                type="number"
                required={required}
                className={classNames(
                    missingRequired ? InputBoxClassNameError : InputBoxClassName,
                )}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.currentTarget.value);
                }}
                onBlur={() => {
                    onValueChange(parseStringValue(inputValue));
                }}
            />
        </div>
    );
}

