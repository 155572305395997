import { fabric } from "fabric";
import { Scene3dData } from "./3d";
import { DownloadImageMetadata, } from "./download-image";
import { isStaticImageObject } from "core/utils/type-guards";
import { Editor } from "core/editor";
import { ColorCorrectV2ImageMetadata } from "./color-correct-v2";

export enum StaticImageElementType {
    Hed = 'hed',
    Color = 'color',
    ThreeD = '3d',
    Subject = 'subject',
}




export const staticImageElementTypeToDisplayName = {
    [StaticImageElementType.Hed]: "Keep shape",
    [StaticImageElementType.Color]: "Keep color",
    [StaticImageElementType.ThreeD]: "3D",
    [StaticImageElementType.Subject]: "Asset",
}

export function getStaticImageElementType(
    object: any,
) {
    if (!isStaticImageObject(object)) {
        return;
    }

    return object.metadata?.imageType || StaticImageElementType.Subject;
}

export enum StaticImageElementColorDisplayType {
    Alpha = 'alpha',
    RGB = 'rgb',
    ShapeOnly = 'shape-only',
    ColorAndShape = 'color-and-shape',
}

export enum StaticImageElement2dType {
    Asset = 'Preserve all details',
    PreserveColorAndShape = 'Keep color & shape',
    PreserveShape = 'Blend to Scene',
    PreserveColor = 'Generate Variation',

}

export function getStaticImageElement2dType(
    object: fabric.Object | fabric.ActiveSelection,
): StaticImageElement2dType | undefined {
    if (!isStaticImageObject(object)) {
        return;
    }

    const imageType = object.metadata?.imageType || StaticImageElementType.Subject;

    if (imageType === StaticImageElementType.Subject) {
        return StaticImageElement2dType.Asset;
    } else if (imageType === StaticImageElementType.Color) {
        return StaticImageElement2dType.PreserveColor;
    } else if (imageType === StaticImageElementType.ThreeD) {
        return StaticImageElement2dType.PreserveShape;
    } else if (imageType === StaticImageElementType.Hed) {
        const colorDisplayType = object.metadata?.colorDisplayType;

        if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
            return StaticImageElement2dType.PreserveShape;
        } else if (colorDisplayType === StaticImageElementColorDisplayType.RGB) {
            return StaticImageElement2dType.PreserveColor;
        } else if (colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly) {
            return StaticImageElement2dType.PreserveShape;
        } else if (colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape) {
            return StaticImageElement2dType.PreserveColorAndShape;
        }

    }
}

export function setStaticImageElement2dType(
    editor: Editor | null,
    object: fabric.Object | fabric.ActiveSelection,
    type: StaticImageElement2dType,
) {
    if (!isStaticImageObject(object)) {
        return;
    }

    const imageType = object.metadata?.imageType || StaticImageElementType.Subject;

    if (imageType === StaticImageElementType.ThreeD) {

        // Do not modify 3D image element type

        return;
    }

    if (type === StaticImageElement2dType.Asset) {
        setStaticImageElementType({
            object,
            imageType: StaticImageElementType.Subject,
            editor,
        });
    } else if (type === StaticImageElement2dType.PreserveColor) {
        setStaticImageElementType({
            object,
            imageType: StaticImageElementType.Color,
            editor,
        });
    } else if (type === StaticImageElement2dType.PreserveShape) {
        setStaticImageElementType({
            object,
            imageType: StaticImageElementType.Hed,
            colorDisplayType: StaticImageElementColorDisplayType.ShapeOnly,
            editor,
        });
    } else if (type === StaticImageElement2dType.PreserveColorAndShape) {
        setStaticImageElementType({
            object,
            imageType: StaticImageElementType.Hed,
            colorDisplayType: StaticImageElementColorDisplayType.ColorAndShape,
            editor,
        });
    }
}

export type StaticImageElementPromptMetadata = {
    placementPrefix?: string,
    placement?: string,
    surroundingPrefix?: string,
    surrounding?: string,
    backgroundPrefix?: string,
    background?: string,
    subject?: string,
}

export type StaticImage3dElementBaseMetadata = StaticImageElementPromptMetadata & DownloadImageMetadata & ColorCorrectV2ImageMetadata & {
    imageId?: string,
}

export type StaticImage3dElementMetadata = StaticImage3dElementBaseMetadata & {
    imageType: StaticImageElementType.ThreeD,
    scene3dData: Scene3dData,
}

export type StaticImageHedElementMetadata = StaticImage3dElementBaseMetadata & {
    imageType: StaticImageElementType.Hed,
    colorDisplayType: StaticImageElementColorDisplayType,
    hedUrl?: string,
};

export type StaticImageColorElementMetadata = StaticImage3dElementBaseMetadata & {
    imageType: StaticImageElementType.Color,
    colorDisplayType: StaticImageElementColorDisplayType,
};

export type StaticImageSubjectElementMetadata = StaticImage3dElementBaseMetadata & {
    imageType: StaticImageElementType.Subject,
}

export type StaticImageElementMetadata = StaticImage3dElementMetadata | StaticImageHedElementMetadata | StaticImageColorElementMetadata | StaticImageSubjectElementMetadata;

export function getDefaultStaticImageElementMetadata(
    imageType: StaticImageElementType,
): StaticImageElementMetadata {
    if (imageType === StaticImageElementType.Hed) {
        return {
            imageType: StaticImageElementType.Hed,
            colorDisplayType: StaticImageElementColorDisplayType.Alpha,
        };
    } else if (imageType === StaticImageElementType.Color) {
        return {
            imageType: StaticImageElementType.Color,
            colorDisplayType: StaticImageElementColorDisplayType.RGB,
        };
    } else if (imageType === StaticImageElementType.Subject) {
        return {
            imageType,
        };
    }
    return {
        imageType: StaticImageElementType.Subject,
    };
}

export function setStaticImageElementType({
    object,
    imageType,
    editor,
    ...metadata
}: Partial<StaticImageElementMetadata> & {
    object: fabric.Object | fabric.ActiveSelection,
    editor?: Editor | null,
}) {
    if (!imageType) {
        return;
    }

    if (!isStaticImageObject(object)) {
        return;
    }

    if (!editor) {

        object.metadata = {
            ...object.metadata,
            ...getDefaultStaticImageElementMetadata(imageType),
            ...metadata,
        };

        return;
    }

    editor.objects.update(
        {
            metadata: {
                ...getDefaultStaticImageElementMetadata(imageType),
                ...metadata,
            } as any,
        },
        object.id,
    );
}