import React from 'react';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { classNames } from 'core/utils/classname-utils';
import { editorContextStore } from 'contexts/editor-context';
import { preprocessImageUrl } from 'core/utils/url-utils';
import { CustomModelPredictionInputBackendType, CustomModelTrainingStatus, getDisplayNameFromId, getModelTrainingMentionName, isCustomModelTrainingStatusActive, UiDisplayMessageEventHandler } from 'core/common/types';
import { CustomModelPromptEditor } from './custom-model-prompt-editor';
import { customModelPlaygroundGenerateImageConfig, CustomModelPlaygroundResultTab, CustomModelPlaygroundStatus, isCustomModelPlaygroundResultTab, useCustomModelPlayground } from './custom-model-playground-context';
import * as Dialog from '@radix-ui/react-dialog';
import styles from './custom-model.module.css';
import { CustomModelPlaygroundPromptEditorZIndex, FloatTagZIndex } from 'components/constants/zIndex';
import { getObjectEntries } from 'core/utils/type-utils';
import { ApiInput } from 'components/dashboard/api/api-input';
import { Download, Settings, TriangleAlert } from 'lucide-react';
import { Cross1Icon } from '@radix-ui/react-icons';
import { downloadImageDataUrl } from 'components/utils/data';
import { debugLog } from 'core/utils/print-utilts';
import { Assets } from 'core/controllers/assets';
import { CustomModelPredictions } from './custom-model-predictions';
import * as Tabs from '@radix-ui/react-tabs';
import StickyBox from 'components/utils/sticky-box';
import { sortByTimeModified } from 'core/utils/time-utils';

const PreviewImageContainerClassName = "flex items-center justify-center border border-zinc-800 bg-cover bg-center rounded-lg overflow-hidden";

const CommonButtonClassName = "px-4 py-2 flex flex-row items-center justify-center gap-2";

function SubmitButton() {
    const {
        status,
        setStatus,
        apiState,
        setOutputImages,
        setPredictionId,
    } = useCustomModelPlayground();

    const isIdle = status === CustomModelPlaygroundStatus.Idle;

    const customModelTrainings = editorContextStore(state => state.customModelTrainings);

    const hasSuccessfulTraining = React.useMemo(() => {
        return Object.values(customModelTrainings)
            .find(training => training.status === CustomModelTrainingStatus.Succeeded) != null;
    }, [customModelTrainings]);

    return (
        <button
            className={classNames(
                isIdle ?
                    hasSuccessfulTraining ?
                        PrimaryButtonClassName :
                        PrimaryButtonClassNameDisabled :
                    PrimaryButtonClassNameLoading,
                    CommonButtonClassName,
            )}
            onClick={() => {
                if (status !== CustomModelPlaygroundStatus.Idle) {
                    return;
                }

                const {
                    backend,
                    eventEmitter,
                } = editorContextStore.getState();

                if (!backend) {
                    return;
                }

                if (!hasSuccessfulTraining) {
                    eventEmitter.emit<UiDisplayMessageEventHandler>(
                        'ui:display-message',
                        'info',
                        'Please train a custom model first before generating images. '
                    );
                    return;
                }

                const {
                    width,
                    height,
                    guidanceScale,
                    numInferenceSteps,
                    promptEditorState,
                    numImages,
                } = apiState;


                const {
                    text,
                    json,
                    scaleConfigs,
                } = promptEditorState;

                if (text.length <= 0) {
                    return;
                }

                setStatus(CustomModelPlaygroundStatus.Rendering);

                setOutputImages([]);

                backend.startCustomModelPrediction({
                    input: {
                        backendType: CustomModelPredictionInputBackendType.Fal,
                        scaleConfigs,
                        prompt: text,
                        promptJson: json ?? undefined,
                        image_size: {
                            width,
                            height,
                        },
                        num_images: numImages,
                        num_inference_steps: numInferenceSteps,
                        guidance_scale: guidanceScale,
                    }
                }).then((response) => {
                    if (response.ok) {
                        setPredictionId(response.predictionId);
                    } else {
                        if (!response.message?.includes('quota left')) {
                            eventEmitter.emit<UiDisplayMessageEventHandler>(
                                'ui:display-message',
                                'error',
                                response.message,
                            );
                        }

                        setStatus(CustomModelPlaygroundStatus.Idle);
                    }
                });
            }}
        >
            {!isIdle && <SimpleSpinner
                width={16}
                height={16}
                pathClassName='fill-lime-500'
            />}
            <span>
                Generate
            </span>
        </button>
    );
}

function ConfigureButton() {
    const {
        apiState,
        setApiState,
    } = useCustomModelPlayground();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <button
                    className={classNames(
                        SecondaryButtonClassNameInactive,
                        CommonButtonClassName,
                    )}
                >
                    <Settings size='18' />
                    <span>
                        Configure
                    </span>
                </button>
            </Dialog.Trigger>
            <Dialog.Portal>
            <Dialog.Overlay
                    className={styles.DialogOverlay}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                />
                <Dialog.Content
                    className={classNames(
                        styles.DialogContent,
                        DropdownClassName,
                        "py-2 w-[90vw] md:w-[50vw] lg:w-[600px] rounded-xl text-sm",
                    )}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                >
                    <div className='mb-4 flex flex-row items-center text-base'>
                        <Dialog.Title
                            className="flex-1 text-zinc-500 font-semibold truncate"
                        >
                            Configure Playground
                        </Dialog.Title>
                        <Dialog.Close
                            className='text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer'
                        >
                            <Cross1Icon/>
                        </Dialog.Close>
                    </div>
                    <div className='pb-1 flex flex-col items-stretch gap-4'>
                        {getObjectEntries(
                            customModelPlaygroundGenerateImageConfig,
                        ).map(([key, props]) => (
                            <ApiInput
                                {...props}
                                key={key}
                                value={apiState[key] as any}
                                onValueChange={(value: unknown) => {
                                    setApiState((prevState) => {
                                        return {
                                            ...prevState,
                                            [key]: value,
                                        }
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

function PastGenerations() {

    return (
        <div
            className="flex flex-col items-stretch justify-center gap-4"
        >
            <CustomModelPredictions/>
        </div>
    );
}

function Ouptut() {
    const {
        status,
        apiState,
        outputImages,
    } = useCustomModelPlayground();

    const isRendering = React.useMemo(() => status === CustomModelPlaygroundStatus.Rendering, [status]);

    const {width, height} = apiState;

    return (
        <div
            className="flex flex-col items-stretch justify-center gap-4"
        >
            <div className={classNames(
                'grid auto-rows-auto gap-2 md:gap-4',
                apiState.numImages > 1 ? 'grid-cols-2' : 'grid-cols-1',
            )}>
                {Array.from(
                    Array(apiState.numImages).keys()
                ).map((index) => (
                    <AspectRatio.Root
                        key={index}
                        ratio={width / height}
                        className={classNames(
                            PreviewImageContainerClassName,
                            "relative",
                        )}
                        style={{
                            backgroundImage: outputImages[index] ? `url(${preprocessImageUrl(outputImages[index])})` : undefined,
                        }}
                    >
                    {
                        isRendering ? (
                            <div
                                className="flex flex-col items-center gap-4 justify-center text-zinc-500"
                            >
                                <SimpleSpinner
                                    width={23}
                                    height={23}
                                    pathClassName="fill-lime-500"
                                />
                                <span className="truncate">
                                    Processing ... Please wait up to 1 minute.
                                </span>
                            </div>
                        ) : (
                            !outputImages?.[index] && <div
                                className="hidden sm:flex max-w-[80%] flex-row items-center gap-4 justify-center text-zinc-500 text-center"
                            >
                                No output yet! Press the "Submit" button to test your custom model.
                            </div>
                        )
                    }
                    {outputImages?.[index] && <button
                        className={classNames(
                            SecondaryButtonClassNameInactive,
                            "absolute right-0 bottom-0 m-4 hover:bg-lime-500 active:bg-lime-300 hover:text-zinc-900 border-none hover:border-none shadow-xl",
                        )}
                        onClick={() => {
                            const url = outputImages[index];
                            downloadImageDataUrl(
                                url,
                                `output-${index}`,
                            );
                        }}
                    >
                        <Download size={18}/>
                    </button>}
                    </AspectRatio.Root>
                ))}
            </div>
        </div>
    )
}

const triggerClassName = classNames(
    styles.TabsTrigger,
    'px-6 py-2 md:min-w-[4rem] xl:min-w-[8rem] transition-colors text-center',
);

function CustomModelPlaygroundPromptMessage() {
    const {
        apiState,
    } = useCustomModelPlayground();

    const model = editorContextStore(state => state.customModelInfo);

    const trainings = editorContextStore(state => state.customModelTrainings);

    const message = React.useMemo(() => {
        const successfulTrainings = Object.values(trainings ?? {})
            .filter(training => training.status === CustomModelTrainingStatus.Succeeded)
            .sort(sortByTimeModified);

        if (successfulTrainings.length <= 0) {
            return "Please train a model, before trying to generate an image.";
        }

        const training = successfulTrainings[0];

        const promptEditorState = apiState.promptEditorState;

        const text = promptEditorState.text;

        if (!text) {
            return "Please use the prompt editor to describe the image you want to see generated.";
        }

        const hasMentionedCustomModel = Object.keys(promptEditorState.scaleConfigs).length > 0;

        const modelId = model?.displayName ?? model?.id ?? training.modelId ?? '';

        const modelDisplayName = model?.displayName ?? getDisplayNameFromId(modelId);

        if (!hasMentionedCustomModel) {
            const mentionName = getModelTrainingMentionName({
                modelDisplayName,
                training,
            });

            return `Type ${mentionName} with detailed description to generate accurate images.`;
        }

        return "";
    }, [apiState, trainings, model]);

    return (
        <div
            className={classNames(
                'absolute top-[-38px] max-h-[30px] flex flex-row items-center bg-lime-900/20 border border-lime-900/30 p-2 py-1 rounded-lg shadow-md text-lime-400 text-sm font-semibold transition-opacity',
                message ? 'opacity-100' : 'opacity-0',
            )}
        >
            <TriangleAlert size={18} className='mr-2' />
            <div className='flex-1 truncate'>
                {message}
            </div>
        </div>
    )
}

export function CustomModelPlaygroundEditor({
    modelId,
}: {
    modelId: string,
}) {

    const backend = editorContextStore(state => state.backend);

    const {
        apiState,
        setApiState,
        setStatus,
        setOutputImages,
        predictionId,
        resultTab,
        setResultTab,
    } = useCustomModelPlayground();

    React.useEffect(() => {
        if (!backend || !predictionId) {
            return;
        }

        return backend.onCustomModelPredictionUpdate({
            predictionId,
            callback: (prediction) => {
                debugLog(`Prediction ${predictionId} updated: `, prediction);

                if (prediction.output) {

                    Promise.all(
                        prediction.output
                        .map((path) => Assets.loadAssetFromPath({
                            path,
                            backend,
                        }))
                    ).then((output) => {
                        setOutputImages(output.filter(Boolean) as string[]);
                    });

                }

                const isPredictionActive = isCustomModelTrainingStatusActive(prediction.status);

                setStatus(
                    isPredictionActive ?
                        CustomModelPlaygroundStatus.Rendering :
                        CustomModelPlaygroundStatus.Idle
                );
            },
        });
    }, [
        backend,
        predictionId,
        setStatus,
        setOutputImages,
    ]);

    return (
        <div
            className='flex flex-col gap-2'
        >
            <Tabs.Root
                value={resultTab}
                onValueChange={(tab) => {
                    if (isCustomModelPlaygroundResultTab(tab)) {
                        setResultTab(tab);
                    }
                }}
                className='w-full min-h-0 flex-1 flex flex-col'
            >
                <StickyBox
                    style={{
                        zIndex: CustomModelPlaygroundPromptEditorZIndex,
                    }}
                    className='bg-zinc-900 mb-4'
                >
                    <form
                        className='relative mt-[48px] flex flex-col gap-2 text-sm'
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <CustomModelPlaygroundPromptMessage/>
                        <div className='flex flex-row gap-2'>
                            <CustomModelPromptEditor
                                editorState={apiState.promptEditorState}
                                setEditorState={(editorState) => setApiState((apiState) => ({
                                    ...apiState,
                                    promptEditorState: editorState,
                                }))}
                            />
                            <div className='flex flex-col gap-2'>
                                <SubmitButton/>
                                <ConfigureButton/>
                            </div>
                        </div>
                    </form>
                    <Tabs.List
                        className='h-[42px] w-full text-md font-semibold border-b border-zinc-800 bg-zinc-900'
                    >
                        <Tabs.Trigger value={CustomModelPlaygroundResultTab.Output} className={triggerClassName}>
                            Output
                        </Tabs.Trigger>
                        <Tabs.Trigger value={CustomModelPlaygroundResultTab.PastGenerations} className={triggerClassName}>
                            Past Generations
                        </Tabs.Trigger>
                    </Tabs.List>
                </StickyBox>
                <Tabs.Content
                    value={CustomModelPlaygroundResultTab.Output}
                    className='w-full min-h-0 flex-1 focus:outline-none'
                >
                    <Ouptut/>
                </Tabs.Content>
                <Tabs.Content
                    value={CustomModelPlaygroundResultTab.PastGenerations}
                    className='w-full min-h-0 flex-1 focus:outline-none'
                >
                    <PastGenerations/>
                </Tabs.Content>
            </Tabs.Root>
        </div>
    );
}