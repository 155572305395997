import React from "react"
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { ArrowRight, Check, Filter, Plus } from "lucide-react";
import { AssetLibraryItem } from "./components/assets-library";
import { classNames } from "core/utils/classname-utils";
import { Navigate } from "./components/navigate";
import { LeftPanelSectionContainer } from "./base";
import { PresetImageGridItem } from "./components/preset-image-grid";
import { setTryOnPersonImageElementFromImageId } from "contexts/tryon-editor-context";
import { Tooltip, TooltipProps } from "../../utils/tooltip";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { TryOnExitClothEditorButton } from "./components/tryon-panel-button";
import {
    DocsBatchGenerator,
    TryOnModelPreviewData,
    TryOnModelPreviewDataTopFitTypes,
    TryOnModelPreviewDataNeckTypes,
    TryOnModelPreviewDataTopTuckTypes,
    TryOnModelPreviewDataTopTypes,
    TryOnModelPreviewDataDressCodeTypes,
    TryOnModelPreviewDataBodyType,
    TryOnModelPreviewFilterQueryConstraints,
    TryOnModelPreviewDataTags,
} from "core/common/types";
import { useInView } from "react-intersection-observer";
import { mergeRefs } from "components/utils/merge-refs";
import { FloatTagZIndex } from "components/constants/zIndex";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownBase } from "components/utils/dropdown-base";
import { ListFilter } from "components/icons/list-filter";
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import styles from './tryon-select-pose.module.css';
import { useTryOnPoseLibraryFilterToggleGroupValue } from "components/utils/tryon-pose-filter";
import { StaticImageElementType } from "core/common/types/elements";
import { ImageComponent } from "components/utils/image";

type TryOnAssetLibraryItem = AssetLibraryItem & {
    modelId: string,
    modelPreviewData: {
        topFitTypes: TryOnModelPreviewDataTopFitTypes,
        topNeckType: TryOnModelPreviewDataNeckTypes,
        topTuckType: TryOnModelPreviewDataTopTuckTypes,
        topType: TryOnModelPreviewDataTopTypes,
        dresscodeType: TryOnModelPreviewDataDressCodeTypes,
        bodyType: TryOnModelPreviewDataBodyType,
    }
}

function getAssetItemFromModelPreviewData(data: TryOnModelPreviewData): TryOnAssetLibraryItem {
    const imageId = data.imageId;
    const url = data.imageUrl;
    const previewUrl = data.imageUrls.find(url => url.endsWith("/256")) || url;
    const modelId = data.modelId;
    const {
        topFitTypes,
        topNeckType,
        topTuckType,
        topType,
        dresscodeType,
        bodyType,
    } = data;
    return {
        url,
        previewUrl,
        metadata: {
            imageType: StaticImageElementType.Subject,
            imageId,
        },
        name: imageId,
        modelId,
        modelPreviewData: {
            topFitTypes,
            topNeckType,
            topTuckType,
            topType,
            dresscodeType,
            bodyType,
        },
    };
}

const GridItemClassNameActive = "flex flex-row items-center p-2 rounded-md border border-solid border-lime-800 active:border-lime-900 text-lime-400 hover:text-lime-500 active:text-lime-600 focus-visible:outline-none transition-colors select-none cursor-pointer";

const GridItemClassNameInactive = SecondaryButtonClassNameInactive;

const GridItemClassNameDisabled = SecondaryButtonClassNameDisabled;

function TryOnPreviewImageTag({
    className = "",
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            className={classNames(
                "flex m-1 px-2 py-1 text-xs rounded text-zinc-500 border border-zinc-700/50 bg-zinc-800 shadow",
                className,
            )}
            {...props}
        >
            {children}
        </div>
    )
}

function TryOnPreviewImageTags({
    imageItem,
}: {
    imageItem: TryOnAssetLibraryItem,
}) {
    return (
        <div
            className="flex flex-wrap"
        >
            {Object.entries(imageItem.modelPreviewData).map(([key, value]) => (
                <TryOnPreviewImageTag
                    key={key}
                >
                    {value}
                </TryOnPreviewImageTag>
            ))}
        </div>
    )
}

const TryOnPoseLibraryItem = React.forwardRef(({
    imageId,
    imageItem,
    tryOnModelId,
    isIdle = true,
    ...props
}: TooltipProps & {
    imageId: string,
    tryOnModelId?: string,
    imageItem: TryOnAssetLibraryItem,
    isIdle?: boolean,
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    const [
        elementRef,
        inView,
    ] = useInView();

    const url = imageItem.previewUrl || imageItem.url;

    return (
        <Tooltip
            {...props}
            triggerChildren={(
                <PresetImageGridItem
                    ref={mergeRefs([forwardedRef, elementRef])}
                    url={inView ? url : ""}
                    className={`${isIdle ?
                        tryOnModelId === imageItem.modelId ?
                            GridItemClassNameActive :
                            GridItemClassNameInactive :
                        GridItemClassNameDisabled
                        }`}
                    onAddItem={() => {
                        if (imageItem.modelId === tryOnModelId || !isIdle) {
                            return;
                        }
                        setTryOnPersonImageElementFromImageId(
                            imageItem.modelId,
                            imageItem.url,
                        );
                    }}
                />
            )}
            contentClassName="pointer-events-none"
            contentChildren={(
                <div
                    className="flex flex-col"
                >
                    <ImageComponent
                        src={imageItem.previewUrl}
                        alt={`Model ${imageId}`}
                    />
                </div>
            )}
        />
    )
})

function TryOnPoseLibraryFilterToggleButton({
    className = "",
    children,
    ...props
}: ToggleGroup.ToggleGroupItemProps & React.RefAttributes<HTMLButtonElement>) {
    return (
        <ToggleGroup.Item
            className={classNames(
                styles.ToggleGroupItem,
                "flex flex-items items-center justify-center px-2 py-1 text-sm rounded transition-colors shadow",
                className,
            )}
            {...props}
        >
            {children}
        </ToggleGroup.Item>
    )
}

function TryOnPoseLibraryFilterToggleGroup({
    tags,
}: {
    tags: { [key in TryOnModelPreviewDataTags]?: string },
}) {
    const {
        value,
        onValueChange,
    } = useTryOnPoseLibraryFilterToggleGroupValue({
        tags,
    });

    return (
        <ToggleGroup.Root
            className="w-full grid grid-rows-1 grid-flow-col gap-2"
            type="single"
            value={value}
            onValueChange={onValueChange}
        >
            {Object.entries(tags).map(([tagKey, tagName]) => (
                <TryOnPoseLibraryFilterToggleButton
                    key={tagKey}
                    value={tagKey}
                >
                    {tagName}
                </TryOnPoseLibraryFilterToggleButton>
            ))}
        </ToggleGroup.Root>
    )
}

function TryOnPoseLibraryFilterToggle({
    tagKey,
    children,
}: {
    tagKey: TryOnModelPreviewDataTags,
    children?: React.ReactNode,
}) {
    const tryOnModelPreviewFilterContraints = editorContextStore(state => state.tryOnModelPreviewFilterContraints);

    const isActive = React.useMemo(() => tryOnModelPreviewFilterContraints.includes(tagKey), [tagKey, tryOnModelPreviewFilterContraints]);

    return (
        <button
            className={classNames(
                "flex flex-items items-center px-2 py-1 text-sm rounded transition-colors",
                isActive ? "text-zinc-800 bg-lime-500 hover:bg-lime-400" : "text-zinc-300 bg-zinc-800 hover:bg-zinc-600"
            )}
            onClick={() => {
                editorContextStore.getState().setTryOnModelPreviewFilterContraints((
                    filterConstraints
                ) => {
                    const newConstraints = filterConstraints.filter(e => e !== tagKey);
                    if (newConstraints.length === filterConstraints.length) {
                        newConstraints.push(tagKey);
                    }
                    return newConstraints;
                })
            }}
        >
            {isActive ? <Check size={18} /> : <Plus size={18} />}
            <div className="w-2" />
            {children || String(tagKey)}
        </button>
    )
}

function TryOnPoseLibraryFilter() {

    return (
        <DropdownBase
            triggerProps={{
                className: "flex flex-row items-center justify-center text-zinc-500 hover:text-lime-500 active:text-lime-600 transition-colors cursor-pointer",
                children: (
                    <>
                        <ListFilter
                            size={18}
                            className="mr-2"
                        />
                        Filter
                    </>
                )
            }}
            contentProps={{
                className: "flex flex-col",
                children: (
                    <>
                        <span className="mb-2 text-zinc-500">
                            Filter poses
                        </span>
                        <span className="mb-2">
                            Body Size
                        </span>
                        <TryOnPoseLibraryFilterToggleGroup
                            tags={{
                                'thin': "Slim",
                                "fat": "Large",
                            }}
                        />
                        <div className="h-3" />
                        <span className="mb-2">
                            Cloth Fit
                        </span>
                        <TryOnPoseLibraryFilterToggleGroup
                            tags={{
                                'loose-fit': "Loose",
                                "tight-fit": "Tight",
                            }}
                        />
                        <div className="h-3" />
                        <span className="mb-2">
                            Dress Code
                        </span>
                        <TryOnPoseLibraryFilterToggleGroup
                            tags={{
                                'casual': "Casual",
                                "business": "Business",
                                "sports": "Sports"
                            }}
                        />
                        <div className="h-3" />
                        <span className="mb-2">
                            Top Style
                        </span>
                        <TryOnPoseLibraryFilterToggleGroup
                            tags={{
                                'tucked-in_high-waist_pants': "Tucked In",
                                "untucked_top": "Untucked",
                            }}
                        />
                        <div className="h-3" />
                        <span className="mb-2">
                            Sleeve Style
                        </span>
                        <TryOnPoseLibraryFilterToggleGroup
                            tags={{
                                'long-sleeve_shirt': "Long Sleeve",
                                "short-sleeve_shirt": "Short Sleeve",
                                "off-shoulder_top": "No Sleeve",
                            }}
                        />
                    </>
                )
            }}
        />
    )
}

function getTryOnPoseLibraryItemsFromBatch(
    modelPreviewsData: TryOnModelPreviewData[]
) {
    return modelPreviewsData.map(getAssetItemFromModelPreviewData).reduce<Record<string, TryOnAssetLibraryItem>>((result, data) => {
        result[data.modelId] = data;
        return result;
    }, {});
}

function TryOnPoseLibrary() {
    const backend = editorContextStore(state => state.backend);

    const tryOnModelId = editorContextStore(state => state.tryOnModelId);

    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);

    const [
        assetItems,
        setAssetItems,
    ] = React.useState<Record<string, TryOnAssetLibraryItem>>({});

    const modelPreviewsDataGenerator = editorContextStore(state => state.tryOnModelPreviewGenerator);

    const isIdle = tryOnEditorState === 'idle';

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        let localModelPreviewsDataGenerator = modelPreviewsDataGenerator;

        if (localModelPreviewsDataGenerator) {
            localModelPreviewsDataGenerator.reset(true);
        } else {
            localModelPreviewsDataGenerator = backend.getTryOnModelPreviewsGenerator({
                batchSize: 24,
            });
            editorContextStore.getState().setTryOnModelPreviewGenerator(
                localModelPreviewsDataGenerator,
            );
        }

        localModelPreviewsDataGenerator
            ?.getNextBatch()
            .then((modelPreviewsData) => {

                const assetItems = getTryOnPoseLibraryItemsFromBatch(modelPreviewsData);

                setAssetItems(assetItems);

            });

    }, [
        backend,
    ]);



    const tryOnModelPreviewFilterContraints = editorContextStore(state => state.tryOnModelPreviewFilterContraints);

    React.useEffect(() => {
        if (!tryOnModelPreviewFilterContraints) {
            return;
        }

        const {
            tryOnModelPreviewGenerator,
        } = editorContextStore.getState();

        tryOnModelPreviewGenerator?.setConstraints(
            tryOnModelPreviewFilterContraints
        ).then((batch) => {
            setAssetItems(getTryOnPoseLibraryItemsFromBatch(batch));
        });

    }, [tryOnModelPreviewFilterContraints]);

    const [
        lastRowRef,
        lastRowInView,
    ] = useInView();

    React.useEffect(() => {
        if (lastRowInView && modelPreviewsDataGenerator) {

            modelPreviewsDataGenerator
                .getNextBatch()
                .then((modelPreviewsData) => {
                    setAssetItems((prevData) => ({
                        ...prevData,
                        ...getTryOnPoseLibraryItemsFromBatch(modelPreviewsData)
                    }));
                })
        }
    }, [
        backend,
        lastRowInView,
        modelPreviewsDataGenerator,
    ]);

    const assetItemsArray = React.useMemo(() => Object.values(assetItems), [assetItems]);

    return (
        <div
            className="flex flex-col"
        >
            <div
                className="flex flex-row items-center mb-2"
            >
                <span
                    className="font-semibold"
                >
                    Poses
                </span>
                <div className="flex-1" />
                <TryOnPoseLibraryFilter />
            </div>
            <div
                className="grid grid-cols-2 gap-2"
            >
                {Object.values(assetItems).map((imageItem, index) => imageItem.name && (
                    <TryOnPoseLibraryItem
                        ref={index === assetItemsArray.length - 1 ? lastRowRef : undefined}
                        key={imageItem.name}
                        imageId={imageItem.name}
                        imageItem={imageItem}
                        tryOnModelId={tryOnModelId}
                        isIdle={isIdle}
                    />
                ))}
            </div>
        </div>
    )
}

function ContinueButton({
    className = "",
    onClick,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {

    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);

    const isIdle = tryOnEditorState === 'idle';

    if (!isIdle) {
        return (
            <button
                className={classNames(
                    className,
                    PrimaryButtonClassNameDisabled,
                    'flex flex-row items-center justify-center',
                )}
                {...props}
            >
                <SimpleSpinner
                    width={18}
                    height={18}
                    pathClassName="fill-lime-500"
                    className="mr-2"
                />
                <span className="text-left">
                    Fitting cloth to the model ...
                </span>
            </button>
        )
    }

    return (
        <button
            className={classNames(
                className,
                PrimaryButtonClassName,
                'flex flex-row items-center justify-center',
            )}
            onClick={(e) => {
                onClick?.(e);
            }}
            {...props}
        >
            <ArrowRight
                width={18}
                height={18}
                className="mr-2"
            />
            <span className="min-w-[150px] text-left">
                Continue to Generate
            </span>
        </button>
    )
}

const NavigationPanel = React.forwardRef((
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) => {
    return (
        <div
            {...props}
            ref={forwardedRef}
            className={classNames(
                "w-full grid grid-cols-1 gap-2",
                className,
            )}
        >
            <ContinueButton
                onClick={() => {
                    editorContextStore.getState().setActiveLeftPanels((v) => [...v, 'TryOnRender']);
                }}
            />
            <TryOnExitClothEditorButton />
        </div>
    )
})

export function TryOnSelectPose() {

    const [
        navigationPanelRef,
        navigationPanelInView,
    ] = useInView();

    return (
        <div
            className="relative flex flex-col"
        >
            <div className="h-2" />
            <Navigate>
                <span
                    ref={navigationPanelRef}
                    className="text-zinc-500 group-hover:text-lime-800 mr-2 transition-colors"
                >
                    Step 2:
                </span>
                <span>
                    Select Pose
                </span>
            </Navigate>
            <NavigationPanel
                className={classNames(
                    "bg-zinc-900 py-4",
                    navigationPanelInView ? "" : "sticky left-0 top-0 z-10 border-b border-zinc-800 shadow-xl"
                )}
            />
            <TryOnPoseLibrary />
        </div>
    )
}