import { AppUserQuotas } from "core/common/types";
import { isUserSubscriptionTierFree } from "./quota-utils";

export function isColorCorrectV2QuotasAvaialable(userQuotas: AppUserQuotas | null) {
    try {
        if (!userQuotas) {
            return false;
        }

        if (!isUserSubscriptionTierFree(userQuotas)) {
            return true;
        }
        const {
            numColorCorrectV2Renders,
            maxNumColorCorrectV2Renders,
        } = userQuotas;

        return numColorCorrectV2Renders < maxNumColorCorrectV2Renders;
    } catch (error) {
        console.error(error);
    }
    return false;
}