import React from 'react';
import { editorContextStore } from 'contexts/editor-context';
import { FloatTagButtonWithTooltip, floatTagButtonIconSize } from './float-tag-button';
import { BringFrontIcon, SendBackIcon } from 'components/icons/bring-front-icon';
import { Editor } from 'core/editor';
import { Box, CopyPlus, Trash } from 'lucide-react';
import { isStaticImageObject3d } from 'core/common/types/3d';
import { isActiveSelection, isGenerationFrame } from 'core/utils/type-guards';

function Divider() {
    return (
        <div className='w-1' />
    );
}

const ButtonClassName = "flex flex-row items-center justify-center text-sm font-semibold"

function BringToFrontButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Bring to front"
            onClick={() => {
                editor?.objects.bringToFront();
            }}
        >
            <BringFrontIcon size={floatTagButtonIconSize} />
        </FloatTagButtonWithTooltip>
    )
}

function SendToBackButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Send to back"
            onClick={() => {
                editor?.objects.sendToBack();
            }}
        >
            <SendBackIcon size={floatTagButtonIconSize} />
        </FloatTagButtonWithTooltip>
    )
}

function Edit3dButton({
    editor,
}: {
    editor: Editor | null,
}) {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Rotate 3D prop"
            onClick={() => {
                editorContextStore.getState().setActiveLeftPanels((prevLeftPanels) => ([
                    ...prevLeftPanels,
                    'TransformProps3d',
                ]));
            }}
        >
            <Box size={floatTagButtonIconSize} />
        </FloatTagButtonWithTooltip>
    )
}


function DuplicateButton() {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Duplicate"
            onClick={() => {
                editorContextStore.getState().editor?.objects.copy();
                editorContextStore.getState().editor?.objects.paste();
            }}
        >
            <CopyPlus size={floatTagButtonIconSize} />
        </FloatTagButtonWithTooltip>
    )
}


function DeleteButton() {
    return (
        <FloatTagButtonWithTooltip
            className={ButtonClassName}
            tooltipChildren="Delete"
            onClick={() => {
                editorContextStore.getState().editor?.objects.remove();
            }}
        >
            <Trash size={floatTagButtonIconSize} />
        </FloatTagButtonWithTooltip>
    )
}



function DefaultObjectFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <DuplicateButton />
            <Divider />
            <DeleteButton />
        </>
    )
}

function Object3dFloatTag({
    editor,
    onComponentMount,
    onComponentUnmount,
}: {
    editor: Editor | null,
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    React.useEffect(() => {
        onComponentMount();
        return () => onComponentUnmount();
    }, [onComponentMount, onComponentUnmount]);

    return (
        <>
            <DuplicateButton />
            <Divider />
            <DeleteButton />
            <Divider />
            <Edit3dButton
                editor={editor}
            />
        </>
    )
}

export function ObjectFloatTag({
    onComponentMount,
    onComponentUnmount,
}: {
    onComponentMount: () => void,
    onComponentUnmount: () => void,
}) {
    const editor = editorContextStore(state => state.editor);
    const activeObject = editorContextStore(state => state.activeObject);

    if (!editor || !activeObject) {
        return null;
    }

    if (isGenerationFrame(activeObject as any)) {
        return null;
    }

    if (isActiveSelection(activeObject)) {
        return null;
    }

    if (isStaticImageObject3d(activeObject)) {
        return (
            <Object3dFloatTag
                editor={editor}
                onComponentMount={onComponentMount}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

    return (
        <DefaultObjectFloatTag
            editor={editor}
            onComponentMount={onComponentMount}
            onComponentUnmount={onComponentUnmount}
        />
    );
}