import { PROPERTIES_TO_INCLUDE, RENDER_CANVAS_LEGNTH } from 'core/common/constants';
import { fabric } from "fabric"
import { isArray, pick } from "lodash"
import { nanoid } from "nanoid"
import { Base } from "./base"
import { ILayer, ILayerOptions, LayerType } from "core/common/layers"
import { copyStyleProps, defaultControllerOptions, defaultObjectOptions, getCopyStyleCursor, } from "../common/constants"
import { Direction, GradientOptions, ScaleType, ShadowOptions, Size } from "../common/interfaces"
import ObjectImporter from "../utils/object-importer"
import setObjectGradient, { setMinimumScaleSize, setObjectShadow } from "../utils/fabric"
import { BBox2d, EditorAsset, EditorAssetContentType, ShuffleStackEventHandler, UpdateObjectPropsEventHandler } from "core/common/types"
import { isBackgroundImage, isGenerationFrame } from "core/utils/type-guards"
import { getBbox2dFromObjectBounds, getObjectBoundsFromBBox2d, mergeBbox2d } from 'core/utils/bbox-utils';
import { cloneFilters } from 'core/utils/object-filter-utils';
import { OBJECT_ROTATION_SNAP_THRESHOLD } from 'components/constants/objects';
import { addObjectToCanvas } from 'components/utils/add-to-canvas-utils';
import { StaticImageElementMetadata } from 'core/common/types/elements';
import { UpdateObjectIndexEventHandler } from "core/common/types";

type FabricObjectOrId = fabric.Object | string;

class Objects extends Base {

  public clipboard: any
  public isCut: any
  public copyStyleClipboard: any

  protected async addObject(
    object: fabric.Object,
    center: { x: number, y: number },
    setActive = true,
  ) {
    try {

      const { canvas } = this
      if (this.config.clipToFrame) {
        const frame = this.editor?.frame?.frame
        object.clipPath = frame
      }

      object.left = center.x - (object.getScaledWidth() || 0) * 0.5;
      object.top = center.y - (object.getScaledHeight() || 0) * 0.5;
      object.setCoords();

      addObjectToCanvas({
        canvas,
        object,
      });

      this.bringToFront(object);

      if (setActive) {
        this.state.setActiveObject(object)
        canvas.setActiveObject(object)
      }

      this.updateContextObjects()
      this.editor.history.save()

      if (object.type === "StaticVideo") {
        setTimeout(() => {
          this.canvas.requestRenderAll()
        }, 500)
      }

      this.onShuffledStack();

    } catch (e) {

      console.error(e);
      console.error('Cannot add object')

    }
  }

  public getDefaultObjectLocation = (location?: { x: number, y: number }) => {
    const {
      tl,
      br,
    } = this.canvas.calcViewportBoundaries();

    const center = location || tl.clone().add(br).multiply(0.5);
    const viewportWidth = br.x - tl.x;
    const viewportHeight = br.y - tl.y;
    const size = Math.min(0.5 * viewportWidth, 0.5 * viewportHeight);

    return {
      width: Math.min(size, RENDER_CANVAS_LEGNTH),
      x: center.x,
      y: center.y,
    };
  }

  public add = async (item: Partial<ILayer>, location?: { x: number, y: number }) => {
    const options = defaultObjectOptions as Required<ILayer>
    const objectImporter = new ObjectImporter(this.editor)
    const refItem = item as unknown as ILayer

    try {

      const {
        tl,
        br,
      } = this.canvas.calcViewportBoundaries();

      const center = location || tl.clone().add(br).multiply(0.5);
      const viewportWidth = br.x - tl.x;
      const viewportHeight = br.y - tl.y;
      const size = Math.min(0.5 * viewportWidth, 0.5 * viewportHeight);

      options.width = size;
      options.left = center.x;
      options.top = center.y;

      const object: fabric.Object = await objectImporter.import(refItem, options);

      await this.addObject(object, center);

      return object;

    } catch (e) {

      console.error(e);
      console.error('Cannot add item')

    }

  }
  /**
   *
   * @param options object properties to be updated
   * @param id if provided, will update the update by id
   */
  public update = (options: Partial<ILayerOptions>, id?: string) => {
    const frame = this.editor?.frame?.frame

    let refObject = this.canvas.getActiveObject();
    if (id) {
      refObject = this.findOneById(id);
    }

    const canvas = this.canvas

    if (refObject) {
      for (const property in options) {
        if (property === "angle" || property === "top" || property === "left") {
          if (property === "angle") {
            // @ts-ignore
            refObject.rotate(options["angle"])
            canvas.requestRenderAll()
          } else {
            // @ts-ignore
            refObject.set(property as "top" | "left", frame[property] + options[property])
            canvas.requestRenderAll()
          }
        } else if (property === "clipToFrame") {
          if (options["clipToFrame"]) {
            refObject.set("clipPath", frame)
          } else {
            refObject.set("clipPath", null)
          }
        } else if (property === "shadow") {
          // @ts-ignore
          this.setShadow(options["shadow"])
        } else if (property === "metadata") {
          refObject.set("metadata", {
            ...refObject.metadata,
            ...options[property],
          })
        } else if (refObject.type === LayerType.ACTIVE_SELECTION && refObject._objects) {
          refObject._objects.forEach((object) => {
            if (property === "metadata") {
              object.set("metadata", {
                ...object.metadata,
                ...options["metadata"],
              })
            } else {
              // @ts-ignore
              object.set(property, options[property])
            }
            object.setCoords()
          })
        } else {
          // @ts-ignore
          refObject.set(property as keyof fabric.Object, options[property])
          canvas.requestRenderAll()
          refObject.setCoords()
        }
      }

      this.editor.history.save();

      this.editor.emit<UpdateObjectPropsEventHandler>(
        'object:update-props',
        {
          objectId: refObject.id,
          props: options,
        },
      );
    }
  }

  public clear = () => {
    const frame = this.editor?.frame?.frame
    this.canvas.getObjects().forEach((object) => {
      if (Objects.isObjectRemovable(object)) {
        this.canvas.remove(object)
      }
    })
    frame?.set({
      fill: "#ffffff",
    })
    this.canvas.renderAll()
  }

  public reset = () => {
    const background = this.editor?.frame?.background

    this.canvas.getObjects().forEach((object) => {
      if (Objects.isObjectRemovable(object)) {
        this.canvas.remove(object)
      }
    })
    background?.set({
      fill: "#ffffff",
    })
    this.canvas.renderAll()
    this.editor.history.reset()
  }

  protected selectOne(id: string) {
    const [object] = this.findById(id) as fabric.Object[]
    if (object) {
      this.canvas.disableEvents()
      this.canvas.setActiveObject(object)
      if (object.group) {
        object.hasControls = false
      }
      this.canvas.enableEvents()
      this.canvas.requestRenderAll()

      const activeObject = this.canvas.getActiveObject()
      this.state.setActiveObject(activeObject)
    }
  }

  static objectTypesDisableMultiSelect = new Set([
    LayerType.FRAME,
    LayerType.BACKGROUND,
    LayerType.BACKGROUND_IMAGE,
    LayerType.GENERATION_FRAME,
  ]);

  static isMultiSelectableObjects(object?: fabric.Object) {
    if (!object) {
      return false;
    }
    const objectType = object.type;
    if (objectType && Objects.objectTypesDisableMultiSelect.has(objectType as LayerType)) {
      return false
    } else if (!object.evented) {
      return false
    } else if (object.locked) {
      return false
    }
    return true
  }

  static filterMultiSelectableObjects(objects: fabric.Object[]) {
    return objects.filter(Objects.isMultiSelectableObjects);
  }

  static createActiveSelection(
    objects: fabric.Object[],
    options: fabric.IObjectOptions,
  ) {
    return new fabric.ActiveSelection(objects, {
      ...options,
      ...defaultControllerOptions,
    }) as fabric.Object;
  }

  getBoundsOfAll() {
    const objects = this.canvas.getObjects();
    let bbox: BBox2d | undefined;
    objects.forEach((object) => {
      bbox = mergeBbox2d(
        getBbox2dFromObjectBounds(object),
        bbox,
      );
    });
    const generationFrame = this.getGenerationFrame();
    if (generationFrame) {
      const {
        left = 0,
        top = 0,
        width = 0,
        height = 0,
      } = generationFrame;
      bbox = mergeBbox2d(
        getBbox2dFromObjectBounds({
          left: left + width,
          top,
          width,
          height,
        }),
        bbox,
      );
    }
    return bbox && getObjectBoundsFromBBox2d(bbox);
  }

  protected selectMultiple(ids: string[]) {
    const filteredObjects = this.findByIds(ids).filter(object => object != null && !Objects.objectTypesDisableMultiSelect.has(object.type));
    if (!filteredObjects.length) {
      return
    }

    if (filteredObjects.length === 1) {
      this.canvas.setActiveObject(filteredObjects[0])
      this.canvas.renderAll()
      this.state.setActiveObject(filteredObjects[0])
      return
    }
    const activeSelection = Objects.createActiveSelection(filteredObjects, {
      canvas: this.canvas,
    }) as fabric.Object
    this.canvas.setActiveObject(activeSelection)
    this.canvas.renderAll()
    this.state.setActiveObject(activeSelection)
  }

  public selectAll = (renderAll = true) => {
    const filteredObjects = Objects.filterMultiSelectableObjects(this.canvas.getObjects());
    if (!filteredObjects.length) {
      // console.log(`No valid object to select from ${this.canvas.getObjects().length} objects`);
      return
    }
    if (filteredObjects.length === 1) {
      this.canvas.setActiveObject(filteredObjects[0])
      if (renderAll) this.canvas.renderAll()
      this.state.setActiveObject(filteredObjects[0])
      return
    }
    const activeSelection = Objects.createActiveSelection(filteredObjects, {
      canvas: this.canvas,
    }) as fabric.Object
    this.canvas.setActiveObject(activeSelection)
    if (renderAll) this.canvas.renderAll()
    this.state.setActiveObject(activeSelection)
  }

  public select = (id?: string | string[]) => {
    this.canvas.discardActiveObject()
    if (id) {
      if (Array.isArray(id)) {
        this.selectMultiple(id);
      } else {
        this.selectOne(id);
      }
    } else {
      this.selectAll(true);
    }
  }

  public deselect = (renderAll = true) => {
    this.canvas.discardActiveObject()
    if (renderAll) this.canvas.requestRenderAll()
    this.state.setActiveObject(null)
  }

  public move(direction: Direction, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      const updatedPosition = refObject[direction] + value
      refObject.set(direction, updatedPosition)
      this.editor.history.save()
    }
  }

  public position(position: Direction, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      refObject.set(position, value)
      this.editor.history.save()
    }
  }

  public resize(size: Size, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (size === "width") {
      refObject.set("scaleX", value / refObject.width)
    }
    if (size === "height") {
      refObject.set("scaleY", value / refObject.height)
    }
  }

  public scale(type: ScaleType, id?: string, frame?: fabric.Object) {
    if (!frame) return;
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    const { width = 0, height = 0, top = 0 } = frame
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      let scaleX = width / refObject.width
      let scaleY = height / refObject.height
      const scaleMax = Math.max(scaleX, scaleY)
      const scaleMin = Math.min(scaleX, scaleY)

      if (type === "fit") {
        refObject.set({
          scaleX: scaleMin,
          scaleY: scaleMin,
        })
      }
      if (type === "fill") {
        refObject.set({
          scaleY: scaleMax,
          scaleX: scaleMax,
        })
      }
      refObject.center()
      if (scaleY >= scaleX) {
        refObject.set("top", top)
      }
    }
  }

  public cut = () => {
    this.copy()
    this.isCut = true
    this.remove()
  }

  public copy = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      this.clipboard = activeObject
    }
  }

  public copyById = (id: string) => {
    const object = this.findOneById(id)
    if (object) {
      this.clipboard = object
    }
  }

  public clone = () => {
    if (this.canvas) {
      const activeObject = this.canvas.getActiveObject()
      if (!activeObject) {
        return;
      }
      const frame = this.editor?.frame?.frame

      this.canvas.discardActiveObject()

      this.duplicate(activeObject, frame, (duplicates) => {
        const selection = Objects.createActiveSelection(duplicates, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.canvas.requestRenderAll()
      })
    }
  }

  public cloneAudio = (id: string) => {
    const object = this.findOneById(id)
    const frame = this.editor?.frame?.frame
    this.deselect()
    this.duplicate(object, frame, (duplicates) => {
      this.canvas.requestRenderAll()
      this.updateContextObjects()
    })
  }

  protected static cloneObject(
    source: fabric.Object,
    propertiesToInclude: string[] = PROPERTIES_TO_INCLUDE,
  ) {
    const objectFrom = source.toObject(propertiesToInclude);
    console.log(source.constructor.name);
    return (source.constructor as any).fromObject(
      objectFrom,
    ) as any as fabric.Object;
  }

  public duplicate(
    object: fabric.Object,
    frame: fabric.Object | undefined,
    callback: (clones: fabric.Object[]) => void,
    onBeforeAddObject?: (clone: fabric.Object, index: number) => void,
  ): void {
    if (object instanceof fabric.Group && object.type !== LayerType.STATIC_VECTOR) {
      const objects: fabric.Object[] = (object as fabric.Group).getObjects()
      const duplicates: fabric.Object[] = []
      for (let i = 0; i < objects.length; i++) {
        this.duplicate(
          objects[i],
          frame,
          (clones) => {
            duplicates.push(...clones)
            if (i === objects.length - 1) {
              callback(duplicates)
            }
          },
          (clone) => onBeforeAddObject?.(clone, i),
        );
      }
    } else {
      const prevFilters = (object as any).filters;
      (object as any).filters = [];
      object.clone(
        (clone: fabric.Object) => {
          clone.clipPath = undefined
          clone.id = nanoid()
          clone.set({
            left: object.left! + 10,
            top: object.top! + 10,
          })
          if (frame && this.config.clipToFrame) {
            clone.clipPath = frame;
          }
          clone.metadata = { ...object.metadata };
          cloneFilters(object, clone);

          onBeforeAddObject?.(clone, 0);

          addObjectToCanvas({
            canvas: this.canvas,
            object: clone,
          });

          callback([clone])
        },
        PROPERTIES_TO_INCLUDE
      );
      (object as any).filters = prevFilters;
    }
    this.onShuffledStack();
  }

  public paste = () => {
    const object = this.clipboard
    if (object) {
      const frame = this.editor?.frame?.frame
      this.canvas.discardActiveObject()
      this.duplicate(object, frame, (duplicates) => {
        const selection = Objects.createActiveSelection(duplicates, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.canvas.requestRenderAll()
        this.updateContextObjects()
      })
    }
  }

  static isObjectRemovable(object: fabric.Object) {
    if (object) {
      return object.type !== LayerType.GENERATION_FRAME && object.type !== LayerType.FRAME && object.type !== LayerType.BACKGROUND_IMAGE;
    }
    return false;
  }

  static isObjectExportable(object: fabric.Object) {
    return Objects.isObjectRemovable(object);
  }

  /**`
   * Remove active object
   */
  public remove = (id?: string) => {
    let refObject = id ? this.findOneById(id) : this.canvas.getActiveObjects();

    if (!Objects.isObjectRemovable(refObject)) {
      return;
    }

    if (isArray(refObject)) {
      refObject = refObject.filter(obj => Objects.isObjectRemovable(obj));
      if (refObject.length <= 0) {
        return;
      }
      refObject.forEach((obj: any) => this.canvas.remove(obj))
    } else {
      this.canvas.remove(refObject)
    }

    this.canvas.discardActiveObject().renderAll()
    this.editor.history.save()
    this.updateContextObjects()
  }

  public list = () => {
    const objects = this.canvas.getObjects()
    const filteredObjects = objects.filter((o) => {
      return Objects.isObjectExportable(o)
    })
    return filteredObjects
  }

  public copyStyle = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      //  @ts-ignore
      const clonableProps = copyStyleProps[activeObject.type]
      const clonedProps = pick(activeObject.toJSON(), clonableProps)

      this.copyStyleClipboard = {
        objectType: activeObject.type,
        props: clonedProps,
      }

      this.editor?.frame?.setHoverCursor(getCopyStyleCursor())
      this.canvas.hoverCursor = getCopyStyleCursor()
      this.canvas.defaultCursor = getCopyStyleCursor()
    }
  }

  public pasteStyle = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject && this.copyStyleClipboard) {
      if (activeObject.type === this.copyStyleClipboard.objectType) {
        const { fill, ...basicProps } = this.copyStyleClipboard.props
        activeObject.set(basicProps)

        if (fill) {
          if (fill.type) {
            activeObject.set({ fill: new fabric.Gradient(fill) })
          } else {
            activeObject.set({ fill })
          }
        }
      }
    }
    this.copyStyleClipboard = null
    this.editor?.frame?.setHoverCursor("default")
    this.canvas.hoverCursor = "move"
    this.canvas.defaultCursor = "default"
  }

  protected bringGenerationFrameToFront() {
    const generationFrame = this.getGenerationFrame();
    if (!generationFrame) {
      console.log('Generation frame does not exist');
      return;
    }
    if (generationFrame.visible) {
      this.canvas.bringToFront(generationFrame);
    } else {
      console.log('Generation frame is not visible');
    }
  }

  protected sendBackgroundImageToBack() {
    // TODO: Initialize the background image

    // const backgroundImage = this.getBackgroundImage();

    // if (!backgroundImage) {
    //   console.log("Background image does not exist.");
    //   return;
    // }

    // if (backgroundImage.visible) {
    //   this.canvas.sendToBack(backgroundImage);
    // } else {
    //   console.log("Generation frame is not visible.");
    // }
  }

  public onShuffledStack() {
    this.editor.emit<ShuffleStackEventHandler>('objects:shuffle-stack');
    this.bringGenerationFrameToFront();
    this.sendBackgroundImageToBack();
  }

  static MIN_Z_INDEX = 0;

  protected getObjectFromObjectOrId(objectOrId?: FabricObjectOrId): fabric.Object | undefined {
    return objectOrId === undefined ?
      this.canvas.getActiveObject() :
      (
        typeof (objectOrId) === 'string' ?
          this.findOneById(objectOrId) :
          objectOrId
      );
  }

  /**
   * Moves an object or a selection up in stack of drawn objects.
   */
  public bringForward = (id?: string | fabric.Object) => {
    const refObject = this.getObjectFromObjectOrId(id);

    if (refObject) {
      this.canvas.bringForward(refObject);
      this.onShuffledStack();

      this.editor.emit<UpdateObjectIndexEventHandler>('object:update-index',
        {
          object: refObject,
        },
      );
    }
  }

  /**
   * Moves an object or the objects of a multiple selection to the top of the stack of drawn objects
   */
  public bringToFront = (id?: string | fabric.Object) => {
    const refObject = this.getObjectFromObjectOrId(id);
    if (refObject) {
      this.canvas.bringToFront(refObject);
      this.onShuffledStack();

      this.editor.emit<UpdateObjectIndexEventHandler>('object:update-index',
        {
          object: refObject,
        },
      );
    }
  }

  /**
   * Moves an object or a selection down in stack of drawn objects.
   */
  public sendBackwards = (id?: string | fabric.Object) => {
    const refObject = this.getObjectFromObjectOrId(id);
    const objects = this.canvas.getObjects()

    const index = objects.findIndex((o) => o === refObject)

    const canBeMoved = index > Objects.MIN_Z_INDEX

    if (refObject && canBeMoved) {
      this.canvas.sendBackwards(refObject)
      this.onShuffledStack();
      this.editor.emit<UpdateObjectIndexEventHandler>('object:update-index',
        {
          object: refObject,
        },
      );
    }
  }

  /**
   * Moves an object to specified level in stack of drawn objects.
   */
  public sendToBack = (id?: string | fabric.Object) => {
    const refObject = this.getObjectFromObjectOrId(id);
    if (refObject) {
      refObject.moveTo(Objects.MIN_Z_INDEX)
    }
    this.onShuffledStack();
    if (refObject) {
      this.editor.emit<UpdateObjectIndexEventHandler>('object:update-index',
        {
          object: refObject,
        },
      );
    }
  }

  /**
   * Moves an object to the top of the frame. If multiple objects are selected,
   * will move all objects to the top of the selection.
   */
  public alignTop = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          currentObject.set({
            top: refTop,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        currentObject.set({
          top: frame.top,
        })
      }
      this.canvas.requestRenderAll()
    }
  }
  /**
   * Moves an object to the middle of the frame. If multiple objects are selected,
   * will move all objects to the middle of the selection.
   */
  public alignMiddle = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        const refHeight = refObject.height
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectHeight = currentObject.getScaledHeight()
          currentObject.set({
            top: refTop + refHeight / 2 - currentObjectHeight / 2,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectHeight = currentObject.getScaledHeight()
        currentObject.set({
          top: (frame.top || 0) + (frame.height || 0) / 2 - currentObjectHeight / 2,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the bottom of the frame. If multiple objects are selected,
   * will move all objects to the bottom of the selection.
   */
  public alignBottom = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        const refHeight = refObject.height
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectHeight = currentObject.getScaledHeight()
          currentObject.set({
            top: refTop + refHeight - currentObjectHeight,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectHeight = currentObject.getScaledHeight()
        currentObject.set({
          top: (frame.top || 0) + (frame.height || 0) - currentObjectHeight,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the left of the frame. If multiple objects are selected,
   * will move all objects to the left of the selection.
   */
  public alignLeft = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refLeft = refObject.left
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          currentObject.set({
            left: refLeft,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        currentObject.set({
          left: frame.left,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the center of the frame. If multiple objects are selected,
   * will move all objects to the center of the selection.
   */
  public alignCenter = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects
        const refLeft = refObject.left
        const refWidth = refObject.width
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectWidth = currentObject.getScaledWidth()
          currentObject.set({
            left: refLeft + refWidth / 2 - currentObjectWidth / 2,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectWidth = currentObject.getScaledWidth()
        currentObject.set({
          left: (frame.left || 0) + (frame.width || 0) / 2 - currentObjectWidth / 2,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the right of the frame. If multiple objects are selected,
   * will move all objects to the right of the selection.
   */
  public alignRight = (id?: string, frame?: fabric.Object) => {
    frame = frame || this.editor?.frame?.frame;
    if (!frame) return;

    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Group[]
        const refLeft = refObject.left
        const refWidth = refObject.width
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectWidth = currentObject.getScaledWidth()
          currentObject.set({
            left: refLeft + refWidth - currentObjectWidth,
          })
        })
        const selection = Objects.createActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectWidth = currentObject.getScaledWidth()
        currentObject.set({
          left: (frame.left || 0) + (frame.width || 0) - currentObjectWidth,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Set object shadow
   * @param options ShadowOptions
   */
  public setShadow = (options: ShadowOptions) => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject instanceof fabric.Group && activeObject.type !== LayerType.STATIC_VECTOR) {
      // @ts-ignore
      activeObject._objects.forEach((object) => {
        setObjectShadow(object, options)
      })
    } else {
      setObjectShadow(activeObject, options)
    }
    this.canvas.requestRenderAll()
    this.editor.history.save()
  }

  /**
   * Set object fill as gradient
   * @param param GradientOptions
   */
  public setGradient = ({ angle, colors }: GradientOptions) => {
    const activeObject = this.canvas.getActiveObject()
    if (!activeObject) {
      return;
    }
    if (activeObject instanceof fabric.Group) {
      // @ts-ignore
      activeObject._objects.forEach((object) => {
        setObjectGradient(object, angle, colors)
      })
    } else {
      setObjectGradient(activeObject, angle, colors)
    }
    this.canvas.requestRenderAll()
    this.editor.history.save()
  }

  /**
   * Group selected objects
   */
  public group = () => {
    const activeObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    if (activeObject.type !== LayerType.ACTIVE_SELECTION) {
      return
    }

    activeObject.toGroup()
    this.canvas.requestRenderAll()
    this.editor.history.save()

    const groupedActiveObject = this.canvas.getActiveObject();
    if (groupedActiveObject) {
      groupedActiveObject.set({
        name: "group",
        id: nanoid(),
        // @ts-ignore
        subTargetCheck: true,
      })
    }
    this.updateContextObjects()
  }

  public ungroup = () => {
    const frame = this.editor?.frame?.frame
    const activeObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    if (activeObject.type !== LayerType.GROUP.toLowerCase()) {
      return
    }

    activeObject.clipPath = null
    const activeSelection = activeObject.toActiveSelection()
    // @ts-ignore
    activeSelection._objects.forEach((object) => {
      object.clipPath = frame
    })
    this.state.setActiveObject(activeSelection)
    this.canvas.requestRenderAll()
    this.editor.history.save()
    this.updateContextObjects()
  }

  /**
   * Lock object movement and disable controls
   */
  public lock = (id?: string) => {
    let refObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject._objects) {
        refObject._objects.forEach((object) => {
          object.set({
            hasControls: false,
            lockMovementY: true,
            lockMovementX: true,
            locked: true,
          })
        })
        // @ts-ignore
        refObject.set({
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          locked: true,
        })
      } else {
        // @ts-ignore
        refObject.set({
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          locked: true,
        })
      }
      this.canvas.requestRenderAll()
      this.editor.history.save()
    }
  }

  /**
   * Unlock active object
   */
  public unlock = (id?: string) => {
    let refObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject?._objects) {
        refObject._objects.forEach((object) => {
          object.set({
            hasControls: true,
            lockMovementY: false,
            lockMovementX: false,
            locked: false,
          })
        })
        // @ts-ignore
        refObject.set({
          hasControls: true,
          lockMovementY: false,
          lockMovementX: false,
          locked: false,
        })
      } else {
        // @ts-ignore
        refObject.set({
          hasControls: true,
          lockMovementY: false,
          lockMovementX: false,
          locked: false,
        })
      }
      this.canvas.requestRenderAll()
      this.editor.history.save()
    }
  }

  public findByName = (name: string) => {
    return this.canvas.getObjects().filter((o) => o.name === name)
  }

  public removeByName = (name: string) => {
    this.canvas.getObjects().forEach((o) => {
      if (o.name === name) {
        this.canvas.remove(o)
        this.editor.history.save()
      }
    })
    this.canvas.requestRenderAll()
  }

  public find = (predicate: (object: fabric.Object) => boolean) => {
    return this.canvas.getObjects().filter((object) => Objects.isMultiSelectableObjects(object) && predicate(object));
  }

  public findOne = (predicate: (object: fabric.Object) => boolean) => {
    return this.canvas.getObjects().find((object) => Objects.isMultiSelectableObjects(object) && predicate(object));
  }

  public findByIdInObjecs = (id: string, objects: fabric.Object[]): any => {
    let item: fabric.Object | null = null

    for (const object of objects) {
      if (object.id === id) {
        item = object
        break
      }
      if (object.type === "group") {
        // @ts-ignore
        const itemInGroup = this.findByIdInObjecs(id, object._objects)
        if (itemInGroup) {
          item = itemInGroup
          break
        }
      }
    }
    return item
  }

  public findById = (id: string) => {
    const objects = this.canvas.getObjects()
    const object = this.findByIdInObjecs(id, objects)
    return [object]
  }

  public findOneById = (id: string) => {
    const objects = this.findById(id)
    return objects[0]
  }

  public findByIds = (ids: string[]) => {
    const objects = this.canvas.getObjects();
    return ids.map(id => this.findByIdInObjecs(id, objects));
  }

  public removeById = (id: string) => {
    this.canvas.getObjects().forEach((o) => {
      if (o.id === id) {
        this.canvas.remove(o)
        this.editor.history.save()
        this.updateContextObjects()
      }
    })
    this.canvas.requestRenderAll()
  }

  // Text exclusive hooks
  public toUppercase(id?: string) {
    let refObject = this.canvas.getActiveObject() as fabric.StaticText
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject && refObject.type === LayerType.STATIC_TEXT) {
      if (refObject.isEditing) {
        refObject.hiddenTextarea!.value = refObject.hiddenTextarea!.value.toUpperCase()
        refObject.updateFromTextArea()
        this.canvas.requestRenderAll()
        return
      }

      const text = refObject.text
      refObject.text = text!.toUpperCase()
      this.canvas.requestRenderAll()
    }
  }

  // Text exclusive hooks
  public toLowerCase(id?: string) {
    let refObject = this.canvas.getActiveObject() as fabric.StaticText
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject && refObject.type === LayerType.STATIC_TEXT) {
      if (refObject.isEditing) {
        refObject.hiddenTextarea!.value = refObject.hiddenTextarea!.value.toLowerCase()
        refObject.updateFromTextArea()
        this.canvas.requestRenderAll()
        return
      }

      const text = refObject.text
      refObject.text = text!.toLowerCase()
      this.canvas.requestRenderAll()
    }
  }

  public updateContextObjects = () => {
    // const objects = this.canvas.getObjects()
    // const filteredObjects = objects.filter((o) => {
    //   return o.type !== "Frame" && o.type !== "Background"
    // })
    // this.state.setObjects(filteredObjects)
  }

  public addImageFromUrl({
    url,
    options,
    location,
    asset,
    generationId,
    targetWidth,
    targetHeight,
    uploadStorage = false,
    setActive = true,
    metadata,
  }: {
    url: string,
    options?: fabric.IImageOptions,
    location?: {
      x: number,
      y: number,
    },
    targetWidth?: number,
    targetHeight?: number,
    asset?: EditorAsset | null,
    generationId?: string,
    uploadStorage?: boolean,
    metadata?: StaticImageElementMetadata,
    setActive?: boolean,
  }): Promise<fabric.StaticImage | null> {
    if (!url) return Promise.resolve(null);
    asset = asset || (uploadStorage ? {
      type: 'image-storage',
      path: '',
    } : {
      type: 'image-url',
      path: url,
    });

    return fabric.StaticImage.fromURL(
      url,
      {
        ...defaultControllerOptions,
        ...options,
        type: 'StaticImage',
        crossOrigin: 'anonymous',
        asset,
        generationId,
        metadata,
      },
    ).then(image => {
      if (image) {
        image.id = nanoid();

        const {
          tl,
          br,
        } = this.canvas.calcViewportBoundaries();
        const center = location || this.canvas.getActiveObject()?.getCenterPoint() || tl.clone().add(br).multiply(0.5);

        setMinimumScaleSize(image);
        if (targetWidth && image.width) {
          image.scale(targetWidth / image.width);
        } else if (targetHeight && image.height) {
          image.scale(targetHeight / image.height);
        }


        this.addObject(
          image as any as fabric.Image,
          center,
          setActive,
        );

        return image;
      }
      return null;
    }).then(async (image) => {

      if (!image) {
        return image;
      }

      if (!uploadStorage) {
        return image;
      }

      console.log('Upload image to user storage');

      const path = await this.editor.assets.addAsset(await fetch(url).then(async r => ({
        data: await r.blob(),
        contentType: (r.headers.get('Content-Type') || EditorAssetContentType.png) as EditorAssetContentType,
        saveToMemory: true,
      })));

      if (path) {
        this.editor.assets.setObjectAsset(image.id, {
          type: 'image-storage',
          path,
        });
      }

      return image;
    });
  }

  getGenerationFrame() {
    return this.canvas.getObjects().find(isGenerationFrame);
  }

  // getBackgroundImage() {
  //   return this.canvas.getObjects().find(isBackgroundImage);
  // }

  // setBackgroundImage(backgroundImage: fabric.BackgroundImage) {
  //   // Find and delete all background images

  //   const backgroundImages = this.canvas.getObjects().filter(isBackgroundImage);

  //   backgroundImages.forEach(backgroundImage => {
  //     this.canvas.remove(backgroundImage);
  //   });

  //   const { canvas } = this;

  //   addObjectToCanvas({
  //     canvas,
  //     object: backgroundImage,
  //   });

  //   this.canvas.sendToBack(backgroundImage);

  //   this.editor.history.save();
  // }
}

export default Objects
