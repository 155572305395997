import bigInt from "big-integer";




export function incrementBigInt(value: string, unit = 1) {
    return bigInt(value).add(unit).toString();
}

export function isBigIntLessThanEqual(a: string, b: string) {
    return bigInt(a).compare(b) <= 0;
}


export function getNearestNumberInGrid(value: number, gap: number) {
    return Math.round(value / gap) * gap;
}

export function getBytesFromMegabytes(mb: number) {
    return mb * 1e6;
}

export function getMegabytesFromBytes(bytes: number) {
    return bytes * 1e-6;
}

export function getClosestNumber(goal: number, counts: number[]) {
    return counts.reduce(function(prev, curr) {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
}

export function lerp(a: number, b: number, t: number): number {
    return a + t * (b - a);
}

function clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max));
}

export function rescaleNumber({
    value,
    sourceMax,
    sourceMin,
    targetMax,
    targetMin,
}: {
    value: number,
    sourceMin: number,
    sourceMax: number,
    targetMin: number,
    targetMax: number,
}): number {
    // Normalize the value to [0, 1]
    const normalizedValue = (value - sourceMin) / (sourceMax - sourceMin);
    // Rescale the normalized value to [targetMin, targetMax]
    return clamp(
        (normalizedValue * (targetMax - targetMin)) + targetMin,
        targetMin,
        targetMax,
    );
}

/**
 * Rounds a number to the nearest specified decimal places.
 *
 * @param value - The number to round.
 * @param decimalPlaces - The number of decimal places to round to.
 * @returns The number rounded to the specified decimal places.
 */
export function roundToNearestNumber(value: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(value * factor) / factor;
}