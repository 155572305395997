import { fabric } from 'fabric';
import { noop, throttle, debounce, } from "lodash";
import { editorContextStore } from "contexts/editor-context";
import { RenderPipelineType } from "core/common/types/render-args";
import { getPromptFromTemplate } from "core/common/prompt-template";
import { getRenderPipelineType, getRenderPipelineArgs, getInputCompositeImageFromRenderPipelineArgs, getInputCompositeMaskImageFromRenderPipelineArgs } from "components/utils/render";
import { PromptTemplate, RealTimeRenderEditorInitEventHandler, RealTimeRenderStartRenderEventHandler, StateUpdater, UiDisplayMessageDialogEventHandler, UpdateObjectPropsEventHandler } from "core/common/types";
import { IRealTimeRenderController, RealTimeRenderMode, RealTimeRenderPipelineArgs, RealTimeRenderPipelineArgsResult, RealTimeRenderResultType } from "core/common/types/realtime-render";
import { isRealTimeRenderAvailable } from "core/utils/quota-utils";
import { RealTimeRenderWebSocketController } from "./realtime-render-websocket-controller";
import { UpdateObjectIndexEventHandler } from "core/common/types";
// import { downloadJson } from "components/utils/data";
import { debugError, debugLog } from "core/utils/print-utilts";
import { getObjectWorldCoords } from "core/utils/geometry-utils";
import { isBbox2dOverlapStrict } from 'core/utils/bbox-utils';
import { SceneJSON } from 'core/common/types/scene-json';

enum WebSocketRequestType {
    Render = 'ws-render-request',
}

enum WebSocketResponseType {
    RawRender = 'raw-render',
    RefColorCorrect = 'ref-color-correct',
    OverlayColorCorrect = 'overlay-color-correct',
    Message = 'message',
}


export function getRealTimeRenderResultTypeFromWebSocketResponseType(
    responseType: WebSocketResponseType,
): RealTimeRenderResultType {
    switch (responseType) {
        case WebSocketResponseType.RawRender:
            return RealTimeRenderResultType.RawRender;
        case WebSocketResponseType.RefColorCorrect:
            return RealTimeRenderResultType.RefColorCorrect;
        case WebSocketResponseType.OverlayColorCorrect:
            return RealTimeRenderResultType.OverlayColorCorrect;
        case WebSocketResponseType.Message:
        default:
            return RealTimeRenderResultType.None;
    }
}

function animateObjectToNewPosition({
    object,
    left,
    top,
    animationOptions,
}: {
    object: fabric.Object,
    left: number,
    top: number,
    animationOptions: fabric.IAnimationOptions,
}) {
    return new Promise<fabric.Object>((resolve) => {
        object.animate(
            {
                left,
                top,
            },
            {
                ...animationOptions,
                onComplete: () => {
                    animationOptions.onComplete?.();
                    resolve(object);
                },
            },
        );
    });
}

class RealTimeOutputFrameCleaner {
    private isClearing = false;

    private async clearOutObjectsInternal() {
        const canvas = editorContextStore.getState().editor?.canvas;
        if (!canvas) {
            debugLog('Canvas not found.');
            return null;
        }
        const objectsList = editorContextStore.getState().editor?.objects.list();

        const { editor } = editorContextStore.getState();
        const generationFrames = editor?.generationFrames;

        if (!generationFrames) {
            return;
        }

        const generationFrame = generationFrames.generationFrame;
        if (!generationFrame) {
            return;
        }
        const frameWidth = generationFrame.getScaledWidth();

        const frameCoords = getObjectWorldCoords(generationFrame, true);
        const frameCoordsModified = frameCoords.map(point => new fabric.Point(point.x + frameWidth, point.y));

        const frameCoordsTl = frameCoords[0];
        const frameCoordsBr = frameCoords[2];
        const frameCoordsModifiedTl = frameCoordsModified[0];
        const frameCoordsModifiedBr = frameCoordsModified[2];

        if (!frameCoordsTl ||
            !frameCoordsBr ||
            !frameCoordsModifiedTl ||
            !frameCoordsModifiedBr) {
            return;
        }

        if (!objectsList) {
            return;
        }

        await Promise.all(objectsList.map(async (object) => {
            const objectCoordsTl = object.getCoords(true)[0];
            const objectCoordsBr = object.getCoords(true)[2];

            if (isBbox2dOverlapStrict(
                objectCoordsTl,
                objectCoordsBr,
                frameCoordsModifiedTl,
                frameCoordsModifiedBr,
            ) && !isBbox2dOverlapStrict(
                objectCoordsTl,
                objectCoordsBr,
                frameCoordsTl,
                frameCoordsBr,
            )) {
                object = await animateObjectToNewPosition({
                    object,
                    left: objectCoordsTl.x + frameWidth,
                    top: objectCoordsTl.y,
                    animationOptions: {
                        duration: 500,
                        onChange: canvas.requestRenderAll.bind(canvas),
                        easing: fabric.util.ease.easeOutCubic,
                    },
                });

                object.setCoords(true);
            }
        }));
    }

    clearOutObjectsOverlappingOutputFrame = async () => {
        if (this.isClearing) {
            return;
        }

        this.isClearing = true;

        try {
            this.clearOutObjectsInternal();
        } catch (error) {
            console.error(error);
        }

        this.isClearing = false;
    }

}

export class RealTimeRenderController implements IRealTimeRenderController {
    private _isRendering = false;

    private outputFrameCleaner = new RealTimeOutputFrameCleaner();

    private removeEventListeners = noop;

    private removeRenderEventListeners = noop;

    private removeWebSocketEventListeners = noop;

    private previousObjectIdsInsideGenerationFrame: Set<string> = new Set();

    private previousPrompt: string = "";

    private inputCompositeImageUrl: string = "";

    private inputCompositeMaskImageUrl: string = "";

    private inputSceneJson?: SceneJSON;

    private websocketController: RealTimeRenderWebSocketController = new RealTimeRenderWebSocketController();

    private hasFlushed = false;

    private prevRenderRequest: {
        pipelineType?: RenderPipelineType,
        pipelineArgs?: RealTimeRenderPipelineArgs,
        sceneJSON?: SceneJSON,
    } = {};

    get isWebsocketCreated() {
        return this.websocketController.isWebsocketCreated;
    }

    get websocketReadyState() {
        return this.websocketController.websocketReadyState;
    }

    constructor() {
        this.registerEventListeners();

        const { editor } = this;

        if (editor) {
            editor.emit<RealTimeRenderEditorInitEventHandler>(
                'realtime-render:init-editor',
            );
        } else {
            console.warn("Editor is not initialized");
        }
    }

    private get renderMode() {
        return editorContextStore.getState().realtimeRenderMode;
    }

    private get editor() {
        return editorContextStore.getState().editor;
    }

    private get backend() {
        return editorContextStore.getState().backend;
    }

    get renderInputImage() {
        return this.inputCompositeImageUrl;
    }

    get renderInputMaskImage() {
        return this.inputCompositeMaskImageUrl;
    }

    get renderInputSceneJson() {
        return this.inputSceneJson;
    }

    get ignoreRenderOnce() {
        return editorContextStore.getState().realtimeRenderIgnoreRenderOnceRef.current;
    }

    set ignoreRenderOnce(value: boolean) {
        editorContextStore.getState().realtimeRenderIgnoreRenderOnceRef.current = value;
    }

    destroy() {
        this.inputCompositeImageUrl = "";

        this.inputCompositeMaskImageUrl = "";

        this.removeEventListeners();

        this.removeRenderEventListeners();

        this.destroyWebSocket();
    }

    private updatePreviousObjectIds() {
        this.previousObjectIdsInsideGenerationFrame = new Set((
            this.editor?.generationFrames?.getObjectsIntersectingGenerationFrame().map(object => object.id)
        ) || []);

    }

    private updatePreviousPrompt() {
        const {
            generateToolPromptTemplate,
        } = editorContextStore.getState();
        this.previousPrompt = getPromptFromTemplate(generateToolPromptTemplate);
    }

    private updatePreviousRenderState() {
        try {


            this.updatePreviousObjectIds();

            this.updatePreviousPrompt();
        } catch (error) {

            debugError(error);

        }
    }


    private doesObjectIntersectGenerationFrame(object: fabric.Object) {
        if (this._isRendering) {
            return false;
        }

        if (!object) {
            return false;
        }

        // Check if the added object is intersecting the generation frame

        const generationFrames = this.editor?.generationFrames;

        if (!generationFrames) {
            return false;
        }

        if (!generationFrames.intersectsGenerationFrame(object)) {
            return false;
        }

        return true;
    }

    private handleObjectAdded = (e: fabric.IEvent<Event>) => {
        try {

            const object = e.target;

            if (!object) {
                return;
            }

            if (!this.doesObjectIntersectGenerationFrame(object)) {
                return;
            }

            this.render();

        } catch (error) {

            console.error(error);

        } finally {
            this.updatePreviousRenderState();
        }

    };

    private handleObjectRemoved = (e: fabric.IEvent<Event>) => {
        try {

            const object = e.target;
            if (!object) {
                // debugLog('No valid target object');
                return;
            }

            if (!this.previousObjectIdsInsideGenerationFrame.has(object.id)) {
                return;
            }

            this.render();

        } catch (error) {

            console.error(error);

        } finally {
            this.updatePreviousRenderState();
        }

    };


    private handleMoveToFrontOrBack = (params: { object: fabric.Object; }) => {
        try {
            if (!params.object) {
                return;
            }

            if (!this.previousObjectIdsInsideGenerationFrame.has(params.object.id)) {
                return;
            }

            this.render();

        } catch (error) {

            console.error(error);

        } finally {
            this.updatePreviousRenderState();
        }
    }

    private handleObjectModified = (e: fabric.IEvent<Event>) => {
        try {
            const object = e.target;
            if (!object) {
                return;
            }

            if (!this.previousObjectIdsInsideGenerationFrame.has(object.id) && !this.doesObjectIntersectGenerationFrame(object)) {
                // debugLog(`Object ${object.id} is not previously inside the generation frame nor is it currently inside the generation frame.`);
                return;
            }

            this.render();

        } catch (error) {

            console.error(error);

        } finally {
            this.updatePreviousRenderState();
        }
    }

    private handleObjectPropUpdated = ({
        objectId,
    }: {
        objectId: string,
    }) => {
        try {
            if (!objectId) {
                return;
            }

            const {
                editor,
            } = editorContextStore.getState();

            if (!editor) {
                return;
            }

            let shouldRender = false;

            if (this.previousObjectIdsInsideGenerationFrame.has(objectId)) {
                shouldRender = true;
            }

            if (!shouldRender) {

                const object = editor.objects.findOneById(objectId);

                shouldRender = this.doesObjectIntersectGenerationFrame(object);

            }

            this.render();

        } catch (error) {

            console.error(error);

        } finally {

        }

    }

    private handleGenerateToolPromptTemplate = throttle((generateToolPromptTemplate: PromptTemplate) => {
        const prompt = getPromptFromTemplate(generateToolPromptTemplate);

        if (!this.previousPrompt || !prompt) {
            this.previousPrompt = prompt;
            return;
        }

        if (prompt === this.previousPrompt) {
            return;
        }

        this.previousPrompt = prompt;

        this.render();

    }, 3000);

    private handleRenderModeActive() {
        this.removeRenderEventListeners();

        const { editor } = this;

        const canvas = editor?.canvas.canvas;

        if (canvas) {
            canvas.on('object:modified', this.handleObjectModified);
            canvas.on('object:added', this.handleObjectAdded);
            canvas.on('object:removed', this.handleObjectRemoved);
        }

        const unsubscribeGenerateToolPromptTemplate = editorContextStore.subscribe(
            state => state.generateToolPromptTemplate,
            this.handleGenerateToolPromptTemplate,
        );

        editor?.on<UpdateObjectPropsEventHandler>(
            'object:update-props',
            this.handleObjectPropUpdated,
        );
        editor?.on<UpdateObjectIndexEventHandler>('object:update-index', this.handleMoveToFrontOrBack);

        this.removeRenderEventListeners = () => {
            if (canvas) {
                canvas.off('object:modified', this.handleObjectModified);
                canvas.off('object:added', this.handleObjectAdded);
                canvas.off('object:removed', this.handleObjectRemoved);
            }

            editor?.off<UpdateObjectPropsEventHandler>(
                'object:update-props',
                this.handleObjectPropUpdated,
            );
            editor?.off<UpdateObjectIndexEventHandler>('object:update-index', this.handleMoveToFrontOrBack);


            unsubscribeGenerateToolPromptTemplate();
        }

        this.render();
    }

    private handleRenderModeDisabled() {
        this.removeRenderEventListeners();
        this.destroyWebSocket();
    }

    private registerEventListeners() {
        const unsubscribeRealtimeRenderMode = editorContextStore.subscribe(
            state => state.realtimeRenderMode,
            (renderMode) => {
                if (renderMode === RealTimeRenderMode.Active) {
                    this.handleRenderModeActive();
                } else if (renderMode === RealTimeRenderMode.Disabled) {
                    this.handleRenderModeDisabled();
                }
            }
        );

        const {
            editor,
        } = editorContextStore.getState();

        const handleStartRender = () => this.render();

        editor?.on<RealTimeRenderStartRenderEventHandler>(
            'realtime-render:start-render',
            handleStartRender,
        );

        this.removeEventListeners = () => {
            handleStartRender();
            unsubscribeRealtimeRenderMode();
        };
    }

    private isGenerationFrameVisible() {
        // Set the generation frames using the editor

        const generationFrames = this.editor?.generationFrames;

        if (!generationFrames) {
            return false;
        }

        return generationFrames.isGenerationFrameInsideViewport();
    }

    private didGenerationFrameChange() {
        return false;
    }

    private didObjectsInsideGenerationFrameChange() {
        return false;
    }

    private renderNeedsUpdate() {
        if (!this.isGenerationFrameVisible()) {
            return false;
        }

        if (!this.didGenerationFrameChange()) {
            return false;
        }

        if (!this.didObjectsInsideGenerationFrameChange()) {
            return false;
        }

        return true;
    }

    private async getRenderPipelineArgs(): Promise<{
        pipelineType?: RenderPipelineType,
        pipelineArgs?: RealTimeRenderPipelineArgs,
        sceneJSON?: SceneJSON,
    }> {
        const {
            editor,
            generateToolReferenceImage,
        } = editorContextStore.getState();

        if (!editor) {
            return {};
        }

        const {
            canvas,
            generationFrames,
        } = editor;

        if (!canvas) {
            return {};
        }

        if (!generationFrames) {
            return {};
        }

        const objectsInsideGenerationFrame = generationFrames.getObjectsIntersectingGenerationFrame();

        let pipelineType = getRenderPipelineType({
            objectsInsideGenerationFrame,
            generateToolReferenceImage,
        });

        if (pipelineType === RenderPipelineType.Hed) {
            pipelineType = RenderPipelineType.Canny;
        } else if (pipelineType === RenderPipelineType.RefHed) {
            pipelineType = RenderPipelineType.RefCanny;
        } else if (pipelineType === RenderPipelineType.RefColorHedInpaint) {
            pipelineType = RenderPipelineType.RefCanny;
        } else if (pipelineType === RenderPipelineType.ColorHedInpaint) {
            pipelineType = RenderPipelineType.Canny;
        }

        const {
            renderPipelineArgs: pipelineArgs,
            sceneJSON,
        } = await getRenderPipelineArgs({
            editor,
            pipelineType,
            readCanvasData: false,
        }) as RealTimeRenderPipelineArgsResult;

        if (!pipelineArgs) {
            return {};
        }

        if (pipelineArgs?.pipeline_type === RenderPipelineType.RefCanny || pipelineArgs?.pipeline_type === RenderPipelineType.RefHed) {
            pipelineArgs.ref_overlay_args = {
                'inpaint_start': 0.0,
                'inpaint_finish': 0.5,
                'denoising_start': 0.0,
                'denoising_end': 1.0,
            };
            pipelineArgs.num_inference_steps = 3;
            pipelineArgs.scheduler_timestep_indices = [1, 2];
        } else if (pipelineArgs) {
            pipelineArgs.num_inference_steps = 2;
            pipelineArgs.scheduler_timestep_indices = [0, 1];
        }

        pipelineArgs.update_controlnet_args = true;
        pipelineArgs.update_init_image_args = true;
        pipelineArgs.update_ip_adapter_or_prompt_args = true;
        pipelineArgs.use_previous_init_image_latents = false;
        pipelineArgs.composite_mask_channel_for_canny = 'B';
        pipelineArgs.composite_mask_channel_for_foreground = 'G';

        return {
            pipelineType,
            pipelineArgs,
            sceneJSON,
        };
    }

    private async getInputRenderRequestInternal() {
        const {
            pipelineType,
            pipelineArgs,
            sceneJSON,
        } = await this.getRenderPipelineArgs();

        this.prevRenderRequest.pipelineType = pipelineType;
        this.prevRenderRequest.pipelineArgs = pipelineArgs;
        this.prevRenderRequest.sceneJSON = sceneJSON;

        return {
            pipelineType,
            pipelineArgs,
            sceneJSON,
        };
    }

    private getFlushInputRequestInternal() {
        this.hasFlushed = true;
        return {
            flush: true,
        };
    }

    get open() {
        return this.websocketController.isWebsocketOpen;
    }

    private getInputRenderRequest = async () => {
        const {
            pipelineType,
            pipelineArgs,
            sceneJSON,
        } = await this.getInputRenderRequestInternal();

        if (!pipelineArgs) {
            return;
        }

        // Save the input composite image so that we can create the generated image
        this.inputCompositeImageUrl = getInputCompositeImageFromRenderPipelineArgs(
            pipelineArgs,
        ) || "";

        this.inputCompositeMaskImageUrl = getInputCompositeMaskImageFromRenderPipelineArgs(
            pipelineArgs
        ) || "";

        this.inputSceneJson = sceneJSON;

        this.hasFlushed = false;


        return {
            request_type: WebSocketRequestType.Render,
            renderPipelineType: pipelineType,
            renderPipelineArgs: pipelineArgs,
            flush: false,
        };
    }

    private flushRender = debounce(async () => {

        this.renderInternal({
            flush: true,
        });
    },
        1000,
        { leading: true, trailing: true }
    );

    private renderInternal = async ({
        flush = false
    }: {
        flush?: boolean,
    }) => {
        if (this.ignoreRenderOnce) {
            this.ignoreRenderOnce = false;

            debugLog('Ignore real-time render once');

            return {
                isRendered: false,
            };
        }


        this._isRendering = true;

        if (!this.isWebsocketCreated || this.websocketReadyState === WebSocket.CLOSED) {
            await this.createWebSocketConnection();
        }

        if (!this.open) {
            debugLog('Real-time websocket connection is not open yet');

            return {
                isRenderer: false,
            };
        }

        this.outputFrameCleaner.clearOutObjectsOverlappingOutputFrame();

        const {
            setRealtimeRenderProgress,
        } = editorContextStore.getState();

        setRealtimeRenderProgress(flush ? 0.66 : 0.33);

        const renderRequest = flush ?
            this.getFlushInputRequestInternal() :
            await this.getInputRenderRequest();

        this.websocketController.send(renderRequest);

        this._isRendering = false;

        return {
            isRendered: true,
        }
    }

    private colorCorrect = debounce(async () => {
        if (!this.hasFlushed) {
            return;
        }

        await this.websocketController.startColorCorrection();

        this.outputFrameCleaner.clearOutObjectsOverlappingOutputFrame();
    },
        1000,
        { leading: false, trailing: true },
    )

    render() {
        if (!this.isGenerationFrameVisible()) {
            debugLog('Generation frame is not visible.');
            return;
        }

        if (this.ignoreRenderOnce) {
            this.ignoreRenderOnce = false;
            return {
                isRendered: false,
            };
        }

        this.renderInternal({}).then(({
            isRendered = true,
        }) => {
            if (isRendered) {
                return this.flushRender();
            }
        });

        this.updatePreviousRenderState();
    }

    private destroyWebSocket = () => {
        if (!this.isWebsocketCreated) {
            return;
        }

        this.removeWebSocketEventListeners();
        this.websocketController.stop();
        this.websocketController.destroy();
    };

    private createWebSocketConnection() {
        return this.websocketController.start(async () => {
            this.websocketController.handleSendFrame();

            this.colorCorrect();

            return [
                this.getFlushInputRequestInternal(),
            ];
        });
    }

    static setRealtimeRenderMode(setMode: StateUpdater<RealTimeRenderMode>) {

        const {
            editor,
            userQuotas,
            realtimeRenderMode,
            setRealtimeRenderMode,
        } = editorContextStore.getState();

        const targetMode = typeof (setMode) === 'function' ? setMode(realtimeRenderMode) : setMode;

        if (targetMode === RealTimeRenderMode.Active) {

            const canUserRender = isRealTimeRenderAvailable(userQuotas);

            if (canUserRender) {

                setRealtimeRenderMode(targetMode);

            } else {

                setRealtimeRenderMode(RealTimeRenderMode.Disabled);

                editor?.emit<UiDisplayMessageDialogEventHandler>(
                    'ui:display-message-dialog',
                    'realtime-subscribe',
                    {},
                );

            }

        } else {

            setRealtimeRenderMode(targetMode);

        }
    }
}