import { editorContextStore } from "contexts/editor-context";
import { MobileBottomPanelState } from "core/common/types/mobile";

export function onAddObjectToCanvas() {

    editorContextStore.getState()
        .setMobileBottomPanelState(
            MobileBottomPanelState.Hide,
        );

}