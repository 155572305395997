import React from 'react';
import {
    HtmlCanvasController,
    Point,
    useNumUndoRedoCanvasBrushStrokesHistory,
} from './html-canvas-controller';
import { MouseButton, getMouseButtonFromPointerEvent } from 'core/common/constants';
import Events from 'core/common/events';
import { noop } from 'lodash';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { editorContextStore } from 'contexts/editor-context';
import { FloatTagZIndex } from 'components/constants/zIndex';
import { classNames } from 'core/utils/classname-utils';
import { Eraser, RefreshCcw } from 'lucide-react';
import { TryOnBrushSizeAdjust, TryOnPaintBrushCursor, TryOnTaskBarButton, TryOnToolBarRedoButton, TryOnToolBarUndoButton, TryOnToolbarDividerVertical } from './tryon-toolbar';
import { useTryOnClothCanvasController, useTryOnPersonCanvasController, useTryOnPersonHistoryRef } from 'contexts/tryon-editor-context';

function Loading() {
    return (
        <div className='w-full h-full flex flex-row items-center justify-center'>
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" /> <span className='w-2' /> Loading person editor ...
        </div>
    )
}

type TryOnPersonEditorInnerProps = {
    canvasBoundsWidth?: number,
    canvasBoundsHeight?: number,
    zoomScrollSensitivity?: number,
}

function TryOnPersonEditorInner({
    canvasBoundsWidth = 1000,
    canvasBoundsHeight = 1500,
    zoomScrollSensitivity = 0.2,
}: TryOnPersonEditorInnerProps) {
    const canvasContainerRef = React.useRef<HTMLDivElement | null>(null);
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const clothCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const canvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const clothCanvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const canvasControllerRef = useTryOnPersonCanvasController();
    const resizeObserverRef = React.useRef<ResizeObserver>();
    const renderFunctionRef = React.useRef<() => void>(noop);
    const isMiddlePointerDownRef = React.useRef(false);
    const pointerDownLocationRef = React.useRef<Point>({ x: 0, y: 0 });
    const pointerDownCanvasOriginRef = React.useRef<Point>({ x: 0, y: 0 });
    const isRenderingRef = React.useRef(false);

    const tryOnPersonPaintState = editorContextStore(state => state.tryOnPersonPaintState);
    const isBrushVisible = tryOnPersonPaintState === 'erasing';
    const isLeftPointerDownRef = React.useRef(false);
    const isPointerOverToolbarRef = React.useRef(false);
    const brushSize = editorContextStore(state => state.tryOnPersonBrushSize);
    const [isPointerOver, setIsPointerOver] = React.useState(false);

    const brushCursorRef = React.useRef<HTMLDivElement | null>(null);

    const pointerPositionRef = React.useRef<Point>({ x: 0, y: 0 });

    React.useEffect(() => {
        canvasControllerRef.current?.setBounds({
            left: 0,
            top: 0,
            right: canvasBoundsWidth,
            bottom: canvasBoundsHeight,
        });
    }, [canvasControllerRef, canvasBoundsWidth, canvasBoundsHeight]);

    React.useEffect(() => {
        const {
            setTryOnPersonPaintState,
        } = editorContextStore.getState();

        setTryOnPersonPaintState('idle');

        const canvas = canvasRef.current;
        const container = canvasContainerRef.current;
        if (!container || !canvas) {
            return;
        }

        const canvasController = canvasControllerRef.current;
        if (!canvasController) {
            return;
        }

        resizeObserverRef.current = new ResizeObserver((entries) => {
            const { width, height } = (entries[0] && entries[0].contentRect) || {};

            canvas.width = width;

            canvas.height = height;

            if (clothCanvasRef.current) {
                clothCanvasRef.current.width = width;
                clothCanvasRef.current.height = height;
            }

            canvasController.init();

            renderFunctionRef.current();
        });

        resizeObserverRef.current.observe(container);

        canvasContextRef.current = canvas.getContext('2d');

        if (!canvasContextRef.current) {
            return;
        }

        clothCanvasContextRef.current = clothCanvasRef.current?.getContext("2d") || null;

        canvasController.setContext(canvasContextRef.current);
        if (canvasControllerRef.current && clothCanvasContextRef.current) {
            canvasControllerRef.current.setClothContext(clothCanvasContextRef.current);
        }

        // Initialize render function

        renderFunctionRef.current = () => {
            const canvas = canvasRef.current;

            const ctx = canvasContextRef.current;

            if (!canvas || !ctx) {
                return;
            }

            canvasController.render();

            if (isRenderingRef.current) {
                requestAnimationFrame(renderFunctionRef.current);
            }
        }

        renderFunctionRef.current();

        return () => {
            resizeObserverRef.current?.unobserve(container);
        }
    }, [canvasControllerRef]);

    const onPointerDown = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        const container = canvasContainerRef.current;
        if (!container) {
            return;
        }

        const canvasController = canvasControllerRef.current;

        if (!canvasController) {
            return;
        }

        const mouseButton = getMouseButtonFromPointerEvent(e.nativeEvent);

        if (mouseButton === MouseButton.Middle) {

            isMiddlePointerDownRef.current = true;
            isRenderingRef.current = true;

            const { x, y } = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: container.offsetLeft,
                    y: container.offsetTop,
                }
            );

            pointerDownLocationRef.current.x = x;
            pointerDownLocationRef.current.y = y;

            pointerDownCanvasOriginRef.current = canvasController.getOrigin(pointerDownCanvasOriginRef.current);

            renderFunctionRef.current();

        } else if (mouseButton === MouseButton.Left) {

            isLeftPointerDownRef.current = true;

            const {
                tryOnPersonPaintState,
                tryOnPersonBrushSize,
            } = editorContextStore.getState();

            if (tryOnPersonPaintState !== 'idle' && clothCanvasRef.current && !isPointerOverToolbarRef.current) {
                isRenderingRef.current = true;
                const { left, top } = clothCanvasRef.current.getBoundingClientRect();

                const point = HtmlCanvasController.getPointerEventLocation(
                    e,
                    {
                        x: left,
                        y: top,
                    }
                );

                canvasControllerRef.current?.startBrushStroke({
                    point,
                    brushType: 'erase',
                    lineWidth: tryOnPersonBrushSize,
                    color: '',
                });

                renderFunctionRef.current();
            }
        }

    }, [canvasControllerRef]);

    const onPointerMove = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        const container = canvasContainerRef.current;
        if (!container) {
            return;
        }

        const canvasController = canvasControllerRef.current;

        if (!canvasController) {
            return;
        }

        const {
            tryOnPersonPaintState,
            tryOnPersonBrushSize,
        } = editorContextStore.getState();

        if (isLeftPointerDownRef.current) {


            if (tryOnPersonPaintState !== 'idle' && clothCanvasRef.current && !isPointerOverToolbarRef.current) {

                const { left, top } = clothCanvasRef.current.getBoundingClientRect();

                const point = HtmlCanvasController.getPointerEventLocation(
                    e,
                    {
                        x: left,
                        y: top,
                    }
                );
                canvasController.moveBrushStroke(point);

            }

        } else if (isMiddlePointerDownRef.current) {

            const { x, y } = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: container.offsetLeft,
                    y: container.offsetTop,
                }
            );

            const dx = x - pointerDownLocationRef.current.x;
            const dy = y - pointerDownLocationRef.current.y;

            const { x: x0, y: y0 } = pointerDownCanvasOriginRef.current;

            canvasController.setOrigin(
                x0 + dx,
                y0 + dy,
            );
        }

        if (tryOnPersonPaintState !== 'idle' && brushCursorRef.current) {

            const { clientX, clientY } = e;
            brushCursorRef.current.style.left = `${clientX - tryOnPersonBrushSize * 0.5}px`;
            brushCursorRef.current.style.top = `${clientY - tryOnPersonBrushSize * 0.5}px`;

            pointerPositionRef.current.x = clientX;
            pointerPositionRef.current.y = clientY;

        }
    }, [canvasControllerRef]);

    const onPointerUp = React.useCallback(() => {
        const {
            tryOnPersonPaintState,
        } = editorContextStore.getState();

        if (isLeftPointerDownRef.current && tryOnPersonPaintState !== 'idle') {
            canvasControllerRef.current?.endBrushStroke();
        }

        isMiddlePointerDownRef.current = false;
        isLeftPointerDownRef.current = false;
        isRenderingRef.current = false;
        renderFunctionRef.current();
    }, [canvasControllerRef]);

    const onPointerEnter = React.useCallback(() => {
        setIsPointerOver(true);
    }, []);

    const onPointerLeave = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        onPointerUp();
        renderFunctionRef.current();
        setIsPointerOver(false);
    }, [onPointerUp]);

    React.useEffect(() => {
        if (!brushCursorRef.current) {
            return;
        }
        brushCursorRef.current.style.left = `${pointerPositionRef.current.x - brushSize * 0.5}px`;
        brushCursorRef.current.style.top = `${pointerPositionRef.current.y - brushSize * 0.5}px`;
    }, [brushSize]);

    const onMouseWheel = React.useCallback((e: React.WheelEvent<HTMLDivElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }

        const canvasController = canvasControllerRef.current;

        if (!canvasController) {
            return;
        }

        const isCtrlKey = e.ctrlKey;

        if (isCtrlKey) {
            const { left, top } = canvas.getBoundingClientRect();

            const pointerPos = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: left,
                    y: top,
                },
            );

            canvasController.scaleAt(
                pointerPos,
                Math.exp((-e.deltaY / 120) * zoomScrollSensitivity)
            );

            renderFunctionRef.current();

        } else {
            const isTrackpad = Events.detectTrackpadUtil(e.nativeEvent);

            if (isTrackpad) {
                const deltaX = e.deltaX;
                const deltaY = e.deltaY;

                canvasController.move(
                    -deltaX,
                    -deltaY,
                );

                renderFunctionRef.current();
            }
        }

    }, [canvasControllerRef, zoomScrollSensitivity]);

    // console.log([isBrushVisible, isPointerOver, brushSize]);

    return (
        <div
            ref={canvasContainerRef}
            className='relative w-full h-full'
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onWheel={onMouseWheel}
        >
            <TryOnPaintBrushCursor
                ref={brushCursorRef}
                className='bg-zinc-500/50 border-zinc-800/20'
                style={{
                    display: isBrushVisible ? 'block' : 'none',
                    opacity: isPointerOver ? 1 : 0,
                    width: `${brushSize || 0}px`,
                    height: `${brushSize || 0}px`,
                }}
            />
            <canvas
                ref={canvasRef}
            />
            <canvas
                ref={clothCanvasRef}
                className='absolute left-0 top-0'
            />
        </div>
    )
}

export type TryOnPersonEditorProps = TryOnPersonEditorInnerProps;

export function TryOnPersonEditor(props: TryOnPersonEditorProps) {
    return <TryOnPersonEditorInner
        {...props}
    />;
}



export function TryOnClothPoseEditorTaskbar({
    children,
    className = '',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const clothCanvasControllerRef = useTryOnClothCanvasController();

    const personHistoryRef = useTryOnPersonHistoryRef();

    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);

    const tryOnPersonPaintState = editorContextStore(state => state.tryOnPersonPaintState);

    const tryOnModelId = editorContextStore(state => state.tryOnModelId);

    const tryOnPersonImageElement = editorContextStore(state => state.tryOnPersonImageElement);

    const brushSize = editorContextStore(state => state.tryOnPersonBrushSize);

    const setBrushSize = editorContextStore(state => state.setTryOnPersonBrushSize);

    const isLoading = tryOnEditorState === 'warping';

    const isDisabled = !tryOnModelId || !tryOnPersonImageElement || !tryOnPersonImageElement.src || tryOnEditorState !== 'idle';

    const tooltipMessage = React.useMemo(() => {

        if (!tryOnModelId) {
            return "Model is not ready. Please select a model pose first."
        }

        if (!tryOnPersonImageElement) {
            return "Model is not ready. Please select a model first."
        }

        if (tryOnEditorState === 'warping') {
            return "Fitting cloth onto the model ...";
        }

        if (tryOnEditorState === 'rendering') {
            console.log("Waiting for the rendering process to finish ...");
        }

        return "Click to fit cloth onto the model."
    }, [
        tryOnModelId,
        tryOnPersonImageElement,
        tryOnEditorState,
    ]);

    const {
        numUndos,
        numRedos,
    } = useNumUndoRedoCanvasBrushStrokesHistory({
        history: personHistoryRef.current,
    });

    if (!tryOnPersonImageElement) {
        return null;
    }

    return (
        <div
            {...props}
            className={classNames(
                className,
                'w-full flex items-center justify-center mb-4',
            )}
        >
            <div
                className='relative px-1.5 py-1.5 rounded-lg flex flex-row items-center justify-center justify-items-stretch text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity'
                style={{
                    zIndex: FloatTagZIndex,
                    pointerEvents: 'none',
                }}
            >
                <TryOnToolBarUndoButton
                    isDisabled={numUndos <= 0}
                    onClick={() => {
                        personHistoryRef.current?.undo();
                    }}
                />
                <TryOnToolBarRedoButton
                    isDisabled={numRedos <= 0}
                    onClick={() => {
                        personHistoryRef.current?.redo();
                    }}
                />
                <TryOnToolbarDividerVertical />
                <TryOnTaskBarButton
                    isActive={tryOnPersonPaintState === 'erasing'}
                    className='flex flex-row items-center justify-center'
                    contentChildren={
                        <div
                            className='flex flex-col'
                        >
                            <div className='text-zinc-300 mb-1'>
                                Cloth Erase Tool
                            </div>
                            <div className='text-zinc-500'>
                                Select this tool to erase cloth
                            </div>
                        </div>
                    }
                    onClick={() => {
                        editorContextStore.getState().setTryOnPersonPaintState((prevState) => {
                            if (prevState === 'erasing') {
                                return 'idle';
                            }
                            return 'erasing';
                        });
                    }}
                >
                    <Eraser
                        size={18}
                        className=''
                    />
                </TryOnTaskBarButton>
                <TryOnToolbarDividerVertical
                    className='hidden xl:block'
                />
                <TryOnBrushSizeAdjust
                    className='hidden xl:flex'
                    brushSize={brushSize}
                    setBrushSize={setBrushSize}
                />
                <TryOnToolbarDividerVertical
                    className='hidden xl:block'
                />
                <TryOnTaskBarButton
                    isDisabled={isDisabled}
                    className='group flex flex-row items-center justify-center'
                    contentChildren={tooltipMessage}
                    onClick={() => {
                        if (isDisabled) {
                            return;
                        }
                        clothCanvasControllerRef.current?.parseAndWarpClothImage();
                    }}
                >
                    <RefreshCcw
                        size={18}
                        className={classNames(
                            "lg:mr-2 transition-colors",
                            isDisabled ? "" : 'text-zinc-500 group-hover:text-zinc-300',
                            isLoading ? "animate-spin" : "",
                        )}
                    />
                    <span
                        className={classNames(
                            "hidden xl:block",
                            isDisabled ? "" : 'text-zinc-300 group-hover:text-zinc-200'
                        )}
                    >
                        {isLoading ?
                            "Fitting" :
                            "Fit Cloth"}
                    </span>
                </TryOnTaskBarButton>
            </div>
        </div>
    )
}