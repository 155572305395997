import React from "react"
import { NavBarContainer } from "../navbar/navbar-container"
import { NavBarUserProfile } from "../navbar/user-profile";
import { Logo } from "components/logo";

export function DashboardNavBar() {
    return (
        <NavBarContainer>
            <div className="px-3">
                <Logo />
            </div>
            <div className="flex-1 ml-8 flex flex-row justify-center items-center text-sm text-zinc-500">
            </div>
            <div>
                <NavBarUserProfile />
            </div>
        </NavBarContainer>
    );
}