import { IShortcutsManager } from "core/common/interfaces";
import { ExportGenerationFrameTemplateEventHandler } from "core/common/types";
import { Editor } from "core/editor";
import { ShortcutsUtils } from "core/utils/shortcuts-utils";

export class EditorShortcutsManager implements IShortcutsManager {

    private editor: Editor;

    constructor({
        editor,
    }: {
        editor: Editor,
    }) {
        this.editor = editor;
    }

    handleKeyDown(event: KeyboardEvent) {
        if (ShortcutsUtils.isCtrlZero(event)) {
            event.preventDefault()
            this.editor.zoom.zoom(1);
        } else if (ShortcutsUtils.isCtrlMinus(event)) {
            event.preventDefault()
            this.editor.zoom.zoomOutGrid()
        } else if (ShortcutsUtils.isCtrlEqual(event)) {
            event.preventDefault()
            this.editor.zoom.zoomInGrid()
        } else if (ShortcutsUtils.isCtrlOne(event)) {
            event.preventDefault()
            this.editor.zoom.zoomToOne()
        } else if (ShortcutsUtils.isShiftOne(event)) {
            event.preventDefault();
            this.editor.zoom.zoomToFit();
        } else if (ShortcutsUtils.isCtrlZ(event)) {
            this.editor.history.undo()
        } else if (ShortcutsUtils.isCtrlShiftZ(event)) {
            this.editor.history.redo()
        } else if (ShortcutsUtils.isCtrlY(event)) {
            this.editor.history.redo()
        } else if (ShortcutsUtils.isCtrlA(event)) {
            event.preventDefault()
            this.editor.objects.select()
        } else if (ShortcutsUtils.isDelete(event)) {
            event.preventDefault()
            this.editor.objects.remove()
        } else if (ShortcutsUtils.isCtrlC(event)) {
            event.preventDefault()
            this.editor.objects.copy()
        } else if (ShortcutsUtils.isCtrlV(event)) {
            event.preventDefault()
            this.editor.objects.paste()
        } else if (ShortcutsUtils.isCtrlX(event)) {
            event.preventDefault();
            this.editor.objects.cut();
        } else if (ShortcutsUtils.isCtrlS(event)) {
            event.preventDefault();
        } else if (ShortcutsUtils.isCtrlAltArrowUp(event)) {
            event.preventDefault();
            this.editor.objects.bringForward();
        } else if (ShortcutsUtils.isCtrlAltArrowDown(event)) {
            event.preventDefault();
            this.editor.objects.sendBackwards();
        } else if (ShortcutsUtils.isSquareBracketLeft(event)) {
            this.editor.objects.bringToFront();
        } else if (ShortcutsUtils.isSquareBracketRight(event)) {
            this.editor.objects.sendToBack();
        } else if (ShortcutsUtils.isCtrlE(event)) {
            event.preventDefault();
            this.editor.generationFrames.centerToObject();
        } else if (ShortcutsUtils.isCtrlAltK(event)) {
            event.preventDefault();
            this.editor.emit<ExportGenerationFrameTemplateEventHandler>(
                'generation-frame:export-template',
            );
        }
        return {
            isHandled: true,
        };
    }

}