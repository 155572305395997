import { IHistory } from 'core/common/interfaces';
import throttle from 'lodash/throttle';
import { MagicEraseCanvasSnapshot } from 'core/common/types';
import _ from 'lodash';
import { Editor } from 'core/editor';

type HistoryCommand = {
    type: 'UPDATE' | 'redo' | 'undo',
    snapshot: MagicEraseCanvasSnapshot,
}

export class MagicEraseHistory implements IHistory<MagicEraseCanvasSnapshot> {
    private editor: Editor | null;

    private redos: HistoryCommand[] = []
    private undos: HistoryCommand[] = []
    private current: MagicEraseCanvasSnapshot = {}

    private restoreCanvas: (snapshot: MagicEraseCanvasSnapshot) => void

    private getCanvasSnapshot: () => (MagicEraseCanvasSnapshot | undefined)

    constructor({
        editor,
        restoreCanvas,
        getCanvasSnapshot,
    }: {
        editor: Editor | null,
        getCanvasSnapshot: () => (MagicEraseCanvasSnapshot | undefined),
        restoreCanvas: (snapshot: MagicEraseCanvasSnapshot) => void,
    }) {
        this.editor = editor;
        this.restoreCanvas = restoreCanvas;
        this.getCanvasSnapshot = getCanvasSnapshot;

    }

    initialize() { }

    destroy() {
        this.redos.length = 0;
        this.undos.length = 0;
        this.current = {};
    }

    private getSnapshot(snapshot?: MagicEraseCanvasSnapshot): MagicEraseCanvasSnapshot {
        if (snapshot) {
            return snapshot;
        }

        return this.getCanvasSnapshot?.() || {};
    }

    public save = (snapshot?: MagicEraseCanvasSnapshot) => {
        try {

            snapshot = this.getSnapshot(snapshot);
            if (snapshot) {
                this.undos.push({
                    type: 'UPDATE',
                    snapshot: _.cloneDeep(this.current),
                });
                this.current = _.cloneDeep(snapshot);
            }
        } catch (error) {
            console.log(error);
        }
    }

    public undo = throttle(() => {
        if (this.undos.length >= 1) {
            const undo = this.undos.pop()
            if (!undo) {
                return
            }
            this.redos.push({
                type: "redo",
                snapshot: _.cloneDeep(this.current),
            })
            this.restore(undo)
        }
    }, 100)

    public redo = throttle(() => {
        console.log('Redo magic erase');
        const redo = this.redos.pop()
        if (!redo) {
            return
        }
        this.undos.push({
            type: "undo",
            snapshot: _.cloneDeep(this.current),
        })
        this.restore(redo)
    }, 100)


    private restore(command: HistoryCommand) {
        this.current = { ...command.snapshot };
        this.restoreCanvas?.(command.snapshot);
    }

    public reset = () => {
        this.redos = []
        this.undos = []
    }
}