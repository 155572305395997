import { noop } from 'lodash';
import React from 'react';

export const StepByStepTutorialContext = React.createContext<{
    step: number,
    setStep: (value: number | ((v: number) => number)) => void,
}>({
    step: 0,
    setStep: noop,
});

export function useStepByStepTutorialContext() {
    return React.useContext(StepByStepTutorialContext);
}