import { fabric } from "fabric"
import { Base } from "core/controllers/base";
import { LeftPanelItemType } from "components/constants/editor-options";
import { editorContextVanillaStore } from "contexts/editor-context";
import { ControllerOptions } from "../interfaces";
import { ColorAttentionMaskMap, GenerateToolPaintTab } from "../types";

export class VisualizeColorMaskHandler extends Base {
    private unsubscribe: () => void;

    constructor(props: ControllerOptions) {
        super(props);

        const unsub0 = editorContextVanillaStore.subscribe(
            state => state.generateToolIsRendering,
            () => {
                this.handleVisualizeColorAttentionMask();
            }
        );

        const unsub1 = editorContextVanillaStore.subscribe(
            state => state.activeLeftPanels,
            () => {
                this.handleVisualizeColorAttentionMask();
            }
        );

        const unsub2 = editorContextVanillaStore.subscribe(
            state => state.generateToolPaintTab,
            (generateToolPaintTab) => {
                const {
                    activeLeftPanels,
                    inpaintBrushColor,
                    generateToolIsRendering,
                } = editorContextVanillaStore.getState();

                const shouldHighlight = VisualizeColorMaskHandler.shouldHighlightColorAttentionMask(activeLeftPanels, generateToolPaintTab, generateToolIsRendering);

                if (shouldHighlight) {
                    this.highlightColorAttentionMask(inpaintBrushColor);
                } else {
                    this.unhighlightColorAttentionMask(inpaintBrushColor);
                }
                this.editor?.canvas.requestRenderAll();
            }
        );

        const unsub3 = editorContextVanillaStore.subscribe(
            state => state.inpaintBrushColor,
            (inpaintBrushColor, prevInpaintBrushColor) => {
                const { activeLeftPanels, generateToolPaintTab, generateToolIsRendering } = editorContextVanillaStore.getState();
                this.unhighlightColorAttentionMask(prevInpaintBrushColor);
                if (VisualizeColorMaskHandler.shouldHighlightColorAttentionMask(activeLeftPanels, generateToolPaintTab, generateToolIsRendering)) {
                    this.highlightColorAttentionMask(inpaintBrushColor);
                }
                this.editor?.canvas.requestRenderAll();
            }
        );

        const unsub4 = editorContextVanillaStore.subscribe(
            state => state.generateToolColorAttentionMaskMap,
            () => {
                const { inpaintBrushColor, activeLeftPanels, generateToolPaintTab, generateToolIsRendering } = editorContextVanillaStore.getState();
                if (VisualizeColorMaskHandler.shouldHighlightColorAttentionMask(activeLeftPanels, generateToolPaintTab, generateToolIsRendering)) {
                    this.highlightColorAttentionMask(inpaintBrushColor);
                    this.editor?.canvas.requestRenderAll();
                }
            },
        );

        this.unsubscribe = () => {
            unsub0?.();
            unsub1?.();
            unsub2?.();
            unsub3?.();
            unsub4?.();
        }
    }

    public destroy() {
        return this.unsubscribe();
    }

    private handleVisualizeColorAttentionMask() {
        const { activeLeftPanels, inpaintBrushColor, generateToolPaintTab, generateToolIsRendering } = editorContextVanillaStore.getState();

        const shouldHighlight = VisualizeColorMaskHandler.shouldHighlightColorAttentionMask(activeLeftPanels, generateToolPaintTab, generateToolIsRendering);

        if (shouldHighlight) {
            this.highlightColorAttentionMask(inpaintBrushColor);
        } else {
            this.unhighlightColorAttentionMask(inpaintBrushColor);
        }
        this.editor?.canvas.requestRenderAll();
    }

    static shouldHighlightColorAttentionMask(activeLeftPanels: LeftPanelItemType[], generateToolPaintTab: GenerateToolPaintTab, generateToolIsRendering: boolean) {
        return !generateToolIsRendering && (activeLeftPanels.findIndex(e => e === 'Generate') > -1) && generateToolPaintTab === 'Tags';
    }

    private unhighlightColorAttentionMask(color: string, generateToolColorAttentionMaskMap?: ColorAttentionMaskMap) {
        generateToolColorAttentionMaskMap = generateToolColorAttentionMaskMap || editorContextVanillaStore.getState()?.generateToolColorAttentionMaskMap;

        const attentionMaskMaps = generateToolColorAttentionMaskMap?.[color];
        if (attentionMaskMaps) {
            attentionMaskMaps.images.forEach(id => {
                this.editor.objects.findOneById(id)?.set('shadow', null);
            });
        }
    }

    private highlightColorAttentionMask(color: string, generateToolColorAttentionMaskMap?: ColorAttentionMaskMap) {
        generateToolColorAttentionMaskMap = generateToolColorAttentionMaskMap || editorContextVanillaStore.getState()?.generateToolColorAttentionMaskMap;

        const attentionMaskMaps = generateToolColorAttentionMaskMap?.[color];
        if (attentionMaskMaps) {
            attentionMaskMaps.images.forEach(id => {
                this.editor.objects.findOneById(id)?.set('shadow', new fabric.Shadow({
                    blur: 15,
                    offsetX: 0,
                    offsetY: 0,
                    color,
                }));
            });
        }
    }
}