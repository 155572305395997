import React from "react"
import { fabric } from "fabric"
import { EditorCanvasModalContainer } from "../editor-modal/editor-canvas-modal";
import { editorContextStore } from "contexts/editor-context";
import { removeLastFromImmutableList } from "core/utils/array-utils";
import { REPLACE_PRODUCT_CANVAS_ID } from "components/constants/ids";
import { isStaticImageObject } from "core/utils/type-guards";
import { DEFAULT_VIEWPORT_TRANSFORM, DEFAULT_ZOOM_SENSITIVITY, RENDER_CANVAS_LEGNTH, defaultControllerOptions } from "core/common/constants";
import Events from "core/common/events";
import { duplicateAndAddFabricObject } from "core/utils/fabric";
import Zoom from "core/controllers/zoom";
import { getCenterFromBounds, getObjectsBounds } from "core/utils/bbox-utils";
import { isValidHttpsUrl } from "core/utils/string-utils";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { GenerateStrength, ObjectBounds2d } from "core/common/types";
import { classNames } from "core/utils/classname-utils";
import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { displayUiMessage } from "components/utils/display-message";
import { downloadDataUrl } from "components/utils/data";
import { ArrowRight } from "lucide-react";
import { resetRegenerateProductState, useRegenerateProductChangeEffect } from "hooks/use-regenerate-product";
import { callEraseProductBackend, setProjectThumbnailAsImageObject } from "components/utils/render";
import { ShortcutsUtils } from "core/utils/shortcuts-utils";

const defaultEditorConfig: fabric.ICanvasOptions = {
    backgroundColor: undefined,
    height: 980,
    width: 1024,
}

type SaveCanvasCoords = {
    sx: number,
    sy: number,
    sw: number,
    sh: number,
    dx: number,
    dy: number,
    dw: number,
    dh: number,
    width: number,
    height: number,
}



class ReplaceProductEditor {
    static ProductImageObjectId = "product-image-object";

    static BackgroundImageObjectId = "background-image-object";

    static BackgroundMaskImageObjectId = "background-mask-image-object";

    private canvas: fabric.Canvas;

    private options: fabric.ICanvasOptions;

    private unsubscribeEvents = () => { };

    private boundingBox?: ObjectBounds2d;

    private whiteFilter: fabric.IBlendImageFilter;

    private undos: string[] = [];

    private redos: string[] = [];

    private currentState: string | undefined;

    private maxCount = 100;

    private locked = false;

    private findObjectById(id: string) {
        return this.canvas._objects.find(o => o.id === id);
    }

    get productImageObject() {
        return this.findObjectById(ReplaceProductEditor.ProductImageObjectId);
    }

    get backgroundImageObject() {
        return this.findObjectById(ReplaceProductEditor.BackgroundImageObjectId);
    }

    get backgroundMaskImageObject() {
        return this.findObjectById(ReplaceProductEditor.BackgroundMaskImageObjectId);
    }

    constructor({
        canvasId = REPLACE_PRODUCT_CANVAS_ID,
        options = {},
    }: {
        canvasId?: string,
        options?: fabric.ICanvasOptions
    }) {
        this.options = {
            ...defaultEditorConfig,
            ...options,
        }
        this.canvas = new fabric.Canvas(
            canvasId,
            this.options,
        );

        this.canvas.on(
            'mouse:wheel',
            this.onMouseWheel,
        );

        this.canvas.on(
            'object:modified',
            this.onObjectModified,
        );

        this.unsubscribeEvents = () => {

            this.canvas.off(
                'mouse:wheel',
                this.onMouseWheel,
            );

            this.canvas.off(
                'object:modified',
                this.onObjectModified,
            );

        };
        this.whiteFilter = new fabric.Image.filters.BlendColor({
            color: 'white',
            mode: 'tint',
            alpha: 1.0,
        });


    }

    private onObjectModified = () => {
        this.saveState();
    }

    saveState() {
        if (this.locked) {
            return;
        }

        if (this.undos.length === this.maxCount) {
            //Drop the oldest element
            this.undos.shift();
        }

        //Add the current state
        if (this.currentState) {
            this.undos.push(
                this.currentState
            );
        }

        //Make the state of the canvas the current state
        this.currentState = this.canvas.toDatalessJSON([
            'id',
            'hasControls',
            'selectable',
            'moveCursor',
            'hoverCursor',
            'lockMovementX',
            'lockMovementY',
            'lockRotation',
            'lockScalingX',
            'lockScalingY',
            'lockSkewingX',
            'lockSkewingY',
        ]) as any as string;

        //Reset the redo stack.
        //We can only redo things that were just undone.
        this.redos.length = 0;

        return this.currentState;
    }

    undo() {
        if (this.undos.length > 0) {
            return this.applyState(this.redos, this.undos.pop());
        }
        return Promise.resolve();
    }

    //Pop the most recent redo state. Use the specified callback method.
    redo() {
        if (this.redos.length > 0) {
            return this.applyState(this.undos, this.redos.pop());
        }
        return Promise.resolve();
    }

    async applySavedState(newState: string) {
        this.redos.length = 0;
        await this.applyState(this.undos, newState);
        if (this.backgroundImageObject) {
            this.zoomToFit(this.backgroundImageObject);
        }
    }

    private applyState(stack: string[], newState: string | undefined) {
        return new Promise<void>((resolve) => {
            if (this.currentState) {
                //Push the current state
                stack.push(this.currentState);
            }

            //Make the new state the current state
            this.currentState = newState;

            //Lock the stacks for the incoming change
            this.locked = true;

            //Update canvas with the new current state
            this.canvas.loadFromJSON(this.currentState, () => {
                // Check if the product image is restored

                const { productImageObject } = this;

                if (productImageObject) {
                    const {
                        setReplaceProductInputImagePath,
                    } = editorContextStore.getState();
                    setReplaceProductInputImagePath((productImageObject as fabric.Image).getSrc());
                }

                //Unlock the stacks
                this.locked = false;

                resolve();
            });
        });

    }

    destroy() {
        this.unsubscribeEvents();
        this.canvas.dispose();
        this.undos.length = 0;
        this.redos.length = 0;
    }

    resize({
        width,
        height,
    }: {
        width: number,
        height: number,
    }) {
        this.canvas?.setWidth(width).setHeight(height)
        this.canvas?.renderAll()
        const diffWidth = width / 2 - (this.options?.width || width) / 2
        const diffHeight = height / 2 - (this.options?.height || height) / 2

        this.options.width = width
        this.options.height = height

        const deltaPoint = new fabric.Point(diffWidth, diffHeight)
        this.canvas?.relativePan(deltaPoint);
    }

    static minZoom = 50;
    static maxZoom = 200;

    private static getZoomRatio(zoom: number) {
        const minZoom = ReplaceProductEditor.minZoom;
        const maxZoom = ReplaceProductEditor.maxZoom;
        let zoomRatio = zoom
        if (zoom <= minZoom / 100) {
            zoomRatio = minZoom / 100
        } else if (zoom >= maxZoom / 100) {
            zoomRatio = maxZoom / 100
        }
        return zoomRatio;
    }

    private zoomInternal(
        center: { x: number, y: number },
        zoomFitRatio: number,
    ) {
        const zoomRatio = ReplaceProductEditor.getZoomRatio(zoomFitRatio);
        const defaultTransform = [1, 0, 0, 1, 0, 0];
        defaultTransform[0] = zoomRatio;
        defaultTransform[3] = zoomRatio;
        defaultTransform[4] = ((this.canvas.getWidth() / zoomRatio / 2) - center.x) * zoomRatio;
        defaultTransform[5] = ((this.canvas.getHeight() / zoomRatio / 2) - center.y) * zoomRatio;
        this.canvas.setViewportTransform(defaultTransform);
    }

    zoomToPoint(point: fabric.Point, zoom: number) {
        const zoomRatio = ReplaceProductEditor.getZoomRatio(zoom);
        this.canvas.zoomToPoint(point, zoomRatio)
    }

    zoomToFit(
        object: fabric.Object | fabric.Object[],
        frameMargin = 180
    ) {
        let bounds: ObjectBounds2d = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        };

        if (Array.isArray(object) && object.length <= 0) {
            return;
        }

        if (Array.isArray(object)) {
            bounds = getObjectsBounds(object) || bounds;
        } else {
            bounds = {
                left: object.left || 0,
                top: object.top || 0,
                width: object.width || 0,
                height: object.height || 0,
            }
        }

        const zoomFitRatio = Zoom.getBBoxZoomFitRatio({
            ...bounds,
            canvas: this.canvas,
            frameMargin,
        });

        const center = getCenterFromBounds(bounds);
        this.zoomInternal(center, zoomFitRatio);
    }

    handleZoom = (event: fabric.IEvent<any>) => {
        const delta = event.e.deltaY
        let zoomRatio = this.canvas.getZoom()
        if (delta > 0) {
            zoomRatio -= DEFAULT_ZOOM_SENSITIVITY
        } else {
            zoomRatio += DEFAULT_ZOOM_SENSITIVITY
        }
        this.zoomToPoint(new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2), zoomRatio)
        event.e.preventDefault()
        event.e.stopPropagation()
    }

    onMouseWheel = (event: fabric.IEvent<any>) => {
        const isCtrlKey = event.e.ctrlKey;
        if (isCtrlKey) {
            this.handleZoom(event);
        } else {
            const isTrackpad = Events.detectTrackpadUtil(event.e);

            if (isTrackpad) {
                const viewportTransform = this.canvas.viewportTransform;
                const deltaX = event.e?.deltaX;
                const deltaY = event.e?.deltaY;
                if (viewportTransform && deltaX != null && deltaY != null) {
                    viewportTransform[4] -= deltaX;
                    viewportTransform[5] -= deltaY;
                    this.canvas.setViewportTransform(viewportTransform);
                    this.canvas.requestRenderAll();
                }
            }
        }
    }

    async duplicateAndAddObject(object: fabric.Object) {
        const duplicateObjects = await duplicateAndAddFabricObject({
            canvas: this.canvas,
            object,
        });

        this.canvas.requestRenderAll();

        return duplicateObjects;
    }

    async addBackgroundImageObject({
        imageObject,
    }: {
        imageObject: fabric.StaticImage,
    }) {
        const {
            editor,
            backend,
        } = editorContextStore.getState();

        if (!editor || !backend) {
            return;
        }

        const generationId = imageObject.generationId;

        const pastGeneration = generationId ? await editor.assets.getPastGeneration(generationId) : undefined;

        let regenerateErasedImagePath: string | undefined = pastGeneration?.regenerateErasedImagePath;
        let regenerateMaskImagePath: string | undefined = pastGeneration?.regenerateMaskImagePath;

        if (!regenerateErasedImagePath || !regenerateMaskImagePath) {


            // Erase the product first
            const response = await callEraseProductBackend({
                editor,
                backend,
                activeObject: imageObject as any as fabric.Object,
            });

            regenerateErasedImagePath = response?.erased_image;

            regenerateMaskImagePath = response?.mask_image;

        }

        if (!regenerateErasedImagePath || !regenerateMaskImagePath) {
            return;
        }

        const [
            erasedImageUrl,
            maskImageUrl,
        ] = await Promise.all([
            editor.assets.loadAsset({
                path: regenerateErasedImagePath,
            }),
            editor.assets.loadAsset({
                path: regenerateMaskImagePath,
            }),
        ]);

        if (!erasedImageUrl || !maskImageUrl) {
            return;
        }

        backend.getMaskImageBoundingBox({
            imageUrl: maskImageUrl,
        })?.then((bbox) => {
            this.boundingBox = bbox;
        });

        const image = await fabric.StaticImage.fromURL(
            erasedImageUrl,
            {
                id: ReplaceProductEditor.BackgroundImageObjectId,
                hasControls: false,
                selectable: false,
                moveCursor: 'auto',
                hoverCursor: 'auto',
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockSkewingX: true,
                lockSkewingY: true,
                asset: {
                    type: 'image-url',
                    path: erasedImageUrl,
                },
            }
        );

        this.canvas.add(image as any as fabric.Object);

        this.zoomToFit(image as any as fabric.Object);

        const maskImage = await fabric.StaticImage.fromURL(
            maskImageUrl,
            {
                id: ReplaceProductEditor.BackgroundMaskImageObjectId,
                left: image.left,
                top: image.top,
                width: image.width,
                height: image.height,
                hasControls: false,
                selectable: false,
                moveCursor: 'auto',
                hoverCursor: 'auto',
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockSkewingX: true,
                lockSkewingY: true,
                asset: {
                    type: 'image-url',
                    path: erasedImageUrl,
                },
            }
        );

        this.canvas.add(maskImage as any as fabric.Object);

        maskImage.visible = false;

        return image;
    }

    async setProductImage(imageUrl: string) {
        if (!imageUrl) {
            return;
        }

        const { productImageObject, backgroundImageObject } = this;

        if (productImageObject) {

            if ((productImageObject as fabric.Image).getSrc() === imageUrl) {
                return;
            }

            // Remove the product image from the scene

            this.boundingBox = {
                left: productImageObject.left || 0,
                top: productImageObject.top || 0,
                width: productImageObject.width || 0,
                height: productImageObject.height || 0,
            }

            this.canvas.remove(productImageObject as any as fabric.Object);
        }

        const productImage = await fabric.StaticImage.fromURL(
            imageUrl,
            {
                ...defaultControllerOptions,
                id: ReplaceProductEditor.ProductImageObjectId,
                asset: {
                    type: 'image-url',
                    path: imageUrl,
                }
            }
        )

        this.canvas.add(productImage as any as fabric.Object);
        this.canvas.bringToFront(productImage as any as fabric.Object);
        this.canvas.setActiveObject(productImage as any as fabric.Object);

        if (!backgroundImageObject) {
            return;
        }

        if (this.boundingBox && this.boundingBox.width > 0 && this.boundingBox.height > 0) {
            const {
                left,
                top,
                width,
                height,
            } = this.boundingBox;

            const bottomCenter = {
                x: (left + width / 2),
                y: top + height,
            }

            const productWidth = productImage.width || 0;
            const productHeight = productImage.height || 0;

            const backgroundWidth = backgroundImageObject.getScaledWidth() || 0;
            const backgroundHeight = backgroundImageObject.getScaledHeight() || 0;

            if (productWidth > backgroundWidth / 2 || productHeight > backgroundHeight / 2) {
                const scale = Math.min(
                    backgroundWidth / 2 / productWidth,
                    backgroundHeight / 2 / productHeight,
                );
                productImage.scale(scale);
            }

            productImage.left = bottomCenter.x - (productImage.getScaledWidth() || 0) * 0.5;
            productImage.top = bottomCenter.y - (productImage.getScaledHeight() || 0);
            productImage.setCoords();
        }

        this.canvas.requestRenderAll();
    }

    private async centerToBackground(
        callback: () => Promise<void>
    ) {
        const {
            backgroundImageObject,
        } = this;

        if (!backgroundImageObject) {
            return;
        }

        const prevViewportTransform = this.canvas.viewportTransform?.slice() || DEFAULT_VIEWPORT_TRANSFORM;

        this.zoomToFit(
            backgroundImageObject as any as fabric.Object,
            0,
        );

        await callback();

        this.canvas.setViewportTransform(prevViewportTransform);
    }

    private getSaveCanvasCoords(): SaveCanvasCoords | undefined {
        const {
            backgroundImageObject,
        } = this;

        backgroundImageObject?.setCoords();

        const oCoords = backgroundImageObject?.oCoords;

        if (!oCoords) {
            return;
        }

        const { tl, br } = oCoords;

        if (!tl || !br) {
            return;
        }

        const sx = tl.x;
        const sy = tl.y;
        const sw = br.x - tl.x;
        const sh = br.y - tl.y;

        if (sw <= 0 || sh <= 0) {
            return;
        }

        const scale = RENDER_CANVAS_LEGNTH / Math.max(sw, sh);

        const width = Math.round(sw * scale);
        const height = Math.round(sh * scale);

        const dx = 0;
        const dy = 0;
        const dw = width;
        const dh = height;

        return {
            sx,
            sy,
            sw,
            sh,
            dx,
            dy,
            dw,
            dh,
            width,
            height,
        };
    }

    private getCanvasImageUrl({
        sx,
        sy,
        sw,
        sh,
        dx,
        dy,
        dw,
        dh,
        width,
        height,
    }: SaveCanvasCoords) {
        const {
            backgroundMaskImageObject,
        } = this;

        const tmpCanvas1 = fabric.util.createCanvasElement();
        tmpCanvas1.width = width;
        tmpCanvas1.height = height;
        const ctx1 = tmpCanvas1.getContext('2d');

        if (!ctx1) {
            return;
        }

        if (backgroundMaskImageObject) {
            backgroundMaskImageObject.visible = false;
        }

        const multiplier = Math.max(width / sw, height / sh);

        const canvasElement = this.canvas.toCanvasElement(
            multiplier,
            {
                left: sx,
                top: sy,
                width: sw,
                height: sh,
            }
        );

        ctx1.drawImage(
            canvasElement,
            0, 0,
            canvasElement.width, canvasElement.height,
            dx, dy,
            dw, dh,
        );

        return tmpCanvas1.toDataURL('image/png');
    }

    private getProductImageUrl({
        sx,
        sy,
        sw,
        sh,
        dx,
        dy,
        dw,
        dh,
        width,
        height,
    }: SaveCanvasCoords) {
        const {
            productImageObject,
            backgroundImageObject,
            backgroundMaskImageObject,
        } = this;

        if (!productImageObject) {
            return;
        }

        const tmpCanvas1 = fabric.util.createCanvasElement();
        tmpCanvas1.width = width;
        tmpCanvas1.height = height;
        const ctx1 = tmpCanvas1.getContext('2d');

        if (!ctx1) {
            return;
        }

        if (backgroundImageObject) {
            backgroundImageObject.visible = false;
        }

        if (backgroundMaskImageObject) {
            backgroundMaskImageObject.visible = false;
        }

        const multiplier = Math.max(width / sw, height / sh);

        const canvasElement = this.canvas.toCanvasElement(
            multiplier,
            {
                left: sx,
                top: sy,
                width: sw,
                height: sh,
            }
        );

        ctx1.drawImage(
            canvasElement,
            0, 0,
            canvasElement.width, canvasElement.height,
            dx, dy,
            dw, dh,
        );

        if (backgroundImageObject) {
            backgroundImageObject.visible = true;
        }

        return tmpCanvas1.toDataURL('image/png');
    }

    private getCanvasMaskImageUrl({
        sx,
        sy,
        sw,
        sh,
        dx,
        dy,
        dw,
        dh,
        width,
        height,
    }: SaveCanvasCoords) {
        const {
            productImageObject,
            backgroundImageObject,
            backgroundMaskImageObject,
        } = this;

        if (!backgroundImageObject || !backgroundMaskImageObject || !productImageObject || !isStaticImageObject(productImageObject)) {
            return;
        }

        backgroundImageObject.visible = false;
        backgroundMaskImageObject.visible = true;

        const tmpCanvas1 = fabric.util.createCanvasElement();
        tmpCanvas1.width = width;
        tmpCanvas1.height = height;
        const ctx1 = tmpCanvas1.getContext('2d');

        if (!ctx1) {
            return;
        }

        const multiper = Math.max(width / sw, height / sh);

        const prevFilters = productImageObject.filters;

        productImageObject.filters = [
            this.whiteFilter,
        ];

        productImageObject.applyFilters();

        const canvasElement = this.canvas.toCanvasElement(
            multiper,
            {
                left: sx,
                top: sy,
                width: sw,
                height: sh,
            }
        );

        ctx1.drawImage(
            canvasElement,
            0, 0,
            canvasElement.width, canvasElement.height,
            dx, dy,
            dw, dh,
        );

        backgroundImageObject.visible = true;
        backgroundMaskImageObject.visible = false;

        productImageObject.filters = prevFilters;
        productImageObject.applyFilters();

        return tmpCanvas1.toDataURL('image/jpeg');
    }

    async saveCanvas() {
        this.locked = true;
        const {
            backgroundImageObject,
            productImageObject,
        } = this;
        if (!backgroundImageObject) {
            displayUiMessage(
                "No valid background image in the canvas",
                "info",
            );
            return;
        }

        if (!productImageObject) {
            displayUiMessage(
                "No valid product image in the canvas.",
                "info",
            );
            return;
        }

        const imageDataUrlRef: { current: string | undefined } = {
            current: undefined,
        };

        const maskDataUrlRef: { current: string | undefined } = {
            current: undefined,
        };

        const productDataUrlRef: { current: string | undefined } = {
            current: undefined,
        }

        const erasedImageDataUrlRef: { current: string | undefined } = {
            current: undefined,
        };

        await this.centerToBackground(
            async () => {
                const saveCoords = this.getSaveCanvasCoords();

                if (!saveCoords) {
                    return;
                }

                imageDataUrlRef.current = this.getCanvasImageUrl(saveCoords);

                // Apply black filter on the canvas mask
                maskDataUrlRef.current = this.getCanvasMaskImageUrl(saveCoords);

                productDataUrlRef.current = this.getProductImageUrl(saveCoords);

                erasedImageDataUrlRef.current = (this.backgroundImageObject as fabric.Image).getSrc();
            }
        );

        this.locked = false;
        return {
            imageDataUrl: imageDataUrlRef.current,
            maskDataUrl: maskDataUrlRef.current,
            productDataUrl: productDataUrlRef.current,
            erasedImageDataUrl: erasedImageDataUrlRef.current,
        };
    }

    removeActiveObject() {
        this.locked = true;

        // Load active object

        const activeObject = this.canvas.getActiveObject();

        if (activeObject?.id === ReplaceProductEditor.ProductImageObjectId) {
            const {
                setReplaceProductInputImagePath,
            } = editorContextStore.getState();

            setReplaceProductInputImagePath(undefined);

            this.canvas.remove(activeObject);

            this.saveState();
        }

        this.locked = false;
    }

    handleKeyDown = (event: KeyboardEvent) => {
        let isHandled = false;
        if (ShortcutsUtils.isCtrlZ(event)) {
            // undo
            this.undo();
            isHandled = true;
        } else if (ShortcutsUtils.isCtrlShiftZ(event)) {
            // redo
            this.redo();
            isHandled = true;
        } else if (ShortcutsUtils.isDelete(event)) {
            this.removeActiveObject();
            isHandled = true;
        }
        return {
            isHandled,
        };
    }
}

export function ReplaceProductGenerateModal() {
    const isExitingRef = React.useRef(false);

    const handleExitModal = React.useCallback((addImages = false) => {
        if (isExitingRef.current) {
            return;
        }
        isExitingRef.current = true;

        const {
            setActiveLeftPanels,
        } = editorContextStore.getState();

        setActiveLeftPanels((prevLeftPanels) => {
            return removeLastFromImmutableList(prevLeftPanels, 'Assets')
        });

    }, []);

    return (
        <EditorCanvasModalContainer
            onExit={() => handleExitModal(false)}
            className="flex flex-col"
        >

        </EditorCanvasModalContainer>
    )
}

enum ReplaceProductEditorState {
    Idle = 'idle',
    NoProduct = 'no-product',
    Loading = 'loading',
}

const replaceProductEditorStateName: Record<ReplaceProductEditorState, React.ReactNode> = {
    [ReplaceProductEditorState.Idle]: 'Ready',
    [ReplaceProductEditorState.Loading]: (<>
        <SimpleSpinner
            width={18}
            height={18}
            className="mr-2"
            pathClassName="fill-lime-500"
        />
        Loading ...
    </>),
    [ReplaceProductEditorState.NoProduct]: (<>
        Please upload a product image.
    </>),
}

export function ReplaceProductModal() {
    const isExitingRef = React.useRef(false);

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const editorCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

    const replaceProductEditorRef = React.useRef<ReplaceProductEditor | undefined>(undefined);

    const editor = editorContextStore(state => state.editor);

    const editingObjectId = editorContextStore(state => state.editingObjectId);
    const editingObject = React.useMemo(() => editingObjectId ? editor?.objects.findOneById(editingObjectId) as (fabric.Object | undefined) : undefined, [editor, editingObjectId]);

    const replaceProductInputImagePath = editorContextStore(state => state.replaceProductInputImagePath);

    const [replaceProductEditorState, setReplaceProductEditorState] = React.useState(ReplaceProductEditorState.NoProduct);

    useRegenerateProductChangeEffect({
        editor,
        editingObject,
    });

    React.useEffect(() => {
        if (!replaceProductEditorRef.current) {
            return;
        }
        if (replaceProductInputImagePath) {
            replaceProductEditorRef.current.setProductImage(replaceProductInputImagePath);
            setReplaceProductEditorState(ReplaceProductEditorState.Idle);
        } else {
            setReplaceProductEditorState(ReplaceProductEditorState.NoProduct);
        }
    }, [replaceProductInputImagePath]);

    React.useEffect(() => {
        // Initialize replace product editor

        if (!editingObjectId || !editorCanvasRef.current || !containerRef.current) {
            return;
        }

        const {
            editor,
            replaceProductCanvasState,
        } = editorContextStore.getState();

        if (!editor) {

            return;
        }

        const editingObject = editor.objects.findOneById(editingObjectId);

        if (!isStaticImageObject(editingObject)) {
            return;
        }

        setReplaceProductEditorState(ReplaceProductEditorState.Loading);

        const container = containerRef.current;

        const editorCanvas = editorCanvasRef.current;

        const replaceProductEditor = new ReplaceProductEditor({
            canvasId: editorCanvas.id,
        });

        replaceProductEditorRef.current = replaceProductEditor;

        const resizeObserver = new ResizeObserver((entries) => {
            const { width, height } = (entries[0] && entries[0].contentRect) || {}
            replaceProductEditor?.resize({
                width,
                height,
            });
        });

        resizeObserver.observe(container);

        // Add the editing object to the canvas

        if (replaceProductCanvasState) {
            replaceProductEditor.applySavedState(replaceProductCanvasState).finally(() => {
                setReplaceProductEditorState(ReplaceProductEditorState.Idle);
            });
        } else {
            replaceProductEditor.addBackgroundImageObject({
                imageObject: editingObject,
            }).catch(() => {
                console.log("Cannot log background image object");
            }).finally(() => {
                const {
                    replaceProductInputImagePath,
                } = editorContextStore.getState();

                if (replaceProductInputImagePath) {
                    replaceProductEditor.setProductImage(replaceProductInputImagePath);
                    setReplaceProductEditorState(ReplaceProductEditorState.Idle);
                } else {
                    setReplaceProductEditorState(ReplaceProductEditorState.NoProduct);
                }
            });
        }


        return () => {
            resizeObserver.unobserve(container);
            replaceProductEditor.destroy();
        }

    }, [
        editingObjectId
    ]);

    const handleExitModal = React.useCallback((addImages = false) => {
        if (isExitingRef.current) {
            return;
        }
        isExitingRef.current = true;

        resetRegenerateProductState();

        const {
            setActiveLeftPanels,
        } = editorContextStore.getState();

        setActiveLeftPanels((prevLeftPanels) => {
            return removeLastFromImmutableList(prevLeftPanels, 'Assets')
        });

    }, []);

    const handleGenerate = React.useCallback(() => {

        setReplaceProductEditorState(ReplaceProductEditorState.Loading);

        replaceProductEditorRef.current?.saveCanvas().then(async (dataUrls) => {

            if (!dataUrls) {
                return;
            }

            const {
                imageDataUrl,
                maskDataUrl,
                productDataUrl,
                erasedImageDataUrl,
            } = dataUrls;

            const {
                setActiveLeftPanels,
                setRegenerateProductReferenceImagePath,
                setRegenerateProductInputImagePath,
                setRegenerateProductErasedImagePath,
                setRegenerateProductEraseMaskImagePath,
                setReplaceProductCanvasState,
                setRegenerateProductColorStrength,
            } = editorContextStore.getState();

            setReplaceProductCanvasState(replaceProductEditorRef.current?.saveState());

            if (imageDataUrl) {
                setRegenerateProductReferenceImagePath(imageDataUrl);
            }

            if (maskDataUrl) {
                setRegenerateProductEraseMaskImagePath(maskDataUrl);
            }

            if (productDataUrl) {
                setRegenerateProductInputImagePath(productDataUrl);
            }

            if (erasedImageDataUrl) {
                setRegenerateProductErasedImagePath(erasedImageDataUrl);
            } else {
                setRegenerateProductErasedImagePath(replaceProductInputImagePath);
            }

            setActiveLeftPanels((prevPanels) => {
                return [
                    ...prevPanels,
                    'RegenerateProduct',
                ];
            });

            setRegenerateProductColorStrength(GenerateStrength.Weak);

        }).finally(() => {
            setReplaceProductEditorState(ReplaceProductEditorState.Idle);
        });
    }, [replaceProductInputImagePath]);

    return (
        <EditorCanvasModalContainer
            onExit={() => handleExitModal(false)}
            className="flex flex-col"
            onKeyDown={(e) => replaceProductEditorRef.current?.handleKeyDown?.(e.nativeEvent)}
        >
            <div
                ref={containerRef}
                className="relative flex-1 w-full rounded overflow-hidden"
            >
                <canvas
                    ref={editorCanvasRef}
                    id={REPLACE_PRODUCT_CANVAS_ID}
                />
                {replaceProductEditorState !== ReplaceProductEditorState.Idle && (
                    <div className="absolute left-0 top-0 w-full h-full flex flex-row items-center justify-center bg-zinc-800/50 z-10">
                        {replaceProductEditorStateName[replaceProductEditorState]}
                    </div>
                )}
            </div>
            <div
                className="w-full mt-4 flex items-center justify-end"
            >
                <button
                    className={classNames(
                        SecondaryButtonClassNameInactive,
                        "min-w-[50px] items-center justify-center"
                    )}
                    onClick={() => {
                        handleExitModal(false);
                    }}
                >
                    Exit
                </button>
                <div className="w-4" />
                <button
                    className={classNames(
                        replaceProductEditorState === ReplaceProductEditorState.Idle ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
                        "flex flex-row min-w-[50px] items-center justify-center"
                    )}
                    onClick={() => {
                        if (replaceProductEditorState === ReplaceProductEditorState.Idle) {
                            handleGenerate();
                        }
                    }}
                >
                    <ArrowRight size={18} className="mr-2" />
                    Continue to Generate
                </button>
            </div>
        </EditorCanvasModalContainer>
    );
}


