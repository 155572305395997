export function copyToClipboard(text: string): boolean {
    // First try using the modern Clipboard API
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
            return true;
        }).catch(err => {
            console.error('Failed using navigator.clipboard: ', err);
        });
    }

    // Second, try using the older Internet Explorer specific method
    else if ((window as any).clipboardData && (window as any).clipboardData.setData) {
        try {
            // setData can return false if unsuccessful
            return (window as any).clipboardData.setData('Text', text);
        } catch (err) {
            console.error('Failed using window.clipboardData: ', err);
        }
    }

    // Third, fallback to creating a textarea and using document.execCommand('copy')
    else {
        try {
            let textField = document.createElement('textarea');
            textField.innerText = text;
            document.body.appendChild(textField);
            textField.select();
            let successful = document.execCommand('copy');
            textField.remove();
            return successful;
        } catch (err) {
            console.error('Failed using execCommand: ', err);
        }
    }

    // If all methods fail, return false
    return false;
}