import { defaultPromptTemplate } from "core/common/prompt-template";
import { GenerateToolEditorState } from "core/common/types/generate-tool";
import { cloneDeep } from "lodash";
import { SetEditorStateFunction, getUpdaterFunction, getUpdaterFunctionWithCleanup } from "./editor-context-utils";
import { emptyPromptState } from "core/common/interfaces";
import { ColorAttentionMaskMap, StateUpdater } from "core/common/types";
import { getUniqueArray } from "core/utils/array-utils";

const noop = () => { };

const defaultGenerateToolNumImages = 1;

export function getDummyGenerateToolEditorState(): GenerateToolEditorState {
    return {
        generateToolEditorTab: 'Templates',
        setGenerateToolEditorTab: noop,
        generateToolPrompt: '',
        setGenerateToolPrompt: noop,
        generateToolPromptState: '',
        setGenerateToolPromptState: noop,
        generateToolPromptTemplate: cloneDeep(defaultPromptTemplate),
        generateToolColorAttentionMaskMap: {},
        setGenerateToolColorAttentionMaskMap: noop,
        setGenerateToolPromptTemplate: noop,
        generateToolPromptEditorType: 'Template',
        setGenerateToolPromptEditorType: noop,
        generateToolIsRendering: false,
        setGenerateToolIsRendering: noop,
        generateToolPaintTab: 'Tags',
        setGenerateToolPaintTab: noop,
        generateToolRenderProgress: 0,
        setGenerateToolRenderProgress: noop,
        generateToolRenderJobId: null,
        setGenerateToolRenderJobId: noop,
        generateToolTemplateGridType: 'Templates',
        setGenerateToolTemplateGridType: noop,
        generateToolNumImages: defaultGenerateToolNumImages,
        setGenerateToolNumImages: noop,
        generateToolImg2ImgStrength: 0.8,
        setGenerateToolImg2ImgStrength: noop,
        generateToolControlNetStrengthStart: 1.0,
        setGenerateToolControlNetStrengthStart: noop,
        generateToolControlNetStrengthFinish: 0.5,
        setGenerateToolControlNetStrengthFinish: noop,
        generateToolGuidanceScale: 0,
        setGenerateToolGuidanceScale: noop,
        generateToolRenderProcessController: undefined,
        setGenerateToolRenderProcessController: noop,
        generateToolOverlayUsePoisson: true,
        setGenerateToolOverlayUsePoisson: noop,
        generateToolOverlayUseCanny: true,
        setGenerateToolOverlayUseCanny: noop,
        generateToolReferenceImage: undefined,
        setGenerateToolReferenceImage: noop,
    }
}


function cleanupGenerateToolColorAttentionMaskMap(generateToolColorAttentionMaskMap?: ColorAttentionMaskMap) {
    if (generateToolColorAttentionMaskMap) {
        const imageColorMap: Record<string, string> = {};
        Object.entries(generateToolColorAttentionMaskMap).forEach(([color, { images }]) => {
            getUniqueArray(images).forEach((id) => {
                imageColorMap[id] = color;
            });
        });
        Object.values(generateToolColorAttentionMaskMap).forEach((mask) => {
            mask.images.length = 0;
        });
        Object.entries(imageColorMap).forEach(([id, color]) => {
            generateToolColorAttentionMaskMap[color]?.images.push(id);
        });
    }
    return generateToolColorAttentionMaskMap;
}


function updateGenerateToolColorAttentionMaskMap(set: (state: StateUpdater<Partial<GenerateToolEditorState>>) => void) {
    return (updater: StateUpdater<ColorAttentionMaskMap>) => {
        if (typeof (updater) === 'function') {
            set(state => {
                const newAttentionMaskMap = updater(state.generateToolColorAttentionMaskMap || {});
                return {
                    generateToolColorAttentionMaskMap: cleanupGenerateToolColorAttentionMaskMap(newAttentionMaskMap),
                }
            });
        } else {
            set(() => ({ generateToolColorAttentionMaskMap: cleanupGenerateToolColorAttentionMaskMap(updater) }));
        }
    }
}

export function getDefaultGenerateToolEditorState(set: SetEditorStateFunction): GenerateToolEditorState {
    return {
        generateToolEditorTab: 'Templates',
        setGenerateToolEditorTab: getUpdaterFunction(set, 'generateToolEditorTab'),
        generateToolPrompt: '',
        setGenerateToolPrompt: getUpdaterFunction(set, 'generateToolPrompt'),
        generateToolPromptState: cloneDeep(emptyPromptState),
        setGenerateToolPromptState: getUpdaterFunction(set, 'generateToolPromptState'),
        generateToolColorAttentionMaskMap: {},
        setGenerateToolColorAttentionMaskMap: updateGenerateToolColorAttentionMaskMap(set),
        generateToolPromptTemplate: defaultPromptTemplate,
        setGenerateToolPromptTemplate: getUpdaterFunction(set, 'generateToolPromptTemplate'),
        generateToolPromptEditorType: 'Template',
        setGenerateToolPromptEditorType: getUpdaterFunction(set, 'generateToolPromptEditorType'),
        generateToolPaintTab: 'Tags',
        setGenerateToolPaintTab: getUpdaterFunction(set, 'generateToolPaintTab'),
        generateToolIsRendering: false,
        setGenerateToolIsRendering: getUpdaterFunction(set, 'generateToolIsRendering'),
        generateToolRenderProgress: 0,
        setGenerateToolRenderProgress: getUpdaterFunction(set, 'generateToolRenderProgress'),
        generateToolRenderJobId: null,
        setGenerateToolRenderJobId: getUpdaterFunction(set, 'generateToolRenderJobId'),
        generateToolTemplateGridType: 'Templates',
        setGenerateToolTemplateGridType: getUpdaterFunction(set, 'generateToolTemplateGridType'),
        generateToolNumImages: defaultGenerateToolNumImages,
        setGenerateToolNumImages: getUpdaterFunction(set, 'generateToolNumImages'),
        generateToolImg2ImgStrength: 0.8,
        setGenerateToolImg2ImgStrength: getUpdaterFunction(set, 'generateToolImg2ImgStrength'),
        generateToolControlNetStrengthStart: 1.0,
        setGenerateToolControlNetStrengthStart: getUpdaterFunction(set, 'generateToolControlNetStrengthStart'),
        generateToolControlNetStrengthFinish: 0.5,
        setGenerateToolControlNetStrengthFinish: getUpdaterFunction(set, 'generateToolControlNetStrengthFinish'),
        generateToolGuidanceScale: 3.5,
        setGenerateToolGuidanceScale: getUpdaterFunction(set, 'generateToolGuidanceScale'),
        generateToolRenderProcessController: undefined,
        setGenerateToolRenderProcessController: getUpdaterFunctionWithCleanup(
            set,
            'generateToolRenderProcessController',
            (controller) => controller?.destroy(),
        ),
        generateToolOverlayUsePoisson: true,
        setGenerateToolOverlayUsePoisson: getUpdaterFunction(set, 'generateToolOverlayUsePoisson'),
        generateToolOverlayUseCanny: true,
        setGenerateToolOverlayUseCanny: getUpdaterFunction(set, 'generateToolOverlayUseCanny'),
        generateToolReferenceImage: undefined,
        setGenerateToolReferenceImage: getUpdaterFunction(set, 'generateToolReferenceImage'),
    }
}