import { downloadImageDataUrl } from "components/utils/data";
import { getHtmlImageElementFromUrlAsync } from "core/utils/image-utils";


function compileShader(
    gl: WebGLRenderingContext,
    type: number,
    source: string
): WebGLShader | null {
    const shader = gl.createShader(type);

    if (!shader) {
        return null;
    }

    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(
            "An error occurred compiling the shaders: " + gl.getShaderInfoLog(shader)
        );
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

function createProgram(
    gl: WebGLRenderingContext,
    vertexShader: WebGLShader,
    fragmentShader: WebGLShader
): WebGLProgram | null {
    const program = gl.createProgram();

    if (!program) {
        return null;
    }

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error(
            "Unable to initialize the shader program: " +
            gl.getProgramInfoLog(program)
        );
        return null;
    }

    return program;
}

function createTexture(
    gl: WebGLRenderingContext,
    image: HTMLImageElement
): WebGLTexture | null {
    const texture = gl.createTexture();

    if (!texture) {
        return null;
    }

    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Set the texture parameters
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // Upload the image into the texture
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

    return texture;
}

function setRectangle(
    gl: WebGLRenderingContext,
    x: number,
    y: number,
    width: number,
    height: number
) {
    var x1 = x;
    var x2 = x + width;
    var y1 = y;
    var y2 = y + height;
    gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]),
        gl.STATIC_DRAW
    );
}

interface SetAlphaAsMaskChannelProcessorArgs {
    colorImage: HTMLImageElement | string;
    maskImage: HTMLImageElement | string;
    channelIndex: number;
    useCanvas?: boolean,
}

class SetAlphaAsMaskChannelProcessor {
    protected canvas: HTMLCanvasElement;
    protected gl: WebGLRenderingContext | null;
    protected ctx?: CanvasRenderingContext2D | null
    private program?: WebGLProgram | null;

    constructor({
        gl = null,
        ctx = null,
        canvas,
    }: {
        gl?: WebGLRenderingContext | null,
        canvas: HTMLCanvasElement,
        ctx?: CanvasRenderingContext2D | null,
    }) {
        this.gl = gl;
        this.ctx = ctx;
        this.canvas = canvas;

        if (this.gl) {
            this.program = this.getProgramSetAlphaAsMaskChannel(this.gl);
        }
    }

    getProgramSetAlphaAsMaskChannel(gl: WebGLRenderingContext) {
        const vsSource = `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
        uniform vec2 u_resolution;
        varying vec2 v_texCoord;

        void main() {
            vec2 zeroToOne = a_position / u_resolution;
            vec2 zeroToTwo = zeroToOne * 2.0;
            vec2 clipSpace = zeroToTwo - 1.0;
            gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
            v_texCoord = a_texCoord;
        }
        `;

        // Modified fragment shader that takes two textures and a channel index
        const fsSource = `
        precision mediump float;
        uniform sampler2D u_colorImage;
        uniform sampler2D u_maskImage;
        uniform int channel_index;
        varying vec2 v_texCoord;

        void main() {
            vec4 color = texture2D(u_colorImage, v_texCoord);
            vec4 mask = texture2D(u_maskImage, v_texCoord);
            float alpha = 0.0;
            if (channel_index == 0) {
                alpha = mask.r;
            } else if (channel_index == 1) {
                alpha = mask.g;
            } else if (channel_index == 2) {
                alpha = mask.b;
            } else if (channel_index == 3) {
                alpha = mask.a;
            }
            gl_FragColor = vec4(color.rgb, alpha);
        }
        `;

        // Compile shaders
        const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vsSource);
        const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fsSource);

        if (!vertexShader || !fragmentShader) {
            return null;
        }

        // Create the shader program
        const program = createProgram(gl, vertexShader, fragmentShader);

        return program;
    }

    async processWebGL({
        colorImage,
        maskImage,
        channelIndex,
    }: SetAlphaAsMaskChannelProcessorArgs) {

        const { canvas, gl, program } = this;

        if (!canvas || !gl || !program) {
            return;
        }

        colorImage = typeof (colorImage) === 'string' ?
            await getHtmlImageElementFromUrlAsync(colorImage) :
            colorImage;

        maskImage = typeof (maskImage) === 'string' ?
            await getHtmlImageElementFromUrlAsync(maskImage) :
            maskImage;

        gl.useProgram(program);

        // look up where the vertex data needs to go.
        var positionLocation = gl.getAttribLocation(program, "a_position");
        var texcoordLocation = gl.getAttribLocation(program, "a_texCoord");

        // Create a buffer to put three 2d clip space points in
        var positionBuffer = gl.createBuffer();

        // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        // Set a rectangle the same size as the image.
        setRectangle(gl, 0, 0, colorImage.width, colorImage.height);

        // provide texture coordinates for the rectangle.
        var texcoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
        gl.bufferData(
            gl.ARRAY_BUFFER,
            new Float32Array([
                0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
            ]),
            gl.STATIC_DRAW
        );

        // Create a texture.
        const colorTexture = createTexture(gl, colorImage);


        // Set the sampler uniform to use texture unit 0 for the color image
        const colorImageLocation = gl.getUniformLocation(program, "u_colorImage");
        gl.uniform1i(colorImageLocation, 0);

        // Set mask texture
        const maskTexture = createTexture(gl, maskImage);


        // Set the sampler uniform to use texture unit 1 for the mask image
        const maskImageLocation = gl.getUniformLocation(program, "u_maskImage");
        gl.uniform1i(maskImageLocation, 1);


        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, colorTexture);

        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, maskTexture);

        // lookup uniforms
        var resolutionLocation = gl.getUniformLocation(program, "u_resolution");

        // Set up the canvas size
        canvas.width = colorImage.width;
        canvas.height = colorImage.height;

        // Tell WebGL how to convert from clip space to pixels
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        // Clear the canvas
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Tell it to use our program (pair of shaders)
        gl.useProgram(program);

        // Turn on the position attribute
        gl.enableVertexAttribArray(positionLocation);

        // Bind the position buffer.
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
        var size = 2; // 2 components per iteration
        var type = gl.FLOAT; // the data is 32bit floats
        var normalize = false; // don't normalize the data
        var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
        var offset = 0; // start at the beginning of the buffer
        gl.vertexAttribPointer(
            positionLocation,
            size,
            type,
            normalize,
            stride,
            offset
        );

        // Turn on the texcoord attribute
        gl.enableVertexAttribArray(texcoordLocation);

        // bind the texcoord buffer.
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

        // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
        var size = 2; // 2 components per iteration
        var type = gl.FLOAT; // the data is 32bit floats
        var normalize = false; // don't normalize the data
        var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
        var offset = 0; // start at the beginning of the buffer
        gl.vertexAttribPointer(
            texcoordLocation,
            size,
            type,
            normalize,
            stride,
            offset
        );

        // set the resolution
        gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

        // Look up the location of the uniform
        const channelIndexLocation = gl.getUniformLocation(program, "channel_index");

        // Set the channel_index uniform. WebGL requires using `uniform1i` for setting an integer.
        gl.uniform1i(channelIndexLocation, channelIndex);

        // Draw the rectangle.
        var primitiveType = gl.TRIANGLES;
        var offset = 0;
        var count = 6;
        gl.drawArrays(primitiveType, offset, count);

        return canvas.toDataURL();
    }

    private setupGeometry(): void {
        const { gl } = this;
        if (!gl) return;

        // Create a buffer for the quad's positions.
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        // Set up the positions (two triangles covering the entire canvas)
        const positions = [
            -1.0, -1.0,
            1.0, -1.0,
            -1.0, 1.0,
            -1.0, 1.0,
            1.0, -1.0,
            1.0, 1.0,
        ];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

        // Configure the vertex attribute to read data from the buffer
        const positionLocation = gl.getAttribLocation(this.program!, "a_position");
        gl.enableVertexAttribArray(positionLocation);
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    }


    async processCanvas({
        colorImage,
        maskImage,
        channelIndex,
    }: SetAlphaAsMaskChannelProcessorArgs): Promise<string> {
        if (!(this.canvas.getContext)) {
            throw new Error('Canvas API is not supported in this environment.');
        }

        const ctx = this.canvas.getContext('2d');
        if (!ctx) {
            throw new Error('Unable to get canvas rendering context.');
        }

        // Ensure images are loaded
        const loadedColorImage = typeof colorImage === 'string' ? await getHtmlImageElementFromUrlAsync(colorImage) : colorImage;
        const loadedMaskImage = typeof maskImage === 'string' ? await getHtmlImageElementFromUrlAsync(maskImage) : maskImage;

        // Draw and get pixel data for the color image
        this.canvas.width = loadedColorImage.width;
        this.canvas.height = loadedColorImage.height;
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(loadedColorImage, 0, 0);
        const colorImageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

        // Draw and get pixel data for the mask image
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(loadedMaskImage, 0, 0);
        const maskImageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

        // Modify the alpha channel of the color image
        for (let i = 0; i < colorImageData.data.length; i += 4) {
            const maskValue = maskImageData.data[i + channelIndex]; // Get the channel value from the mask
            colorImageData.data[i + 3] = maskValue; // Set alpha channel
        }

        // Put the modified image data back onto the canvas
        ctx.putImageData(colorImageData, 0, 0);

        // Optionally, return a data URL of the canvas
        return this.canvas.toDataURL();
    }

    async process(args: SetAlphaAsMaskChannelProcessorArgs): Promise<string | undefined> {
        if (!Boolean(args.useCanvas) && this.gl) {
            return this.processWebGL(args);
        } else if (this.ctx) {
            return this.processCanvas(args);
        }
        return undefined;
    }
}

function getProgramGetChannelMask(gl: WebGLRenderingContext) {
    const vsSource = `
attribute vec2 a_position;
attribute vec2 a_texCoord;

uniform vec2 u_resolution;

varying vec2 v_texCoord;

void main() {
    // convert the rectangle from pixels to 0.0 to 1.0
    vec2 zeroToOne = a_position / u_resolution;

    // convert from 0->1 to 0->2
    vec2 zeroToTwo = zeroToOne * 2.0;

    // convert from 0->2 to -1->+1 (clipspace)
    vec2 clipSpace = zeroToTwo - 1.0;

    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);

    // pass the texCoord to the fragment shader
    // The GPU will interpolate this value between points.
    v_texCoord = a_texCoord;
}
    `;

    // Fragment shader source
    const fsSource = `
precision mediump float;

uniform sampler2D u_image;
uniform int channel_index;
varying vec2 v_texCoord;

void main() {
    vec4 color = texture2D(u_image, v_texCoord);
    float channel_color = 0.0;
    if (channel_index == 0) {
        channel_color = color.r;
    } else if (channel_index == 1) {
        channel_color = color.g;
    } else if (channel_index == 2) {
        channel_color = color.b;
    } else if (channel_index == 3) {
        channel_color = color.a;
    }
    gl_FragColor = vec4(channel_color, channel_color, channel_color, 1.0);
}
    
    `;

    // Compile shaders
    const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fsSource);

    if (!vertexShader || !fragmentShader) {
        return;
    }

    // Create the shader program
    const program = createProgram(gl, vertexShader, fragmentShader);

    if (!program) {
        return;
    }

    return program;
}

interface GetChannelMaskImageProcessArgs {
    image: string | HTMLImageElement,
    channelIndex: number,
    useCanvas?: boolean,
}

class GetChannelMaskImageProcessor {
    protected canvas: HTMLCanvasElement;

    protected gl: WebGLRenderingContext | null;

    protected ctx: CanvasRenderingContext2D | null;

    private program: WebGLProgram | undefined;

    constructor({
        gl = null,
        ctx = null,
        canvas,
    }: {
        gl?: WebGLRenderingContext | null,
        ctx?: CanvasRenderingContext2D | null,
        canvas: HTMLCanvasElement,
    }) {
        this.gl = gl;
        this.ctx = ctx;

        this.canvas = canvas;

        if (this.gl) {
            this.program = getProgramGetChannelMask(this.gl);
        }
    }

    async process(args: GetChannelMaskImageProcessArgs) {
        if (!Boolean(args.useCanvas) && this.gl) {
            console.log('Process webgl');
            return this.processWebGL(args);
        }
        if (this.ctx) {
            console.log('Process canvas');
            return this.processCPU(args);
        }
        return undefined;
    }

    async processCPU({
        image,
        channelIndex = 0,
    }: GetChannelMaskImageProcessArgs) {
        const { canvas, ctx } = this;

        if (!canvas || !ctx) {
            return;
        }


        if (typeof (image) === 'string') {
            image = await getHtmlImageElementFromUrlAsync(image);
        }

        // Assuming image is now an HTMLImageElement
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0);

        // Retrieve the image data
        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        const data = imageData.data; // the pixel data

        // Modify the pixel data to isolate the desired channel
        for (let i = 0; i < data.length; i += 4) {
            const channelValue = data[i + channelIndex]; // Channel value (R, G, B, or A)
            // Set R, G, and B to the channel value (and A to 255 for opacity)
            data[i] = channelValue; // Red or zero
            data[i + 1] = channelValue; // Green or zero
            data[i + 2] = channelValue; // Blue or zero
            // Alpha channel is ignored in this manipulation; it could be set to a fixed value if needed
        }

        // Put the modified image data back onto the canvas
        ctx.putImageData(imageData, 0, 0);

        // Convert the canvas to a Data URL and return it
        return canvas.toDataURL();
    }

    async processWebGL({
        image,
        channelIndex = 0,
    }: GetChannelMaskImageProcessArgs) {

        const { canvas, gl, program } = this;

        if (!canvas || !gl || !program) {
            return;
        }


        if (typeof (image) === 'string') {
            image = await getHtmlImageElementFromUrlAsync(image);
        }

        gl.useProgram(program);

        // look up where the vertex data needs to go.
        var positionLocation = gl.getAttribLocation(program, "a_position");
        var texcoordLocation = gl.getAttribLocation(program, "a_texCoord");

        // Create a buffer to put three 2d clip space points in
        var positionBuffer = gl.createBuffer();

        // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        // Set a rectangle the same size as the image.
        setRectangle(gl, 0, 0, image.width, image.height);

        // provide texture coordinates for the rectangle.
        var texcoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
        gl.bufferData(
            gl.ARRAY_BUFFER,
            new Float32Array([
                0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
            ]),
            gl.STATIC_DRAW
        );

        // Create a texture.
        var texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

        // Upload the image into the texture.
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

        // lookup uniforms
        var resolutionLocation = gl.getUniformLocation(program, "u_resolution");

        // Set up the canvas size
        canvas.width = image.width;
        canvas.height = image.height;

        // Tell WebGL how to convert from clip space to pixels
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        // Clear the canvas
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Tell it to use our program (pair of shaders)
        gl.useProgram(program);

        // Turn on the position attribute
        gl.enableVertexAttribArray(positionLocation);

        // Bind the position buffer.
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
        var size = 2; // 2 components per iteration
        var type = gl.FLOAT; // the data is 32bit floats
        var normalize = false; // don't normalize the data
        var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
        var offset = 0; // start at the beginning of the buffer
        gl.vertexAttribPointer(
            positionLocation,
            size,
            type,
            normalize,
            stride,
            offset
        );

        // Turn on the texcoord attribute
        gl.enableVertexAttribArray(texcoordLocation);

        // bind the texcoord buffer.
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

        // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
        var size = 2; // 2 components per iteration
        var type = gl.FLOAT; // the data is 32bit floats
        var normalize = false; // don't normalize the data
        var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
        var offset = 0; // start at the beginning of the buffer
        gl.vertexAttribPointer(
            texcoordLocation,
            size,
            type,
            normalize,
            stride,
            offset
        );

        // set the resolution
        gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

        // Look up the location of the uniform
        const channelIndexLocation = gl.getUniformLocation(program, "channel_index");

        // Set the channel_index uniform. WebGL requires using `uniform1i` for setting an integer.
        gl.uniform1i(channelIndexLocation, channelIndex);

        // Draw the rectangle.
        var primitiveType = gl.TRIANGLES;
        var offset = 0;
        var count = 6;
        gl.drawArrays(primitiveType, offset, count);

        return canvas.toDataURL();
    }
}

export class ImageProcessingUtils {

    private canvas;

    private gl: WebGLRenderingContext | null;

    private ctx: CanvasRenderingContext2D | null = null;

    private getChannelMaskImageProcessor: GetChannelMaskImageProcessor;

    private setAlphaMaskChannelImageProcessor: SetAlphaAsMaskChannelProcessor;

    constructor() {
        this.canvas = document.createElement('canvas');

        this.gl = this.canvas.getContext('webgl');

        if (!this.gl) {
            this.ctx = this.canvas.getContext('2d');
        }

        this.getChannelMaskImageProcessor = new GetChannelMaskImageProcessor({
            canvas: this.canvas,
            gl: this.gl,
            ctx: this.ctx,
        });

        this.setAlphaMaskChannelImageProcessor = new SetAlphaAsMaskChannelProcessor({
            canvas: this.canvas,
            gl: this.gl,
            ctx: this.ctx,
        });
    }

    get isWebGL() {
        return this.gl != null;
    }

    async getChannelMaskImage(args: GetChannelMaskImageProcessArgs) {
        return this.getChannelMaskImageProcessor.process(args);
    }

    async setAlphaMaskChannel(args: SetAlphaAsMaskChannelProcessorArgs) {
        return this.setAlphaMaskChannelImageProcessor.process(args);
    }
}

export const imageProcessingUtils = new ImageProcessingUtils();
