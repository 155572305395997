import { DEFAULT_RENDER_LENGTH, DEFAULT_RENDER_NUM_INFERENCE_STEPS } from 'core/common/constants';
import React from 'react';
import { DisplayMessageType, EditorAsset, EditorAssetContentType, GenerateStrength, PastGeneration, PastGenerationImagePathType, PastGenerationVersion, PromptTemplate, RegenerateProductResult, UiDisplayMessageEventHandler } from 'core/common/types';
import { editorContextStore } from 'contexts/editor-context';
import { Editor } from "core/editor";
import { fabric } from 'fabric';
import { nanoid } from "nanoid";
import { Timestamp } from 'firebase/firestore';
import { Backend, StartEraseProductJobArgs, StartEraseProductJobResponse, StartRegenerateProductJobArgs, StartRenderJobArgs, StartRenderJobFunctionArgs } from 'backend/base';
import { isDataURL, isValidFirebaseStoragePath, isValidHttpUrl, isValidHttpsUrl } from 'core/utils/string-utils';
import { EditorActiveObject } from 'core/common/interfaces';
import { isPastGeneration, isStaticImageObject, isStaticImageObjectColor, isStaticImageObjectGenerated, isStaticImageObjectHed, isStaticImageObjectUploaded } from 'core/utils/type-guards';
import { getPromptFromTemplate, getSubjectFromPromptTemplate } from 'core/common/prompt-template';
import { throttle } from 'lodash';
import { StaticImageObject } from 'objects';
import { WebRenderProcessController } from './render-process-controller';
import { fillUndefined } from 'core/utils/array-utils';
import { ImageOverlayStrength, RenderArgs, RenderPipelineArgs, RenderPipelineType } from 'core/common/types/render-args';
import { canHedImageBeColored, createSolidColorImageDataURL } from 'core/utils/image-utils';
import { getGenerateToolPromptValue } from 'hooks/use-generate-tool-prompt';
import { AnalyticsConfig } from 'analytics/config';
import { addObjectToCanvas } from './add-to-canvas-utils';
import { downloadImageDataUrl } from './data';
import { GenerationFrameDataProps } from 'core/canvas';
import MobileZoom from 'core-mobile/controllers/mobile-zoom';
import { MobileBottomPanelState } from 'core/common/types/mobile';


export function getRenderResultsStartLocation(
    editor: Editor | null,
    width = DEFAULT_RENDER_LENGTH,
) {
    if (!editor) {
        return new fabric.Point(0, 0);
    }

    let location = undefined;

    const generationFrameCenter = editor.objects.getGenerationFrame()?.getCenterPoint();

    if (generationFrameCenter && editor.canvas.pointInViewport(generationFrameCenter)) {
        location = generationFrameCenter;
    } else {
        location = editor.canvas.getCenterPoint();
    }

    location = location || new fabric.Point(0, 0);

    // Set the result image to be right next to the generation frame
    location.setX(
        location.x + width * 1.01, // nudge to the right of the frame a bit
    );
    return location;
}

function getObjectLocationAtIndex({
    startLocation,
    width,
    height,
    index,
    numRows = 2,
}: {
    startLocation: { x: number, y: number },
    width: number,
    height: number,
    index: number,
    numRows?: number,
}) {
    // Calculate the column and row of the object based on its index
    const col = index % numRows;
    const row = Math.floor(index / numRows);

    return {
        x: startLocation.x + width * col,
        y: startLocation.y + height * row,
    };
}

export function addRenderImagePlaceholders(
    editor: Editor | null,
    numImages = 1,
    width = DEFAULT_RENDER_LENGTH,
    height = DEFAULT_RENDER_LENGTH,
) {
    if (!editor) {
        return [];
    }
    const location = getRenderResultsStartLocation(editor, width);
    const placeholders: fabric.Rect[] = [];
    for (let i = 0; i < numImages; ++i) {
        const imageLocation = {
            x: location.x + width * i - width * 0.5,
            y: location.y - height * 0.5,
        };
        const rectangle = new fabric.Rect({
            width,
            height,
            fill: '#365314',
            opacity: 0.2,
            selectable: false,
            hasControls: false,
            left: imageLocation.x,
            top: imageLocation.y,
        });

        addObjectToCanvas({
            canvas: editor.canvas.canvas,
            object: rectangle,
        });

        editor.canvas.requestRenderAll();
        placeholders.push(rectangle);
    }
    return placeholders;
}

export function removeRenderImagePlaceholders(
    editor: Editor | null,
    placeholders: fabric.Object[],
) {
    if (!editor) {
        return;
    }
    placeholders.forEach((object) => editor.canvas.canvas.remove(object));
    editor.canvas.requestRenderAll();
}

async function addImageFromUrl(
    editor: Editor,
    imageUrl: string,
    index: number,
    renderId?: string,
    width = DEFAULT_RENDER_LENGTH,
    height = DEFAULT_RENDER_LENGTH,
    startLocation?: fabric.Point,
) {
    if (typeof (imageUrl) !== 'string') {
        return;
    }

    const location = startLocation || getRenderResultsStartLocation(editor, width);

    const isDataurl = isDataURL(imageUrl);
    const isStoragePath = !isDataurl && isValidFirebaseStoragePath(imageUrl);

    let url: string | undefined = imageUrl;

    if (isStoragePath) {
        url = await editor.assets.loadAsset({
            type: 'image-storage',
            path: imageUrl,
        });
    }

    if (!url) {
        return;
    }

    const uploadStorage = isDataurl;

    const imageLocation = getObjectLocationAtIndex({
        startLocation: location,
        width,
        height,
        index,
        numRows: 2,
    });

    renderId = renderId || nanoid();

    const generationId = `${renderId}_${index}`;

    const imageObject = await editor.objects.addImageFromUrl({
        url,
        location: imageLocation,
        uploadStorage,
        generationId,
        setActive: false,
        targetHeight: height,
    });

    return imageObject;
}

export function onRenderImageResultAdded({
    outputImage,
    prompt,
    inputImagePath,
    promptTemplate,
    regenerateErasedImagePath,
    regenerateMaskImagePath,
}: {
    outputImage: fabric.StaticImage,
    prompt: string,
    inputImagePath?: string,
    promptTemplate: PromptTemplate,
    regenerateErasedImagePath?: string,
    regenerateMaskImagePath?: string,
}) {
    if (!outputImage || !outputImage.id) {
        console.log(`Output image is invalid. Cannot add image result`);
        return;
    }

    const id = outputImage.generationId;
    if (!id) {
        return;
    }

    // Add the prompt to the past generations
    const {
        backend,
        projectId,
        setPastGenerations,
    } = editorContextStore.getState();

    const version: PastGenerationVersion = 'v2';

    const pastGeneration: PastGeneration = {
        id,
        prompt,
        timeModified: Timestamp.now(),
        imgPath: outputImage.asset?.path,
        imgPathType: 'storage',
        projectId,
        promptTemplate,
        inputImagePath: inputImagePath || '',
        regenerateErasedImagePath,
        regenerateMaskImagePath,
        version,
    }
    backend?.addPastGeneration({
        pastGeneration,
    });
    setPastGenerations((pastGenerations) => {
        const imageAsset = outputImage.asset;
        let imgPath = outputImage.id;
        let imgPathType: PastGenerationImagePathType = 'image-id';
        if (imageAsset?.type === 'image-storage') {
            imgPath = imageAsset.path;
            imgPathType = 'storage'
        }
        return {
            [id]: {
                id,
                prompt,
                promptTemplate,
                timeModified: Timestamp.now(),
                imgPath,
                imgPathType,
                projectId,
                inputImagePath,
                regenerateErasedImagePath,
                regenerateMaskImagePath,
                version,
            },
            ...(pastGenerations ?? {}),
        };
    });
}

export async function addRenderImageResultToCanvas({
    renderId,
    imageUrl,
    index,
    width = DEFAULT_RENDER_LENGTH,
    height = DEFAULT_RENDER_LENGTH,
    startLocation,
}: {
    renderId?: string,
    imageUrl?: string,
    index: number,
    width?: number,
    height?: number,
    startLocation?: fabric.Point,
}) {
    renderId = (renderId || nanoid()) as string;
    const {
        editor,
        backend,
    } = editorContextStore.getState();
    if (!editor || !backend || !imageUrl || typeof (imageUrl) !== 'string') {
        return;
    }

    return await addImageFromUrl(
        editor,
        imageUrl,
        index,
        renderId,
        width,
        height,
        startLocation,
    );
}


export function setProjectThumbnailAsImageObject(imageObject: fabric.StaticImage) {
    const {
        editor,
        backend,
        projectId,
    } = editorContextStore.getState();

    if (!editor || !backend || !imageObject) {
        return;
    }

    if (projectId && imageObject && imageObject.asset?.type === 'image-storage') {
        backend?.setProjectThumbnail(
            projectId,
            imageObject.asset.path,
        );
    } else {
        console.log(`Cannot set project ${projectId} thumbnail to asset ${imageObject?.asset?.type}`);
    }
}

export async function uploadRenderInputImage(inputImageUrl: string) {
    if (!inputImageUrl) {
        return;
    }
    const { editor } = editorContextStore.getState();
    if (!editor) {
        return;
    }
    return await editor.assets.addAsset({
        data: inputImageUrl,
        contentType: EditorAssetContentType.png,
    });
}

function displayMessage(type: DisplayMessageType, message: string) {
    return editorContextStore.getState().editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', type, message);
}

const regenerateProductImg2ImgStrengthMap = {
    [GenerateStrength.None]: 1.0,
    [GenerateStrength.ExtraWeak]: 0.9,
    [GenerateStrength.Weak]: 0.8,
    [GenerateStrength.Default]: 0.7,
    [GenerateStrength.Strong]: 0.6,
    [GenerateStrength.ExtraStrong]: 0.5,
}

function getRegenerateProductUseCanny(
    regenerateProductCorrectColor: GenerateStrength,
) {
    return (
        regenerateProductCorrectColor === GenerateStrength.None ||
        regenerateProductCorrectColor === GenerateStrength.ExtraWeak ||
        regenerateProductCorrectColor === GenerateStrength.Weak
    );
}

function getGeneratedResultFromImageUrl(
    imageUrl?: string,
): RegenerateProductResult {
    return {
        imageUrl,
        isSelected: Boolean(imageUrl),
    };
}

async function uploadRegenerateProductErasedAndMaskImage({
    editor,
    erasedImageUrl,
    maskImageUrl,
    generationId,
}: {
    editor: Editor,
    erasedImageUrl?: string,
    maskImageUrl?: string,
    generationId: string,
}) {
    if (!editor || !erasedImageUrl || !maskImageUrl || !generationId) {
        return {};
    }


    const [
        regenerateErasedImagePath,
        regenerateMaskImagePath,
    ] = await Promise.all([
        editor.assets.addAsset({
            data: erasedImageUrl,
            contentType: EditorAssetContentType.png,
        }),
        editor.assets.addAsset({
            data: maskImageUrl,
            contentType: EditorAssetContentType.png,
        }),
    ]);

    await editor.assets.updatePastGeneration({
        id: generationId,
        regenerateMaskImagePath,
        regenerateErasedImagePath,
    });

    return {
        regenerateMaskImagePath,
        regenerateErasedImagePath,
    };
}

async function getEraseProductImageDataUrls(
    editor: Editor,
    regenerateProductErasedImagePath: string,
    regenerateProductEraseMaskImagePath: string,
): Promise<Partial<StartEraseProductJobResponse>> {
    const {
        setRegenerateProductErasedImagePath,
        setRegenerateProductEraseMaskImagePath,
    } = editorContextStore.getState();

    const [
        erased_image,
        mask_image,
    ] = await Promise.all([
        editor.assets.loadAsset({
            path: regenerateProductErasedImagePath,
            contentType: EditorAssetContentType.png,
        }).then((path) => {
            setRegenerateProductErasedImagePath(path);
            return path;
        }),
        editor.assets.loadAsset({
            path: regenerateProductEraseMaskImagePath,
            contentType: EditorAssetContentType.png,
        }).then((path) => {
            setRegenerateProductEraseMaskImagePath(path);
            return path;
        }),
    ]);
    return {
        erased_image,
        mask_image,
    };
}

async function eraseProductInternal({
    editor,
    backend,
    activeObject,
    ...renderArgs
}: Partial<StartEraseProductJobArgs> & {
    editor: Editor,
    backend: Backend,
    activeObject: StaticImageObject & { generationId: string },
}): Promise<Partial<StartEraseProductJobResponse>> {
    const {
        regenerateProductPromptTemplate: promptTemplate,
        regenerateProductInputImagePath: inputImagePath,
        regenerateProductNumImages,
        regenerateProductErasedImagePath,
        regenerateProductEraseMaskImagePath,
        regenerateProductReferenceImagePath,
        setRegenerateProductRenderState,
        setRegenerateProductResults,
        setRegenerateProductErasedImagePath,
        setRegenerateProductEraseMaskImagePath,
        setRegenerateRenderProcessController,
    } = editorContextStore.getState();

    setRegenerateProductRenderState('rendering');
    setRegenerateProductResults(Array(regenerateProductNumImages).fill({
        imageUrl: undefined,
        isSelected: false,
    }));


    if (regenerateProductErasedImagePath && regenerateProductEraseMaskImagePath) {

        const results = await getEraseProductImageDataUrls(
            editor,
            regenerateProductErasedImagePath,
            regenerateProductEraseMaskImagePath,
        );

        setRegenerateProductRenderState('idle');


        return results;
    }

    const objectImageUrl = inputImagePath && (
        await editor.assets.loadAsset({
            path: inputImagePath,
        })
    );

    const imageUrl = regenerateProductReferenceImagePath || activeObject.getSrc();

    const subjectPrompt = getSubjectFromPromptTemplate(promptTemplate);

    if (!subjectPrompt) {
        displayMessage(
            'error',
            'No product in the prompt.'
        )
        setRegenerateProductRenderState('idle');
        return {
            mask_image: 'No product in the prompt',
        };
    }

    setRegenerateProductErasedImagePath(undefined);
    setRegenerateProductEraseMaskImagePath(undefined);

    const renderProcessController = new WebRenderProcessController();
    setRegenerateRenderProcessController(renderProcessController);

    const results = await backend.startEraseProductJob({
        ...renderArgs,
        job_type: 'erase-from-prompt',
        image_url: imageUrl,
        mask_url: objectImageUrl,
        subject_image_url: objectImageUrl,
        subject_prompt: subjectPrompt,
        renderProcessController,
    });

    const erasedImageUrl = results?.erased_image;
    const maskImageUrl = results?.mask_image;

    setRegenerateProductErasedImagePath(erasedImageUrl);
    setRegenerateProductEraseMaskImagePath(maskImageUrl);

    // Upload the regenerated image to assets; do not wait for it.

    uploadRegenerateProductErasedAndMaskImage({
        editor,
        generationId: activeObject.generationId,
        maskImageUrl,
        erasedImageUrl,
    });

    setRegenerateProductRenderState('idle');

    return results;
}

export const callEraseProductBackend = throttle(async function callEraseProductBackend({
    editor,
    backend,
    activeObject,
    ...renderArgs
}: Partial<StartEraseProductJobArgs> & {
    editor?: Editor | null,
    backend?: Backend,
    activeObject: EditorActiveObject,
}): Promise<Partial<StartEraseProductJobResponse>> {

    editor = editor || editorContextStore.getState().editor;
    backend = backend || editorContextStore.getState().backend;

    if (!editor || !backend || !isStaticImageObjectGenerated(activeObject)) {
        displayMessage(
            'info',
            'The original image is not valid.',
        )
        return {};
    }

    const {
        regenerateProductRenderState,
        setRegenerateProductRenderState,
    } = editorContextStore.getState();

    if (regenerateProductRenderState !== 'idle') {
        displayMessage(
            'info',
            'Please wait until the generation process is finished.'
        )
        return {};
    }

    try {

        return await eraseProductInternal({
            editor,
            backend,
            activeObject,
            ...renderArgs
        });

    } catch (error) {

        console.error(error);

    } finally {
        setRegenerateProductRenderState('idle');
    }

    return {};
}, 1000);

async function callRegenerateProductBackendInternal({
    editor,
    backend,
    activeObject,
    ...renderArgs
}: Partial<StartRegenerateProductJobArgs> & {
    editor: Editor,
    backend: Backend,
    activeObject?: StaticImageObject,
}) {
    const {
        regenerateProductNumImages,
        setRegenerateProductRenderState,
        setRegenerateProductResults,
        setRegenerateRenderProcessController,
        regenerateProductPromptTemplate: promptTemplate,
        regenerateProductInputImagePath: inputImagePath,
        regenerateProductColorStrength,
        regenerateProductCorrectColor,
        regenerateProductErasedImagePath,
        regenerateProductEraseMaskImagePath,
        regenerateProductReferenceImagePath,
    } = editorContextStore.getState();

    if (!inputImagePath) {
        return [];
    }

    let erasedImageUrl = undefined;
    let eraseMaskImageUrl = undefined;

    if (regenerateProductErasedImagePath && regenerateProductEraseMaskImagePath) {
        const erasedImageUrls = await getEraseProductImageDataUrls(
            editor,
            regenerateProductErasedImagePath,
            regenerateProductEraseMaskImagePath,
        );
        erasedImageUrl = erasedImageUrls.erased_image;
        eraseMaskImageUrl = erasedImageUrls.mask_image;
    }

    setRegenerateProductRenderState('rendering');
    setRegenerateProductResults(Array(regenerateProductNumImages).fill({
        imageUrl: undefined,
        isSelected: false,
    }));


    const objectImageUrl = (isValidHttpsUrl(inputImagePath) || isDataURL(inputImagePath)) ? inputImagePath : (
        await editor.assets.loadAsset({
            path: inputImagePath,
        })
    );

    const imageUrl = regenerateProductReferenceImagePath || activeObject?.getSrc() || "";

    const prompt = getPromptFromTemplate(promptTemplate);

    const subjectPrompt = getSubjectFromPromptTemplate(promptTemplate);

    const renderProcessController = new WebRenderProcessController();

    setRegenerateRenderProcessController(renderProcessController);

    const results = await backend.startRegenerateProductJob({
        ...renderArgs,
        job_type: 'replace-product',
        image_url: imageUrl,
        mask_url: eraseMaskImageUrl || objectImageUrl,
        erased_image_url: erasedImageUrl,
        subject_image_url: objectImageUrl,
        subject_prompt: subjectPrompt,
        prompt,
        refine_mask: !Boolean(eraseMaskImageUrl),
        num_images_per_prompt: regenerateProductNumImages,
        img2img_strength: regenerateProductImg2ImgStrengthMap[regenerateProductColorStrength],
        use_p_correction: regenerateProductCorrectColor,
        use_c_correction: getRegenerateProductUseCanny(regenerateProductColorStrength),
        renderProcessController,
        onReceiveRenderResult: async ({
            imageUrl,
            index,
        }) => {
            setRegenerateProductResults((prevResults) => {
                const newResults = prevResults.slice();

                if (index >= newResults.length) {
                    newResults.length = index + 1;
                }

                newResults[index] = getGeneratedResultFromImageUrl(
                    imageUrl,
                );

                return fillUndefined(
                    newResults,
                    {
                        imageUrl: undefined,
                        isSelected: false,
                    }
                );
            });
        }
    });


    setRegenerateProductRenderState('idle');

    const imageUrls = results as (string | undefined)[];

    while (imageUrls.length < regenerateProductNumImages) {
        imageUrls.push(undefined);
    }

    setRegenerateProductResults(imageUrls.map(getGeneratedResultFromImageUrl))

    return imageUrls;
}

export const callRegenerateProductBackend = throttle(async function callRegenerateProductBackend({
    editor = null,
    backend,
    activeObject,
    ...renderArgs
}: Partial<StartRegenerateProductJobArgs> & {
    editor?: Editor | null,
    backend?: Backend,
    activeObject: EditorActiveObject,
}): Promise<(string | undefined)[]> {

    editor = editor || editorContextStore.getState().editor;
    backend = backend || editorContextStore.getState().backend;

    if (!editor || !backend || !isStaticImageObject(activeObject)) {
        return [];
    }

    const {
        regenerateProductRenderState,
        setRegenerateProductRenderState,
    } = editorContextStore.getState();

    if (regenerateProductRenderState !== 'idle') {
        return [];
    }

    try {

        return await callRegenerateProductBackendInternal({
            editor,
            backend,
            activeObject,
            ...renderArgs
        })

    } catch (error) {

        console.error(error);

    } finally {
        setRegenerateProductRenderState('idle');
    }

    return [];
}, 1000);

export const callEraseAndRegenerateProduct = throttle(async function callEraseAndRegenerateProduct({
    editor = null,
    backend,
    activeObject,
    subject_image_url,
    subject_mask_url,
    subject_prompt,
    ...renderArgs
}: Partial<StartRegenerateProductJobArgs> & {
    editor?: Editor | null,
    backend?: Backend,
    activeObject: EditorActiveObject,
}): Promise<(string | undefined)[]> {
    editor = editor || editorContextStore.getState().editor;
    backend = backend || editorContextStore.getState().backend;

    if (!editor || !backend || !isStaticImageObjectGenerated(activeObject)) {
        return [];
    }

    const {
        regenerateProductRenderState,
        setRegenerateProductRenderState,
    } = editorContextStore.getState();

    if (regenerateProductRenderState !== 'idle') {
        return [];
    }

    try {

        await eraseProductInternal({
            editor,
            backend,
            activeObject,
            subject_image_url,
            subject_mask_url,
            subject_prompt,
        });

        return await callRegenerateProductBackendInternal({
            editor,
            backend,
            activeObject,
            subject_image_url,
            subject_mask_url,
            subject_prompt,
            ...renderArgs
        });

    } catch (error) {

        console.error(error);

    } finally {
        setRegenerateProductRenderState('idle');
    }

    return [];
}, 1000);

function isGenerationFrameEmpty(
    objectsInsideGenerationFrame: fabric.Object[],
): boolean {
    return objectsInsideGenerationFrame.length <= 0;
}

function doesGenerationFrameHaveProduct(
    objectsInsideGenerationFrame: fabric.Object[],
): boolean {
    return objectsInsideGenerationFrame.find(isStaticImageObjectUploaded) != null;
}

function doesGenerationFrameHaveProps(
    objectsInsideGenerationFrame: fabric.Object[],
): boolean {
    return objectsInsideGenerationFrame.find((object) => (
        isStaticImageObjectHed(object) ||
        isStaticImageObjectColor(object)
    )) != null;
}

function doesGenerationFrameHaveReferenceImage(
    generateToolReferenceImage?: EditorAsset,
): boolean {
    if (!generateToolReferenceImage) {
        return false;
    }

    const { path } = generateToolReferenceImage;

    return Boolean(
        path && (
            isDataURL(path) || isValidHttpsUrl(path) || isValidFirebaseStoragePath(path)
        )
    );
}

function doesGenerationFrameHaveColoredProps(
    objectsInsideGenerationFrame: fabric.Object[],
): boolean {
    return objectsInsideGenerationFrame.find(canHedImageBeColored) != null;
}

interface RenderPipelineTypeFlags {
    isEmpty?: boolean,
    hasReferenceImage?: boolean,
    haveProps?: boolean,
    haveProduct?: boolean,
    haveColoredProps?: boolean,
    haveShapeProps?: boolean,
}

function getRenderPipelineTypeFromFlags({
    isEmpty = true,
    hasReferenceImage = false,
    haveProps = false,
    haveProduct = false,
    haveColoredProps = false,
    haveShapeProps = false,
}: RenderPipelineTypeFlags) {
    // If there's nothiing on the canvas and no reference image, use default

    // If there's nothing on the canvas but we have reference image, use ref_default

    // If there's only product on the canvas, and no props, no reference images, use canny

    // If there's only product on the canvas, with reference image, and no props, use ref_canny

    // If there's props on the canvas (regardless of whether there's product), no reference image, no color, use hed

    // If there's props on the canvas, with reference image, no color, use ref_hed

    // If there's props on the canvas, no reference image, with color, use color_hed_inpaint

    // If there's props on the canvas, with reference image, with color, use ref_color_hed_inpaint


    if (isEmpty && !hasReferenceImage) {
        return RenderPipelineType.Default;
    }

    if (isEmpty && hasReferenceImage) {
        return RenderPipelineType.RefDefault;
    }

    if (haveProduct && !haveProps && !hasReferenceImage) {
        return RenderPipelineType.Canny;
    }

    if (haveProduct && !haveProps && hasReferenceImage) {
        return RenderPipelineType.RefCanny;
    }

    if (haveProps && !hasReferenceImage && !haveColoredProps) {
        return RenderPipelineType.Hed;
    }

    if (haveProps && hasReferenceImage && !haveColoredProps) {
        return RenderPipelineType.RefHed;
    }

    if (haveProps && !hasReferenceImage && haveColoredProps) {
        return RenderPipelineType.ColorHedInpaint;
    }

    if (haveProps && hasReferenceImage && haveColoredProps) {
        return RenderPipelineType.RefColorHedInpaint;
    }

    if (!isEmpty || haveShapeProps) {
        return hasReferenceImage ? RenderPipelineType.RefCanny : RenderPipelineType.Canny;
    }

    // Default case if none of the conditions are met
    return hasReferenceImage ? RenderPipelineType.RefDefault : RenderPipelineType.Default;
}

export function getRenderPipelineType({
    objectsInsideGenerationFrame,
    generateToolReferenceImage,
}: {
    generateToolReferenceImage?: EditorAsset,
    objectsInsideGenerationFrame: fabric.Object[],
}) {
    const isEmpty = isGenerationFrameEmpty(objectsInsideGenerationFrame);
    const hasReferenceImage = doesGenerationFrameHaveReferenceImage(generateToolReferenceImage);
    const haveProps = doesGenerationFrameHaveProps(objectsInsideGenerationFrame);
    const haveProduct = doesGenerationFrameHaveProduct(objectsInsideGenerationFrame);
    const haveColoredProps = doesGenerationFrameHaveColoredProps(objectsInsideGenerationFrame);

    return getRenderPipelineTypeFromFlags({
        isEmpty,
        hasReferenceImage,
        haveProps,
        haveProduct,
        haveColoredProps,
    });
}

export function getNegativePrompt() {
    return "blurry, cropped, fake, ugly, bad quality, chaotic, random, painting, drawing, graphic design, small texts, watermark, drawing, sketch, duplicate, morbid, mutilated, mutated, deformed, disfigured, extra limbs, malformed limbs, missing arms, missing legs, extra arms, extra legs, long neck, grayscale, b&w";
}

function getGuidanceScale() {
    return 7.5;
}

function getRenderImageOverlayStrength(): ImageOverlayStrength {
    return [0.0, 0.1];
}

function getControlNetConditioningScale() {
    return 1.0;
}

function getRenderArgs({
    editor,
}: {
    editor: Editor,
}): RenderArgs | undefined {
    const { user } = editorContextStore.getState();

    const {
        generateToolNumImages,
        setGenerateToolIsRendering,
        setGenerateToolRenderProgress,
    } = editorContextStore.getState();

    if (!user) {
        editor.emit<UiDisplayMessageEventHandler>(
            'ui:display-message',
            'error',
            'Render editor is invalid',
        );
        setGenerateToolIsRendering(false);
        return;
    }

    let prompt = getGenerateToolPromptValue();
    if (!prompt || prompt.length <= 0) {
        editor.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', 'Prompt is empty. Please enter a valid description of your design.');
        setGenerateToolIsRendering(false);
        return;
    }

    if (!prompt || prompt.length <= 0) {
        displayMessage('error', 'Prompt is empty, please enter a valid prompt.');
        return;
    }
    const numImages = Math.min(generateToolNumImages, 4);

    setGenerateToolRenderProgress(0.1);

    const {
        generateToolOverlayUsePoisson = true,
        generateToolOverlayUseCanny = true,
    } = editorContextStore.getState();

    const renderLength = DEFAULT_RENDER_LENGTH;

    const steps = DEFAULT_RENDER_NUM_INFERENCE_STEPS;

    const width = renderLength;

    const height = renderLength;

    const { uid } = user;

    const negative_prompt = getNegativePrompt();

    const guidance_scale = getGuidanceScale();

    const imageOverlayStrength = getRenderImageOverlayStrength();

    return {
        uid,
        prompt,
        negative_prompt,
        width,
        height,
        guidance_scale,
        image_overlay_use_canny: generateToolOverlayUseCanny,
        image_overlay_use_poisson: generateToolOverlayUsePoisson,
        num_inference_steps: steps,
        num_images: numImages,
        image_overlay_strength: imageOverlayStrength,
        apply_extra_poisson_blending_to_color_correction: true,
    };
}

async function getRenferenceImageSrc(editor: Editor) {
    const {
        generateToolReferenceImage,
    } = editorContextStore.getState();

    return generateToolReferenceImage ?
        await editor?.assets.loadAsset(generateToolReferenceImage) :
        undefined;
}

export function getInputCompositeImageFromRenderPipelineArgs(
    renderPipelineArgs: RenderPipelineArgs,
) {
    if (renderPipelineArgs.pipeline_type === RenderPipelineType.Canny) {
        return renderPipelineArgs.composite_image;
    } else if (renderPipelineArgs.pipeline_type === RenderPipelineType.RefCanny) {
        return renderPipelineArgs.composite_image;
    } else if (renderPipelineArgs.pipeline_type === RenderPipelineType.Hed) {
        return renderPipelineArgs.composite_image;
    } else if (renderPipelineArgs.pipeline_type === RenderPipelineType.RefHed) {
        return renderPipelineArgs.composite_image;
    } else if (renderPipelineArgs.pipeline_type === RenderPipelineType.ColorHedInpaint) {
        return renderPipelineArgs.composite_image;
    } else if (renderPipelineArgs.pipeline_type === RenderPipelineType.RefColorHedInpaint) {
        return renderPipelineArgs.composite_image;
    }
}

function getRenderImagesFromCanvas({
    editor,
    ...props
}: GenerationFrameDataProps & {
    editor: Editor,
}) {
    return editor.canvas.getGenerationFrameDataURLs(
        {
            ...props,
        },
    );
}

export async function getRenderPipelineArgs({
    editor,
    pipelineType,
    ...props
}: GenerationFrameDataProps & {
    editor: Editor,
    pipelineType?: RenderPipelineType,
}): Promise<RenderPipelineArgs | undefined> {

    const renderArgs = getRenderArgs({
        editor,
    });

    if (!renderArgs) {
        return;
    }


    const {
        generateToolReferenceImage,
    } = editorContextStore.getState();

    const {
        image,
        mask,
        hedImage,
        isImageEmpty = true,
        hasProps = false,
        hasProduct = false,
        hasColoredProps = false,
        hasShapeProps = false,
    } = await getRenderImagesFromCanvas({
        editor,
        ...props
    });

    const hasReferenceImage = doesGenerationFrameHaveReferenceImage(generateToolReferenceImage);

    pipelineType = pipelineType || getRenderPipelineTypeFromFlags({
        hasReferenceImage,
        isEmpty: isImageEmpty,
        haveProps: hasProps,
        haveProduct: hasProduct,
        haveColoredProps: hasColoredProps,
        haveShapeProps: hasShapeProps,
    });

    // if (process.env.NODE_ENV === 'development') {
    //     console.log({
    //         pipelineType,
    //         hasReferenceImage,
    //         isEmpty: isImageEmpty,
    //         haveProps: hasProps,
    //         haveProduct: hasProduct,
    //         haveColoredProps: hasColoredProps,
    //         haveShapeProps: hasShapeProps,
    //     });

    // }

    if (!image && !mask && !hedImage) {
        return undefined;
    }

    const referenceImageSrc = await getRenferenceImageSrc(editor);

    const {
        width = DEFAULT_RENDER_LENGTH,
        height = DEFAULT_RENDER_LENGTH,
    } = renderArgs;


    if (pipelineType === RenderPipelineType.Default) {
        return {
            ...renderArgs,
            pipeline_type: pipelineType,
        };
    } else if (pipelineType === RenderPipelineType.RefDefault) {
        return {
            ...renderArgs,
            ref_image: referenceImageSrc,
            pipeline_type: pipelineType,
        };
    } else if (pipelineType === RenderPipelineType.Canny) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();
        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            canny_controlnet_conditioning_scale: getControlNetConditioningScale(),
            canny_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            canny_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
        };
    } else if (pipelineType === RenderPipelineType.RefCanny) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();
        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            canny_controlnet_conditioning_scale: getControlNetConditioningScale(),
            canny_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            canny_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
            ref_image: referenceImageSrc,
        };
    } else if (pipelineType === RenderPipelineType.Hed) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();

        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            hed_controlnet_conditioning_scale: getControlNetConditioningScale(),
            hed_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            hed_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
            hed_image: hedImage || createSolidColorImageDataURL(width, height, '#000000'),
        }
    } else if (pipelineType === RenderPipelineType.RefHed) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();

        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            hed_controlnet_conditioning_scale: getControlNetConditioningScale(),
            hed_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            hed_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
            hed_image: hedImage || createSolidColorImageDataURL(width, height, '#000000'),
            ref_image: referenceImageSrc,
        }
    } else if (pipelineType === RenderPipelineType.ColorHedInpaint) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();

        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            hed_controlnet_conditioning_scale: getControlNetConditioningScale(),
            hed_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            hed_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
            hed_image: hedImage || createSolidColorImageDataURL(width, height, '#000000'),
        }
    } else if (pipelineType === RenderPipelineType.RefColorHedInpaint) {
        const {
            generateToolControlNetStrengthStart,
            generateToolControlNetStrengthFinish,
        } = editorContextStore.getState();
        return {
            ...renderArgs,
            pipeline_type: pipelineType,
            hed_controlnet_conditioning_scale: getControlNetConditioningScale(),
            hed_controlnet_conditioning_scale_start: generateToolControlNetStrengthStart,
            hed_controlnet_conditioning_scale_finish: generateToolControlNetStrengthFinish,
            composite_image: image,
            composite_mask_image: mask,
            hed_image: hedImage || createSolidColorImageDataURL(width, height, '#000000'),
            ref_image: referenceImageSrc,
        };
    }
}

export async function handleRenderFrontend({
    onRenderError,
}: {
    onRenderError?: (error: unknown) => boolean,
}) {
    const {
        editor,
        backend,
        userQuotas,
        generateToolReferenceImage,
        generateToolPromptTemplate,
        setGenerateToolIsRendering,
        setGenerateToolRenderJobId,
        setGenerateToolRenderProgress,
        setGenerateToolRenderProcessController,
    } = editorContextStore.getState();

    if (!editor) {

        console.error('Editor is invalid.');

        return;
    }

    if (!backend) {

        console.error('Backend is invalid.');

        return;
    }

    const {
        canvas,
        generationFrames,
    } = editor;

    if (!canvas) {

        editor.emit<UiDisplayMessageEventHandler>(
            'ui:display-message',
            "info",
            "Canvas is invalid",
        );

        return;
    }

    if (!generationFrames) {
        editor.emit<UiDisplayMessageEventHandler>(
            'ui:display-message',
            "info",
            "Generation frame is invalid",
        );
        return;
    }

    try {

        setGenerateToolIsRendering(true);
        setGenerateToolRenderProgress(0.01);

        const renderPipelineArgs = await getRenderPipelineArgs({
            editor,
            readCanvasData: true,
        });

        const pipelineType = renderPipelineArgs?.pipeline_type;

        if (process.env.NODE_ENV === 'development') {
            console.log(`Pipeline type: ${pipelineType}`);
            // console.log(renderPipelineArgs);
            // setGenerateToolIsRendering(false);
            // setGenerateToolRenderProgress(0);
            // return;
        }

        if (!renderPipelineArgs || !pipelineType) {
            setGenerateToolIsRendering(false);
            setGenerateToolRenderProgress(0);
            return;
        }

        const startLocationRef: {
            current?: fabric.Point,
        } = { current: undefined };

        const renderImageObjects: fabric.StaticImage[] = [];
        const renderProcessController = new WebRenderProcessController();
        setGenerateToolRenderProcessController(renderProcessController);

        const {
            prompt,
            num_images: samples = 1,
            width = DEFAULT_RENDER_LENGTH,
            height = DEFAULT_RENDER_LENGTH,
        } = renderPipelineArgs;

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.RenderStart,
            {
                prompt,
                width,
                height,
                samples,
            }
        );

        await backend.startRenderJob({
            renderPipelineType: pipelineType,
            renderPipelineArgs,
            userSubscriptionTier: userQuotas?.tier,
            onRenderProgress: (progress) => {
                setGenerateToolIsRendering(true);
                if (typeof (progress) === 'number') {
                    setGenerateToolRenderProgress(progress);
                } else if (typeof (progress) === 'string') {
                    progress = parseFloat(progress);
                    if (!isNaN(progress)) {
                        setGenerateToolRenderProgress(progress);
                    }
                }
            },
            onReceiveRenderResult: async ({
                imageUrl,
                index,
            }) => {
                if (!imageUrl) {
                    return;
                }
                startLocationRef.current = startLocationRef.current || getRenderResultsStartLocation(
                    editor,
                    width,
                );

                const renderImageObject = await addRenderImageResultToCanvas({
                    imageUrl,
                    index,
                    width,
                    height,
                    startLocation: startLocationRef.current,
                });

                if (renderImageObject) {
                    renderImageObjects.push(renderImageObject);
                }
            },
            renderProcessController,
        });

        setGenerateToolRenderProcessController(undefined);
        setGenerateToolIsRendering(false);
        setGenerateToolRenderProgress(1);

        const firstImageObject = renderImageObjects[0];

        if (firstImageObject) {
            setProjectThumbnailAsImageObject(
                firstImageObject,
            );

            editorContextStore.getState().setMobileBottomPanelState(MobileBottomPanelState.Hide);

            editor.zoom.zoomToFit(
                firstImageObject as any as fabric.Object,
            );

        }

        const renderInputImage = getInputCompositeImageFromRenderPipelineArgs(renderPipelineArgs);
        let renderInputImageStoragePath: string | undefined;
        if (renderInputImage) {
            renderInputImageStoragePath = await uploadRenderInputImage(renderInputImage);
        }


        renderImageObjects.forEach((
            renderImageObject,
        ) => {
            if (!renderImageObject) {
                console.log("Render image object is invalid");
                return;
            }
            onRenderImageResultAdded({
                outputImage: renderImageObject,
                prompt,
                inputImagePath: renderInputImageStoragePath,
                promptTemplate: generateToolPromptTemplate,
            });
        });

        setTimeout(() => {
            setGenerateToolRenderProgress(0);
        }, 100);

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.RenderFinish,
            {
                prompt,
                width,
                height,
                samples,
            }
        );

    } catch (error) {
        displayMessage('error', "Unknown error when rendering image.");

        console.error(error);

        const stopResetState = onRenderError?.(error);

        if (!stopResetState) {
            setGenerateToolIsRendering(false);
            setGenerateToolRenderJobId(null);
            setGenerateToolRenderProgress(0);
        }

        editorContextStore.getState().analytics.track(
            AnalyticsConfig.RenderError,
            {
                error,
            }
        );
    }
}