import React, { createContext, useState } from "react"
import { classNames } from "core/utils/classname-utils";
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, PrimaryButtonClassNameLoading, SecondaryButtonClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { MessageDialog, MessageDialogClose, MessageDialogProps } from "./message-dialog"
import { editorContextStore } from "contexts/editor-context";
import { AppUserSubscriptionTierV2 } from "core/common/types";
import { openSubscriptionsLink, subscribeToIndividualProPlan, subscribeToPlan } from "components/subscription/checkout-portal";
import { Cross1Icon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import styles from './manage-subscription.module.css';
import { SimpleSpinner } from "components/icons/simple-spinner";
import { InvoiceGenerator } from "backend/firebase/invoice-generator";
import { StripeInvoice } from "core/common/types/stripe";
import { useInView } from "react-intersection-observer";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { ArrowLeft, CheckCircle2Icon, CircleSlash, Download } from "lucide-react";
import { downloadDataUrl } from "components/utils/data";
import { getObjectEntries } from "core/utils/type-utils";
import { AppSubscriptionPlanType, StripeSubscriptionPriceId, StripeSubscriptionProductId, isSubscriptionPlanBetterThan, subscriptionPlanToPrice, subscriptionPlanToProduct, subscriptionTierV2ToPlan, subscriptionTierV2ToRecommendedPlan } from "core/common/types/subscription";
import * as Tabs from '@radix-ui/react-tabs';
import { noop } from "lodash";
import { UpdateStripeSusbcriptionArgs } from "backend/base";
import { displayUiMessage } from "components/utils/display-message";
import * as Tooltip from '@radix-ui/react-tooltip';
import { MessageDialogZIndex } from "components/constants/zIndex";

enum ManageSubscriptionDialogTab {
    Plans = 'Plans',
    Invoices = 'Invoices',
}

enum ManagePlanPage {
    Default = 'default',
    Update = 'update',
    Unsubscribe = 'unsubscribe',
}

// Define the context shape
interface TabContextType {
    activeTab: ManageSubscriptionDialogTab;
    setActiveTab: React.Dispatch<React.SetStateAction<ManageSubscriptionDialogTab>>;
    managePlanPage: ManagePlanPage;
    setManagePlanPage: React.Dispatch<React.SetStateAction<ManagePlanPage>>;
    currentPlan: AppSubscriptionPlanType;
    setCurrentPlan: React.Dispatch<React.SetStateAction<AppSubscriptionPlanType>>;
    nextPlan: AppSubscriptionPlanType | undefined;
    setNextPlan: React.Dispatch<React.SetStateAction<AppSubscriptionPlanType | undefined>>;
}

// Create a context with a default undefined value (will never be used directly without a provider)
const TabContext = React.createContext<TabContextType>({
    activeTab: ManageSubscriptionDialogTab.Plans,
    setActiveTab: noop,
    managePlanPage: ManagePlanPage.Default,
    setManagePlanPage: noop,
    currentPlan: AppSubscriptionPlanType.Free,
    setCurrentPlan: noop,
    nextPlan: AppSubscriptionPlanType.Free,
    setNextPlan: noop,
});


function SubscribeButton({
    className = "",
    isLoading,
    setLoading,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isLoading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    return (
        <button
            className={classNames(
                PrimaryButtonClassName,
                "flex flex-row items-center justify-center",
                isLoading ? "" : styles.AnimateShadowOpacity,
                isLoading ? "cursor-wait bg-lime-200 hover:bg-lime-200 active:bg-lime-300" : "cursor-pointer bg-zinc-100 hover:bg-white active:bg-lime-300 shadow-white/50",
                className,
            )}
            onClick={() => {
                if (isLoading) {
                    return;
                }
                setLoading(true);
                subscribeToIndividualProPlan(false).then(() => {
                    setLoading(false);
                });
            }}
            {...props}
        >
            {isLoading && <SimpleSpinner
                width={23}
                height={23}
                className="mr-2"
                pathClassName="fill-lime-500"
            />}
            {isLoading ?
                "Redirecting ..." :
                "Subscribe"
            }
        </button>
    )
}

function FreePlanBadge() {
    const userQuotas = editorContextStore(state => state.userQuotas);
    const numRenders = userQuotas?.numRenders || 0;
    const maxNumRenders = userQuotas?.maxNumRenders || 100;
    const maxNumProjects = userQuotas?.maxNumProjects || 10;
    return (
        <div
            className="w-full px-4 py-3 rounded-md border border-zinc-800"
        >
            <div className="flex flex-row items-center justify-start">
                <span className="font-semibold mr-4 truncate">
                    Free Plan
                </span>
                <span
                    className="rounded-full px-3 py-1 bg-lime-900 font-semibold text-xs text-lime-500 truncate"
                >
                    Current Plan
                </span>
                <span className="flex-1" />
                <span className="font-semibold text-zinc-400 truncate">
                    $0 / month
                </span>
            </div>
            <div className="w-full text-zinc-500 my-2 truncate">
                {maxNumRenders} renders + {maxNumProjects} projects.
            </div>
            <div className="w-full flex flex-row text-sm">
                <span>
                    Render usage:
                </span>
                <span className="flex-1" />
                <span>
                    {numRenders} / {maxNumRenders}
                </span>
            </div>
            <div
                className="relative w-full mt-2 rounded-full h-1 bg-zinc-800 overflow-hidden"
            >
                <div
                    className="absolute rounded-full bg-lime-500"
                    style={{
                        width: `${100 * numRenders / maxNumRenders}%`,
                        height: "100%",
                    }}
                />
            </div>
        </div>
    )
}

function ProPlanBadge({
    isLoading,
    setLoading,
}: {
    isLoading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    return (
        <div
            className="w-full px-4 py-3 rounded-md bg-lime-500 text-zinc-900 shadow-lg"
        >
            <div className="flex flex-row items-center justify-start">
                <span className="font-semibold mr-4">
                    Pro Plan
                </span>
                <span className="flex-1" />
                <span className="font-semibold text-zinc-800">
                    $10 / month
                </span>
            </div>
            <div className="text-zinc-800 my-2">
                Unlimited render + unlimited projects.
            </div>
            <div className="h-2" />
            <SubscribeButton
                isLoading={isLoading}
                setLoading={setLoading}
                className="w-full"
            />
        </div>
    )
}

function ManageSubscriptionDialogContentFree() {
    const [isLoading, setLoading] = React.useState(false);


    return (
        <>
            <FreePlanBadge />
            <div className="h-4" />
            <ProPlanBadge
                isLoading={isLoading}
                setLoading={setLoading}
            />
            <div className="h-4" />
        </>
    )
}

function stripeTimestampToReadableDate(stripeTimestamp: number): string {
    // Stripe timestamps are in seconds, JavaScript Date expects milliseconds
    const date = new Date(stripeTimestamp * 1000);

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: true,
    };

    // Format the date to the user's locale and timezone
    return date.toLocaleDateString(undefined, options);
}


async function downloadInvoicePdfFromStripeObject(invoice: StripeInvoice): Promise<void> {
    if (!invoice.invoice_pdf) {
        console.error('Invoice PDF URL not found');
        return;
    }
    const formattedDate = stripeTimestampToReadableDate(invoice.created);
    const fileName = `flair-invoice-${formattedDate}.pdf`;

    try {
        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        const storagePath = await backend.downloadAndUploadInvoice(invoice.id);

        if (!storagePath) {
            return;
        }

        const downloadUrl = await backend.getDownloadUrlFromStoragePath(storagePath);

        downloadDataUrl(downloadUrl, fileName);

    } catch (error) {
        console.error('Error downloading invoice PDF:', error);
    }
}

function DownloadInvoiceButton({
    invoice,
}: {
    invoice: StripeInvoice,
}) {
    const [isDownloading, setIsDownloading] = React.useState(false);

    return (
        <button
            className={classNames(
                "flex flex-row items-center justify-center gap-2 text-sm rounded px-2 py-1 bg-transparent transition-colors",
                invoice.invoice_pdf && !isDownloading ? "hover:bg-zinc-800 hover:text-lime-500 cursor-pointer" : "cursor-not-allowed text-zinc-700",
                isDownloading ? "cursor-wait bg-zinc-800/50" : "",
            )}
            onClick={() => {
                const pdfLink = invoice.invoice_pdf;

                if (!pdfLink || isDownloading) {
                    return;
                }

                setIsDownloading(true); // Start loading

                downloadInvoicePdfFromStripeObject(invoice).then(() => {
                    setIsDownloading(false); // Finish loading
                }).catch(() => {
                    setIsDownloading(false); // Handle error and finish loading
                });
            }}
            disabled={isDownloading}
        >
            {isDownloading ? <SimpleSpinner width={16} height={16} pathClassName="fill-lime-500" /> : <Download size={16} />}
            Download
        </button>
    );
}

function LoadingUserInvoices() {
    return (
        <div
            className="flex flex-row py-2 justify-center items-center text-zinc-500 gap-2 text-sm"
        >
            <SimpleSpinner width={16} height={16} pathClassName="fill-lime-500" />
            Loading invoices ...
        </div>
    )
}

function EmptyUserInvoices() {
    return (
        <div
            className="flex flex-row py-2 justify-center items-center text-zinc-800 gap-2 text-sm"
        >
            <CircleSlash size={16} />
            No more invoices.
        </div>
    )
}

function UserInvoices() {
    const invoicesGeneratorRef = React.useRef(new InvoiceGenerator());

    const [invoices, setInvoices] = React.useState<StripeInvoice[]>([]);

    const [isLoading, setIsLoading] = React.useState(true);

    const [
        lastRowRef,
        lastRowInView,
    ] = useInView();

    React.useEffect(() => {
        setIsLoading(true);

        invoicesGeneratorRef.current
            .getNextBatch()
            .then((invoices) => {
                setInvoices(invoices);
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    React.useEffect(() => {
        if (!lastRowInView) {
            return;
        }

        setIsLoading(true);
        invoicesGeneratorRef.current
            .getNextBatch()
            .then((invoices) => {
                setInvoices((prev) => {
                    const prevIds = new Set(prev.map(invoice => invoice.id));
                    return [
                        ...prev,
                        ...invoices.filter(invoice => !prevIds.has(invoice.id))
                    ]
                });
            }).finally(() => {
                setIsLoading(false);
            });
    }, [lastRowInView]);

    return (
        <ScrollAreaContainer
            viewportProps={{
                className: "max-h-full",
            }}
        >
            <div className="px-3 grid grid-cols-1 divide-y divide-zinc-800">
                {invoices.map((invoice, index) => (
                    <div
                        key={invoice.id}
                        ref={index === invoices.length - 1 ? lastRowRef : undefined}
                        className="flex flex-row py-2 items-center text-zinc-300 gap-4 text-sm"
                    >
                        <div className="font-semibold text-zinc-500 truncate">
                            Invoice
                        </div>
                        <div className="truncate">
                            {stripeTimestampToReadableDate(invoice.created)}
                        </div>
                        <div className="flex-1" />
                        <DownloadInvoiceButton
                            invoice={invoice}
                        />
                    </div>
                ))}
                {isLoading ? <LoadingUserInvoices /> : <EmptyUserInvoices />}
            </div>
        </ScrollAreaContainer>
    );

}

function ManageSubscriptionDialogContentProStep0({
    onClickNext,
}: {
    onClickNext: () => void,
}) {
    return (
        <div
            className="w-full"
        >
            <div
                className="w-full px-1 py-3 mb-4 rounded-md border border-zinc-800"
            >
                <div className="mx-3 flex flex-row items-center justify-start">
                    <span className="font-semibold mr-4 truncate">
                        Pro Plan
                    </span>
                    <span
                        className="rounded-full px-3 py-1 bg-lime-900 font-semibold text-xs text-lime-500 truncate"
                    >
                        Current Plan
                    </span>
                    <span className="flex-1" />
                    <span className="font-semibold text-zinc-400 truncate">
                        $10 / month
                    </span>
                </div>
                <div className="mx-3 w-full text-zinc-500 my-2 truncate">
                    Unlimited render + unlimited projects.
                </div>
                <UserInvoices />
            </div>
            <div className="w-full flex flex-row">
                <div className="flex-1" />
                <button
                    className={classNames(
                        "flex flex-row items-center justify-end select-none text-zinc-400 hover:text-zinc-100 transition-colors cursor-pointer",
                    )}
                    onClick={onClickNext}
                >
                    Cancel subscription
                </button>
            </div>
        </div>
    )
}

const cancelingReasons = [
    'No longer needed',
    'Too expensive',
    'App too hard to use',
    'Found alternative',
    'Need higher quality results',
    'Other',
];

function UnsubscribeReason({
    onClickBack,
    onClickNext,
}: {
    onClickBack?: () => void,
    onClickNext: () => void,
}) {
    const [isReasonSelected, setReasonSelected] = React.useState<boolean[]>(cancelingReasons.map(() => false));
    const isAnySelected = Boolean(isReasonSelected.find((v) => v));
    const [isLoading, setLoading] = React.useState(false);
    const canContinue = isAnySelected && !isLoading;

    return (
        <div
            className="w-full"
        >
            <div className="text-lg font-semibold">
                Why are you canceling? 🥺
            </div>
            <div
                className="w-full grid grid-cols-2 gap-2 mt-4"
            >
                {cancelingReasons.map((reason, index) => {
                    const isSelected = isReasonSelected[index] || false;
                    return (
                        <button
                            key={reason + index}
                            className={classNames(
                                isSelected ? SecondaryButtonClassName : SecondaryButtonClassNameInactive,
                            )}
                            onClick={() => {
                                setReasonSelected(array => {
                                    array = array.slice();
                                    array[index] = !isSelected;
                                    return array;
                                })
                            }}
                        >
                            {reason}
                        </button>
                    )
                })}
                <button
                    className={classNames(
                        PrimaryButtonClassName,
                        "flex flex-row items-center justify-center gap-3",
                    )}
                    onClick={onClickBack}
                >
                    <ArrowLeft size={18} />
                    Back
                </button>
                <button
                    className={classNames(
                        canContinue ?
                            SecondaryButtonClassNameInactive :
                            isLoading ? PrimaryButtonClassNameLoading : PrimaryButtonClassNameDisabled,
                        isLoading ? "text-zinc-900" : "",
                        "flex flex-row items-center justify-center"
                    )}
                    onClick={() => {
                        if (!isAnySelected) {
                            return;
                        }
                        if (isLoading) {
                            return;
                        }
                        setLoading(true);
                        openSubscriptionsLink(true).then(() => {
                            setLoading(false);
                            onClickNext();
                        });
                    }}
                >
                    {isLoading && <SimpleSpinner
                        width={23}
                        height={23}
                        className="mr-2"
                        pathClassName="fill-lime-500"
                    />}
                    {isLoading ?
                        "Redirecting ..." :
                        isAnySelected ? "Next" : "Select feedbacks above"
                    }
                </button>
            </div>
        </div>
    );
}

function UnsubscribeOpenPortal() {
    return (
        <div
            className="w-full flex flex-col"
        >
            <div className="mb-4 font-semibold">
                Thank you for trying out Flair!
            </div>
            <div className="mb-8 text-zinc-400">
                You are redirected to the billing portal. <br />
                Click the <span className="text-zinc-200">"Cancel Plan"</span> button there to finish the cancellation.
            </div>
            <MessageDialogClose
                className={PrimaryButtonClassName}
            >
                Done
            </MessageDialogClose>
        </div>
    )
}

function ManageSubscriptionDialogContentPro() {
    const [step, setStep] = React.useState(0);

    const handleClickBack = React.useCallback(() => {
        setStep(s => Math.max(s - 1, 0));
    }, []);

    const handleClickNext = React.useCallback(() => {
        setStep(s => Math.min(s + 1, 2));
    }, []);

    if (step === 0) {
        return (
            <ManageSubscriptionDialogContentProStep0
                onClickNext={handleClickNext}
            />
        );
    } else if (step === 1) {
        return (
            <UnsubscribeReason
                onClickNext={handleClickNext}
                onClickBack={handleClickBack}
            />
        );
    } else if (step === 2) {
        return (
            <UnsubscribeOpenPortal />
        )
    }

    return null;
}

type SubscriptionPlanContent = React.ReactNode | string;

export interface DisplaySubscriptionPlan {
    id: AppSubscriptionPlanType,
    planId?: StripeSubscriptionProductId,
    priceId?: StripeSubscriptionPriceId,
    name: SubscriptionPlanContent,
    heroMessage: SubscriptionPlanContent,
    primaryButton?: SubscriptionPlanContent,
    description?: SubscriptionPlanContent,
    features: SubscriptionPlanContent[],
    featuresTitle?: SubscriptionPlanContent,
}

const PlanCardHero = React.forwardRef(function PlanCardHero(
    {
        price,
        description,
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        price: SubscriptionPlanContent,
        description?: SubscriptionPlanContent,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className="flex flex-row items-end justify-center gap-2 py-4"
            {...props}
        >
            <div
                className="text-5xl"
            >
                {price}
            </div>
            <div className="text-base text-zinc-500">
                {description}
            </div>
        </div>
    );
});

function PlanFeatureTooltip({
    children,
}: {
    children: React.ReactNode,
}) {
    return (
        <Tooltip.Root
            delayDuration={100}
        >
            <Tooltip.Trigger>
                <QuestionMarkCircledIcon
                    className="text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer"
                />
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    className={classNames(
                        DropdownClassName,
                        'bg-zinc-950 lg:max-w-[300px] text-sm',
                    )}
                    style={{
                        zIndex: MessageDialogZIndex,
                    }}
                    sideOffset={6}
                    side='bottom'
                >
                    {children}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    );
}

export const appSubscriptionPlans: Record<AppSubscriptionPlanType, DisplaySubscriptionPlan> = {
    [AppSubscriptionPlanType.Free]: {
        id: AppSubscriptionPlanType.Free,
        name: 'Free',
        heroMessage: (
            <PlanCardHero
                price="$0"
            />
        ),
        primaryButton: "Your current plan",
        features: [
            "Up to 100 designs / month",
            "Up to 10 projects",
            "3 minutes real-time / day",
        ],
    },
    [AppSubscriptionPlanType.Pro]: {
        id: AppSubscriptionPlanType.Pro,
        planId: subscriptionPlanToProduct[AppSubscriptionPlanType.Pro],
        priceId: subscriptionPlanToPrice[AppSubscriptionPlanType.Pro],
        name: 'Pro',
        heroMessage: (
            <PlanCardHero
                price="$10"
                description="/ month"
            />
        ),
        primaryButton: "Get Pro",
        features: [
            "Unlimited projects",
            <div className="flex flex-row gap-2">
                <span>
                    ~3 custom models / month
                </span>
                <PlanFeatureTooltip>
                    <span>
                        A typical custom model training run would take 45 minutes. <br />
                        The Pro plan has a total of 135 minutes per month.
                    </span>
                </PlanFeatureTooltip>
            </div>,
            <div className="flex flex-row gap-2">
                <span>
                    ~5500 real-time images
                </span>
                <PlanFeatureTooltip>
                    <span>
                        A typical real-time session would generate 1 image per two seconds. <br />
                        The Pro plan has a total of 3 GPU hours per month.
                    </span>
                </PlanFeatureTooltip>
            </div>,
            "Faster Render",
            "Image Upscale / Variations",
            "Personal Commercial License"

        ],
    },
    [AppSubscriptionPlanType.ProPlus]: {
        id: AppSubscriptionPlanType.ProPlus,
        planId: subscriptionPlanToProduct[AppSubscriptionPlanType.ProPlus],
        priceId: subscriptionPlanToPrice[AppSubscriptionPlanType.ProPlus],
        name: 'Pro+',
        heroMessage: (
            <PlanCardHero
                price="$35"
                description="/ month"
            />
        ),
        primaryButton: "Get Pro+",
        features: [
            "Unlimited Projects",
            <div className="flex flex-row gap-2">
                <span>
                    ~6 custom models / month
                </span>
                <PlanFeatureTooltip>
                    <span>
                        A typical custom model training run would take 45 minutes. <br />
                        The Pro+ plan has a total of 270 minutes per month.
                    </span>
                </PlanFeatureTooltip>
            </div>,
            <div className="flex flex-row gap-2">
                <span>
                    ~18000 real-time images
                </span>
                <PlanFeatureTooltip>
                    <span>
                        A typical real-time session would generate 1 image per two seconds. <br />
                        The Pro+ plan has a total of 10 GPU hours per month.
                    </span>
                </PlanFeatureTooltip>
            </div>,
            "Fastest render",
            "Image Upscales / Variations",
            "Company Commercial Licesnse",
            "API Access"
        ],
    },
}

const PlanCardTitle = React.forwardRef(function PlanCardTitle(
    {
        subscriptionPlan,
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        subscriptionPlan: DisplaySubscriptionPlan,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                "flex flex-col items-start text-lg lg:text-xl",
                className,
            )}
            {...props}
        >
            {subscriptionPlan.name}
        </div>
    );
});

type OnClickPlanButtonHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<unknown>;

const PlanCardButton = React.forwardRef(function PlanCardButton(
    {
        subscriptionPlan,
        recommended = false,
        disabled = false,
        isCurrent = false,
        className = "",
        onClick,
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        subscriptionPlan: DisplaySubscriptionPlan,
        recommended?: boolean,
        disabled?: boolean,
        isCurrent?: boolean,
        onClick?: OnClickPlanButtonHandler,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <div
            ref={forwardedRef}
            className={classNames(
                "flex flex-row items-center justify-center cursor-pointer text-sm lg:text-base",
                isLoading ? PrimaryButtonClassNameLoading :
                    disabled ? PrimaryButtonClassNameDisabled :
                        recommended ? PrimaryButtonClassName : SecondaryButtonClassNameInactive,
                className,
            )}
            onClick={async (e) => {
                if (isLoading) {
                    return;
                }

                setIsLoading(true);
                await onClick?.(e);
                setIsLoading(false);
            }}
            {...props}
        >
            {isLoading ?
                (
                    <>
                        <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
                        <span className="ml-2">
                            Redirecting ...
                        </span>
                    </>
                ) :
                isCurrent ?
                    "Current Plan" :
                    subscriptionPlan.primaryButton || subscriptionPlan.name}
        </div>
    )
});

export const PlanCardFeatures = React.forwardRef(function PlanCardFeatures(
    {
        subscriptionPlan,
        recommended = false,
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        subscriptionPlan: DisplaySubscriptionPlan,
        recommended?: boolean,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            className={classNames(
                "min-h-0 flex flex-col items-start gap-4",
                className,
            )}
            {...props}
        >
            {subscriptionPlan.featuresTitle}
            {subscriptionPlan.features.map((feature, index) => {
                return (
                    <div
                        key={index}
                        className={classNames(
                            "flex flex-row items-start justify-start gap-2 lg:gap-4 text-xs lg:text-sm",
                        )}
                    >
                        <div className={classNames(
                            "my-px",
                            recommended ? "text-lime-500" : "text-zinc-500"
                        )}>
                            <CheckCircle2Icon
                                size={18}
                            />
                        </div>
                        <div className="flex-1">
                            {feature}
                        </div>
                    </div>
                )
            })}
        </div>
    );
});


const PlanCardUnsubscribe = React.forwardRef(function PlanCardFeatures(
    {
        subscriptionPlan,
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        subscriptionPlan: DisplaySubscriptionPlan,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    const {
        setManagePlanPage,
    } = React.useContext(TabContext);

    return (
        <div
            ref={forwardedRef}
            className={classNames(
                "flex flex-row items-center justify-center text-sm text-zinc-500 hover:text-zinc-300 active:text-zinc-700 cursor-pointer transition-colors",
                className,
            )}
            onClick={() => {
                setManagePlanPage(ManagePlanPage.Unsubscribe);
            }}
            {...props}
        >
            Unsubscribe
        </div>
    );
});

export const PlanCard = React.forwardRef(function PlanCard(
    {
        subscriptionPlan,
        recommended = false,
        disabled = false,
        isCurrent = false,
        canUnsubscribe = false,
        onClickPrimaryButton,
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        subscriptionPlan: DisplaySubscriptionPlan,
        recommended?: boolean,
        disabled?: boolean,
        isCurrent?: boolean,
        canUnsubscribe?: boolean,
        onClickPrimaryButton?: OnClickPlanButtonHandler,
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwardedRef}
            {...props}
            className={classNames(
                "relative min-w-0 flex flex-col px-4 py-4 lg:px-8 lg:py-8 gap-4 rounded-lg transition-colors",
                disabled ?
                    "border border-zinc-800 shadow-xl text-zinc-500" :
                    recommended ?
                        "border border-lime-500 shadow-[0_0_25px_rgba(0,0,0,0.1)] shadow-lime-500/10" :
                        "border border-zinc-800 shadow-xl",
            )}
        >
            {recommended && (
                <div
                    className="absolute top-[-15px] right-[10%] px-3 py-1 rounded-full bg-lime-500 text-xs lg:text-sm text-zinc-900"
                >
                    Recommended
                </div>
            )}
            <PlanCardTitle
                subscriptionPlan={subscriptionPlan}
            />
            {subscriptionPlan.heroMessage}
            <PlanCardButton
                disabled={disabled}
                recommended={recommended}
                subscriptionPlan={subscriptionPlan}
                isCurrent={isCurrent}
                onClick={onClickPrimaryButton}
            />
            <PlanCardFeatures
                recommended={recommended}
                subscriptionPlan={subscriptionPlan}
                className="my-4"
            />
            {canUnsubscribe && (
                <PlanCardUnsubscribe
                    subscriptionPlan={subscriptionPlan}
                    className="w-full"
                />
            )}
        </div>
    )
});

async function updateSubscriptionPlan(args: UpdateStripeSusbcriptionArgs) {
    try {
        // await new Promise((resolve) => (
        //     setTimeout(resolve, 1000)
        // ));

        const {
            backend,
        } = editorContextStore.getState();

        if (!backend) {
            return;
        }

        const {
            updated,
            message,
        } = await backend.updateStripeSubscription(args);

        if (!updated) {

            displayUiMessage(
                message,
                'error',
            );

            return;
        }

    } catch (error) {

        console.error(error);

        displayUiMessage(
            'Cannot update subscription. Please try again later.',
            'error',
        );

    }
}

const PrimaryButtonLoadingClassName = classNames(
    PrimaryButtonClassNameLoading,
    "flex-1",
);

/* @tw */
const PrimaryButtonIdleClassName = classNames(
    PrimaryButtonClassName,
    "flex-1",
);

/* @tw */
const SecondaryButtonLoadingClassName = classNames(
    SecondaryButtonClassNameDisabled,
    "items-center justify-center flex-1",
    "cursor-wait",
);

/* @tw */
const SecondaryButtonIdleClassName = classNames(
    SecondaryButtonClassNameInactive,
    "items-center justify-center flex-1",
    "cursor-pointer",
);

function UpdatePlan() {
    const {
        currentPlan,
        nextPlan,
        setManagePlanPage,
    } = React.useContext(TabContext);

    const nextSubscriptionPlan = nextPlan ? appSubscriptionPlans[nextPlan] : undefined;

    const [isUpdating, setIsUpdating] = React.useState(false);

    const [message, setMessage] = React.useState('');

    const isUpgrade = nextPlan && isSubscriptionPlanBetterThan(nextPlan, currentPlan);

    const BackButtonLoadingClassName = isUpgrade ? SecondaryButtonLoadingClassName : PrimaryButtonLoadingClassName;
    const BackButtonIdleClassName = isUpgrade ? SecondaryButtonIdleClassName : PrimaryButtonIdleClassName;
    const ConfirmButtonLoadingClassName = isUpgrade ? PrimaryButtonLoadingClassName : SecondaryButtonLoadingClassName;
    const ConfirmButtonIdleClassName = isUpgrade ? PrimaryButtonIdleClassName : SecondaryButtonIdleClassName;


    return (
        <div
            className="w-fit flex flex-col items-center justify-center gap-8"
        >
            <div className="text-lg">
                Are you sure to {isUpgrade ? "upgrade" : "downgrade"} to {nextPlan} plan?
            </div>
            {nextSubscriptionPlan && <PlanCardFeatures
                subscriptionPlan={nextSubscriptionPlan}
            />}
            <div
                className="relative w-full flex flex-row items-center justify-center gap-2"
            >
                <button
                    className={classNames(
                        isUpdating ? BackButtonLoadingClassName : BackButtonIdleClassName,
                        "flex-1",
                    )}
                    onClick={() => {
                        if (isUpdating) {
                            return;
                        }

                        setManagePlanPage(ManagePlanPage.Default);
                    }}
                >
                    Back
                </button>
                <button
                    className={classNames(
                        isUpdating ? ConfirmButtonLoadingClassName : ConfirmButtonIdleClassName,
                        "items-center justify-center flex-1",
                        isUpdating ? "cursor-wait" : "cursor-pointer",
                    )}
                    onClick={async () => {
                        if (isUpdating) {
                            return;
                        }

                        if (!nextPlan) {
                            setMessage('Cannot update plan because the target plan is invalid.');
                            return;
                        }


                        const fromProductId = subscriptionPlanToProduct[currentPlan];

                        if (!fromProductId) {

                            setMessage('Cannot update plan because the currnet plan is invalid.');
                            return;

                        }

                        const toProductId = subscriptionPlanToProduct[nextPlan];

                        if (!toProductId) {
                            setMessage('Cannot update plan because the target plan is invalid.');
                            return;
                        }

                        setMessage("Updating subscription ...");

                        setIsUpdating(true);

                        await updateSubscriptionPlan({
                            toProductId,
                            fromProductId,
                        });

                        setIsUpdating(true);

                        setMessage("Verifying that the update is successful ...");

                        // Wait until the quota changes

                        await new Promise<void>(resolve => {

                            const unsubscribe = editorContextStore.subscribe(
                                state => state.userQuotas?.tierV2,
                                () => {
                                    unsubscribe();
                                    resolve();
                                },
                            );

                        });


                        setIsUpdating(false);

                        setMessage("");

                        setManagePlanPage(ManagePlanPage.Default);

                    }}
                >
                    Confirm
                </button>
            </div>
            <div className="flex flex-row items-center justify-center gap-2 text-sm text-zinc-500">
                {isUpdating && <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />}
                {message}
            </div>
        </div>
    )
}

function UnsubscribePlanContainer({
    className = "",
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,) {
    return (
        <div
            className={classNames(
                className,
                "md:w-[min(80%, 500px)]",
            )}
            {...props}
        >
            {children}
        </div>
    )
}

function UnsubscribePlan() {
    const [step, setStep] = React.useState(0);

    const {
        setManagePlanPage,
    } = React.useContext(TabContext);

    const handleClickBack = React.useCallback(() => {

        if (step === 0) {
            // Exit
            setManagePlanPage(ManagePlanPage.Default);
        }

        setStep(s => Math.max(s - 1, 0));

    }, [step, setManagePlanPage]);

    const handleClickNext = React.useCallback(() => {
        setStep(s => Math.min(s + 1, 1));
    }, []);

    if (step === 0) {
        return (
            <UnsubscribePlanContainer>
                <UnsubscribeReason
                    onClickBack={handleClickBack}
                    onClickNext={handleClickNext}
                />
            </UnsubscribePlanContainer>
        );
    } else if (step === 1) {
        return (
            <UnsubscribePlanContainer>
                <UnsubscribeOpenPortal />
            </UnsubscribePlanContainer>
        )
    }

    return null;
}

const tierV2ToRecommendedPlan = subscriptionTierV2ToRecommendedPlan;

const tierV2ToPlan = subscriptionTierV2ToPlan;

function DefaultPlans() {
    const userQuotas = editorContextStore(state => state.userQuotas);
    const tierV2 = userQuotas?.tierV2 || AppUserSubscriptionTierV2.Free;
    const recommendedPlan = tierV2ToRecommendedPlan[tierV2];
    const hideFreeTier = tierV2 !== AppUserSubscriptionTierV2.Free;

    const {
        currentPlan,
        setNextPlan,
        setActiveTab,
        setManagePlanPage,
    } = React.useContext(TabContext);

    return (
        <div
            className={classNames(
                "grid grid-flow-col auto-cols-auto items-stretch justify-center gap-4",
            )}
        >
            {getObjectEntries(appSubscriptionPlans)
                .filter(([planId,]) => (
                    !hideFreeTier ||
                    planId !== AppSubscriptionPlanType.Free
                ))
                .map(([planId, subscriptionPlan]) => (
                    <PlanCard
                        key={planId}
                        subscriptionPlan={subscriptionPlan}
                        recommended={planId === recommendedPlan}
                        disabled={planId === AppSubscriptionPlanType.Free}
                        isCurrent={currentPlan === planId}
                        canUnsubscribe={currentPlan === planId && planId !== AppSubscriptionPlanType.Free}
                        onClickPrimaryButton={async () => {
                            if (currentPlan === AppSubscriptionPlanType.Free &&
                                planId !== AppSubscriptionPlanType.Free) {
                                // Open payment portal

                                const priceId = subscriptionPlanToPrice[planId];

                                await subscribeToPlan(
                                    priceId,
                                );

                            } else {

                                if (planId === currentPlan) {

                                    if (planId !== AppSubscriptionPlanType.Free) {

                                        // Open the invoices tab

                                        setActiveTab(ManageSubscriptionDialogTab.Invoices);

                                    }

                                } else {

                                    setNextPlan(planId);

                                    setManagePlanPage(ManagePlanPage.Update);

                                }

                            }
                        }}
                    />
                ))}
        </div>
    );
}

const planPages = {
    [ManagePlanPage.Default]: DefaultPlans,
    [ManagePlanPage.Update]: UpdatePlan,
    [ManagePlanPage.Unsubscribe]: UnsubscribePlan,
}

const Plans = React.forwardRef(function Plans(
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwaredRef: React.ForwardedRef<HTMLDivElement>,
) {
    const { managePlanPage } = React.useContext(TabContext);

    const Component = planPages[managePlanPage];

    return (
        <div
            ref={forwaredRef}
            className={classNames(
                "py-2 flex justify-center",
                className,
            )}
            {...props}
        >
            <Component />
        </div>
    );
});

const Invoices = React.forwardRef(function Invoices(
    {
        className = "",
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwaredRef: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <div
            ref={forwaredRef}
            className={classNames(
                "py-2 flex flex-col",
                className,
            )}
            {...props}
        >
            <UserInvoices />
        </div>
    )
});


const TabToComponent = {
    [ManageSubscriptionDialogTab.Plans]: Plans,
    [ManageSubscriptionDialogTab.Invoices]: Invoices,
}

function ManageSubscriptionDialogContent() {
    const [activeTab, setActiveTab] = React.useState<ManageSubscriptionDialogTab>(ManageSubscriptionDialogTab.Plans);
    const [managePlanPage, setManagePlanPage] = React.useState<ManagePlanPage>(ManagePlanPage.Default);
    const [currentPlan, setCurrentPlan] = React.useState<AppSubscriptionPlanType>(AppSubscriptionPlanType.Free);
    const [nextPlan, setNextPlan] = React.useState<AppSubscriptionPlanType | undefined>(undefined);

    const userQuotas = editorContextStore(state => state.userQuotas);

    React.useEffect(() => {
        const tierV2 = userQuotas?.tierV2 || AppUserSubscriptionTierV2.Free;
        const currentPlan = tierV2ToPlan[tierV2];
        setCurrentPlan(currentPlan);
    }, [userQuotas?.tierV2]);

    return (
        <TabContext.Provider
            value={{
                activeTab,
                setActiveTab,
                managePlanPage,
                setManagePlanPage,
                currentPlan,
                setCurrentPlan,
                nextPlan,
                setNextPlan,
            }}
        >
            <div
                className={classNames(
                    DropdownClassName,
                    'rounded-2xl min-w-[80vw] md:min-w-0 w-[80vw] lg:w-[950px] max-h-[180vh] lg:h-[min(700px,180vh)] px-6 py-4 flex flex-col items-center overflow-hidden',
                )}
            >
                <MessageDialogClose
                    className="absolute right-4"
                >
                    <Cross1Icon
                        className='text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors'
                    />
                </MessageDialogClose>
                <div className="w-full text-zinc-500 mb-2">
                    Manage Subscription
                </div>
                <Tabs.Root
                    value={activeTab}
                    onValueChange={(value) => {
                        setActiveTab(value as ManageSubscriptionDialogTab);
                    }}
                    className="w-full"
                >
                    <Tabs.List
                        className='w-full flex flex-row items-center gap-1 font-semibold border-b border-zinc-800 bg-zinc-900'
                        style={{
                            zIndex: 1,
                        }}
                    >
                        {getObjectEntries(ManageSubscriptionDialogTab).map(([key, tab]) => (
                            <Tabs.Trigger
                                key={key}
                                value={tab}
                                className='group py-1 text-sm hover:text-lime-500 active:text-lime-600 focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b-2 transition-colors border-transparent text-zinc-400 data-[state=active]:text-zinc-300 data-[state=active]:border-lime-600 data-[state=active]:hover:border-lime-500'
                            >
                                <div className='w-fit min-w-[5vw] lg:min-w-[5rem] px-3 py-2 rounded flex flex-row items-center justify-center gap-3 bg-transparent group-hover:text-zinc-300 group-hover:bg-zinc-800 transition-colors'>
                                    {key}
                                </div>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                    <div className="mt-4">
                        {getObjectEntries(TabToComponent).map(([key, Component]) => (
                            <Tabs.Content
                                key={key}
                                value={key}
                            >
                                <Component />
                            </Tabs.Content>
                        ))}
                    </div>
                </Tabs.Root>
                {/* <Plans /> */}
                {/* {isPro ?
                    <ManageSubscriptionDialogContentPro /> :
                    <ManageSubscriptionDialogContentFree />
                } */}
            </div>
        </TabContext.Provider>
    )
}

interface ManageSubscriptionDialogContextType {
    open: boolean;
    setOpen: (value: boolean) => void;
}

// Create a context with an undefined default value. We will never actually use this value directly;
// instead, we will always use the provider below to wrap our components, which provides an actual value.
const ManageSubscriptionDialogContext = createContext<ManageSubscriptionDialogContextType>({
    open: false,
    setOpen: () => { },
});

export function useManageSubscriptionDialogContext() {
    return React.useContext(ManageSubscriptionDialogContext);
}

// Define the props for the provider, including the children it will wrap
interface ManageSubscriptionDialogProviderProps {
    children: React.ReactNode;
}

// Create a provider component
export const ManageSubscriptionDialogProvider: React.FC<ManageSubscriptionDialogProviderProps> = ({ children }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    // The value that will be provided to the descendants of this provider
    const value = { open, setOpen };

    return (
        <ManageSubscriptionDialogContext.Provider value={value}>
            {children}
        </ManageSubscriptionDialogContext.Provider>
    );
};

export function ManageSubscriptionDialog(props: MessageDialogProps) {
    const {
        open,
        setOpen,
    } = useManageSubscriptionDialogContext();

    return (
        <MessageDialog
            {...props}
            open={open}
            onOpenChange={setOpen}
            contentChildren={<ManageSubscriptionDialogContent />}
        />
    );
}