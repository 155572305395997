import { EditorAsset, EditorAssetContentType, OnAddAssetEventHandler, PastGeneration, UserAssetInfoType } from "core/common/types";
import { getDataUrlFromBlob, getDataUrlFromString } from "core/utils/asset-utils";
import { isDataURL, isValidFirebaseStoragePath, isValidHttpsUrl } from "core/utils/string-utils";
import { isFabricObject, isFabricObjectWithAsset } from "core/utils/type-guards";
import { Base } from "./base";
import { getAssetIdFromStoragePath } from "core/utils/image-utils";
import { fabric } from "fabric";
import { Backend, UpdateUserAssetInfoArgs, UploadUserAssetInfoArgs } from "backend/base";
import { removeKeyFromObjectImmutable } from "core/utils/object-utils";
import { debugError, debugLog } from "core/utils/print-utilts";

export class Assets extends Base {

    private assetStore: Record<string, string> = {}

    private saveAssetToMemory({
        path,
        asset,
    }: {
        path: string,
        asset: string,
    }) {
        this.assetStore[path] = asset;
    }

    private deleteAssetFromMemory({
        path,
    }: {
        path: string,
    }) {
        delete this.assetStore[path];
    }

    private loadAssetFromMemory({
        path,
    }: {
        path: string,
    }) {
        return this.assetStore[path];
    }

    private saveAssetToLocal({
        path,
        asset,
    }: {
        path: string,
        asset: string,
    }) {
        return window?.localStorage.setItem(path, asset);
    }

    private loadAssetFromLocal({
        path,
    }: {
        path: string,
    }) {
        return window?.localStorage.getItem(path);
    }

    private deleteAssetFromLocal({
        path,
    }: {
        path: string,
    }) {
        return window?.localStorage.removeItem(path);
    }

    private static async getAssetDataUrlFromData(data: string | File | Blob) {
        if (!data) {
            return;
        }
        try {
            if (typeof (data) === 'string') {
                return await getDataUrlFromString(data);
            }
            if (data instanceof Blob) {
                return await getDataUrlFromBlob(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private static async loadAssetFromRemote({
        path,
        backend,
    }: {
        path: string,
        backend: Backend,
    }) {
        try {
            return await backend.getDownloadUrlFromStoragePath(path);
        } catch (error) {
            debugError(`Cannot load asset from path ${path}: `, error);
        }
        return null;
    }

    private async loadAssetFromRemote({
        path,
    }: {
        path: string,
    }) {
        const backend = this.editor.state.backend;
        if (backend) {
            return Assets.loadAssetFromRemote({
                path,
                backend,
            });
        }
        return null;
    }

    static getAssetTypeFromPath(path: string) {
        if (isDataURL(path) || isValidHttpsUrl(path)) {
            return 'image-url';
        } else if (isValidFirebaseStoragePath(path)) {
            return 'image-storage';
        }
        return undefined;
    }

    static getEditorAssetFromPath(path?: string): EditorAsset | null {
        if (!path) {
            return null;
        }

        const type = Assets.getAssetTypeFromPath(path);

        if (!type) {
            return null;
        }

        return {
            path,
            type,
        };
    }

    static async loadAssetFromPath({
        path,
        backend,
    }: {
        path: string,
        backend: Backend,
    }) {
        if (!path) {
            return null;
        }

        const type = Assets.getAssetTypeFromPath(path);

        if (type === 'image-storage') {
            return Assets.loadAssetFromRemote({
                backend,
                path,
            });
        } else if (type === 'image-url') {
            return path;
        }

        return null;
    }

    public async loadAsset({
        type,
        path,
        saveToMemory = true,
        saveToLocal = false,
    }: Partial<EditorAsset> & {
        saveToMemory?: boolean,
        saveToLocal?: boolean,
    }) {
        if (!path) {
            return undefined;
        }

        type = Assets.getAssetTypeFromPath(path) || type;

        // Get asset from backend storage
        if (type === 'image-storage') {

            let asset = undefined;

            asset = this.loadAssetFromMemory({ path });
            if (asset) {
                return asset;
            }

            asset = this.loadAssetFromLocal({ path });
            if (asset) {
                saveToMemory && this.saveAssetToMemory({
                    path,
                    asset,
                });
                return asset;
            }

            asset = await this.loadAssetFromRemote({ path });
            if (asset) {
                saveToMemory && this.saveAssetToMemory({
                    path,
                    asset,
                });
                saveToLocal && this.saveAssetToLocal({
                    path,
                    asset,
                });
                return asset;
            }

        } else if (type === 'image-url') {
            return path;
        }

        return undefined;
    }

    private async uploadAsset({
        data,
        contentType,
        assetId,
    }: {
        data: string | File | Blob,
        contentType: EditorAssetContentType,
        assetId?: string,
    }) {
        const backend = this.editor.state.backend;
        if (!backend) {
            return undefined;
        }
        let path = undefined;
        if (typeof (data) === 'string') {
            if (isDataURL(data)) {
                path = await backend.uploadDataUrlToStorage({
                    data,
                    contentType,
                    assetId,
                });
            } else if (contentType === EditorAssetContentType.json) {
                path = await backend.uploadJsonToStorage({
                    data,
                    assetId,
                });
            } else {
                debugError("Cannot upload asset because the data is not a valid data-url or json.");
            }
        } else {
            path = await backend.uploadFileToStorage({
                contentType,
                data,
            });
        }

        if (path) {
            this.editor.emit<OnAddAssetEventHandler>('assets:on-add', {
                path,
                contentType,
            });
        }
        return path;
    }

    private async saveAsset({
        path,
        data,
        saveToMemory = true,
        saveToLocal = false,
    }: {
        path: string,
        data: string | File | Blob,
        saveToMemory?: boolean,
        saveToLocal?: boolean,
    }) {
        if (saveToLocal || saveToMemory) {
            const dataUrl = await Assets.getAssetDataUrlFromData(data);
            if (!dataUrl) {
                return;
            }
            if (saveToMemory) {
                this.saveAssetToMemory({
                    path,
                    asset: dataUrl,
                });
            }
            if (saveToLocal) {
                this.saveAssetToLocal({
                    path,
                    asset: dataUrl,
                });
            }
        }
    }

    public async deleteUserImageAsset({
        assetId,
        assetType = 'images',
        storagePath,
        removeFromLocal = true,
        removeFromMemory = false,
    }: {
        assetId?: string | null,
        storagePath: string,
        assetType?: UserAssetInfoType,
        removeFromLocal?: boolean,
        removeFromMemory?: boolean,
    }) {
        const id = assetId || getAssetIdFromStoragePath(storagePath);
        if (!id) {
            return;
        }
        const {
            backend,
            setUserImageAssetInfoCollection,
        } = this.editor.state;

        setUserImageAssetInfoCollection(
            (prevCollection) => removeKeyFromObjectImmutable(prevCollection, id)
        );

        if (removeFromLocal) {
            this.deleteAssetFromLocal({
                path: storagePath,
            });
        }

        if (removeFromMemory) {
            this.deleteAssetFromMemory({
                path: storagePath,
            });
        }

        await backend?.deleteUserAssetInfo({
            assetId: id,
            assetType,
        });
    }

    public async addAsset({
        data,
        contentType,
        saveToMemory = true,
        saveToLocal = false,
        assetId,
    }: {
        data: string | File | Blob,
        contentType: EditorAssetContentType,
        saveToMemory?: boolean,
        saveToLocal?: boolean,
        assetId?: string,
    }) {
        if (!data) {
            return undefined;
        }
        const userId = this.editor.state.user?.uid;
        const backend = this.editor.state.backend;
        if (userId && backend) {
            const path = await this.uploadAsset({
                data,
                contentType,
                assetId,
            })
            if (path) {
                await this.saveAsset({
                    path,
                    data,
                    saveToMemory,
                    saveToLocal,
                });
            }
            return path;
        }
        return undefined;
    }

    getObjectAssetId(object: fabric.Object | string) {
        object = typeof (object) === 'string' ?
            this.editor.objects.findOneById(object) :
            object;

        if (isFabricObjectWithAsset(object)) {
            return getAssetIdFromStoragePath(object.asset.path);
        }

        return null;
    }

    public setObjectAsset(
        objectId: string,
        asset: EditorAsset,
    ) {
        const object = this.editor.objects.findOneById(objectId);
        if (isFabricObject(object)) {
            // @ts-ignore
            object.set('asset', asset);
            this.editor.history.save();
        } else {
            console.log(`Cannot set asset of invalid object ${objectId}`);
        }
    }

    public async setUserAssetInfo(args: UploadUserAssetInfoArgs) {
        const {
            backend,
            setUserImageAssetInfoCollection,
        } = this.state;

        if (!backend) {
            return;
        }

        const response = await backend?.addUserAssetInfo(args);

        if (!response) {
            return;
        }

        const result = response.isUpdated && response.result;

        if (!result) {
            return;
        }

        setUserImageAssetInfoCollection((prevCollection) => ({
            [result.id]: result,
            ...prevCollection,
        }));
    }

    public async updateUserAssetInfo(args: UpdateUserAssetInfoArgs) {
        const {
            backend,
            setUserImageAssetInfoCollection,
        } = this.state;

        if (!backend) {
            return;
        }

        const response = await backend.updateUserAssetInfo(args);

        if (!response) {
            return;
        }

        const result = response.isUpdated && response.result;

        if (!result) {
            return;
        }

        setUserImageAssetInfoCollection((prevCollection) => ({
            [result.id]: result,
            ...prevCollection,
        }));
    }

    public async updateObjectUserAssetInfo({
        object,
        ...args
    }: Partial<UpdateUserAssetInfoArgs> & {
        object: fabric.Object | fabric.StaticImage,
        assetType: UserAssetInfoType,
    }) {
        if (!object) {
            return;
        }
        const assetId = this.getObjectAssetId(object as any as fabric.Object);

        if (!assetId) {
            return;
        }

        return await this.updateUserAssetInfo({
            ...args,
            assetId,
        });
    }

    public async getPastGeneration(generationId?: string) {
        if (!generationId) {
            return;
        }
        const backend = this.editor.state.backend;
        const {
            pastGenerations,
            setPastGenerations,
        } = this.editor.state;
        if (!backend || !pastGenerations) {
            return;
        }
        let generation = pastGenerations[generationId];
        if (generation) {
            return generation;
        }
        generation = await backend.getPastGeneration({
            generationId,
        });
        if (generation) {
            // Cache the generation
            setPastGenerations(generations => ({
                ...generations,
                [generationId]: generation,
            }));
        }

        return generation;
    }

    public async addPastGenarations(pastGeneration: PastGeneration) {
        if (!pastGeneration) {
            return;
        }
        const backend = this.editor.state.backend;
        backend?.addPastGeneration({
            pastGeneration,
        });
        this.editor.state.setPastGenerations((pastGenerations) => {
            return {
                ...(pastGenerations ?? {}),
                [pastGeneration.id]: pastGeneration,
            };
        });
    }

    public async updatePastGeneration(pastGenerationPartial: Partial<PastGeneration> & { id: string }) {
        if (!pastGenerationPartial) {
            return;
        }
        const pastGeneration = await this.getPastGeneration(pastGenerationPartial.id);
        if (!pastGeneration) {
            return;
        }

        const newPastGeneration = {
            ...pastGeneration,
            ...pastGenerationPartial,
        };

        const backend = this.editor.state.backend;
        backend?.addPastGeneration({
            pastGeneration: newPastGeneration,
        });
        this.editor.state.setPastGenerations((pastGenerations) => {
            return {
                ...(pastGenerations ?? {}),
                [newPastGeneration.id]: newPastGeneration,
            };
        });
    }
}