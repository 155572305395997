import React from 'react';
import { DropdownClassName } from 'components/constants/class-names';
import { editorContextStore } from 'contexts/editor-context';

import { CustomModelDatasetItem } from 'core/common/types';
import { classNames } from 'core/utils/classname-utils';
import { useParams } from 'react-router-dom';
import { CreateCustomModelNavbar } from './navbar';
import { LoadingCardImage } from 'components/panels/panel-items/components/loading-card';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { Assets } from 'core/controllers/assets';

export function CustomModelDataEditor({
    item,
    src: srcInput,
}: {
    item: CustomModelDatasetItem,
    src?: string,
}) {
    const backend = editorContextStore(state => state.backend);
    const [src, setSrc] = React.useState<string>("");

    React.useEffect(() => {
        if (!backend) {
            debugError(`Cannot load src ${srcInput} because the editor is not initialized.`);
            return;
        }

        setSrc("");

        Assets.loadAssetFromPath({
            path: srcInput || item.storagePath,
            backend,
        }).then((imageUrl) => {
            if (!imageUrl) {
                return;
            }

            setSrc(imageUrl);
        });

    }, [backend, srcInput, item.storagePath]);

    return (
        <div
            className={classNames(
                DropdownClassName,
                'md:min-w-[80vw] h-[80vh] flex flex-row py-3',
            )}
        >
            <div className='relative flex-1 h-full bg-black/20 flex items-center justify-center rounded-md overflow-hidden'>
                <LoadingCardImage
                    src={src}
                    className='h-full object-contain'
                />
            </div>
        </div>
    )
}

export function CustomModelDataEditorPage() {
    const {
        modelId,
        dataId,
    } = useParams();
    const backend = editorContextStore(state => state.backend);

    const dataset = editorContextStore(state => state.customModelDataset);
    const dataItem = React.useMemo(() => dataId ? dataset?.[dataId] : undefined, [dataId, dataset]);
    const setDataset = editorContextStore(state => state.setCustomModelDataset);
    const setDataItem = React.useCallback((item?: CustomModelDatasetItem) => {
        if (!dataId) {
            return;
        }
        if (!item) {
            debugLog(`Remove model ${modelId} data item ${dataId}`);
            return setDataset((dataset) => {
                const newDataset = { ...dataset };
                if (newDataset[dataId]) {
                    delete newDataset[dataId];
                }
                return newDataset;
            });
        }
        debugLog(`Set model ${modelId} data item ${dataId}`);
        return setDataset((dataset) => ({
            ...dataset,
            [dataId]: item,
        }));
    }, [dataId, setDataset]);

    React.useEffect(() => {
        if (!backend || !modelId || !dataId) {
            return;
        }
        backend.onCustomModelDatasetItemUpdate(
            modelId,
            dataId,
            setDataItem,
        );
    }, [backend, modelId, dataId, setDataItem]);

    if (!dataItem) {
        debugLog('No valid data item found');
    }

    return (
        <div
            className='h-screen bg-zinc-900 text-zinc-100 flex flex-col items-center'
        >
            <CreateCustomModelNavbar />
            <div className='h-8' />
            <div className='w-full xl:max-w-[1280px]'>
                {dataItem && <CustomModelDataEditor
                    item={dataItem}
                />}
            </div>
        </div>
    )
}