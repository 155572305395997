import { isGenerationFrame } from 'core/utils/type-guards';
import { getNearestNumberInGrid, lerp } from 'core/utils/number-utils';
import { fabric } from "fabric"
import { Base } from "./base"
import { getCenterFromBounds } from 'core/utils/bbox-utils';
import { ViewportTransform, isViewportTransform } from 'core/common/types/viewport-transform';

enum ZoomAnimateStatus {
  Idle,
  Zooming,
}

class Zoom extends Base {

  protected status: ZoomAnimateStatus = ZoomAnimateStatus.Idle;

  static getBBoxZoomFitRatio({
    width = 0,
    height = 0,
    canvas,
    frameMargin = 0,
  }: {
    width?: number,
    height?: number,
    canvas: fabric.Canvas,
    frameMargin?: number,
  }) {
    if (!width || !height) {
      return 1;
    }
    const canvasWidth = canvas.getWidth() - frameMargin
    const canvasHeight = canvas.getHeight() - frameMargin
    let scaleX = canvasWidth / width
    let scaleY = canvasHeight / height
    if (height >= width) {
      scaleX = scaleY
      if (canvasWidth < width * scaleX) {
        scaleX = scaleX * (canvasWidth / (width * scaleX))
      }
    } else {
      if (canvasHeight < height * scaleX) {
        scaleX = scaleX * (canvasHeight / (height * scaleX))
      }
    }
    return scaleX
  }

  static getObjectZoomFitRatio(canvas: fabric.Canvas, object: fabric.Object, frameMargin = 0) {
    const width = object.getScaledWidth() ?? 0;
    const height = object.getScaledHeight() ?? 0;
    if (width <= 0 || height <= 0) {
      return 1;
    }
    const canvasWidth = canvas.getWidth() - frameMargin
    const canvasHeight = canvas.getHeight() - frameMargin
    let scaleX = canvasWidth / width
    let scaleY = canvasHeight / height
    if (height >= width) {
      scaleX = scaleY
      if (canvasWidth < width * scaleX) {
        scaleX = scaleX * (canvasWidth / (width * scaleX))
      }
    } else {
      if (canvasHeight < height * scaleX) {
        scaleX = scaleX * (canvasHeight / (height * scaleX))
      }
    }
    return scaleX
  }

  get zoomFitRatio() {
    const frame = this.editor.frame;
    if (frame) {
      return frame.fitRatio;
    }
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      return Zoom.getObjectZoomFitRatio(this.canvas, activeObject);
    }
    return 1;
  }

  get viewportTransform(): ViewportTransform {
    return (this.canvas.viewportTransform as any) || [1, 0, 0, 1, 0, 0];
  }

  protected set viewportTransform(value: ViewportTransform) {
    if (this.status === ZoomAnimateStatus.Idle) {
      this.canvas.setViewportTransform(value);
    }
  }

  setCanvasViewportTransform(value: number[]) {
    if (!isViewportTransform(value)) {
      return;
    }

    this.viewportTransform = value;
  }

  get zoomRatio() {
    return this.canvas.getZoom();
  }

  zoom(zoomRatio: number) {
    zoomRatio = Zoom.getZoomRatio(zoomRatio);
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }

  static getNearestZoomRatio(zoomRatio: number) {
    return getNearestNumberInGrid(zoomRatio, 0.05);
  }

  zoomInGrid() {
    this.zoom(
      Zoom.getNearestZoomRatio(this.canvas.getZoom() + 0.05)
    );
  }

  zoomOutGrid() {
    this.zoom(
      Zoom.getNearestZoomRatio(this.canvas.getZoom() - 0.05)
    );
  }

  zoomIn() {
    let zoomRatio = this.canvas.getZoom()
    zoomRatio += 0.05
    const center = this.canvas.getCenter()
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio)
    this.state.setZoomRatio(zoomRatio)
  }

  zoomOut() {
    let zoomRatio = this.canvas.getZoom()
    zoomRatio -= 0.05
    const center = this.canvas.getCenter()
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio)
    this.state.setZoomRatio(zoomRatio)
  }

  zoomToOne() {
    const center = this.canvas.getCenter()
    this.setCanvasViewportTransform([1, 0, 0, 1, 0, 0])
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1)
    this.state.setZoomRatio(1)
  }

  get center() {
    const center = this.canvas.getCenter();
    return {
      x: center.left,
      y: center.top,
    };
  }

  static minZoom = 25;
  static maxZoom = 200;
  static minZoomRatio = Zoom.minZoom / 100;
  static maxZoomRatio = Zoom.maxZoom / 100;

  static getZoomRatio(zoom: number) {
    let zoomRatio = zoom
    if (zoom <= Zoom.minZoomRatio) {
      zoomRatio = Zoom.minZoomRatio
    } else if (zoom >= Zoom.maxZoomRatio) {
      zoomRatio = Zoom.maxZoomRatio
    }
    return zoomRatio;
  }

  protected requestRenderAfterZoom(timeoutMs = 50) {
    setTimeout(() => {
      this.canvas?.requestRenderAll();
    }, timeoutMs);
  }

  static getViewportTransform({
    center,
    zoomFitRatio,
    canvasWidth,
    canvasHeight,
  }: {
    center: { x: number, y: number },
    zoomFitRatio: number,
    canvasWidth: number,
    canvasHeight: number,
  }): ViewportTransform {
    const zoomRatio = Zoom.getZoomRatio(zoomFitRatio);
    const viewportTransform: ViewportTransform = [1, 0, 0, 1, 0, 0];
    viewportTransform[0] = zoomRatio;
    viewportTransform[3] = zoomRatio;
    viewportTransform[4] = ((canvasWidth / zoomRatio / 2) - center.x) * zoomRatio;
    viewportTransform[5] = ((canvasHeight / zoomRatio / 2) - center.y) * zoomRatio;
    return viewportTransform;
  }

  protected zoomInternal(
    center: { x: number, y: number },
    zoomFitRatio: number,
    requestRender = true,
  ) {
    if (this.status !== ZoomAnimateStatus.Idle) {
      return;
    }

    const canvasWidth = this.canvas.getWidth();

    const canvasHeight = this.canvas.getHeight();

    const defaultTransform = Zoom.getViewportTransform({
      center,
      zoomFitRatio,
      canvasWidth,
      canvasHeight,
    });

    // const zoomRatio = Zoom.getZoomRatio(zoomFitRatio);
    // const defaultTransform = [1, 0, 0, 1, 0, 0];
    // defaultTransform[0] = zoomRatio;
    // defaultTransform[3] = zoomRatio;
    // defaultTransform[4] = ((this.canvas.getWidth() / zoomRatio / 2) - center.x) * zoomRatio;
    // defaultTransform[5] = ((this.canvas.getHeight() / zoomRatio / 2) - center.y) * zoomRatio;

    this.setCanvasViewportTransform(defaultTransform);
    this.state.setZoomRatio(zoomFitRatio);

    if (requestRender) {
      this.requestRenderAfterZoom();
    }
  }

  protected async animateZoomInternal({
    endCenter,
    endZoomRatio,
    durationMs = 500,
  }: {
    endCenter: { x: number, y: number },
    endZoomRatio: number,
    durationMs?: number,
  }) {
    if (this.status !== ZoomAnimateStatus.Idle) {
      return Promise.resolve();
    }

    const canvas = this.canvas;

    if (!canvas) {
      return Promise.resolve();
    }

    const startViewportTransform = canvas.viewportTransform;

    if (!startViewportTransform) {
      return Promise.resolve();
    }

    const canvasWidth = this.canvas.getWidth();

    const canvasHeight = this.canvas.getHeight();

    const endViewportTransform = Zoom.getViewportTransform({
      center: endCenter,
      zoomFitRatio: endZoomRatio,
      canvasWidth,
      canvasHeight,
    });

    const indicesToLerp = [0, 3, 4, 5];

    return new Promise<void>((resolve) => {
      this.status = ZoomAnimateStatus.Zooming;

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: durationMs,
        onChange: (value) => {
          try {
            const viewportTransform = [...startViewportTransform] as ViewportTransform;

            indicesToLerp.forEach((index) => {
              viewportTransform[index] = lerp(
                startViewportTransform[index],
                endViewportTransform[index],
                value,
              );
            });

            this.setCanvasViewportTransform(viewportTransform);

            canvas.requestRenderAll();
          } catch (error) {
            console.error(error);
          }
        },
        onComplete: () => {
          this.state.setZoomRatio(endZoomRatio);
          resolve();
        },
        easing: fabric.util.ease.easeOutCubic,
      });
    });
  }

  zoomToFitAll(frameMargin = 180) {

    const bounds = this.editor.objects.getBoundsOfAll();
    if (!bounds) {
      return;
    }

    const zoomFitRatio = Zoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);
    this.zoomInternal(center, zoomFitRatio);
  }

  async zoomToFit(
    object?: fabric.Object | fabric.Frame | null,
    frameMargin = 180,
    extraArgs: {
      animate?: boolean,
      durationMs?: number,
      fixZoom?: boolean,
    } = {},
  ) {
    object = object || this.editor.frame?.frame || this.canvas.getActiveObject();

    if (!object) {
      return this.zoomToFitAll();
    }

    const bounds = {
      left: object.left,
      top: object.top,
      width: (object.width ?? 0) * (isGenerationFrame(object) ? 2 : 1),
      height: object.height,
    }

    const zoomFitRatio = Zoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);


    if (extraArgs.animate) {
      await this.animateZoomInternal({
        endCenter: center,
        endZoomRatio: extraArgs.fixZoom ? this.zoomRatio : zoomFitRatio,
        durationMs: extraArgs.durationMs || 500,
      });
    } else {
      this.zoomInternal(center, zoomFitRatio);
    }


    // const frameMargin = object?.type === 'Frame' ? (this.editor.frame?.frameMargin || 0) : 180;
    // const zoomFitRatio = object ? Zoom.getObjectZoomFitRatio(this.canvas, object, frameMargin) : 1;
    // const center = object?.getCenterPoint() || this.getCanvasCenterPoint();
    // this.zoomInternal(center, zoomFitRatio);
  }

  zoomToRatio(zoomRatio: number) {
    const center = this.canvas.getCenter()
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio)
    this.state.setZoomRatio(zoomRatio)
  }

  zoomToPoint(point: fabric.Point, zoom: number) {
    const zoomRatio = Zoom.getZoomRatio(zoom);
    this.canvas.zoomToPoint(point, zoomRatio);
    this.state.setZoomRatio(zoomRatio);

    this.requestRenderAfterZoom();
  }
}

export default Zoom
