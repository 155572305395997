import React from "react";
import Objects from "core/controllers/objects";
import { LayerType } from "core/common/layers";
import { UserProjectType, UsedUserProjectType } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { EditorActiveObject } from "core/common/interfaces";
import { LeftPanelItemType } from "components/constants/editor-options";
import { isStaticImageObjectColor, isStaticImageObjectHed, isStaticImageObjectUploaded } from "core/utils/type-guards";

function setActiveObjectLeftPanel(
    activeObject: EditorActiveObject,
) {
    if (!activeObject) {
        return;
    }
    const {
        setActiveLeftPanels,
    } = editorContextStore.getState();

    if ((activeObject as fabric.Object).type === LayerType.GENERATION_FRAME) {
        setActiveLeftPanels(panels => {
            if (!panels.includes('Generate')) {
                return ['Generate'];
            }
            return panels;
        });
    } else if (Objects.isMultiSelectableObjects(activeObject as fabric.Object)) {

        if (isStaticImageObjectHed(activeObject)) {
            // setActiveLeftPanels(['Edit']);
            return;
        }

        if (isStaticImageObjectColor(activeObject)) {
            return;
        }

        if (isStaticImageObjectUploaded(activeObject)) {
            return;
        }

        setActiveLeftPanels(['Edit']);
    }
}

const defaultProjectActiveLeftPanel: Record<UsedUserProjectType, LeftPanelItemType> = {
    [UserProjectType.ProductPhotography]: 'Assets',
    [UserProjectType.TryOn]: 'Assets',
    [UserProjectType.Fashion]: 'Assets',
    [UserProjectType.Accessories]: 'Assets',
    [UserProjectType.Beauty]: 'Assets',
    [UserProjectType.CPG]: 'Assets',
    [UserProjectType.Food]: 'Assets',
    [UserProjectType.Furniture]: 'Assets',
    [UserProjectType.Homegoods]: 'Assets',
    [UserProjectType.Staging]: 'Assets',
    [UserProjectType.Humans]: 'Assets',
}

export function useActiveObjectLeftPanel() {
    const activeObject = editorContextStore(state => state.activeObject);
    const prevActiveObjectRef = React.useRef<EditorActiveObject>();

    const projectType = editorContextStore(state => state.projectType);
    const defaultActiveLeftPanel = React.useMemo(() => {
      if (projectType && projectType in defaultProjectActiveLeftPanel) {
        return defaultProjectActiveLeftPanel[projectType as UsedUserProjectType];
      }
      return 'Assets';
    }, [projectType]);

    React.useEffect(() => {
        const {
            isSelectingTag,
            setActiveLeftPanels,
            skipActiveObjectLeftPanelUpdate,
        } = editorContextStore.getState();

        if (skipActiveObjectLeftPanelUpdate.current) {
            skipActiveObjectLeftPanelUpdate.current = false;
            return;
        }

        if (activeObject !== prevActiveObjectRef.current) {
            if (activeObject && !isSelectingTag) {

                setActiveObjectLeftPanel(activeObject);

            } else if (activeObject == null && prevActiveObjectRef.current != null) {

                // Deselect an object
                setActiveLeftPanels(panels => {
                    if (panels.includes('Edit')) {
                        return [
                            defaultActiveLeftPanel
                        ];
                    }
                    return panels;
                });

            }
            prevActiveObjectRef.current = activeObject;
        }
    }, [activeObject, defaultActiveLeftPanel]);
}

