import { fabric } from "fabric"

export function initFabricFilterBackend(forceInit = false) {
    try {
        if (!forceInit && fabric.filterBackend) {
            return;
        }
        fabric.filterBackend = (fabric as any).initFilterBackend?.();
    } catch (error) {
        console.error(error);
    }
}

export function replaceFirstFilter(
    object: {
        filters?: fabric.IBaseFilter[],
        applyFilters: () => void,
    },
    filter: fabric.IBaseFilter,
    applyFilters = true,
) {
    if (object.filters) {
        object.filters = [filter, ...object.filters.slice(1)];
    } else {
        object.filters = [filter];
    }
    if (applyFilters) {
        object.applyFilters();
    }
}

export function prependFilter(
    object: {
        filters?: fabric.IBaseFilter[],
        applyFilters: () => void,
    },
    filter: fabric.IBaseFilter,
    applyFilters = true,
) {
    object.filters = [filter, ...(object.filters ?? [])];
    if (applyFilters) {
        object.applyFilters();
    }
}

export function removeFirstFilter(
    object: {
        filters?: fabric.IBaseFilter[],
        applyFilters: () => void,
    },
    applyFilters = true,
) {
    if (!object.filters || object.filters.length <= 0) {
        return;
    }
    const [filter, ...rest] = object.filters;
    object.filters = rest;
    if (applyFilters) {
        object.applyFilters();
    }
    return filter;
}

export function pushFilter(
    object: {
        filters?: fabric.IBaseFilter[],
        applyFilters: () => void,
    },
    filter: fabric.IBaseFilter,
    applyFilters = true,
) {
    if (object.filters) {
        object.filters.push(filter);
    } else {
        object.filters = [filter];
    }
    if (applyFilters) {
        object.applyFilters();
    }
}

export function popFilter(
    object: {
        filters?: fabric.IBaseFilter[],
        applyFilters: () => void,
    },
    applyFilters = true,
) {
    if (!object.filters) {
        return;
    }
    const filter = object.filters.pop();
    if (applyFilters) {
        object.applyFilters();
    }
    return filter;
}

export function cloneFilters(
    source: fabric.Object,
    target: fabric.Object,
) {
    const filters = (source as any)?.filters;
    if (!filters || !Array.isArray(filters) || typeof((target as any)?.applyFilters) !== 'function') {
        return;
    }
    const newFilters = filters.map((filter) => {
        return filter.constructor.fromObject(filter.toObject());
    });
    (target as any).filters = newFilters;
    (target as any).applyFilters();
}