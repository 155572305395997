import React from "react";
import { PrimaryButtonClassName, SecondaryButtonClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names"
import { classNames } from "core/utils/classname-utils"
import { Download, Image, LucideProps } from "lucide-react"
import { LeftPanelSectionContainer } from "../base"
import { LeftPanelTitle } from "../components/left-panel-title"
import { LeftPanelBarDividerSmall } from "../components/divider"
import { editorContextStore } from "contexts/editor-context"
import { EditArrange } from "./edit-arrange"
import { downloadImageDataUrl, getStaticImageFileStem } from "components/utils/data"
import { isStaticImageObject, isStaticImageObjectHed } from "core/utils/type-guards"
import { EditImageProcessType, SetObjectEditImageProgressControllerEventHandler, TriggerRemoveBackgroundEventHandler, UpdateObjectPropsEventHandler } from "core/common/types"
import { EditorActiveObject } from "core/common/interfaces"
import { createObjectEditImageProgressController, EditImageProgressController } from "./edit-image-process"
import { useActiveObjectPastGeneration } from "hooks/use-past-generation"
import { fabric } from 'fabric';
import { ColorFilterMode, ColorFilterModeDropdown, ColorPicker } from "./edit-color"
import { Editor } from "core/editor"
import { debounce } from "lodash"
import { Tooltip } from "components/utils/tooltip"
import { isUserSubscriptionTierFree } from "core/utils/quota-utils"
import { DownloadImageGroup } from "components/utils/download-image-button"
import { SelectOptionIcon, SelectOptionValue, SelectOptions } from "components/utils/select-options"
import { StaticImageElement2dType, StaticImageElementType, getStaticImageElement2dType, getStaticImageElementType } from "core/common/types/elements"
import { DropdownImageElement2dTypeValue, imageElement2dTypeDropdownOptions, setDropdownImageElement2dType } from "../components/element-type"
import { PANELS_LIST_ICONS } from "components/panels/panels-list-icons"
import { useManageSubscriptionDialogContext } from "components/popup/message-dialog/manage-subscription-dialog";
import { AnalyticsConfig } from 'analytics/config';


type TextButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
    buttonTitle: React.ReactNode,
    buttonDescription: React.ReactNode,
}

const TextButton = React.forwardRef(({
    buttonTitle: title,
    buttonDescription: description,
    className,
    ...props
}: TextButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
    return (
        <button
            ref={forwardedRef}
            className={classNames(
                SecondaryButtonClassNameInactive,
                'flex flex-col',
                className ?? '',
            )}
            {...props}
        >
            <div
                className="w-full text-start"
            >
                {title}
            </div>
            <div
                className="w-full text-start text-zinc-500 mt-2"
            >
                {description}
            </div>
        </button>
    );
})

type TextButtonWithTooltipProps = TextButtonProps & {
    tooltipContent?: React.ReactNode,
}

function TextButtonWithTooltip({
    tooltipContent,
    ...props
}: TextButtonWithTooltipProps) {

    if (!tooltipContent) {
        return (
            <TextButton
                {...props}
            />
        );
    }

    return (
        <Tooltip
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={(
                <TextButton
                    {...props}
                />
            )}
            contentProps={{
                side: 'right',
                align: 'start',
                sideOffset: 2,
            }}
            contentChildren={tooltipContent}
        />
    )
}

function TextButtonProcessing({
    buttonTitle: title,
    buttonDescription: description,
    className,
    progress,
    onStop,
}: TextButtonProps & {
    progress: number,
    onStop?: () => void,
}) {
    return (
        <div
            className={classNames(
                SecondaryButtonClassName,
                className ?? '',
                'relative flex flex-col overflow-hidden',
            )}
            onClick={() => onStop?.()}
        >
            <div
                className="w-full text-start z-10"
            >
                {title}
            </div>
            <div
                className="w-full text-start text-zinc-500 mt-2 z-10"
            >
                {description}
            </div>
            <div
                className="absolute left-0 top-0 z-0 bg-lime-500/20 h-full"
                style={{
                    width: `${progress}%`,
                    transitionProperty: 'width',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDuration: '150ms',
                }}
            ></div>
        </div>
    )
}

const TextButtonDisabled = React.forwardRef(({
    buttonTitle: title,
    buttonDescription: description,
    className,
    ...props
}: TextButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
    return (
        <button
            ref={forwardedRef}
            className={classNames(
                SecondaryButtonClassNameDisabled,
                'flex flex-col',
                className ?? '',
            )}
            {...props}
        >
            <div
                className="w-full text-start"
            >
                {title}
            </div>
            <div
                className="w-full text-start text-zinc-500 mt-2"
            >
                {description}
            </div>
        </button>
    );
})

function TextButtonDisabledWithTooltip({
    tooltipContent,
    ...props
}: TextButtonWithTooltipProps) {
    if (!tooltipContent) {
        return (
            <TextButtonDisabled
                {...props}
            />
        )
    }

    return (
        <Tooltip
            triggerProps={{
                asChild: true,
            }}
            triggerChildren={(
                <TextButtonDisabled
                    {...props}
                />
            )}
            contentProps={{
                side: 'right',
                align: 'start',
                sideOffset: 2,
            }}
            contentChildren={tooltipContent}
        />
    );
}


function TextButtonProgress({
    disabled = false,
    imageProcessType: type,
    editImageProcessController,
    tooltipContent,
    onClick,
    ...props
}: TextButtonWithTooltipProps & {
    disabled?: boolean,
    imageProcessType: EditImageProcessType,
    editImageProcessController?: EditImageProgressController,
    isProcessing?: boolean,
}) {
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const activeType = editImageProcessController?.type;

    React.useEffect(() => {
        if (editImageProcessController?.isDestroyed === false) {
            setIsProcessing(true);
            return editImageProcessController.subscribeToProgress(
                setProgress,
                () => {
                    setProgress(1);
                },
                () => {
                    setIsProcessing(false);
                }
            );
        } else {
            setIsProcessing(false);
        }
    }, [editImageProcessController]);

    if (disabled) {
        return (
            <TextButtonDisabledWithTooltip
                {...props}
            />
        );
    }

    if (isProcessing) {

        if (type !== activeType) {
            return (
                <TextButtonDisabledWithTooltip
                    {...props}
                />
            );
        }

        return (
            <TextButtonProcessing
                {...props}
                progress={progress * 100}
                onStop={() => {
                    console.log('Stop edit image process and revert to normal');
                    editImageProcessController?.destroy();
                    setIsProcessing(false);
                }}
            />
        )
    }

    return (
        <TextButtonWithTooltip
            {...props}
            tooltipContent={tooltipContent}
            onClick={onClick}
        />
    )
}

type ObjectWithProgress = EditorActiveObject & {
    editImageProgressController?: EditImageProgressController;
}

function ImageIcon({
    staticImageObjectType,
    ...props
}: LucideProps & {
    staticImageObjectType: StaticImageElement2dType | undefined,
}) {
    let Icon = Image;

    if (staticImageObjectType === StaticImageElement2dType.Asset) {

        Icon = PANELS_LIST_ICONS['Assets'];

    } else {

        Icon = PANELS_LIST_ICONS['Elements'];

    }

    return (
        <Icon
            {...props}
        />
    );
}

function ImageInfo({
    imageFileStem,
}: {
    imageFileStem: string,
}) {
    const editor = editorContextStore(state => state.editor);

    const [staticImageObjectType, setStaticImageObjectType] = React.useState<StaticImageElement2dType | undefined>();

    const activeObject = editorContextStore(state => state.activeObject);

    const ignoreUpdatePropEventRef = React.useRef(false);

    React.useEffect(() => {

        if (isStaticImageObject(activeObject)) {

            setStaticImageObjectType(
                getStaticImageElement2dType(
                    activeObject
                ),
            );

        } else {

            setStaticImageObjectType(undefined);

        }


    }, [activeObject]);

    React.useEffect(() => {
        const handleUpdateObjectPropsEvent: UpdateObjectPropsEventHandler['handler'] = ({ objectId }) => {

            if (ignoreUpdatePropEventRef.current) {

                ignoreUpdatePropEventRef.current = false;

                return;
            }

            const { activeObject } = editorContextStore.getState();

            if (!activeObject || objectId !== activeObject?.id) {
                return;
            }

            setStaticImageObjectType(
                getStaticImageElement2dType(
                    activeObject,
                )
            );
        };

        editor?.on<UpdateObjectPropsEventHandler>(
            'object:update-props',
            handleUpdateObjectPropsEvent,
        );

        return () => {
            editor?.off<UpdateObjectPropsEventHandler>(
                'object:update-props',
                handleUpdateObjectPropsEvent,
            );
        };
    }, [editor]);

    return (
        <div
            className="group w-full flex flex-row justify-center items-center mb-2"
        >
            <ImageIcon
                size={18}
                className="mr-2 text-zinc-500 group-hover:text-zinc-300 transition-colors"
                staticImageObjectType={staticImageObjectType}
            />
            <SelectOptions<StaticImageElement2dType | undefined>
                value={staticImageObjectType}
                onValueChange={(value) => {
                    const {
                        editor,
                        activeObject,
                    } = editorContextStore.getState();

                    if (!activeObject || !editor) {
                        return;
                    }

                    const type = value as any as StaticImageElement2dType;

                    ignoreUpdatePropEventRef.current = true;

                    setDropdownImageElement2dType(
                        editor,
                        activeObject,
                        type,
                    );

                    setStaticImageObjectType(type);
                }}
                options={imageElement2dTypeDropdownOptions}
                triggerProps={{
                    className: "flex-1 flex flex-row items-center gap-1 group-hover:text-zinc-100 transition-colors",
                }}
                triggerChildren={(
                    <>
                        <DropdownImageElement2dTypeValue
                            staticImageObjectType={staticImageObjectType}
                        />
                        <SelectOptionIcon
                            className="text-xs"
                        />
                    </>
                )}
            />
            <button
                className={SecondaryButtonClassNameInactive}
            >
                Replace
            </button>
        </div>
    )
}

function ImagePastGeneration() {
    const generation = useActiveObjectPastGeneration();

    if (!generation) {
        return null;
    }
    return (
        <div
            className="w-full mb-2 px-3 py-2 border border-zinc-800 bg-zinc-800/50 rounded-md"
        >
            {generation.prompt}
        </div>
    )
}

type SetObjectEditImageType = 'remove-background' | 'upscale' | 'upscale-premium';

function setObjectEditImageProgressController({
    type,
    object,
}: {
    type: SetObjectEditImageType,
    object: fabric.Object | EditorActiveObject,
}) {
    if (!isStaticImageObject(object)) {
        return;
    }

    return createObjectEditImageProgressController({
        type,
        object,
    });
}

function isEditableImageObject(object: EditorActiveObject) {
    if (!object) {
        return false;
    }
    return isStaticImageObject(object);
}

function TagButtonTitle({
    children,
    disabled = false,
    tagChildren,
    tagClassName = "",
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean,
    tagChildren?: React.ReactNode,
    tagClassName?: string,
}) {
    return (
        <div
            className="w-full flex flex-row items-center gap-2 transition-colors"
            {...props}
        >
            <div
                className={classNames(
                    "truncate",
                )}
            >
                {children}
            </div>
            <div
                className={classNames(
                    "px-1 rounded select-none",
                    !disabled ? "bg-lime-900/50 text-lime-500" : "bg-lime-900/30 text-lime-600",
                    tagClassName,
                )}
            >
                {tagChildren}
            </div>
        </div>
    );
}

function ProButtonTitle({
    children,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean,
}) {
    return (
        <TagButtonTitle
            {...props}
            tagChildren="Pro"
        >
            {children}
        </TagButtonTitle>
    );
}

function LeftPanelEditImageTools() {
    const editor = editorContextStore(state => state.editor);
    const activeObject = editorContextStore(state => state.activeObject);
    const userQuotas = editorContextStore(state => state.userQuotas);

    const isFreeTier = React.useMemo(() => isUserSubscriptionTierFree(userQuotas), [userQuotas]);

    const [editImageController, setEditImageController] = React.useState<EditImageProgressController | undefined>();

    const actveObjectEditImageController = (activeObject as ObjectWithProgress).editImageProgressController;

    const { setOpen: setOpenSubscriptionDialog } = useManageSubscriptionDialogContext();

    React.useEffect(() => {
        setEditImageController(actveObjectEditImageController);
    }, [actveObjectEditImageController]);

    const startEditImageProcess = React.useCallback((
        type: SetObjectEditImageType,
    ) => {
        setEditImageController(setObjectEditImageProgressController({
            type,
            object: activeObject,
        }));
    }, [activeObject]);

    React.useEffect(() => {
        if (!editor || !activeObject) {
            return;
        }

        const handleSetController = ({
            object
        }: {
            object: fabric.StaticImage,
        }) => {
            if (!object || object.id !== activeObject.id || object.type !== activeObject.type) {
                return;
            }
            const controller = (object as any as ObjectWithProgress).editImageProgressController;
            if (!controller) {
                return;
            }
            setEditImageController(controller);
        }

        editor.on<SetObjectEditImageProgressControllerEventHandler>(
            'object:set-edit-image-progress-controller',
            handleSetController,
        );
        return () => {
            editor.off<SetObjectEditImageProgressControllerEventHandler>(
                'object:set-edit-image-progress-controller',
                handleSetController,
            );
        }
    }, [editor, activeObject]);

    React.useEffect(() => {
        if (!editor) {
            return;
        }
        const removeBackgroundHandler = () => startEditImageProcess('remove-background');
        editor.on<TriggerRemoveBackgroundEventHandler>(
            'remove-background:trigger-start',
            removeBackgroundHandler,
        );
        return () => {
            editor.off<TriggerRemoveBackgroundEventHandler>(
                'remove-background:trigger-start',
                removeBackgroundHandler,
            );
        }
    }, [editor, startEditImageProcess]);

    return (
        <LeftPanelSectionContainer
            label="Tools"
        >
            <TextButtonProgress
                imageProcessType="remove-background"
                editImageProcessController={editImageController}
                buttonTitle="Remove Background"
                buttonDescription="Remove the background of your image in one click."
                onClick={() => {
                    startEditImageProcess(
                        'remove-background',
                    );
                }}
            />
            <LeftPanelBarDividerSmall />
            <TextButtonProgress
                imageProcessType="magic-erase"
                editImageProcessController={editImageController}
                buttonTitle={(
                    <TagButtonTitle
                        tagChildren="v2"
                    >
                        Magic Erase
                    </TagButtonTitle>
                )}
                buttonDescription="Paint over objects to erase them from the image."
                onClick={() => {
                    editorContextStore.getState().setActiveLeftPanels((panels) => {
                        return [
                            ...(panels || []),
                            'MagicErase',
                        ]
                    });
                    editorContextStore.getState().analytics.track(
                        AnalyticsConfig.EditImageToolButtonInteraction,
                        {
                            isFreeTier,
                            interactionTarget: 'Magic Erase Button'
                        }
                    );
                }}
            />
            <LeftPanelBarDividerSmall />
            <TextButtonProgress
                imageProcessType="upscale"
                editImageProcessController={editImageController}
                buttonTitle={(
                    <ProButtonTitle disabled={isFreeTier}>
                        Upscale
                    </ProButtonTitle>
                )}
                buttonDescription="Fast upscaler for standard use."
                tooltipContent={(
                    <div className="flex flex-col items-start gap-2 min-w-[300px]">
                        <span>
                            Max resolution: 2048 x 2048.
                        </span>
                        <span>
                            Estimated processing time: 3 seconds.
                        </span>
                        <span className="text-zinc-500">
                            Upscale is only available to Pro users.
                        </span>
                    </div>
                )}
                onClick={() => {
                    isFreeTier ?
                        setOpenSubscriptionDialog(true) :
                        startEditImageProcess(
                            'upscale',
                        );
                    editorContextStore.getState().analytics.track(
                        AnalyticsConfig.EditImageToolButtonInteraction,
                        {
                            isFreeTier,
                            interactionTarget: 'Upscale Button'
                        }
                    );
                }}
            />
            < LeftPanelBarDividerSmall />
            <TextButtonProgress
                imageProcessType="upscale-premium"
                editImageProcessController={editImageController}
                buttonTitle={(
                    <ProButtonTitle>
                        Upscale Creative
                    </ProButtonTitle>
                )}
                buttonDescription="Premium upscaler for professional use."
                tooltipContent={(
                    <div className="flex flex-col items-start gap-2 min-w-[300px]">
                        <span>
                            Max resolution: 2048 x 2048.
                        </span>
                        <span>
                            Estimated processing time: 90 seconds.
                        </span>
                        <span className="text-zinc-500">
                            Upscale premium is only available to Pro users.
                        </span>
                    </div>
                )}
                onClick={() => {
                    editorContextStore.getState().setActiveLeftPanels((panels) => {
                        return [
                            ...(panels || []),
                            'UpscaleV2',
                        ]
                    });
                }}
            />
            <LeftPanelBarDividerSmall />
            <TextButtonProgress
                imageProcessType="image-variations"
                editImageProcessController={editImageController}
                buttonTitle={(
                    <TagButtonTitle
                        tagChildren="v1"
                    >
                        Image Variations
                    </TagButtonTitle>
                )}
                buttonDescription="Create similar images."
                onClick={() => {
                    editorContextStore.getState().setActiveLeftPanels((panels) => {
                        return [
                            ...(panels || []),
                            'ImageVariations',
                        ]
                    });
                    editorContextStore.getState().analytics.track(
                        AnalyticsConfig.EditImageToolButtonInteraction,
                        {
                            isFreeTier,
                            interactionTarget: 'Image Variations Button'
                        }
                    );
                }}
            />
        </LeftPanelSectionContainer >
    );
}

function removeObjectFilter(
    object: fabric.StaticImage,
    editor?: Editor | null,
) {
    if (!object?.filters || object.filters.length <= 0) {
        return;
    }
    object.filters = [];
    object.applyFilters();
    if (editor) {
        editor.canvas.requestRenderAll();
    }
}

function getEditorSaveStateDebounce() {
    return debounce((editor: Editor) => {
        console.log('Save object filter');
    }, 1000);
}

const editorSaveStateDebounce = getEditorSaveStateDebounce();

function setObjectBlendColorFilter(
    object: any,
    color: string,
    mode: string,
    alpha: number = 1.0,
    editor?: Editor | null,
) {
    if (!isStaticImageObject(object)) {
        return;
    }
    if (mode.toLowerCase() === 'none') {
        return removeObjectFilter(object, editor);
    }
    if (!object.filters || object.filters.length <= 0) {
        const filter = new fabric.Image.filters.BlendColor({
            color,
            mode,
            alpha,
        });
        object.filters = [filter];
    } else {
        (object.filters[0] as fabric.IBlendColorFilter).color = color;
        (object.filters[0] as fabric.IBlendColorFilter).mode = mode;
        (object.filters[0] as fabric.IBlendColorFilter).alpha = alpha;
    }
    object.applyFilters();
    if (editor) {
        editor.canvas.requestRenderAll();
        editorSaveStateDebounce(editor);
    }
}

export function getObjectBlendColorFilter(
    object: EditorActiveObject,
) {
    if (!isStaticImageObject(object) || !object.filters || object.filters.length <= 0) {
        return {
            color: '#000000',
            mode: 'none',
            alpha: 1.0,
        };
    }
    const filter = object.filters[0] as fabric.IBlendColorFilter;
    return {
        color: filter.color || '#000000',
        mode: filter.mode || 'none',
        alpha: filter.alpha || 1.0,
    };
}

function ObjectColorFilterOptions({
    editor,
    activeObject,
}: {
    editor: Editor | null,
    activeObject: EditorActiveObject,
}) {
    const [color, setColor] = React.useState('#ffffff');
    const [colorMode, setColorMode] = React.useState<ColorFilterMode>('none');
    const [filterAlpha, setFilterAlpha] = React.useState(1);

    React.useEffect(() => {
        const {
            color,
            mode,
            alpha,
        } = getObjectBlendColorFilter(activeObject);
        setColor(color);
        setColorMode(mode as ColorFilterMode);
        setFilterAlpha(alpha);
    }, [activeObject]);

    return (
        <LeftPanelSectionContainer label="Color">
            <div className="w-full flex flex-row items-center">
                <ColorFilterModeDropdown
                    value={colorMode}
                    setValue={(mode) => {
                        setColorMode(mode);
                        if (mode === 'none') {
                            setColor('#000000');
                        }
                        setObjectBlendColorFilter(
                            activeObject,
                            color,
                            mode,
                            filterAlpha,
                            editor,
                        );
                    }}
                />
                <div className="flex-1" />
                <ColorPicker
                    disabled={colorMode === 'none'}
                    colorHex={color}
                    setColorHex={(color) => {
                        if (colorMode === 'none') {
                            setColorMode('add');
                        }
                        setColor(color);
                        setObjectBlendColorFilter(
                            activeObject,
                            color,
                            colorMode,
                            filterAlpha,
                            editor,
                        );
                    }}
                />
            </div>
        </LeftPanelSectionContainer>
    )
}

export function EditImagePanel() {
    const editor = editorContextStore(state => state.editor);
    const activeObject = editorContextStore(state => state.activeObject);

    if (!isStaticImageObject(activeObject)) {
        return null;
    }

    return (
        <div
            className="flex flex-col"
        >
            <LeftPanelTitle>
                Edit Image
            </LeftPanelTitle>
            <ImagePastGeneration />

            {isEditableImageObject(activeObject) && (
                <>
                    <LeftPanelEditImageTools />
                    <div className="h-4" />
                </>
            )}
            <div className="h-2" />
            <ObjectColorFilterOptions
                editor={editor}
                activeObject={activeObject}
            />
            <div className="h-2" />
            <LeftPanelSectionContainer label="Download">
                {isStaticImageObject(activeObject) && <DownloadImageGroup
                    imageObject={activeObject}
                />}
            </LeftPanelSectionContainer>
            <div className="h-32" />
        </div>
    )
}