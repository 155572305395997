import React from "react"
import { fabric } from "fabric"
import { editorContextStore } from "contexts/editor-context";
import { Editor } from "core/editor";
import { emptyGenerateTemplate } from "components/constants/default-generate-template";
import { removeLastFromImmutableList } from "core/utils/array-utils";
import { isStaticImageObject, isStaticImageObjectGenerated } from "core/utils/type-guards";
import { cloneDeep } from "lodash";
import { EditorActiveObject, emptyPromptState } from "core/common/interfaces";
import { RENDER_CANVAS_LEGNTH } from "core/common/constants";
import { addRenderImageResultToCanvas, onRenderImageResultAdded } from "components/utils/render";
import { getPromptFromTemplate, getSubjectFromPromptTemplate, setPromptTemplateSubject } from "core/common/prompt-template";
import { GenerateStrength, RegenerateProductResults } from "core/common/types";
import { debugError, debugLog } from "core/utils/print-utilts";

export function getEditingObject({
    editor,
    editingObjectId,
}: {
    editor: Editor | null,
    editingObjectId?: string,
}) {
    return editingObjectId ?
        editor?.objects.findOneById(editingObjectId) as (fabric.Object | undefined) :
        undefined;
}

export function useEditingObject(editor: Editor | null) {
    const editingObjectId = editorContextStore(state => state.editingObjectId);

    return React.useMemo(() => getEditingObject({
        editor,
        editingObjectId,
    }), [editor, editingObjectId]);
}

export function resetRegenerateProductState() {
    if (process.env.NODE_ENV === 'development') {
        console.log("Reset regenerate product state");
    }


    const {
        setRegenerateProductPromptTemplate,
        setRegenerateProductRenderState,
        setRegenerateProductEraseMaskImagePath,
        setRegenerateProductErasedImagePath,
        setRegenerateProductInputImagePath,
        setRegenerateProductReferenceImagePath,
        setRegenerateRenderProcessController,
        setReplaceProductInputImagePath,
        setReplaceProductCanvasState,
        setRegenerateProductResults,
        setRegenerateProductColorStrength,
    } = editorContextStore.getState();

    setRegenerateProductRenderState('idle');
    setRegenerateProductPromptTemplate(cloneDeep(emptyGenerateTemplate.prompt));
    setRegenerateProductEraseMaskImagePath(undefined);
    setRegenerateProductErasedImagePath(undefined);
    setRegenerateProductInputImagePath(undefined);
    setRegenerateProductReferenceImagePath(undefined);
    setRegenerateRenderProcessController(undefined);
    setReplaceProductInputImagePath(undefined);
    setReplaceProductCanvasState(undefined);
    setRegenerateProductResults([]);
    setRegenerateProductColorStrength(GenerateStrength.Default);
}

export function useRegenerateProductChangeEffect({
    editor,
    editingObject,
}: {
    editor: Editor | null,
    editingObject?: fabric.Object,
}) {
    React.useEffect(() => {

        if (!editor || !isStaticImageObjectGenerated(editingObject)) {
            resetRegenerateProductState();
            return;
        }

        const generateionId = editingObject.generationId;

        editor.assets.getPastGeneration(generateionId).then((
            pastGeneration,
        ) => {
            if (!pastGeneration) {
                resetRegenerateProductState();
                return;
            }

            const {
                promptTemplate,
                inputImagePath,
                regenerateMaskImagePath,
                regenerateErasedImagePath,
            } = pastGeneration;

            if (!promptTemplate) {
                resetRegenerateProductState();
                return;
            }

            const {
                setRegenerateProductPromptTemplate,
                setRegenerateProductInputImagePath,
                setRegenerateProductErasedImagePath,
                setRegenerateProductEraseMaskImagePath,
                setRegenerateProductReferenceImagePath,
            } = editorContextStore.getState();

            setRegenerateProductPromptTemplate((prevTemplate) => {
                if (!prevTemplate || prevTemplate.words.length <= 0) {
                    return cloneDeep(promptTemplate);
                }

                const subject = getSubjectFromPromptTemplate(prevTemplate);

                if (!subject) {
                    return cloneDeep(promptTemplate);
                }

                return setPromptTemplateSubject(promptTemplate, subject);
            });

            setRegenerateProductInputImagePath((prevPath) => prevPath || inputImagePath);

            if (regenerateMaskImagePath) {
                setRegenerateProductEraseMaskImagePath((prevPath) => prevPath || regenerateMaskImagePath);
            }

            if (regenerateErasedImagePath) {
                setRegenerateProductErasedImagePath((prevPath) => prevPath || regenerateErasedImagePath);
            }

            console.log(`Load regenerate product reference image from object ${editingObject.id}`);

            setRegenerateProductReferenceImagePath((prevPath) => prevPath || editingObject.getSrc());
        });

    }, [
        editor,
        editingObject,
    ]);
}

export async function handleExitRegenrateProduct({
    editor,
    editingObject,
    addImages = false,
    generatedResults,
}: {
    editor?: Editor | null,
    editingObject?: EditorActiveObject,
    addImages?: boolean,
    generatedResults?: RegenerateProductResults,
}) {
    if (!editor) {
        debugError('Editor is invalid.');
        return;
    }

    const {
        setEditingObjectId,
        setActiveLeftPanels,
        regenerateProductPromptTemplate,
    } = editor.state;

    const regenerateProductResults = generatedResults || editor.state.regenerateProductResults;

    setEditingObjectId(undefined);

    resetRegenerateProductState();

    setActiveLeftPanels((prevLeftPanels) => {
        return removeLastFromImmutableList(prevLeftPanels, 'Assets')
    });

    if (addImages && editor && isStaticImageObject(editingObject)) {
        const width = editingObject.width || RENDER_CANVAS_LEGNTH;
        const height = editingObject.height || RENDER_CANVAS_LEGNTH;
        const startLocation = editingObject.getCenterPoint().add(new fabric.Point(width, 0));

        const generationId = editingObject.generationId;

        const pastGeneration = generationId ? await editor.assets.getPastGeneration(generationId) : undefined;

        const {
            inputImagePath,
            inputMaskImagePath,
            sceneJsonPath,
            regenerateMaskImagePath,
            regenerateErasedImagePath,
        } = pastGeneration ?? {};

        const selectedResults = regenerateProductResults.filter(({
            imageUrl,
            isSelected,
        }) => imageUrl && isSelected);

        debugLog(`Add ${selectedResults.length} objects to the canvas`);

        await Promise.all(selectedResults.map(async ({
            imageUrl
        }, index) => {

            try {

                const renderImageObject = await addRenderImageResultToCanvas({
                    imageUrl,
                    index,
                    width,
                    height,
                    startLocation,
                });

                if (!renderImageObject) {
                    debugError(`Cannot add render image object ${index} to canvas.`);
                    return;
                }

                await onRenderImageResultAdded({
                    outputImage: renderImageObject,
                    prompt: getPromptFromTemplate(regenerateProductPromptTemplate),
                    promptTemplate: regenerateProductPromptTemplate,
                    inputImagePath,
                    inputMaskImagePath,
                    sceneJSONPath: sceneJsonPath,
                    regenerateMaskImagePath,
                    regenerateErasedImagePath,
                });

            } catch (error) {

                debugError(error);

            }
        }));

        editorContextStore.getState().setRegenerateProductResults([]);

    } else {
        editorContextStore.getState().setRegenerateProductResults([]);

        debugError({
            addImages,
            editor,
            editingObject,
        });
    }
}