import React from "react";
import Objects from "core/controllers/objects";
import { LayerType } from "core/common/layers";
import { UserProjectType } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { EditorActiveObject } from "core/common/interfaces";
import { LeftPanelItemType } from "components/constants/editor-options";
import { isStaticImageObjectColor, isStaticImageObjectHed, isStaticImageObjectUploaded } from "core/utils/type-guards";

function setActiveObjectBottomPanel(
    activeObject: EditorActiveObject,
) {
    if (!activeObject) {
        return;
    }
    const {
        setActiveLeftPanels,
    } = editorContextStore.getState();

    if ((activeObject as fabric.Object).type === LayerType.GENERATION_FRAME) {
        return;
    } else if (Objects.isMultiSelectableObjects(activeObject as fabric.Object)) {

        if (isStaticImageObjectHed(activeObject)) {
            // setActiveLeftPanels(['Edit']);
            return;
        }

        if (isStaticImageObjectColor(activeObject)) {
            return;
        }

        if (isStaticImageObjectUploaded(activeObject)) {
            return;
        }

        console.log('Set active object panel as Edit');

        setActiveLeftPanels(['Edit']);
    }
}

export function useActiveObjectBottomPanel() {
    const activeObject = editorContextStore(state => state.activeObject);
    const prevActiveObjectRef = React.useRef<EditorActiveObject>();

    React.useEffect(() => {
        const {
            isSelectingTag,
            setActiveLeftPanels,
            skipActiveObjectLeftPanelUpdate,
        } = editorContextStore.getState();

        if (skipActiveObjectLeftPanelUpdate.current) {
            skipActiveObjectLeftPanelUpdate.current = false;
            return;
        }
        if (activeObject !== prevActiveObjectRef.current) {
            if (activeObject && !isSelectingTag) {

                setActiveObjectBottomPanel(activeObject);

            } else if (activeObject == null && prevActiveObjectRef.current != null) {

                // Deselect an object
                setActiveLeftPanels(panels => {
                    if (panels.includes('Edit')) {
                        return [];
                    }
                    return panels;
                });

            }
            prevActiveObjectRef.current = activeObject;
        }
    }, [activeObject]);
}

