import React from "react"
import { LeftPanelItem } from "./panel-item"
import { PanelsList } from "./panels-list"
import { LeftPanelAlertDialog } from "./panel-items/components/left-panel-alert"

export function LeftPanels() {

    return (
        <div
            className="hidden lg:flex flex-row"
        >
            <PanelsList />
            <LeftPanelItem />
            <LeftPanelAlertDialog />
        </div>
    )
}