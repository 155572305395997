import React from 'react';
import { Canvas } from 'components-mobile/editor/canvas';
import { EditorNavBar } from 'components-mobile/editor/editor-navbar';

import { BACKGROUND_DARK } from 'components/constants/colors';
import { RequireAuth } from 'components/auth/require-auth';
import { useParams } from 'react-router-dom';
import { editorContextStore } from 'contexts/editor-context';
import { isTemporaryProjectId } from 'core/utils/project-utils';
import { SampleProjectScene } from 'core/common/scene';
import { FirstOnboardEditorTutorial } from 'components-mobile/onboard/project-tutorial-window';
import { Chatbot } from 'components/chatbot';
import { AnalyticsConfig } from 'analytics/config';
import { EditorPopups } from 'components-mobile/popup/popups';
import { RequireDesktop } from 'components/auth/require-desktop';
import { initFabricFilterBackend } from 'core/utils/object-filter-utils';
import { RealTimeRenderMode } from 'core/common/types/realtime-render';
import { RealTimeRenderController } from 'components/editor/realtime-render-controller';
import { useRealTimeQuotaUpdate } from 'hooks/use-realtime-update';
import { BottomPanels } from 'components-mobile/panels/bottom-panels';

export function EditorInternal({
    initScene,
}: {
    initScene?: SampleProjectScene,
}) {
    const params = useParams();
    const projectId = params?.projectId;
    const setProjectId = editorContextStore(state => state.setProjectId);

    React.useEffect(() => {
        editorContextStore.getState().analytics.track(
            AnalyticsConfig.PageOpen,
            {
                name: `Project ${projectId}`,
                url: window.location.href,
            }
        );
    }, [projectId]);

    React.useEffect(() => {
        initFabricFilterBackend();
    }, []);

    React.useEffect(() => {
        if (projectId && !isTemporaryProjectId(projectId)) {
            setProjectId(projectId);
            // Load project state from remote server
            const {
                backend,
                setProjectDisplayName,
            } = editorContextStore.getState();
            backend?.getProjectDisplayName(projectId).then((projectDisplayName) => {
                setProjectDisplayName(projectDisplayName);
            });
        } else {
            setProjectId(undefined);
        }
        return () => {
            setProjectId(undefined);
        }
    }, [projectId, setProjectId]);

    React.useEffect(() => {
        const {
            setRealtimeRenderMode,
            setRealtimeRenderController,
        } = editorContextStore.getState();
        setRealtimeRenderMode(RealTimeRenderMode.Disabled);

        const realTimeRenderController = new RealTimeRenderController();

        setRealtimeRenderController(realTimeRenderController);

        return () => {
            setRealtimeRenderController(undefined);
            realTimeRenderController.destroy();
        }
    }, []);

    useRealTimeQuotaUpdate();

    return (
        <div
            className='w-screen h-screen flex flex-col text-sm text-zinc-100 overflow-hidden'
        >
            <EditorNavBar />
            <div
                className='flex-1 flex flex-row overflow-hidden'
                style={{
                    backgroundColor: BACKGROUND_DARK,
                }}
            >
                <BottomPanels />
                <Canvas
                    config={{
                        // background: BACKGROUND_DARK,
                        background: 'transparent',
                        controlsPosition: {
                            rotation: "BOTTOM",
                        },
                        shadow: null,
                    }}
                    initScene={initScene}
                />
            </div>
        </div>
    )
}

export function Editor({
    initScene,
}: {
    initScene?: SampleProjectScene,
}) {
    return (
        <RequireAuth>
            <RequireDesktop>
                <FirstOnboardEditorTutorial />
                <EditorInternal initScene={initScene} />
                <Chatbot />
            </RequireDesktop>
        </RequireAuth>
    );
}