import React from 'react';
import { createPortal } from "react-dom";
import { Cross2Icon } from '@radix-ui/react-icons';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { Tooltip } from 'components/utils/tooltip';
import { editorContextStore } from 'contexts/editor-context';
import { MouseButton, getMouseButtonFromPointerEvent } from 'core/common/constants';
import Events from 'core/common/events';
import { classNames } from 'core/utils/classname-utils';
import { clamp, noop, toInteger, toSafeInteger } from 'lodash';
import { TryOnClothPoseEditorTaskbar, TryOnPersonEditor } from './tryon-person-editor';
import { getHtmlImageElementFromUrlAsync, getImageElementFromFilesAsync } from 'core/utils/image-utils';
import { TryOnRenderResultViewer } from './tryon-render-result-viewer';
import { LeftPanelAlertCancelEventHandler, LeftPanelAlertConfirmEventHandler, SetActiveHistoryHandler, StateUpdater, TryOnClothMaskPaintState, TryOnClothMaskType, TryOnClothMaskTypeColorHex, TryOnClothMaskTypeName, TryOnEditorState, TryOnGeneratedImagesEventHandler } from 'core/common/types';
import { isDataURL } from 'core/utils/string-utils';
import { fabric } from "fabric"
import {
    TryOnEditorContextProvider,
    generateClothPrompt,
    handleClothImageUpdate,
    resetTryOnEditor,
    setTryOnPersonImageElementFromImageId,
    useTryOnClothBrushStrokesHistoryRef,
    useTryOnClothCanvasController,
    useTryOnPersonCanvasController,
    useTryOnRenderResults,
} from 'contexts/tryon-editor-context';
import { useNumUndoRedoTryOnClothBrushStrokesHistory } from './tryon-cloth-canvas-controller';
import { HtmlCanvasController, Point } from './html-canvas-controller';
import { useFileDrop } from 'hooks/use-file-drop';
import { FloatTagZIndex, TryOnClothEditorZIndex } from 'components/constants/zIndex';
import { PaintBrushIcon } from 'components/icons/paint-brush-icon';
import { Eraser, Minus, Plus, Redo, RefreshCcw, Undo } from 'lucide-react';
import { TryOnBrushSizeAdjust, TryOnPaintBrushCursor, TryOnTaskBarButton, TryOnTaskBarButtonProps, TryOnToolbarDividerVertical } from './tryon-toolbar';
import { TRYON_CLOTH_EDITOR_CONTAINER_ID } from 'components/constants/ids';
import { LeftPanelItemType, isTryOnLeftPanelItemType } from 'components/constants/editor-options';
import { getClothImageElementFromFiles } from 'components/utils/tryon-upload-image';
import { AnalyticsConfig } from 'analytics/config';

function getTouchEventLocation(e: React.TouchEvent) {
    return { x: e.touches[0].clientX, y: e.touches[0].clientY };
}

type TryOnClothMaskEditorTaskBarButtonProps = TryOnTaskBarButtonProps;

function TryOnClothMaskEditorTaskBarButton(props: TryOnClothMaskEditorTaskBarButtonProps) {
    return (
        <TryOnTaskBarButton {...props} />
    );
}

function TryOnClothUndoButton(props: Partial<TryOnClothMaskEditorTaskBarButtonProps>) {
    return (
        <TryOnClothMaskEditorTaskBarButton
            {...props}
            className='flex items-center justify-center'
            contentClassName='text-zinc-300'
            contentChildren="Undo"
        >
            <Undo size={18} />
        </TryOnClothMaskEditorTaskBarButton>
    );
}

function TryOnClothRedoButton(props: Partial<TryOnClothMaskEditorTaskBarButtonProps>) {
    return (
        <TryOnClothMaskEditorTaskBarButton
            {...props}
            contentClassName='text-zinc-300'
            contentChildren="Redo"
        >
            <Redo size={18} />
        </TryOnClothMaskEditorTaskBarButton>
    );
}


const MIN_BRUSH_SIZE = 5;
const MAX_BRUSH_SIZE = 100;
const DELTA_BRUSH_SIZE = 5;

function TryOnClothMaskBrushSizeAdjust() {
    const tryOnClothMaskBrushSize = editorContextStore(state => state.tryOnClothMaskBrushSize);
    const [brushSize, setBrushSize] = React.useState(tryOnClothMaskBrushSize);
    return (
        <div
            className='flex flex-row items-center justify-center'
        >
            <TryOnClothMaskEditorTaskBarButton
                className='px-1'
                contentChildren="Decrease brush size"
                isDisabled={brushSize <= MIN_BRUSH_SIZE}
                onClick={() => {
                    editorContextStore.getState().setTryOnClothMaskBrushSize((prevBrushSize) => {
                        const newBrushSize = clamp(
                            prevBrushSize - DELTA_BRUSH_SIZE,
                            MIN_BRUSH_SIZE,
                            MAX_BRUSH_SIZE,
                        );
                        setBrushSize(newBrushSize);
                        return newBrushSize;
                    });
                }}
            >
                <Minus
                    size={16}
                />
            </TryOnClothMaskEditorTaskBarButton>
            <div className='w-1' />
            <input
                className={classNames(
                    'w-12 px-1 py-1 text-center text-zinc-200 bg-zinc-500/10 rounded-md border border-solid border-zinc-500/20 hover:border-zinc-500/50 focus-visible:outline-none focus:border-lime-500 focus-visible:border-lime-500 transition-colors pointer-events-auto'
                )}
                type='number'
                value={brushSize}
                onChange={(e) => {

                    setBrushSize(toSafeInteger(e.currentTarget.value));
                }}
                onBlur={() => {
                    const value = clamp(
                        brushSize,
                        MIN_BRUSH_SIZE,
                        MAX_BRUSH_SIZE,
                    );
                    setBrushSize(value);
                    editorContextStore.getState().setTryOnClothMaskBrushSize(value);
                }}
            />
            <div className='w-1' />
            <TryOnClothMaskEditorTaskBarButton
                className='px-1'
                contentChildren="Increase brush size"
                isDisabled={brushSize >= MAX_BRUSH_SIZE}
                onClick={() => {
                    editorContextStore.getState().setTryOnClothMaskBrushSize((prevBrushSize) => {
                        const newBrushSize = clamp(
                            prevBrushSize + DELTA_BRUSH_SIZE,
                            MIN_BRUSH_SIZE,
                            MAX_BRUSH_SIZE,
                        );
                        setBrushSize(newBrushSize);
                        return newBrushSize;
                    });
                }}
            >
                <Plus
                    size={16}
                />
            </TryOnClothMaskEditorTaskBarButton>
        </div>
    )
}

const paintBrushCursorClassNames: Record<TryOnClothMaskType, string> = {
    'empty': 'bg-zinc-500/50 border-zinc-800/20',
    'left-sleeve': 'bg-red-500/50 border-red-800/20',
    'torso': 'bg-lime-500/50 border-lime-800/20',
    'right-sleeve': 'bg-blue-500/50 border-blue-800/20',
}

function TryOnClothMaskEditorLegendButton({
    children,
    className = '',
    iconClassName = '',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    iconClassName?: string,
}) {
    return (
        <button
            {...props}
            className={classNames(
                className,
                'flex flex-row items-center justify-start min-h-[1.75rem] px-2 py-1 rounded text-zinc-500 bg-zinc-900 border border-zinc-800 transition-colors pointer-events-auto cursor-pointer',
            )}
        >
            <div
                className={classNames(
                    iconClassName,
                    'w-4 h-4 mr-2 rounded-sm'
                )}
            />
            {children}
        </button>
    )
}

const LegendsClassNames: { [key in TryOnClothMaskType]?: string } = {
    'right-sleeve': "bg-blue-700 group-hover:bg-blue-600",
    'torso': "bg-lime-700 group-hover:bg-lime-600",
    'left-sleeve': "bg-red-700 group-hover:bg-red-600",
}

function TryOnClothMaskEditorLegends({
    children,
    className = '',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const tryOnParsedClothImageElement = editorContextStore(state => state.tryOnParsedClothImageElement);

    if (!tryOnParsedClothImageElement) {
        return null;
    }

    return (
        <div
            {...props}
            className={classNames(
                className,
                'grid grid-rows-3 grid-cols-1 gap-1 pointer-events-none',
            )}
        >
            {Object.entries(LegendsClassNames).map(([type, className]) => (
                <TryOnClothMaskEditorLegendButton
                    key={type}
                    className='group'
                    iconClassName={className}
                    onClick={() => {
                        editorContextStore.getState().setTryOnActiveClothMaskType(type as TryOnClothMaskType);
                    }}
                >
                    {TryOnClothMaskTypeName[type as TryOnClothMaskType]}
                </TryOnClothMaskEditorLegendButton>
            ))}
        </div>
    )
}

const BrushTypeClassNames: Record<TryOnClothMaskType, string> = {
    'empty': "bg-zinc-800 hover:bg-zinc-600 border border-zinc-500 hover:border-zinc-300 active:border-zinc-400",
    'left-sleeve': "bg-red-800 hover:bg-red-600 border border-red-500 hover:border-red-300 active:border-red-400",
    "torso": "bg-lime-800 hover:bg-lime-600 border border-lime-500 hover:border-lime-300 active:border-lime-400",
    "right-sleeve": "bg-blue-800 hover:bg-blue-600 border border-blue-500 hover:border-blue-300 active:border-blue-400",
}

const ActiveBrushTypeClassName: Record<TryOnClothMaskType, string> = {
    'empty': 'bg-zinc-500 border border-zinc-200',
    'left-sleeve': 'bg-red-500 border border-red-200',
    'torso': 'bg-lime-500 border border-lime-200',
    'right-sleeve': 'bg-blue-500 border border-blue-200',
}

function getBruthTypeClassName(
    type: TryOnClothMaskType,
    activeType: TryOnClothMaskType,
    tryOnClothMaskPaintState: TryOnClothMaskPaintState,
) {
    return (activeType === type && tryOnClothMaskPaintState === 'painting') ?
        ActiveBrushTypeClassName[type] :
        BrushTypeClassNames[type];
}

function TryOnClothBrushTypePickerButton({
    maskType,
    tryOnActiveClothMaskType,
    tryOnClothMaskPaintState,
}: {
    maskType: TryOnClothMaskType,
    tryOnActiveClothMaskType: TryOnClothMaskType,
    tryOnClothMaskPaintState: TryOnClothMaskPaintState,
}) {
    return (
        <Tooltip
            triggerChildren={(
                <button
                    className={classNames(
                        'justify-self-center w-4 h-4 rounded-full transition-colors',
                        getBruthTypeClassName(
                            maskType,
                            tryOnActiveClothMaskType,
                            tryOnClothMaskPaintState,
                        ),
                    )}
                    onClick={() => {
                        editorContextStore.getState().setTryOnActiveClothMaskType(maskType);
                        editorContextStore.getState().setTryOnClothMaskPaintState('painting');
                    }}
                />
            )}
            triggerProps={{
                asChild: true,
            }}
            contentChildren={(
                <div
                    className='flex flex-col'
                >
                    <div className='text-zinc-300 mb-1'>
                        {TryOnClothMaskTypeName[maskType]}
                    </div>
                    <div className='text-zinc-500'>
                        Select this color to paint mask for {TryOnClothMaskTypeName[maskType].toLowerCase()}
                    </div>
                </div>
            )}
        />
    )
}

function TryOnClothBrushTypePicker() {
    const tryOnActiveClothMaskType = editorContextStore(state => state.tryOnActiveClothMaskType);
    const tryOnClothMaskPaintState = editorContextStore(state => state.tryOnClothMaskPaintState)
    return (
        <div
            className="px-2 grid grid-rows-1 grid-cols-3 gap-2 items-center justify-center pointer-events-auto"
        >
            <TryOnClothBrushTypePickerButton
                tryOnActiveClothMaskType={tryOnActiveClothMaskType}
                tryOnClothMaskPaintState={tryOnClothMaskPaintState}
                maskType='right-sleeve'
            />
            <TryOnClothBrushTypePickerButton
                tryOnActiveClothMaskType={tryOnActiveClothMaskType}
                tryOnClothMaskPaintState={tryOnClothMaskPaintState}
                maskType='torso'
            />
            <TryOnClothBrushTypePickerButton
                tryOnActiveClothMaskType={tryOnActiveClothMaskType}
                tryOnClothMaskPaintState={tryOnClothMaskPaintState}
                maskType='left-sleeve'
            />
        </div>
    )
}

function TryOnClothMaskEditorTaskBar({
    children,
    className = '',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const historyRef = useTryOnClothBrushStrokesHistoryRef();

    const tryOnClothMaskPaintState = editorContextStore(state => state.tryOnClothMaskPaintState);

    const tryOnClothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    const tryOnActiveClothMaskType = editorContextStore(state => state.tryOnActiveClothMaskType);

    const tryOnClothMaskBrushSize = editorContextStore(state => state.tryOnClothMaskBrushSize);

    const setTryOnClothMaskBrushSize = editorContextStore(state => state.setTryOnClothMaskBrushSize);

    const {
        numUndos,
        numRedos,
    } = useNumUndoRedoTryOnClothBrushStrokesHistory({
        history: historyRef.current,
    });

    if (!tryOnClothImageElement) {
        return null;
    }

    return (
        <div
            className={classNames(
                className,
                'w-full flex items-center justify-center mb-4 z-10',
            )}
        >
            <div
                {...props}
                className={classNames(
                    'relative px-1.5 py-1.5 rounded-lg flex flex-row items-center justify-center justify-items-stretch text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity',
                )}
                style={{
                    zIndex: FloatTagZIndex,
                }}
            >
                <div className='grid grid-rows-1 grid-cols-2 items-center justify-center'>
                    <TryOnClothUndoButton
                        isDisabled={numUndos <= 0}
                        onClick={() => {
                            historyRef.current?.undo();
                        }}
                    />
                    <TryOnClothRedoButton
                        isDisabled={numRedos <= 0}
                        onClick={() => {
                            historyRef.current?.redo();
                        }}
                    />
                </div>
                <TryOnToolbarDividerVertical />
                <TryOnClothBrushTypePicker />
                <TryOnToolbarDividerVertical />
                <TryOnClothMaskEditorTaskBarButton
                    isActive={tryOnClothMaskPaintState === 'painting'}
                    className='flex flex-row items-center justify-center'
                    contentChildren={
                        <div
                            className='flex flex-col'
                        >
                            <div className='text-zinc-300 mb-1'>
                                Mask Paint Tool
                            </div>
                            <div className='text-zinc-500'>
                                Select this tool to paint mask for {TryOnClothMaskTypeName[tryOnActiveClothMaskType].toLowerCase()}
                            </div>
                        </div>
                    }
                    onClick={() => {
                        editorContextStore.getState().setTryOnClothMaskPaintState((prevState) => {
                            if (prevState === 'painting') {
                                return 'idle';
                            }
                            return 'painting';
                        });
                    }}
                >
                    <PaintBrushIcon
                        size={18}
                        brushColor={TryOnClothMaskTypeColorHex[tryOnActiveClothMaskType]}
                        className=''
                    />
                </TryOnClothMaskEditorTaskBarButton>
                <div className='w-2' />
                <TryOnClothMaskEditorTaskBarButton
                    isActive={tryOnClothMaskPaintState === 'erasing'}
                    className='flex flex-row items-center justify-center'
                    contentChildren={
                        <div
                            className='flex flex-col'
                        >
                            <div className='text-zinc-300 mb-1'>
                                Mask Erase Tool
                            </div>
                            <div className='text-zinc-500'>
                                Select this tool to erase mask
                            </div>
                        </div>
                    }
                    onClick={() => {
                        editorContextStore.getState().setTryOnClothMaskPaintState((prevState) => {
                            if (prevState === 'erasing') {
                                return 'idle';
                            }
                            return 'erasing';
                        });
                    }}
                >
                    <Eraser
                        size={18}
                        className=''
                    />
                </TryOnClothMaskEditorTaskBarButton>
                <TryOnToolbarDividerVertical
                    className='hidden xl:block'
                />
                <TryOnBrushSizeAdjust
                    className='hidden xl:flex'
                    brushSize={tryOnClothMaskBrushSize}
                    setBrushSize={setTryOnClothMaskBrushSize}
                />
                {children}
            </div>
        </div>
    )
}

function TryOnClothMaskEditor({
    zoomScrollSensitivity = 0.2,
}: {
    zoomScrollSensitivity?: number,
}) {
    const editor = editorContextStore(state => state.editor);
    const canvasContainerRef = React.useRef<HTMLDivElement | null>(null);
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const maskCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const canvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const maskCanvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const isPointerOverToolbarRef = React.useRef(false);

    const brushCursorRef = React.useRef<HTMLDivElement | null>(null);
    const brushSize = editorContextStore(state => state.tryOnClothMaskBrushSize);
    const tryOnClothMaskPaintState = editorContextStore(state => state.tryOnClothMaskPaintState);
    const tryOnActiveClothMaskType = editorContextStore(state => state.tryOnActiveClothMaskType);
    const isBrushVisible = tryOnClothMaskPaintState === 'painting' || tryOnClothMaskPaintState === 'erasing';
    const [isCanvasMoving, setIsCanvasMoving] = React.useState(false);
    const [isPointerOver, setIsPointerOver] = React.useState(false);

    const canvasControllerHistoryRef = useTryOnClothBrushStrokesHistoryRef();
    const canvasControllerRef = useTryOnClothCanvasController();
    const resizeObserverRef = React.useRef<ResizeObserver>();
    const renderFunctionRef = React.useRef<() => void>(noop);
    const isLeftPointerDownRef = React.useRef(false);
    const isMiddlePointerDownRef = React.useRef(false);
    const pointerDownLocationRef = React.useRef<Point>({ x: 0, y: 0 });
    const pointerDownCanvasOriginRef = React.useRef<Point>({ x: 0, y: 0 });
    const pointerPositionRef = React.useRef<Point>({ x: 0, y: 0 });
    const isRenderingRef = React.useRef(false);
    const isPaintingRef = React.useRef(false);
    const isErasingRef = React.useRef(false);

    React.useEffect(() => {
        isPaintingRef.current = false;
        isErasingRef.current = false;

        if (tryOnClothMaskPaintState === 'painting') {
            isPaintingRef.current = true;
        } else if (tryOnClothMaskPaintState === 'erasing') {
            isErasingRef.current = true;
        }
    }, [tryOnClothMaskPaintState]);

    React.useEffect(() => {
        if (!canvasControllerRef.current) {
            return;
        }

        const {
            editor,
            setTryOnClothMaskPaintState,
            setTryOnActiveClothMaskType,
        } = editorContextStore.getState();

        setTryOnClothMaskPaintState('idle');

        setTryOnActiveClothMaskType('torso');

        const canvas = canvasRef.current;
        const container = canvasContainerRef.current;
        if (!editor || !container || !canvas) {
            return;
        }

        resizeObserverRef.current = new ResizeObserver((entries) => {
            const { width, height } = (entries[0] && entries[0].contentRect) || {};

            canvas.width = width;

            canvas.height = height;

            if (maskCanvasRef.current) {
                maskCanvasRef.current.width = width;
                maskCanvasRef.current.height = height;
            }

            canvasControllerRef.current?.init();
            renderFunctionRef.current();
        });

        resizeObserverRef.current.observe(container);

        canvasContextRef.current = canvas.getContext('2d');

        maskCanvasContextRef.current = maskCanvasRef.current?.getContext(
            '2d'
        ) || null;

        if (!canvasContextRef.current) {
            return;
        }

        canvasControllerRef.current?.setContext(canvasContextRef.current);

        if (maskCanvasContextRef.current) {
            canvasControllerRef.current?.setMaskContext(maskCanvasContextRef.current);
        }

        // Initialize render function

        renderFunctionRef.current = () => {

            const canvas = canvasRef.current;

            const ctx = canvasContextRef.current;

            if (!canvas || !ctx) {
                return;
            }

            canvasControllerRef.current?.render();

            if (isRenderingRef.current) {
                requestAnimationFrame(renderFunctionRef.current);
            }
        }

        renderFunctionRef.current();

        // Initialize shortcuts manager

        // editor.emit<SetActiveShortcutsManagerHandler>(
        //     'shortcuts-manager:set',
        //     {
        //         current: new TryOnClothCanvasShortcutsManager({
        //             historyRef: canvasControllerHistoryRef,
        //             controllerRef: canvasControllerRef,
        //         })
        //     }
        // );

        return () => {
            resizeObserverRef.current?.unobserve(container);
        }
    }, [
        canvasControllerRef,
        canvasControllerHistoryRef,
    ]);

    const onPointerDown = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {

        if (!canvasControllerRef.current) {
            return;
        }

        const container = canvasContainerRef.current;
        if (!container) {
            return;
        }

        const mouseButton = getMouseButtonFromPointerEvent(e.nativeEvent);

        if (mouseButton === MouseButton.Middle) {

            isMiddlePointerDownRef.current = true;
            isRenderingRef.current = true;

            const { x, y } = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: container.offsetLeft,
                    y: container.offsetTop,
                }
            );

            pointerDownLocationRef.current.x = x;
            pointerDownLocationRef.current.y = y;

            pointerDownCanvasOriginRef.current = canvasControllerRef.current.getOrigin(pointerDownCanvasOriginRef.current);

            renderFunctionRef.current();

            canvasControllerRef.current.isCanvasMoving = true;
            setIsCanvasMoving(true);
        } else if (mouseButton === MouseButton.Left) {
            isLeftPointerDownRef.current = true;

            if (tryOnClothMaskPaintState !== 'idle' && maskCanvasRef.current && !isPointerOverToolbarRef.current) {
                isRenderingRef.current = true;

                const { left, top } = maskCanvasRef.current.getBoundingClientRect();

                const point = HtmlCanvasController.getPointerEventLocation(
                    e,
                    {
                        x: left,
                        y: top,
                    }
                );

                canvasControllerRef.current?.startBrushStroke({
                    point,
                    brushType: tryOnClothMaskPaintState === 'painting' ? 'paint' : 'erase',
                    color: TryOnClothMaskTypeColorHex[tryOnActiveClothMaskType],
                    lineWidth: brushSize,
                });

                renderFunctionRef.current();
            }

        }

    }, [
        brushSize,
        tryOnClothMaskPaintState,
        tryOnActiveClothMaskType,
        canvasControllerRef,
    ]);

    const onPointerMove = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {

        if (!canvasControllerRef.current) {
            return;
        }

        const container = canvasContainerRef.current;
        if (!container) {
            return;
        }

        if (isLeftPointerDownRef.current) {

            if (tryOnClothMaskPaintState !== 'idle' && maskCanvasRef.current && !isPointerOverToolbarRef.current) {
                const { left, top } = maskCanvasRef.current.getBoundingClientRect();

                const point = HtmlCanvasController.getPointerEventLocation(
                    e,
                    {
                        x: left,
                        y: top,
                    }
                );
                canvasControllerRef.current?.moveBrushStroke(point);
            }

        } else if (isMiddlePointerDownRef.current) {

            const { x, y } = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: container.offsetLeft,
                    y: container.offsetTop,
                }
            );

            const dx = x - pointerDownLocationRef.current.x;
            const dy = y - pointerDownLocationRef.current.y;

            const { x: x0, y: y0 } = pointerDownCanvasOriginRef.current;

            canvasControllerRef.current?.setOrigin(
                x0 + dx,
                y0 + dy,
            );
        }

        if (isPaintingRef.current || isErasingRef.current) {

            if (brushCursorRef.current) {
                const { clientX, clientY } = e;
                brushCursorRef.current.style.left = `${clientX - brushSize * 0.5}px`;
                brushCursorRef.current.style.top = `${clientY - brushSize * 0.5}px`;

                pointerPositionRef.current.x = clientX;
                pointerPositionRef.current.y = clientY;
            }
        }
    }, [
        brushSize,
        tryOnClothMaskPaintState,
        canvasControllerRef,
    ]);

    const onPointerUp = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        if (isLeftPointerDownRef.current) {

            if (tryOnClothMaskPaintState !== 'idle') {
                canvasControllerRef.current?.endBrushStroke();
            }

        }

        isMiddlePointerDownRef.current = false;
        isLeftPointerDownRef.current = false;
        isRenderingRef.current = false;
        renderFunctionRef.current();

        if (canvasControllerRef.current) {
            canvasControllerRef.current.isCanvasMoving = false;
        }

        setIsCanvasMoving(false);
    }, [
        canvasControllerRef,
        tryOnClothMaskPaintState,
    ]);

    const onPointerEnter = React.useCallback(() => {
        setIsPointerOver(true);
    }, []);

    const onPointerLeave = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        isMiddlePointerDownRef.current = false;
        isRenderingRef.current = false;
        renderFunctionRef.current();
        setIsPointerOver(false);
    }, []);

    React.useEffect(() => {
        if (!brushCursorRef.current) {
            return;
        }
        brushCursorRef.current.style.left = `${pointerPositionRef.current.x - brushSize * 0.5}px`;
        brushCursorRef.current.style.top = `${pointerPositionRef.current.y - brushSize * 0.5}px`;
    }, [brushSize]);

    React.useEffect(() => {
        if (!editor || !canvasControllerHistoryRef.current) {
            return;
        }
        editor.emit<SetActiveHistoryHandler>(
            'history:set',
            canvasControllerHistoryRef,
        );
        return () => {
            editor.emit<SetActiveHistoryHandler>(
                'history:set',
                { current: undefined },
            );
        }
    }, [
        editor,
        canvasControllerRef,
        canvasControllerHistoryRef,
    ]);

    const onMouseWheel = React.useCallback((e: React.WheelEvent<HTMLDivElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }

        const isCtrlKey = e.ctrlKey;

        if (isCtrlKey) {
            const { left, top } = canvas.getBoundingClientRect();

            const pointerPos = HtmlCanvasController.getPointerEventLocation(
                e,
                {
                    x: left,
                    y: top,
                },
            );

            canvasControllerRef.current?.scaleAt(
                pointerPos,
                Math.exp((-e.deltaY / 120) * zoomScrollSensitivity)
            );

            renderFunctionRef.current();

        } else {
            const isTrackpad = Events.detectTrackpadUtil(e.nativeEvent);

            if (isTrackpad) {
                const deltaX = e.deltaX;

                const deltaY = e.deltaY;

                canvasControllerRef.current?.move(
                    -deltaX,
                    -deltaY,
                );

                renderFunctionRef.current();
            }
        }

    }, [
        zoomScrollSensitivity,
        canvasControllerRef,
    ]);

    return (
        <div
            ref={canvasContainerRef}
            className='relative w-full h-full'
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onWheel={onMouseWheel}
        >
            <TryOnClothMaskEditorLegends
                className='absolute left-2 top-10 transition-opacity'
                style={{
                    opacity: isPointerOver ? 1 : 0,
                }}
            />
            <TryOnClothMaskEditorTaskBar
                className='absolute left-0 bottom-0 transition-opacity'
                style={{
                    opacity: isPointerOver ? 1 : 0,
                }}
                onPointerEnter={() => {
                    isPointerOverToolbarRef.current = true;
                }}
                onPointerLeave={() => {
                    isPointerOverToolbarRef.current = false;
                }}
            />
            <TryOnPaintBrushCursor
                ref={brushCursorRef}
                className={paintBrushCursorClassNames[tryOnClothMaskPaintState === 'painting' ? tryOnActiveClothMaskType : 'empty']}
                style={{
                    display: isBrushVisible ? 'block' : 'none',
                    opacity: isPointerOver ? 1 : 0,
                    width: `${brushSize || 0}px`,
                    height: `${brushSize || 0}px`,
                }}
            />
            <canvas
                ref={canvasRef}
            />
            <canvas
                ref={maskCanvasRef}
                className='absolute left-0 top-0 transition-opacity'
                style={{
                    opacity: isCanvasMoving ? 0 : 0.5,
                }}
            />
        </div>
    )
}

function PanelLabel({
    children,
    className = '',
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            className={classNames(
                'absolute left-2 top-2 pointer-events-none text-zinc-500/50',
                className,
            )}
            {...props}
        >
            {children}
        </div>
    )
}

function getImageElementFromUrl(src: string) {
    return getHtmlImageElementFromUrlAsync(src);
}

function useParsedClothImageEffect() {
    const tryOnModelId = editorContextStore(state => state.tryOnModelId);

    const tryOnClothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    const clothCanvasControllerRef = useTryOnClothCanvasController();

    React.useEffect(() => {
        if (!clothCanvasControllerRef.current || !tryOnModelId) {
            return;
        }

        const {
            setTryOnWarpedClothImageElement,
            setTryOnWarpedHumanMaskImageElement,
        } = editorContextStore.getState();

        setTryOnWarpedClothImageElement(undefined);

        setTryOnWarpedHumanMaskImageElement(undefined);

        clothCanvasControllerRef.current.parseAndWarpClothImage();
    }, [
        tryOnModelId,
        clothCanvasControllerRef,
    ]);

    React.useEffect(() => {

        if (!clothCanvasControllerRef.current ||
            !tryOnClothImageElement) {
            return;
        }

        const clothCanvasController = clothCanvasControllerRef.current;

        clothCanvasController.handleClothImageUpdate();

        clothCanvasController.parseAndWarpClothImage();

    }, [
        tryOnClothImageElement,
        clothCanvasControllerRef,
    ]);
}

function TryOnClothEditorContainer({
    children,
    className = "",
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                className,
                'absolute flex flex-row left-0 top-0 w-full h-full p-2',
            )}
            style={{
                zIndex: TryOnClothEditorZIndex,
            }}
        >
            <div className='relative flex flex-row w-full h-full bg-zinc-900 rounded-lg border border-zinc-800 shadow-lg'>
                <Tooltip
                    triggerProps={{
                        asChild: true,
                    }}
                    triggerChildren={
                        <Cross2Icon
                            width={18}
                            height={18}
                            className='absolute z-10 top-2 right-2 text-zinc-500 hover:text-zinc-200 cursor-pointer pointer-events-all'
                            onClick={() => {
                                editorContextStore.getState().setActiveLeftPanels(['Generate']);
                            }}
                        />
                    }
                    contentChildren="Exit editing cloth"
                />
                {children}
            </div>
        </div>
    )
}

function TryOnClothEditorVerticalDivider() {
    return (
        <div
            className='w-px h-full bg-zinc-800'
        />
    );
}

function TryOnClothEditorCover({
    children,
    visible = true,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    visible?: boolean,
}) {
    if (!visible) {
        return null;
    }
    return (
        <div
            {...props}
            className='absolute left-0 top-0 w-full h-full flex flex-row items-center justify-center pointer-events-none bg-zinc-900/70 text-lg'
        >
            {children}
        </div>
    )
}

function TryOnClothEditorLoadingCover({
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    visible?: boolean,
}) {
    return (
        <TryOnClothEditorCover
            {...props}
        >
            <SimpleSpinner width={20} height={20} pathClassName="fill-lime-500" />
            <span className='w-2' />
            {children}
        </TryOnClothEditorCover>
    );
}

function getTryOnClothEditorLoadingMessage(
    clothImageElement: HTMLImageElement | undefined,
    tryOnEditorState: TryOnEditorState,
) {
    if (!clothImageElement) {
        return "Loading cloth image ...";
    }
}

function TryOnClothEditorClothCanvas() {
    const clothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);

    const loadingMessage = getTryOnClothEditorLoadingMessage(
        clothImageElement,
        tryOnEditorState,
    );

    const tryOnClothCanvasControllerRef = useTryOnClothCanvasController();

    return (
        <div
            tabIndex={-1}
            className='group relative flex-1 h-full min-w-0 focus-visible:outline-none'
            onKeyDown={(e) => {
                tryOnClothCanvasControllerRef.current?.handleKeyDown(e.nativeEvent)
            }}
        >
            <PanelLabel
                className='group-focus:text-zinc-100'
            >
                Cloth
            </PanelLabel>
            <TryOnClothMaskEditor />
            <TryOnClothEditorLoadingCover
                visible={Boolean(loadingMessage)}
            >
                {loadingMessage}
            </TryOnClothEditorLoadingCover>
        </div>
    );
}

function TryOnClothUploadCanvas() {
    const addDroppedFiles = React.useCallback((
        files: FileList,
        e: React.DragEvent<HTMLDivElement>,
    ) => {
        e.preventDefault();
        e.stopPropagation();

        getClothImageElementFromFiles({
            files,
        }).then((imageElement) => {
            if (!imageElement) {
                return;
            }

            const {
                setActiveLeftPanels,
                setTryOnClothImageElement,
            } = editorContextStore.getState()

            setTryOnClothImageElement(imageElement);

            setActiveLeftPanels((panels) => [
                ...panels,
                'TryOnSelectPose',
            ]);
        })
    }, []);

    const {
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
    } = useFileDrop({
        handleDropFiles: addDroppedFiles,
    });

    const tryOnClothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    const tryOnClothCanvasControllerRef = useTryOnClothCanvasController();

    return (
        <div
            tabIndex={-1}
            className='group relative flex-1 h-full min-w-0 focus-visible:outline-none'
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onKeyDown={(e) => {
                tryOnClothCanvasControllerRef.current?.handleKeyDown(e.nativeEvent)
            }}
        >
            <PanelLabel
                className='group-focus:text-zinc-100'
            >
                Cloth
            </PanelLabel>
            <TryOnClothMaskEditor />
            <TryOnClothEditorCover
                visible={!Boolean(tryOnClothImageElement)}
            >
                <div
                    className='flex flex-col items-center justify-center px-4'
                >
                    <span
                        className='text-zinc-300 mb-1 text-xl'
                    >
                        Drag the image of your clothing item here.
                    </span>
                    <span
                        className='text-zinc-500'
                    >
                        Only works with <span className='font-bold text-zinc-300'>Tops, T-Shirts, and Sweaters</span>.
                    </span>
                </div>
            </TryOnClothEditorCover>
        </div>
    );

}

function getTryOnHumanPreviewLoadingMessage(
    personImageElement: HTMLImageElement | undefined,
    tryOnEditorState: TryOnEditorState,
) {
    if (!personImageElement) {
        return "Loading model image ...";
    }

    if (tryOnEditorState === 'warping') {
        return "Fitting cloth onto the model";
    }

    if (tryOnEditorState === 'rendering') {
        return "Generating model image";
    }

    if (tryOnEditorState === 'upscaling') {
        return "Upscaling model image";
    }
}

function TryOnClothEditorHumanPreviewCanvas() {

    const personImageElement = editorContextStore(state => state.tryOnPersonImageElement);

    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);

    const loadingMessage = getTryOnHumanPreviewLoadingMessage(
        personImageElement,
        tryOnEditorState,
    );

    const tryOnClothCanvasControllerRef = useTryOnPersonCanvasController();

    return (
        <div
            tabIndex={-1}
            className='group relative flex-1 h-full min-w-0 focus-visible:outline-none'
            onKeyDown={(e) => {
                tryOnClothCanvasControllerRef.current?.handleKeyDown(e.nativeEvent);
            }}
        >
            <PanelLabel
                className='group-focus:text-zinc-100'
            >
                Human Preview
            </PanelLabel>
            <TryOnClothPoseEditorTaskbar
                className='absolute left-0 bottom-0 transition-opacity'
            />
            <TryOnPersonEditor />
            <TryOnClothEditorLoadingCover
                visible={Boolean(loadingMessage)}
            >
                {loadingMessage}
            </TryOnClothEditorLoadingCover>
        </div>
    )
}

function TryOnSelectPoseEditor() {

    return (
        <TryOnClothEditorContainer>
            <TryOnClothEditorClothCanvas />
            <TryOnClothEditorVerticalDivider />
            <TryOnClothEditorHumanPreviewCanvas />
        </TryOnClothEditorContainer>
    );
}

function TryOnUploadClothEditor() {
    return (
        <TryOnClothEditorContainer>
            <TryOnClothUploadCanvas />
            <TryOnClothEditorVerticalDivider />
            <TryOnClothEditorHumanPreviewCanvas />
        </TryOnClothEditorContainer>
    );
}

function TryOnRenderEditor() {
    return (
        <TryOnClothEditorContainer>
            <TryOnRenderResultViewer
                className='flex-1 h-full min-w-0'
            >
                <PanelLabel>
                    Generated Results
                </PanelLabel>
            </TryOnRenderResultViewer>
            <TryOnClothEditorVerticalDivider />
            <TryOnClothEditorHumanPreviewCanvas />
        </TryOnClothEditorContainer>
    );
}

function useAutoCaption() {
    const tryOnClothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    React.useEffect(() => {

        if (!tryOnClothImageElement) {
            return;
        }

        const {
            tryOnClothPrompt,
        } = editorContextStore.getState();

        if (tryOnClothPrompt && tryOnClothPrompt.length > 0) {

            // Prompt is already defined, no need to caption it
            return;
        }

        generateClothPrompt();

    }, [tryOnClothImageElement]);
}

function useTryOnInitCleanUp() {

    const tryOnClothImageElement = editorContextStore(state => state.tryOnClothImageElement);

    React.useEffect(() => {

        // Clean up the previous warped cloth images when the cloth image is removed

        if (!tryOnClothImageElement) {
            resetTryOnEditor();
        }


    }, [tryOnClothImageElement]);

    React.useEffect(() => {
        const {
            setActiveLeftPanels,
        } = editorContextStore.getState();

        console.log("Try on init upload cloth");

        setActiveLeftPanels(['TryOnUploadCloth']);


        return () => {

            const {
                editor,
                tryOnClothImageElement,
                tryOnRenderResults,
            } = editorContextStore.getState();

            if (editor) {

                const viewportCenter = editor.canvas.getViewportCenter();

                const targetHeight = tryOnRenderResults.reduce((prevHeight, { height }) => Math.max(prevHeight, height), 0) || tryOnClothImageElement?.height || 1024;

                let startX = viewportCenter.x;
                let startY = viewportCenter.y;

                if (tryOnClothImageElement?.src) {

                    const targetWidth = tryOnClothImageElement.width * targetHeight / tryOnClothImageElement.height;

                    const url = tryOnClothImageElement.src;

                    editor.objects.addImageFromUrl({
                        url,
                        location: {
                            x: startX,
                            y: startY,
                        },
                        targetWidth,
                        targetHeight,
                        uploadStorage: isDataURL(url),
                    });

                    startX += targetWidth;
                }

                if (tryOnRenderResults?.length) {
                    const addImagePromises = tryOnRenderResults.map(({
                        imageUrl,
                        width,
                        height,
                    }) => {
                        if (!imageUrl) {
                            return Promise.resolve();
                        }

                        const targetWidth = width * targetHeight / height;

                        const promise = editor.objects.addImageFromUrl({
                            url: imageUrl,
                            location: {
                                x: startX,
                                y: startY,
                            },
                            targetWidth,
                            targetHeight,
                            uploadStorage: true,
                            setActive: false,
                        });

                        startX += targetWidth;

                        return promise;
                    });

                    Promise.all(addImagePromises).then(
                        images => images.filter(Boolean)[0]
                    ).then((firstImage) => {
                        if (!firstImage?.asset?.path) {
                            return;
                        }

                        const {
                            backend,
                            projectId,
                        } = editorContextStore.getState();

                        if (!backend || !projectId) {
                            return;
                        }

                        return backend?.setProjectThumbnail(
                            projectId,
                            firstImage.asset.path,
                        );
                    });
                }
            }


            resetTryOnEditor();
        }

    }, []);
}

function useDefaultModelId() {
    React.useEffect(() => {
        setTryOnPersonImageElementFromImageId(
            "10065",
            "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/model-preview-ffa4eb0e/public"
        );
    }, []);
}

function useTryOnExitEffect() {
    const editor = editorContextStore(state => state.editor);

    const [isExiting, setExiting] = React.useState(false);

    const stateUpdaterRef = React.useRef<StateUpdater<LeftPanelItemType[]> | undefined>();

    const handleActiveLeftPanelsUpdate = React.useCallback((stateUpdater: StateUpdater<LeftPanelItemType[]>) => {
        const nextLeftPanels = typeof (stateUpdater) === 'function' ?
            stateUpdater(editorContextStore.getState().activeLeftPanels) :
            stateUpdater;

        const nextLeftPanel = nextLeftPanels[nextLeftPanels.length - 1];

        if (isTryOnLeftPanelItemType(nextLeftPanel)) {
            return true;
        }

        stateUpdaterRef.current = stateUpdater;

        setExiting(true);

        return false;
    }, []);

    React.useEffect(() => {
        if (!editor || !isExiting || !stateUpdaterRef.current) {
            return;
        }

        const stateUpdater = stateUpdaterRef.current;

        const handleAlertConfirm = () => {
            editor.removeCanUpdateStateCallback(
                'activeLeftPanels',
                handleActiveLeftPanelsUpdate
            );
            editorContextStore.getState().setActiveLeftPanels(stateUpdater);
        }

        const handleAlertCancel = () => {
            stateUpdaterRef.current = undefined;
            setExiting(false);
        };

        editor.once<LeftPanelAlertConfirmEventHandler>(
            'left-panel:alert-confirm',
            handleAlertConfirm,
        );

        editor.once<LeftPanelAlertCancelEventHandler>(
            'left-panel:alert-cancel',
            handleAlertCancel,
        );

        editorContextStore.setState(() => ({
            isLeftPanelAlertOpen: true,
            leftPanelAlertProps: {
                title: "Are you sure to exit the cloth editor?",
                subtitle: "The current generated result will be added to the canvas. Any unsaved edits will be removed.",
            }
        }));

        return () => {
            stateUpdaterRef.current = undefined;
            editor.off<LeftPanelAlertConfirmEventHandler>(
                'left-panel:alert-confirm',
                handleAlertConfirm,
            );
            editor.off<LeftPanelAlertCancelEventHandler>(
                'left-panel:alert-cancel',
                handleAlertCancel,
            );
        }
    }, [
        editor,
        isExiting,
        handleActiveLeftPanelsUpdate,
    ]);

    React.useEffect(() => {
        if (!editor) {
            return;
        }

        editor.setCanUpdateStateCallback(
            'activeLeftPanels',
            handleActiveLeftPanelsUpdate
        );

        return () => {

            editor.removeCanUpdateStateCallback(
                'activeLeftPanels',
                handleActiveLeftPanelsUpdate
            );
        }
    }, [editor, handleActiveLeftPanelsUpdate]);
}

const TryOnClothEditorInner = React.memo(function TryOnClothEditorInner() {
    const activeLeftPanels = editorContextStore(state => state.activeLeftPanels);
    const currentActivePanel = activeLeftPanels[activeLeftPanels.length - 1];


    useParsedClothImageEffect();

    useAutoCaption();

    useDefaultModelId();

    useTryOnExitEffect();

    useTryOnInitCleanUp();

    if (currentActivePanel === 'TryOnUploadCloth') {
        return (
            <TryOnUploadClothEditor />
        );
    } else if (currentActivePanel === 'TryOnSelectPose') {
        return (
            <TryOnSelectPoseEditor />
        );
    } else if (currentActivePanel === 'TryOnRender') {
        return (
            <TryOnRenderEditor />
        );
    }

    return null;
})


export const TryOnClothEditor = React.memo(function TryOnClothEditor({
    canvasBoundsWidth = 1000,
    canvasBoundsHeight = 1500,
}: {
    canvasBoundsWidth?: number,
    canvasBoundsHeight?: number,
}) {
    return (
        <TryOnEditorContextProvider
            bounds={{
                left: 0,
                top: 0,
                right: canvasBoundsWidth,
                bottom: canvasBoundsHeight,
            }}
        >
            <TryOnClothEditorInner />
        </TryOnEditorContextProvider>
    );
})