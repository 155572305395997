import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import styles from './right-click-menu.module.css';


import { EditorRightClickMenuObject } from './object-menu';
import { downloadImageDataUrl, getStaticImageFileStem } from 'components/utils/data';
import { editorContextStore } from 'contexts/editor-context';
import { isActiveSelection, isStaticImageObject } from 'core/utils/type-guards';
import { displayUiMessage } from 'components/utils/display-message';

export function EditorRightClickMenuActiveSelection() {
    const activeObject = editorContextStore(state => state.activeObject);
    return (
        <>
            <EditorRightClickMenuObject />
            <ContextMenu.Item
                className={styles.ContextMenuItem}
                onClick={() => {
                    if (!isActiveSelection(activeObject)) {
                        displayUiMessage(`Active object must be a group selection.`, 'error');
                        return;
                    }

                    Promise.all(activeObject
                        .getObjects()
                        .map((image) => {
                            if (isStaticImageObject(image)) {
                                return downloadImageDataUrl(
                                    image.getSrc(),
                                    getStaticImageFileStem(image as any as fabric.Object),
                                );
                            }
                            return Promise.resolve();
                        })).catch((e) => {
                            console.error(e);
                            displayUiMessage(
                                "Cannot download images",
                                "error",
                            )
                        });
                }}
            >
                Download
            </ContextMenu.Item>
        </>
    )
}