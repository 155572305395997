import { AppUserSubscriptionTierV2 } from "../types";

export enum AppSubscriptionPlanType {
    Free = 'Free',
    Pro = 'Pro',
    ProPlus = 'Pro+',
}

export enum StripeSubscriptionPriceId {
    IndividualProPlan = "price_1MdzaWLI14x6JehmXZPh9zlE",
    IndividualProPlusPlan = "price_1OmNjPLI14x6Jehm1iGkRijd",
    ApiStandardPlan = "price_1O9AD7LI14x6Jehmobvvbqro",
}

export enum StripeSubscriptionProductId {
    IndividualProPlan = "prod_NBRGNzZxW8R533",
    IndividualProPlusPlan = "prod_PbavOeZrnOyekM",
    ApiStandardPlan = "prod_Ox4LrgcmOcKUig",
}

export interface StripeSubscriptionLineItem {
    price: StripeSubscriptionPriceId,
    quantity?: number,
}

export const priceToLineItem: Record<StripeSubscriptionPriceId, StripeSubscriptionLineItem> = {
    [StripeSubscriptionPriceId.ApiStandardPlan]: {
        price: StripeSubscriptionPriceId.ApiStandardPlan,
    },
    [StripeSubscriptionPriceId.IndividualProPlan]: {
        price: StripeSubscriptionPriceId.IndividualProPlan,
        quantity: 1,
    },
    [StripeSubscriptionPriceId.IndividualProPlusPlan]: {
        price: StripeSubscriptionPriceId.IndividualProPlusPlan,
        quantity: 1,
    },
}

export function isStripeSubscriptionPriceId(value: any): value is StripeSubscriptionPriceId {
    return Object.values(StripeSubscriptionPriceId).includes(value);
}

export function isStripeSubscriptionProductId(value: any): value is StripeSubscriptionProductId {
    return Object.values(StripeSubscriptionProductId).includes(value);
}

export const subscriptionPlanToProduct = {
    [AppSubscriptionPlanType.Free]: undefined,
    [AppSubscriptionPlanType.Pro]: StripeSubscriptionProductId.IndividualProPlan,
    [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionProductId.IndividualProPlusPlan,
}

export const subscriptionPlanToPrice = {
    [AppSubscriptionPlanType.Free]: undefined,
    [AppSubscriptionPlanType.Pro]: StripeSubscriptionPriceId.IndividualProPlan,
    [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionPriceId.IndividualProPlusPlan,
}

export const subscriptionPlanRank = {
    [AppSubscriptionPlanType.Free]: 0,
    [AppSubscriptionPlanType.Pro]: 1,
    [AppSubscriptionPlanType.ProPlus]: 2,
}

export function isSubscriptionPlanBetterThan(planA: AppSubscriptionPlanType, planB: AppSubscriptionPlanType) {
    return subscriptionPlanRank[planA] > subscriptionPlanRank[planB];
}

export const subscriptionTierV2ToRecommendedPlan: Record<AppUserSubscriptionTierV2, AppSubscriptionPlanType> = {
    [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.Pro,
    [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.ProPlus,
    [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
}

export const subscriptionTierV2ToPlan: Record<AppUserSubscriptionTierV2, AppSubscriptionPlanType> = {
    [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.Free,
    [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.Pro,
    [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
}