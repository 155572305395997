import { InputBoxClassName } from 'components/constants/class-names';
import { SimpleSpinner } from 'components/icons/simple-spinner';
import { classNames } from 'core/utils/classname-utils';
import { validateEmail } from 'core/utils/string-utils';
import React from 'react';

enum EmailSubscribeState {
    Idle,
    Loading,
    Subscribed,
    Error,
}

export function EmailSubscribeInput({
    placeholder,
    handleEmailSusbcribe,
}: {
    placeholder?: string,
    handleEmailSusbcribe: (email: string) => Promise<boolean>,
}) {
    const [subscribeState, setSubscribeState] = React.useState<EmailSubscribeState>(EmailSubscribeState.Idle);

    const [message, setMessage] = React.useState('');

    const [email, setEmail] = React.useState<string>('');

    const handleClickSubscribe = React.useCallback(() => {

        if (subscribeState !== EmailSubscribeState.Idle) {
            return;
        }

        if (!email || !validateEmail(email)) {
            setSubscribeState(EmailSubscribeState.Error);
            setMessage("Please input a valid email address.");
            return;
        }

        setSubscribeState(EmailSubscribeState.Loading);

        handleEmailSusbcribe(email).then((isSubscribed) => {
            if (isSubscribed) {
                setSubscribeState(EmailSubscribeState.Subscribed);
                setTimeout(() => {
                    setSubscribeState(EmailSubscribeState.Idle);
                }, 2000);
            } else {
                setSubscribeState(EmailSubscribeState.Idle);
            }
        }).catch(() => {
            setSubscribeState(EmailSubscribeState.Idle);
        });


    }, [
        email,
        subscribeState,
        handleEmailSusbcribe,
    ]);

    return (
        <div className='relative w-full flex flex-col sm:flex-row items-center justify-center gap-4'>
            <input
                type='email'
                required
                className={classNames(
                    InputBoxClassName,
                    subscribeState === EmailSubscribeState.Idle ?
                        "border-zinc-800" :
                        subscribeState === EmailSubscribeState.Loading ?
                            "border-zinc-800/50" :
                            subscribeState === EmailSubscribeState.Error ?
                                "border-red-800/50"
                                : "border-zinc-800"
                )}
                placeholder={placeholder}
                onBlur={(e) => {
                    const inputValue = e.currentTarget.value;

                    if (!validateEmail(inputValue)) {
                        setSubscribeState(EmailSubscribeState.Error);
                        setMessage("Please input a valid email address.");
                        return;
                    }

                    setSubscribeState(EmailSubscribeState.Idle);
                    setMessage("");

                    setEmail(inputValue);
                }}
            />
            <button
                className={classNames(
                    'flex flex-row items-center justify-center text-center gap-4 w-full sm:w-auto rounded-md px-6 py-2 text-base font-semibold tracking-wide text-zinc-900 transition-colors duration-300',
                    subscribeState === EmailSubscribeState.Idle ?
                        "bg-lime-500 hover:bg-lime-300 active:bg-lime-600 " :
                        subscribeState === EmailSubscribeState.Loading ?
                            "bg-zinc-800 text-zinc-500 cursor-wait" :
                            subscribeState === EmailSubscribeState.Error ?
                                "bg-zinc-800 cursor-not-allowed"
                                : "bg-lime-500 hover:bg-lime-300 active:bg-lime-600 "
                )}
                onClick={handleClickSubscribe}
            >
                {subscribeState === EmailSubscribeState.Loading ?
                    <>
                        <SimpleSpinner width={18} height={18} pathClassName='fill-lime-600' />
                        Loading
                    </> :
                    subscribeState === EmailSubscribeState.Subscribed ?
                        "Subscribed!" :
                        "Subscribe"}
            </button>
            <div className={classNames(
                'absolute left-[18px] bottom-[-28px] text-sm text-zinc-300/30 transition-opacity',
                message ? "block opacity-100" : "hidden opacity-0",
            )}>
                {message}
            </div>
        </div>
    );
}