import { cloneDeep, noop } from "lodash";
import { GenerateStrength, PromptTemplate, RegenerateProductRenderState, RegenerateProductResults, StateUpdater } from "../types";
import { RenderProcessController } from "./render-progress-controller";
import { emptyGenerateTemplate } from "components/constants/default-generate-template";
import { getUpdaterFunction, getUpdaterFunctionWithCleanup, SetEditorStateFunction } from "contexts/editor-context-utils";

export interface RegenerateProductEditorState {
    regenerateProductRenderState: RegenerateProductRenderState,
    setRegenerateProductRenderState: (value: StateUpdater<RegenerateProductRenderState>) => void,
    regenerateProductCorrectColor: boolean,
    setRegenerateProductCorrectColor: (value: StateUpdater<boolean>) => void,
    regenerateProductColorStrength: GenerateStrength,
    setRegenerateProductColorStrength: (value: StateUpdater<GenerateStrength>) => void,
    regenerateProductPromptTemplate: PromptTemplate,
    setRegenerateProductPromptTemplate: (value: StateUpdater<PromptTemplate>) => void,
    regenerateProductInputImagePath: string | undefined,
    setRegenerateProductInputImagePath: (value: StateUpdater<string | undefined>) => void,
    regenerateProductResults: RegenerateProductResults,
    setRegenerateProductResults: (value: StateUpdater<RegenerateProductResults>) => void,
    regenerateProductNumImages: number,
    setRegenerateProductNumImages: (value: StateUpdater<number>) => void,
    regenerateProductErasedImagePath: string | undefined,
    setRegenerateProductErasedImagePath: (value: StateUpdater<string | undefined>) => void,
    regenerateProductEraseMaskImagePath: string | undefined,
    setRegenerateProductEraseMaskImagePath: (value: StateUpdater<string | undefined>) => void,
    regenerateRenderProcessController: RenderProcessController | undefined,
    setRegenerateRenderProcessController: (value: StateUpdater<RenderProcessController | undefined>) => void,
    regenerateProductReferenceImagePath: string | undefined,
    setRegenerateProductReferenceImagePath: (value: StateUpdater<string | undefined>) => void,
    regenerateProductCreativity: number,
    setRegenerateProductCreativity: (value: StateUpdater<number>) => void,
}

export function getDummyRegenerateProductEditorState(): RegenerateProductEditorState {
    return {
        regenerateProductRenderState: 'idle',
        setRegenerateProductRenderState: noop,
        regenerateProductCorrectColor: true,
        setRegenerateProductCorrectColor: noop,
        regenerateProductColorStrength: GenerateStrength.Default,
        setRegenerateProductColorStrength: noop,
        regenerateProductPromptTemplate: cloneDeep(emptyGenerateTemplate.prompt),
        setRegenerateProductPromptTemplate: noop,
        regenerateProductInputImagePath: undefined,
        setRegenerateProductInputImagePath: noop,
        regenerateProductResults: [],
        setRegenerateProductResults: noop,
        regenerateProductNumImages: 4,
        setRegenerateProductNumImages: noop,
        regenerateProductErasedImagePath: undefined,
        setRegenerateProductErasedImagePath: noop,
        regenerateProductEraseMaskImagePath: undefined,
        setRegenerateProductEraseMaskImagePath: noop,
        regenerateRenderProcessController: undefined,
        setRegenerateRenderProcessController: noop,
        regenerateProductReferenceImagePath: undefined,
        setRegenerateProductReferenceImagePath: noop,
        regenerateProductCreativity: 0.5,
        setRegenerateProductCreativity: noop,
    };
}

export function getDefaultRegenerateProductEditorState(
    set: SetEditorStateFunction,
): RegenerateProductEditorState {
    return {
        regenerateProductRenderState: 'idle',
        setRegenerateProductRenderState: getUpdaterFunction(set, 'regenerateProductRenderState'),
        regenerateProductCorrectColor: true,
        setRegenerateProductCorrectColor: getUpdaterFunction(set, 'regenerateProductCorrectColor'),
        regenerateProductColorStrength: GenerateStrength.Default,
        setRegenerateProductColorStrength: getUpdaterFunction(set, 'regenerateProductColorStrength'),
        regenerateProductPromptTemplate: cloneDeep(emptyGenerateTemplate.prompt),
        setRegenerateProductPromptTemplate: getUpdaterFunction(set, 'regenerateProductPromptTemplate'),
        regenerateProductInputImagePath: undefined,
        setRegenerateProductInputImagePath: getUpdaterFunction(set, 'regenerateProductInputImagePath'),
        regenerateProductResults: [],
        setRegenerateProductResults: getUpdaterFunction(set, 'regenerateProductResults'),
        regenerateProductNumImages: 4,
        setRegenerateProductNumImages: getUpdaterFunction(set, 'regenerateProductNumImages'),
        regenerateProductErasedImagePath: undefined,
        setRegenerateProductErasedImagePath: getUpdaterFunction(set, 'regenerateProductErasedImagePath'),
        regenerateProductEraseMaskImagePath: undefined,
        setRegenerateProductEraseMaskImagePath: getUpdaterFunction(set, 'regenerateProductEraseMaskImagePath'),
        regenerateRenderProcessController: undefined,
        setRegenerateRenderProcessController: getUpdaterFunctionWithCleanup(set, 'regenerateRenderProcessController', (controller) => controller?.destroy()),
        regenerateProductReferenceImagePath: undefined,
        setRegenerateProductReferenceImagePath: getUpdaterFunction(set, 'regenerateProductReferenceImagePath'),
        regenerateProductCreativity: 0.5,
        setRegenerateProductCreativity: getUpdaterFunction(set, "regenerateProductCreativity"),
    };
}

export function resetRegenerateProductEditorState(editorContextState: RegenerateProductEditorState) {
    editorContextState.setRegenerateProductRenderState('idle');
    editorContextState.setRegenerateProductCorrectColor(true);
    editorContextState.setRegenerateProductColorStrength(GenerateStrength.Default);
    editorContextState.setRegenerateProductPromptTemplate(cloneDeep(emptyGenerateTemplate.prompt));
    editorContextState.setRegenerateProductInputImagePath(undefined);
    editorContextState.setRegenerateProductResults([]);
    editorContextState.setRegenerateProductNumImages(4);
    editorContextState.setRegenerateProductErasedImagePath(undefined);
    editorContextState.setRegenerateProductEraseMaskImagePath(undefined);
    editorContextState.setRegenerateRenderProcessController(undefined);
    editorContextState.setRegenerateProductReferenceImagePath(undefined);
    editorContextState.setRegenerateProductCreativity(0.5);
}