import React from 'react';

export function useMaxCollisionHeight({
    margin = 10,
    elementRef,
}: {
    elementRef: { current: HTMLElement | null },
    margin?: number,
}) {
    const [maxHeight, setMaxHeight] = React.useState(0);

    const updateMaxHeight = React.useCallback(() => {
        if (!window || !elementRef.current) {
            console.log(`Element ref is invalid`);
            return;
        }
        const windowHeight = window.innerHeight;
        const boundingRect = elementRef.current.getBoundingClientRect();
        const { top } = boundingRect;
        setMaxHeight(Math.max(windowHeight - top - margin, margin));
    }, [elementRef, margin]);

    return {
        maxHeight,
        updateMaxHeight,
    }
}