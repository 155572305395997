export const CANVAS_ID = 'flair-editor-canvas';
export const CANVAS_CONTAINER_ID = `${CANVAS_ID}_container`;
export const GENERATION_FRAME_TOP_ID = 'generation-frame-top';
export const GENERATION_FRAME_BOTTOM_ID = 'generation-frame-bottom';

export const TRYON_CANVAS_ID = "tryon-editor-canvas"
export const TRYON_CANVAS_CONTAINER_ID = "tryon-editor-canvas-container"
export const TRYON_CLOTH_EDITOR_CONTAINER_ID = "tryon-cloth-editor-container"

export const REPLACE_PRODUCT_CANVAS_ID = "replace-product-canvas";

export const EDITOR_FLOAT_TAG_CONTAINER_ID = 'editor-float-tag-container';