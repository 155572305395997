import { classNames } from "core/utils/classname-utils";
import React from "react";
import styles from './loading-card.module.css';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { ImageComponentPrimitive } from "components/utils/image";

export const LoadingCardImage = ({
    className,
    style,
    defaultClassName = "w-full h-full",
    ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
    defaultClassName?: string,
}) => {
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    return (
        <>
            <ImageComponentPrimitive
                alt="loading card"
                {...props}
                isImageLoaded={isImageLoaded}
                setImageLoaded={setImageLoaded}
                className={className}
                style={{
                    ...style,
                    display: isImageLoaded ? 'block' : 'none',
                }}
            />
            <div
                className={classNames(
                    styles.Card,
                    isImageLoaded ? '' : styles.IsLoading,
                    defaultClassName,
                )}
                style={{
                    display: isImageLoaded ? 'none' : 'block',
                }}
            />
        </>
    )
};

export const LoadingCardImageAspectRatio = React.forwardRef(({
    className,
    imageProps,
    ...props
}: AspectRatio.AspectRatioProps & {
    imageProps: React.ImgHTMLAttributes<HTMLImageElement>,
}, forwardedRef: React.Ref<HTMLDivElement>) => {
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    return (
        <AspectRatio.Root
            ref={forwardedRef}
            className={classNames(
                styles.Card,
                isImageLoaded ? '' : styles.IsLoading,
                className ?? '',
            )}
            {...props}
        >
            <ImageComponentPrimitive
                alt="loading card"
                {...imageProps}
                style={{
                    display: isImageLoaded ? 'block' : 'none',
                }}
                isImageLoaded={isImageLoaded}
                setImageLoaded={setImageLoaded}
            />
        </AspectRatio.Root>
    )
});