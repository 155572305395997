import React from "react"
import { Navigate } from "./components/navigate";
import { classNames } from "core/utils/classname-utils"
import { editorContextStore } from "contexts/editor-context";
import { InputBoxClassName, PrimaryButtonClassName, SecondaryButtonClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from "components/constants/class-names"
import { TryOnPromptType } from "core/common/types";
import { cleanupPrompt } from "components/utils/prompt";
import { removeLineBreaks } from "core/utils/string-utils";
import { RefreshCcw } from "lucide-react";
import { generateClothPrompt } from "contexts/tryon-editor-context";
import { ProgressBar } from "./components/progress-bar";
import { Download } from "lucide-react"
import { downloadImageDataUrl } from "components/utils/data";
import { displayUiMessage } from "components/utils/display-message";


function TryOnRenderButton() {
    const tryOnEditorState = editorContextStore(state => state.tryOnEditorState);
    const tryOnRenderProgress = editorContextStore(state => state.tryOnRenderProgress);

    const isRendering = tryOnEditorState === 'rendering';

    const [isError, setIsError] = React.useState(false);

    const onClick = React.useCallback(() => {
        const {
            editor,
            backend,
            tryOnEditorState,
            tryOnEditorController,
            setTryOnEditorState,
            setTryOnRenderProgress,
            tryOnWarpedClothImageElement,
            tryOnWarpedHumanMaskImageElement,
        } = editorContextStore.getState();

        const tryOnPersonCanvasController = tryOnEditorController?.tryOnPersonCanvasControllerRef.current;

        if (!editor || !backend || !tryOnPersonCanvasController) {
            return;
        }

        if (tryOnEditorState !== 'idle') {
            return;
        }

        if (!tryOnWarpedClothImageElement || !tryOnWarpedHumanMaskImageElement) {
            return;
        }

        tryOnPersonCanvasController.renderClothImage()
            ?.catch((error) => {
                console.error(error);

                setIsError(true);

                return new Promise<void>((resolve) => {

                    setTimeout(() => {

                        setIsError(false);
                        resolve();

                    }, 1000);
                })


            }).finally(() => {
                setTryOnRenderProgress(1);
                setTryOnEditorState('idle');
            });

    }, []);

    if (isRendering) {
        return (
            <ProgressBar
                className="w-full pointer-events-auto"
                progress={tryOnRenderProgress}
                isError={isError}
            />
        );
    }

    return (
        <button
            className={classNames(
                PrimaryButtonClassName,
                'flex flex-row items-center justify-center'
            )}
            onClick={onClick}
        >
            Generate
        </button>
    );
}

function useTryOnPrompt(promptType: TryOnPromptType) {
    if (promptType === 'cloth') {
        return {
            prompt: editorContextStore(state => state.tryOnClothPrompt),
            setPrompt: editorContextStore(state => state.setTryOnClothPrompt),
        }
    }
    if (promptType === 'model') {
        return {
            prompt: editorContextStore(state => state.tryOnModelPrompt),
            setPrompt: editorContextStore(state => state.setTryOnModelPrompt),
        }
    }
    if (promptType === 'background') {
        return {
            prompt: editorContextStore(state => state.tryOnBackgroundPrompt),
            setPrompt: editorContextStore(state => state.setTryOnBackgroundPrompt),
        }
    }
    return {
        prompt: editorContextStore(state => state.tryOnClothPrompt),
        setPrompt: editorContextStore(state => state.setTryOnClothPrompt),
    }
}



function getIdFromPromptType(promptType: TryOnPromptType) {
    return `tryon-${promptType}-prompt-input-div`;
}

function getInputFieldId(id: string) {
    return `${id}-input`;
}

function TryOnPromptInputField({
    promptType,
    id,
    inputId,
    className = "",
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    inputId?: string,
    promptType: TryOnPromptType
}) {
    id = id || getIdFromPromptType(promptType);
    inputId = inputId || getInputFieldId(id);

    const {
        prompt,
        setPrompt,
    } = useTryOnPrompt(promptType);

    const [inputValue, setInputValue] = React.useState(prompt);

    React.useEffect(() => {
        setInputValue(prompt);
    }, [prompt]);


    return (
        <div
            {...props}
            id={id}
            className={classNames(
                "flex flex-col",
                className,
            )}
        >
            {children}
            <input
                id={inputId}
                className={InputBoxClassName}
                value={inputValue}
                type="text"
                onChange={(e) => {
                    setInputValue(removeLineBreaks(e.currentTarget.value));
                }}
                onBlur={(e) => {
                    setPrompt(cleanupPrompt(e.currentTarget.value));
                }}
            />
        </div>
    );
}

function TryOnPromptInput({
    promptType,
    labelContent,
    id,
    inputId,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    inputId?: string,
    promptType: TryOnPromptType,
    labelContent: React.ReactNode,
}) {
    id = id || getIdFromPromptType(promptType);
    inputId = inputId || getInputFieldId(id);

    return (
        <TryOnPromptInputField
            promptType={promptType}
            id={id}
            inputId={inputId}
            {...props}
        >
            <label
                htmlFor={inputId}
                className="mb-2 text-zinc-300"
            >
                {labelContent}
            </label>
        </TryOnPromptInputField>
    );
}

function TryOnClothPromptInput(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const id = getIdFromPromptType('cloth');
    const inputId = getInputFieldId(id);
    const tryOnClothPromptState = editorContextStore(state => state.tryOnClothPromptState);
    const isGenerating = tryOnClothPromptState === 'generating';
    return (
        <TryOnPromptInputField
            promptType='cloth'
            id={id}
            inputId={inputId}
            {...props}
        >
            <div className="w-full flex flex-row items-center justify-center">
                <label
                    htmlFor={inputId}
                    className="mb-2 text-zinc-300"
                >
                    Cloth
                </label>
                <div className="flex-1" />
                <button
                    className={classNames(
                        "flex flex-row items-center justify-start text-xs  transition-colors truncate",
                        isGenerating ? "text-zinc-600 cursor-wait" : "text-zinc-500  hover:text-lime-500 active:text-lime-800 cursor-pointer"
                    )}
                    onClick={() => {
                        if (isGenerating) {
                            return;
                        }

                        console.log(`Clicked auto caption`);

                        generateClothPrompt();
                    }}
                >
                    <RefreshCcw
                        width={12}
                        height={12}
                        className={classNames(
                            'mr-1',
                            isGenerating ? 'animate-spin' : '',
                        )}
                    />
                    {isGenerating ? "Generating" : "Auto caption"}
                </button>
            </div>
        </TryOnPromptInputField>
    );
}

function TryOnModelPromptInput(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <TryOnPromptInput
            promptType='model'
            labelContent="Model"
            {...props}
        />
    );
}

function TryOnBackgroundPromptInput(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <TryOnPromptInput
            promptType='background'
            labelContent="Background"
            {...props}
        />
    );
}

enum DownloadButtonState {
    Idle = 'idle',
    Downloading = 'downloading',
    Inactive = 'inactive',
}

function DownloadButton() {
    const imageUrls = editorContextStore(state => state.tryOnRenderResults);

    const [buttonState, setButtonState] = React.useState(DownloadButtonState.Inactive);

    React.useEffect(() => {
        setButtonState(
            imageUrls?.length > 0 ? DownloadButtonState.Idle : DownloadButtonState.Inactive
        );
    }, [imageUrls]);

    return (
        <button
            className={classNames(
                buttonState === DownloadButtonState.Inactive ?
                    SecondaryButtonClassNameDisabled :
                    buttonState === DownloadButtonState.Downloading ? SecondaryButtonClassName : SecondaryButtonClassNameInactive,
                buttonState === DownloadButtonState.Downloading ? 'cursor-wait' : '',
                'flex flex-row justify-center text-center transition-colors',
            )}
            onClick={() => {
                if (buttonState !== DownloadButtonState.Idle) {
                    return;
                }

                setButtonState(DownloadButtonState.Downloading);

                Promise.all(imageUrls.map(({ imageUrl }, index) => {
                    return downloadImageDataUrl(imageUrl, `fashion-result-${index}`);
                })).catch((error) => {
                    console.error(error);
                    displayUiMessage("Cannot download fashion output images.", 'error');
                }).finally(() => {
                    setButtonState(DownloadButtonState.Idle);
                });
            }}
        >
            <Download
                size={18}
                className="mr-2"
            />
            <span>
                {buttonState === DownloadButtonState.Downloading ? "Downloading ..." : "Download result image"}
            </span>
        </button>
    )
}

export function TryOnRender() {

    const setActiveLeftPanels = editorContextStore(state => state.setActiveLeftPanels);

    React.useEffect(() => {

        // Make sure that the previous panel of is "TryOnSelectPose"

        setActiveLeftPanels((prevPanels) => {
            if (prevPanels.length < 1) {
                return prevPanels;
            }

            if (prevPanels.length === 1) {
                return [
                    'TryOnSelectPose',
                    prevPanels[0],
                ]
            }

            if (prevPanels[prevPanels.length - 2] !== 'TryOnSelectPose') {
                const newPanels = [...prevPanels];
                newPanels[prevPanels.length - 2] = 'TryOnSelectPose';
                return newPanels;
            }

            return prevPanels;
        });
    }, [setActiveLeftPanels]);

    return (
        <div
            className="flex flex-col gap-4 pt-2"
        >
            <Navigate>
                <span
                    className="text-zinc-500 group-hover:text-lime-800 mr-2 transition-colors"
                >
                    Step 3:
                </span>
                <span>
                    Edit Prompt & Generate
                </span>
            </Navigate>
            <TryOnClothPromptInput />
            <TryOnModelPromptInput />
            <TryOnBackgroundPromptInput />
            <TryOnRenderButton />
            <DownloadButton />
        </div>
    )

}