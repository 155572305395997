import { isValidHttpUrl } from 'core/utils/string-utils';
import { isDataURL } from 'core/utils/string-utils';
import {
    EditorAssetExtension,
    EditorAssetContentType,
} from 'core/common/types';

export function getEditorAssetExtension(contentType: EditorAssetContentType) {
    return EditorAssetExtension[contentType];
}

export function isEditorAssetContentTypeValid(contentType: string): contentType is EditorAssetContentType {
    return Object.keys(EditorAssetExtension).findIndex(t => t === contentType) > -1;
}

export function isHeicImageType(contentType: string) {
    return contentType.toLowerCase().includes("image/heic");
}

export function getDataUrlFromBlob(blob: Blob | undefined | null) {
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export async function getDataUrlFromString(data: string) {
    if (!data) {
        return '';
    }
    if (isDataURL(data)) {
        return data;
    } else if (isValidHttpUrl(data)) {
        const object = await fetch(data).then(r => r.blob());
        return getDataUrlFromBlob(object);
    }
    return '';
}