import { fabric } from "fabric"
import { Backend } from "backend/base";
import { Editor } from "core/editor";
import { getRawDataUrlFromImageObject } from "core/utils/image-utils";
import { isStaticImageObjectGenerated } from "core/utils/type-guards";
import { DEFAULT_RENDER_LENGTH } from "core/common/constants";
import { editorContextStore } from "contexts/editor-context";
import { UpscaleModelType } from "core/common/types/upscale";
import { AppUserSubscriptionTier } from "core/common/types";
import { RenderJobController } from "core/common/interfaces";
import { debugLog } from "core/utils/print-utilts";
import { getMaskImageFromPastGeneration } from "core/utils/past-generation-utils";

async function upscaleDefaultImageObject(
    modelType: UpscaleModelType,
    userSubscriptionTier: AppUserSubscriptionTier | undefined,
    renderProcessController: RenderJobController,
    object: fabric.StaticImage,
    backend: Backend,
    onError: (error: Error) => void,
    upscale?: 2 | 4,
    prompt?: string,
) {
    const imageUrl = await getRawDataUrlFromImageObject({
        object,
    });

    if (!imageUrl) {
        onError(new Error('Image url is invalid.'));
        return;
    }

    return await backend.upscaleImage({
        modelType,
        userSubscriptionTier,
        upscale,
        imageUrl,
        onError,
        renderProcessController,
        prompt,
    });
}

async function upscaleGeneratedImageObject(
    editor: Editor,
    modelType: UpscaleModelType,
    userSubscriptionTier: AppUserSubscriptionTier | undefined,
    renderProcessController: RenderJobController,
    object: fabric.StaticImage,
    backend: Backend,
    onError: (error: Error) => void,
    upscale?: 2 | 4,
) {
    try {
        const pastGenerations = editorContextStore.getState().pastGenerations;

        if (!pastGenerations) {

            debugLog(`Past generation is invalid for image ${object.id}`);

            return await upscaleDefaultImageObject(
                modelType,
                userSubscriptionTier,
                renderProcessController,
                object,
                backend,
                onError,
                upscale,
            );
        }

        const generationId = object.generationId;
        if (!generationId) {
            debugLog(`Past generation id is invalid for image ${object.id}`);

            return await upscaleDefaultImageObject(
                modelType,
                userSubscriptionTier,
                renderProcessController,
                object,
                backend,
                onError,
                upscale,
            );
        }
        const generation = pastGenerations[generationId] || (await backend.getPastGeneration({ generationId }));
        const prompt = generation.prompt;

        const inputImagePath = generation.inputImagePath;

        if (!inputImagePath) {
            debugLog(`Input image path is invalid for image ${object.id}`);

            return await upscaleDefaultImageObject(
                modelType,
                userSubscriptionTier,
                renderProcessController,
                object,
                backend,
                onError,
                upscale,
                prompt
            );
        }
        const imageWidth = object.width || DEFAULT_RENDER_LENGTH;
        const imageHeight = object.height || DEFAULT_RENDER_LENGTH;
        const imageScale = DEFAULT_RENDER_LENGTH / Math.max(imageWidth, imageHeight);
        const targetWidth = Math.round(imageScale * imageWidth);
        const targetHeight = Math.round(imageScale * imageHeight);

        const [
            imageUrl,
            inputImageUrl,
        ] = await Promise.all([
            getRawDataUrlFromImageObject({
                object,
                width: targetWidth,
                height: targetHeight,
            }),
            getMaskImageFromPastGeneration({
                editor,
                backend,
                pastGeneration: generation,
            }),
        ]);

        if (!imageUrl) {
            onError(new Error('Image is invalid'))
            return;
        }

        try {

            // const compositeInputImageUrl = await maskImageWithAlpha(imageUrl, inputImageUrl);
            // debugLog(Boolean(compositeInputImageUrl) ? `Valid composite image.` : `Invalid composite image.`);

            return await backend.upscaleImage({
                modelType,
                userSubscriptionTier,
                upscale,
                imageUrl,
                onError,
                renderProcessController,
                // inputImageUrl: compositeInputImageUrl,
                inputImageUrl,
                prompt,
            });

        } catch (error) {

            console.error(error);

            return await upscaleDefaultImageObject(
                modelType,
                userSubscriptionTier,
                renderProcessController,
                object,
                backend,
                onError,
                upscale,
                prompt,
            );

        }

    } catch (error) {
        console.error(error);
        onError(error as Error);
    }
}

export async function upscaleImageObject({
    modelType,
    object,
    editor,
    backend,
    onError,
    upscale = 2,
    renderProcessController,
}: {
    modelType: UpscaleModelType,
    object: fabric.StaticImage,
    editor: Editor,
    backend: Backend,
    onError: (error: Error) => void,
    upscale?: 2 | 4,
    renderProcessController: RenderJobController,
}) {
    try {

        if (!object) {
            onError(new Error('Selected object is invalid.'));
            return;
        }

        const {
            userQuotas,
        } = editorContextStore.getState();

        const outputUrl =
            isStaticImageObjectGenerated(object) ?
                await upscaleGeneratedImageObject(
                    editor,
                    modelType,
                    userQuotas?.tier,
                    renderProcessController,
                    object,
                    backend,
                    onError,
                    upscale,
                ) :
                await upscaleDefaultImageObject(
                    modelType,
                    userQuotas?.tier,
                    renderProcessController,
                    object,
                    backend,
                    onError,
                    upscale,
                );

        if (!outputUrl) {
            return;
        }

        const objectWidth = object.getScaledWidth() || 100;

        const location = object.getCenterPoint() || new fabric.Point(0, 0);
        location.setX(location.x + objectWidth);

        const outputImage = await editor.objects.addImageFromUrl({
            url: outputUrl,
            location,
            uploadStorage: true,
            targetWidth: objectWidth,
        });

        if (!outputImage) {
            return;
        }

    } catch (error) {

        onError(error as Error);

    }
}