import React from 'react';
import { Editor } from 'core/editor';
import {
    StaticImageElement2dType,
    StaticImageElementType,
    setStaticImageElement2dType,
    staticImageElementTypeToDisplayName,
} from 'core/common/types/elements';
import { SelectOptionValue } from 'components/utils/select-options';


export function setDropdownImageElement2dType(
    editor: Editor | null,
    object: fabric.Object | fabric.ActiveSelection,
    type: StaticImageElement2dType,
) {
    setStaticImageElement2dType(
        editor,
        object,
        type,
    );
}

export const imageElement2dTypeDropdownOptions = {
    "Image Type": [
        {
            name: String(StaticImageElement2dType.Asset),
            value: StaticImageElement2dType.Asset,
        },
        {
            name: String(StaticImageElement2dType.PreserveShape),
            value: StaticImageElement2dType.PreserveShape,
        },
        {
            name: String(StaticImageElement2dType.PreserveColor),
            value: StaticImageElement2dType.PreserveColor,
        },
        {
            name: String(StaticImageElement2dType.PreserveColorAndShape),
            value: StaticImageElement2dType.PreserveColorAndShape,
        },
    ]
};

export function DropdownImageElement2dTypeValue<T = StaticImageElement2dType>({
    staticImageObjectType,
}: {
    staticImageObjectType: T,
}) {
    return (
        <SelectOptionValue>
            <span className='truncate'>
                {staticImageObjectType ? (
                    String(staticImageObjectType) || 'None'
                ) : 'None'}
            </span>
        </SelectOptionValue>
    );
}
