import React from "react"
import { NavBarContainer } from "components/navbar/navbar-container"
import { NavBarUserProfile } from "components/navbar/user-profile";
import { Logo } from "components/logo";
import { ChevronDown, ChevronLeft } from "lucide-react";
import { NavBarUpgrade } from "components/navbar/upgrade";
import { useNavigate } from "react-router-dom";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import {NavbarDropdownContent, NavbarDropdownItem, NavbarDropdownItemRightSlot} from 'components/navbar/navbar-dropdown';

export function CreateCustomModelNavbar() {
    const navigate = useNavigate();
    return (
        <NavBarContainer>

            <div className="px-3">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger className="px-3">
                            <Logo />
                        </DropdownMenu.Trigger>
                        <NavbarDropdownContent
                            sideOffset={18}
                        >
                            <NavbarDropdownItem
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                <ChevronLeft
                                    size={16}
                                />
                                <span
                                    className="ml-3"
                                >
                                    Back to projects
                                </span>
                            </NavbarDropdownItem>
                        </NavbarDropdownContent>
                    </DropdownMenu.Root>
            </div>
            <div className="flex-1 ml-8 flex flex-row justify-center items-center text-sm text-zinc-500">
            </div>
            <div className="flex flex-row items-center justify-center gap-2.5">
                <NavBarUpgrade />
                <NavBarUserProfile />
            </div>
        </NavBarContainer>
    );
}