import * as Tabs from "@radix-ui/react-tabs"
import { FloatTagZIndex } from "components/constants/zIndex";
import { editorContextStore } from "contexts/editor-context";
import { classNames } from "core/utils/classname-utils"
import React from "react"

export function setTabsListStickyScrollVertical({
    tabsRootRef,
    tabsListRef,
    isStickyRef,
    tabsListPlaceholderRef,
    scrollAreaBoundingRect,
}: {
    tabsRootRef: React.MutableRefObject<HTMLDivElement | null>,
    tabsListRef: React.MutableRefObject<HTMLDivElement | null>,
    isStickyRef: React.MutableRefObject<boolean>,
    tabsListPlaceholderRef: React.MutableRefObject<HTMLDivElement | null>,
    scrollAreaBoundingRect: DOMRect,
}) {
    if (!tabsRootRef?.current || !tabsListRef?.current || !tabsListPlaceholderRef.current || !scrollAreaBoundingRect) {
        return;
    }
    const containerTop = scrollAreaBoundingRect.top;
    const tabsRootBoundingRect = tabsRootRef.current.getBoundingClientRect();
    const top = tabsRootBoundingRect.top;

    const isSticky = top - containerTop <= 0;

    if (isStickyRef.current !== isSticky) {
        isStickyRef.current = isSticky;
        tabsListRef.current.style.position = isSticky ? 'fixed' : 'static';
        tabsListRef.current.style.left = tabsRootBoundingRect.left + 'px';
        tabsListRef.current.style.width = isSticky ? tabsRootBoundingRect.width + 'px' : 'auto';
        tabsListRef.current.style.top = containerTop + 'px';
        tabsListPlaceholderRef.current.style.display = isSticky ? "block" : "none";
    }
}

export function updateTabsListPosition({
    isStickyRef,
    tabsRootRef,
    tabsListRef,
    tabsListPlaceholderRef,
}: {
    isStickyRef: React.MutableRefObject<boolean>,
    tabsRootRef: React.MutableRefObject<HTMLDivElement | null>,
    tabsListRef: React.MutableRefObject<HTMLDivElement | null>,
    tabsListPlaceholderRef: React.MutableRefObject<HTMLDivElement | null>,
}) {
    if (!tabsRootRef?.current?.parentElement || !tabsListRef?.current || !tabsListPlaceholderRef.current) {
        return;
    }
    const isSticky = Boolean(isStickyRef.current);
    const tabsRootBoundingRect = tabsRootRef.current.parentElement.getBoundingClientRect();
    tabsListRef.current.style.position = isSticky ? 'fixed' : 'static';
    tabsListRef.current.style.left = tabsRootBoundingRect.left + 'px';
    tabsListRef.current.style.width = isSticky ? tabsRootBoundingRect.width + 'px' : 'auto';
    tabsListRef.current.style.top = tabsRootBoundingRect.top + 'px';
    tabsListPlaceholderRef.current.style.display = isSticky ? "block" : "none";
}

export function useTabsListPlaceholder({
    tabsListRef,
    tabsListPlaceholderRef,
}: {
    tabsListRef: React.MutableRefObject<HTMLDivElement | null>,
    tabsListPlaceholderRef?: React.MutableRefObject<HTMLDivElement | null>,
}) {
    const promptTemplate = editorContextStore(state => state.generateToolPromptTemplate);

    React.useEffect(() => {
    }, [promptTemplate]);

    React.useEffect(() => {
        if (!tabsListRef.current || !tabsListPlaceholderRef?.current) {
            return;
        }
        const {
            width = 0,
            height = 0,
        } = tabsListRef.current.getBoundingClientRect();
        tabsListPlaceholderRef.current.style.width = width + 'px';
        tabsListPlaceholderRef.current.style.height = height + 'px';
    }, []);
}

export const LeftPanelTabs = React.forwardRef(<T extends string>({
    tabs,
    activeTab,
    className,
    setActiveTab,
    triggerProps,
    contentProps,
    tabsRootRef,
    tabsListRef,
    tabsListPlaceholderRef,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    tabsRootRef?: React.ForwardedRef<HTMLDivElement>,
    tabsListRef?: React.ForwardedRef<HTMLDivElement>,
    tabsListPlaceholderRef?: React.ForwardedRef<HTMLDivElement>,
    tabs: Record<string, React.ReactNode>
    activeTab: T,
    setActiveTab: (value: T) => void,
    triggerProps?: Partial<Tabs.TabsTriggerProps>,
    contentProps?: Partial<Tabs.TabsContentProps>,
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <div
            ref={forwardedRef}
            {...props}
            className={`${className}`}
        >
            <Tabs.Root
                ref={tabsRootRef}
                value={activeTab}
                // @ts-ignore
                onValueChange={setActiveTab}
            >
                {tabsListPlaceholderRef && <div ref={tabsListPlaceholderRef} style={{
                    display: 'none',
                }} />}
                <Tabs.List
                    ref={tabsListRef}
                    className={`grid grid-cols-${Object.keys(tabs).length} gaps-2 shadow-md bg-zinc-900`}
                    style={{
                        zIndex: FloatTagZIndex,
                    }}
                >
                    {Object.keys(tabs).map(tab => (
                        <Tabs.Trigger
                            key={tab}
                            value={tab}
                            {...triggerProps}
                            className={classNames(
                                'py-2 text-center hover:text-lime-500 active:text-lime-600 focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b transition-colors',
                                tab === activeTab ?
                                    'border-zinc-500 hover:border-lime-500 text-zinc-300' : 'border-zinc-800 text-zinc-400',
                                triggerProps?.className ?? '',
                            )}
                        >
                            {tab}
                        </Tabs.Trigger>
                    ))}
                </Tabs.List>
                {
                    Object.entries(tabs).map(([tab, node]) => (
                        <Tabs.Content
                            key={tab}
                            value={tab}
                            {...contentProps}
                        >
                            {node}
                        </Tabs.Content>
                    ))
                }
            </Tabs.Root>
        </div>
    )
})