
import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { getApiDashboardModelUrl } from "core/common/types/api";


function replaceUrl(url: string) {
    window?.history.replaceState(null, '', url);
}

export function useDashboardModelNavigateEffect() {

    const dashboardType = editorContextStore(state => state.dashboardType);

    const apiDashboardModelType = editorContextStore(state => state.apiDashboardModelType);

    React.useEffect(() => {
        if (dashboardType === 'manage-api') {

            const url = getApiDashboardModelUrl(apiDashboardModelType);

            replaceUrl(url);

        } else if (dashboardType === 'projects') {

            replaceUrl('/');

        }

    }, [
        dashboardType,
        apiDashboardModelType,
    ]);
}