import React, { useState } from "react"
import { StepByStepsTutorialConfig } from "../config"
import { Highlighter } from "../highlighter";
import { inlineButtonClassName } from "./constants";

function CreateNewProject() {
    const [highlightId, setHighlightId] = React.useState<string | null>('create-new-project-card');

    React.useEffect(() => {
        setHighlightId('create-new-project-card');
    }, []);

    return (
        <div
            className=""
            onPointerOver={() => {
                setHighlightId('create-new-project-card');
            }}
        >
            {highlightId && <Highlighter type="box" elementId={highlightId} />}
            Thank you for trying out Flair!<br /><br />
            Click the <span className={inlineButtonClassName}>Create New Project</span> button to start your first design project.<br />
        </div>
    );
}

export const createNewProjectConfig: StepByStepsTutorialConfig = {
    name: 'Create New Project Tutorial',
    hasWelcome: true,
    hasEnding: false,
    steps: [
        {
            title: 'Getting started',
            Content: CreateNewProject,
        },
    ],
    heightRatio: 0.5,
}