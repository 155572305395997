import React from "react"
import { InputBoxClassName, InputUnderscoreClassName, SecondaryButtonClassName, SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { classNames } from "core/utils/classname-utils";



function LeftPanelSectionContainerTitle({
    label,
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    label: string,
}) {
    return (
        <div
            {...props}
            className={`flex flex-row items-center my-2 ${className}`}
        >
            <div
                className="grow font-semibold"
            >
                {label}
            </div>
            <div
                className={`${SecondaryButtonClassNameInactive}`}
            >
                Edit
            </div>
        </div>
    )
}

export function LeftPanelSectionContainer({
    label,
    children,
    className,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    label?: string,
}) {
    return (
        <div
            className='flex flex-col'
        >
            {label && <div
                className="font-semibold mb-2"
            >
                {label}
            </div>}
            <div
                className={classNames(
                    'flex-1 flex flex-col',
                    className ?? '',
                )}
                {...props}
            >
                {children}
            </div>
        </div>
    )
}