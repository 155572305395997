import { getUpdaterFunction, SetEditorStateFunction } from 'contexts/editor-context-utils';
import { PublicUserId, PublicUserRoles, StateUpdater } from 'core/common/types';
import { Timestamp } from 'firebase/firestore';
import { noop } from 'lodash';

export type CustomModelEditorTab = 'data' | 'train' | 'play';

export type CustomModelDatasetItemStatus = 'queued' | 'processing' | 'finished';

export type CustomModelDataset = Record<string, CustomModelDatasetItem>;

export enum CustomModelType {
    Product = "Product",
    Fashion = "Fashion",
    Style = "Style",
    Face = "Face",
    Custom = "Custom",
}

export function isCustomModelType(value: any): value is CustomModelType {
    return Object.values(CustomModelType).includes(value);
}

export interface CustomModelInfo {
    id: string,
    customModelType?: CustomModelType,
    displayName?: string,
    timeCreated: Timestamp,
    timeModified: Timestamp,
    isDeleted: boolean,
    roles: PublicUserRoles,
    thumbnailStoragePath?: string,
    defaultPredictionItem?: Pick<
        CustomModelPredictionItem,
        "input" | "output" | "usedModels"
    >,
}

export function isCustomModelInfo(obj: any): obj is CustomModelInfo {
    return (
        obj != null &&
        typeof obj.id === "string" &&
        (typeof obj.displayName === "undefined" || typeof obj.displayName === "string") &&
        typeof obj.roles === "object" &&
        obj.roles != null
    );
}

export interface CustomModelDatasetItem {
    id: string,
    caption?: string,
    storagePath: string,
    timeCreated: Timestamp,
    timeModified: Timestamp,
}

export function isCustomModelDatasetItem(obj: any): obj is CustomModelDatasetItem {
    return (
        typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "string" &&
        typeof obj.storagePath === "string"
    );
}


export enum CustomModelTrainingStatus {
    Starting = "starting",
    Processing = "processing",
    Succeeded = "succeeded",
    Failed = "failed",
    Canceled = "canceled"
}

const activeCustomModelTrainingStatuses = new Set([
    CustomModelTrainingStatus.Processing,
    CustomModelTrainingStatus.Starting,
]);

export function isCustomModelTrainingStatusActive(status?: CustomModelTrainingStatus) {
    return status && activeCustomModelTrainingStatuses.has(status);
}

export enum CustomModelTrainingBackendType {
    Replicate = "R",
    Fal = "F",
}

export interface CustomModelTrainingInputFalSpecific {
    backendType: CustomModelTrainingBackendType.Fal,
    trigger_word: string,
    iter_multiplier?: number,
    is_style?: boolean,
    is_input_format_already_preprocessed?: boolean,
    modelId?: string,
    trainingStrengthPercent: number,
}

export function isCustomModelTrainingInputFalSpecific(obj: any): obj is CustomModelTrainingInputFalSpecific {
    return obj != null &&
        typeof obj.trigger_word === "string";
}

export interface CustomModelTrainingInputReplicateSpecific {
    backendType: CustomModelTrainingBackendType.Replicate,
    steps: number,
    batch_size: number,
    autocaption: boolean,
    trigger_word: string,
    learning_rate: number,
    input_images?: string,
    modelId?: string,
    trainingId?: string,
    trainingStrengthPercent: number,
}

export function isCustomModelTrainingInputReplicateSpecific(obj: any): obj is CustomModelTrainingInputReplicateSpecific {
    return obj != null &&
        typeof obj.steps === "number" &&
        typeof obj.batch_size === "number" &&
        typeof obj.autocaption === "boolean" &&
        typeof obj.trigger_word === "string" &&
        typeof obj.learning_rate === "number";
}


export type CustomModelTrainingInput = CustomModelTrainingInputReplicateSpecific | CustomModelTrainingInputFalSpecific;

export function getDefaultCustomModelTrainingInput(): CustomModelTrainingInput {
    return {
        backendType: CustomModelTrainingBackendType.Replicate,
        steps: 1000,
        batch_size: 1,
        autocaption: true,
        trigger_word: "TOK",
        learning_rate: 0.0004,
        trainingStrengthPercent: 0.5,
    };
}


export type CustomModelScaleConfig = {
    scale: number,
    modelId: string,
    trainingId: string,
}

export type CustomModelScaleConfigs = Record<string, CustomModelScaleConfig>;

export interface CustomModelPlaygroundPromptEditorState {
    json: string | null,
    text: string,
    scaleConfigs: CustomModelScaleConfigs,
}

export enum CustomModelPredictionInputBackendType {
    SelfHost = "S",
    Fal = "F",
}

export interface ImageSizeFalType {
    width: number;
    height: number;
}

export enum OutputFormatFalType {
    JPEG = "jpeg",
    PNG = "png",
}

export interface CustomModelPredictionInputFal {
    backendType: CustomModelPredictionInputBackendType.Fal,
    scaleConfigs: CustomModelScaleConfigs;
    prompt: string;
    promptJson?: string;
    promptEditorState?: string;
    seed?: number;
    sync_mode?: boolean;
    image_size?: ImageSizeFalType;
    num_images?: number;
    output_format?: OutputFormatFalType;
    guidance_scale?: number;
    num_inference_steps?: number;
    enable_safety_checker?: boolean;
}

export interface CustomModelPredictionInputSelfHost {
    backendType: CustomModelPredictionInputBackendType.SelfHost,
    width: number;
    height: number;
    prompt: string;
    promptJson?: string;
    promptEditorState?: string;
    lora_scale?: number;
    num_outputs: number;
    aspect_ratio?: string;
    output_format?: string;
    guidance_scale: number;
    output_quality?: number;
    num_inference_steps: number;
    scale_configs?: CustomModelScaleConfigs;
}

export function isCustomModelPredictionInputSelfHost(
    input: any,
): input is CustomModelPredictionInputSelfHost {
    return input &&
        typeof(input.prompt) === "string" &&
        typeof(input.width) === "number" &&
        typeof(input.height) === "number" &&
        typeof(input.num_inference_steps) === "number";
}

export type CustomModelPredictionInput = CustomModelPredictionInputFal | CustomModelPredictionInputSelfHost;

export function getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(
    customModelInput: CustomModelPredictionInput,
): CustomModelPredictionInputSelfHost {
    if (isCustomModelPredictionInputSelfHost(customModelInput)) {
        return customModelInput;
    }

    return {
        backendType: CustomModelPredictionInputBackendType.SelfHost,
        width: customModelInput.image_size?.width ?? 1024,
        height: customModelInput.image_size?.height ?? 1024,
        prompt: customModelInput.prompt,
        promptJson: customModelInput.promptJson,
        promptEditorState: customModelInput.promptEditorState,
        num_outputs: customModelInput.num_images ?? 1,
        num_inference_steps: customModelInput.num_inference_steps ?? 28,
        guidance_scale: customModelInput.guidance_scale ?? 3.5,
        scale_configs: customModelInput.scaleConfigs,
    };
}

export enum CustomModelAction {
    CreateNewModel = "CreateNewModel",
    StartTraining = "StartTraining",
    StopTraining = "StopTraining",
    StartPrediction = "StartPrediction",
    StopPrediction = "StopPrediction",
}


export interface HandleCreateCustomModelArgs {
    type: CustomModelAction.CreateNewModel,
    displayName?: string,
    customModelType: CustomModelType,
}

export type HandleCreateCustomModelResponse = {
    ok: false,
    message: string,
} | {
    ok: true,
    id: string,
    displayName: string,
    customModelInfo: CustomModelInfo,
}

export type HandleCustomModelTrainingStopArgs = {
    type: CustomModelAction.StopTraining,
    modelId: string,
    trainingId: string,
};

export type HandleCustomModelTrainingStopResponse = {
    ok: boolean,
    message: string,
}

export interface HandleCustomModelTrainingStartArgs {
    type: CustomModelAction.StartTraining,
    modelId: string,
    trainingInput: CustomModelTrainingInput,
}

export type HandleCustomModelTrainingStartResponse = {
    ok: false,
    message: string,
} | {
    ok: true,
    newTrainingId: string,
}

export type HandleStartCustomModelPredictionArgs = {
    type: CustomModelAction.StartPrediction,
    input: Partial<CustomModelPredictionInput>,
}

export type HandleStartCustomModelPredictionResponse = {
    ok: false,
    message: string,
} | {
    ok: true,
    message: string,
    predictionId: string,
}

export type HandleStopCustomModelPredictionArgs = {
    // userId: string,
    type: CustomModelAction.StopPrediction,
    modelId: string,
    predictionId: string,
};

export type HandleStopCustomModelPredictionResponse = {
    ok: boolean,
    message: string,
};


export interface CustomModelTrainingItem {
    id: string,
    jobId?: string,
    modelId?: string,
    displayName?: string,
    caption?: string,
    status: CustomModelTrainingStatus,
    input: CustomModelTrainingInput,
    zipFileStoragePath?: string,
    timeCreated: Timestamp,
    timeModified: Timestamp,
    progress: number,
}

export function isCustomModelTrainingItem(item: any): item is CustomModelTrainingItem {
    return (
        item &&
        typeof(item.id) === 'string' &&
        item.status &&
        item.input
    );
}

export function getModelTriggerWord({
    modelId,
}: {
    modelId: string,
}) {
    return modelId.slice(0, 5).toUpperCase();
}


export interface CustomModelPredictionItem {
    id: string,
    callerPublicUserId: PublicUserId,
    status: CustomModelTrainingStatus,
    input: CustomModelPredictionInput,
    output?: string[],
    usedModels: Record<string, boolean>,
    roles: PublicUserRoles,
    timeCreated: Timestamp,
    timeModified: Timestamp,
    backendTimeStarted?:Timestamp,
    backendTimeCompleted?:Timestamp,
    isDeleted: boolean,
}

export function isCustomModelPredictionItem(item: any): item is CustomModelPredictionItem {
    return (
        item &&
        typeof(item.id) === "string" &&
        item.status &&
        item.input
    );
}

export function getDisplayNameFromId(id: string) {
    return id.slice(0, 5);
}

function getTrainingDefaultDisplayName(training: Partial<CustomModelTrainingItem> & {
    id: string,
}) {
    return (
        `Training-${getDisplayNameFromId(training.id)}`
    );
}

export function getTrainingDisplayName(training: Partial<CustomModelTrainingItem> & {
    id: string,
}) {
    return training.displayName || getTrainingDefaultDisplayName(training);
}

export const customModelMentionTrigger = '@';

export const customModelCaptionTrigger = '[trigger]';

export function getModelTrainingMentionName({
    modelDisplayName,
    training,
}: {
    modelDisplayName: string,
    training: {
        displayName?: string,
        id: string,
    },
}) {
    return customModelMentionTrigger + modelDisplayName + '/' + getTrainingDisplayName(training);
}

export function getModelIdFromTraining(training: CustomModelTrainingItem) {
    return training.modelId || training.input?.modelId;
}

export interface CustomModelEditorState {
    customModelId: string | undefined,
    setCustomModelId: (value: StateUpdater<string | undefined>) => void,
    customModels: Record<string, CustomModelInfo>,
    setCustomModels: (value: StateUpdater<Record<string, CustomModelInfo>>) => void,
    publicCustomModels: Record<string, CustomModelInfo>,
    setPublicCustomModels: (value: StateUpdater<Record<string, CustomModelInfo>>) => void,
    customModelInfo: CustomModelInfo | undefined,
    setCustomModelInfo: (value: StateUpdater<CustomModelInfo | undefined>) => void,
    customModelDataset: CustomModelDataset | undefined,
    setCustomModelDataset: (value: StateUpdater<CustomModelDataset | undefined>) => void,
    customModelTrainings: Record<string, CustomModelTrainingItem>,
    setCustomModelTrainings: (value: StateUpdater<Record<string, CustomModelTrainingItem>>) => void,
}

export function getDummyCustomModelEditorState(): CustomModelEditorState {
    return {
        customModelId: undefined,
        setCustomModelId: noop,
        customModels: {},
        setCustomModels: noop,
        publicCustomModels: {},
        setPublicCustomModels: noop,
        customModelInfo: undefined,
        setCustomModelInfo: noop,
        customModelDataset: undefined,
        setCustomModelDataset: noop,
        customModelTrainings: {},
        setCustomModelTrainings: noop,
    };
}

export function getDefaultCustomModelEditorState(set: SetEditorStateFunction): CustomModelEditorState {
    return {
        customModelId: undefined,
        setCustomModelId: getUpdaterFunction(set, 'customModelId'),
        customModels: {},
        setCustomModels: getUpdaterFunction(set, 'customModels'),
        publicCustomModels: {},
        setPublicCustomModels: getUpdaterFunction(set, 'publicCustomModels'),
        customModelInfo: undefined,
        setCustomModelInfo: getUpdaterFunction(set, 'customModelInfo'),
        customModelDataset: undefined,
        setCustomModelDataset: getUpdaterFunction(set, 'customModelDataset'),
        customModelTrainings: {},
        setCustomModelTrainings: getUpdaterFunction(set, 'customModelTrainings'),
    };
}

export function resetCustomModelEditorState(state: CustomModelEditorState) {
    state.setCustomModels({});
    state.setCustomModelTrainings({});
    state.setCustomModelId(undefined);
    state.setCustomModelInfo(undefined);
    state.setCustomModelDataset(undefined);
    state.setPublicCustomModels({});
}