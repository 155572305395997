import React from "react";
import { createPortal } from 'react-dom';
import {
    WebGLRendererParameters,
} from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Scene3dController } from "./scene3d-controller";
import { Scene3dData, isScene3dData, isScene3dJson, isStaticImageObject3d } from "core/common/types/3d";
import { CANVAS_CONTAINER_ID } from "components/constants/ids";
import { EditorActiveObject } from "core/common/interfaces";
import { editorContextStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { hideObjectControls, showObjectControls } from "core/utils/fabric";
import { EditorAssetContentType } from "core/common/types";
import { FloatTagButtonWithTooltip } from "components/editor/float-tag/float-tag-button";
import { Check, MousePointerClick, Redo, Undo } from "lucide-react";
import { classNames } from "core/utils/classname-utils";
import { FloatTagZIndex } from "components/constants/zIndex";

async function saveSceneCanvasToObject({
    object,
    canvasDataUrl,
    onImageLoaded,
}: {
    object: fabric.StaticImage,
    canvasDataUrl: string | undefined,
    onImageLoaded?: () => void,
}) {
    if (!canvasDataUrl) {
        return;
    }

    const {
        editor,
        backend,
    } = editorContextStore.getState();

    if (!editor || !backend) {
        return;
    }

    object.setSrc(
        canvasDataUrl,
        () => {
            onImageLoaded?.();
            editor.canvas.requestRenderAll();
        },
    );

    const assetId = editor.assets.getObjectAssetId(object as any as fabric.Object) || object.id;

    const path = await editor.assets.addAsset({
        data: canvasDataUrl,
        contentType: EditorAssetContentType.png,
        assetId,
    });

    if (path) {
        editor.assets.setObjectAsset(
            object.id,
            {
                type: 'image-storage',
                path,
                contentType: EditorAssetContentType.png,
            }
        );
    }
}

function getFabricObjectBounds(
    object: fabric.Object,
) {
    object.setCoords();
    const tl = object.oCoords?.tl;
    const br = object.oCoords?.br;

    if (!tl || !br) {
        return;
    }

    const displayWidth = br.x - tl.x;
    const displayHeight = br.y - tl.y;

    if (displayWidth <= 0 || displayHeight <= 0) {
        return;
    }

    return {
        left: tl.x,
        top: tl.y,
        width: displayWidth,
        height: displayHeight,
    }
}

function setObjectVisible(object: fabric.Object, isVisible: boolean) {
    if (!object) {
        return;
    }
    object.visible = isVisible;
    if (isVisible) {
        showObjectControls(object);
    } else {
        hideObjectControls(object);
    }
}

function Scene3dControlMessage({
    className = "",
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                "absolute w-full flex items-center justify-center pointer-events-none text-zinc-500 opacity-100 group-active:opacity-0 transition-opacity",
                className,
            )}
        >
            <MousePointerClick
                size={16}
                className="mr-2"
            />
            Click and drag to rotate the object.
        </div>
    )
}

function Scene3dFloatTagBottom({
    className = "",
    onClickDone,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    onClickDone?: () => void,
}) {
    return (
        <div
            className={classNames(
                "px-1 py-1 rounded-md flex flex-row items-stretch justify-center text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity",
                className,
            )}
            {...props}
        >
            <FloatTagButtonWithTooltip
                tooltipChildren="Undo"
            >
                <Undo size={16} />
            </FloatTagButtonWithTooltip>
            <div className="w-1" />
            <FloatTagButtonWithTooltip
                tooltipChildren="Redo"
            >
                <Redo size={16} />
            </FloatTagButtonWithTooltip>
            <div className="w-1" />
            <FloatTagButtonWithTooltip
                className="bg-lime-500 text-zinc-800 hover:bg-lime-600 hover:text-zinc-900"
                tooltipChildren="Exit to main canvas."
                onClick={onClickDone}
            >
                <Check size={16} className="mr-2" />
                Done
            </FloatTagButtonWithTooltip>
        </div>
    )
}

export function Scene3dCanvas({
    rendererParams,
    onExit,
}: {
    rendererParams?: WebGLRendererParameters,
    onExit?: () => void,
}) {
    const canvasContainer = React.useMemo(() => document.getElementById(CANVAS_CONTAINER_ID), []);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const sceneControllerRef = React.useRef<Scene3dController | undefined>(undefined);

    const activeObject = editorContextStore(state => state.activeObject);

    React.useEffect(() => {

        if (!activeObject) {

            return onExit?.();
        }

        activeObject.rotate(0);

        const originalWidth: number = activeObject.width as number;
        const originalHeight: number = activeObject.height as number;
        const originalScaledWidth = activeObject.getScaledWidth();
        const originalScaledHeight = activeObject.getScaledHeight();
        const originalLeft = activeObject.left as number;
        const originalTop = activeObject.top as number;

        if (!originalWidth || !originalHeight || !originalScaledWidth || !originalScaledHeight) {
            return onExit?.();
        }

        const scale = 512 / Math.max(originalWidth, originalHeight);
        const width = scale * originalWidth;
        const height = scale * originalHeight;

        const is3d = isStaticImageObject3d(activeObject);

        if (!is3d) {
            console.log("Image object is not 3d");
            return onExit?.();
        }

        const scene3dData = activeObject.metadata.scene3dData;

        if (!isScene3dData(scene3dData)) {
            console.log("Scene data is invalid");
            return onExit?.();
        }

        if (sceneControllerRef.current) {
            console.log("Scene controller is invalid");
            return onExit?.();
        }

        const container = containerRef.current;
        if (!container) {
            console.log("Container is invalid");
            return onExit?.();
        }

        if (!isScene3dJson(scene3dData.sceneJson)) {
            console.log("Scene 3d JSON is invalid");
            return onExit?.();
        }

        const objectBounds = getFabricObjectBounds(activeObject as fabric.Object);

        if (!objectBounds) {
            return onExit?.();
        }

        container.style.left = `${objectBounds.left}px`;
        container.style.top = `${objectBounds.top}px`;
        container.style.width = `${objectBounds.width}px`;
        container.style.height = `${objectBounds.height}px`;
        const sceneController = new Scene3dController({
            container,
        });

        sceneController.initScene({
            ...scene3dData,
            rendererParams,
        });

        // Hide the object
        setObjectVisible(activeObject as fabric.Object, false);

        return () => {
            const savedScene = sceneController.saveScene();

            if (savedScene) {
                activeObject.metadata.scene3dData = savedScene;

                // console.log(`Save canvas to [${width}, ${height}]; Original size: [${originalScaledWidth}, ${originalScaledHeight}]`);

                const canvasDataUrl = sceneController.toDataURL({
                    width,
                    height,
                });

                saveSceneCanvasToObject({
                    object: activeObject,
                    canvasDataUrl,
                    onImageLoaded: () => {
                        activeObject.scaleX = activeObject.width ? originalScaledWidth / activeObject.width : 1;
                        activeObject.scaleY = activeObject.height ? originalScaledHeight / activeObject.height : 1;
                        activeObject.left = originalLeft;
                        activeObject.top = originalTop;
                    }
                });
            }

            sceneController.destroy();

            setObjectVisible(activeObject as fabric.Object, true);
        }
    }, [
        activeObject,
        rendererParams,
        onExit,
    ]);

    if (!canvasContainer) {
        console.log("Canvas container is invalid");
        return null;
    }

    return createPortal(
        <div
            className="relative w-full h-full"
            style={{
                zIndex: FloatTagZIndex,
            }}
        >
            <div
                ref={containerRef}
                className='group absolute outline-none focus:outline-none focus-active:outline-none cursor-grab active:cursor-grabbing'
                style={{
                    position: "absolute",
                    boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",
                }}
            >
                <Scene3dControlMessage
                    className="bottom-[18px]"
                />
                <div
                    className="absolute bottom-[-56px] w-full flex items-center justify-center pointer-events-none"
                >
                    <Scene3dFloatTagBottom
                        onClickDone={onExit}
                        className="pointer-events-auto"
                    />
                </div>
            </div>
        </div>,
        // @ts-ignore
        canvasContainer,
    );
}