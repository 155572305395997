import React from "react";
import { Zap } from "lucide-react";
import { classNames } from "core/utils/classname-utils";
import { PrimaryButtonClassName } from "components/constants/class-names";
import { useManageSubscriptionDialogContext } from "components/popup/message-dialog/manage-subscription-dialog";
import { editorContextStore } from "contexts/editor-context";

import { AppUserSubscriptionTierV2 } from "core/common/types";

function useUpgradeVisible() {
    const userQuotas = editorContextStore(state => state.userQuotas)
    return React.useMemo(() => {
        if (!userQuotas) {
            return true;
        }

        if (process.env.NODE_ENV === 'development') {
            return true;
        }

        return userQuotas.tierV2 !== AppUserSubscriptionTierV2.ProPlus
    }, [userQuotas]);
}

export function NavBarUpgrade() {
    const { setOpen } = useManageSubscriptionDialogContext();

    const visible = useUpgradeVisible();

    if (!visible) {
        return null;
    }

    return (
        <button
            className={classNames(
                PrimaryButtonClassName,
                'group py-1 px-3 flex flex-row items-center justify-center gap-2 cursor-pointer',
                'bg-lime-500/10 hover:bg-lime-500/30 active:bg-lime-500 active:text-lime-100 text-sm text-lime-300 hover:text-lime-200 actve:text-lime-50 shadow-lg shadow-lime-900/5 hover:shadow-lime-600/5 active:shadow-lime-900/5',
            )}
            onClick={() => {
                setOpen(true);
            }}
        >
            <span>
                Upgrade
            </span>
            <Zap
                size={16}
            />
        </button>
    )
}