import { GenerateTemplate } from "core/common/types";

export const emptyGenerateTemplate: GenerateTemplate = {
    prompt: {
        words: [
            {
                "type": "input",
                "value": "",
                "placeholder": "",
                "autocompleteType": "subject"
            },
        ],
        style: '',
    }
}

export const defaultGenerateTemplate: GenerateTemplate = {
    prompt: {
        words: [
            {
                "type": "input",
                "value": "",
                "placeholder": "",
                "autocompleteType": "subject"
            },
            {
                "type": "input",
                "prefix": "in front of",
                "value": "a natural beige background",
                "autocompleteType": "background"
            },
            {
                "type": "fixed",
                "prefix": "on",
                "value": "circular platforms",
                "autocompleteType": "placement"
            },
        ],
        style: '',
    }
}

export const defaultGenerateTemplateId = 'test_077';