import React from "react";
import { LeftPanelItemType, LEFT_PANELS_LIST_ITEMS } from "components/constants/editor-options";
import { DEFAULT_ICON, PANELS_LIST_ICONS } from "./panels-list-icons"
import { useLeftPanelState } from "hooks/use-left-panel-state";
import { editorContextStore } from "contexts/editor-context";
import { LayerType } from "core/common/layers";
import { EditorActiveObject } from "core/common/interfaces";
import Objects from "core/controllers/objects";
import { isStaticImageObjectColor, isStaticImageObjectHed, isStaticImageObjectUploaded } from "core/utils/type-guards";
import { classNames } from "core/utils/classname-utils";
import { isEditLeftPanelDisabled } from "./panel-items/edit/edit";
import { UserProjectType, UsedUserProjectType } from "core/common/types";
import { projectLeftPanelListItems } from "components/constants/left-panels";
import { useActiveObjectLeftPanel } from "hooks/use-active-object-left-panel";



function getPanelListItemClassName(
    isActive = false,
    isDisabled = false,
) {
    const names = [
        'w-full flex flex-col items-center justify-center select-none cursor-pointer p-2 mb-2 rounded-md transition-colors border',
    ];
    if (isDisabled) {
        names.push('border-zinc-900 text-zinc-700 cursor-not-allowed');
    } else {
        names.push(
            'active:border-zinc-700 active:text-white',
            isActive ? 'text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800' : 'text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900',
        );
    }
    return classNames(...names);
}

function isLeftPanelDisabled(
    type: LeftPanelItemType,
    activeObject: EditorActiveObject,
) {
    if (type === 'Edit') {
        return isEditLeftPanelDisabled(activeObject);
    }
    return false;
}

function PanelListItem({
    type,
    name,
}: {
    type: LeftPanelItemType,
    name: string,
}) {
    const {
        activeLeftPanel,
        setActiveLeftPanels,
    } = useLeftPanelState();

    const Icon = PANELS_LIST_ICONS[type] || DEFAULT_ICON;

    const activeObject = editorContextStore(state => state.activeObject);

    const isDisabled = React.useMemo(() => isLeftPanelDisabled(
        type,
        activeObject,
    ), [type, activeObject]);

    return (
        <div
            className={getPanelListItemClassName(
                type === activeLeftPanel,
                isDisabled,
            )}
            onClick={() => {
                if (type && !isDisabled) {
                    // setActiveLeftPanel(type, setActiveLeftPanels);
                    setActiveLeftPanels([type]);
                }
            }}
        >
            <Icon
                size={18}
                className="my-1"
            />
            <div
                className="text-xs"
                style={{
                    fontSize: 10,
                }}
            >
                {name}
            </div>
        </div>
    );
}

export function PanelsList() {
    useActiveObjectLeftPanel();
    const projectType = editorContextStore(state => state.projectType);

    const listItems = React.useMemo(() => {
        const type = projectType || UserProjectType.ProductPhotography;
      return (type && type !== UserProjectType.CustomModels && projectLeftPanelListItems[type]) ?
        projectLeftPanelListItems[type] :
        projectLeftPanelListItems[UserProjectType.ProductPhotography];
    }, [projectType]);

    return (
        <div
            className="min-h-[100%] flex flex-col items-center bg-zinc-900 border-r border-zinc-800 p-2"
        >
            {listItems.map((key) => (
                <PanelListItem
                    key={key}
                    type={key as LeftPanelItemType}
                    name={LEFT_PANELS_LIST_ITEMS[key]?.name}
                />
            ))}
        </div>
    )
}