import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip';
import { classNames } from 'core/utils/classname-utils'
import { DropdownClassName } from 'components/constants/class-names';

export const FloatTagButton = React.forwardRef((
    {
        className = "",
        children,
        ...props
    }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) => {
    return (
        <button
            ref={forwardedRef}
            {...props}
            className={classNames(
                className,
                "px-2 py-1 rounded hover:bg-zinc-700 transition-colors"
            )}
        >
            {children}
        </button>
    )
})

export const FloatTagButtonWithTooltip = React.forwardRef((
    {
        disabled = false,
        className = "",
        children,
        tooltipChildren,
        ...props
    }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
        tooltipChildren?: React.ReactNode,
        disabled?: boolean,
    },
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) => {
    return (
        <Tooltip.Root
            delayDuration={500}
        >
            <Tooltip.Trigger
                asChild
            >
                <button
                    ref={forwardedRef}
                    {...props}
                    className={classNames(
                        "flex flex-row items-center px-2 py-1 rounded transition-colors",
                        disabled ? "text-zinc-500" : "text-zinc-300 hover:bg-zinc-700 active:bg-zinc-900/80",
                        className,
                    )}
                >
                    {children}
                </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    className={classNames(
                        DropdownClassName,
                        'bg-black text-xs'
                    )}
                    sideOffset={10}
                    side='bottom'
                >
                    {tooltipChildren}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    )
});