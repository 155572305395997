import {
    TryOnModelPreviewData,
    isTryOnModelPreviewData,
    TryOnModelPreviewFilterQueryConstraints,
    ITryOnModelPreviewGenerator,
    defaultTryOnModelPreviewFilterQueryConstraints,
} from "core/common/types";
import { FirebaseBackend } from "./firebase-backend";
import { collection, doc, Firestore, getDoc, getDocs, getFirestore, limit, onSnapshot, query, runTransaction, setDoc, updateDoc, where, orderBy, startAfter, DocumentSnapshot, serverTimestamp, addDoc, QueryDocumentSnapshot, DocumentData, connectFirestoreEmulator, deleteDoc, QueryConstraint, documentId } from 'firebase/firestore';
import { cloneDeep, throttle } from "lodash";
import { editorContextStore } from "contexts/editor-context";

function isConstraintsEqual(a: TryOnModelPreviewFilterQueryConstraints, b: TryOnModelPreviewFilterQueryConstraints): boolean {

    if (a.length !== b.length) {
        return false;
    }

    const sortedA = [...a].sort();
    const sortedB = [...b].sort();

    for (let i = 0; i < sortedA.length; i++) {
        if (sortedA[i] !== sortedB[i]) {
            return false;
        }
    }

    return true;
}

function getQueryConstraints(filterConstraints: TryOnModelPreviewFilterQueryConstraints): QueryConstraint[] {

    const tags = filterConstraints.filter(Boolean);

    if (tags.length <= 0) {
        return [];
    }

    return tags.map((tag) => (
        where(
            `tags.${tag}`,
            '==',
            true,
        )
    ));
}

function getTryOnModelPreviewsFilterQuery({
    firestore,
    limitSize = 0,
    lastVisible,
    filterConstraints,
}: & {
    firestore: Firestore,
    limitSize?: number,
    lastVisible?: DocumentSnapshot<unknown>,
    filterConstraints: TryOnModelPreviewFilterQueryConstraints
}) {
    const posePreviewsRef = collection(firestore, FirebaseBackend.TryOnModelPreviewCollectionPath);

    const queryConstraints: QueryConstraint[] = getQueryConstraints(filterConstraints);


    if (lastVisible) {
        queryConstraints.push(startAfter(lastVisible));
    }

    if (limitSize) {
        queryConstraints.push(limit(limitSize));
    }

    return query(
        posePreviewsRef,
        ...queryConstraints
    );
}

const getTryOnModelPreviews = throttle((params: {
    firestore: Firestore,
    limitSize?: number,
    lastVisible?: DocumentSnapshot<unknown>,
    filterConstraints: TryOnModelPreviewFilterQueryConstraints,
}) => {
    return getDocs(getTryOnModelPreviewsFilterQuery(params));
}, 150);

export class TryOnPreviewGenerator implements ITryOnModelPreviewGenerator {
    private firestore: Firestore;

    batchSize: number;

    private isRemoteFinished = false;

    private remoteLastVisible: DocumentSnapshot<unknown> | undefined = undefined;

    private localLastVisibleIndex = 0;

    private documentCache: TryOnModelPreviewData[] = [];


    private localConstraints: TryOnModelPreviewFilterQueryConstraints = []

    private unsubscribeStateUpdate = () => { };

    private get filterConstraints() {
        return editorContextStore.getState().tryOnModelPreviewFilterContraints;
    }

    private set filterConstraints(constraints: TryOnModelPreviewFilterQueryConstraints) {
        this.localConstraints = cloneDeep(constraints);

        editorContextStore.getState().setTryOnModelPreviewFilterContraints((prevConstraints) => {
            if (isConstraintsEqual(prevConstraints, constraints)) {
                return prevConstraints;
            }
            return constraints;
        });
    }

    constructor({
        firestore,
        batchSize = 24,
    }: {
        batchSize?: number,
        firestore: Firestore,
    }) {
        this.batchSize = batchSize;
        this.firestore = firestore;
    }

    reset(localOnly = false) {
        if (!localOnly) {
            this.isRemoteFinished = false;
            this.remoteLastVisible = undefined;
            this.documentCache.length = 0;
            this.localConstraints = [];
            this.unsubscribeStateUpdate();
            this.filterConstraints = cloneDeep(defaultTryOnModelPreviewFilterQueryConstraints);
        }

        this.localLastVisibleIndex = 0;
    }

    set constraints(constraints: TryOnModelPreviewFilterQueryConstraints) {
        if (isConstraintsEqual(this.localConstraints, constraints)) {
            return;
        }

        this.reset();

        this.filterConstraints = constraints;
    }

    setConstraints(constraints: TryOnModelPreviewFilterQueryConstraints) {
        this.constraints = constraints;

        return this.getNextBatch();
    }

    private async getNextBatchRemote() {
        if (this.isRemoteFinished) {
            return [];
        }

        const docSnapshots = await getTryOnModelPreviews({
            firestore: this.firestore,
            limitSize: this.batchSize,
            lastVisible: this.remoteLastVisible,
            filterConstraints: this.filterConstraints,
        });

        if (!docSnapshots) {
            return [];
        }

        this.remoteLastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

        if (docSnapshots.empty) {
            this.isRemoteFinished = true;
        }

        const batch = docSnapshots.docs.map(doc => doc.data()).filter(isTryOnModelPreviewData);

        this.documentCache.push(...batch);

        return batch;
    }

    get isLocalFinished() {
        return this.localLastVisibleIndex >= this.documentCache.length;
    }

    private getNextBatchLocal() {
        const batchStart = this.localLastVisibleIndex;

        const batchFinish = Math.min(
            this.localLastVisibleIndex + this.batchSize,
            this.documentCache.length,
        );

        const batch = this.documentCache.slice(batchStart, batchFinish);

        this.localLastVisibleIndex = batchFinish;

        return batch;
    }

    async getNextBatch() {

        if (this.isLocalFinished && this.isRemoteFinished) {
            return [];
        }

        const batch = this.getNextBatchLocal();

        if (batch.length < this.batchSize) {

            // Query the next batch remotely

            const remoteNextBatch = await this.getNextBatchRemote();

            const delta = this.batchSize - batch.length;

            batch.push(...remoteNextBatch.slice(0, delta));

            this.localLastVisibleIndex += delta;

        }

        return batch;
    }
}