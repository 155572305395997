import { editorContextStore } from "contexts/editor-context"
import { UserAssetInfo, UserAssetInfoCollection } from "core/common/types";
import React from "react"
import { useInView } from "react-intersection-observer";
import { Tooltip, TooltipProps } from "components/utils/tooltip";
import { mergeRefs } from "components/utils/merge-refs";
import { classNames } from "core/utils/classname-utils";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { getObjectTargetScale } from "components/utils/upload";
import { PresetImageGridItem, PresetImageGridItemPropsAddItemHandler } from "./preset-image-grid";

export function useUserImageAssets() {

    const userImageAssetInfoCollection = editorContextStore(state => state.userImageAssetInfoCollection);
    const backend = editorContextStore(state => state.backend);

    React.useEffect(() => {
        if (!backend) {
            return;
        }

        const {
            userImageAssetGeneratorRef,
        } = editorContextStore.getState();

        if (!userImageAssetGeneratorRef.current) {
            userImageAssetGeneratorRef.current = backend.getUserAssetInfoGenerator({
                assetType: 'images',
                batchSize: 12,
            });
            userImageAssetGeneratorRef.current
                ?.getNextBatch()
                .then((assetInfo) => {
                    editorContextStore.getState().setUserImageAssetInfoCollection(assetInfo.reduce<UserAssetInfoCollection>((result, i) => {
                        result[i.id] = i;
                        return result;
                    }, {}));
                });
        }

    }, [backend]);

    const [
        lastRowRef,
        lastRowInView,
    ] = useInView();

    React.useEffect(() => {

        const {
            userImageAssetGeneratorRef,
            setUserImageAssetInfoCollection,
        } = editorContextStore.getState();

        if (!lastRowInView || !userImageAssetGeneratorRef.current) {
            return;
        }

        userImageAssetGeneratorRef.current
            .getNextBatch()
            .then((assetInfo) => {
                setUserImageAssetInfoCollection((prevCollection) => ({
                    ...prevCollection,
                    ...assetInfo.reduce<UserAssetInfoCollection>((result, i) => {
                        result[i.id] = i;
                        return result;
                    }, {}),
                }));
            });

    }, [lastRowInView]);

    const userImageAssets = React.useMemo(() => Object.values(userImageAssetInfoCollection), [userImageAssetInfoCollection]);

    return {
        userImageAssets,
        lastRowRef,
    };
}


export const UserAssetLibraryItem = React.forwardRef(({
    imageItem,
    isIdle = true,
    onAddItem,
    ...props
}: TooltipProps & {
    imageItem: UserAssetInfo,
    isIdle?: boolean,
    onAddItem: PresetImageGridItemPropsAddItemHandler,
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    const editor = editorContextStore(state => state.editor);

    const [
        elementRef,
        inView,
    ] = useInView();

    const storagePath = imageItem.storagePath;

    const [url, setUrl] = React.useState("");

    React.useEffect(() => {

        if (!editor) {
            return;
        }

        editor.assets.loadAsset({
            type: 'image-storage',
            path: storagePath,
        }).then((dataUrl) => {
            if (!dataUrl) {
                return;
            }
            setUrl(dataUrl);
        });

    }, [editor, storagePath]);

    return (
        <Tooltip
            {...props}
            triggerChildren={(
                <PresetImageGridItem
                    ref={mergeRefs([forwardedRef, elementRef])}
                    url={inView ? url : ""}
                    className={classNames(
                        SecondaryButtonClassNameInactive,
                        url ? "cursor-pointer" : "cursor-wait"
                    )}
                    onAddItem={(args) => onAddItem({
                        ...args,
                        caption: imageItem.caption,
                    })}
                />
            )}
            contentClassName="pointer-events-none"
            contentChildren={(
                <div>
                    {url ?
                        "Click to add this asset to the canvas." :
                        "Loading asset ..."}
                </div>
            )}
        />
    )
})

