import { editorContextStore } from 'contexts/editor-context';
import { UserAuthChangedEventHandler } from 'core/common/types';
import React from 'react';

export function useAuthUpdateEffect() {
    const backend = editorContextStore(state => state.backend);
    const user = editorContextStore(state => state.user);
    const setUser = editorContextStore(state => state.setUser);
    const setUserQuotas = editorContextStore(state => state.setUserQuotas);
    const setUserOnboardData = editorContextStore(state => state.setUserOnboardData);

    React.useEffect(() => {
        return backend?.onAuthStateChanged((user) => {
            const {
                setPublicUserId,
            } = editorContextStore.getState();

            setUser(user);
            if (!user) {
                setUserQuotas(null);
                setUserOnboardData(null);
                setPublicUserId(null);
                editorContextStore.getState().analytics.identify();
            } else {
                backend
                    .getPublicUserId()
                    .then(async (publicUserId) => {
                        setPublicUserId(publicUserId || null);
                    });

                editorContextStore.getState().analytics.identify(
                    user.uid,
                );

                backend.onUserLogin({
                    uid: user.uid,
                });
            }
            editorContextStore.getState().editor?.emit<UserAuthChangedEventHandler>('user:auth-changed', user);
        });
    }, [backend, setUser, setUserQuotas, setUserOnboardData]);

    React.useEffect(() => {
        return backend?.onUserQuotasUpdate({
            userId: user?.uid,
            onUpdate: setUserQuotas,
        });
    }, [backend, user, setUserQuotas]);

    React.useEffect(() => {
        if (user) {
            backend?.getUserOnboardData(user?.uid).then((userOnboardData) => {
                setUserOnboardData(userOnboardData);
            });
        }
    }, [backend, user, setUserOnboardData]);
}