import React from "react"
import { Editor } from "core/editor";
import { getObjectTargetScale } from "components/utils/upload";
import { LeftPanelSectionContainer } from "../base";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { PresetImageGridContainer, PresetImageGridItem } from "./preset-image-grid";
import { HorizontalImageGrid, HorizontalImageGridImageItem } from "./horizontal-image-grid";
import { showObjectControls } from "core/utils/fabric";
import { fabric } from "fabric";
import { StaticImageElementMetadata } from "core/common/types/elements";
import { editorContextStore } from "contexts/editor-context";
import { MobileBottomPanelState } from "core/common/types/mobile";
import { onAddObjectToCanvas } from "../on-add-object";

export type AssetLibraryItem = {
    id?: string,
    url: string,
    previewUrl?: string,
    name?: string,
    metadata?: StaticImageElementMetadata,
}

function handleAddAsset(
    item: AssetLibraryItem,
    editor: Editor | null,
    event?: Event,
) {
    const {
        url,
        metadata,
    } = item;
    const location = event && editor?.canvas.canvas.getPointer(event);
    editor?.objects.addImageFromUrl({
        url,
        asset: {
            type: 'image-url',
            path: url,
        },
        uploadStorage: false,
        location,
        metadata,
    }).then((object) => {
        if (!object) {
            return;
        }
        const scale = getObjectTargetScale(
            // @ts-ignore
            object,
        );
        object.scale(scale);
        showObjectControls(object as any as fabric.Object);

        onAddObjectToCanvas();
    });

}

export function AssetLibrary({
    label,
    editor,
    assets,
    children,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    label?: string,
    editor: Editor | null,
    assets: AssetLibraryItem[],
}) {
    const uniqueAssets = React.useMemo(() => {
        const uniqueAssets: AssetLibraryItem[] = [];
        const urlHashTable: Record<string, boolean> = {};
        assets.forEach(asset => {
            if (!urlHashTable[asset.url]) {
                uniqueAssets.push(asset);
                urlHashTable[asset.url] = true;
            }
        });
        return uniqueAssets;
    }, [
        assets,
    ]);

    return (
        <LeftPanelSectionContainer
            label={label}
        >
            {children}
            <PresetImageGridContainer>
                {uniqueAssets.map((item) => (
                    <PresetImageGridItem
                        id={item.url}
                        key={item.url}
                        url={item.previewUrl || item.url}
                        className={`${SecondaryButtonClassNameInactive}`}
                        onAddItem={({
                            event,
                        }) => {
                            handleAddAsset(
                                item,
                                editor,
                                event,
                            );
                        }}
                    />
                ))}
            </PresetImageGridContainer>
        </LeftPanelSectionContainer>
    );
}

function getHorizontalImageGridItemsFromAssets(
    assets: AssetLibraryItem[],
) {
    const output: Record<string, HorizontalImageGridImageItem & { index: number }> = {};
    assets.forEach((asset, index) => {
        const url = asset.previewUrl || asset.url;
        if (!url) {
            return;
        }
        output[index] = {
            index,
            imgPath: url,
            alt: asset.name,
        };
    });
    return output;
}

export function AssetLibraryHorizontalImageGrid({
    assets,
    editor,
    onClickViewAll,
}: {
    assets: AssetLibraryItem[],
    editor: Editor | null,
    onClickViewAll?: () => void,
}) {
    const items = React.useMemo(() => getHorizontalImageGridItemsFromAssets(assets), [assets]);
    return (
        <HorizontalImageGrid
            items={items}
            onClickViewAll={onClickViewAll}
            onSelectItem={(item, event) => {
                const index = (item as any)?.index;
                if (typeof (index) !== 'number') {
                    return;
                }
                const asset = assets[index];
                if (!asset) {
                    return;
                }
                handleAddAsset(
                    asset,
                    editor,
                    event,
                );
            }}
            getHoverCardContent={(item) => (
                <>
                    <span>
                        {item.alt || 'Untitled Asset'}
                    </span>
                    <span
                        className="mt-2 text-zinc-500"
                    >
                        Click to use this asset.
                    </span>
                </>
            )}
        />
    );
}