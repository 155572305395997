import React, { useRef, useCallback, useMemo, RefAttributes, HTMLAttributes } from "react"
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from "lucide-react";

import { editorContextStore } from 'contexts/editor-context';
import { AnalyticsConfig } from 'analytics/config';
import { classNames } from "core/utils/classname-utils"
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { NEW_PROJECT, NEW_TRYON_PROJECT, CUSTOM_MODELS } from 'components/constants/routes';
import { SpecialProjectTypes, getOrderedProjectTypes, SpecialProjectCarouselNames, SpecialProjectTooltipTexts, SpecialProjectTypeImages } from 'components/constants/UserProjectTypeStyles';
import { UserProjectType } from 'core/common/types';
import { Tooltip } from 'components/utils/tooltip';
import { preprocessImageUrl } from "core/utils/url-utils";
import { debugLog } from "core/utils/print-utilts";

export type WorkflowAction =
  | 'WorkflowCarouselItemClick'
  | 'WorkflowDashboardCardClick'
  | 'ProjectDashboardCardClick'
  | 'SidebarNavClick';

export type WorkflowActionData = {
  workflow?: SpecialProjectTypes,
}
export function trackWorkflowAction(action: WorkflowAction, data: WorkflowActionData = {}): void {
  const { analytics } = editorContextStore.getState();

  analytics.track(
      AnalyticsConfig.WorkflowAction,
      {
          action,
          ...data
      }
  );
}

type WorkflowCarouselItemProps = AspectRatio.AspectRatioProps & RefAttributes<HTMLDivElement> & {
  name: string;
  url: string;
  workflow: UserProjectType;
  onClick?: () => void
};
function WorkflowCarouselItem({ name, url, workflow, children, onClick = () => {}, ...props}: WorkflowCarouselItemProps) {

  return (
    <div className=''>
      <AspectRatio.Root
            ratio={1.25}
            draggable
            className={classNames('relative cursor-pointer items-center justify-center ')}
            onClick={onClick}
            onDragEnd={(event) => {}}
            {...props}
        >
            <div
              className='absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center'
              style={{
                backgroundImage: `url(${preprocessImageUrl(url)})`,
              }}
            ></div>
            <div className={`absolute bottom-0 bg-gradient-to-t from-black to-transparent transition-all group-hover:opacity-0 group-hover:pl-10 group-hover:from-transparent duration-300 w-full text-zinc-300 text-sm font-bold pt-8 pb-2 pl-2 truncate text-left`}>
              {name}
            </div>
            {children}
        </AspectRatio.Root>
    </div>
  );
}

function getWorkflowCarouselItems(navigate: NavigateFunction) {
  const setDashboardType = editorContextStore(state => state.setDashboardType);

  return Object.values(getOrderedProjectTypes())
      .map((type, index) => {
        return (<div key={index} className='flex-none w-72 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 2xl:w-[12.5%] max-w-[272px] snap-center hover:border-lime-600 border-zinc-800 transition-colors border-2 rounded-md overflow-hidden mr-2'>
            <Tooltip
              triggerProps={{
                asChild: false,
              }}
              triggerChildren={
                <WorkflowCarouselItem
                  workflow={type}
                  name={SpecialProjectCarouselNames[type as SpecialProjectTypes]}
                  url={SpecialProjectTypeImages[type as SpecialProjectTypes] || `https://picsum.photos/seed/${type}/800`}
                  onClick={() => {
                    if (type === UserProjectType.CustomModels) {
                      setDashboardType('models');

                      debugLog('Navigate to custom models dashboard type');

                      navigate(`/${CUSTOM_MODELS}`, { replace: true });
                    } else if (type === UserProjectType.Fashion) {
                      navigate(`/${NEW_TRYON_PROJECT}`, { replace: true });
                    } else {
                      navigate(`/${NEW_PROJECT}/${type}`, { replace: true });
                    }
                    trackWorkflowAction('WorkflowCarouselItemClick', {
                      workflow:type as SpecialProjectTypes
                    });
                  }}
                  />
              }
              contentProps={{
                align: 'center',
                sideOffset: 8,
              }}
              contentChildren={
                 SpecialProjectTooltipTexts[type as SpecialProjectTypes] || `Create ${type} photography project.`
              }
            />
          </div>
        );
      })
}

export type WorkflowCarouselProps = HTMLAttributes<HTMLDivElement>;
export function WorkflowCarousel({
  children,
  className,
  ...props
}: WorkflowCarouselProps) {

  const itemContainer = useRef<HTMLDivElement>(null);
  const leftArrow = useRef<HTMLDivElement>(null);
  const rightArrow = useRef<HTMLDivElement>(null);
  const scrollAreaContainer = useRef<HTMLDivElement>(null);
  const scrollAreaViewport = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const updateArrows = useCallback(() => {
    if (!scrollAreaViewport.current || !itemContainer.current) return;

    const scrollLeft = itemContainer.current.scrollLeft;
    const containerWidth = itemContainer.current.clientWidth;
    const scrollWidth = itemContainer.current.scrollWidth;

    if (leftArrow.current) {
      leftArrow.current.style.display = scrollLeft > 0 ? 'flex' : 'none';
    }
    if (rightArrow.current) {
      rightArrow.current.style.display = scrollLeft + containerWidth < scrollWidth ? 'flex' : 'none';
    }
  }, []);

  const onArrowClick = useCallback((direction: 'left' | 'right') => {
    const scrollAmount = (scrollAreaContainer.current?.clientWidth ?? 0) / 3;
    itemContainer.current?.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth',
    });
  }, []);

  const classNames = useMemo(() => ({
    arrows: 'pointer-events-auto absolute top-0 px-1.5 h-full flex items-center justify-center text-zinc-100 from-zinc-900 to-transparent cursor-pointer',
    arrowContainer: 'absolute top-0 pointer-events-none w-full h-full',
    itemContainer: 'flair-workflowCarousel-itemContainer relative flex overflow-x-auto overflow-x-hidden'
  }), []);

  return (
    <ScrollAreaContainer className={`flair-workflowCarousel relative w-full ${className}`}
            ref={scrollAreaContainer}
            viewportRef={scrollAreaViewport}
            orientation="horizontal"
            hideScrollbar
            onScroll={() => updateArrows()}
            onPointerOver={() => updateArrows()}
            >
        <div ref={itemContainer}
          className={classNames.itemContainer}
          onScroll={() => updateArrows()}>
          {getWorkflowCarouselItems(navigate)}
        </div>
        <div className={classNames.arrowContainer}>
          <div
            ref={leftArrow}
            className={`${classNames.arrows} left-0 bg-gradient-to-r`}
            onClick={() => onArrowClick('left')}
          >
            <ChevronLeft size={20} />
          </div>
          <div
            ref={rightArrow}
            className={`${classNames.arrows} right-0 bg-gradient-to-l`}
            onClick={() => onArrowClick('right')}
          >
            <ChevronRight size={20} />
          </div>
        </div>
        {children}
    </ScrollAreaContainer>
  )

}