import { Cross1Icon, DragHandleDots2Icon } from '@radix-ui/react-icons';
import { DropdownClassName, PrimaryButtonClassName, PrimaryButtonClassNameDisabled, SecondaryButtonClassNameInactive, TextButtonClassName } from 'components/constants/class-names';
import { HelperWidgetZIndex } from 'components/constants/zIndex';
import { ScrollAreaContainer } from 'components/scroll-area/scroll-area';
import { classNames } from 'core/utils/classname-utils';
import { clamp } from 'lodash';
import React from 'react';
import Draggable from 'react-draggable';
import { StepByStepsTutorialConfig } from './config';
import { Highlighter } from './highlighter';
import { StepByStepTutorialContext } from './step-by-step-context';
import { stepByStepsFirstOnboardConfig } from './tutorials/first-onboard';

const defaultStepByStepTutorialConfig = stepByStepsFirstOnboardConfig;

function Continue({
    className = '',
    disabled = false,
    onClick,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    disabled?: boolean,
}) {
    return (
        <button
            {...props}
            onClick={(e) => {
                if (disabled) {
                    return;
                }
                onClick?.(e);
            }}
            className={classNames(
                disabled ? PrimaryButtonClassNameDisabled : PrimaryButtonClassName,
                'px-6',
                className,
            )}
        >
            Continue
        </button>
    )
}

function Done({
    className = '',
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={classNames(
                PrimaryButtonClassName,
                'px-6',
                className,
            )}
        >
            Done
        </button>
    )
}

function Pagination({
    hasPrev = false,
    hasNext = true,
    displayDone = false,
    onClickPrev,
    onClickNext,
    onClickSkip,
}: {
    hasPrev: boolean,
    hasNext: boolean,
    displayDone?: boolean,
    onClickPrev: () => void,
    onClickNext: () => void,
    onClickSkip: () => void,
}) {
    return (
        <div className='w-full flex flex-row items-center justify-center'>
            <button
                className={classNames(
                    hasPrev ? SecondaryButtonClassNameInactive : TextButtonClassName,
                    'px-3',
                )}
                onClick={hasPrev ? onClickPrev : onClickSkip}
            >
                {hasPrev ? 'Previous' : 'Skip tutorial'}
            </button>
            <div className='flex-1' />
            {
                hasNext ?
                    <Continue onClick={onClickNext} /> :
                    displayDone ?
                        <Done onClick={onClickSkip} /> :
                        <Continue disabled />
            }
        </div>
    )
}


function StepsTitle({
    step,
    numSteps,
    title,
    hasWelcome = false,
    hasEnding = false,
}: {
    step: number,
    numSteps: number,
    title: string,
    hasWelcome?: boolean,
    hasEnding?: boolean,
}) {
    return (
        <span
            className='grow flex flex-row items-center text-lg font-semibold pointer-events-none select-none'
        >
            <span className='flex-1 truncate'>
                {title}
            </span>
        </span>
    )
}



export function StepByStepTutorial({
    config = defaultStepByStepTutorialConfig,
    step,
    setStep,
    onClose,
    onSkip = onClose,
}: {
    config?: StepByStepsTutorialConfig,
    step: number,
    setStep: (value: number | ((v: number) => number)) => void,
    onSkip: () => void,
    onClose: () => void,
}) {
    const stepConfig = React.useMemo(() => config.steps[step], [config, step]);
    const Content = stepConfig.Content;
    const [isOpen, setOpen] = React.useState(false);
    const [size, setSize] = React.useState({ width: 280, height: 100 });
    const [defaultPosition, setDefaultPosition] = React.useState({ x: 0, y: 0 });

    React.useEffect(() => {
        if (!window) {
            setOpen(false);
            return;
        }
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const heightRatio = config.heightRatio ?? 0.2;

        const width = clamp(windowWidth * 0.9, 280, 1200);
        const height = clamp(windowHeight * heightRatio, 100, 600);
        setSize({
            width,
            height,
        });

        const y = typeof (config.topRatio) === 'number' ?
            windowHeight * config.topRatio :
            (windowHeight - height) / 2;

        const defaultPosition = {
            x: Math.max(0, (windowWidth - width) / 2),
            y: Math.max(0, y),
        };
        setDefaultPosition(defaultPosition);
        setOpen(true);
    }, [config]);

    if (!isOpen) {
        return null;
    }

    return (
        <StepByStepTutorialContext.Provider
            value={{
                step,
                setStep,
            }}
        >
            <Draggable
                handle="#handle"
                defaultPosition={defaultPosition}
                scale={1}
                bounds="body"
            >
                <div
                    className={classNames(
                        DropdownClassName,
                        'absolute flex flex-col p-3 text-zinc-300 rounded-xl bg-zinc-950',
                    )}
                    style={{
                        left: 0,
                        top: 0,
                        width: size.width,
                        height: size.height,
                        zIndex: HelperWidgetZIndex,
                    }}
                >
                    <div
                        id="handle"
                        className='flex flex-row justify-center items-center cursor-move mb-2 pb-2 border-b border-zinc-800/50'
                    >
                        <DragHandleDots2Icon
                            className='text-zinc-600 mr-2 pointer-events-none'
                        />
                        <StepsTitle
                            step={step}
                            numSteps={config.steps.length}
                            title={stepConfig.title}
                            hasWelcome={config.hasWelcome}
                            hasEnding={config.hasEnding}
                        />
                        <Cross1Icon
                            className='text-zinc-600 hover:text-zinc-300 transition-colors cursor-pointer'
                            onClick={onClose}
                        />
                    </div>
                    {stepConfig.highlights && stepConfig.highlights.map((props, index) => (
                        <Highlighter
                            key={index}
                            {...props}
                        />
                    ))}
                    <ScrollAreaContainer
                        className='flex-1 w-full'
                        style={{
                            maxHeight: '80vh',
                        }}
                    >
                        {typeof (Content) === 'string' ? Content : <Content />}
                    </ScrollAreaContainer>
                    <div className='h-2' />
                    <Pagination
                        hasPrev={step > 0}
                        hasNext={step < config.steps.length - 1}
                        displayDone={config.displayDone}
                        onClickPrev={() => setStep(step => step - 1)}
                        onClickNext={() => {
                            setStep(step => step + 1);
                            stepConfig.onContinue?.();
                        }}
                        onClickSkip={onSkip}
                    />
                </div>
            </Draggable>
        </StepByStepTutorialContext.Provider>
    )
}