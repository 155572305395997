import React from "react"
import { useNavigate } from "react-router-dom";
import { LogOut, CreditCard } from "lucide-react";

import { editorContextStore } from "contexts/editor-context"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FloatTagZIndex } from "components-mobile/constants/zIndex";
import { ManageSubscriptionDialog } from "components-mobile/popup/message-dialog/manage-subscription-dialog";

const dropdownMenuItemClassName = "w-full flex flex-row items-center justify-start px-4 py-2 text-zinc-300 hover:text-lime-500 hover:outline-none select-none cursor-pointer transition-colors";

const iconSize = 14;

function DividerEmpty() {
    return <div className="h-2" />
}

function ManageSubscriptionText() {
    return (
        <>
            <span
                className="pt-[2px]"
            >
                <CreditCard size={iconSize} />
            </span>
            <span
                className="ml-3"
            >
                Manage Subscription
            </span>
        </>
    );
}

function LogOutText() {
    return (
        <>
            <span
                className="pt-[2px]"
            >
                <LogOut
                    size={14}
                />
            </span>
            <span
                className="ml-3"
            >
                Log out
            </span>
        </>
    )
}


type DialogType = "manage-subscription" | undefined;

export function NavBarUserProfile() {
    const editor = editorContextStore(state => state.editor);
    const user = editorContextStore(state => state.user);
    const backend = editorContextStore(state => state.backend);
    const setProjectId = editorContextStore(state => state.setProjectId);
    const [dialogType, setDialogType] = React.useState<DialogType>();
    const navigate = useNavigate();
    const name = user?.displayName || user?.email || 'Anon';
    const initial = name.charAt(0);
    return (
        <DropdownMenu.Root>
            <ManageSubscriptionDialog
                defaultOpen={false}
                open={dialogType === 'manage-subscription'}
                onOpenChange={(value) => setDialogType((prevType) => value ? prevType : undefined)}
                triggerProps={{
                    asChild: true,
                }}
            />
            <DropdownMenu.Trigger className="w-7 h-7 flex justify-center items-center rounded-full bg-lime-500 text-zinc-900 font-semibold select-none shadow-md focus-visible:outline-none">
                {initial.toUpperCase()}
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
                sideOffset={14}
                className='bg-zinc-900 shadow-md border border-zinc-800 rounded-md min-w-[230px] max-w-[350px] text-zinc-300 text-sm flex flex-col justify-center items-start'
                style={{
                    zIndex: FloatTagZIndex,
                }}
            >
                <div className="w-full px-4 py-2 text-zinc-500 border-b border-zinc-800 truncate">
                    {name}
                </div>
                <DividerEmpty />
                <DropdownMenu.Item
                    className={dropdownMenuItemClassName}
                    onClick={() => {
                        setDialogType('manage-subscription');
                    }}
                >
                    <ManageSubscriptionText />
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    className={dropdownMenuItemClassName}
                    onClick={() => {
                        console.log('Sign out user.');
                        setProjectId?.(undefined);
                        backend?.signOutUser().then(() => {
                            editorContextStore.getState().setUser(null);
                            navigate('/login');
                        });
                    }}
                >
                    <LogOutText />
                </DropdownMenu.Item>
                <DividerEmpty />
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}