import React from 'react';
import ReactDOM from 'react-dom';
import styles from './highlighter.module.css';
import { classNames } from 'core/utils/classname-utils';
import { HighlighterZIndex } from 'components/constants/zIndex';
import { DropdownClassName } from 'components/constants/class-names';


type GetElementBoundingRectProps = {
    elementId?: string,
    element?: HTMLElement,
    boundingRect?: {
        left: number,
        top: number,
        width: number,
        height: number,
    },
}

function getElement({
    elementId,
    element,
}: GetElementBoundingRectProps) {
    if (element) {
        return element;
    }
    if (elementId) {
        return document.getElementById(elementId);
    }
    return undefined;
}

function getElementBoundingRect({
    elementId,
    element,
    boundingRect,
}: GetElementBoundingRectProps) {
    if (boundingRect) {
        return boundingRect;
    }
    if (element) {
        return element.getBoundingClientRect();
    }
    if (elementId) {
        return document.getElementById(elementId)?.getBoundingClientRect();
    }
    return undefined;
}


function HighlighterBox() {
    return (
        <div
            className={classNames(
                styles.Pulse,
                'w-full h-full rounded-md shadow-lime-500'
            )}
        />
    )
}

function HighlighterDot() {
    return (
        <div
            className={classNames(
                styles.Pulse,
                'shadow-lime-500',
            )}
            style={{
                width: '22px',
                height: '22px',
                borderRadius: '50%',
            }}
        />
    );
}

function HighlighterPopup({
    className = '',
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                'absolute text-sm',
                DropdownClassName,
                className,
            )}
        >
            {children}
        </div>
    )
}

export type HighlighterType = 'box' | 'dot';

export type HighlighterProps = GetElementBoundingRectProps & {
    type?: HighlighterType,
    visibleDelay?: number,
    attachToTarget?: boolean,
    popupContent?: string,
}

export function Highlighter({
    type = 'dot',
    visibleDelay = 500,
    attachToTarget = false,
    popupContent = '',
    ...props
}: HighlighterProps) {

    const [visible, setVisible] = React.useState(false);

    const [showPopup, setShowPopup] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, visibleDelay);
    }, [visibleDelay]);

    const targetElement = getElement(props);

    React.useEffect(() => {
        if (!targetElement || !popupContent) {
            return;
        }
        const pointerOverEvent = () => {
            setShowPopup(true);
        }
        const pointerLeaveEvent = () => {
            setShowPopup(false);
        }
        targetElement.addEventListener('pointerover', pointerOverEvent);
        targetElement.addEventListener('pointerleave', pointerLeaveEvent);
        return () => {
            targetElement.removeEventListener('pointerover', pointerOverEvent);
            targetElement.removeEventListener('pointerleave', pointerLeaveEvent);
        }
    }, [targetElement, popupContent]);

    const boundingRect = getElementBoundingRect(props);
    if (!boundingRect || !visible) {
        return null;
    }
    attachToTarget = attachToTarget && Boolean(targetElement);

    const portalParent = (attachToTarget && targetElement) ? targetElement : document.body;

    const left = attachToTarget ? 0 : boundingRect.left;
    const top = attachToTarget ? 0 : boundingRect.top;
    const width = boundingRect.width;
    const height = boundingRect.height;

    return ReactDOM.createPortal(
        <div
            className='flex items-center justify-center overflow-visible pointer-events-none'
            style={{
                position: 'absolute',
                left,
                top,
                width,
                height,
                zIndex: HighlighterZIndex,
            }}
        >
            {
                type === 'dot' ?
                    <HighlighterDot /> :
                    <HighlighterBox />
            }
            {
                showPopup &&
                <HighlighterPopup
                    style={{
                        top: (height + 2) + 'px',
                    }}
                >
                    {popupContent}
                </HighlighterPopup>
            }
        </div>,
        portalParent,
    );
}