export function removeUndefinedFromObject<T extends Record<string, any>>(obj: T): Partial<T> {
    if (obj) {
        Object.keys(obj).forEach(function (key) {
            // Get this value and its type
            const value = obj[key];
            const type = typeof value;
            if (value && type === "object") {
                // Recurse...
                removeUndefinedFromObject(value as T);
                // ...and remove if now "empty" (NOTE: insert your definition of "empty" here)
                if (!Object.keys(value).length) {
                    delete obj[key]
                }
            }
            else if (type === "undefined") {
                // Undefined, remove it
                delete obj[key];
            }
        });
    }
    return obj;
}

export function removeKeyFromObjectImmutable<T, K extends keyof T>(obj: T, key: K): Omit<T, K> {
    const { [key]: _, ...rest } = obj;
    return rest;
}

type AnyObject = {
    [key: string]: any;
};

export function deepMerge<T extends AnyObject>(obj1: T, obj2: T): T {
    const result: T = { ...obj1 };

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key]) && obj1.hasOwnProperty(key) && typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
                result[key] = deepMerge(obj1[key], obj2[key]);
            } else {
                result[key] = obj2[key];
            }
        }
    }

    return result;
}

export function dedupeItemsByKey<Item, Key extends keyof Item>(items: Item[], key: Key): Item[] {
    const seenKeys = new Set<Item[Key]>();

    return items.filter(item => {
      const value = item[key];
      if (seenKeys.has(value)) {
        return false;
      } else {
        seenKeys.add(value);
        return true;
      }
    });
}