import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import { classNames } from 'core/utils/classname-utils';

import styles from './right-click-menu.module.css';
import { editorContextStore } from 'contexts/editor-context';

export function EditorRightClickMenuDefault() {
    return (
        <>
            <ContextMenu.Item
                className={classNames(
                    styles.ContextMenuItem,
                    'text-zinc-500',
                )}
                onSelect={() => {
                    editorContextStore.getState().editor?.objects.select();
                }}
            >
                Select All
                <div
                    className={styles.RightSlot}
                >
                    Ctrl + A
                </div>
            </ContextMenu.Item>
        </>
    )
}