import { CustomModelDataset, CustomModelInfo } from 'core/common/types/custom-model-types';
import { PastGeneration, CustomModelDatasetItem } from 'core/common/types';
import { SampleProjectScene } from 'core/common/scene';
import { IScene } from "core/common/scene";
import type { AppRoleType, AppUser, AppUserQuotas, AppUserSubscriptionTier, BackendCallableResponseData, CustomModelPredictionItem, CustomModelTrainingItem, DocsBatchGenerator, EditorAssetContentType, EraseProductArgs, GenerateTemplateItem, HandleCreateCustomModelArgs, HandleCreateCustomModelResponse, HandleCustomModelTrainingStartArgs, HandleCustomModelTrainingStartResponse, HandleCustomModelTrainingStopArgs, HandleCustomModelTrainingStopResponse, HandleStartCustomModelPredictionArgs, HandleStartCustomModelPredictionResponse, HandleStopCustomModelPredictionArgs, HandleStopCustomModelPredictionResponse, HttpsCallable, HttpsCallableResult, ObjectBounds2d, PublicUserId, RegenerateProductArgs, TryOnModelPreviewData, UserAssetInfo, UserAssetInfoType, UserOnboardData, UserProject, UserProjectType, } from "core/common/types";
import { RenderProcessController, RenderJobController } from 'core/common/interfaces';
import { TryOnPreviewGenerator } from './firebase/tryon-preview-generator';
import { ApiModelType, ApiUsageDoc, EmailApiDataDoc, UserApiDataDoc } from 'core/common/types/api';
import { FlairTemplateGenerator, GenerateTemplateTypesenseDoc } from 'core/common/types/template-generator';
import { RenderPipelineArgs, RenderPipelineType } from 'core/common/types/render-args';
import { UpscaleModelType } from 'core/common/types/upscale';
import { StripeListInvoicesParams, StripeListInvoicesResponse } from 'core/common/types/stripe';
import { StripeSubscriptionPriceId, StripeSubscriptionProductId } from 'core/common/types/subscription';
import { ElementsSearchManager } from "backend/firebase/elements-manager";
import { Timestamp } from 'firebase/firestore';
import { BackendUserFeatureFlags } from 'core/controllers/featureFlags/featureFlags';
import { ColorCorrectV2Args, ColorCorrectV2RenderJobDoc, ColorCorrectV2Response } from 'core/common/types/color-correct-v2';

type ImageType = string;

export type CreateSubscriptionsPortalLinkArgs = {
    returnUrl?: string,
}


export type CreateSubscriptionsPortalLinkCallable = HttpsCallable<CreateSubscriptionsPortalLinkArgs, BackendCallableResponseData>;

export type ParseClothImageResult = {
    image_parsed: string,
    image_mask?: string,
    warped_cloth?: string,
    warped_human_mask?: string,
    bbox?: [number, number, number, number],
}

export function isParseClothImageResult(object: any): object is ParseClothImageResult {
    return object && typeof (object.image_parsed) === 'string';
}

export type WarpParsedImageResult = {
    warped_cloth?: string,
    warped_human_mask?: string,
}

export function isWarpParsedImageResult(object: any): object is WarpParsedImageResult {
    return object &&
        typeof (object.warped_cloth) === 'string' &&
        typeof (object.warped_human_mask) === 'string';
}

export type StartRenderJobFunctionArgs = {
    renderPipelineType: RenderPipelineType,
    renderPipelineArgs: RenderPipelineArgs,
};

export type RenderClothImageArgs = {
    render_overview_prompt: string,
    render_overview_negative_prompt?: string,
    face_prompt: string,
    face_negative_prompt?: string,
    warped_cloth_image: ImageType,
    warped_cloth_human_mask_image?: ImageType,
    warped_cloth_mask_image?: ImageType,
    model_image_id?: string,
    model_image?: ImageType,
    model_cloth_mask_image?: ImageType,
    model_parse_image?: ImageType,
    model_preserve_mask_image?: ImageType,
    target_width?: number,
    target_height?: number,
    num_inference_steps?: number,
    crop_expand_ratio?: number,
}

export type ImageCaptionArgs = {
    imageUrl: string;
    prompt?: string;
    extractSubject?: boolean;
}

export type RenderClothImageResult = {
    image: string,
}

export function isRenderClothImageResult(object: any): object is RenderClothImageResult {
    return object?.image && typeof (object.image) === 'string';
}

export type ReceiveRenderResultHandler = (params: {
    imageUrl: string,
    index: number,
}) => Promise<void>

export type StartRenderJobArgs = StartRenderJobFunctionArgs & {
    userSubscriptionTier?: AppUserSubscriptionTier,
    onRenderProgress?: (progress?: number) => void,
    onReceiveRenderResult?: ReceiveRenderResultHandler,
    renderProcessController?: RenderProcessController,
}

export type StartRegenerateProductJobArgs = RegenerateProductArgs & {
    userSubscriptionTier?: AppUserSubscriptionTier,
    renderProcessController?: RenderProcessController,
    onReceiveRenderResult?: ReceiveRenderResultHandler,
};

export type StartRegenerateProductJobResponse = string[];

export type StartEraseProductJobArgs = EraseProductArgs & {
    userSubscriptionTier?: AppUserSubscriptionTier,
    renderProcessController?: RenderProcessController,
    onReceiveRenderResult?: ReceiveRenderResultHandler,
}

export type StartEraseProductJobResponse = {
    erased_image: string,
    mask_image: string,
}

export type UploadUserAssetInfoArgs = {
    uid?: string,
    assetId?: string,
    storagePath: string,
    assetType: UserAssetInfoType,
    caption?: string,
}

export type UpdateUserAssetInfoArgs = {
    uid?: string,
    assetId?: string,
    storagePath?: string,
    assetType: UserAssetInfoType,
    caption?: string,
}

export type DeleteUserAssetInfoArgs = {
    uid?: string,
    assetId: string,
    assetType: UserAssetInfoType,
}

export type UploadUserAssetInfoRespnose = {
    isUpdated: boolean,
    message: string,
    result?: UserAssetInfo,
}

export type GetUserAssetInfoArgs = {
    uid?: string,
    assetId: string,
    assetType: UserAssetInfoType,
}

export type OnUserAssetInfoUpdateArgs = {
    assetType: UserAssetInfoType,
    batchSize: number,
    onUpdate: (docs: UserAssetInfo[]) => void,
}

export type CheckoutSessionDiscount = {
    coupon: string,
}

export type CreateCheckoutSessionParams = {
    price: StripeSubscriptionPriceId,
    quantity?: number,
    success_url?: string,
    cancel_url?: string,
    allow_promotion_codes?: boolean,
    discounts?: CheckoutSessionDiscount[],
}

export type CreateCheckoutSessionResult = {
    url?: string,
    message?: string,
}

export type GetApiUsageGeneratorArgs = {
    batchSize: number,
    modelType?: ApiModelType,
}

export type OnApiUsageUpdateArgs = {
    onUpdate: (generations: any[]) => void,
    batchSize: number,
    modelType?: ApiModelType,
}

export type UpscaleImageArgs = {
    modelType: UpscaleModelType,
    userSubscriptionTier?: AppUserSubscriptionTier,
    renderProcessController?: RenderJobController,
    imageUrl: string,
    inputImageUrl?: string,
    upscale?: 2 | 4,
    onError?: ((error: Error) => void) | undefined,
    prompt?: string,
    max_filter_size?: number,
    img2img_strength?: number,
    num_inference_steps?: number,
    guided_filter_radius?: number,
    guided_filter_eps?: number,
    guided_filter_strength?: number,
}

export type UpdateStripeSusbcriptionArgs = {
    fromProductId: StripeSubscriptionProductId,
    toProductId: StripeSubscriptionProductId,
    toPriceId?: StripeSubscriptionPriceId,
}

export type UpdateStripeSusbcriptionResponse = {
    updated: boolean,
    message: string,
}

export type OnColorCorrectV2UpdateArgs = {
    uid: string,
    jobId: string,
    onUpdate: (doc?: ColorCorrectV2RenderJobDoc) => void,
}

export interface CancelColorCorrectV2JobArgs {
    uid: string,
    jobId: string,
}

export interface AppEnvironmentVairables {
    REACT_APP_SEGMENT_API_KEY: string;
    REACT_APP_MIXPANEL_API_KEY: string;
    REACT_APP_RENDER_API_URL: string;
    REACT_APP_RENDER_CANNY_API_URL: string;
    REACT_APP_REPLACE_PRODUCT_API_URL: string;
    REACT_APP_ERASE_PRODUCT_APP_URL: string;
    REACT_APP_SAM_API_KEY: string;
    REACT_APP_TRYON_PARSE_CLOTH_API_URL: string;
    REACT_APP_TRYON_RENDER_CLOTH_API_URL: string;
    REACT_APP_TRYON_WARP_CLOTH_API_URL: string;
    REACT_APP_CAPTION_API_URL: string;
    REACT_APP_TRYON_SECRET_KEY: string;
    REACT_APP_CLIP_API_URL: string;
    REACT_APP_CLIP_API_KEY: string;
    REACT_APP_IMAGE_API_URL: string;
    REACT_APP_IMAGE_API_KEY: string;
    REACT_APP_CAPTION_SECRET_KEY: string;
    REACT_APP_SEARCH_TEMPLATES_API_URL: string;
    REACT_APP_RENDER_API_ENDPOINT_URL: string;
    REACT_APP_UPSCALE_API_URL: string;
    REACT_APP_UPSCALE_STATE_MANAGER_URL: string;
    REACT_APP_API_PRICING_FACTOR: string;
    REACT_APP_API_QUANTITY_PER_STEP: string;
    REACT_APP_API_QUANTITY_PER_LENGTH: string;
    REACT_APP_API_QUANTITY_PER_CONTROL_IMAGE: string;
    REACT_APP_API_MIN_QUANTITY: string;
    REACT_APP_API_PRICE_PER_QUANTITY: string;
    REACT_APP_CONVERT_IMAGE_API_URL: string;
    REACT_APP_REALTIME_SERVER_API_URL: string;
    REACT_APP_REALTIME_SERVER_TIMEOUT_SECONDS: number;
    REACT_APP_REALTIME_COLOR_CORRECTION_API_URL: string;
    REACT_APP_REMOVE_BACKGROUND_API_URL: string;
    REACT_APP_VERSION: string;
}

export type CreateCustomModelArgs = Omit<HandleCreateCustomModelArgs, 'type'>;
export type StartCustomModelTrainingArgs = Omit<HandleCustomModelTrainingStartArgs, 'type'>;
export type StopCustomModelTrainingArgs = Omit<HandleCustomModelTrainingStopArgs, 'type'>;
export type StartCustomModelPredictionArgs = Omit<HandleStartCustomModelPredictionArgs, 'type'>;
export type StopCustomModelPredictionArgs = Omit<HandleStopCustomModelPredictionArgs, 'type'>;

export type DeleteCustomModelArgs = {
    modelId: string,
}

export type DeleteCustomModelResponse = {
    ok: boolean,
    message: string,
}

export type DeleteCustomModelPredictionArgs = {
    predictionId: string,
}

export type DeleteCustomModelPredictionResponse = {
    ok: boolean,
    message: string,
}

export type OnCustomModelPredictionUpdateArgs = {
    predictionId: string,
    callback: (prediction: CustomModelPredictionItem) => void,
}

export type UpdateCustomModelInfoArgs = {
    modelId: string,
    modelInfo: Partial<CustomModelInfo>,
}

export type GetCustomModelTrainingArgs = {
    modelId: string,
    trainingId: string,
}

export type GetCustomModelTrainingsArgs = {
    modelId: string,
}

export type GetCustomModelTrainingsResponse = CustomModelTrainingItem[];

export type OnCustomModelTrainingUpdateArgs = {
    modelId: string,
    trainingId: string,
    callback: (trainingItem: CustomModelTrainingItem) => void,
}

export type OnCustomModelTrainingCollectionUpdateArgs = {
    modelId: string,
    callback: (trainingItems: CustomModelTrainingItem[]) => void,
}

export type UploadCustomModelDataItemToStorageArgs = {
    data: File | Blob,
    modelId: string,
}

export type UploadCustomModelDataItemToStorageResponse = string | undefined;

export type GetPublicCustomModelPredictionsArgs = {
    modelId?: string,
};

export type GetPublicCustomModelPredictionsResponse = Record<string, CustomModelPredictionItem>;

export type OnCustomModelPredictionsUpdateArgs = {
    publicUserId: PublicUserId,
    modelId?: string,
    callback: (predictions: Record<string, CustomModelPredictionItem>) => void,
}

export interface Backend {
    getDownloadUrlFromStoragePath: (path: string) => Promise<string>;
    deleteImageFromStorage: (path: string) => Promise<void>;
    uploadDataUrlToStorage: (params: {
        data: string,
        contentType: EditorAssetContentType,
        assetId?: string,
    }) => Promise<string>;
    uploadJsonToStorage: (params: {
        data: string,
        assetId?: string,
    }) => Promise<string>;
    isStoragePathURL: (value: string) => boolean;
    cleanupStoragePathURL(storagePath: string): string;
    uploadFileToStorage: (params: {
        data: File | Blob,
        contentType: EditorAssetContentType,
    }) => Promise<string>;
    uploadFileToDatasetStorage: (params: {
        data: File | Blob,
        modelId: string,
        contentType: EditorAssetContentType,
    }) => Promise<string>;
    addUserAssetInfo: (params: UploadUserAssetInfoArgs) => Promise<UploadUserAssetInfoRespnose>;
    deleteUserAssetInfo: (params: DeleteUserAssetInfoArgs) => Promise<UploadUserAssetInfoRespnose>;
    updateUserAssetInfo: (params: UpdateUserAssetInfoArgs) => Promise<UploadUserAssetInfoRespnose>;
    getUserAssetInfo: (params: GetUserAssetInfoArgs) => Promise<{
        isSuccess?: boolean,
        message?: string,
        result?: UserAssetInfo,
    }>;
    countTotalUserAssets: () => Promise<number>;
    getUserAssetInfoGenerator: (params: {
        assetType: UserAssetInfoType,
        batchSize: number,
    }) => DocsBatchGenerator<UserAssetInfo> | undefined;
    onUserAssetInfoUpdate: (params: OnUserAssetInfoUpdateArgs) => (() => void);
    startRenderJob: (args: StartRenderJobArgs) => Promise<unknown>;
    startRegenerateProductJob: (args: StartRegenerateProductJobArgs) => Promise<StartRegenerateProductJobResponse>;
    startEraseProductJob: (args: StartEraseProductJobArgs) => Promise<StartEraseProductJobResponse>,
    onRenderJobUpdate: (reference: string, onNext: (renderJob: any) => void, onError?: ((error: Error) => void) | undefined, onCompletion?: (() => void) | undefined) => (() => void);
    removeBackground: (params: {
        imageUrl: string,
        onError?: ((error: Error) => void) | undefined,
    }) => Promise<string | null>;
    upscaleImage: (params: UpscaleImageArgs) => Promise<string | null>;
    onAuthStateChanged(observer: (user: AppUser | null) => void, onError?: ((error: any) => void) | undefined): (() => void);
    onUserLogin: (params: { uid?: string }) => Promise<void>;
    continueWithGoogle: () => Promise<AppUser | null>,
    sendSignInLinkToEmail: (email: string) => Promise<unknown>,
    signInWithEmailLink: (email: string | null) => Promise<unknown>,
    signInWithEmailAndPassword: (email: string, password: string) => Promise<unknown>,
    signInWithCustomToken: (token: string) => Promise<AppUser | null>,
    getPublicUserId: () => Promise<PublicUserId | undefined>,
    getProjectUsers: (project: string) => Promise<Record<string, AppRoleType> | undefined>,
    deleteProject: (projectId: string) => Promise<void>,
    onUserProjectsUpdate: (userId: string, callback: (snapshot: Record<string, UserProject>) => void) => (() => void),
    getProjectDisplayName: (projectId: string) => Promise<string | undefined>,
    setProjectDisplayName: (projectId: string, displayName: string) => Promise<void>,
    setProjectType: (projectId: string, projectType: UserProjectType) => Promise<void>,
    getProjectType: (projectId: string) => Promise<UserProjectType | undefined>,
    setProjectThumbnail: (projectId: string, thumbnail: string) => Promise<void>,
    createNewProject: HttpsCallable<string, UserProject | null>,
    onUserQuotasUpdate: (params: {
        userId?: string,
        onUpdate: (quotas: AppUserQuotas) => void
    }) => (() => void);
    getUserSubscriptions: () => Promise<any | undefined>,
    onUserSubscriptionUpdate: (callback: (data?: any) => void) => (() => void),
    getProjectSceneData: (projectId: string) => Promise<IScene | undefined>;
    setProjectSceneData: (projectId: string, scene: IScene) => Promise<{
        isUpdated: boolean,
        scene?: IScene,
    }> | undefined;
    signOutUser: () => Promise<void>;
    getUserOnboardData: (uid?: string) => Promise<UserOnboardData>;
    setUserOnboardData(params: {
        uid?: string,
        userOnboardData?: Partial<UserOnboardData>,
    }): Promise<void>,
    isInviteCodeValid(inviteCode: string): Promise<{
        exists: boolean,
        isUsed: boolean,
    }>,
    setInviteCodeUsed(inviteCode: string, email: string, version?: 'v1' | 'v2'): Promise<HttpsCallableResult<BackendCallableResponseData>>,
    doesUserHaveInviteCode(): Promise<HttpsCallableResult<BackendCallableResponseData & { inviteCode?: string }>>;
    getSampleProjectScene(params: { storagePath: string }): Promise<SampleProjectScene | null>;
    addPastGeneration(params: {
        pastGeneration: PastGeneration,
    }): Promise<{
        isUpdated: boolean,
        message: string,
    }>;
    getPastGeneration: (params: {
        generationId: string,
    }) => Promise<any>;
    initializePastGenerator: (params: {
        batchSize: number,
        earliestTimeModified: Timestamp,
    }) => void;
    getPastGenerationGenerator: () => DocsBatchGenerator<any> | undefined;
    createSubscriptionsPortalLink: (args: CreateSubscriptionsPortalLinkArgs) => Promise<string | undefined>,
    createCheckoutSession: (params: CreateCheckoutSessionParams) => Promise<CreateCheckoutSessionResult>,
    deleteCustomModel: (args: DeleteCustomModelArgs) => Promise<DeleteCustomModelResponse>,
    getCustomModelDataset: (modelId: string) => Promise<CustomModelDataset>,
    onCustomModelDatasetUpdate: (
        modelId: string,
        callback: (dataset?: CustomModelDataset) => void,
    ) => (() => void),
    deleteCustomModelDataItem: (params: {
        modelId: string,
        dataId: string,
    }) => Promise<void>,
    onCustomModelDatasetItemUpdate: (
        modelId: string,
        dataId: string,
        callback: (dataItem?: CustomModelDatasetItem) => void,
    ) => (() => void),
    onUserCustomModelsUpdate: (
        publicUserId: PublicUserId,
        callback: (customModels: Record<string, CustomModelInfo>) => void,
    ) => (() => void),
    getPublicCustomModels: () => Promise<CustomModelInfo[]>,
    setCustomModelDataItem: (params: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) => Promise<void>,
    updateCustomModelDataItem: (params: {
        modelId: string,
        dataId: string,
        data: Partial<CustomModelDatasetItem>,
    }) => Promise<void>,
    uploadCustomModelDataItemToStorage: (args: UploadCustomModelDataItemToStorageArgs) => Promise<UploadCustomModelDataItemToStorageResponse>;
    createCustomModel: (args: CreateCustomModelArgs) => Promise<HandleCreateCustomModelResponse>,
    startCustomModelTraining: (args: StartCustomModelTrainingArgs) => Promise<HandleCustomModelTrainingStartResponse>;
    getCustomModelTraining: (args: GetCustomModelTrainingArgs) => Promise<CustomModelTrainingItem | undefined>;
    getCustomModelTrainings: (args: GetCustomModelTrainingsArgs) => Promise<GetCustomModelTrainingsResponse>;
    onCustomModelTrainingUpdate: (args: OnCustomModelTrainingUpdateArgs) => (() => void);
    onCustomModelTrainingCollectionUpdate: (args: OnCustomModelTrainingCollectionUpdateArgs) => (() => void);
    stopCustomModelTraining: (args: StopCustomModelTrainingArgs) => Promise<HandleCustomModelTrainingStopResponse>;
    startCustomModelPrediction: (args: StartCustomModelPredictionArgs) => Promise<HandleStartCustomModelPredictionResponse>;
    stopCustomModelPrediction: (args: StopCustomModelPredictionArgs) => Promise<HandleStopCustomModelPredictionResponse>;
    deleteCustomModelPrediction: (args: DeleteCustomModelPredictionArgs) => Promise<DeleteCustomModelPredictionResponse>;
    getPublicCustomModelPredictions: (args: GetPublicCustomModelPredictionsArgs) => Promise<GetPublicCustomModelPredictionsResponse>;
    onCustomModelPredictionUpdate: (args: OnCustomModelPredictionUpdateArgs) => (() => void);
    onCustomModelPredictionsUpdate: (args: OnCustomModelPredictionsUpdateArgs)  => (() => void);
    getCustomModelInfo: (modelId: string) => Promise<CustomModelInfo | undefined>;
    updateCustomModelInfo: (args: UpdateCustomModelInfoArgs) => Promise<void>;
    sendEmailRedirectLink: (params: {
        email: string,
        name?: string,
        noAuthToken?: boolean,
    }) => Promise<HttpsCallableResult<void | string>> | undefined,
    sendEmailLoginLink: (params: {
        email: string,
        name?: string,
    }) => Promise<HttpsCallableResult<any>>,
    warpParsedClothImage: (params: {
        clothImageUrl: string,
        parsedClothMaskImageUrl: string,
        personImageId?: string,
        epsilon?: number,
        leftColor?: string,
        middleColor?: string,
        rightColor?: string,
    }) => Promise<WarpParsedImageResult | undefined>,
    parseClothImage: (params: {
        imageUrl: string,
        personImageId?: string,
        useClothAlpha?: boolean,
        epsilon?: number,
        leftColor?: string,
        middleColor?: string,
        rightColor?: string,
    }) => Promise<ParseClothImageResult | undefined>,
    renderClothImages: (renderArgs: RenderClothImageArgs) => Promise<RenderClothImageResult | undefined>,
    getImageCaption: (args: ImageCaptionArgs) => Promise<string>,
    getTryOnModelPreview: (args: { imageId: string }) => Promise<TryOnModelPreviewData | undefined> | undefined,
    getTryOnModelPreviewsGenerator: (args: { batchSize: number }) => TryOnPreviewGenerator;
    getMaskImageBoundingBox: (args: { imageUrl: string }) => Promise<ObjectBounds2d | undefined> | undefined;
    getImageText: (args: {
        imageUrl: string | string[],
        texts: string[],
    }) => Promise<string | undefined> | undefined;
    getImageMultiText: (args: {
        imageUrl: string | string[],
        texts: string[][],
    }) => Promise<string[] | undefined> | undefined;
    onUserApiDataUpdate: (callback: (userId?: UserApiDataDoc) => void) => (() => void);
    getApiUsageGenerator: (args: GetApiUsageGeneratorArgs) => DocsBatchGenerator<ApiUsageDoc> | undefined;
    onApiUsageUpdate: (args: OnApiUsageUpdateArgs) => (() => void);
    getEmailApiData: (email: string) => Promise<EmailApiDataDoc>;
    getFirestoreTemplate: (id: string) => Promise<GenerateTemplateItem | undefined>;
    getFirestoreTemplatesByTag: (tag: string) => Promise<GenerateTemplateItem[]>;
    setFirestoreTemplatesByTagNextBatch: (tag: string, pageSize: number) => Promise<boolean>;
    getDefaultGenerateTemplates: () => Promise<GenerateTemplateTypesenseDoc[]>;
    getGenerateTemplateGenerator: (props: {
        batchSize?: number,
    }) => FlairTemplateGenerator;
    getUserInvoices: (params: StripeListInvoicesParams) => Promise<StripeListInvoicesResponse>,
    downloadAndUploadInvoice: (invoiceId: string) => Promise<string | undefined>;
    updateStripeSubscription: (params: UpdateStripeSusbcriptionArgs) => Promise<UpdateStripeSusbcriptionResponse>,
    disconnectRealTimeState: (connectionId: string) => Promise<void> | undefined,
    getTagsInOrder: (projectType?: UserProjectType) => Promise<string[]>;
    getElementsManager: () => ElementsSearchManager;
    getUserFeatureFlags: (userId: string) => Promise<BackendUserFeatureFlags>;

    // Color correct v2
    startColorCorrectV2: (args: ColorCorrectV2Args & {
        renderProcessController: RenderProcessController,
    }) => Promise<ColorCorrectV2Response>,
    onColorCorrectV2Update: (args: OnColorCorrectV2UpdateArgs) => (() => void);
    cancelColorCorrectV2Job: (args: CancelColorCorrectV2JobArgs) => Promise<void>;
}