import { nanoid } from 'nanoid';
import { Assets } from './controllers/assets';
import { getPromptStateFromPromptTemplate } from 'core/common/prompt-template';
import { defaultGenerateTemplate } from 'components/constants/default-generate-template';
import { SampleProjectScene } from 'core/common/scene';
import type { IEditorContext } from 'contexts/editor-context';
import { editorContextVanillaStore } from 'contexts/editor-context';
import Canvas from 'core/canvas';
import { EditorConfig, EditorEventHandler, PromptEditorEventHandler, EditorInitEventHandler, StateUpdater } from './common/types';
import { ActiveHistory } from 'core/controllers/history/active-history';
import { Frame } from './controllers/frame';
import Zoom from './controllers/zoom';
import Objects from './controllers/objects';
import { Scene } from './controllers/scene';
import Events from './common/events';
import Guidelines from './controllers/guidelines';
import { defaultEditorConfig } from './common/constants';
import { GenerationFrames } from './controllers/generation-frames';
import { isStaticImageObjectUploaded } from './utils/type-guards';
import { debugError, debugLog } from './utils/print-utilts';

type CanUpdateStateCallback<K extends keyof IEditorContext> = (
  stateUpdater: StateUpdater<IEditorContext[K]>
) => boolean;

type CanUpdateStateCallbacks = {
  [K in keyof IEditorContext]?: CanUpdateStateCallback<K>;
};

export class Editor {
  private id: string
  public canvas: Canvas
  public frame?: Frame
  public generationFrames: GenerationFrames;
  public zoom: Zoom
  public history: ActiveHistory
  public objects: Objects
  public scene: Scene
  public config: EditorConfig
  public canvasId: string
  public events: Events
  public assets: Assets
  protected guidelines: Guidelines

  private _isDestroyed = false;

  private canUpdateStateCallbacks: CanUpdateStateCallbacks = {};

  get eventEmitter() {
    return this.state.eventEmitter;
  }

  constructor({
    id,
    config,
    initScene,
  }: {
    id: string,
    config: Partial<EditorConfig>,
    initScene?: SampleProjectScene,
  }) {
    this.id = nanoid();
    this.config = {
      ...defaultEditorConfig,
      ...config,
      id,
    }

    // Init canvas
    this.canvasId = id

    const canvas = new Canvas({
      id: this.canvasId,
      config: this.config,
      editor: this,
    })
    this.canvas = canvas

    // Init controllers
    const options = {
      canvas: this.canvas.canvas,
      editor: this,
      config: this.config,
      state: this.state,
    }

    // this.frame = new Frame(options)
    this.generationFrames = new GenerationFrames(options);
    this.zoom = new Zoom(options)
    this.history = new ActiveHistory(options)
    this.objects = new Objects(options)
    this.events = new Events(options)
    this.guidelines = new Guidelines(options)
    this.assets = new Assets(options)
    this.scene = new Scene(options)

    this.state.setEditor(this);

    this.objects.onShuffledStack();

    this.initEventHandlers();

    this.initScene(initScene);
  }


  private async setInitScene(initScene: SampleProjectScene | undefined) {
    try {
      if (!initScene) {
        return;
      }

      if (this._isDestroyed) {
        return;
      }

      await this.scene.importFromJSON(initScene);

      if (this._isDestroyed) {
        // Check again in case the editor is destroyed when we are importing the scene
        return;
      }

      if (!this._firstLoad) {
        return;
      }

      this._firstLoad = false;

      const initSceneGenerationFrame = initScene.generationFrame;

      if (!initSceneGenerationFrame) {
        debugError('Init scene has no valid generation frame');

        const object = this.objects.findOne(object => isStaticImageObjectUploaded(object));

        if (!object) {
          debugError('No valid object found');
        }

        this.generationFrames.centerToObject(object);

        if (defaultGenerateTemplate) {
          this.emit<PromptEditorEventHandler>('prompt-editor:set-state', getPromptStateFromPromptTemplate(defaultGenerateTemplate.prompt));
        }
      }

      this.zoom.zoomToFitAll();

    } catch (error) {
      debugError(error);
    }
  }

  private initScene(initScene: SampleProjectScene | undefined) {
    if (!initScene) {
      debugError("No valid init scene found.");
      return Promise.resolve();
    }

    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        if (this._isDestroyed) {
          debugError("Cannot set init scene because the editor is destroyed.");
          return;
        }
        await this.setInitScene(initScene);
        this.history.initialize();
        this.emit<EditorInitEventHandler>(
          'editor:init',
        );
        resolve();
      }, 50);
    });
  }

  get state() {
    return editorContextVanillaStore.getState();
  }

  private _firstLoad = true;

  private _projectId: string | undefined;

  private async loadProjectSceneData(projectId?: string) {
    if (this._isDestroyed) {
      // console.log(`Editor ${this.id} is already destroyed`);
      return;
    }
    if (projectId && projectId !== this._projectId) {
      // console.log(`Editor ${this.id}: Set cached project id from ${this._projectId} to ${projectId}`);
      this._projectId = projectId;
      const data = await this.state.backend?.getProjectSceneData(projectId);

      await this.initScene(data);
    }
  }

  private unsubscribeActiveLeftPanels?: () => void;
  private unsubscribeProjectId?: () => void;

  private initEventHandlers() {
    this.unsubscribeActiveLeftPanels = editorContextVanillaStore.subscribe(
      (state) => state.activeLeftPanels,
      (activeLeftPanels) => {
        const isGenerating = activeLeftPanels.findIndex(e => e === 'Generate') > -1;
        if (this.state.activeInpaintBrush && !isGenerating) {
          this.state.setActiveInpaintBrush(null);
        }
      }
    );
    this.unsubscribeProjectId = editorContextVanillaStore.subscribe(
      (state) => state.projectId,
      (projectId) => {
        this.loadProjectSceneData(projectId);
      }
    );
  }

  private removeEventHandlers() {
    this.unsubscribeActiveLeftPanels?.();
    this.unsubscribeProjectId?.();
  }

  public emit<T extends EditorEventHandler>(name: T['type'], ...args: Parameters<T['handler']>) {
    return this.eventEmitter.emit(name, ...args);
  }

  public on<T extends EditorEventHandler>(name: T['type'], handler: T['handler']) {
    return this.eventEmitter.on(name, handler);
  }

  public once<T extends EditorEventHandler>(name: T['type'], handler: T['handler']) {
    return this.eventEmitter.once(name, handler);
  }

  public off<T extends EditorEventHandler>(name: T['type'], handler: T['handler']) {
    return this.eventEmitter.off(name, handler);
  }

  public debug() {
    console.log({
      objects: this.canvas.canvas?.getObjects(),
      json: this.canvas.canvas?.toJSON(),
    })
  }

  public destroy() {
    this._isDestroyed = true;
    this.eventEmitter.destroy();
    this.removeEventHandlers();
    this.canvas.destroy();
    this.events.destroy();
    this.history.destroy();
    this.generationFrames.destroy();
    // this._firstLoad = true;
    // this._projectId = undefined;
    this.state.setEditor(null);
    this.canUpdateStateCallbacks = {};
  }

  // CONTEXT MENU
  public cancelContextMenuRequest = () => {
    this.state.setContextMenuRequest(null);
  }

  setCanUpdateStateCallback<K extends keyof IEditorContext>(
    key: K,
    callback: CanUpdateStateCallback<K>,
  ) {
    // @ts-ignore
    this.canUpdateStateCallbacks[key] = callback;
  }

  removeCanUpdateStateCallback<K extends keyof IEditorContext>(
    key: K,
    callback?: CanUpdateStateCallback<K>,
  ) {
    if (callback) {
      if (this.canUpdateStateCallbacks[key] === callback) {
        this.canUpdateStateCallbacks[key] = undefined;
      }
    } else {
      this.canUpdateStateCallbacks[key] = undefined;
    }
  }

  public canUpdateState<K extends keyof IEditorContext>(
    key: K,
    stateUpdater: StateUpdater<IEditorContext[K]>,
  ) {
    const callback = this.canUpdateStateCallbacks[key];
    if (callback) {
      return callback(stateUpdater);
    }
    return true;
  }
}
