import { ImageFormat } from "./image";

export enum DownloadImageResizeOption {
    PointFiveX = '0.5x',
    PointSevenFiveX = '0.75x',
    OneX = '1x',
    OnePointFiveX = '1.5x',
    TwoX = '2x',
    Threex = '3x',
    Width512 = '512w',
    Width1024 = '1024w',
    Width2048 = '2048w',
    // Custom = 'custom',
}

export type DownloadImageMetadata = {
    downloadOptionResize?: DownloadImageResizeOption,
    downloadOptionFormat?: ImageFormat,
    downloadOptionSuffix?: string,
}