import React from "react"
import { MixIcon } from '@radix-ui/react-icons';
import {
    Folder,
    Type,
    LucideProps,
    RefreshCcw,
    Image,
    Settings2,
    Eraser,
    User,
    Shirt,
    Box,
    LucideIcon,
    PersonStanding,
} from 'lucide-react';
import { LeftPanelItemType } from "components/constants/editor-options";

const MixIconLucide: React.ForwardRefExoticComponent<LucideProps> = React.forwardRef(function MixIconLucide({
    size = 24,
    className,
}: Partial<LucideProps>, forwarededRef) {
    return (
        <MixIcon
            ref={forwarededRef}
            width={size}
            height={size}
            className={className}
        />
    )
})

export const PANELS_LIST_ICONS: Record<LeftPanelItemType, LucideIcon> = {
    'Generate': RefreshCcw,
    'Assets': Folder,
    'Text': Type,
    'WordMask': Type,
    'PaintPrompt': Image,
    'Edit': Settings2,
    'MagicErase': Eraser,
    'RemoveBackground': Eraser,
    'ImageVariations': Image,
    'Elements': MixIconLucide,
    'Humans': User,
    "TryOnUploadCloth": Shirt,
    'TransformProps3d': Box,
    'RegenerateProduct': RefreshCcw,
    'ReplaceProduct': RefreshCcw,
    'TryOnSelectPose': PersonStanding,
    'TryOnRender': Settings2,
    'UpscaleV2': Box,
    // 'TryOnFixDetails': Shirt,
    // 'TryOnChangeBackground': Shirt,
    // 'TryOnChangeFace': Shirt,
};

export const DEFAULT_ICON = Folder;