import { DEFAULT_CANVAS_LENGTH, PROPERTIES_TO_INCLUDE, defaultObjectOptions } from 'core/common/constants';
import { isStaticImageObject, isStaticImageObjectColor } from 'core/utils/type-guards';
import { isStaticImageObjectHed } from 'core/utils/type-guards';
import { DEFAULT_VIEWPORT_TRANSFORM, MAX_CANVAS_LENGTH, RENDER_CANVAS_LEGNTH } from 'core/common/constants';
import { BACKGROUND_DARK } from 'components/constants/colors';
import { fabric } from "fabric"
import { FabricCanvas } from "core/common/interfaces"
import { BBox2d, EditorConfig, ObjectBounds2d } from "core/common/types"
import type { Editor } from "core/editor"
import { canHedImageBeColored, doesHedImageHaveHedUrl, resizeImageCanvasElement, canHedImageColorBeUsed, getImageData, isImageChannelBlack } from 'core/utils/image-utils';
import { setActiveObjects, setFabricObjectMetadata } from 'core/utils/fabric';
import { getCenterFromBounds, isPointInBounds } from 'core/utils/bbox-utils';
import { isStaticImageObject3d } from 'core/common/types/3d';
import { StaticImageElementColorDisplayType } from 'core/common/types/elements';
import { StaticImageObjectHed } from 'core/common/types/hed-image-element';
import { ChannelRGBA } from 'core/common/types/image';
import Zoom from './zoom';
import { ILayer, LayerType } from 'core/common/layers';
import ObjectImporter from 'core/utils/object-importer';
import { IObject, getSceneLayers } from 'core/common/scene';
import { debugError, debugLog } from 'core/utils/print-utilts';
import { initFabricFilterBackend } from 'core/utils/object-filter-utils';
import { downloadImageDataUrl } from 'components/utils/data';
import { cloneDeep } from 'lodash';
import ObjectExporter from 'core/utils/object-exporter';
import { removeObjectFromGroupTemporarily } from 'core/utils/geometry-utils';
import { SceneJSON } from 'core/common/types/scene-json';

const imageFilterColors = new Set([
    '#000000',
    '#ffffff',
    '#0000ff',
    '#00ff00',
    '#00ffff',
]);


interface ImageInfo {
    imageDataUrl?: string,
    isEmpty?: boolean,
}

interface MaskImageInfo {
    imageDataUrl?: string,
    hasProduct?: boolean,
    hasColoredProps?: boolean,
    hasShapeProps?: boolean,
}

interface CanvasSize {
    width: number,
    height: number,
}

export interface RenderCanvasControllerConfig {
    background: string,
    size: CanvasSize,
    shadow: any,
    propertiesToInclude?: string[],
}

export interface GetDataUrlsProps {
    readCanvasData?: boolean,
    generationFrameBounds: ObjectBounds2d,
    alwaysUseShapeControl: boolean,
    sceneJson?: any,
    sceneObjects?: fabric.Object[],
    targetWidth?: number, // width of the image after upscale
    targetHeight?: number, // width of the image after upscale
}

enum RenderCanvasControllerStatus {
    None = "None",
    Idle = "Idle",
    GeneratingDataURLs = "GeneratingDataURLs",
}

export interface GenerationFrameDataOutput {
    image?: string,
    mask?: string,
    hedImage?: string,
    attentionMask?: string,
    isImageEmpty?: boolean,
    hasProps?: boolean,
    hasProduct?: boolean,
    hasColoredProps?: boolean,
    hasShapeProps?: boolean,
    sceneJSON?: SceneJSON,
}

export class RenderCanvasController {
    private editor: Editor;
    private config: RenderCanvasControllerConfig;
    private canvas: FabricCanvas;
    private canvasElement: HTMLCanvasElement;
    private status = RenderCanvasControllerStatus.None;


    private imageFilters = Array.from(imageFilterColors).reduce<Record<string, fabric.IBlendImageFilter>>((result, color) => {
        result[color] = new fabric.Image.filters.BlendColor({
            color,
            mode: 'tint',
            alpha: 1.0,
        });
        return result;
    }, {});

    get blackFilter() {
        return this.imageFilters['#000000'];
    }

    get whiteFilter() {
        return this.imageFilters['#ffffff'];
    }

    get blueFilter() {
        return this.imageFilters['#0000ff'];
    }

    constructor({
        editor,
        config,
    }: {
        editor: Editor,
        config: RenderCanvasControllerConfig,
    }) {
        initFabricFilterBackend();

        this.editor = editor;
        this.config = config;
        this.canvasElement = fabric.util.createCanvasElement();
        this.canvas = new fabric.Canvas(
            // "offscreen-canvas",
            this.canvasElement,
            {
                backgroundColor: 'transparent',
                preserveObjectStacking: true,
                fireRightClick: false,
                height: RENDER_CANVAS_LEGNTH,
                width: RENDER_CANVAS_LEGNTH,
            },
        ) as FabricCanvas;
        this.status = RenderCanvasControllerStatus.Idle;
    }

    get isIdle() {
        return this.status === RenderCanvasControllerStatus.Idle;
    }

    protected zoomInternal(
        center: { x: number, y: number },
        zoomFitRatio: number,
    ) {
        const zoomRatio = Zoom.getZoomRatio(zoomFitRatio);
        const defaultTransform = [1, 0, 0, 1, 0, 0];
        defaultTransform[0] = zoomRatio;
        defaultTransform[3] = zoomRatio;
        defaultTransform[4] = ((this.canvas.getWidth() / zoomRatio / 2) - center.x) * zoomRatio;
        defaultTransform[5] = ((this.canvas.getHeight() / zoomRatio / 2) - center.y) * zoomRatio;
        this.canvas.setViewportTransform(defaultTransform);

        this.canvas.requestRenderAll();
    }

    private zoomToBounds = (bounds: ObjectBounds2d) => {
        const zoomFitRatio = Zoom.getBBoxZoomFitRatio({
            ...bounds,
            canvas: this.canvas,
            frameMargin: 0,
        });
        const center = getCenterFromBounds(bounds);

        debugLog(`Zoom fit ratio: ${zoomFitRatio}; center: ${JSON.stringify(center)}`);

        this.zoomInternal(center, zoomFitRatio);
    }

    private processObjectJson({
        object,
        zIndex,
    }: {
        object: ILayer,
        zIndex: number,
    }): ILayer & {zIndex: number} {
        const {
            preview,
            ...cleanedObject
        } = object;

        return {
            ...cleanedObject,
            zIndex,
        };
    }

    exportToJSON({
        objects,
        generationFrameBounds,
    }: {
        objects?: fabric.Object[],
        generationFrameBounds: ObjectBounds2d,
    }) {
        if (!objects || objects.length <= 0) {
            return undefined;
        }

        const objectExporter = new ObjectExporter();

        const sceneJSON: SceneJSON = {
            objects: {},
            generationFrameBounds,
        };

        objects.forEach((object, zIndex) => {
            const exportedObject = objectExporter.export(
                object as ILayer,
                defaultObjectOptions as Required<ILayer>,
            );

            sceneJSON.objects[object.id] = this.processObjectJson({
                object: exportedObject,
                zIndex,
            });
        });

        return sceneJSON;
    }

    private async importFromJSON(sceneJSON: SceneJSON) {
        const objectImporter = new ObjectImporter(this.editor);
        const layers = getSceneLayers(sceneJSON);
        const updatedTemplateLayers = layers.map((layer) => {
            if (layer.type === LayerType.BACKGROUND) {
                return {
                    ...layer,
                    shadow: this.config.shadow,
                }
            }
            return layer
        });

        const importObjectPromises: Promise<void>[] = [];

        const canvasObjects: (fabric.Object | undefined | null)[] = new Array(updatedTemplateLayers.length);

        for (const layer of updatedTemplateLayers as Required<(ILayer & { zIndex: number })[]>) {
            importObjectPromises.push(
                objectImporter.import(
                    layer,
                    defaultObjectOptions as Required<ILayer>,
                ).then((element) => {
                    if (element) {
                        // this.canvas.add(element);
                        if (layer.zIndex >= 0 && layer.zIndex < canvasObjects.length) {
                            canvasObjects[layer.zIndex] = element;
                        } else {
                            canvasObjects.push(element);
                        }
                    } else {
                        console.log("UNABLE TO LOAD OBJECT: ", layer)
                    }
                }).catch((e) => {
                    console.error(e);
                })
            )
        }

        await Promise.all(importObjectPromises);

        const canvasObjectsFiltered: fabric.Object[] = canvasObjects.filter(o => Boolean(o)) as fabric.Object[];

        this.importObjectsInternal(canvasObjectsFiltered);
    }

    private addObjectToCanvas(object: fabric.Object) {
        object.hasControls = false;
        object.selectable = false;
        this.canvas.add(object);
    }

    private importObjectsInternal(objects: fabric.Object[]) {
        const {
            canvas,
        } = this;

        canvas.remove(...canvas.getObjects());

        objects.forEach((object) => {
            this.addObjectToCanvas(object);
        });
    }

    private cloneObject = async (object: fabric.Object) => {
        const clonedObject = await new Promise<fabric.Object>((resolve) => {
            removeObjectFromGroupTemporarily(
                object,
                (object) => {
                    object.clone(resolve, {
                        ...PROPERTIES_TO_INCLUDE,
                        ...this.config.propertiesToInclude ?? {},
                    });
                },
            );
        });

        clonedObject.metadata = cloneDeep(object.metadata);

        return clonedObject;
    }

    private async importFromObjects(objects: fabric.Object[]) {
        const clonedObjects = await Promise.all(objects.map(this.cloneObject));

        this.importObjectsInternal(clonedObjects);
    }


    private renderCanvasElement(options: fabric.IDataURLOptions = {}) {
        this.canvas.renderAll();
        const multiplier = (options.multiplier || 1);
        return this.canvas.toCanvasElement(multiplier, options);
    }

    static getCanvasSize(
        generationFrameBounds: ObjectBounds2d,
        targetLength = RENDER_CANVAS_LEGNTH,
    ) {
        const { width, height } = generationFrameBounds;
        if (!width || !height) {
            return {
                width: targetLength,
                height: targetLength,
            }
        }

        // targetLength = Math.min(targetLength, MAX_CANVAS_LENGTH);

        const scale = targetLength / Math.max(width, height);
        return {
            width: scale * width,
            height: scale * height,
        }
    }



    private static async resizeCanvas(
        from: HTMLCanvasElement,
        to: HTMLCanvasElement,
    ) {
        if (from.width === to.width || from.height === to.height) {
            return from;
        }
        return await resizeImageCanvasElement({
            from,
            to,
        });
    }

    private async onlyShowObjectsTemporarily(
        objects: fabric.Object[],
        callback: () => Promise<void>,
    ) {
        if (!objects || objects.length <= 0) {
            return await callback();
        }
        const visibleObjectIds = new Set(objects.map(object => object.id));
        const previousObjectsVisibility: Record<string, boolean> = {};
        const objectsList = this.canvas.getObjects();
        objectsList.forEach((object) => {
            // Save previous object visibility
            previousObjectsVisibility[object.id] = Boolean(object.visible);
            object.visible = visibleObjectIds.has(object.id);
        });
        try {
            await callback();
        } catch (error) {
            console.error(error);
        }
        // Restore previous object visibility
        objectsList.forEach((object) => {
            object.visible = previousObjectsVisibility[object.id];
        });
    }

    private static getImageMaskFilterTypeFromParams({
        useInpaint,
        useColor,
        shouldGenerateHed,
    }: {
        useInpaint: boolean,
        useColor: boolean,
        shouldGenerateHed: boolean,
    }) {
        return '#' +
            (useInpaint ? 'ff' : '00') +
            (useColor ? 'ff' : '00') +
            (shouldGenerateHed ? 'ff' : '00');
    }

    private static getImageMaskFilterParams(
        object: fabric.Object & fabric.StaticImage,
        alwaysUseShape = false,
    ) {
        if (isStaticImageObjectHed(object)) {

            return {
                useInpaint: false,
                useColor: Boolean(canHedImageColorBeUsed(object)),
                shouldGenerateHed: !doesHedImageHaveHedUrl(object),
            };

        } else if (isStaticImageObjectColor(object)) {
            // console.log(`Always use shape? ${alwaysUseShape}`);

            return {
                useInpaint: false,
                useColor: true,
                shouldGenerateHed: alwaysUseShape,
            };
        } else if (isStaticImageObject3d(object)) {
            return {
                useInpaint: false,
                useColor: false,
                shouldGenerateHed: true,
            };

        } else {

            return {
                useInpaint: true,
                useColor: true,
                shouldGenerateHed: true,
            }
        }
    }

    private static getImageMaskFilterType(
        object: fabric.Object,
        alwaysUseShape = false,
    ) {
        if (!object || !isStaticImageObject(object)) {
            return undefined;
        }

        return RenderCanvasController.getImageMaskFilterTypeFromParams(
            RenderCanvasController.getImageMaskFilterParams(
                object,
                alwaysUseShape,
            )
        );
    }

    private static applyImageMaskFilter(
        object: fabric.Object,
        imageFilters: Record<string, fabric.IBaseFilter>,
        alwaysUseShape = false,
    ) {
        if (!isStaticImageObject(object)) {
            return;
        }
        const filterType = RenderCanvasController.getImageMaskFilterType(
            object,
            alwaysUseShape,
        );

        setFabricObjectMetadata(
            object,
            'imageMaskFilterType',
            filterType,
        );

        if (!filterType) {
            return;
        }

        const filter = imageFilters[filterType];

        if (!filter) {
            return;
        }

        object.filters = [filter];
        object.applyFilters();
    }

    private static doesStaticImageRequireMask(object: any) {
        return isStaticImageObjectHed(object) ||
            isStaticImageObject3d(object) ||
            isStaticImageObjectColor(object);
    }

    private getMaskImageInfo(
        maskImageElement?: HTMLCanvasElement,
        readCanvasData: boolean = true,
    ): MaskImageInfo {
        if (!maskImageElement) {
            return {};
        }

        // console.log(`getMaskImageInfo: read canvas data: ${readCanvasData}`);

        const imageDataUrl = maskImageElement.toDataURL('image/png');

        if (!readCanvasData) {
            return {
                imageDataUrl,
            };
        }

        const maskImageData = getImageData(maskImageElement);

        if (!maskImageData) {
            return {};
        }

        const hasProduct = !isImageChannelBlack(
            maskImageData.data,
            ChannelRGBA.R,
        );

        const hasColoredProps = !isImageChannelBlack(
            maskImageData.data,
            ChannelRGBA.G,
        );

        const hasShapeProps = !isImageChannelBlack(
            maskImageData.data,
            ChannelRGBA.B,
        );

        return {
            imageDataUrl,
            hasProduct,
            hasColoredProps,
            hasShapeProps,
        };
    }

    private async getImageMask({
        ctx1,
        outputCanvas,
        renderCanvasOptions,
        objectsInsideGenerationFrame,
        dx, dy,
        dw, dh,
        alwaysUseShape = false,
        readCanvasData = true,
    }: {
        ctx1: CanvasRenderingContext2D,
        outputCanvas: HTMLCanvasElement,
        renderCanvasOptions: fabric.IDataURLOptions,
        objectsInsideGenerationFrame: fabric.Object[],
        dx: number,
        dy: number,
        dw: number,
        dh: number,
        alwaysUseShape?: boolean,
        readCanvasData?: boolean,
    }) {
        const visibleObjects = objectsInsideGenerationFrame.filter(isStaticImageObject);
        if (!visibleObjects ||
            visibleObjects.length <= 0 ||
            visibleObjects.filter(RenderCanvasController.doesStaticImageRequireMask).length <= 0) {

            // debugLog(visibleObjects);

            console.warn(`No visible object amongst ${visibleObjects?.length} objects requires mask`);

            return undefined;
        }

        const maskRef: { current?: string } = { current: undefined };
        const maskImageInfoRef: { current?: MaskImageInfo } = { current: undefined };

        await this.onlyShowObjectsTemporarily(
            visibleObjects,
            async () => {
                const prevFilters: Record<string, fabric.IBaseFilter[] | undefined> = {};
                visibleObjects.forEach((object) => {
                    if (!isStaticImageObject(object)) {
                        return;
                    }
                    prevFilters[object.id] = object.filters && [...object.filters];
                    RenderCanvasController.applyImageMaskFilter(
                        object,
                        this.imageFilters,
                        alwaysUseShape,
                    );
                });

                ctx1.globalCompositeOperation = 'source-over';
                ctx1.fillStyle = 'black';
                ctx1.fillRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);

                const tmpCanvas0 = this.renderCanvasElement(renderCanvasOptions);

                // await downloadImageDataUrl(tmpCanvas0.toDataURL(), "image-mask-tmp-canvas0-output");

                ctx1.globalCompositeOperation = 'source-over';
                ctx1.drawImage(
                    tmpCanvas0,
                    0, 0,
                    tmpCanvas0.width, tmpCanvas0.height,
                    dx, dy,
                    dw, dh,
                );

                const maskImageElement = await RenderCanvasController.resizeCanvas(ctx1.canvas, outputCanvas);

                // Output image data information

                maskRef.current = maskImageElement?.toDataURL('image/png');

                maskImageInfoRef.current = this.getMaskImageInfo(
                    maskImageElement,
                    readCanvasData,
                );

                visibleObjects.forEach((object) => {
                    if (!isStaticImageObject(object)) {
                        return;
                    }
                    object.filters = prevFilters[object.id];
                    object.applyFilters();
                });
            }
        );
        return maskImageInfoRef.current;
    }

    private async colorHedImagesTemporarily(
        objects: fabric.Object[],
        callback: () => Promise<void>,
    ) {
        // Show color images
        const hedImages: StaticImageObjectHed[] = objects.filter((object) => object.visible && canHedImageBeColored(object)) as any;
        const prevFilters: Record<string, fabric.IBaseFilter[] | undefined> = {};

        hedImages.forEach((image) => {
            prevFilters[image.id] = image.filters;

            const colorDisplayType = image.metadata?.colorDisplayType;
            if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
                image.filters = [
                    this.blackFilter,
                    ...(image.filters ?? []),
                ];
                image.applyFilters();
            }
        });

        await callback();

        hedImages.forEach((image) => {
            image.filters = prevFilters[image.id];
            image.applyFilters();
        });
    }

    private static isReferenceObjectVisible(object: fabric.Object) {
        if (!isStaticImageObjectHed(object)) {
            return true;
        }
        if (canHedImageBeColored(object)) {
            return true;
        }
        return false;
    }

    private getImageInfo(
        imageElement: HTMLCanvasElement | undefined,
        readCanvasData = false,
        imageChannelToCheck = ChannelRGBA.A,
    ): ImageInfo {
        if (!imageElement) {
            return {};
        }

        // console.log(`getImageInfo: read canvas data: ${readCanvasData}`);

        const imageDataUrl = imageElement.toDataURL('image/png');

        if (!readCanvasData) {
            return {
                imageDataUrl,
            };
        }

        const imageData = getImageData(imageElement);

        if (!imageData) {
            return {};
        }

        const isEmpty = isImageChannelBlack(
            imageData.data,
            imageChannelToCheck,
        );


        return {
            imageDataUrl,
            isEmpty,
        };
    }

    private async getReferenceImageInternal({
        ctx1,
        outputCanvas,
        renderCanvasOptions,
        dx, dy,
        dw, dh,
        readCanvasData = true,
    }: {
        ctx1: CanvasRenderingContext2D,
        outputCanvas: HTMLCanvasElement,
        renderCanvasOptions: fabric.IDataURLOptions,
        dx: number,
        dy: number,
        dw: number,
        dh: number,
        readCanvasData?: boolean,
    }) {
        const tmpCanvas0 = this.renderCanvasElement(renderCanvasOptions);

        // Crop image
        ctx1.globalCompositeOperation = 'source-over';
        ctx1.drawImage(
            tmpCanvas0,
            0, 0,
            tmpCanvas0.width, tmpCanvas0.height,
            dx, dy,
            dw, dh,
        );

        // Draw inpaint mask

        // const inpaintingCanvas = this.editor.generationFrames.inpaintingCanvas;
        // if (inpaintingCanvas) {
        //     ctx1.globalCompositeOperation = 'destination-out';
        //     ctx1.drawImage(
        //         inpaintingCanvas,
        //         0, 0,
        //         inpaintingCanvas.width, inpaintingCanvas.height,
        //         dx, dy,
        //         dw, dh,
        //     );
        // }

        const imageElement = await RenderCanvasController.resizeCanvas(ctx1.canvas, outputCanvas);

        return this.getImageInfo(
            imageElement,
            readCanvasData,
        );
    }

    private async getReferenceImage({
        objectsInsideGenerationFrame,
        ...props
    }: {
        ctx1: CanvasRenderingContext2D,
        outputCanvas: HTMLCanvasElement,
        renderCanvasOptions: fabric.IDataURLOptions,
        objectsInsideGenerationFrame: fabric.Object[],
        dx: number,
        dy: number,
        dw: number,
        dh: number,
        readCanvasData?: boolean,
    }) {
        const visibleObjects = objectsInsideGenerationFrame.filter(RenderCanvasController.isReferenceObjectVisible);


        const hedImageRef: { current?: ImageInfo } = { current: undefined };
        await this.onlyShowObjectsTemporarily(
            visibleObjects,
            async () => {
                await this.colorHedImagesTemporarily(
                    visibleObjects,
                    async () => {
                        this.canvas.renderAll();
                        hedImageRef.current = await this.getReferenceImageInternal(props);
                    }
                );
            }
        );
        return hedImageRef.current;
    }

    private static async removeAllFiltersTemporarily(
        objects: fabric.StaticImage[],
        callback: () => Promise<void>,
    ) {
        if (!objects || objects.length <= 0) {
            return await callback();
        }
        const prevFilters: Record<string, fabric.IBaseFilter[] | undefined> = {};
        objects.forEach((object) => {
            prevFilters[object.id] = object.filters;
            object.filters = [];
            object.applyFilters();
        });
        await callback();
        objects.forEach((object) => {
            object.filters = prevFilters[object.id];
            object.applyFilters();
        });
    }

    private async getHedImageMask({
        ctx1,
        outputCanvas,
        renderCanvasOptions,
        objectsInsideGenerationFrame,
        dx, dy,
        dw, dh,
        readCanvasData = true,
    }: {
        ctx1: CanvasRenderingContext2D,
        outputCanvas: HTMLCanvasElement,
        renderCanvasOptions: fabric.IDataURLOptions,
        objectsInsideGenerationFrame: fabric.Object[],
        dx: number,
        dy: number,
        dw: number,
        dh: number,
        readCanvasData?: boolean,
    }) {
        // Find the hed images that intersects with the generation frame
        const hedObjects = objectsInsideGenerationFrame.filter((object) => isStaticImageObjectHed(object) && doesHedImageHaveHedUrl(object)) as any as fabric.StaticImage[];
        if (hedObjects.length <= 0) {
            return undefined;
        }
        const hedImageRef: { current?: ImageInfo } = { current: undefined };
        await this.onlyShowObjectsTemporarily(
            // @ts-ignore
            hedObjects,
            async () => {
                await RenderCanvasController.removeAllFiltersTemporarily(
                    hedObjects,
                    async () => {
                        ctx1.globalCompositeOperation = 'source-over';
                        ctx1.fillStyle = 'black';
                        ctx1.fillRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);

                        const tmpCanvas0 = this.renderCanvasElement(renderCanvasOptions);

                        ctx1.drawImage(
                            tmpCanvas0,
                            0, 0,
                            tmpCanvas0.width, tmpCanvas0.height,
                            dx, dy,
                            dw, dh,
                        );

                        const hedImageElement = await RenderCanvasController.resizeCanvas(ctx1.canvas, outputCanvas);

                        hedImageRef.current = this.getImageInfo(
                            hedImageElement,
                            readCanvasData,
                            ChannelRGBA.R,
                        );
                    }
                );

            }
        );
        return hedImageRef.current;
    }

    private resizeCanvas({
        targetWidth,
        targetHeight,
    }: {
        targetWidth: number,
        targetHeight: number,
    }) {
        // this.canvas.width = targetWidth;
        // this.canvas.height = targetHeight;
        this.canvas.setWidth(targetWidth);
        this.canvas.setHeight(targetHeight);
        this.canvas.calcOffset();
    }

    private getDataURLsCanvasSize({
        targetWidth,
        targetHeight,
    }: {
        targetWidth?: number,
        targetHeight?: number,
    }) {
        if (targetWidth && targetHeight) {

            this.resizeCanvas({
                targetWidth,
                targetHeight,
            });

            return {
                width: targetWidth,
                height: targetHeight,
                targetLength: Math.max(targetWidth, targetHeight),
            };
        }

        const width = this.canvas.width ?? RENDER_CANVAS_LEGNTH;
        const height = this.canvas.height ?? RENDER_CANVAS_LEGNTH;

        return {
            width,
            height,
            targetLength: RENDER_CANVAS_LEGNTH,
        };
    }

    public async getDataURLs(
        props: GetDataUrlsProps,
        options?: fabric.IDataURLOptions,
    ): Promise<GenerationFrameDataOutput> {
        if (!this.isIdle) {
            // console.log('canvas: is rendering generation frame data urls');

            debugError("Processing data url");

            return {};
        }

        this.status = RenderCanvasControllerStatus.GeneratingDataURLs;

        try {

            const {
                sceneJson,
                sceneObjects,
                readCanvasData = true,
                targetWidth,
                targetHeight,
            } = props;

            if (sceneJson) {
                await this.importFromJSON(sceneJson);
            } else if (sceneObjects) {
                await this.importFromObjects(sceneObjects);
            } else {
                debugError("No valid scene JSON or objects.");

                return {};
            }

            const {
                width,
                height,
                targetLength,
            } = this.getDataURLsCanvasSize({
                targetWidth,
                targetHeight,
            });

            debugLog(`Canvas size: [${width}, ${height}]; Target length: ${targetLength}`);

            const imageRef: { current: string | undefined } = { current: undefined };
            const maskRef: { current: string | undefined } = { current: undefined };
            const hedImageRef: { current: string | undefined } = { current: undefined };

            const imageFlagsRef: {
                isImageEmpty: boolean,
                hasProps: boolean,
                hasProduct: boolean,
                hasColoredProps: boolean,
                hasShapeProps: boolean,
            } = {
                isImageEmpty: false,
                hasProps: false,
                hasProduct: false,
                hasColoredProps: false,
                hasShapeProps: false,
            };

            const {
                generationFrameBounds,
                alwaysUseShapeControl,
            } = props;

            this.zoomToBounds(generationFrameBounds);

            const dx = 0;
            const dy = 0;
            const dw = width;
            const dh = height;

            const tmpCanvas1 = fabric.util.createCanvasElement();
            const tmpCanvas2 = fabric.util.createCanvasElement();
            tmpCanvas1.width = width;
            tmpCanvas1.height = height;
            tmpCanvas2.width = tmpCanvas1.width;
            tmpCanvas2.height = tmpCanvas1.height;
            const ctx1 = tmpCanvas1.getContext('2d');
            const ctx2 = tmpCanvas2.getContext('2d');

            if (!ctx1 || !ctx2) {
                debugError("Generation frame contexts are invalid");
                return {};
            }

            const { width: outputWidth, height: outputHeight } = RenderCanvasController.getCanvasSize(
                generationFrameBounds,
                targetLength,
            );

            debugLog(`Output size: [${outputWidth}, ${outputHeight}]; Target length: ${targetLength}`);

            const tmpCanvas3 = fabric.util.createCanvasElement();
            tmpCanvas3.width = outputWidth;
            tmpCanvas3.height = outputHeight;
            const outputCanvas = tmpCanvas3;

            const multiplier = 1;

            const objectsInsideGenerationFrame = this.canvas.getObjects();

            const renderCanvasOptions: fabric.IDataURLOptions = {
                ...options,
                multiplier,
                left: 0,
                top: 0,
                width,
                height,
                enableRetinaScaling: true,
            }

            const imageInfo = await this.getReferenceImage({
                ctx1,
                outputCanvas,
                renderCanvasOptions,
                objectsInsideGenerationFrame,
                dx, dy,
                dw, dh,
                readCanvasData,
            });

            imageRef.current = imageInfo?.imageDataUrl;
            imageFlagsRef.isImageEmpty = imageInfo?.isEmpty ?? true;


            const maskImageInfo = await this.getImageMask({
                ctx1,
                outputCanvas,
                renderCanvasOptions,
                objectsInsideGenerationFrame,
                dx, dy,
                dw, dh,
                alwaysUseShape: alwaysUseShapeControl,
                readCanvasData,
            });

            maskRef.current = maskImageInfo?.imageDataUrl;

            imageFlagsRef.hasColoredProps = maskImageInfo?.hasColoredProps ?? false;
            imageFlagsRef.hasProduct = maskImageInfo?.hasProduct ?? false;
            imageFlagsRef.hasShapeProps = maskImageInfo?.hasShapeProps ?? false;

            outputCanvas.width = DEFAULT_CANVAS_LENGTH;
            outputCanvas.height = DEFAULT_CANVAS_LENGTH;


            const hedImageInfo = await this.getHedImageMask({
                ctx1,
                outputCanvas,
                renderCanvasOptions,
                objectsInsideGenerationFrame,
                dx, dy,
                dw, dh,
                readCanvasData,
            });

            hedImageRef.current = hedImageInfo?.imageDataUrl;
            imageFlagsRef.hasProps = !(hedImageInfo?.isEmpty ?? true);

            this.status = RenderCanvasControllerStatus.Idle;

            return {
                image: imageRef.current,
                mask: maskRef.current,
                hedImage: hedImageRef.current,
                sceneJSON: sceneJson || this.exportToJSON({
                    objects: sceneObjects,
                    generationFrameBounds,
                }),
                ...imageFlagsRef,
            };

        } catch (error) {

            console.error(error);

            return {};

        } finally {

            this.status = RenderCanvasControllerStatus.Idle;

        }
    }

}