import { isMobile } from 'components/utils/is-mobile';
import { editorContextStore } from 'contexts/editor-context';
import React from 'react';

export function useCheckMobileEffect() {

    React.useEffect(() => {

        const {
            setIsMobile,
        } = editorContextStore.getState();

        setIsMobile(
            isMobile({
                tablet: true,
                featureDetect: true,
            })
        );

    }, []);

}