import React from "react"

export function ListFilter({
    size,
    ...props
}: React.SVGProps<SVGSVGElement> & {
    size: number,
}) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        >
            <path d="M3 6h18" /><path d="M7 12h10" />
            <path d="M10 18h4" />
        </svg>
    )
}