import React from "react"
import { fabric } from "fabric"
import { Backend } from "backend/base";
import { EditorAssetContentType, UiDisplayMessageEventHandler } from "core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { isStaticImageObject } from "core/utils/type-guards";
import { Navigate } from "../components/navigate";
import { ActiveObjectLoadingCover } from "components/utils/active-object-loading-cover";
import { Editor } from "core/editor";
import { isDataURL } from "core/utils/string-utils";
import { getDataUrlFromImageElement, setImageObjectSrc } from "core/utils/image-utils";
import { getDataUrlFromString } from "core/utils/asset-utils";


async function getImageDataUrl(object: fabric.StaticImage) {
    const url = object.getSrc();
    if (isDataURL(url)) {
        return url;
    }
    const element = object.getElement();
    if (element instanceof HTMLImageElement || element instanceof HTMLCanvasElement) {
        return await getDataUrlFromImageElement({
            from: element,
        });
    }
    return await getDataUrlFromString(url);
}

export async function removeImageObjectBackground({
    object,
    editor,
    backend,
    onError,
    isStopped,
}: {
    object: fabric.StaticImage,
    editor: Editor,
    backend: Backend,
    onError: (error: Error) => void,
    isStopped?: { current: boolean },
}) {
    if (!object) {
        onError(new Error('Selected object is invalid.'));
        return;
    }

    const imageUrl = await getImageDataUrl(object);

    if (imageUrl) {

        const outputUrl = await backend.removeBackground({ imageUrl, onError });

        if (isStopped?.current === true) {
            console.log('Process is stopped');
            return;
        }

        setImageObjectSrc(
            object,
            outputUrl,
            onError,
        );

    } else {
        onError(new Error('Image url is invalid.'));
    }
}

export async function removeActiveImageBackground({
    onError,
}: {
    onError?: (error: Error) => void,
}) {
    const {
        editor,
        backend,
        activeObject,
    } = editorContextStore.getState();
    if (editor && backend && isStaticImageObject(activeObject)) {
        await removeImageObjectBackground({
            object: activeObject,
            editor,
            backend,
            onError: (e) => {
                console.error(e);
                editorContextStore.getState().editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', e.message);
                onError?.(e);
            },
        });
        return true;
    } else {
        const e = new Error('Selected object is not an image.')
        editorContextStore.getState().editor?.emit<UiDisplayMessageEventHandler>('ui:display-message', 'error', e.message);
        onError?.(e);
    }
    return false;
}

export function RemoveBackground() {
    const isRemoveBackgroundCalledRef = React.useRef(false);
    const [isProcessing, setIsProcessing] = React.useState(true);
    React.useEffect(() => {
        if (isRemoveBackgroundCalledRef.current) {
            return;
        }
        isRemoveBackgroundCalledRef.current = true;
        removeActiveImageBackground({
            onError: (e) => {
                editorContextStore.getState().setActiveLeftPanels(['Edit']);
            }
        }).then((isSuccess) => {
            console.log(`Finish remove background. Success? ${isSuccess}`);
            if (isSuccess) {
                setIsProcessing(false);
            } else {
                editorContextStore.getState().setActiveLeftPanels(['Edit']);
            }
        });
    }, []);

    return (
        <div
            className="flex flex-col"
        >
            {isProcessing && <ActiveObjectLoadingCover />}
            <Navigate />
        </div>
    )
}
