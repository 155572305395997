
import { commandScore } from "components/text-editor/command-score";
import { AssetLibraryItem } from "components/panels/panel-items/components/assets-library";


export class ElementsSearchCache {
    private accessories: Record<string, AssetLibraryItem[]>;
    constructor(accessories?: Record<string, AssetLibraryItem[]>) {
      this.accessories = {};
      if (accessories) {
        this.accessories = accessories;
      }
    }
    async setAccessories(accessories: Record<string, AssetLibraryItem[]>) {
        this.accessories = accessories;
    }
    async search(searchTerm: string) {
        const fullAccessoryArray: AssetLibraryItem[] = [];

        for (const accessoryType in this.accessories) {
            const accessoryItems = this.accessories[accessoryType];
            accessoryItems.forEach(item => {
                fullAccessoryArray.push(item);
            });
        }

        const matches = findBestMatches(searchTerm, fullAccessoryArray);

        if (!matches) {
            return [];
        }

        return matches;
    }

}
function findBestMatches(searchString: string, dictionary: AssetLibraryItem[]): AssetLibraryItem[] {
    const scoredItems = dictionary
    .filter(item => item.name !== null)
    .map(item => ({
      item,
      score: commandScore(item.name as string, searchString)
    }));

    const threshold = 0.5; 
    const goodMatches = scoredItems.filter(({ score }) => score > threshold);

    goodMatches.sort((a, b) => b.score - a.score);

    // filter out duplicate urls
    return goodMatches
    .map(({ item }) => item)
    .filter((function() {
      const seenUrls = new Set();
      return function(item) {
        if (seenUrls.has(item.url)) {
          return false;
        } else {
          seenUrls.add(item.url);
          return true;
        }
      };
    })());
}

export { commandScore, findBestMatches };