import React from 'react';
import { Navigate } from '../components/navigate';
import { classNames } from 'core/utils/classname-utils';

import { PrimaryButtonClassName, PrimaryButtonClassNameDisabled, SecondaryButtonClassName, SecondaryButtonClassNameDisabled, SecondaryButtonClassNameInactive } from 'components/constants/class-names';
import { RegenerateProductModel } from 'components/popup/message-dialog/regenerate-product';
import { editorContextStore } from 'contexts/editor-context';
import { ProgressBar } from '../components/progress-bar';
import { PromptTemplateForm, PromptTemplateFormField } from 'components/text-editor/prompt-template-editor';
import { handleExitRegenrateProduct, useEditingObject, useRegenerateProductChangeEffect } from 'hooks/use-regenerate-product';
import { PanelSwitchRow } from '../components/switch';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { Tooltip } from 'components/utils/tooltip';
import { DropdownOptionItem, DropdownOptionsWithTrigger } from 'components/utils/dropdown-options';
import { GenerateStrength } from 'core/common/types';
import { callEraseAndRegenerateProduct } from 'components/utils/render';
import { Editor } from 'core/editor';
import { EditorActiveObject } from 'core/common/interfaces';
import { Plus } from 'lucide-react';

function GenerateButton() {
    const editor = editorContextStore(state => state.editor);
    const backend = editorContextStore(state => state.backend);
    const editingObject = useEditingObject(editor);
    const regenerateProductRenderState = editorContextStore(state => state.regenerateProductRenderState);

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        if (regenerateProductRenderState === 'rendering') {
            setProgress(0.1);
        } else {
            setProgress(0);
        }
    }, [regenerateProductRenderState]);

    return (
        regenerateProductRenderState === 'rendering' ?
            <ProgressBar
                progress={progress}
                className={classNames(
                    'cursor-pointer',
                )}
                onClick={() => {
                    const {
                        regenerateRenderProcessController,
                    } = editorContextStore.getState();

                    regenerateRenderProcessController?.abort();
                }}
            /> :
            <button
                className={classNames(
                    PrimaryButtonClassName,
                    'cursor-pointer'
                )}
                onClick={() => {
                    callEraseAndRegenerateProduct({
                        editor,
                        backend,
                        activeObject: editingObject,
                    });
                }}
            >
                Generate
            </button>
    )
}

function RegenerateProductPromptEditor() {
    const regenerateProductPromptTemplate = editorContextStore(state => state.regenerateProductPromptTemplate);

    const setRegenerateProductPromptTemplate = editorContextStore(state => state.setRegenerateProductPromptTemplate);

    const subjectWordIndex = React.useMemo(() => regenerateProductPromptTemplate.words.findIndex(word => word.autocompleteType === 'subject'), [regenerateProductPromptTemplate]);

    const subjectWord = React.useMemo(() => regenerateProductPromptTemplate.words[subjectWordIndex], [regenerateProductPromptTemplate, subjectWordIndex]);

    return (
        <PromptTemplateFormField
            key={subjectWordIndex}
            word={subjectWord}
            index={subjectWordIndex}
            setPromptTemplate={setRegenerateProductPromptTemplate}
            canInsert={false}
        />
    );
}

const dropdownContainerClassName = 'w-full mb-4 flex flex-row items-center text-sm text-zinc-300'

function CorrectColorSwitch() {
    const regenerateProductCorrectColor = editorContextStore(state => state.regenerateProductCorrectColor);

    const handleCheckedChange = React.useCallback((value: boolean) => {
        editorContextStore.getState().setRegenerateProductCorrectColor(value);
    }, []);

    return (
        <PanelSwitchRow
            id="regenerate-product-panel-switch-row"
            className={dropdownContainerClassName}
            rootProps={{
                checked: regenerateProductCorrectColor,
                onCheckedChange: handleCheckedChange,
            }}
            labelProps={{
                className: "flex flex-row items-center",
            }}
        >
            <span className='mr-2'>
                Correct Color
            </span>
            <Tooltip
                triggerChildren={(
                    <QuestionMarkCircledIcon
                        className='text-zinc-500'
                    />
                )}
                contentChildren={(
                    <div>
                        Correct the color of the subject to make the lighting look more natural. This may modify the apperance of the product.
                    </div>
                )}
            />
        </PanelSwitchRow>
    )
}

const colorStrengthDropdownOptions = Object.values(GenerateStrength).reduce<Record<string, DropdownOptionItem<GenerateStrength>>>((prev, value) => {
    prev[value] = {
        name: value,
        value,
    };
    return prev;
}, {});


function ColorStrengthDropdown() {
    const regenerateProductColorStrength = editorContextStore(state => state.regenerateProductColorStrength);
    return (
        <div
            className={dropdownContainerClassName}
        >
            <div
                className='mr-2 truncate text-zinc-300'
            >
                Color Strength
            </div>
            <Tooltip
                triggerChildren={(
                    <QuestionMarkCircledIcon
                        className='text-zinc-500'
                    />
                )}
                contentChildren={(
                    <div>
                        How much the original image's color will influence the generated results.
                        <br />
                        <br />
                        <span className='text-zinc-500'>
                            The stronger the strength, the more the results will adhere to the original image.
                        </span>
                    </div>
                )}
            />
            <div className='flex-1' />
            <DropdownOptionsWithTrigger
                value={regenerateProductColorStrength}
                options={colorStrengthDropdownOptions}
                onSelectItem={(item) => {
                    editorContextStore.getState().setRegenerateProductColorStrength(item.value);
                }}
            />
        </div>
    )
}

function AddResultsButton({
    className = "",
    editor,
    editingObject,
    onClick,
    ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    editor?: Editor | null,
    editingObject?: EditorActiveObject,
}) {
    const generatedResults = editorContextStore(state => state.regenerateProductResults);
    const numSelectedResults = React.useMemo(() => generatedResults.reduce((sum, { isSelected }) => isSelected ? sum + 1 : sum, 0), [generatedResults]);
    return (
        <button
            className={classNames(
                numSelectedResults > 0 ? SecondaryButtonClassNameInactive : SecondaryButtonClassNameDisabled,
                "items-center justify-center",
                className,
            )}
            onClick={(e) => {
                if (numSelectedResults > 0) {
                    handleExitRegenrateProduct({
                        editor,
                        editingObject,
                        addImages: true,
                    });
                }


                onClick?.(e);
            }}
            {...props}
        >
            <Plus size={18} className='mr-2' />
            {numSelectedResults > 0 ?
                `Add ${numSelectedResults} images to canvas` :
                'No image to add'}
        </button>
    )
}

export function RegenerateProduct() {
    const editor = editorContextStore(state => state.editor);

    const editingObject = useEditingObject(editor);

    useRegenerateProductChangeEffect({
        editor,
        editingObject,
    });

    return (
        <div
            className='flex flex-col'
        >
            <Navigate />
            <RegenerateProductPromptEditor />
            <CorrectColorSwitch />
            <ColorStrengthDropdown />
            <GenerateButton />
            <div className='h-4' />
            <AddResultsButton
                editor={editor}
                editingObject={editingObject}
            />
            <RegenerateProductModel />
        </div>
    )
}