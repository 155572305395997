// {"esrgan", "sdxl", "object-drop", "pct", "clarity-upscale"}

import { Timestamp } from "firebase/firestore";
import { ChannelRGBA } from "./image";
import { getObjectEntries } from "core/utils/type-utils";
import { StateUpdater } from "./utils";
import { noop } from "lodash";
import { getUpdaterFunction, SetEditorStateFunction } from "contexts/editor-context-utils";

export enum ColorCorrectV2Stage {
    Esrgan = "esrgan",
    PCT = "pct",
    ObjectDrop = "object-drop",
    SD = "sdxl",
    Clarity = "clarity-upscale",
    Final = 'final',
    SmoothBlend = "smooth-blend",
}

export const ColorCorrectV2StageToMessage: Record<ColorCorrectV2Stage, string> = {
    [ColorCorrectV2Stage.Esrgan]: 'Running initial upscale ...',
    [ColorCorrectV2Stage.PCT]: 'Running color correction ...',
    [ColorCorrectV2Stage.ObjectDrop]: 'Running lighting correction ...',
    [ColorCorrectV2Stage.SD]: 'Running creative upscale ...',
    [ColorCorrectV2Stage.Clarity]: 'Running creative upscale ...',
    [ColorCorrectV2Stage.Final]: 'Adding final touches ...',
    [ColorCorrectV2Stage.SmoothBlend]: 'Fixing object details ...',
};

export type ColorCorrectV2ImageMetadata = {
    isColorCorrectV2Output?: boolean,
    colorCorrectV2PctResult?: string,
    colorCorrectV2ObjectDropResult?: string,
    colorCorrectV2SmoothBlendResult?: string,
}

export enum ColorCorrectV2StartingPointImageType {
    InitialRenderImage = "initial_render_image",
    ForegroundOnBackground = "foreground_pasted_on_background_hi_res",
}

export interface ColorCorrectV2Args {
    // Required args
    initial_render_image: string; // assuming base64-encoded image data or URL

    // Optional args
    composite_image?: string; // assuming base64-encoded image data or URL
    product_mask_channel?: 'R' | 'G' | 'B' | 'A'; // defaults to 'R'
    // product_and_props_mask_channel?: string; // defaults to 'G'
    composite_mask_image?: string; // assuming base64-encoded image data or URL
    prompt?: string; // default: "beautiful award-winning product photography"
    negative_prompt?: string; // default: "blurry, ugly, chaotic, fake, painting, drawing, 3d rendering"
    width?: number; // default: 1024
    height?: number; // default: 1024

    // Pipeline-selection args
    gpu_stages_to_run?: ColorCorrectV2Stage[]; // defaults to ["esrgan", "sdxl", "object-drop", "pct"]
    return_without_smoothing?: boolean; // default: False
    debug_only_show_intermediate_smoothing_result?: boolean; // default: False

    // ESRGAN-specific args
    run_guided_filter?: boolean; // default: True
    guided_filter_radius?: number; // default: 5
    guided_filter_eps?: number; // default: 0.01
    guided_filter_strength?: number; // default: 2

    // SDXL-specific args
    sdxl_anyline_use_unmodified?: string; // default: "true"
    sdxl_guidance_scale?: number; // default: 3.0
    sdxl_num_inference_steps?: number; // values: 3, 4, 8, 12, 25
    sdxl_strength?: number; // default: 0.65
    sdxl_controlnet_conditioning_scale?: number; // default: 0.9
    sdxl_target_width?: number; // default: 1280
    sdxl_target_height?: number; // default: 1280

    // Clarity upscale-specific args
    clarity_upscale_scale_factor?: number; // default: 2.0, range: [1.0, 3.0]
    clarity_upscale_dynamic?: number; // default: 6.0, range: [1, 50]
    clarity_upscale_creativity?: number; // default: 0.35, range: [0, 1]
    clarity_upscale_resemblance?: number; // default: 0.6, range: [0, 3]
    clarity_upscale_tiling_width?: number; // default: 256, range: [16, 256]
    clarity_upscale_tiling_height?: number; // default: 256, range: [16, 256]
    clarity_upscale_sd_model?: string; // default: "juggernaut_reborn.safetensors [338b85bc4f]"
    clarity_upscale_scheduler?: string; // default: "DPM++ 3M SDE Karras"
    clarity_upscale_num_inference_steps?: number; // default: 25, range: [5, 35]
    clarity_upscale_seed?: number; // optional
    clarity_upscale_downscaling?: boolean; // default: True
    clarity_upscale_downscaling_resolution?: number; // default: 768, range: [512, 1024]
    clarity_upscale_lora_links?: string; // default: ""
    clarity_upscale_custom_sd_model?: string; // default: ""
    clarity_upscale_sharpen?: number; // default: 0.0, range: [0, 10]
    clarity_upscale_handfix?: string; // default: "disabled"
    clarity_upscale_pattern?: boolean; // default: False
    clarity_upscale_output_format?: string; // default: "webp"
    clarity_skip_repaste_product?: boolean; // default: False

    // Post-background-generation (SDXL or Clarity)
    post_background_paste_back_product_image?: boolean; // default true
    post_background_step_min_filter?: number; // default: 5
    post_background_step_gaussian_blur?: number; // default: 3

    // Color-correction-specific args
    object_drop_num_inference_steps?: number; // default: 25
    object_drop_guidance_scale?: number; // default: 3.0
    object_drop_strength?: number; // default: 0.9

    // Smoothing-specific args
    skip_smoothing_stage?: boolean; // default: False
    smoothing_strength: number,
    smoothing_scale?: [number, number],
    smoothing_blend_strength: number,

    // Final post-processing args
    final_step_min_filter?: number; // default: 3
    final_step_gaussian_blur?: number; // default: 1

    // Starting point
    starting_point_image_type?: ColorCorrectV2StartingPointImageType, // default: foreground_pasted_on_background_hi_res
}

export enum ColorCorrectV2ResponseStatus {
    Rendering = '1',
    Error = '-1',
}

export interface ColorCorrectV2Response {
    status: ColorCorrectV2ResponseStatus,
    job_id?: string,
    message?: string,
}

export enum ColorCorrectV2JobStatus {
    Active = "Active",
    Completed = "Completed",
    Stopped = "Stopped",
}

export interface ColorCorrectV2RenderJobDoc {
    id: string;
    status: ColorCorrectV2JobStatus;
    render_start_timestamp?: Timestamp;
    render_finish_timestamp?: Timestamp;
    intermediate_results?: Record<ColorCorrectV2Stage, string>;
    currently_running_stage?: ColorCorrectV2Stage;
    currently_running_stage_index?: number;
}


export function isColorCorrectV2RenderJobDoc(doc: any): doc is ColorCorrectV2RenderJobDoc {
    return doc != null &&
        typeof(doc.id) === 'string';
}

function getStageIndex({
    stage: targetStage,
    stagesToRun,
}: {
    stagesToRun: ColorCorrectV2Stage[],
    stage: ColorCorrectV2Stage,
}) {
    return stagesToRun.findIndex(stage => stage === targetStage);
}

export function getLatestIntermediateResult({
    intermediateResults,
    stagesToRun,
}: {
    intermediateResults: Record<ColorCorrectV2Stage, string>,
    stagesToRun: ColorCorrectV2Stage[],
}) {
    const sortedStages = getObjectEntries(intermediateResults)
        .filter(([, url]) => url != null && Boolean(url.trim()))
        .sort(([stageA,], [stageB,]) => {
            const indexA = getStageIndex({stage: stageA, stagesToRun});
            const indexB = getStageIndex({stage: stageB, stagesToRun});
            return indexB - indexA;
        });
    return sortedStages[0];
}

export interface ColorCorrectV2EditorState {
    colorCorrectV2Creativity: number,
    setColorCorrectV2Creativity: (value: StateUpdater<number>) => void,
    colorCorrectV2TargetLength: number,
    setColorCorrectV2TargetLength: (value: StateUpdater<number>) => void,
}

export function getDummyColorCorrectV2EditorState(): ColorCorrectV2EditorState {
    return {
        colorCorrectV2Creativity: 0.2,
        setColorCorrectV2Creativity: noop,
        colorCorrectV2TargetLength: 1280,
        setColorCorrectV2TargetLength: noop,
    };
}

export function getDefaultColorCorrectV2EditorState(
    set: SetEditorStateFunction,
): ColorCorrectV2EditorState {
    return {
        colorCorrectV2Creativity: 0.2,
        setColorCorrectV2Creativity: getUpdaterFunction(set, 'colorCorrectV2Creativity'),
        colorCorrectV2TargetLength: 1280,
        setColorCorrectV2TargetLength: getUpdaterFunction(set, 'colorCorrectV2TargetLength'),
    };
}

export function resetColorCorrectV2EditorState(state: ColorCorrectV2EditorState) {
    state.setColorCorrectV2Creativity(0.2);
    state.setColorCorrectV2TargetLength(1280);
}