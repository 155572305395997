import React from "react"
import { classNames } from "core/utils/classname-utils"

export function NavBarContainer({
    className,
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div
            {...props}
            className={classNames(
                "flex justify-cener items-center w-full px-4 py-3 bg-zinc-900 border-b border-zinc-800",
                className ?? '',
            )}
        >
            {children}
        </div>
    )
}