import { StateUpdater } from "core/common/types";
import { Timestamp } from "firebase/firestore";


export enum ApiDashboardModelType {
    ImageGeneration = "generate-image",
}

export const apiDashboardModelIdToModelType: Record<string, ApiDashboardModelType> = {
    'generate-image': ApiDashboardModelType.ImageGeneration,
}

export function getApiDashboardModelUrl(apiDashboardModelType: ApiDashboardModelType | undefined) {
    if (apiDashboardModelType) {
        return `/api/${apiDashboardModelType}`;
    }
    return '/api';
}

export enum ApiDashboardTab {
    Models = 'models',
    Settings = 'settings'
}

export enum ApiDashboardModelTab {
    Demo = "demo",
    Docs = 'docs',
    Usage = "usage",
}

export enum ApiDashboardModelPreviewTab {
    Code = "code",
    Results = "results",
}

export enum ApiInputType {
    Text = 'text',
    Number = 'number',
    Options = 'options',
    Slider = 'slider',
    Image = 'image',
}


export enum UserApiStatus {
    Creating = "creating",
    CreateFailed = "create-failed",
    Ready = "ready",
    Deactivated = "deactivated",
}

export enum ApiSubscriptionTier {
    None = "None",
    Standard = "Standard",
    Enterprise = "Enterprise",
}

export enum ApiBetaAccess {
    None = "None",
    Full = "Full",
}

export interface UserApiStatusDoc {
    tier: ApiSubscriptionTier,
    status: UserApiStatus,
    subscriptionItemId: string,
    message?: string,
    apiBetaAccess?: ApiBetaAccess,
}

export function isUserApiStatusDoc(data: any): data is UserApiStatusDoc {
    return data &&
        data.tier &&
        data.status &&
        typeof (data.subscriptionItemId) === "string";
}

export interface EmailApiDataDoc {
    apiBetaAccess?: ApiBetaAccess,
}

export function isEmailApiDataDoc(data: any): data is EmailApiDataDoc {
    return Boolean(data);
}

export interface UserApiDataDoc extends UserApiStatusDoc {
    primaryKey: string,
    secondaryKey: string,
}

export function isUserApiDataDoc(data: any): data is UserApiDataDoc {
    return data &&
        data.primaryKey &&
        data.secondaryKey &&
        isUserApiStatusDoc(data);
}

export enum ApiRenderState {
    Idle = 'idle',
    Rendering = 'rendering',
    Error = 'error',
}

export interface ApiEditorState {
    userApiData: UserApiDataDoc,
    setUserApiData: (value: StateUpdater<UserApiDataDoc>) => void,
    apiDashboardModelType: ApiDashboardModelType | undefined,
    setApiDashboardModelType: (value: StateUpdater<ApiDashboardModelType | undefined>) => void,
}

export function getDefaultUserApiData(): UserApiDataDoc {
    return {
        tier: ApiSubscriptionTier.None,
        status: UserApiStatus.Deactivated,
        subscriptionItemId: '',
        primaryKey: '',
        secondaryKey: '',
    };
}

export enum ApiPipelineType {
    Default = 'default',
    Canny = 'canny',
    ProductPlacement = 'product_placement',
}

export enum ApiModelType {
    GenerateImage = "generate_image",
}

export type ApiUsageDoc = {
    model_type: ApiModelType,
    pipeline_type?: ApiPipelineType,
    id: string,
    timestamp: Timestamp,
    result: {
        duration_seconds?: number,
        success?: boolean,
        images?: string[],
    }
}

export function isApiUsageDoc(doc: any): doc is ApiUsageDoc {
    return doc &&
        typeof (doc.id) === 'string' &&
        typeof (doc.model_type) === 'string' &&
        Object.values(ApiModelType).includes(doc.model_type) &&
        doc.timestamp &&
        doc.result;
}


export type GenerateImageApiDocType = ApiPipelineType | 'Pricing';

