import React from "react"
import { MessageDialog, MessageDialogClose } from "./message-dialog";
import { classNames } from "core/utils/classname-utils";
import { DropdownClassName, MessageDialogClassName } from "components/constants/class-names";
import { Cross1Icon } from "@radix-ui/react-icons";
import { editorContextStore } from "contexts/editor-context";
import { AppUserSubscriptionTierV2 } from "core/common/types";
import { AppSubscriptionPlanType, subscriptionPlanToPrice } from "core/common/types/subscription";
import { PlanCard, appSubscriptionPlans, DisplaySubscriptionPlan } from "./manage-subscription-dialog";
import { subscribeToPlan } from "components/subscription/checkout-portal";

const subscriptionTierV2ToRecommendedPlans: Record<AppUserSubscriptionTierV2, AppSubscriptionPlanType[]> = {
    [AppUserSubscriptionTierV2.Free]: [AppSubscriptionPlanType.Pro, AppSubscriptionPlanType.ProPlus],
    [AppUserSubscriptionTierV2.Pro]: [AppSubscriptionPlanType.ProPlus],
    [AppUserSubscriptionTierV2.ProPlus]: [],
}

function EmptySubscriptionPlans() {
    return (
        <div
            className="w-full flex flex-col items-center gap-8"
        >
            <div className="text-2xl text-zinc-300">
                You have used all the real-time quota.
            </div>
            <div
                className="text-base text-zinc-300 max-w-[80vw] md:max-w-[500px] mb-8"
            >
                Thank you for using Flair! You have used all the the real-time quota for this month. If you need more quota, please contact <a href="mailto:mickey@flair.ai" className="text-lime-500 hover:text-lime-300">Flair Support</a>.
            </div>
        </div>
    );
}

function SubscriptionPlans({
    subscriptionPlans,
}: {
    subscriptionPlans: DisplaySubscriptionPlan[],
}) {
    return (
        <div
            className="w-full flex flex-col items-center gap-8"
        >
            <div className="text-2xl">
                Upgrade subscription to get more real-time quota.
            </div>
            <div
                className={classNames(
                    "py-2 grid grid-flow-col auto-cols-auto items-stretch justify-center gap-4",
                )}
            >
                {subscriptionPlans.map((subscriptionPlan, index) => (
                    <PlanCard
                        key={subscriptionPlan.id || index}
                        subscriptionPlan={subscriptionPlan}
                        recommended={index === 0}
                        disabled={false}
                        canUnsubscribe={false}
                        onClickPrimaryButton={async () => {
                            const { id } = subscriptionPlan;

                            if (!id || id === AppSubscriptionPlanType.Free) {
                                return;
                            }

                            const priceId = subscriptionPlanToPrice[id];

                            await subscribeToPlan(
                                priceId,
                            );
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

function RealTimeSubscribeDialogContent() {
    const userQuotas = editorContextStore(state => state.userQuotas);
    const tierV2 = userQuotas?.tierV2 || AppUserSubscriptionTierV2.Free;
    const subscriptionPlans = React.useMemo(() => subscriptionTierV2ToRecommendedPlans[tierV2].map((planId) => (
        appSubscriptionPlans[planId]
    )), [tierV2]);

    return (
        <div
            className={classNames(
                MessageDialogClassName,
            )}
        >
            <MessageDialogClose
                className="absolute right-4"
            >
                <Cross1Icon
                    className='text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors'
                />
            </MessageDialogClose>
            <div className="w-full text-zinc-500 mb-6">
                No real-time quota left.
            </div>
            {subscriptionPlans.length > 0 ?
                <SubscriptionPlans
                    subscriptionPlans={subscriptionPlans}
                /> :
                <EmptySubscriptionPlans />}
        </div>
    );
}

export function RealTimeSubscribeDialog({
    onClose,
}: {
    onClose?: () => void,
}) {
    return (
        <MessageDialog
            defaultOpen={true}
            onOpenChange={(value) => {
                if (!value) {
                    onClose?.();
                }
            }}
            contentChildren={(
                <RealTimeSubscribeDialogContent />
            )}
        />
    );
}