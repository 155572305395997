import { ApiPipelineType } from "core/common/types/api";
import { GenerateImageApiState, isCannyGenerateImageApiState, isProductPlacementGenerateImageApiState } from "./generate-image-api-config"

// API pricing constants
const API_PRICE_PER_QUANTITY: number = parseFloat(process.env.REACT_APP_API_PRICE_PER_QUANTITY ?? '0.001');


function _getNumControlImageFromApiState(apiState: GenerateImageApiState) {
    if (isCannyGenerateImageApiState(apiState)) {
        return 1 + apiState.canny_base_image ? 1 : 0;
    } else if (isProductPlacementGenerateImageApiState(apiState)) {
        return 1 + (apiState.foreground_image ? 1 : 0) +
            (apiState.ref_image ? 1 : 0);
    }
    return 1;
}

export function getNumControlImageFromApiState(apiState: GenerateImageApiState) {
    return Math.max(
        _getNumControlImageFromApiState(apiState),
        1,
    );
}

export type GetGenerateImageApiPricingProps = {
    pipelineType: string,
}


export function getApiSubscriptionUsageQuantity({
    pipelineType,
}: GetGenerateImageApiPricingProps): number {

    if (pipelineType === ApiPipelineType.Default) {
        return 8;
    } else if (pipelineType === ApiPipelineType.ProductPlacement) {
        return 20;
    } else if (pipelineType === ApiPipelineType.Canny) {
        return 15;
    }

    return 8;
}

export function getGenerateImageApiPricing(props: GetGenerateImageApiPricingProps) {

    const total_price = getApiSubscriptionUsageQuantity(props) * API_PRICE_PER_QUANTITY;

    return parseFloat(total_price.toFixed(3));
}

export function getGenerateImageApiPricingFromApiState(apiState: GenerateImageApiState) {
    return getGenerateImageApiPricing({
        pipelineType: apiState.pipelineType,
    });
}
