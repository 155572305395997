import { editorContextStore } from "contexts/editor-context";
import { TryOnModelPreviewDataTags } from "core/common/types";
import React from "react"

export function useTryOnPoseLibraryFilterToggleGroupValue({
    tags,
}: {
    tags: { [key in TryOnModelPreviewDataTags]?: string },
}) {
    const tryOnModelPreviewFilterContraints = editorContextStore(state => state.tryOnModelPreviewFilterContraints);

    const tagKeys = React.useMemo(() => new Set(Object.keys(tags) as Array<TryOnModelPreviewDataTags>), [tags]);

    const value = React.useMemo(() => tryOnModelPreviewFilterContraints.find(tag => tagKeys.has(tag)) || "", [tryOnModelPreviewFilterContraints, tagKeys]);

    const onValueChange = React.useCallback((value: string) => {
        editorContextStore.getState().setTryOnModelPreviewFilterContraints((
            filterConstraints,
        ) => {
            const newConstraints = filterConstraints.filter(e => !tagKeys.has(e));

            if (tagKeys.has(value as TryOnModelPreviewDataTags)) {
                newConstraints.push(value as TryOnModelPreviewDataTags);
            }

            return newConstraints;
        });
    }, [tagKeys]);

    return {
        value,
        onValueChange,
    }
}