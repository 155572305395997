import { DropdownClassName, InputBoxClassName, InputBoxClassNameError } from "components/constants/class-names";
import { LEFT_PANEL_DROPDOWN_WIDTH } from "components/constants/left-panels";
import { FloatTagZIndex } from "components/constants/zIndex";
import { Tooltip, TooltipProps } from "components/utils/tooltip";
import { classNames } from "core/utils/classname-utils";
import { useMaxCollisionHeight } from "hooks/use-max-collision-height";
import React from "react";
import { Command, CommandImperativeHandle } from "./autocomplete";
import { CommandItem } from "./prompt-autocomplete";

const SIMPLE_TEXT_EDITOR_ID_PREFIX = 'simple-text-editor';
const SIMPLE_TEXT_EDITOR_COMMAND_ITEM_ID = `${SIMPLE_TEXT_EDITOR_ID_PREFIX}-command-item`;

export function SimpleTextEditor({
    autocompleteItems,
    autocompleteLabel,
    description,
    className,
    value,
    onValueChange,
    tooltipProps,
    required,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    autocompleteItems: string[],
    autocompleteLabel?: string,
    description?: string,
    value: string,
    onValueChange: (value: string) => void,
    tooltipProps?: TooltipProps,
    required?: boolean,
}) {

    const [input, setInput] = React.useState(value);
    const inputElementRef = React.useRef<HTMLInputElement | null>(null);
    const commandImperativeHandleRef = React.useRef<CommandImperativeHandle | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [filterCommand, setFilterCommand] = React.useState(false);
    const [dropdownWidth, setDropdownWidth] = React.useState(LEFT_PANEL_DROPDOWN_WIDTH);
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const [isInputEmpty, setIsInputEmpty] = React.useState(!Boolean(value));

    const isTextInputFocusedRef = React.useRef(false);
    const isCommandElementFocusedRef = React.useRef(false);

    React.useEffect(() => {
        setInput(value);
        setIsInputEmpty(!Boolean(value));
    }, [value]);


    const { maxHeight, updateMaxHeight } = useMaxCollisionHeight({
        elementRef: inputElementRef,
        margin: 100,
    });

    const updateDropdownWidth = React.useCallback(() => {
        const { width } = inputElementRef.current?.getBoundingClientRect() ?? {};
        if (width) {
            setDropdownWidth(width);
        }
    }, []);

    const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.currentTarget.value;
        setInput(event.currentTarget.value);
        onValueChange(input);
        setIsInputEmpty(!Boolean(input));
        setIsDropdownOpen(true);
        setFilterCommand(true);
        updateMaxHeight();
        updateDropdownWidth();
    }, [
        updateMaxHeight,
        onValueChange,
        updateDropdownWidth,
    ]);

    const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!commandImperativeHandleRef.current) {
            return;
        }
        if (event.code === 'Tab' && !isDropdownOpen) {
            return;
        }
        commandImperativeHandleRef.current.handleKeyDown(event);
        if (event.code === 'Enter') {
            event.preventDefault();
            inputElementRef.current?.blur();
        } else if (event.code === 'Escape') {
            event.preventDefault();
            inputElementRef.current?.blur();
        }
    }, [isDropdownOpen]);

    const closeDropdown = React.useCallback(() => {
        setIsDropdownOpen(false);
        setFilterCommand(false);
    }, []);

    const handleSelectItem = React.useCallback((item: string) => {
        setInput(item);
        setIsInputEmpty(!Boolean(item));
        onValueChange(item);
        closeDropdown();
    }, [closeDropdown, onValueChange]);

    const items = React.useMemo(() => Array.from(new Set(autocompleteItems)), [autocompleteItems]);

    React.useEffect(() => {
        if (isDropdownOpen) {
            updateDropdownWidth();
        }
    }, [
        isDropdownOpen,
        updateDropdownWidth,
    ]);

    React.useEffect(() => {
        if (input) {
            setIsTooltipOpen(false);
        }
    }, [input]);

    const inputElement = (
        <input
            ref={inputElementRef}
            id={props.id ? `${props.id}-input` : undefined}
            value={input}
            className={classNames(
                required ?
                    isInputEmpty ? InputBoxClassNameError : InputBoxClassName :
                    InputBoxClassName,
            )}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => {
                isTextInputFocusedRef.current = true;
                setIsDropdownOpen(true);
                updateMaxHeight();
            }}
            onBlur={(e) => {
                if (!e.relatedTarget?.id?.includes(SIMPLE_TEXT_EDITOR_COMMAND_ITEM_ID)) {
                    isTextInputFocusedRef.current = false;
                    closeDropdown();
                    onValueChange(input);
                }
            }}
        />
    );

    return (
        <div {...props} className={classNames("relative", className ?? '')}>
            <Tooltip
                {...tooltipProps}
                open={isTooltipOpen}
                onOpenChange={(isOpen) => {
                    if (input && isOpen) {
                        setIsTooltipOpen(false);
                    } else {
                        setIsTooltipOpen(isOpen);
                    }
                }}
                triggerChildren={inputElement}
                contentChildren={description}
                contentStyle={{
                    maxWidth: dropdownWidth,
                }}
                contentProps={{
                    align: 'start',
                }}
            />
            <div
                className={classNames(
                    DropdownClassName,
                    'absolute max-h-[500px] overflow-x-hidden overflow-y-scroll pl-3 pr-2 shadow-lg scrollbar-container',
                )}
                style={{
                    zIndex: FloatTagZIndex,
                    display: isDropdownOpen ? 'block' : 'none',
                    width: dropdownWidth,
                }}
            >
                <Command
                    ref={commandImperativeHandleRef}
                    label={autocompleteLabel}
                    shouldFilter={filterCommand}
                    className="z-10"
                    onFocus={() => {
                        isCommandElementFocusedRef.current = true;
                    }}
                    onBlur={() => {
                        isCommandElementFocusedRef.current = false;
                    }}
                    style={{
                        maxHeight,
                    }}
                >
                    <Command.Search value={input} />
                    <Command.List>
                        {items.map((item) => (
                            <CommandItem
                                key={item}
                                tabIndex={0}
                                id={SIMPLE_TEXT_EDITOR_COMMAND_ITEM_ID}
                                onSelect={() => handleSelectItem(item)}
                            >
                                {item}
                            </CommandItem>
                        ))}
                    </Command.List>
                </Command>
            </div>
        </div>
    )
}