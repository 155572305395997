import { fabric } from "fabric"
import { isNaN, noop } from "lodash"
import { ILayer, IShadow, LayerType } from "core/common/layers"
import { ShadowOptions } from "../common/interfaces"
import { defaultControllerOptions, DEFAULT_CANVAS_LENGTH, MIN_OBJECT_LENGTH, PROPERTIES_TO_INCLUDE, RENDER_CANVAS_LEGNTH, defaultEditorConfig } from "core/common/constants"
import { isStaticImageObject3d } from "core/common/types/3d"
import { nanoid } from "nanoid"
import { EditorConfig } from "core/common/types"
import { cloneFilters } from "./object-filter-utils"
import { addObjectToCanvas } from "components/utils/add-to-canvas-utils"
import { EditorState, FabricCanvas } from 'core/common/interfaces';

export function angleToPoint(angle: number, sx: number, sy: number) {
  while (angle < 0) angle += 360
  angle %= 360
  let a = sy,
    b = a + sx,
    c = b + sy,
    p = (sx + sy) * 2,
    rp = p * 0.00277,
    pp = Math.round((angle * rp + (sy >> 1)) % p)

  if (pp <= a) return { x: 0, y: sy - pp }
  if (pp <= b) return { y: 0, x: pp - a }
  if (pp <= c) return { x: sx, y: pp - b }
  return { y: sy, x: sx - (pp - c) }
}

const setObjectGradient = (object: fabric.Object, angle: number, colors: string[]) => {
  let odx = object.width! >> 1
  let ody = object.height! >> 1
  let startPoint = angleToPoint(angle, object.width!, object.height!)
  let endPoint = {
    x: object.width! - startPoint.x,
    y: object.height! - startPoint.y,
  }

  object.set(
    "fill",
    new fabric.Gradient({
      type: "linear",
      coords: {
        x1: startPoint.x - odx,
        y1: startPoint.y - ody,
        x2: endPoint.x - odx,
        y2: endPoint.y - ody,
      },
      colorStops: [
        { offset: 0, color: colors[0] },
        { offset: 1, color: colors[1] },
      ],
    })
  )
}

export const setObjectShadow = (object: fabric.Object | any, options: ShadowOptions) => {
  if (options.enabled) {
    object.set({
      shadow: new fabric.Shadow(options),
    })
  } else {
    object.set({
      shadow: null,
    })
  }
}

export const updateObjectShadow = (object: fabric.Object | any, options?: IShadow) => {
  if (options) {
    object.set({
      shadow: new fabric.Shadow(options),
    })
  } else {
    object.set({
      shadow: null,
    })
  }
}


function getMinimumScaleSize(element: fabric.Object | any) {
  if (!element) return 0.5;

  const { width, height } = element;

  if (width != null && height != null && !isNaN(width) && !isNaN(height)) {
    const minLength = Math.min(width, height);
    const maxLength = Math.max(width, height);
    // At least the object should fit inside the frame
    const maxScale = (DEFAULT_CANVAS_LENGTH * 0.8) / maxLength;

    if (minLength > MIN_OBJECT_LENGTH) {
      return Math.min(
        MIN_OBJECT_LENGTH / minLength,
        maxScale,
      );
    }

    return Math.min(0.5, maxScale);
  }

  return 0.5;
}

export function setMinimumScaleSize(element: fabric.Object | any) {
  if (!element) return;
  element.minScaleLimit = getMinimumScaleSize(element);
}

export const updateObjectBounds = (element: fabric.Object | any, options: Required<ILayer>) => {
  const { top, left, width, height } = element
  if (isNaN(top) || isNaN(left)) {
    element.set({
      top: options.top + options.height / 2 - height / 2,
      left: options.left + options.width / 2 - width / 2,
    });

  }

  // Set minimum scale size of object
  setMinimumScaleSize(element);
}

export function showObjectControls(object: fabric.Object) {
  const is3d = isStaticImageObject3d(object);

  const hasRotate = !is3d;
  const hasNonUniformScaling = !is3d;

  object.setControlsVisibility({
    bl: true,
    br: true,
    tl: true,
    tr: true,
    mb: hasNonUniformScaling,
    ml: hasNonUniformScaling,
    mr: hasNonUniformScaling,
    mt: hasNonUniformScaling,
    mtr: hasRotate,
  });
}

export function hideObjectControls(object: fabric.Object) {
  object.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false,
  });
}

export default setObjectGradient

export function setFabricObjectMetadata(object: fabric.Object, key: string, val: string | undefined) {
  if (object.metadata) {
    object.metadata[key] = val;
  } else {
    object.metadata = {
      [key]: val,
    }
  }
}

type DuplicateAndAddFabricObjectProps = {
  canvas: fabric.Canvas,
  object: fabric.Object,
  frame?: fabric.Object | undefined,
  config?: Partial<EditorConfig>,
}

function duplicateAndAddSingleFabricObject({
  canvas,
  object,
  frame,
  config = defaultEditorConfig,
}: DuplicateAndAddFabricObjectProps) {
  return new Promise<fabric.Object[]>((resolve) => {
    const prevFilters = (object as any).filters;
    (object as any).filters = [];
    object.clone(
      (clone: fabric.Object) => {
        clone.clipPath = undefined
        clone.id = nanoid()
        clone.set({
          left: object.left! + 10,
          top: object.top! + 10,
        })
        if (frame && config.clipToFrame) {
          clone.clipPath = frame;
        }
        clone.metadata = { ...object.metadata };
        cloneFilters(object, clone);

        addObjectToCanvas({
          canvas,
          object: clone,
        });

        resolve([clone])
      },
      PROPERTIES_TO_INCLUDE
    );
    (object as any).filters = prevFilters;
  })
}

function duplicateAndAddFabricObjectRecursive({
  canvas,
  object,
  frame,
  config = defaultEditorConfig,
}: DuplicateAndAddFabricObjectProps): Promise<fabric.Object[]> {
  if (object instanceof fabric.Group && object.type !== LayerType.STATIC_VECTOR) {
    const objects: fabric.Object[] = (object as fabric.Group).getObjects();

    return Promise.all(objects.map((object) => duplicateAndAddFabricObjectRecursive({
      canvas,
      object,
      frame,
      config,
    }))).then(result => result.flat());

  }

  return duplicateAndAddSingleFabricObject({
    canvas,
    object,
    frame,
    config,
  });
}

export function duplicateAndAddFabricObject({
  canvas,
  object,
  frame,
  config = defaultEditorConfig,
}: {
  canvas: fabric.Canvas,
  object: fabric.Object,
  frame?: fabric.Object | undefined,
  config?: Partial<EditorConfig>,
}) {
  return duplicateAndAddFabricObjectRecursive({
    canvas,
    object,
    frame,
    config,
  })
}

export function setActiveObjects({
  state,
  canvas,
  objects,
}: {
  state: EditorState,
  canvas: FabricCanvas,
  objects: fabric.Object[],
}) {
  if (objects.length <= 0) {
    return;
  }

  let object: fabric.Object;

  if (objects.length === 1) {

    object = objects[0];

  } else {

    object = new fabric.ActiveSelection(
      objects,
      {
        ...defaultControllerOptions,
        canvas,
      },
    ) as fabric.Object;

  }

  canvas.setActiveObject(object);

  state.setActiveObject(object);

}

// Function to calculate the magnitude of a vector
export function magnitude(v: fabric.Point): number {
  return Math.sqrt(v.x * v.x + v.y * v.y);
}

// Function to normalize a vector
export function normalize(v: fabric.Point): fabric.Point {
  const mag = magnitude(v);
  return new fabric.Point(v.x / mag, v.y / mag);
}

// Function to subtract two vectors
export function subtract(v1: fabric.Point, v2: fabric.Point): fabric.Point {
  return new fabric.Point(v1.x - v2.x, v1.y - v2.y);
}

export function distanceSquared(a: fabric.Point, b: fabric.Point) {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return dx * dx + dy * dy;
}

// Function to scale a vector by a scalar
export function scale(v: fabric.Point, scalar: number): fabric.Point {
  return new fabric.Point(v.x * scalar, v.y * scalar);
}

// Function to scale a vector by a scalar and then add it to another vector
export function scaleAdd(x: fabric.Point, y: fabric.Point, scalar: number): fabric.Point {
  // First, scale the 'y' vector
  const scaledY = scale(y, scalar);

  // Then, add the scaled 'y' vector to the 'x' vector
  return new fabric.Point(x.x + scaledY.x, x.y + scaledY.y);
}