import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { PromptTemplate, PromptWord, StateUpdater, } from "core/common/types";
import { concatTexts, splitTexts } from "core/utils/string-utils";
import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete";
import { areArraysEqual } from "core/utils/array-utils";
import { getObjectEntries } from "core/utils/type-utils";

function getSubjectFromObject(object: fabric.Object) {
    return (object?.metadata?.subject as string)?.trim();
}

/**
 * Map from autocomplete type to indices in the prompt words.
 * The prefixes are the indices of the first word that has
 * both the type and the prefix.
 * For example, given a `placement` type with prefix `on`, the values are:
 * ```
 * {
 *  lastIndex: 1,
 *  prefixes: {
 *      'on': 1,
 *  }
 * }
 * ```
 */
type PromptWordIndexMap = Record<string, {
    lastIndex: number,
    prefixes: Record<string, number>,
}>

function getPromptWordIndexMap(words: PromptWord[]) {
    const numWords = words?.length || 0;
    if (numWords <= 0) {
        return {};
    }
    const indexMap: PromptWordIndexMap = {};
    words.forEach((word, index) => {
        if (!word) {
            return;
        }
        const {
            value,
            prefix = "",
            autocompleteType,
        } = word;
        if (!value || !autocompleteType) {
            return;
        }

        const wordIndexMap = indexMap[autocompleteType];
        if (wordIndexMap) {
            wordIndexMap.lastIndex = Math.max(wordIndexMap.lastIndex, index);
            wordIndexMap.prefixes[prefix] =
                typeof (wordIndexMap.prefixes[prefix]) === 'number' ?
                    Math.min(
                        wordIndexMap.prefixes[prefix],
                        index,
                    ) :
                    index;
        } else {
            indexMap[autocompleteType] = {
                lastIndex: index,
                prefixes: {
                    [prefix]: index,
                },
            }
        }
    });
    return indexMap;
}

function pushNewWord(
    outputWords: PromptWord[],
    newWord: PromptWord & { replaceIndex: number },
) {
    if (!newWord) {
        return;
    }

    const { replaceIndex, ...word } = newWord;
    outputWords.push({
        ...word,
        isAutoFilled: true,
    });
}

function replaceWithNewWord(
    outputWords: PromptWord[],
    newWord: PromptWord & { replaceIndex: number },
    oldWord: PromptWord,
) {
    const { replaceIndex, ...word } = newWord;
    outputWords.push({
        ...word,
        isAutoFilled: true,
        valueBeforeAutoFill: oldWord.valueBeforeAutoFill || oldWord.value,
    });
}

function getPromptWordDefaultReplaceIndex(
    autocompleteType?: PromptAutocompleteType,
) {
    if (autocompleteType === 'placement') {
        return 0.5;
    }
    if (autocompleteType === 'surrounding') {
        return 1.5;
    }
    if (autocompleteType === 'background') {
        return 999999;
    }
    if (autocompleteType === 'subject') {
        return 0;
    }
    return 999999;
}

const autocompleteTypeOrder: Record<PromptAutocompleteType, number> = {
    'subject': 0,
    'placement': 1,
    'surrounding': 2,
    'background': 3,
    'template': 4,
    'custom': 5,
}

const autocompleteTypeOrderList = Object.keys(autocompleteTypeOrder);

function addOrReplaceWordsAtIndices(
    words: PromptWord[],
    newWordsToReplace: (PromptWord & { replaceIndex?: number })[],
) {
    // console.log('Words:');
    // console.log(words);
    // console.log('New words to replace:');
    // console.log(newWordsToReplace);

    const wordsToReplace = newWordsToReplace
        .filter(Boolean)
        .sort((a, b) => (a?.replaceIndex ?? 0) - (b?.replaceIndex ?? 0)) as (PromptWord & { replaceIndex: number })[];

    if (wordsToReplace.length <= 0) {
        return words;
    }
    let currWordIndex = 0;
    const maxWordIndex = words.length;
    let currNewWordIndex = 0;
    const maxNewWordIndex = wordsToReplace.length;
    const outputWords: PromptWord[] = [];
    const maxOutputLength = words.length + wordsToReplace.length;

    while (
        (currWordIndex < maxWordIndex || currNewWordIndex < maxNewWordIndex) &&
        outputWords.length <= maxOutputLength &&
        currWordIndex <= maxOutputLength &&
        currNewWordIndex <= maxOutputLength
    ) {
        const word = words[currWordIndex];
        const newWord = wordsToReplace[currNewWordIndex];

        if (!word?.value) {
            // Push the new word
            pushNewWord(outputWords, newWord);
            currNewWordIndex += 1;
            currWordIndex += 1;
            continue;
        }
        if (!newWord?.value) {
            // Push the current word
            outputWords.push(word);
            currWordIndex += 1;
            currNewWordIndex += 1;
            continue;
        }
        const replaceIndex = newWord.replaceIndex;
        if (replaceIndex < currWordIndex) {
            // Push the new word
            pushNewWord(outputWords, newWord);
            currNewWordIndex += 1;
        } else if (replaceIndex === currWordIndex) {

            const prevWordReplaceIndex = getPromptWordDefaultReplaceIndex(word.autocompleteType);
            const currWordReplaceIndex = getPromptWordDefaultReplaceIndex(newWord.autocompleteType);

            if (prevWordReplaceIndex === currWordReplaceIndex) {
                // Push the new word and skip the current word (i.e. replace the current word)
                replaceWithNewWord(
                    outputWords,
                    newWord,
                    word,
                );

                currNewWordIndex += 1;
                currWordIndex += 1;

            } else if (prevWordReplaceIndex > currWordReplaceIndex) {

                pushNewWord(outputWords, newWord);
                currNewWordIndex += 1;

                // outputWords.push(word);
                // currWordIndex += 1;

            } else if (prevWordReplaceIndex < currWordReplaceIndex) {

                outputWords.push(word);
                currWordIndex += 1;

                pushNewWord(outputWords, newWord);
                currNewWordIndex += 1;

            }
        } else {
            if (replaceIndex < currWordIndex + 1) {
                // Push the new word after the current word
                outputWords.push(word);
                currWordIndex += 1;
                pushNewWord(outputWords, newWord);
                currNewWordIndex += 1;
            } else {
                // Push the current word
                outputWords.push(word);
                currWordIndex += 1;
            }
        }
    }

    // console.log("Output words:");
    // console.log(outputWords);

    return outputWords;
}

function getDefaultReplaceIndexForAutocompleteType(
    autocompleteType: PromptAutocompleteType,
    indexMap: PromptWordIndexMap,
) {
    // Find the last auto-complete type
    const autocompleteIndex = autocompleteTypeOrder[autocompleteType];
    if (autocompleteIndex == null || autocompleteType === 'subject') {
        return getPromptWordDefaultReplaceIndex(autocompleteType);
    }

    for (let index = autocompleteIndex + 1, i = 0; index <= autocompleteTypeOrder.background; ++index, ++i) {
        const currType = autocompleteTypeOrderList[index];

        if (!currType) {
            return getPromptWordDefaultReplaceIndex(autocompleteType);
        }

        const lastIndex = indexMap[currType]?.lastIndex;

        if (lastIndex) {
            return lastIndex - 0.1 * (i + 1);
        }
    }
    return getPromptWordDefaultReplaceIndex(autocompleteType);
}

function getPromptWordFromPrefixToValues(
    prefixToValues: PrefixToValues,
): Partial<PromptWord>[] {
    return Object.entries(prefixToValues).map(([prefix, prefixValues]) => {
        return {
            prefix,
            value: concatTexts(prefixValues.map(v => v.value)),
            objectIds: prefixValues.map(v => v.objectId),
        };
    }).filter(({ value }) => Boolean(value));
}

function removeAutoFilledWords(words: PromptWord[]): PromptWord[] {
    return words.map((word) => {
        if (!word.isAutoFilled) {
            return word;
        }
        const { valueBeforeAutoFill = undefined, ...restWord } = word;
        if (valueBeforeAutoFill) {
            return {
                ...restWord,
                isAutoFilled: false,
                value: word.valueBeforeAutoFill,
            };
        }
        return undefined;
    }).filter(Boolean) as PromptWord[];
}

function addOrReplaceAutoFilledPromptWords(
    words: PromptWord[],
    newWords: PromptWord[],
) {
    words = removeAutoFilledWords(words);

    const indexMap = getPromptWordIndexMap(words);

    const newWordsToReplace = newWords.map((word) => {
        const { autocompleteType, prefix } = word;
        if (!autocompleteType || prefix == null) {
            return undefined;
        }
        const lastIndex = indexMap[autocompleteType]?.lastIndex;
        if (lastIndex == null) {
            // Insert the prompt after the previous autocomplete-type
            return {
                ...word,
                replaceIndex: getDefaultReplaceIndexForAutocompleteType(
                    autocompleteType,
                    indexMap,
                ),
            };
        }
        const firstPrefixIndex = indexMap[autocompleteType]?.prefixes?.[prefix];
        if (firstPrefixIndex == null) {
            // Insert the prefix after the last
            return {
                ...word,
                replaceIndex: lastIndex + 0.5,
            }
        } else {
            // Replace the word
            return {
                ...word,
                replaceIndex: firstPrefixIndex,
            }
        }
    }) as (PromptWord & { replaceIndex?: number })[];


    let outputWords = addOrReplaceWordsAtIndices(
        words,
        newWordsToReplace,
    );

    // Check if there's subject prompt in the words
    const noSubject = outputWords.find(w => w.autocompleteType === 'subject') == null;

    if (noSubject) {
        // Add an empty subject word in the beginning
        outputWords = [
            {
                type: 'input',
                autocompleteType: 'subject',
                value: '',
            },
            ...outputWords,
        ];
    }

    return outputWords;
}

type PrefixValue = {
    value: string,
    objectId: string,
}

type PrefixToValues = Record<string, PrefixValue[]>;

type PrefixToValuesCollection = {
    subject: PrefixToValues,
    placement: PrefixToValues,
    surrounding: PrefixToValues,
    background: PrefixToValues,
}

type ObjectMetadataValueKey = `${PromptAutocompleteType}Prefix`

function getObjectMetadataPrefixKey(
    autocompleteType: PromptAutocompleteType,
): ObjectMetadataValueKey {
    return `${autocompleteType}Prefix`;
}

function getObjectMetadataValueKey(
    autocompleteType: PromptAutocompleteType,
) {
    return autocompleteType;
}

function getPrefixToValuesFromObject(
    object: fabric.Object,
    prefixToValues: PrefixToValues,
    valueKey: PromptAutocompleteType,
    prefixKey: ObjectMetadataValueKey,
    keepOne: boolean = false,
) {
    if (!object?.metadata?.[valueKey]) {
        return;
    }
    const prefix = object.metadata[prefixKey] || "";
    const value = object.metadata[valueKey];

    if (typeof (value) !== 'string' || typeof (prefix) !== 'string') {
        return;
    }

    const prefixValue: PrefixValue = {
        value,
        objectId: object.id,
    };

    // console.log(`Get value ${valueKey} : ${value} from object ${object.id}`);

    const values = prefixToValues[prefix];

    if (values) {
        if (keepOne) {
            prefixToValues[prefix] = [
                prefixValue,
            ];
        } else {
            values.push(prefixValue);
        }
    } else {
        prefixToValues[prefix] = [
            prefixValue,
        ];
    }
}

function getPromptTemplateUpdaterFromObjects(objects: fabric.Object[]) {
    // How to combine old prompt with the new prompt? Replace the old prompt.
    // For placement we should also consider z-index.
    const prefixToValuesCollection: PrefixToValuesCollection = {
        'subject': {},
        'placement': {},
        'surrounding': {},
        'background': {},
    };
    objects.forEach((object) => {
        if (!object) {
            return;
        }

        getObjectEntries(prefixToValuesCollection)
            .forEach(([key, prefixToValues]) => {
                const valueKey = getObjectMetadataValueKey(key);
                const prefixKey = getObjectMetadataPrefixKey(key);
                getPrefixToValuesFromObject(
                    object,
                    prefixToValues,
                    valueKey,
                    prefixKey,
                    valueKey === 'placement',
                );
            });
    });

    const newWords: PromptWord[] = [];
    Object.entries(prefixToValuesCollection).forEach(([key, prefixToValues]) => {
        const words = getPromptWordFromPrefixToValues(prefixToValues);
        newWords.push(...words.map(word => ({
            ...word,
            type: 'input',
            autocompleteType: key,
        })) as PromptWord[]);
    });

    if (newWords.length <= 0) {
        return undefined;
    }

    return (template: PromptTemplate) => {
        const newTemplate = { ...template };

        newTemplate.words = addOrReplaceAutoFilledPromptWords(
            template.words,
            newWords,
        );

        return newTemplate;
    }
}

export function propagatePromptWordUpdateToObject({
    newTemplate,
    wordIndex,
}: {
    newTemplate: PromptTemplate,
    wordIndex: number,
}) {
    const {
        editor,
        objectsInsideGenerationFrame,
    } = editorContextStore.getState();

    if (!editor || !objectsInsideGenerationFrame?.length) {
        return;
    }

    const newWord = newTemplate?.words?.[wordIndex];

    if (!newWord?.objectIds) {
        return;
    }

    const {
        value,
        prefix = '',
        objectIds,
        autocompleteType,
    } = newWord;

    if (objectIds.length <= 0 || !autocompleteType) {
        return;
    }

    const objectIdsInsideGEnerationFrame = new Set(objectsInsideGenerationFrame.map(object => object.id));

    const objectValues = objectIds.length === 1 ? [value] : splitTexts(value);

    objectIds.forEach((objectId, index) => {
        const objectValue = objectValues[index];

        if (!objectValue) {
            return;
        }

        if (!objectIdsInsideGEnerationFrame.has(objectId)) {
            return;
        }

        const object = editor.objects.findOneById(objectId);

        if (!object) {
            return;
        }

        const valueKey = getObjectMetadataValueKey(autocompleteType);
        const prefixKey = getObjectMetadataPrefixKey(autocompleteType);

        const prevObjectMetadata = object.metadata || {};

        object.metadata = {
            ...prevObjectMetadata,
            [valueKey]: objectValues[index],
            [prefixKey]: prefix || prevObjectMetadata[prefixKey] || "",
        }
    });
}


export function updatePromptFromObjectsEffect() {

    const {
        editor,
        objectsInsideGenerationFrame,
    } = editorContextStore.getState();

    const objects = objectsInsideGenerationFrame;

    if (!editor || !objects) {
        return;
    }

    const promptTemplateUpdater = getPromptTemplateUpdaterFromObjects(objects);

    if (!promptTemplateUpdater) {
        return;
    }

    editorContextStore.getState().setGenerateToolPromptTemplate(
        promptTemplateUpdater,
    );

}

export function useUpdatePromptFromObjectsEffect() {
    const objects = editorContextStore(state => state.objectsInsideGenerationFrame);
    const prevObjectIdsInsideGenerationFrameRef = React.useRef<string[]>([]);

    React.useEffect(() => {

        const {
            editor
        } = editorContextStore.getState();

        if (!editor || !objects) {
            return;
        }

        const newObjectIds = objects.map(object => object.id).sort();

        const areObjectsTheSame = areArraysEqual(
            newObjectIds,
            prevObjectIdsInsideGenerationFrameRef.current,
        );

        if (areObjectsTheSame) {
            return;
        }

        prevObjectIdsInsideGenerationFrameRef.current = [...newObjectIds];

        const promptTemplateUpdater = getPromptTemplateUpdaterFromObjects(objects);

        if (!promptTemplateUpdater) {
            return;
        }

        editorContextStore.getState().setGenerateToolPromptTemplate(
            promptTemplateUpdater,
        );
    }, [objects]);
}

export function handlePromptWordValueChange({
    index,
    word,
    value,
    setPromptTemplate,
}: {
    index: number,
    word: PromptWord,
    value: string,
    setPromptTemplate: (updater: StateUpdater<PromptTemplate>) => void,
}) {
    setPromptTemplate((prevTemplate) => {
        const newTemplate = { ...prevTemplate };

        newTemplate.words[index] = {
            ...word,
            value,
        }

        propagatePromptWordUpdateToObject({
            newTemplate,
            wordIndex: index,
        });

        return newTemplate;
    });
}