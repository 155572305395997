import { editorContextStore } from 'contexts/editor-context';
import { classNames } from 'core/utils/classname-utils';
import React from 'react';
import { ApiDashboardModelType } from 'core/common/types/api';
import { GenerateImageApi } from './generate-image-api';
import { DashboardManageApiDefault } from './default';


function DashboardManageApiInner() {
    const apiDashboardModelType = editorContextStore(state => state.apiDashboardModelType);

    if (apiDashboardModelType === ApiDashboardModelType.ImageGeneration) {
        return (
            <GenerateImageApi />
        );
    }

    return (
        <DashboardManageApiDefault />
    );
}

export const DashboardManageApi = React.memo(function DashboardManageApi({
    visible = true,
}: {
    visible?: boolean,
}) {
    return (
        <div
            className={classNames(
                visible ? "w-full first-line:flex flex-col items-start justify-center" : "hidden",
            )}
        >
            <DashboardManageApiInner />
        </div>
    )
});