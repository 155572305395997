import React from "react";
import { ApiDashboardModelPreviewTab, ApiDashboardModelTab, ApiPipelineType, ApiRenderState, GenerateImageApiDocType } from "core/common/types/api";
import { StateUpdater } from "core/common/types";
import { GenerateImageApiState, getGenerateImageApiConfigFromApiPipelineType } from "./generate-image-api-config";
import { cloneDeep, noop } from "lodash";
import { getApiStateFromApiConfig } from "./api-input";

export type GenerateImagePlaygroundContextState = {
    apiState: GenerateImageApiState,
    setApiState: (value: StateUpdater<GenerateImageApiState>) => void,
    renderProgress: number,
    setRenderProgress: (value: StateUpdater<number>) => void,
    renderState: ApiRenderState,
    setRenderState: (value: StateUpdater<ApiRenderState>) => void,
    renderResults: string[],
    setRenderResults: (value: StateUpdater<string[]>) => void,
    renderResultMessage: string,
    setRenderResultMessage: (value: StateUpdater<string>) => void,
    playgroundTab: ApiDashboardModelPreviewTab,
    setPlaygroundTab: (value: StateUpdater<ApiDashboardModelPreviewTab>) => void,
    modelTab: ApiDashboardModelTab,
    setModelTab: (value: StateUpdater<ApiDashboardModelTab>) => void,
    docTab: GenerateImageApiDocType,
    setDocTab: (value: StateUpdater<GenerateImageApiDocType>) => void,
}


export const GenerateImagePlaygroundContext = React.createContext<GenerateImagePlaygroundContextState>({
    apiState: cloneDeep(getApiStateFromApiConfig(getGenerateImageApiConfigFromApiPipelineType())),
    setApiState: noop,
    renderProgress: 0,
    setRenderProgress: noop,
    renderState: ApiRenderState.Idle,
    setRenderState: noop,
    renderResults: [],
    setRenderResults: noop,
    renderResultMessage: '',
    setRenderResultMessage: noop,
    playgroundTab: ApiDashboardModelPreviewTab.Code,
    setPlaygroundTab: noop,
    modelTab: ApiDashboardModelTab.Demo,
    setModelTab: noop,
    docTab: ApiPipelineType.Default,
    setDocTab: noop,
});